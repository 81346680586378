import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { RegEx } from 'src/app/config/reg-ex';
import { AuthService } from 'src/app/provider/auth.service';
import { CommonService, CountryCode } from 'src/app/provider/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  editProfileStatus: boolean = false;
  editAddressForm: FormGroup;
  changePasswordStatus: boolean = false;
  loggedInUserType: any;
  userProfileArray: any;
  countryList: any[];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  imageUrl: any
  myControl = new FormControl('');
  filteredOptions: Observable<CountryCode[]>;
  isSubmittedForm: boolean = false
  constructor(private authService: AuthService, private router: Router, public commonService: CommonService) { }

  ngOnInit(): void {
    this.formValidation();
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn();
    this.formValidationChangePassword();
    this.userProfile();
  }

  formValidation() {
    this.editAddressForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.pattern(RegEx.firstName)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(RegEx.lastName)]),
      dob: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.pattern(RegEx.email)]),
      location: new FormControl("", [Validators.pattern(RegEx.email)]),
      typeOfEvent: new FormControl(""),
      roleOfEvent: new FormControl(""),
      eventDate: new FormControl(""),
      showPrice: new FormControl(""),
      mobileNumber: new FormControl('', Validators.pattern(RegEx.phoneNo)),
      image: new FormControl('')
    });
    this.countryList = this.commonService.countryListJson;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (value ? this._filter(value) : this.countryList.slice())),
    );
    // this.editAddressForm.get('email').disable()
  }
  private _filter(value: string): CountryCode[] {
    const filterValue = value.toLowerCase();
    return this.countryList.filter(option => option.code.toLowerCase().includes(filterValue));
  }

  editProfileSubmit() {
    console.log("clicked", this.editProfileStatus)
    this.editProfileStatus = !this.editProfileStatus
  }

  changePassword() {
    this.changePasswordStatus = !this.changePasswordStatus
  }




  // change password

  toggleEye: boolean = true;  // toggle eye
  toggleEye1: boolean = true; //toggle eye1
  toggleEye2: boolean = true; //toggle eye2
  vertical: any = "true";
  changePasswordCard: boolean = true //
  resetPasswordForm: FormGroup; //form name
  formValidationChangePassword() {
    // Form group and Form Control Name With Validations
    this.resetPasswordForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i),
        Validators.maxLength(30),
      ]),
      confirmPassword: new FormControl("", [
        Validators.required,
        Validators.maxLength(32),
      ]),
    });
  }

  changePasswordUser() {
    if (this.resetPasswordForm.invalid || (this.resetPasswordForm.value.confirmPassword != this.resetPasswordForm.value.newPassword)) {
      this.isSubmittedForm = true
      console.log(this.isSubmittedForm);
      return;
    }
    else {
      let apiReqUrl = ApiEndPoint.changePassword
      const item = {
        'oldPassword': this.resetPasswordForm.value.oldPassword,
        'newPassword': this.resetPasswordForm.value.newPassword
      }
      this.commonService.showSpinner()
      this.commonService.putApi(apiReqUrl, item, 1).subscribe((res: any) => {
        console.log("change password response ==>", res)
        this.commonService.hideSpinner()
        if (res.responseCode == 200) {
          Swal.fire({
            icon: 'success',
            text: res.responseMessage,
          })
          this.changePasswordStatus = false;
          this.router.navigate(['/profile-management'])
        }
        else {
          this.commonService.hideSpinner()
        Swal.fire({
          title : 'Oops...',
          text :res.responseMessage
        })
        }
      }, (err: any) => {
        if (err.responseCode == 403) {
          Swal.fire({
            title: 'Oops...',
            text: err.responseMessage,
          })
        }else{
          Swal.fire({
            title: 'Oops...',
            text: err.responseMessage,
          })
        }
      })
    }
  }

  navigateToSignin() {
    this.router.navigate(['/signin'])
  }
  newList: any = []
  userProfile() {

    let apiReqUrl = ApiEndPoint.userProfile
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("change password response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        this.editAddressForm.patchValue({
          firstName: res.result.firstName,
          lastName: res.result.lastName,
          email: res.result.email,
          showPrice: res.result.showPrice,
          mobileNumber: res.result.mobileNumber,
        })
        this.imageUrl = res.result.usersProfilePic
        this.myControl.patchValue(res.result.countryCode)

        // this.myControl.value  res.result.countryCode
        // this.commonService.successToast(res.responseMessage)
        this.userProfileArray = res.result
        this.countryListArr(res.result.country);
        //  this.newList = res.result.country.map(x=> x.countryList.countryName)
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  countryListArray: any = [];
  countryName: any
  countryNameList: any
  countryListArr(countryCode) {
    let apiReqUrl = ApiEndPoint.countryList
    // this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      console.log("change password response ==>", res)
      if (res.responseCode == 200) {
        // this.commonService.hideSpinner()
        //  this.commonService.successToast(res.responseMessage)
        this.countryListArray = res.result
        let temp = res.result.filter((item) => {
          return item.countryCode == countryCode
        })
        console.log(temp);
        if (temp.length) {
          this.countryNameList = temp[0].countryName
        }
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }


  /**
   * @param edit user profile
   */
  editProfile() {
    let apiReqUrl = ApiEndPoint.userEditProfile
    var apiReqData = {
      "firstName": this.editAddressForm.value.firstName,
      "lastName": this.editAddressForm.value.lastName,
      "email": this.editAddressForm.value.email,
      "showPrice": this.editAddressForm.value.showPrice,
      "mobileNumber": this.editAddressForm.value.mobileNumber,
      'usersProfilePic': this.imageUrl,
      countryCode: this.myControl.value,
      // "autoNotifyType" : type,
    };
    console.log(apiReqData);

    this.commonService.showSpinner()
    this.commonService.putApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        Swal.fire({
          icon: 'success',
          text: res.responseMessage,
        })
        this.editProfileStatus = !this.editProfileStatus
        this.userProfile(); // recall the profile data
        this.router.navigate(['/profile-management'])

      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  // upload image on cloudnary
  uploadImg(event): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.imageUrl = res['result']
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }

}
