<div class="wrapper">
    <div class="row m-0">
        <div class="col-md-12 help-text-div">
            <h1 class="new-style text-center font-custom headingTitle">Help and Info</h1>
        </div>
        <div class="col-md-12 help-text header-text">
            <p>We invite you to call or text our concierge now at 212-583-1700 to reserve an appointment at our NYC
                salon to view the Motee-Maids, Nouvelle Motee-Maids - bridal and bridesmaids, Little White Dress,
                Evening or Christos Collection</p>
            <p>See all of our collection FAQ below - you can also email us at flagship@Motee-Maids.com or
                customerservice@Motee-Maids.com</p>
            <p>Or book a virtual appointment to discuss your questions from the comfort of your own home.</p>
            </div>
            <div class="col-md-12 header-text m-3" *ngFor="let item of faqList">
                <div class="help-text-div2 d-none" style="">
                <h1 class="new-style font-custom text-left">{{item?.type}}</h1>
            </div>
            <!-- <div class="question-answer"> -->
            <div class="">
                <div class="text-question-div">
                    <h3 class="new-style text-question">Q: {{item?.question}} </h3>
                </div>
                <p class="p2 text-question-div"><strong>A:</strong> {{item?.answer}}&nbsp;</p>
            </div>

            <!-- <div class="question-answer">
                <div  class="text-question-div">
                    <h3 class="new-style text-question">Q: Is there a way to expedite shipping?  </h3>
                </div>
                <p class="p2 text-question-div"><strong>A:</strong>Our standard ground shipping (free of charge) is typically 5-7 business days. We have an overnight shipping option available for any order at $50 additional, please reach out to customerservice@moteemaids.com to add this to your order.</p>
            </div>

            <div class="question-answer">
                <div  class="text-question-div">
                    <h3 class="new-style text-question">Q: What is the return policy?  </h3>
                </div>
                <p class="p2 text-question-div"><strong>A:</strong>Items in the Little White Dress and Evening collections are eligible for return within 14 days of delivery, no exceptions. Please note there is a $25 restocking fee per garment returned. Please click here to receive a shipping label.</p>
                <p class="p2 text-question-div">Please read our full return policy here</p>

            </div>

            <div class="question-answer">
                <div  class="text-question-div">
                    <h3 class="new-style text-question">Q: How can I determine the best size to order? </h3>
                </div>
                <p class="p2 text-question-div"><strong>A:</strong>Please see our size guide.</p>
                <p class="p2 text-question-div">If you would like us to make a personal recommendation according to the dress pattern, please take your bust, under bust, waist and hip measurements (per the the guide above) and email to customerservice@moteemaids.com along with the style you will be wearing. We will review the measurements with our production team and make a recommendation for the size we believe will work best in the style you’ve selected.</p>

            </div> -->
        </div>

        <!-- <div class="col-md-12 header-text">
        <div class="help-text-div2">
            <h1 class="new-style font-custom text-left">BRIDAL</h1>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: How far in advance do you recommend I begin shopping for my dress? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>We recommend 8-10 months for the entire dress selection, customization, and alteration process. Some dresses can take up to 10 months to make, depending on the detail and fabrication. We usually advise brides to allow up to two months for all alterations. If you need to expedite the process, please contact our Customer Service Team for details.
            </p>
        </div>

        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: How do I book an appointment? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>You can request an appointment at the Motee-Maids flagship in New York by visiting www.Motee-Maids.com or by calling 212-583-1700. </p>
        </div>

        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: How many guests am I allowed to bring to my appointment? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong> A: Typically, our brides bring 1-3 guests with them while viewing and selecting a gown. We’re always happy to accommodate your guests, but suggest fewer guests for a more streamlined process.</p>
        </div>

        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>

        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>

        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>

        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>

        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
        <div class="question-answer">
            <div  class="text-question-div">
                <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
            </div>
            <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
        </div>
</div> -->
<!-- <div class="col-md-12 header-text">
    <div class="help-text-div2">
        <h1 class="new-style font-custom text-left">BRIDESMAIDS</h1>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: How far in advance do you recommend I begin shopping for my dress? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>We recommend 8-10 months for the entire dress selection, customization, and alteration process. Some dresses can take up to 10 months to make, depending on the detail and fabrication. We usually advise brides to allow up to two months for all alterations. If you need to expedite the process, please contact our Customer Service Team for details.
        </p>
    </div>

    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: How do I book an appointment? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>You can request an appointment at the Motee-Maids flagship in New York by visiting www.Motee-Maids.com or by calling 212-583-1700. </p>
    </div>

    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: How many guests am I allowed to bring to my appointment? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong> A: Typically, our brides bring 1-3 guests with them while viewing and selecting a gown. We’re always happy to accommodate your guests, but suggest fewer guests for a more streamlined process.</p>
    </div>

    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>

    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>

    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>

    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>

    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
    <div class="question-answer">
        <div  class="text-question-div">
            <h3 class="new-style text-question">Q: What is the price range of your gowns? </h3>
        </div>
        <p class="p2 text-question-div"><strong>A:</strong>Our Motee-Maids Nouvelle gowns range from $1,900-$3,300 and Motee-Maids from $4,200-$10,000. We also offer bespoke services, price upon request.</p>
    </div>
</div> -->


    </div>

</div>