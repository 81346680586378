import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StoreLocatorDialogComponent } from 'src/app/modals/store-locator-dialog/store-locator-dialog.component';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/provider/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
// import { StoreLocatorDialogComponent } from './modals/store-locator-dialog/store-locator-dialog.component';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  collection = false
  logoResultArray: any = [];
  type: any;
  topHeaderTitle: any
  // for sub menu hover 
  isShowCollection: boolean = false
  isShowSocial: boolean = false
  isShowSwatches: boolean = false
  isShowApponitment: boolean = false
  isShowShip: boolean = false
  isShowTrunk: boolean = false
  isShowProfile: boolean = false

  isLoggedIn: boolean;
  myProfile: any;
  loggedInUserType: any;
  topHeaderTitleLink: any;
  totalItemsInShoppingBag: any;
  constructor(public authService: AuthService, private router: Router, public dialog: MatDialog, public commonService: CommonService, private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    // scroll to top 
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scroll(0, 0)
    });
    // end scroll to top 

    this.activatedroute.queryParams.subscribe((res) => {
      this.type = "LOGO";
    })
    this.logo()
    this.headerListDetail();
    this.viewHeaderLink()
    // console.log(this.authService.isLoggedIn);
    // console.log(this.authService.loggedInUserType);

    // this.isLoggedIn = this.authService.isLoggedIn
    this.authService.isLoggedIn.subscribe((res: any) => {
      console.log(res);

      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    // this.authService.loggedInUserType.subscribe((res: any) => {
    //   console.log(res)
    //   this.loggedInUserType = res
    // })

    // console.log("isLoggedIn", this.isLoggedIn$)
    // $('.btn-toggle,.close_panel').click(() => {
    //   $('body').toggleClass('toggle-wrapper');
    // });
    this.commonService.totalItemsInShoppingBag.subscribe((res: any) => {
      console.log(res);
      if (res) {
        this.totalItemsInShoppingBag = res
      } else {
        this.isLoggedIn ? this.myShopingBagApi() : ''
      }
    });

    $(document).ready(function () {
      $('body').on('mouseup', function (e) {
        if (!$(e.target).hasClass("dropdown-menu")) {
          $(".dropdown-menu").each(function () {
            $(this).removeClass("show");
          });
        }
      });
    });
  }

  // check: boolean = false
  // toogleNav() {

  //   this.check = this.check ? false : true

  // }
  // open store locator modal path-pages/modals/store-locator-dialog



  // showCollection() {
  //   this.isShowCollection = true
  //   console.log('enter');

  // }


  loginNavigate(userType) {
    switch (userType) {
      case 'CUSTOMER':
        this.router.navigate(['/signin'])
        break;
      case 'RETAILER':
        this.router.navigate(['/retailer-login'])
        break;
      case 'INFLUENCER':
        this.router.navigate(['/influencer-login'])
        break;
    }
  }

  logout() {
    this.authService.logout()
  }

  // navigateTo(path) {
  //   switch (path) {
  //     case 'my-profile':
  //       this.router.navigate(['/profile-management'], { queryParams: { showTab: 'PROFILE' } })
  //       break;
  //     case 'my-wishlist':
  //       this.router.navigate(['/profile-management'], { queryParams: { showTab: 'WISHLIST' } })
  //       break;
  //     case 'my-shopping-bag':
  //       this.router.navigate(['/my-shopping-bag'])
  //       break;
  //   }
  // }

  menuNavigateTo(path) {
    console.log(path);

    switch (path) {
      case 'Customize':
        this.router.navigate(['/dress-collection'])
        break;
      case 'Social Shopping':
        // $('#deliveryInfoModalId').modal('show')
        this.router.navigate(['/curation-list'])
        break;
      case 'my-profile':
        // this.router.navigate(['/my-profile'])
        this.router.navigate(['/profile-management'], { queryParams: { showTab: 'PROFILE' } })
        break
      case 'search':
        this.router.navigate(['/search'])
        break;
      case 'my-shopping-bag':
        this.router.navigate(['/my-shopping-bag'])
        break;
    }
  }


  menuSubNavigateTo(categoryId, subCategoryId, categoryName, subCategoryName) {
    console.log(categoryId, ' -- ', subCategoryId, ' -- ', categoryName, ' -- ', subCategoryName);
    switch (categoryName) {
      // collection
      case 'Collection':
        this.router.navigate(['/bridal-collection-list'], { queryParams: { categoryId: categoryId, subCategoryId: subCategoryId, categoryName: categoryName, subCategoryName: subCategoryName } })
        break;

      // ready to ship
      case 'Ready To Ship':
        // this.router.navigate(['/bridal-party'], { queryParams: { categoryId: categoryId, subCategoryId: subCategoryId, categoryName: categoryName, subCategoryName: subCategoryName } })
        this.router.navigate(['/readytoship-bridal'], { queryParams: { categoryId: categoryId, subCategoryId: subCategoryId, categoryName: categoryName, subCategoryName: subCategoryName } })

        break;

      // swatches
      case 'Swatches':
        if (subCategoryName == 'Motee Maids Bridesmaids') {
          this.router.navigate(['/swatches-collection-list'])
        } else if (subCategoryName == 'Nouvelle Motee Maids Bridesmaids') {
          this.router.navigate(['/nouvelle-swatches-list'])
        } else {
          this.router.navigate(['/mask-list'])
        }
        break;

      // book an appointment
      case 'Book An Appointment':
        // if (subCategoryName == 'Visit Nearest Salon') {
        if (subCategoryName == 'Appointments') {
          // this.router.navigate(['/visit-nyc-salon'])
          this.router.navigate(['/select-date'])
        } else if (subCategoryName == 'Store Locator') {
          this.router.navigate(['/store-locator'])
        } else {
          this.router.navigate(['/faq'])
        }
        break;

      // trunk show
      case 'Trunk Shows':
        if (subCategoryName == 'Trunk') {
          this.router.navigate(['/trunk'])
        } else {
          this.router.navigate(['/trade'])
        }
        break;

      // story
      case 'Story':
        if (subCategoryName == 'Real Brides') {
          this.router.navigate(['/real-brides'])
        } else if (subCategoryName == 'Blog') {
          this.router.navigate(['/blog'])
        } else {
          this.router.navigate(['/press'])
        }
        break;

      // default

      default:
        this.router.navigate(['/bridal-collection-list'], { queryParams: { categoryId: categoryId, subCategoryId: subCategoryId, categoryName: categoryName, subCategoryName: subCategoryName } })
        break;
    }
  }

  /**
       * userType are used in website
       * @param logo -> normal user comes to buy product
       */
  logo() {
    let apiReqUrl = 'static/viewLogo'

    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.logoResultArray = res.result.logoImage
        this.commonService.hideSpinner()

      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);

      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }

  headerList: any
  // customizeData:any
  /**
     * userType are used in website
     * @param headerList header list -
     */
  headerListDetail() {
    this.commonService.showSpinner()
    this.commonService.getApi(ApiEndPoint.headerList, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.headerList = res.result
        // this.customizeData = res.result[0].parent.categoryName
        this.commonService.hideSpinner()

      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);

      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }

  viewHeaderLink() {
    this.commonService.showSpinner();
    this.commonService.getApi(ApiEndPoint.viewHeaderLink, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.topHeaderTitle = res.result.title
        this.topHeaderTitleLink = res.result.headerLink
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    })

  }

  myShopingBagApi() {
    console.log("myShopingBagApi");
    let apiReqUrl = `${ApiEndPoint.shoppingbagList + '?page=1&limit=100'}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        // this.shoppingBagBuffer = res.result.docs
        this.totalItemsInShoppingBag = res.result.total

      } else {
        this.totalItemsInShoppingBag = 0
      }
    }, (err) => {
      if (err.responseCode == 404) {
        this.totalItemsInShoppingBag = 0
      }
    })
  }

}
