import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
import { AuthService } from 'src/app/provider/auth.service';
declare var $: any;

@Component({
  selector: 'app-ready-to-ship-bridal',
  templateUrl: './ready-to-ship-bridal.component.html',
  styleUrls: ['./ready-to-ship-bridal.component.scss']
})
export class ReadyToShipBridalComponent implements OnInit {
  filtered: any = true
  selected = "Recomended"
  value = "page 1"
  panelOpenState = false;

  like: boolean = false

  step = 0;
  // productListArray: any;
  productDetails: any;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  // panelOpenState = false;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    navText: ['<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"'],
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive: {
      0: {
        items: 5
      },
      400: {
        items: 5
      },
      740: {
        items: 5
      },
      940: {
        items: 5
      }
    },
    // nav: true
    nav: false
    // navigation:true,

  }
  newOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    navText: ['<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"'],
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    // nav: true
    nav: false
    // navigation:true,

  }


  embelishmentOption: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    navText: ['<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"'],
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    // nav: true
    nav: false
    // navigation:true,

  }
  productListArray: any[] = []
  colorListArray: any[] = [];
  swatchesListArray: any[] = [];

  colorId: any[] = [];
  swatchesId: any[] = [];
  // priceId: any = [];
  priceId: any = {};
  swatchesBoxChecked: boolean;
  NoProductArray: any;
  countArray1to34: any;

  priceListArray: any = [
    { _id: 1, minAmount: 100, maxAmount: 300, isSelected: false },
    { _id: 2, minAmount: 300, maxAmount: 500, isSelected: false },
    { _id: 3, minAmount: 500, maxAmount: 800, isSelected: false },
    { _id: 4, minAmount: 800, maxAmount: 1500, isSelected: false },
    { _id: 5, minAmount: 1500, maxAmount: 3000, isSelected: false },
    { _id: 6, minAmount: 3000, maxAmount: 8000, isSelected: false },
    { _id: 7, minAmount: 8000, maxAmount: '', isSelected: false } // max amount
  ];

  countNumber1to34: any = [
    {
      "isSelceted": false,
      "count": '00'
    },
    {
      "isSelceted": false,
      "count": '02'
    },
    {
      "isSelceted": false,
      "count": '04'
    },
    {
      "isSelceted": false,
      "count": '06'
    },
    {
      "isSelceted": false,
      "count": '08'
    },
    {
      "isSelceted": false,
      "count": '10'
    },
    {
      "isSelceted": false,
      "count": '12'
    },
    {
      "isSelceted": false,
      "count": '14'
    },
    {
      "isSelceted": false,
      "count": '16'
    },
    {
      "isSelceted": false,
      "count": '18'
    },
    {
      "isSelceted": false,
      "count": '20'
    },
    {
      "isSelceted": false,
      "count": '22'
    },
    {
      "isSelceted": false,
      "count": '24'
    },
    {
      "isSelceted": false,
      "count": '26'
    },
    {
      "isSelceted": false,
      "count": '28'
    },
    {
      "isSelceted": false,
      "count": '30'
    },
    {
      "isSelceted": false,
      "count": '32'
    },
    {
      "isSelceted": false,
      "count": '34'
    },
    {
      "isSelceted": false,
      "count": '36'
    }
  ]
  sizeSelected: any[] = [];
  ipDetails: any;
  socialShopDetails: any;

  public innerWidth: any;
  sidenavMode: any = 'side'
  sidenavModeOpened: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth
    console.log(this.innerWidth);
    if (this.innerWidth < 768) {
      console.log("inside iffff");
      this.sidenavMode = 'over'
      console.log(this.sidenavMode);
      this.sidenavModeOpened = false
    } else {
      this.sidenavMode = 'side'
      console.log(this.sidenavMode);
      this.sidenavModeOpened = true
    }
  }

  currentConversionRate: any;
  isSkeleton: boolean = true;

  constructor(public authService: AuthService, private commonService: CommonService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.ipDetails = this.commonService.getIPDetails();
    this.innerWidth = window.innerWidth;
    // console.log(this.innerWidth);
    if (this.innerWidth < 769) {
      console.log("inside iffff");
      this.sidenavMode = 'over'
      console.log(this.sidenavMode);
      this.sidenavModeOpened = false
    } else {
      this.sidenavMode = 'side'
      console.log(this.sidenavMode);
      this.sidenavModeOpened = true
    }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      this.productDetails = res
      this.getCollection();

      // route comes from social shoping
      this.activatedRoute.queryParams.subscribe((res) => {
        console.log(res);
        if (res.socialShopDetails) { this.socialShopDetails = res.socialShopDetails }
      })
    });
    this.getColourList();
    this.getSwatchesList() // swatches / fabric
    // this.getCollection()
    // }

    // smooth scroll functionality 
    function reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        }
        else {
          reveals[i].classList.remove("active");
        }
      }
    }

    window.addEventListener("scroll", reveal);

    // show filter when screen width size less then 768px
    $(window).scroll(function (e) {
      var $el = $('.fixedElement');
      var isPositionFixed = ($el.css('position') == 'fixed');
      if ($(this).scrollTop() > 500 && !isPositionFixed) {
        $el.css({ 'position': 'fixed', 'top': '148px' });
      }
      if ($(this).scrollTop() < 500 && isPositionFixed) {
        $el.css({ 'position': 'static', 'top': '200px' });
      }
    });
    // end show filter when screen width size less then 768p

    // conversion rate
    this.currentConversionRate = this.commonService.getConversionRate(this.ipDetails.geoplugin_currencyCode).subscribe((res: any) => {
      console.log(res);
      if (res['responseCode'] == 200) {
        // this.currentConversionRate = res.result
        this.priceListArray.map((x: any) => {
          return x.minAmount = x.minAmount * res.result, x.maxAmount = x.maxAmount * res.result
        })
      }
    })

    // end conversion rate 
  }

  addFilter() {
    this.filtered = false
  }
  removeFilter() {
    this.filtered = true
  }
  /**
  * @param ApiEndPoint
  * @param apiReqUrl
  * send category and sub category 
  * get collection api integration
  *  */
  // colour list  api
  getSwatchesList() {
    let apiReqUrl = ApiEndPoint.listSwatchesWithoutPagination
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      console.log("color list array=>", res);
      if (res['responseCode'] == 200) {
        // this.swatchesListArray = res.result ? res.result : [];
        this.swatchesListArray = res.result.map(ele => ({ ...ele, isSelected: false }))  // add isSelected key to show check-right

        this.commonService.hideSpinner()
      } else {
        this.swatchesListArray = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  selectSwatches(item) {
    // console.log(item);
    const index = this.swatchesId.findIndex(x => x == item._id);
    if (index > -1) {
      item.isSelected = false;
      this.swatchesId.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      item.isSelected = true;
      this.swatchesId.push(item._id)
    }
    // item.isSelected = true;
    // let getId = this.swatchesId.filter(x => x == item._id)
    // if (!getId.length) {
    //   this.swatchesId.push(item._id)
    // }else{
    //   // this.swatchesId.pop(item._id)
    // }
    // console.log(this.swatchesId);
    // console.log(this.swatchesListArray);

    this.getCollection()
  }

  /**
   * @param ApiEndPoint
   * @param apiReqUrl
   * send category and sub category 
   * get collection api integration
   *  */
  // colour list  api
  getColourList() {
    let apiReqUrl = ApiEndPoint.listColor
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      console.log("color list array=>", res);
      if (res['responseCode'] == 200) {
        // this.colorListArray = res.result ? res.result : [];
        this.colorListArray = res.result.map(ele => ({ ...ele, isSelected: false }))  // add isSelected key to show check-right

        this.commonService.hideSpinner()
      } else {
        this.colorListArray = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  selectColor(item) {
    // console.log(item);
    // this.colorId = item
    // let colorId: any[];
    // colorId.push(item)
    // this.colorId = colorId
    const index = this.colorId.findIndex(x => x == item._id);
    if (index > -1) {
      item.isSelected = false;
      this.colorId.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      item.isSelected = true;
      this.colorId.push(item._id)
    }
    // let getId = this.colorId.filter(x => x == item)
    // console.log(getId)
    // if (!getId.length) {
    //   this.colorId.push(item)
    // }
    // console.log(this.colorId);
    this.getCollection()
  }

  // single price selection
  selectPrice(item) {
    if (item.isSelected) {
      this.priceListArray.map(ele => (ele.isSelected = false))  // add isSelected key to show check-right
      this.priceId = {}
    } else {
      this.priceListArray.map(ele => (ele.isSelected = false))  // add isSelected key to show check-right
      this.priceId = item
      // if (this.priceId.isSelected) {
      //   this.priceId = { minAmount: '', maxAmount: '' }
      // }
      if (this.priceId.maxAmount == 0) {
        this.priceId.maxAmount = ''
      }
    }
    this.getCollection()
  }


  // minAmount: any;
  // maxAmount: any;
  // selectPrice(item) {
  //   console.log(item);
  //   const index = this.priceId.findIndex(x => x._id == item._id);
  //   if (index > -1) {
  //     item.isSelected = false;
  //     this.priceId.splice(index, 1); // 2nd parameter means remove one item only
  //   } else {
  //     item.isSelected = true;
  //     this.priceId.push(item)
  //   }
  //   console.log(this.priceId);

  //   // use case for getting filter over max amount if no, then we will send min and max amount 
  //   let findIndexOfMaxEmpty = this.priceId.findIndex(x => x.maxAmount == 0)
  //   console.log(findIndexOfMaxEmpty);

  //   if (findIndexOfMaxEmpty > -1) {
  //     this.minAmount = this.priceId[findIndexOfMaxEmpty].minAmount
  //     this.maxAmount = ''
  //   } else {
  //     this.minAmount = Math.min(...this.priceId.map(x => x.minAmount))
  //     this.maxAmount = Math.max(...this.priceId.map(y => y.maxAmount))
  //   }

  //   console.log(this.minAmount);
  //   console.log(this.maxAmount);
  //   this.getCollection()
  // }


  selectSizes(item) {
    const index = this.sizeSelected.findIndex(x => x == item.count);
    if (index > -1) {
      item.isSelected = false;
      this.sizeSelected.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      item.isSelected = true;
      this.sizeSelected.push(item.count)
    }
    this.getCollection()
  }

  clearFilter() {
    this.swatchesId = []
    this.swatchesListArray.forEach(element => { element.isSelected = false })
    this.colorId = []
    this.colorListArray.forEach(element => { element.isSelected = false })
    this.priceId = {} // single price filter selection
    // this.priceId = [] // multiple price filter selection
    this.priceListArray.forEach(element => { element.isSelected = false })
    this.sizeSelected = []
    this.countNumber1to34.forEach(element => { element.isSelected = false })
    this.getCollection()
  }
  /**
   * @param apiReqUrl contains end point url of ready to ship bridal
   * @param apiReqData contains api req data
   * get collection ready to ship bridal*/
  getCollection() {
    // let apiReqUrl = ApiEndPoint.productListWithFilter
    let apiReqUrl = ApiEndPoint.listNewProductWithFilter
    // let apiReqUrl = "product/getAllCustomProductWithPagination"

    console.log(this.colorId);
    console.log(this.colorId.length);

    let apiReqData = {
      "page": 1,
      "limit": 10,
      "minAmount": this.priceId ? this.priceId.minAmount : '',
      "maxAmount": this.priceId ? this.priceId.maxAmount : '',
      // "minAmount": this.priceId.length ? this.minAmount : '',
      // "maxAmount": this.priceId.length ? this.maxAmount : '',
      "fabric": this.swatchesId.length ? this.swatchesId : '',
      "category": this.productDetails.categoryId,
      "subCategory": [this.productDetails.subCategoryId],
      "color": this.colorId.length ? this.colorId : '',
      "size": this.sizeSelected.length ? this.sizeSelected : '',
      "currencyCode": this.ipDetails.geoplugin_currencyCode
      // "country": this.ipDetails.geoplugin_currencyCode

    }
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    console.log(apiReqData);
    this.commonService.showSpinner()
    // apiReqData = this.commonService.removeEmptyKey(apiReqData)

    this.commonService.postApi(apiReqUrl, apiReqData, (this.authService.checkAuthToken() ? 1 : 0)).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.isSkeleton = false
        // this.commonService.successToast(res.responseMessage)
        this.productListArray = res.result.docs ? res.result.docs : []
        // this.NoProductArray = res.responseCode

        this.commonService.hideSpinner()
      } else {
        this.isSkeleton = false
        // this.NoProductArray = res.responseCode
        this.productListArray = []
        this.commonService.hideSpinner()

      }
    }, err => {
      if (err.responseCode == 404) {
        this.isSkeleton = false
        this.productListArray = []
        // this.NoProductArray = err.status
      }
    })
  }


  /**
   * @param ApiEndPoint contains end point url
   * @param 
   */
  addProductWishlist(productId) {
    if (!this.authService.checkAuthToken()) {
      return this.router.navigate(['/signin']);
    } else {
      // let apiReqUrl = `${ApiEndPoint.addWishListProduct + '?productId=' + productId}`
      let apiReqUrl = `${ApiEndPoint.addWishListProduct + '?newProductId=' + productId}`
      this.commonService.showSpinner()
      this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner()
          this.getCollection()

          //  this.like= !this.like
          //  this.commonService.successToast(res.responseMessage)
        } else {
          this.commonService.hideSpinner()
          this.commonService.errorToast(res.responseMessage)
        }
      })
    }
  }

  // navigateToViewMotherDress(productId) {
  //   this.router.navigate(["/view-mothers-dress"], { queryParams: { productId: productId } })
  // }


  navigateToViewMotherDress(productId) {
    // this.router.navigate(["/view-mothers-dress"], { queryParams: { productId: productId } })
    if (this.socialShopDetails) {
      // when page route come from social shoping
      this.router.navigate(['/view-mothers-dress'], { queryParams: { productId: productId, socialShopDetails: this.socialShopDetails } })
    } else {
      // when page route come from header
      this.router.navigate(["/view-mothers-dress"], { queryParams: { productId: productId } })
    }
  }
  /**
      * Skelton for tranding
      * @param count 
      */
  // skeleton ui configuration
  skeletonImageTheme = { height: '600px' }
  skeletonTextTheme = { height: '30px' }

  // Generate Fake Object Array
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}
