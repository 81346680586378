import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectDressModalComponent } from '../select-dress-modal/select-dress-modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Socket } from 'ngx-socket-io';
import { CommonService } from 'src/app/provider/common.service';
import { AuthService } from 'src/app/provider/auth.service';
import { ChatService } from 'src/app/provider/chat.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { ActivatedRoute, Router } from '@angular/router';
declare var $
@Component({
  selector: 'app-chat-room-list',
  templateUrl: './chat-room-list.component.html',
  styleUrls: ['./chat-room-list.component.scss'],
})
export class ChatRoomListComponent implements OnInit {
  // constructor( public dialog: MatDialog) { }

  // ngOnInit(): void {
  // }
  openDialog(numberOfIndex) {
    const dialogRef = this.dialog.open(SelectDressModalComponent, {
      height: '200px',
      width: '400px',
      data: { numberOfIndex: numberOfIndex, groupId: this.groupId }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  @ViewChild('contentArea') private contentArea: ElementRef;
  messageForm: FormGroup;
  userName = [
    { name: 'King  Waliullah' },
    { name: 'Somya ' },
    { name: 'Tanveer ' },
    { name: 'Arvind sharma' },
    { name: 'Ankita Kumari' },
    { name: 'Sushmita' },
    { name: 'Mohit' },
    { name: 'Satyam' },
    { name: 'King  Waliullah' },
    { name: 'Somya ' },
    { name: 'Tanveer ' },
    { name: 'Arvind sharma' },
    { name: 'Ankita Kumari' },
    { name: 'Sushmita' },
    { name: 'Mohit' },
    { name: 'Satyam' },
  ];
  // constructor() { }

  // ngOnInit(): void {
  //   this.messageForm = new FormGroup({
  //     "message" : new FormControl('')
  //   })
  // }
  send() {
    console.log(this.messageForm.value.message);
  }
  contactList: any = [];
  // senderId: any;
  // adminId: any;
  userId: any;
  particularChat: any = [];
  chatId: any;
  chatUserId: any;
  massageChat: boolean = true;
  message: any;
  receiverId: any;
  userDetail: any;
  sendNewMessage = '';
  index = 0;
  newMessageData: any;
  newMessage: any = '';
  messageList: any = [];
  chatData: any = [];
  recentChatContactList: any = [];
  userTapped: boolean = false;
  profileImageUrl: any;
  groupId: any;

  productIndex: any;
  generatedLink: any
  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public chatService: ChatService,
    public socket: Socket,
    public commonService: CommonService,
    public authService: AuthService,
    public dialog: MatDialog,
    public ngZone: NgZone
  ) { }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.groupId = res.groupId;
      this.addMember(this.groupId);
      // this.productListInGroup(this.groupId)
    });
    // console.log(this.router.url)
    // this.generatedLink = localStorage.getItem('curationLink')
    this.generatedLink = this.commonService.webUrl + 'chat-room?groupId=' + this.groupId
    console.log(this.generatedLink)

    // allChatList: any = [];
    this.socket.connect();
    this.userId = localStorage.getItem('userId');
    this.profileImageUrl = localStorage.getItem('myProfile');
    // this.authService.loggedInData.subscribe((res: any) => {
    //   console.log(res)
    //   this.profileImageUrl = res
    // })

    // this.adminId = '617815e82a3ecb5f876bbac9'
    if (!this.userId || !this.profileImageUrl) {
      console.log('this.userId', this.userId);
      this.getProfileDetails();
    } else {
      this.showOnline();
    }
    //  this.showContactList();
    // this.getChatHistory(true)
    this.receiveMessage();
    // this.recentChatList()
    // this.followingList();
    this.headerListDetail();

    // online user list details
    this.onlineUserDetails();
    this.getOnlineUserDetails();
  }

  // scroll chat of buyer and seller container
  scrollToBottom() {
    setTimeout(() => {
      try {
        this.contentArea.nativeElement.scrollTop =
          this.contentArea.nativeElement.scrollHeight;
      } catch (err) { }
    }, 10);
  }
  /**
   * @private Get APi of User Edit profile details
   */
  getProfileDetails() {
    this.commonService.getApi(ApiEndPoint.userProfile, 1).subscribe((res) => {
      if (res['responseCode'] == 200) {
        this.userId = res['result']['_id'];
        // this.authService.loggedInData.next(res['result']['userResult']['profilePic'])
        this.profileImageUrl = res['result']['profilePic']
          ? res['result']['profilePic']
          : '';
        this.showOnline();
      }
    });
  }

  // get contact list of user
  // showContactList() {
  //   let url = 'admin/contactList';
  //   this.commonService.showSpinner();
  //   this.commonService.getApi(url, 1).subscribe((res: any) => {
  //     console.log('contactList =>', res);
  //     if (res.responseCode == 200) {
  //       this.contactList = res.result;

  //       this.commonService.hideSpinner();
  //       this.commonService.successToast(res.responseMessage);
  //       this.showOnline();
  //       // this.chatHistory(true);
  //       this.receiveMessage();
  //     } else {
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(res.responseMessage);
  //     }
  //   },
  //     (err) => {
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(err);
  //     }
  //   );
  // }

  // make admin online
  showOnline() {
    let obj = {
      userId: this.userId,
      // userId: '617815e82a3ecb5f876bbac9'
    };
    console.log('online user->', obj);
    this.socket.emit('onlineUser', obj);
    this.recentChatContact(true); //
  }

  // get chat history of admin and user
  recentChatContact(id) {
    this.particularChat = [];
    // this.allChatList = [];
    // this.receiverId = id

    let chatData = {
      // senderId: this.userId,
      groupId: this.groupId,
      // "receiverId": this.receiverId
    };
    // let chatData = {
    //   "senderId": "617815e82a3ecb5f876bbac9",
    //   "receiverId": "61779509d0f5990a0888791e"
    // }
    console.log('Req,data', chatData);
    this.socket.emit('chatHistory', chatData);
    this.getRecentChatContact(true);
  }

  getRecentChatContact(id) {
    console.log('get chat history');
    this.socket.fromEvent('chatHistory').subscribe(
      (response: any) => {
        console.log('getChatHistory =>', response);
        console.log('aaaa=>', response['statusCode']);

        if (response['statusCode'] == 200) {
          if (id == true) {
            this.particularChat = response.result.docs[0];
            console.log(this.particularChat);

            // this.recentChatContactList = response['result'];
            // this.recentChatContactList = this.recentChatContactList.filter(
            //   (res) => {
            //     if (res?.receiverId && res?.senderId) {
            //       return res.receiverId._id != res.senderId._id;
            //     }
            //   }

            //   // x => x.receiverId._id != x.senderId._id
            // );
            // this.tempArray = this.recentChatContactList;
            // console.log(this.recentChatContactList);
            // // console.log(this.particularChat)
            this.scrollToBottom();
          }
        } else {
          this.recentChatContactList = [];
          // this.allChatList = [];
        }
      },
      (err) => {
        this.commonService.errorToast('Something went wrong.');
      }
    );
  }

  // 

  onlineUserDetails() {
    let obj = {
      groupId: this.userId,
      // userId: '617815e82a3ecb5f876bbac9'
    };
    console.log('onlineUserDetails->', obj);
    // this.socket.emit('onlineUserDetails', obj);
    this.chatService.sendOnlineUserList(obj);
    // this.chatService.disconnect();
    // $("body>#addNewAddressModalId").remove();

  }

  getOnlineUserDetails() {
    console.log('getOnlineUserDetails');
    this.chatService.getOnlineUserList().subscribe((message: any) => {
      console.log('getOnlineUserDetails ==>', message);
      if (message['response_code'] == 200) {
        //   let lastMessage = message['result']['messages'][0]
        //   console.log(lastMessage)
        //   this.particularChat.messages.push(lastMessage);
        //   console.log("receive last message", this.particularChat);
        //   this.scrollToBottom()
      }
    });
  }


  // -------------------- receive new message ------------------- //
  receiveMessage() {
    console.log('receive message chat');
    this.chatService.getMessage().subscribe((message: any) => {
      console.log('receive message222 ==>', message);
      if (message['statusCode'] == 200) {
        let lastMessage =
          message['result']['messages'][
          message['result']['messages'].length - 1
          ];
        console.log(lastMessage);
        // if (this.userTapped) {
        // this.recentChatContactList.forEach(element => {
        // console.log(this.chatId, lastMessage.chatId);
        // if (this.chatId == lastMessage.chatId) {
        // console.log('ifffffff');
        // console.log(this.chatId, lastMessage.chatId);
        this.particularChat.messages.push(lastMessage);
        // }
        //  else if (this.chatId == 'undefined') {
        //   console.log(this.chatId)
        //   console.log("l:206-> ", this.message.chatHistory[0]._id)
        //   console.log("l:207->", lastMessage.chatId)
        //   if (this.message.chatHistory[0]._id = lastMessage.chatId) {
        //     console.log("ifff matched")
        //     this.particularChat.messages.push(lastMessage);
        //   }
        // }
        // else {
        //   console.log('else 215', this.userTapped);
        //   console.log(this.chatId);
        //   console.log('l:206-> ', message.chatHistory[0]._id);
        //   console.log('l:207->', lastMessage.chatId);
        //   // let messages = []
        //   if ((message.chatHistory[0]._id = lastMessage.chatId)) {
        //     console.log('ifff matched');
        //     // this.particularChat.push(messages)
        //     // this.i = 0
        //     // this.array_temp.push(lastMessage)
        //     // this.particularChat.messages = this.array_temp
        //     // this.i++
        //   }
        // // }
        // });
        // } else if ((message.chatHistory[0]._id = lastMessage.chatId)) {
        //   this.array_temp.push(lastMessage);
        //   this.particularChat.messages = this.array_temp;
        //   console.log('else', this.userTapped);
        // }
        // this.recentChatContact(this.userId)
        // this.recentChatContactList.push()
        // console.log("recent chat -->",this.recentChatContactList)

        // refresh recent chat list
        // console.log(message.chatHistory)
        // this.recentChatContactList = message.chatHistory
        // console.log("mmmm->", this.recentChatContactList)
        this.commonService.incommingMeassageAudio();
        // console.log(
        //   'chat history from oneToOneChat event->',
        //   message.chatHistory
        // );
        // this.recentChatContactList = message.chatHistory;
        // console.log('mmmm->', this.recentChatContactList);
        // this.pushLastMessageInRecentChatList(message['result']);
        console.log('receive last message', this.particularChat);
        this.scrollToBottom();
      }
    });
  }
  array_temp = [];
  i = 0;
  pushLastMessageInRecentChatList(message) {
    // console.log("recent chat -->", this.recentChatContactList)
    if (this.recentChatContactList.length) {
      this.recentChatContactList.forEach((element) => {
        // console.log(element)
        if (!element._id) {
          element.messages.push(message['messages'][0]);
        }
        if (element._id == message._id) {
          element.messages.push(message['messages'][0]);
        }
      });
    }
  }
  // -------------------- receive new message ------------------- //
  recentChatList() {
    console.log('recent receive message chat');
    this.chatService.getRecentChatList().subscribe((message: any) => {
      console.log('receive message234 ==>', message);
      if (message['response_code'] == 200) {
        //   let lastMessage = message['result']['messages'][0]
        //   console.log(lastMessage)
        //   this.particularChat.messages.push(lastMessage);
        //   console.log("receive last message", this.particularChat);
        //   this.scrollToBottom()
      }
    });
  }

  //----------------- view chat --------------------//
  viewChat() {
    if (this.message == '') {
      return;
    } else {
      let data = {
        message: this.newMessage,
      };
    }
  }

  //-------------------------- send message ----------------------------//
  sendMessage() {
    // console.log("send clicked", this.receiverId);
    if (this.newMessage == '') {
      return;
    }
    // else if (this.receiverId == undefined) {
    //   return this.commonService.infoToast("Please select user.");
    // }
    else {
      this.newMessageData = {
        // senderId: this.userId,
        // receiverId: this.receiverId,
        // message: this.newMessage,
        groupId: this.groupId,
        senderId: this.userId,
        message: this.newMessage,
        thumbnail: 'string',
        mediaType: 'text',
      };
      // this.newMessageData = {
      //   "senderId": "617815e82a3ecb5f876bbac9",
      //   "receiverId": "61779509d0f5990a0888791e",
      //   "message": "hii"
      // }
      console.log(this.newMessageData);
      this.chatService.sendMessage(this.newMessageData);
      this.newMessage = '';
      // this.getRecentChatContact(this.userId);
    }
  }

  // clear chat between admin and user
  clearChat() {
    let chatData = {
      senderId: this.userId,
      receiverId: this.receiverId,
    };
    console.log('clear chat', chatData);
    this.particularChat.messages = [];
    this.chatService.clearChat(chatData);
  }

  // // Get chat Id
  // individualChatId(chatId: any, userId: any) {
  //   this.showOnline();

  //   // alert('ddddd')
  //   this.chatId = chatId;
  //   this.chatUserId = userId;
  //   console.log('particular chat id', chatId);

  //   this.particularChatHistory('1');
  // }

  // async particularChatHistory(val: any) {
  //   this.showOnline();

  //   console.log('val????', JSON.stringify(val));
  //   this.massageChat = true;
  //   this.message = '';

  //   if (this.chatId == '') {
  //     console.log('no chat IdL');
  //     return;
  //   }

  //   let chatData = {
  //     chatUserType: 'ADMIN',

  //     adminChat: true,
  //     // chatId: this.chatId ? this.chatId : ''
  //     chatId: this.chatId ? this.chatId : '',
  //   };
  //   console.log('particularChatHistory to show 1????', this.chatId);
  //   this.socket.emit('particularChatHistory', chatData);
  //   await this.socket.fromEvent('particularChatHistory').subscribe(
  //     (response: any) => {
  //       if (response['responseCode'] == 200) {
  //         console.log(
  //           'in history chatasdasasdasasAAAAAA',
  //           response['chatList']
  //         );
  //         // console.log("i am userthis.chatUserId,val)
  //         if (val == '1') {
  //           console.log('145');
  //           this.particularChat = response['result'][0]['message'];
  //           this.allChatList = response['chatList'];

  //           // }
  //           // else {
  //           //   console.log("151")
  //           //   this.allChatList = response["chatList"]
  //         }
  //       } else {
  //         this.particularChat = [];
  //         this.allChatList = [];
  //         // this.mainservice.toastErr(response["responseMessage"])
  //       }
  //     },
  //     (err) => {
  //       this.mainservice.errorToast('Something went wrong');
  //     }
  //   );
  // }

  // open close section of new chat
  openGroup() {
    document.getElementById('new-group-id').style.width = '100%';
  }

  closeGroup() {
    document.getElementById('new-group-id').style.width = '0';
  }

  followinglistData: any = [];
  followingList() {
    // $('#following').modal('show');
    this.commonService.getApi('user/followingList', 1).subscribe(
      (res) => {
        console.log(res);
        if (res['responseCode'] == 200) {
          this.followinglistData = res['result']['docs'];
          // for (let item of this.followinglistData) {
          //   this.folowingListOption.push(true)
          // }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // chatWithNewUser(id) {
  //   console.log("chat with new user");
  //   this.closeGroup()
  //   // this.recentChatContact(id);
  //   this.viewChatHistory(id)
  // }

  chatWithNewUser(item) {
    var userNotFound = false;
    var temp = false;
    console.log('chat with new user');
    console.log(item);
    this.closeGroup();
    // this.recentChatContact(id);
    this.viewChatHistory(item.userId._id);

    if (this.recentChatContactList.length) {
      console.log('dhsfjjsjdjsjj', this.recentChatContactList.length);
      for (let data of this.recentChatContactList) {
        if (
          data?.receiverId?._id == item?.userId?._id ||
          data?.senderId?._id == item?.userId?._id
        ) {
          return;
        }
      }
    } else {
      this.recentChatContactList.push({
        receiverId: {
          fullName: item.userId.fullName,
          profilePic: item.userId.profilePic,
          userName: item.userId.userName,
          _id: item.userId._id,
        },
        senderId: {
          fullName: 'Stromstreet',
          profilePic: this.profileImageUrl,
          userName: 'strom@1',
          _id: this.userId,
        },
      });
    }

    //
    if (item.userId._id) {
      if (!temp) {
        this.recentChatContactList.forEach((element) => {
          console.log(element.receiverId._id, 'dhnsfgkjm', item.userId._id);
          console.log(element.senderId._id, 'dhnsfgkjm', item.userId._id);
          if (
            element.receiverId._id != item.userId._id &&
            element.senderId._id != item.userId._id
          ) {
            userNotFound = true;
          } else {
            temp = true;
            userNotFound = false;
            return;
          }
        });
      }
      if (userNotFound) {
        this.recentChatContactList.push({
          receiverId: {
            fullName: item.userId.fullName,
            profilePic: item.userId.profilePic,
            userName: item.userId.userName,
            _id: item.userId._id,
          },
          senderId: {
            fullName: 'Stromstreet',
            profilePic: this.profileImageUrl,
            userName: 'strom@1',
            _id: this.userId,
          },
        });
        console.log(userNotFound);
        console.log(this.recentChatContactList);
        //
      }
    }
    // push this user on contact list if not their
    // if(item.userId._id == this.recentChatContactList.receiverId._id ){
    //   if()
    // }
    // (this.recentChatContactList.receiverId._id == this.recentChatContactList.senderId._id) ?(this.recentChatContactList.push({
    //   'receiverId': {
    //     fullName: item.userId.fullName,
    //     profilePic: item.userId.profilePic,
    //     userName: item.userId.userName,
    //     _id: item.userId._id
    //   },
    //   'senderId': {
    //     fullName: "Stromstreet",
    //     profilePic: "https://res.cloudinary.com/mobiloitte-testing/image/upload/v1635837242/tnred0iehtnd6jeej5ir.jpg",
    //     userName: "strom@1",
    //     _id: this.userId
    //   }
    // })):return
    // this.userTappedShowOnHeader(item.userId.profilePic, item.userId.fullName)
    // console.log(this.recentChatContactList)
  }

  // get recent chat
  // recentChatContact(){

  // }

  // get chat history of admin and user
  viewChatHistory(id) {
    this.particularChat = [];
    // this.allChatList = [];
    this.receiverId = id;

    let chatData = {
      senderId: this.userId,
      receiverId: this.receiverId,
    };
    // let chatData = {
    //   "senderId": "617815e82a3ecb5f876bbac9",
    //   "receiverId": "61779509d0f5990a0888791e"
    // }
    console.log('Req data view chat', chatData);
    this.socket.emit('viewChat', chatData);
    this.getViewChatHistory(true);
    this.userTapped = true;
  }

  getViewChatHistory(id) {
    console.log('get view chat history');
    this.socket.fromEvent('viewChat').subscribe(
      (response: any) => {
        console.log('get view chat history =>', response);
        if (response['response_code'] == 200) {
          if (id == true) {
            this.particularChat = response['result'];
            // console.log(this.particularChat)
            this.scrollToBottom();
          }
        } else {
          this.particularChat = [];
          // this.allChatList = [];
        }
      },
      (err) => {
        this.commonService.errorToast('Something went wrong.');
      })
  }

  profilePic: any;
  tappedUserName: any;
  // show user on chat header
  userTappedShowOnHeader(profilePic, tappedUserName, chatId) {
    console.log(profilePic, tappedUserName, chatId);
    console.log(chatId);
    this.profilePic = profilePic;
    this.tappedUserName = tappedUserName;
    if (chatId == undefined) {
      console.log('ifffff ', chatId);
    } else {
      console.log('else ', chatId);
      this.chatId = chatId.messages[0].chatId;
    }

    console.log(this.chatId);
    console.log(chatId);

    // this.chatId = (chatId == undefined) ? '' : chatId.messages[0].chatId
  }

  searchRecentChat: any;
  tempArray: any = [];
  // search existing user
  serachRecentChatContact() {
    var searchRecentChat = String(this.searchRecentChat).toLowerCase();
    console.log(this.tempArray);

    this.recentChatContactList = this.recentChatContactList.filter((res) => {
      let senderName = String(res?.senderId?.fullName).toLowerCase();
      let recieverName = String(res?.receiverId?.fullName).toLowerCase();

      return (
        senderName.includes(searchRecentChat) ||
        recieverName.includes(searchRecentChat)
      );
    });
    console.log(
      '-=-=-=-=search result =-=-=-=-=-=-=',
      this.recentChatContactList
    );
  }
  backSpaceEvent(event) {
    if (event.keyCode === 8) {
      this.recentChatContactList = this.tempArray;
      this.serachRecentChatContact();
    }
  }

  ngOnDestroy() {
    console.log("destroy");

    let obj = {
      userId: this.userId,
      // userId: '617815e82a3ecb5f876bbac9'
    };
    console.log('onflineser->', obj);
    this.socket.emit('offlineUser', obj);
    this.chatService.disconnect();
    $("body>#addNewAddressModalId").remove();

  }

  selectedIndex: any;
  selectIndexInGroup(index) {
    this.selectedIndex = index
    // $('#dressTypeModal').appendTo("body").modal('show');
    $('#dressTypeModal').modal('show');
  }

  selectDressTypeInGroup(dressTypeDetails) {
    // this.ngZone.run(() => {


    console.log(dressTypeDetails);
    console.log(this.selectedIndex);
    let apiReqUrl = ApiEndPoint.selectIndex;
    let apiReqData = {
      groupId: this.groupId,
      numberOfIndex: this.selectedIndex
    }
    console.log(apiReqData);
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        // details of social shoping share with other page during routing
        let socialShopDetails = {
          groupId: this.groupId,
          numberOfIndex: this.selectedIndex,
          payType: res.result.payType
        }
        if (dressTypeDetails == 'CUSTOMIZE') {
          console.log('customize');
          // $('#dressTypeModal').appendTo("body").modal('hide');
          $('#dressTypeModal').modal('hide');
          this.router.navigate(['/dress-collection'], {
            queryParams: {
              socialShopDetails: JSON.stringify(socialShopDetails)
            }
          })
        } else {
          console.log('ready to ship');
          // $('#dressTypeModal').appendTo("body").modal('hide');
          $('#dressTypeModal').modal('hide');
          /**
           * navigate to ready to ship page with the following details
           * categoryId, subCategoryId, categoryName and subCategoryName
           * along with social shoping Deatils to difrentiate normal and social shoping 
           * with social shoping data
           */
          this.router.navigate(['/readytoship-bridal'], {
            queryParams: {
              categoryId: dressTypeDetails.parentCategory,
              subCategoryId: dressTypeDetails._id,
              categoryName: 'Ready To Ship',
              subCategoryName: dressTypeDetails.subCategoryName,
              socialShopDetails: JSON.stringify(socialShopDetails)
            }
          })
          // this.router.navigate(['/readytoship-bridal'], { queryParams: { socialShopDetails: JSON.stringify(socialShopDetails) } })
        }
      }
    }, (err: any) => {
      console.log(err);
      // $('#dressTypeModal').appendTo("body").modal('hide');
      $('#dressTypeModal').modal('hide');
      this.commonService.errorToast(err.responseMessage);
      this.productListInGroup(this.groupId)
    })
    // })
  }


  removeIndexInGroup(index) {
    console.log(index);
    let apiReqUrl = ApiEndPoint.removeIndex;
    let apiReqData = {
      'groupId': this.groupId,
      'numberOfIndex': index
    }
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.commonService.alertSuccess(res.responseMessage);
        this.productListInGroup(this.groupId)
      } else {
        this.commonService.alertError(res.responseMessage)
      }
    })
  }
  // /group/addProductInGroup
  // addProductInGroup() {
  //   let apiReqUrl = ApiEndPoint.addProductInGroup;
  //   // let apiReqData = {
  //   //   groupId: this.groupId,
  //   //   productId: '6242e211c0e6445fce7472ea',
  //   //   numberOfIndex: 1,
  //   // };
  //   let apiReqData = {
  //     groupId: this.groupId,
  //     productId: '6242e211c0e6445fce7472ea',
  //     numberOfIndex: 1,
  //   };
  //   console.log(apiReqData);

  //   this.commonService.showSpinner();
  //   this.commonService
  //     .postApi(apiReqUrl, apiReqData, 1)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       if (res['responseCode'] == 200) {
  //         this.commonService.hideSpinner();
  //         // this.groupId = res.result.groupId
  //         // this.followinglistData = res["result"]["docs"];
  //         // for (let item of this.followinglistData) {aa
  //         //   this.folowingListOption.push(true)
  //         // }
  //       } else {
  //         this.commonService.hideSpinner();
  //       }
  //     });
  // }

  productListInGroupDetails: any;
  // /group/productListInGroup
  productListInGroup(groupId) {
    // const indexes = [];
    let apiReqUrl = `${ApiEndPoint.productListInGroup + '?groupId=' + groupId}`;
    // let apiReqData = {
    //   'groupId': groupId
    // }
    this.commonService.getApi(apiReqUrl, 1).subscribe(
      (res: any) => {
        console.log(res);
        if (res['responseCode'] == 200) {
          this.productListInGroupDetails = res.result;
          let productIndex = [];
          let indexes = res.result.products.map((o) => o.numberOfIndex);
          // console.log('indexes', indexes);
          for (let i = 0; i < res.result.numberOfDresses; i++) {
            if (indexes.includes((i))) {
              let index = 0;
              //  for (let j = 0; j < res.result.products.length; j++) {
              //    if(res.result.products[j].numberOfIndex==i){
              //      index=j
              //      break
              //    }
              //  }
              index = res.result.products.findIndex(
                (o) => o.numberOfIndex == String(i)
              );
              productIndex[i] = res.result.products[index];
            } else {
              productIndex.push({});
            }
          }

          this.productIndex = productIndex;
          console.log('=====>replace', productIndex);

          // for (let i = 0; i < res.result.numberOfDresses; i++) {
          //   // this
          //   indexes.push(i);
          // }

          // res.result.productWithIndex = indexes
          // // res.result.products.
          // // let yy = indexes.map(x=> x == res.result.)
          // // res.result.productWithIndex = res.result.products.forEach(element => {

          // // });

          // console.log(this.productListInGroupDetails);

          // // this.followinglistData = res["result"]["docs"];
          // // for (let item of this.followinglistData) {
          // //   this.folowingListOption.push(true)
          // // }
        }
      },
      (err) => {
        console.log(err);
      })
  }

  addMember(groupId) {
    let apiReqUrl = `${ApiEndPoint.addMember + '?groupId=' + groupId}`;
    // let apiReqData = {
    //   'groupId': groupId
    // }
    this.commonService.getApi(apiReqUrl, 1).subscribe(
      (res) => {
        console.log(res);
        if (res['responseCode'] == 200) {
          this.productListInGroup(this.groupId);
          // this.followinglistData = res["result"]["docs"];
          // for (let item of this.followinglistData) {
          //   this.folowingListOption.push(true)
          // }
        }
      },
      (err) => {
        console.log(err);
      })
  }

  // Generate Fake Object Array
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  ReadyToShipModal() {
    $('#readyToShip').modal('show')
  }

  // /group/addProductInGroup
  // addProductInGroup(routeTo) {
  //   console.log(routeTo);

  //   let apiReqUrl = ApiEndPoint.addProductInGroup;
  //   let apiReqData = {
  //     groupId: this.data.groupId,
  //     productId: '',
  //     numberOfIndex: this.data.numberOfIndex
  //   };
  //   console.log(apiReqData);

  //   this.commonService.showSpinner();
  //   this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
  //     console.log(res);
  //     if (res['responseCode'] == 200) {
  //       this.commonService.hideSpinner();
  //       this.router.navigate([routeTo], { queryParams: { routeFrom: 'SOCIAL_SHOPING' } })
  //       // this.groupId = res.result.groupId
  //       // this.followinglistData = res["result"]["docs"];
  //       // for (let item of this.followinglistData) {aa
  //       //   this.folowingListOption.push(true)
  //       // }
  //     } else {
  //       this.commonService.hideSpinner();
  //     }
  //   });
  // }
  closeSelectDressModal() {
    $('#dressTypeModal').modal('hide');
  }

  headerList: any
  /**
     * userType are used in website
     * @param headerList header list -
     */
  headerListDetail() {
    this.commonService.showSpinner()
    this.commonService.getApi(ApiEndPoint.headerList, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.headerList = res.result
        let arr = res.result.filter(item =>
          item.parent.categoryName == 'Ready To Ship'
        );
        this.headerList = arr;
        console.log(this.headerList);
        this.commonService.hideSpinner()

      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);

      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }

  // proceed to checkout after selecting all the dresses
  proceedToCheckout(addressType) {
    console.log(addressType);
    // 'checkout-multiple-address'
    // 'checkout-select-address'
    // addressType
    // currencyCode
    let socialShopOrderDetails = {
      groupId: this.groupId,
      addressType: addressType
    }
    if (addressType == 'DELIVER_AT_SAME_ADDRESS') {
      this.router.navigate(['checkout-select-address'], { queryParams: { socialShopOrderDetails: JSON.stringify(socialShopOrderDetails) } })
    } else {
      this.router.navigate(['checkout-multiple-address'], { queryParams: { socialShopOrderDetails: JSON.stringify(socialShopOrderDetails) } })
    }
  }

  navigateToPayment(orderId, numberOfIndex) {
    this.router.navigate(['checkout-individual-pay'], { queryParams: { orderId: orderId, groupId: this.groupId, numberOfIndex: numberOfIndex, } })
  }


  /**
   * @param copyText copy any Text
   * */
  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.commonService.alertSuccess('Invitation link copied successfully');
  }
  type = 'facebook';
  type1 = 'whatsapp';
  type2 = 'snapchat';
  navUrl: string;
  typeMatch: any;

  private createNavigationUrl(typeMatch) {
    switch ((this.typeMatch = typeMatch)) {
      case 'facebook':
        this.navUrl = 'https://www.facebook.com/sharer/sharer.php?' + this.generatedLink;
        break;
      case 'whatsapp':
        this.navUrl = 'https://whatsapp.com/share?' + this.generatedLink;
        break;
      case 'snapchat':
        this.navUrl = 'https://snapchat.com/share?' + this.generatedLink;
        break;
    }
  }

  public share(type) {
    this.createNavigationUrl(type);
    return window.open(this.navUrl, '_blank');
  }
}
