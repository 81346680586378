import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-mask',
  templateUrl: './view-mask.component.html',
  styleUrls: ['./view-mask.component.scss']
})
export class ViewMaskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  imgSrc: any ="/assets/images/masks/mask1.jpeg"
  changeImg(src){
    this.imgSrc = src


  }
}
