<!-- start toolbar -->
<!-- <mat-toolbar class="toolbar_custom">
    <span>Home/</span><span>Product List</span>
  </mat-toolbar> -->
<!-- end toolbar -->

<!-- <div class="wrraper">
    <div class="container">
        <div class="table-responsive-sm table-responsive-md table-responsive-lg m-5">
            <div class="mb-3">
                <div class=" w-100 d-flex">
                    <div class="filter col-md-9 d-flex justify-content-between">
                        <input type="text" placeholder="Search" class="col-md-3 form-control"> 
                        <input type="date" placeholder="From" class="col-md-4 form-control">
                        <input type="date" placeholder="To" class="col-md-4 form-control">
                    </div>
                    <div class="put col-md-3 d-flex justify-content-between pr-0">
                        <button class="btn buttonGlobal col-md-6" style="height: 35px;">Search</button>
                        <button class="btn buttonGlobal col-md-4 border" style="height: 35px;">Reset</button>
                    </div>
                </div>
                <div class="w-100 text-right">
                    <button class="btn buttonGlobal mt-3">Add New Visitor</button>
                </div>
            </div>
               
        <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Product Name</th>
                <th scope="col">Product Image</th>
                <th scope="col">Consumer Name</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Category</th>
                <th scope="col">Delivery Date</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row" >1</td>
                <td class="font-custom">Top</td>
                <td class="font-custom"><img src="/assets/images/grid1.png" style="width: 100px;height: 70px;" alt=""></td>
                <td class="font-custom">Satyam</td>
                <td class="font-custom">9399874299</td>
                <td class="font-custom">Bridal Dress</td>
                <td class="font-custom">02/04/2022</td>
                <td class="font-custom">Out For Delivery</td>
                <td class="font-custom d-flex justify-content-around">
                    <button class="btn buttonGlobal" routerLink="/view-product">View</button>
                </td>
              </tr>
              <tr>
                <td scope="row" >1</td>
                <td class="font-custom">Top</td>
                <td class="font-custom"><img src="/assets/images/grid2.png" style="width: 100px;height: 70px;" alt=""></td>
                <td class="font-custom">Abdul</td>
                <td class="font-custom">9876543212</td>
                <td class="font-custom">Bridal Dress</td>
                <td class="font-custom">02/04/2022</td>
                <td class="font-custom">Out For Delivery</td>
                <td class="font-custom d-flex justify-content-around">
                    <button class="btn buttonGlobal" routerLink="/view-product">View</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</div> -->


<!-- start toolbar -->
<!-- <mat-toolbar class="toolbar_custom">
    <span>Home/</span><span>Visitor List</span>
  </mat-toolbar> -->
<!-- end toolbar -->

<!-- <div class="wrraper">
  <div class="container">
    <div class="table-responsive-sm table-responsive-md table-responsive-lg m-5">
      <div class="mb-3">
        <div class=" w-100 d-flex">
          <div class="filter col-md-9 d-flex justify-content-between">
            <input type="text" placeholder="Search" class="col-md-3 form-control">
            <input type="date" placeholder="From" class="col-md-4 form-control">
            <input type="date" placeholder="To" class="col-md-4 form-control">
          </div>
          <div class="put col-md-3 d-flex justify-content-between pr-0">
            <button class="btn buttonGlobal col-md-6" style="height: 35px;">Search</button>
            <button class="btn buttonGlobal col-md-4 border" style="height: 35px;">Reset</button>
          </div>
        </div>
        <div class="w-100 text-right">
          <button class="btn buttonGlobal mt-3">Add New Visitor</button>
        </div>
      </div>

      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Name</th>
            <th scope="col">Email ID</th>
            <th scope="col">Mobile Number</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">1</td>
            <td class="font-custom">Satyam</td>
            <td class="font-custom">satyam@gmail.com</td>
            <td class="font-custom">9399874299</td>
            <td class="font-custom">Active</td>
            <td class="font-custom d-flex justify-content-around">
              <button class="btn buttonGlobal" routerLink="/view-visitor">View</button>
              <button class="btn buttonGlobal">Edit</button>
              <button class="btn buttonGlobal">Delete</button>
            </td>
          </tr>
          <tr>
            <td scope="row">2</td>
            <td class="font-custom">Abdul</td>
            <td class="font-custom">Abdul@gmail.com</td>
            <td class="font-custom">9865321245</td>
            <td class="font-custom">Inactive</td>
            <td class="font-custom d-flex justify-content-around">
              <button class="btn buttonGlobal" routerLink="/view-visitor">View</button>
              <button class="btn buttonGlobal">Edit</button>
              <button class="btn buttonGlobal">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div> -->


<!-- my order copy -->
<!-- <div class="container"> -->
<div class="content">

    <!-- <div class="order-details">
            <span class="header-text">Order Details</span>
          </div> -->
    <div class="top-text text-center">
        <mat-card-title class="mat-card-title sign-text ml-0 title-color-dark">Orders Management</mat-card-title>
    </div>
    <!-- filter -->
    <div class="filter-section mb-3 mt-3">
        <div class=" w-100 d-flex">
            <div class="filter col-md-9 d-flex justify-content-between">
                <input type="text" placeholder="Search" class="col-md-3 form-control">
                <input type="date" placeholder="From" class="col-md-4 form-control">
                <input type="date" placeholder="To" class="col-md-4 form-control">
            </div>
            <div class="put col-md-3 d-flex justify-content-between pr-0">
                <button class="btn buttonGlobal col-md-6" style="height: 45px;">Search</button>
                <button class="btn buttonGlobal col-md-4 border" style="height: 45px;">Reset</button>
            </div>
        </div>
        <!-- <div class="w-100 text-right">
        <button class="btn buttonGlobal mt-3">Add New Visitor</button>
      </div> -->
    </div>
    <!-- end filter -->
    <table class="cart">
        <thead>
            <tr>
                <th class="cart__row-price header-text" scope="col">S.No.</th>
                <th class="cart__row-price header-text" scope="col">Product Name</th>
                <th class="cart__row-price header-text" scope="col">Product Image</th>
                <th class="cart__row-price header-text" scope="col">Consumer Name</th>
                <th class="cart__row-price header-text" scope="col">Mobile Number</th>
                <th class="cart__row-price header-text" scope="col">Category</th>
                <th class="cart__row-price header-text" scope="col">Delivery Date</th>
                <th class="cart__row-price header-text" scope="col">Status</th>
                <th class="cart__row-price header-text" scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="cart__meta small--text-left">
                    <p>1</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>Top</p>
                </td>
                <td class="cart__meta small--text-left">
                    <img src="../../../../../assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Front.jpg"
                        style="width: 100px;height: 70px;" alt="">
                </td>
                <td class="cart__meta small--text-left">
                    <p>Jon</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>9399874299</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>Bridal Dress</p>
                </td>
                <!-- <td class="cart__meta small--text-left">
          <p>9399874299</p>
        </td> -->
                <td class="cart__meta small--text-left">
                    <p>02/04/2022</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>Out For Delivery</p>
                </td>
                <td class="cart__meta small--text-left">
                    <div class="font-custom d-flex">
                        <button class="btn btn-sm-custom" (click)="viewSingleAddressModal()">View</button>
                        <!-- <button class="btn btn-sm-custom">Edit</button>
            <button class="btn btn-sm-custom">Delete</button> -->
                    </div>
                </td>
            </tr>
            <tr>
                <td class="cart__meta small--text-left">
                    <p>1</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>Top</p>
                </td>
                <td class="cart__meta small--text-left">
                    <img src="../../../../../assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Front.jpg"
                        style="width: 100px;height: 70px;" alt="">
                </td>
                <td class="cart__meta small--text-left">
                    <p>Jon</p>
                </td>
                <!-- <td class="cart__meta small--text-left">
          <p>9399874299</p>
        </td> -->
                <td class="cart__meta small--text-left">
                    <p>Bridal Dress</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>9399874299</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>02/04/2022</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>Out For Delivery</p>
                </td>
                <td class="cart__meta small--text-left">
                    <div class="font-custom d-flex">
                        <button class="btn btn-sm-custom" (click)="viewMultipleAddressModal()">View</button>
                        <!-- <button class="btn btn-sm-custom">Edit</button>
            <button class="btn btn-sm-custom">Delete</button> -->
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- view my retailer order product modal -->
<div class="modal fade" id="viewRetailerOrderProductModalId" tabindex="-1" aria-labelledby="bagModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered retailer-dlg">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Order
                    Details
                </h2>
                <!-- <div class="form-element-wrapper"> -->
                <!-- <div class="border-btn-wrapper">
                      <button class="submit-form-btn buttonGlobal btn-add-cust" id="submit-cart-modal">
                          + Add
                      </button>
                  </div> -->
                <!-- </div> -->
                <div class="">
                    <!-- <div class="hidden-desktop">
                    <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                </div> -->

                    <div class="container bootstrap snippets bootdey">
                        <div class="panel panel-default">
                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 text-left">
                                        <h4 class="title-color-dark"><strong>Client Details </strong></h4>
                                        <ul class="list-unstyled">
                                            <li><strong>First Name:</strong> John</li>
                                            <li><strong>Last Name:</strong> Doe</li>
                                            <li><strong>Country:</strong> U.S.A.</li>
                                            <li><strong>DOB:</strong> YYYY/MM/DD</li>
                                        </ul>
                                    </div>

                                    <div class="col-md-6 col-sm-6 text-right">
                                        <h4 class="title-color-dark"><strong>Payment Details </strong></h4>
                                        <ul class="list-unstyled">
                                            <li><strong>Bank Name:</strong> 012345678901</li>
                                            <li><strong>Account Number:</strong> 012345678901</li>
                                            <li><strong>SWIFT Code:</strong> SWITCH012345678CODE</li>
                                            <li><strong>V.A.T Reg #:</strong> VAT5678901CODE</li>
                                        </ul>

                                    </div>

                                </div>

                                <div class="table-responsive">
                                    <table class="table table-condensed nomargin">
                                        <thead>
                                            <tr class="title-color-dark">
                                                <th>Item Name</th>
                                                <th>Quantity</th>
                                                <th>Unit Price</th>
                                                <th>VAT</th>
                                                <th>Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <!-- <figure> -->
                                                    <!-- <p>
                                                          <span matBadge="4" matBadgeOverlap="false">Text with a badge</span>
                                                      </p> -->
                                                    <!-- </figure> -->
                                                    <div class="title-color-dark">
                                                        <img src="assets/images/other/bridal.jpg" alt=""
                                                            class="first-img-list">


                                                        <strong class="teet">Unique side and front panel design</strong>
                                                        Black
                                                        <!-- <p>Black</p> -->
                                                    </div>
                                                </td>
                                                <td>2</td>
                                                <td>$20.00</td>
                                                <td>$3.78</td>
                                                <td>$23,78</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img src="assets/images/other/bridal.jpg" alt=""
                                                            class="first-img-list">


                                                        <strong class="teet">Unique side and front panel design</strong>
                                                        Black
                                                        <!-- <p>Black</p> -->
                                                    </div>
                                                </td>
                                                <td>2</td>
                                                <td>$67.00</td>
                                                <td>$1.80</td>
                                                <td>$68.80</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img src="assets/images/other/bridal.jpg" alt=""
                                                            class="first-img-list">


                                                        <strong class="teet">Unique side and front panel design</strong>
                                                        Black
                                                        <!-- <p>Black</p> -->
                                                    </div>
                                                </td>
                                                <td>3</td>
                                                <td>$335.00</td>
                                                <td>$100.20</td>
                                                <td>$435.20</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <hr class="nomargin-top">
                                <div class="row">
                                    <div class="col-sm-6 text-left">
                                        <h4 class="title-color-dark"><strong>Contact Details </strong></h4>
                                        <p class="nomargin nopadding">
                                            <strong>Note:</strong> Lid est laborum dolo rumes fugats.
                                        </p><br>

                                        <!-- no P margin for printing - use <br> instead -->

                                        <address>
                                            PO Box 21132 <br>
                                            Vivas 2355 Australia<br>
                                            Phone: 1-800-565-2390 <br>
                                            Fax: 1-800-565-2390 <br>
                                            Email:moteemaids@abc.com
                                        </address>
                                    </div>

                                    <div class="col-sm-6 text-right">
                                        <ul class="list-unstyled">
                                            <li><strong>Sub - Total Amount:</strong> $2162.00</li>
                                            <li><strong>Discount:</strong> 10.0%</li>
                                            <li><strong>VAT ($6):</strong> $12.0</li>
                                            <li><strong>Grand Total:</strong> $1958.0</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>


                <!-- <div class="form-element-wrapper">
                  <div class="border-btn-wrapper">
                      <div class="submit-form-btn buttonGlobal" id="submit-cart-modal" data-dismiss="modal">
                          Submit
                      </div>
                  </div>
              </div> -->


            </div>
        </div>
    </div>
</div>

<!-- end view retailer order product modal -->
<!-- view retailer multiple address order product modal -->
<div class="modal fade" id="viewRetailerMultipleAddressOrderProductModalId" tabindex="-1"
    aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered retailer-dlg">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Order
                    Details
                </h2>
                <!-- <div class="form-element-wrapper"> -->
                <!-- <div class="border-btn-wrapper">
                      <button class="submit-form-btn buttonGlobal btn-add-cust" id="submit-cart-modal">
                          + Add
                      </button>
                  </div> -->
                <!-- </div> -->
                <div class="">
                    <!-- <div class="hidden-desktop">
                    <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                </div> -->

                    <div class="container bootstrap snippets bootdey">
                        <div class="panel panel-default">
                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 text-left">
                                        <h4 class="title-color-dark"><strong>Client Details </strong></h4>
                                        <ul class="list-unstyled">
                                            <li><strong>First Name:</strong> John</li>
                                            <li><strong>Last Name:</strong> Doe</li>
                                            <!-- <li><strong>Country:</strong> U.S.A.</li> -->
                                            <li><strong>DOB:</strong> YYYY/MM/DD</li>
                                        </ul>
                                    </div>

                                    <div class="col-md-6 col-sm-6 text-right">
                                        <h4 class="title-color-dark"><strong>Payment Details </strong></h4>
                                        <ul class="list-unstyled">
                                            <li><strong>Bank Name:</strong> 012345678901</li>
                                            <li><strong>Account Number:</strong> 012345678901</li>
                                            <li><strong>SWIFT Code:</strong> SWITCH012345678CODE</li>
                                            <li><strong>V.A.T Reg #:</strong> VAT5678901CODE</li>
                                        </ul>

                                    </div>

                                </div>

                                <div class="table-responsive">
                                    <table class="table table-condensed nomargin">
                                        <thead>
                                            <tr class="title-color-dark">
                                                <th>Item Name</th>
                                                <th>Address</th>
                                                <th>Quantity</th>
                                                <th>Unit Price</th>
                                                <th>VAT</th>
                                                <th>Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img src="assets/images/other/bridal.jpg" alt=""
                                                            class="first-img-list">


                                                        <strong class="teet">Unique side and front panel design</strong>
                                                        Black
                                                        <!-- <p>Black</p> -->
                                                    </div>
                                                </td>
                                                <td>okhla phase 1 delhi</td>
                                                <td>2</td>
                                                <td>$20.00</td>
                                                <td>$3.78</td>
                                                <td>$23,78</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img src="assets/images/other/bridal.jpg" alt=""
                                                            class="first-img-list">


                                                        <strong class="teet">Unique side and front panel design</strong>
                                                        Black
                                                        <!-- <p>Black</p> -->
                                                    </div>
                                                </td>
                                                <td>okhla phase 1 delhi</td>

                                                <td>2</td>
                                                <td>$67.00</td>
                                                <td>$1.80</td>
                                                <td>$68.80</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img src="assets/images/other/bridal.jpg" alt=""
                                                            class="first-img-list">


                                                        <strong class="teet">Unique side and front panel design</strong>
                                                        Black
                                                        <!-- <p>Black</p> -->
                                                    </div>
                                                </td>
                                                <td>okhla phase 1 delhi</td>

                                                <td>3</td>
                                                <td>$335.00</td>
                                                <td>$100.20</td>
                                                <td>$435.20</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <hr class="nomargin-top">
                                <div class="row">
                                    <div class="col-sm-6 text-left">
                                        <h4 class="title-color-dark"><strong>Contact Details </strong> </h4>
                                        <p class="nomargin nopadding">
                                            <strong>Note:</strong> Lid est laborum dolo rumes fugats.
                                        </p><br>
                                        <!-- no P margin for printing - use <br> instead -->

                                        <address>
                                            PO Box 21132 <br>
                                            Vivas 2355 Australia<br>
                                            Phone: 1-800-565-2390 <br>
                                            Fax: 1-800-565-2390 <br>
                                            Email:moteemaids@abc.com
                                        </address>
                                    </div>

                                    <div class="col-sm-6 text-right">
                                        <ul class="list-unstyled">
                                            <li><strong>Sub - Total Amount:</strong> $2162.00</li>
                                            <li><strong>Discount:</strong> 10.0%</li>
                                            <li><strong>VAT ($6):</strong> $12.0</li>
                                            <li><strong>Grand Total:</strong> $1958.0</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>


                <!-- <div class="form-element-wrapper">
                  <div class="border-btn-wrapper">
                      <div class="submit-form-btn buttonGlobal" id="submit-cart-modal" data-dismiss="modal">
                          Submit
                      </div>
                  </div>
              </div> -->


            </div>
        </div>
    </div>
</div>

<!-- end view retailer multiple address order product modal -->