<div class="content" style="min-height: 800px;">
    <div class="top-text text-center">
        <mat-card-title class="mat-card-title sign-text ml-0 title-color-dark">My Address</mat-card-title>
    </div>
    <div class="shipping mt-4">
        <div class="border-btn-wrapper">
            <button class="submit-form buttonGlobal btn-add-cust" id="submit-cart-modal" (click)="addAddressModal()">
                + Add New Address
            </button>
        </div>
        <div class="">
            <div class="image-wrapper">
                <div class="data-details">
                    <div class="address-card-box-shadow" *ngFor="let item of listAddressArray">
                        <i class="fa fa-pencil-square-o" aria-hidden="true" style="float: right;padding: 4px;"
                            (click)="viewAddressModal(item?._id)"></i>
                        <div class="row mt-3">
                            <div class="col-xl-1 col-lg-2  col-md-3 col-sm-3">
                                <div class="align-checkbox-vertical-center">
                                    <input class="form-check-input" type="radio" name="exampleRadios"
                                        id="exampleRadios1" value="option1">
                                </div>
                            </div>
                            <div class="col-xl-11 col-lg-10 col-md-9 col-sm-9">
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <p class="product-name title-color-dark" style="word-break: break-all;">
                                            {{item?.address}}</p>
                                        <p class="title-color-dark">{{item?.state}} {{item?.city}} {{item?.zipCode}}</p>
                                        <p class="title-color-dark m-0">{{getCountry(item?.country)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i class="fa fa-trash trsh" aria-hidden="true" (click)="deleteAddressModal(item?._id)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- add new address modal -->
<div class="modal fade" id="addNewAddressModalId" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">New
                    Address!
                </h2>

                <div class="">
                    <form [formGroup]="addAddressForm">
                        <div class="image-wrapper">

                            <div class="data-details">
                                <div class="row mt-2">
                                    <div class="col-md-12">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Address</mat-label>
                                            <input matInput placeholder="Address" formControlName="address"
                                                maxlength="60">
                                        </mat-form-field>
                                        <div class="text-danger"
                                            *ngIf="(addAddressForm.get('address').invalid && (addAddressForm.get('address').touched || addAddressForm.get('address').dirty || isSubmitted));">
                                            <span *ngIf="addAddressForm.get('address').errors.required">
                                                *Address is required.
                                            </span>
                                            <span *ngIf="addAddressForm.get('address').errors.pattern">
                                                *Please enter valid address.
                                            </span>
                                        </div>
                                        <!-- <span class="error"
                                            *ngIf="addAddressForm.get('address').hasError('required') && (addAddressForm.get('address').dirty || addAddressForm.get('address').touched || isSubmitted)">*Please
                                            enter address.</span> -->
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Country</mat-label>
                                            <mat-select (selectionChange)="stateList($event)" formControlName="country">
                                                <mat-option *ngFor="let item of countryListArray"
                                                    [value]="item?.countryCode">{{item?.countryName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div class="text-danger"
                                            *ngIf="(addAddressForm.get('country').invalid && (addAddressForm.get('country').touched || addAddressForm.get('country').dirty || isSubmitted));">
                                            <span *ngIf="addAddressForm.get('country').errors.required">
                                                *Country is required.
                                            </span>
                                        </div>
                                        <!-- <span class="error"
                                            *ngIf="addAddressForm.get('country').hasError('required') && (addAddressForm.get('country').dirty || addAddressForm.get('country').touched || isSubmitted)">*Please
                                            enter country.</span> -->

                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>State</mat-label>
                                            <mat-select formControlName="state">
                                                <mat-option *ngFor="let item of stateListArray"
                                                    [value]="item?.stateName">{{item?.stateName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div class="text-danger"
                                            *ngIf="(addAddressForm.get('state').invalid && (addAddressForm.get('state').touched || addAddressForm.get('state').dirty || isSubmitted));">
                                            <span *ngIf="addAddressForm.get('state').errors.required">
                                                *State is required.
                                            </span>
                                        </div>
                                        <!-- <span class="error"
                                            *ngIf="addAddressForm.get('state').hasError('required') && (addAddressForm.get('state').dirty || addAddressForm.get('state').touched || isSubmitted)">*Please
                                            enter state.</span> -->

                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>City</mat-label>
                                            <input matInput placeholder="City" formControlName="city" maxlength="60">
                                        </mat-form-field>
                                        <!-- <span class="error"
                                            *ngIf="addAddressForm.get('city').hasError('required') && (addAddressForm.get('city').dirty || addAddressForm.get('city').touched || isSubmitted)">*Please
                                            enter city.</span> -->
                                        <div class="text-danger"
                                            *ngIf="(addAddressForm.get('city').invalid && (addAddressForm.get('city').touched || addAddressForm.get('city').dirty || isSubmitted));">
                                            <span *ngIf="addAddressForm.get('city').errors.required">
                                                *City is required.
                                            </span>
                                            <span *ngIf="addAddressForm.get('city').errors.pattern">
                                                *Please enter valid city.
                                            </span>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Zip Code</mat-label>
                                            <input matInput placeholder="Zip Code" formControlName="zipCode"
                                                maxlength="10">
                                        </mat-form-field>
                                        <div class="text-danger"
                                            *ngIf="(addAddressForm.get('zipCode').invalid && (addAddressForm.get('zipCode').touched || addAddressForm.get('zipCode').dirty || isSubmitted));">
                                            <span *ngIf="addAddressForm.get('zipCode').errors.required">
                                                *Zip code is required.
                                            </span>
                                            <span *ngIf="addAddressForm.get('zipCode').errors.pattern">
                                                *Please enter valid zip code.
                                            </span>
                                        </div>
                                        <!-- <span class="error"
                                            *ngIf="addAddressForm.get('zipCode').hasError('required') && (addAddressForm.get('zipCode').dirty || addAddressForm.get('zipCode').touched || isSubmitted)">*Please
                                            enter zip code.</span> -->

                                    </div>
                                </div>



                            </div>


                        </div>
                    </form>
                </div>


                <div class="form-element-wrapper">
                    <div class="border-btn-wrapper">
                        <button type="button" class="submit-form-btn buttonGlobal" id="submit-cart-modal"
                            (click)="addAddress()">
                            Submit
                        </button>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<!-- end add address modal -->

<!-- edit my address -->
<div class="modal fade" id="viewAddress" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Edit
                    Address!
                </h2>

                <div class="">
                    <form [formGroup]="editAddressFrom">
                        <div class="image-wrapper">

                            <div class="data-details">
                                <div class="row mt-2">
                                    <div class="col-md-12">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Address</mat-label>
                                            <input matInput placeholder="Address" formControlName="editAddress"
                                                maxlength="256">
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Country</mat-label>
                                            <mat-select (selectionChange)="stateList($event)"
                                                formControlName="editCountry">
                                                <mat-option *ngFor="let item of countryListArray"
                                                    [value]="item?.countryCode">{{item?.countryName}}

                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>State</mat-label>
                                            <mat-select formControlName="editState">
                                                <mat-option *ngFor="let item of stateListArray"
                                                    [value]="item?.stateName">{{item?.stateName}}

                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>City</mat-label>
                                            <input matInput placeholder="City" formControlName="editCity"
                                                maxlength="60">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Zipcode</mat-label>
                                            <input matInput placeholder="Zipcode" formControlName="editZipCode"
                                                maxlength="10">
                                        </mat-form-field>
                                    </div>
                                </div>



                            </div>


                        </div>
                    </form>
                </div>


                <div class="form-element-wrapper">
                    <div class="border-btn-wrapper">
                        <button type="button" class="submit-form-btn buttonGlobal" id="submit-cart-modal"
                            (click)="editAddress()" [disabled]="!editAddressFrom.valid">
                            Update
                        </button>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>
<!-- end edit modal -->
<!-- delete address -->
<div class="modal fade" id="deleteAddress" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>
            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Delete
                    Address!
                </h2>
                <div class="">
                    <div class="image-wrapper">
                        <div class="data-details">
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <mat-catd>
                                        <h3 class="text-center">Are you sure you want to delete this address?</h3>
                                    </mat-catd>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-element-wrapper m-0">
                    <div class="new-style-logout">
                        <div class="submit-logout buttonGlobal new-cls-no" id="submit-cart-modal" data-dismiss="modal">
                            No
                        </div>
                        <div class="submit-logout buttonGlobal" id="submit-cart-modal" data-dismiss="modal"
                            (click)="deleteAddress()">
                            Yes
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end delete address -->