<!-- signin Modal -->
<div class="wrraper back-pic">
    <div class="login_top_details">
  
      <mat-card class="mat-elevation-z0 bdr">
          <div class="main">
              <div class="top-text text-center">
                  <!-- <img class="logo" mat-card-image src="../../../../assets/images/loginLogo.png" alt="logo"> -->
                  <mat-card-title class="sign-text new-style">RESET PASSWORD
                  </mat-card-title>
                  <p class="text-center textAfterloginHeading header-text">Create your new password.</p>
              </div>
              <div class="input-field">
                  <form [formGroup]="resetPasswordForm">
                      <div class="row">
                      <!-- email Field -->
                      <div class="col-md-12">
                          <label for="" class="top_heading_lab header-text">Enter Your New Password :</label>
                      <input type="text" class="user-name" placeholder="Enter new password" formControlName='newPassword' maxlength="60" [type]="toggleEye1 ? 'password' : 'text'">
                      <!-- eye Password Protector -->
                      <mat-icon matSuffix (click)="toggleEye1 = !toggleEye1">{{toggleEye1 ? 'visibility_off' : 'visibility'}}
                      </mat-icon>
                      <!-- validation -->
                      <span class="error" *ngIf="resetPasswordForm.get('newPassword').hasError('required') && (resetPasswordForm.get('newPassword').touched ||resetPasswordForm.get('newPassword').dirty || isSubmittedForm)">
                          *Password is required.</span>
                      <span class="error" *ngIf="resetPasswordForm.get('newPassword').hasError('pattern') && (resetPasswordForm.get('newPassword').touched||resetPasswordForm.get('newPassword').dirty)">
                          *Please enter valid password.</span>
                      </div>
                      <div class="col-md-12">
                      <!-- Password Field -->
                      <label for="" class="top_heading_lab header-text">Re-Enter New Password :
  
                      </label>
                      <input type="password" class="user-password" [type]="toggleEye ? 'password' : 'text'" placeholder="Re-enter password" formControlName='confirmPassword' maxlength="32">
                      <!-- eye Password Protector -->
                      <mat-icon matSuffix (click)="toggleEye = !toggleEye">{{toggleEye ? 'visibility_off' : 'visibility'}}
                      </mat-icon>
                      <!-- validation -->
                      <!-- <span class="error"
                          *ngIf="resetPasswordForm.get('confirmPassword').hasError('required') && (resetPasswordForm.get('confirmPassword').touched ||resetPasswordForm.get('confirmPassword').dirty)">
                          *Confirm password is required.</span> -->
                      <span class="error" *ngIf="(resetPasswordForm.controls['newPassword'].value != (resetPasswordForm.controls['confirmPassword'].value || isSubmittedForm) && (resetPasswordForm.get('confirmPassword').touched || resetPasswordForm.get('confirmPassword').dirty || isSubmittedForm))">
                          *Password and confirm password should be match.</span>
                      </div>
                      <!-- button -->
                  </div>
                      <div class="down-button">
                          <button mat-button class="buttonGlobal col-md-7" (click)="resetPassword()">Reset Password</button>
                      </div>
  
                  </form>
  
              </div>
  
  
          </div>
      </mat-card>
      </div>
  </div>