import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-reset-password-influecer',
  templateUrl: './reset-password-influecer.component.html',
  styleUrls: ['./reset-password-influecer.component.scss']
})
export class ResetPasswordInfluecerComponent implements OnInit {
  isSubmittedForm:boolean = false
  toggleEye: boolean = true;  // toggle eye
  toggleEye1: boolean = true; //toggle eye1
  vertical: any = "true";
  resetPasswordForm: FormGroup; //form name
  email:any;
  constructor(private activatedroute: ActivatedRoute,public commonService: CommonService, private router:Router) { }

  ngOnInit(): void {
    this.activatedroute.queryParams.subscribe((res)=>{
      this.email = res.email
    })
    this.formValidation()
  }

  formValidation(){
 // Form group and Form Control Name With Validations
 this.resetPasswordForm = new FormGroup({
  newPassword: new FormControl("", [
    Validators.required,
    Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i),
    Validators.maxLength(30),
  ]),
  confirmPassword: new FormControl("", [
    Validators.required,
    Validators.maxLength(32),
  ]),
});
  }


  /**
   * @private Call put api of reset password
   */
   resetPassword() {
     if(this.resetPasswordForm.invalid || (this.resetPasswordForm.controls['newPassword'].value != this.resetPasswordForm.controls['confirmPassword'].value) ){
       this.isSubmittedForm = true
       return
     }
    let apiReqUrl = ApiEndPoint.resetPassword
    let reqData = {
       email:this.email,
      'newPassword' : this.resetPasswordForm.value.newPassword
    }
    // var email = localStorage.getItem('token')
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, reqData, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.router.navigate(['/influencer-login'])
        // localStorage.removeItem("token")
        // this.commonService.successToast(res.responseMessage);
        Swal.fire({
          icon: 'success',
          text: res.responseMessage,
        })
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }
}
