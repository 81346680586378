import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Socket } from 'ngx-socket-io';
// import { ObserveOnOperator } from 'rxjs/internal/operators/observeOn';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private socket: Socket) { }

  // -------- send message ------------ //
  // public sendMessage(message) {
  //  return this.socket.emit('oneToOneChat', message);
  //  // this.socket.emit('chattingAPI', message);

  // }
  
 // -------- send message ------------ //
 public sendMessage(message) {
  return this.socket.emit('groupChat', message);
  // this.socket.emit('chattingAPI', message);

 }
  // -------- receive message ----------//
  public getMessage = () => {
    return new Observable((observer: Observer<string>) => {
      this.socket.on('groupChat', (message) => {
         console.log("message......",message)
        //this.socket.on('chattingAPI', (message) => {
        observer.next(message);
      })
      // observer.complete();
    });
    // return Observable.create((observer) => {
    //    this.socket.on('oneToOneChat', (message) => {
    //      console.log("meaasge",message)
    //     //this.socket.on('chattingAPI', (message) => {
    //     observer.next(message);
    //   })
    // })
  }

  // // -------- receive message ----------//
  // public getMessage = () => {
  //   return new Observable((observer: Observer<string>) => {
  //     this.socket.on('oneToOneChat', (message) => {
  //        console.log("message......",message)
  //       //this.socket.on('chattingAPI', (message) => {
  //       observer.next(message);
  //     })
  //     // observer.complete();
  //   });
  //   // return Observable.create((observer) => {
  //   //    this.socket.on('oneToOneChat', (message) => {
  //   //      console.log("meaasge",message)
  //   //     //this.socket.on('chattingAPI', (message) => {
  //   //     observer.next(message);
  //   //   })
  //   // })
  // }

  // -------- receive message ----------//
  public getRecentChatList = () => {
    return new Observable((observer: Observer<string>) => {
      this.socket.on('chatHistory', (message) => {
         console.log("message......",message)
        //this.socket.on('chattingAPI', (message) => {
        observer.next(message);
      })
      // observer.complete();
    });
    // return Observable.create((observer) => {
    //    this.socket.on('oneToOneChat', (message) => {
    //      console.log("meaasge",message)
    //     //this.socket.on('chattingAPI', (message) => {
    //     observer.next(message);
    //   })
    // })
  }


  // -------- send message ------------ //
  public sendOnlineUserList(message) {
    console.log('getOnlineUserDetails');
    return this.socket.emit('onlineUserDetails', message);
    // this.socket.emit('chattingAPI', message);

  }

  // -------- receive message ----------//
  public getOnlineUserList = () => {
    return new Observable((observer: Observer<string>) => {
      this.socket.on('onlineUserDetails', (message) => {
        console.log("onlineUserDetails message......", message)
        //this.socket.on('chattingAPI', (message) => {
        observer.next(message);
      })
      // observer.complete();
    });
    // return Observable.create((observer) => {
    //    this.socket.on('oneToOneChat', (message) => {
    //      console.log("meaasge",message)
    //     //this.socket.on('chattingAPI', (message) => {
    //     observer.next(message);
    //   })
    // })
  }

  // ---------- chat history ------------ //
  public chatHistory(data) {

    return    this.socket.fromEvent('chatHistory').subscribe(
      (response: any) => {
        console.log('getChatHistory 1111=>', response);
      });
  }

  // ---------- chat list --------------- //
  public chatList(data) {
    console.log(data)
    this.socket.emit('chatList', data)
    return this.socket.fromEvent('chatList')
  }

  // ------- disconnect shat socket ----- //
  public disconnect() {
    this.socket.disconnect();
  }


  //---view chat-----------//
  public viewChat(data) {
    console.log(data)
    this.socket.emit('viewChat', data)
    return this.socket.fromEvent('viewChat')
  }

  //------------clear chat--------//
  
  public clearChat(data) {
    console.log(data)
    this.socket.emit('clearChat', data)
    return this.socket.fromEvent('clearChat')
  }

  //----------Online user-------//
  
  public onlineUser(data) {
    console.log(data)
    this.socket.emit('onlineUser', data)
    return this.socket.fromEvent('onlineUser')
    
  }
}
