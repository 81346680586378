<!-- <h1 mat-dialog-title>Favorite Animal</h1> -->
<mat-card>
<div class="first_text_details">
  <!-- My favorite animal is: -->
  <!-- <div class="modal-header justify-content-end border-0">
    <h2 class="modal-title" id="staticBackdropLabel">The item has been successfully added:</h2>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <div>
        <i class="fa fa-times bg-dark text-light"data-dismiss="modal" aria-label="Close"></i>
    </div>
  </div> -->
 <img class="details_image" src="/assets/images/grid5.png" alt="">
</div>
</mat-card>
<!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
