<div class="wrapper">
    <div class="staticHeading text-center">
        <h1 class="headingTitle new-style">Questions, concerns?</h1>
    </div>
    <div class="termscontent">
        <div class="privacyDivs">
            <h1 class="new-style">Enjoy direct access to our customer service and styling team </h1>
            <p class="header-text">
                Please email us at <a class="text-decoration-underline" href="mailto:customerservice@moteemaids.com">customerservice@moteemaids.com</a> or call / text 212-583-1700
            </p>
            <p class="header-text"> Our Customer Service hours are Monday - Friday 9am - 6pm.</p>
            <br>
            <p class="header-text">Please ensure you visit our <a class="text-decoration-underline" href="/faq">FAQ page</a> for more information.</p>
            <p class="header-text">Email us <a href="mailto:flagship@moteemaids.com">flagship@moteemaids.com</a></p>
        </div>
  <br>
  <div class="privacyDivs">
      <h1 class="new-style">Need to arrange a return?</h1>
      <p class="header-text">Dresses in our Little White Dress and Evening Collections are eligible for return or exchange within 14 days of receiving. We request a $25 restocking fee per dress if returned. Please click here to receive a shipping label.</p>
      <p class="header-text">Our Bridal and Bridesmaids dresses are not eligible for return, and are final sale.</p>
      <p class="header-text">For any additional questions, please email us at <a href="mailto:customerservice@moteemaids.com">customerservice@moteemaids.com</a> or call / text 212-583-1700</p>
  </div>
<br>
<div class="privacyDivs">
    <h1 class="new-style">Want to book an appointment for bridal or bridesmaids at our Flagship?</h1>
    <p class="header-text">We invite you to call or text our concierge now at 212-583-1700 to reserve an appointment to view the moteemaids, Nouvelle moteemaids - bridal and bridesmaids, Little White Dress or Christos</p>
    <p class="header-text">Collection. You can also email us at <a href="mailto:flagship@moteemaids.com ">flagship@moteemaids.com </a> or <a href="mailto:customerservice@moteemaids.com">customerservice@moteemaids.com</a>. </p>
    <br><br>
    <p class="header-text">Our Flagship hours are Tuesday-Friday 11am-6pm and Saturday 10am-5pm.</p>
</div>
<br>
<div class="privacyDivs">
    <h1 class="new-style">Our Flagship location</h1>
    <p>625 Madison Ave, 2nd Floor, New York, NY 10022</p>
    <p>The Flagship hours are Tuesday-Friday 11am-6pm and Saturday 10am-5pm.</p>
    <p>We invite you to call or text our concierge at 212-583-1700</p>
    <p>You can also email us at <a href="mailto:flagship@moteemaids.com">flagship@moteemaids.com</a></p>
</div>
<br>
<div class="privacyDivs">
    <h1 class="new-style">
        Not in NYC?
    </h1>
    <p class="header-text">See our store locator for a retailer near you.</p>
</div>
    </div>
</div>