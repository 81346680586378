<div class="main-class wrapper">
    <div class="toolbar-custom">
        <span class="header-text">Motee Maids Blog</span>
    </div>

    <div class="page-width">
        <div class="row justify-content-start" style="margin-bottom: 25px;">
            <!-- Motee Maids Evening  -->
            <div class="blog-post" *ngFor="let item of blogList">
                <div class="post-content" (click)="viewBlog()">
                    <figure class="post-image">
                        <img mat-card-image class="images-custom" [src]="item?.image?.webImage">
                    </figure>
                    <div class="post-title">
                        <a class="anchor-custom header-text">{{item?.title}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>