<div class="wrapper">
    <!-- <mat-toolbar class="toolbar_custom header-text">
        Home/My Account
    </mat-toolbar> -->
    <mat-sidenav-container>
        <!-- mat sidenav -->
        <mat-sidenav mode="side" opened>
            <button mat-button class="side_menu_button header-text" target="_blank" routerLink="/my-profile"><p>My Account</p></button>
                <button mat-button class="side_menu_button header-text" target="_blank" routerLink="/addresses"><p>Addresses</p></button>
                <button mat-button class="side_menu_button header-text" target="_blank" routerLink="/my-order"><p>My Orders</p></button>
                <button mat-button class="side_menu_button header-text" target="_blank" routerLink="/order-status"><p>Order Status</p></button>
                <button mat-button class="side_menu_button header-text" target="_blank" routerLink="/my-wishlist"><p>My Wishlists</p></button>
        </mat-sidenav>
    
        <!-- main content -->
        <mat-sidenav-content>
            <mat-card class="mat-card-custom">
                <div class="order-details">
                    <span class="header-text">Address Book</span>
                </div>
            <form [formGroup]="addAddressForm" class="main-content">
                    <div class="d-flex pl-4 pt-4" style="justify-content: space-between;">
                        <!-- First name -->
                        <div class="w-50">
                            <p class="header-text">* First Name:</p>
                            <input type="text" placeholder="First name" formControlName="firstName">
                            <span class="error" *ngIf="addAddressForm.get('firstName').hasError('required') && (addAddressForm.get('firstName').touched ||addAddressForm.get('firstName').dirty)">
                                *First name is required.</span>
                        </div>
                       
                        <!-- Last name -->
                        <div class="w-50 ">
                            <p class="header-text">* Last Name:</p>
                            <input type="text" placeholder="Last name" formControlName="lastName">
                            <span class="error" *ngIf="addAddressForm.get('lastName').hasError('required') && (addAddressForm.get('lastName').touched || addAddressForm.get('lastName').dirty)">*Last name is required.</span>
                        </div>
                    </div>
    
                    <div class="d-flex pl-4 pt-4" style="justify-content: space-between;">
                        <!-- Address -->
                        <div class="w-50">
                            <p class="header-text">* Address:</p>
                            <input type="text" placeholder=" Address" formControlName="address">
                            <span class="error" *ngIf="addAddressForm.get('address').hasError('required') && (addAddressForm.get('address').touched || addAddressForm.get('address').dirty)">*Address is required.</span>
                        </div>
    
                        <!-- Address Line 2 -->
                        <div class="w-50">
                            <p class="header-text">Address Line 2:</p>
                            <input type="text" placeholder="Address line 2" >
                        </div>
                    </div>
    
                    <div class="d-flex pl-4 pt-4 ml-1" style="justify-content: space-between;">
                        <!-- City -->
                        <div class="w-50">
                            <p class="header-text">* City:</p>
                            <input type="text" placeholder=" City" formControlName="city">
                            <span class="error" *ngIf="addAddressForm.get('city').hasError('required') && (addAddressForm.get('city').touched || addAddressForm.get('city').dirty)">*City is required.</span>
                        </div>
    
                        <!-- State -->
                        <div class="w-50">
                            <p class="header-text">* State:</p>
                            <input type="text" placeholder="State" formControlName="state">
                            <span class="error" *ngIf="addAddressForm.get('state').hasError('required') && (addAddressForm.get('state').touched || addAddressForm.get('state').dirty)">*State is required.</span>
                        </div>
                    </div>
    
                    <div class="d-flex pl-4 pt-4 ml-1" style="justify-content: space-between;">
                        <!-- Country -->
                        <div class="w-50">
                            <p class="header-text">* Country:</p>
                            <input type="text" placeholder="Country" formControlName="country">
                            <span  class="error" *ngIf="addAddressForm.get('country').hasError('required') && (addAddressForm.get('country').touched || addAddressForm.get('country').dirty)">*Country is required.</span>
                        </div>
    
                        <!-- zip code -->
                        <div class="w-50">
                            <p class="header-text">* Zip Code:</p>
                            <input type="text" placeholder=" Zip code" formControlName="zipCode">
                            <span class="error" *ngIf="addAddressForm.get('zipCode').hasError('required') && (addAddressForm.get('zipCode').touched || addAddressForm.get('zipCode').dirty)">*Zip code is required.</span>
                        </div>
                    </div>
    
                    <div class="d-flex pl-4 pt-4 ml-1" >
                        <!-- Phone -->
                         <!-- country code -->
                         <div class="form-group col-md-2 p-0">
                            <!-- -=-=-=-=-=-=-=-=-=-=- Mobile number Field-=-=--=-=-=-=-=-=-=-= -->
                            <!-- <span style="display: flex;"> -->
                                <p class="header-text">* Country Code</p>
            
                            <input
                              type="text"
                              aria-label="Country"
                              class="user-name"
                              [formControl]="myControl"
                              maxlength="7"
                              (keypress)="commonService.numberOnly($event)"
                              [matAutocomplete]="auto"
                            />
                            <mat-autocomplete #auto="matAutocomplete" class="contr">
                              <mat-option
                                *ngFor="let option of filteredOptions | async"
                                [value]="option.code || option.name"
                              >
                                <span style="display: flex; justify-content: space-between">
                                  <!-- <p>{{ option.name }}</p> -->
                                  <p>{{ option.code }}</p>
                                </span>
                              </mat-option>
                            </mat-autocomplete>
                          </div>
                        
                        <div class="col-md-5 pl-0">
                            <p class="header-text">* Phone</p>
                            <input type="text" placeholder="Phone" formControlName="phone" maxlength="14">
                            <span class="error" *ngIf="addAddressForm.get('phone').hasError('required') && (addAddressForm.get('phone').touched || addAddressForm.get('phone').dirty)">
                                *Phone is required.
                            </span>
                            <span class="error" *ngIf="addAddressForm.get('phone').hasError('pattern')">
                                *Please enter valid phone number.
                            </span>
                        </div>
                    </div>
                </form>
            <div class="m-4">
                <input type="checkbox" checked>
                <label class="header-text">Make this My Preferred Shipping Adress</label>
            </div>
            
            <!-- save button -->
            <div class="m-4">
                <button class="w-25"  mat-raised-button color="accent" >SAVE</button>
            </div>
        </mat-card> 
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
