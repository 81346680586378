import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss']
})
export class SelectDateComponent implements OnInit {
  // dayTimingArray : any = [
  //   {sunday:'09:00 AM',monday:'09:00 AM',tuesday:'09:00 AM',wednesday:'09:00 AM',thursday:'09:00 AM',friday:'09:00 AM',saturday:'09:00 AM'},
  //   {sunday:'10:00 AM',monday:'10:00 AM',tuesday:'10:00 AM',wednesday:'10:00 AM',thursday:'10:00 AM',friday:'10:00 AM',saturday:'10:00 AM'},
  //   {sunday:'11:00 AM',monday:'11:00 AM',tuesday:'11:00 AM',wednesday:'11:00 AM',thursday:'11:00 AM',friday:'11:00 AM',saturday:'11:00 AM'},
  //   {sunday:'12:00 AM',monday:'12:00 AM',tuesday:'12:00 AM',wednesday:'12:00 AM',thursday:'12:00 AM',friday:'12:00 AM',saturday:'12:00 AM'},
  //   {sunday:'01:00 PM',monday:'01:00 PM',tuesday:'01:00 PM',wednesday:'01:00 PM',thursday:'01:00 PM',friday:'01:00 PM',saturday:'01:00 PM'},
  //   {sunday:'02:00 PM',monday:'02:00 PM',tuesday:'02:00 PM',wednesday:'02:00 PM',thursday:'02:00 PM',friday:'02:00 PM',saturday:'02:00 PM'},
  //   {sunday:'03:00 PM',monday:'03:00 PM',tuesday:'03:00 PM',wednesday:'03:00 PM',thursday:'03:00 PM',friday:'03:00 PM',saturday:'03:00 PM'},
  //   {sunday:'04:00 PM',monday:'04:00 PM',tuesday:'04:00 PM',wednesday:'04:00 PM',thursday:'04:00 PM',friday:'04:00 PM',saturday:'04:00 PM'},
  //   {sunday:'05:00 PM',monday:'05:00 PM',tuesday:'05:00 PM',wednesday:'05:00 PM',thursday:'05:00 PM',friday:'05:00 PM',saturday:'05:00 PM'},
  //   {sunday:'06:00 PM',monday:'06:00 PM',tuesday:'06:00 PM',wednesday:'06:00 PM',thursday:'06:00 PM',friday:'06:00 PM',saturday:'06:00 PM'},
  //   {sunday:'07:00 PM',monday:'07:00 PM',tuesday:'07:00 PM',wednesday:'07:00 PM',thursday:'07:00 PM',friday:'07:00 PM',saturday:'07:00 PM'},
  //   {sunday:'08:00 PM',monday:'08:00 PM',tuesday:'08:00 PM',wednesday:'08:00 PM',thursday:'08:00 PM',friday:'08:00 PM',saturday:'08:00 PM'},



  // ]
  getCurrentDate: any = new Date().toISOString().split('T')[0];
  currentDate: any = new Date().toISOString().split('T')[0];
  appoinmentDate: any;

  constructor(public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.getAvailableSlot()
  }
  sundaySlots: any = []
  mondaySlots: any = []
  tuesdaySlots: any = []
  wednessdaySlots: any = []
  thursdaySlots: any = []
  fridaySlots: any = []
  saturdaySlots: any = []

  slotsArray: any[] = [];

  getAvailableSlot() {
    console.log(this.currentDate);
    // let url = `user/availableSlot?date=${new Date(this.currentDate).toDateString()}`
    let url = `${'user/availableSlot?date=' + (new Date(this.currentDate).toISOString())}`
    // let url = `user/availableSlot?date=${this.currentDate}`
    this.commonService.showSpinner()
    this.commonService.getApi(url, 0).subscribe((res: any) => {
      console.log(res);
      if (res['responseCode'] == 200) {
        this.slotsArray = res.result
        let allSlots = res['result']
        this.sundaySlots = allSlots.find((ele) => {
          return ele.day == 'Sunday'
        })
        this.mondaySlots = allSlots.find((ele) => {
          return ele.day == 'Monday'
        })
        this.tuesdaySlots = allSlots.find((ele) => {
          return ele.day == 'Tuesday'
        })
        this.wednessdaySlots = allSlots.find((ele) => {
          return ele.day == "Wednesday"
        })
        this.thursdaySlots = allSlots.find((ele) => {
          return ele.day == "Thursday"
        })
        this.fridaySlots = allSlots.find((ele) => {
          return ele.day == 'Friday'
        })
        this.saturdaySlots = allSlots.find((ele) => {
          return ele.day == 'Saturday'
        })
        console.log(this.mondaySlots);

        this.commonService.hideSpinner()
      } else {
        this.commonService.hideSpinner()
      }
    })
  }
  slotTime: any
  index: any
  rowIndex: any
  getSlotTime(parentItem, item, row, ind) {
    this.slotTime = item
    this.index = ind
    this.rowIndex = row
    this.appoinmentDate = parentItem;
    console.log(parentItem);
    console.log(item);
    console.log(row);
    console.log(ind);

  }
  getToday() {
    this.currentDate = new Date().toISOString().split('T')[0]
    this.getAvailableSlot()
    this.appoinmentDate = new Date(this.currentDate).toISOString()
  }

  navigate() {
    console.log(this.slotTime);
    console.log(this.appoinmentDate);

    if (!this.slotTime) {
      this.commonService.warningToast("Please select slot.")
      return
    }
    let adat = {
      appoinmentDate: this.currentDate,
      slotTime: this.slotTime.time
    }
    console.log(adat);

    this.router.navigate(['/client-information'], { queryParams: { appoinmentDate: this.appoinmentDate, slotTime: this.slotTime.time } })
  }

}
