<div class="wrapper">
    <div class="container">
        <div class="staticHeading text-center">
            <h1 class="new-style headingTitle">
                <!-- Return Policy -->
                {{result?.title}}
            </h1>
        </div>
        <div class="termscontent">
            <div class="privacyDivs header-text">
                <!-- <h1 class="new-style">Return Policy
                </h1> -->
                <!-- <p>{{result?.description}}</p> -->
                <div *ngIf="result" class="header-text" [innerHtml]="result.description"></div>
                <!-- <p>Items in the Little White Dress and Evening collections are eligible for return within 14 days of delivery, no exceptions. Please note there is a $25 restocking fee per garment returned.</p>
         
                <p>Items in the Little White Dress and Evening collections must be in original condition (including tags) and must not have been worn, stained, washed or altered (whether by tailor or otherwise) in any way.

                </p>
                <p>All packaging (including garment bags and hangers) shipped with your order must be returned.

                </p>
                <p>We recommend trying on purchased items once received, to ensure adequate time to return items within our returns timeframe.

                </p>
                <p>
                    Please click here within 14 days of delivery to receive a shipping label (or reach out to customerservice@moteemaids.com). Returns must be mailed back within 5 days of printing the label.


                </p>
                <p>All refunds (minus any $25 restocking fee charges) will be issued to your method of payment within 3-5 business days from the date the item is received by moteemaids. Please note - any rush production fees or expedited shipping charges are not eligible for refund. Returns that do not meet our policy will not be accepted and will be sent back to you.

                </p>
                <br><br>
                <p>Our Bridal and Bridesmaids dresses are not eligible for return, and are final sale.

                </p> -->
            </div>


        </div>
    </div>
</div>