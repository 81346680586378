import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-dress-modal',
  templateUrl: './select-dress-modal.component.html',
  styleUrls: ['./select-dress-modal.component.scss']
})
export class SelectDressModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.headerListDetail()
  }

  // /group/addProductInGroup
  addProductInGroup(routeTo) {
    console.log(routeTo);

    let apiReqUrl = ApiEndPoint.addProductInGroup;
    let apiReqData = {
      groupId: this.data.groupId,
      productId: '',
      numberOfIndex: this.data.numberOfIndex
    };
    console.log(apiReqData);

    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log(res);
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner();
        this.router.navigate([routeTo], { queryParams: { routeFrom: 'SOCIAL_SHOPING' } })
        // this.groupId = res.result.groupId
        // this.followinglistData = res["result"]["docs"];
        // for (let item of this.followinglistData) {aa
        //   this.folowingListOption.push(true)
        // }
      } else {
        this.commonService.hideSpinner();
      }
    });
  }


  headerList: any
  /**
     * userType are used in website
     * @param headerList header list -
     */
  headerListDetail() {
    this.commonService.showSpinner()
    this.commonService.getApi(ApiEndPoint.headerList, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.headerList = res.result
        let arr = res.result.filter(item =>
          item.parent.categoryName == 'Ready To Ship'
        );
        this.headerList = arr;
        console.log(this.headerList);
        this.commonService.hideSpinner()

      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);

      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }



}
