import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService, CountryCode } from 'src/app/provider/common.service';
import { AuthService } from 'src/app/provider/auth.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  toggleEye: boolean = true; // toggle eye
  countryList: any[];
  filteredOptions: Observable<CountryCode[]>;
  myControl = new FormControl('+91');
  customer: string;
  isSigninFormSubmitted: boolean = false;
  ipAddress: string;
  calling_code: any;
  constructor(
    private router: Router,
    public commonService: CommonService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.formValidation();
    this.getIP();
  }

  // form validation
  formValidation() {
    // Form group and Form Control Name With Validations
    this.signinForm = new FormGroup({
      // mobileNumber: new FormControl("", [Validators.required, Validators.pattern(/^[^0][0-9]*$/)]),
      mobileNumber: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });

    this.countryList = this.commonService.countryListJson;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => (value ? this._filter(value) : this.countryList.slice()))
    );
    console.log(this, this.filteredOptions);
  }

  // ipAddress: any
  /**
   * get ip address of current system logged in user
   */
  getIP() {
    this.commonService.getIPAddress1().subscribe((res: any) => {
      this.ipAddress = res.ip;
      if (this.ipAddress) {
        return this.locationViaIP()
      }
    });
  }
  countryName: any
  dataLocation: any
  /**
   * get location and other details via ip address of current system logged in user
   * @param ipAddress from above getIP function
   */
  locationViaIP() {
    this.commonService.getLocationViaIP(this.ipAddress).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.dataLocation = res
        this.calling_code = res.calling_code
        this.countryName = res.country_name
      }
    });
  }

  private _filter(value: string): CountryCode[] {
    const filterValue = value.toLowerCase();
    return this.countryList.filter((option) =>
      option.code.toLowerCase().includes(filterValue)
    );
  }

  navigateToSignup() {
    this.router.navigate(['/signup']);
  }

  navigateToForgot() {
    this.router.navigate(['/forgot-password']);
  }

  // customerLogin() {
  //   this.commonService.loginAs.next(
  //     'loginAsCustomer'
  //   )
  //   this.router.navigate(['/homepage'])
  // }

  /**
   * userType are used in website
   * @param CONSUMER -> normal user comes to buy product
   * @param RETAILER -> wholesale retailer comes to buy product for consumer
   * @param INFLUENCER -> location ideal person of some place refer to buy product
   */
  customerLogin() {
    if (this.signinForm.invalid) {
      this.isSigninFormSubmitted = true;
      return;
    }
    let apiReqUrl = ApiEndPoint.login;
    let apiReqData = {
      mobileNumberPId: this.signinForm.value.mobileNumber,
      password: this.signinForm.value.password,
      typeOfUser: 'CUSTOMER'
    };
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          localStorage.setItem('token', res.result.token);
          localStorage.setItem('userType', 'CUSTOMER');
          localStorage.setItem('userId', res.result._id);
          localStorage.setItem('email', this.signinForm.value.mobileNumber)
          // this.commonService.successToast(res.responseMessage)
          // Swal.fire({
          //   icon: 'success',
          //   text: res.responseMessage,
          // })
          this.authService.isLoggedIn.next(true);
          this.authService.loggedInUserType.next('CUSTOMER');
          this.commonService.myShopingBagApi()
          this.router.navigate(['homepage']);
        } else {
          this.commonService.errorToast(res.responseMessage);
        }
      },
      (err) => {
        console.log(err);
        this.commonService.hideSpinner();
        if (err.responseCode == 404) {
          this.commonService.alertError(err.responseMessage);
        } else if (err.responseCode == 402) {
          this.commonService.alertError(err.responseMessage);
          localStorage.removeItem('token');
        } else {
          this.commonService.alertError(err.responseMessage);
        }
      }
    );
  }
}
