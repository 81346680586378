import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.scss']
})
export class CouponListComponent implements OnInit {
  couponListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  constructor(public commonService:CommonService) { }

  ngOnInit(): void {
    this.listCoupon();
  }

  // list coupon 
  listCoupon() {
    let apiReqUrl = ApiEndPoint.listCoupon
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.couponListArray = res.result ? res.result : []
        this.commonService.hideSpinner()
      }else{
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  copyToClipboard(item) { 
    Swal.fire({
      text:'Coupon Code Successfully Copied!',
    })     
    // this.commonService.successToast('Coupon Code Successfully Copied!')
     document.addEventListener('copy', (e: ClipboardEvent) => {
       e.clipboardData.setData('text/plain', (item));
       e.preventDefault();
       document.removeEventListener('copy', null);
     });
     document.execCommand('copy');
   }

}
