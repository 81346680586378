import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-checkout-select-store-address',
  templateUrl: './checkout-select-store-address.component.html',
  styleUrls: ['./checkout-select-store-address.component.scss']
})
export class CheckoutSelectStoreAddressComponent implements OnInit {
  chackoutForm:FormGroup

  constructor() { }

  ngOnInit(): void {
    this.formValidation()
  }

  formValidation(){
    this.chackoutForm= new FormGroup({
      // 'eventDate': new FormControl("",Validators.required),
      // 'selectCountryCode': new FormControl("",Validators.required),
      'phoneNumber':new FormControl("",[Validators.required, Validators.pattern(/^[^0][0-9]*$/)]),
      'firstName':new FormControl("",Validators.required),
      'lastName':new FormControl("",Validators.required),
      'address1':new FormControl("",Validators.required),
      'address2':new FormControl("",Validators.required),
      'country':new FormControl("",Validators.required),
      'stateOrProvinceOrRegion':new FormControl("",Validators.required),
      // 'city':new FormControl("",Validators.required),
      'zipCode':new FormControl("",[Validators.required,Validators.maxLength(10)]),
      'email':new FormControl("",[Validators.required,Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/)])

    })
  }

}
