<div class="wrapper">
    <div class="w-90 p-3 d-flex justify-content-around">
        <div class="lft col-md-6 p-3">
            <div class="">
                <h2 class="header-text">Motee Maids</h2>
            </div>
            <div class="content">
                <h4 class="header-text m-0">Importance Notice</h4>
                <p class="header-text">Please note, signature is required for all dress deliveries. This is to ensure
                    the safe delivery of your Motee Maids dress, no exceptions are made to this policy.</p>
            </div>

            <!-- <div class=" new-text">
                <mat-card class="header-text">
                    <div class="row p-2">
                        <div class="col-md-3">
                            Contact
                        </div>
                        <div class="col-md-7">
                            somya@mailinator.com
                        </div>
                        <div class="col-md-2 curs" routerLink="/checkout-saved-address">
                            Change
                        </div>
                    </div>
                    <hr>
                    <div class="row p-2">
                        <div class="col-md-3">
                            Ship to
                        </div>
                        <div class="col-md-7">

                            okhla , RE4, DELHI AK 99501, NEW DELHI
                        </div>
                        <div class="col-md-2 curs" routerLink="/checkout-saved-address">
                            Change
                        </div>
                    </div>
                    <hr>
                    <div class="row p-2">
                        <div class="col-md-3">
                            Method
                        </div>
                        <div class="col-md-7">


                            Free shipping over $75 · Free
                        </div>
                        <div class="col-md-2">
                           
                        </div>
                    </div>
                </mat-card>
            </div> -->

            <div class="content mt-5">
                <h3 class="header-text m-0">Payment</h3>
                <h5 class="header-text">All transactions are secure and encrypted.</h5>
            </div>
            <div class="">
                <div class="padding">
                    <div class="">
                        <div class="container-fluid d-flex justify-content-center">
                            <div class="col-sm-12 col-md-12">
                                <form [formGroup]="cardForm">
                                    <div class="card" style="border: 2px solid rgba(0,0,0,.125);">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-md-6"> <span>CREDIT/DEBIT CARD PAYMENT</span> </div>
                                                <div class="col-md-6 text-right" style="margin-top: -5px;"> <img
                                                        src="https://img.icons8.com/color/36/000000/visa.png">
                                                    <img src="https://img.icons8.com/color/36/000000/mastercard.png">
                                                    <img src="https://img.icons8.com/color/36/000000/amex.png">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body" style="height: 270px">
                                            <div class="form-group">
                                                <label for="cc-number" class="control-label">CARD NUMBER</label>
                                                <input id="cc-number" type="tel" class="input-lg form-control cc-number"
                                                    formControlName="card" autocomplete="cc-number"
                                                    placeholder="•••• •••• •••• ••••" maxlength="16"
                                                    (keypress)="commonService.numberOnly($event)">
                                                <div class="text-danger"
                                                    *ngIf="(cardForm.get('card').invalid && (cardForm.get('card').touched || cardForm.get('card').dirty || isSubmitted));">
                                                    <span *ngIf="cardForm.get('card').errors.required">
                                                        *Card number is required.
                                                    </span>
                                                    <span *ngIf="cardForm.get('card').errors.pattern">
                                                        *Please enter valid card number.
                                                    </span>
                                                </div>
                                                <!-- <span class="error"
                                                    *ngIf="cardForm.get('card').hasError('required') && (cardForm.get('card').touched || cardForm.get('card').dirty)">
                                                    *Card number is required.
                                                </span>
                                                <span class="error"
                                                    *ngIf="cardForm.get('card').hasError('pattern') && (cardForm.get('card').touched || cardForm.get('card').dirty)">
                                                    Please enter valid card number.
                                                </span> -->
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="cc-exp" class="control-label">CARD EXPIRY</label>
                                                        <!-- <input id="cc-exp" type="tel"
                                                            class="input-lg form-control cc-exp"
                                                            formControlName="expiryDate" autocomplete="cc-exp"
                                                            placeholder="YYYY-MM" required maxlength="7"> -->
                                                        <input id="cc-exp" type="month"
                                                            class="input-lg form-control cc-exp"
                                                            [min]="commonService.getCurrentYearAndMonth()"
                                                            formControlName="expiryDate" autocomplete="cc-exp"
                                                            placeholder="YYYY-MM" required>
                                                        <div class="text-danger"
                                                            *ngIf="(cardForm.get('expiryDate').invalid && (cardForm.get('expiryDate').touched || cardForm.get('expiryDate').dirty || isSubmitted));">
                                                            <span *ngIf="cardForm.get('expiryDate').errors.required">
                                                                *Card expiry date is required.
                                                            </span>
                                                        </div>
                                                        <!-- <span class="error"
                                                            *ngIf="cardForm.get('expiryDate').hasError('required') && (cardForm.get('expiryDate').touched || cardForm.get('expiryDate').dirty)">
                                                            *Card expiry date is required.
                                                        </span> -->
                                                        <!-- <span class="error"
                                                            *ngIf="cardForm.get('expiryDate').hasError('pattern') && (cardForm.get('expiryDate').touched || cardForm.get('expiryDate').dirty)">
                                                            Please enter valid date.
                                                        </span> -->
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="cc-cvc" class="control-label">CARD CVC</label>
                                                        <input id="cc-cvc" type="password"
                                                            class="input-lg form-control cc-cvc" formControlName="cvv"
                                                            autocomplete="off" placeholder="•••" required maxlength="3"
                                                            (keypress)="commonService.numberOnly($event)">
                                                        <div class="text-danger"
                                                            *ngIf="(cardForm.get('cvv').invalid && (cardForm.get('cvv').touched || cardForm.get('cvv').dirty || isSubmitted));">
                                                            <span *ngIf="cardForm.get('cvv').errors.required">
                                                                *Card cvc is required.
                                                            </span>
                                                            <span *ngIf="cardForm.get('cvv').errors.pattern">
                                                                *Please enter valid card cvc.
                                                            </span>
                                                        </div>
                                                        <!-- <span class="error"
                                                            *ngIf="cardForm.get('cvv').hasError('required') && (cardForm.get('cvv').touched || cardForm.get('cvv').dirty)">
                                                            *Card cvc is required.
                                                        </span>
                                                        <span class="error"
                                                            *ngIf="cardForm.get('cvv').hasError('pattern') && (cardForm.get('cvv').touched || cardForm.get('cvv').dirty)">
                                                            Please enter valid cvc.
                                                        </span> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="numeric" class="control-label">CARD HOLDER NAME</label>
                                                <input type="text" class="input-lg form-control"
                                                    formControlName="cardHolderName" maxlength="60">
                                                <div class="text-danger"
                                                    *ngIf="(cardForm.get('cardHolderName').invalid && (cardForm.get('cardHolderName').touched || cardForm.get('cardHolderName').dirty || isSubmitted));">
                                                    <!-- <span *ngIf="cardForm.get('cardHolderName').errors.required">
                                                                                                            *Card holder name is required.
                                                                                                        </span> -->
                                                    <span *ngIf="cardForm.get('cardHolderName').errors.pattern">
                                                        *Please enter valid card holder name.
                                                    </span>
                                                </div>
                                                <!-- <span class="error"
                                                    *ngIf="cardForm.get('cardHolderName').hasError('required') && (cardForm.get('cardHolderName').touched || cardForm.get('cardHolderName').dirty)">
                                                    *Card holder name required.
                                                </span> -->
                                            </div>
                                            <!-- <div class="form-group"> <input value="MAKE PAYMENT" type="button" class="btn btn-success btn-lg form-control" style="font-size: .8rem;"> </div> -->
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="content mt-5">
                <h3 class="header-text m-0">Billing address</h3>
                <h5 class="header-text">Select the address that matches your card or payment method.</h5>
            </div>
            <div class="new-list">
                <div class="details-data">

                    <div class="form-check radi">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" (click)="sameAddress()">
                        <label class="form-check-label" for="exampleRadios2">
            Same as shipping address
            </label>
                    </div>
                    <hr>
                    <div class="form-check radi">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" (click)="bilingAddress()">
                        <label class="form-check-label" for="exampleRadios2">
            Use a different billing address
            </label>
                    </div>
                </div>
              

                <div *ngIf="showAddress">
                    <div class="row mt-2">
                        <div class="col-md-12">
                           
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Address</mat-label>
                                <input matInput placeholder="Address">
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Country</mat-label>
                                <input matInput placeholder="Country">
                            </mat-form-field>
                          
                        </div>
                        <div class="col-md-6">
                           
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>State</mat-label>
                                <input matInput placeholder="State">
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                           
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>City</mat-label>
                                <input matInput placeholder="City">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Zipcode</mat-label>
                                <input matInput placeholder="Zipcode">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

            
            </div> -->
            <!-- <div class="form">
                <div class="contect-form mb-2">
                    <div class="d-flex justify-content-between">
                        <h4 class="header-text m-0">Contact information</h4>
                        <p class="header-text m-0">Already have an account?<a class="hh">Log in</a></p>
                    </div>
                    <div class="field__input-wrapper">
                        <label for="checkout_email" class="field__label field__label--visible"></label>
                        <input placeholder="Email" autocapitalize="off" spellcheck="false" autocomplete="shipping email" data-shopify-pay-handle="true" data-autofocus="true" data-backup="customer_email" aria-describedby="checkout-context-step-one" aria-required="true" class="field__input"
                            size="30" type="email" id="checkout_email">
                    </div>
                </div>
                <div class="shipping mt-4">
                    <h4 class="header-text m-0">Shipping Address</h4>
                    <div class="field__input-wrapper d-flex justify-content-between">
                        <div class="col-md-6 pl-0">
                            <label for="checkout_fName" class="field__label field__label--visible"></label>
                            <input placeholder="First Name" autocapitalize="off" spellcheck="false" autocomplete="shipping first name" data-shopify-pay-handle="true" data-autofocus="true" data-backup="checkout_fName" aria-describedby="checkout-context-step-one" aria-required="true"
                                class="field__input" size="30" type="text" id="checkout_fName">
                        </div>
                        <div class="col-md-6 pr-0">
                            <label for="checkout_lName" class="field__label field__label--visible"></label>
                            <input placeholder="Last Name" autocapitalize="off" spellcheck="false" autocomplete="shipping last name" data-shopify-pay-handle="true" data-autofocus="true" data-backup="checkout_lName" aria-describedby="checkout-context-step-one" aria-required="true"
                                class="field__input" size="30" type="text" id="checkout_lName">
                        </div>
                    </div>
                    <div>
                        <div class="field__input-wrapper">
                            <label for="checkout_address" class="field__label field__label--visible"></label>
                            <input placeholder="Address" autocapitalize="off" spellcheck="false" autocomplete="shipping address" data-shopify-pay-handle="true" data-autofocus="true" data-backup="checkout_address" aria-describedby="checkout-context-step-one" aria-required="true"
                                class="field__input" size="30" type="text" id="checkout_address">
                        </div>
                        <div class="field__input-wrapper">
                            <label for="checkout_apart" class="field__label field__label--visible"></label>
                            <input placeholder="Apartment" autocapitalize="off" spellcheck="false" autocomplete="shipping apart" data-shopify-pay-handle="true" data-autofocus="true" data-backup="customer_apart" aria-describedby="checkout-context-step-one" aria-required="true" class="field__input"
                                size="30" type="text" id="checkout_apart">
                        </div>
                        <div class="field__input-wrapper">
                            <label for="checkout_city" class="field__label field__label--visible"></label>
                            <input placeholder="City" autocapitalize="off" spellcheck="false" autocomplete="shipping city" data-shopify-pay-handle="true" data-autofocus="true" data-backup="customer_city" aria-describedby="checkout-context-step-one" aria-required="true" class="field__input"
                                size="30" type="text" id="checkout_city">
                        </div>
                    </div>
                    <div class="field__input-wrapper d-flex justify-content-between">
                        <div class="col-md-4 pl-0">
                            <label for="country" class="field__label field__label--visible"></label>
                            <select size="1" autocomplete="shipping country" data-backup="country" class="field__input field__input--select" aria-required="true" id="country" placeholder="Country/region">
                                     <option data-code="US" selected="selected" value="United States">United States</option>
                                     <option disabled="disabled" value="---">---</option>
                                     <option data-code="CA" value="Canada">India</option>
                                     <option data-code="US" value="United States">United States</option></select>
                            <div class="field__caret">
                            </div>
                        </div>
                        <div class="col-md-4 pl-0">
                            <label for="state" class="field__label field__label--visible"></label>
                            <select placeholder="State" autocomplete="shipping address-level1" autocorrect="off" data-backup="province" class="field__input field__input--select" aria-required="true" name="checkout[shipping_address][province]" id="state">
                                 <option disabled="disabled" value="---">---</option>
                                 <option data-code="CA" value="Canada">Delhi</option>
                             </select>
                            <div class="field__caret">
                            </div>
                        </div>
                        <div class="col-md-4 pr-0 pl-0">
                            <label for="zipcode" class="field__label field__label--visible"></label>
                            <input placeholder="Zipcode" autocapitalize="off" spellcheck="false" autocomplete="shipping last name" data-shopify-pay-handle="true" data-autofocus="true" data-backup="zipcode" aria-describedby="checkout-context-step-one" aria-required="true" class="field__input"
                                size="30" type="number" id="zipcode" min="1">
                        </div>
                    </div>
                    <div>
                        <div class="field__input-wrapper">
                            <label for="phone" class="field__label field__label--visible"></label>
                            <input placeholder="Phone" autocapitalize="off" spellcheck="false" autocomplete="shipping email" data-shopify-pay-handle="true" data-autofocus="true" data-backup="customer_email" aria-describedby="checkout-context-step-one" aria-required="true" class="field__input"
                                size="30" type="number" min="1" id="phone">
                        </div>
                    </div>
                </div>
            </div> -->


            <!-- <div class="foot d-flex mt-4  justify-content-between align-items-center">
                <div class="lt d-flex justify-content-center align-items-center">
                    <i class="fa fa-angle-left mr-1"></i>
                    <p class="header-text m-0">Return to cart</p>
                </div>
                <div class="rt">
                    <button class="buttonGlobal" routerLink="/bridal-collection-list">Continue to Shopping</button>
                </div>
            </div> -->
        </div>
        <div class="vlt"></div>
        <div class="col-md-4 p-3">
            <!-- <mat-card class="mat-list-card">
                <mat-card-content>
                    <div class="lht">

                        <figure matBadge="4" matBadgeOverlap="false">
                            <img src="assets/images/other/bridal.jpg" alt="">
                        </figure>
                        <div>
                            <p class="header-text m-0">LW203 - Tulle Trapeze Dress</p>
                            <p class="header-text m-0">Black</p>
                        </div>
                        <div>
                            <p class="header-text m-0">$1,495.00</p>
                        </div>
                    </div>
                </mat-card-content>
                <br>
                <mat-card-content>
                    <div class="lht">

                        <figure matBadge="1" matBadgeOverlap="false">
                            <img src="assets/images/other/bridal.jpg" alt="">
                        </figure>
                        <div>
                            <p class="header-text m-0">LW203 - Tulle Trapeze Dress</p>
                            <p class="header-text m-0">Black</p>
                        </div>
                        <div>
                            <p class="header-text m-0">$1,495.00</p>
                        </div>
                    </div>
                </mat-card-content>
                <br>
                <mat-card-content>
                    <div class="lht">

                        <figure matBadge="2" matBadgeOverlap="false">
                            <img src="assets/images/other/bridal.jpg" alt="">
                        </figure>
                        <div>
                            <p class="header-text m-0">LW203 - Tulle Trapeze Dress</p>
                            <p class="header-text m-0">Black</p>
                        </div>
                        <div>
                            <p class="header-text m-0">$1,495.00</p>
                        </div>
                    </div>
                </mat-card-content>
                <br>
                <mat-card-content>
                    <div class="lht">

                        <figure matBadge="3" matBadgeOverlap="false">
                            <img src="assets/images/other/bridal.jpg" alt="">
                        </figure>
                        <div>
                            <p class="header-text m-0">LW203 - Tulle Trapeze Dress</p>
                            <p class="header-text m-0">Black</p>
                        </div>
                        <div>
                            <p class="header-text m-0">$1,495.00</p>
                        </div>
                    </div>
                </mat-card-content>
                <br>
                <mat-card-content>
                    <div class="lht">

                        <figure matBadge="2" matBadgeOverlap="false">
                            <img src="assets/images/other/bridal.jpg" alt="">
                        </figure>
                        <div>
                            <p class="header-text m-0">LW203 - Tulle Trapeze Dress</p>
                            <p class="header-text m-0">Black</p>
                        </div>
                        <div>
                            <p class="header-text m-0">$1,495.00</p>
                        </div>
                    </div>
                </mat-card-content>
                <br>


            </mat-card> -->
            <hr>
            <mat-card class="mt-3 mb-2">
                <!-- <mat-card-content class="mat-card-content-custom">
                    <mat-card-subtitle>Subtotal:</mat-card-subtitle>
                    <mat-card-subtitle>$2559.95</mat-card-subtitle>
                </mat-card-content>
                <mat-card-content class="mat-card-content-custom" style="margin-bottom: 25px;">
                    <mat-card-subtitle>Shipping</mat-card-subtitle>
                    <mat-card-subtitle>$ 40.05</mat-card-subtitle>
                </mat-card-content>
                <hr> -->
                <mat-card-content class="mat-card-content-custom mt-4">
                    <mat-card-title>Total</mat-card-title>
                    <mat-card-title>{{orderDetails?.totalPrice | currency: ipDetails?.geoplugin_currencySymbol}}
                    </mat-card-title>
                </mat-card-content>
                <br>
                <button mat-raised-button class="buttonGlobal" color="accent" (click)="pay()">Pay Now</button>
            </mat-card>
        </div>
    </div>
</div>