<!-- <div class="container"> -->
<div class="content">

    <!-- <div class="order-details">
    <span class="header-text">Order Details</span>
  </div> -->
    <div class="top-text text-center">
        <mat-card-title class="mat-card-title sign-text ml-0 title-color-dark">My Orders</mat-card-title>
    </div>
    <table class="cart">
        <thead>
            <tr>
                <th class="header-text" colspan="2">Product</th>
                <!-- <th class="cart__row-price header-text" scope="col">Price</th> -->
                <!-- <th class="text-center small--hide header-text" scope="col">Quantity</th> -->
                <th class="small--hide header-text" scope="col">Date & Time</th>
                <th class="small--hide header-text" scope="col">Status</th>

                <th class="cart__row-price header-text text-right" scope="col">Total</th>
            </tr>
        </thead>
        <tbody>
            <!-- <tr (click)="orderModal()" style="cursor: pointer;" *ngFor = "let produtDetailsImages of item?.productDetails"> -->

            <tr *ngFor="let item of orderList">
                <td class="cart__image-wrapper">
                    <div>
                        <img [src]="item?.productDetails[0]?.productId?.productImage?.frontImage?.webImage"
                            class="cart__image" alt="">
                    </div>
                </td>
                <td class="cart__meta small--text-left">
                    <div class="list-view-item__title">
                        <a class="header-text">{{item?.productDetails[0]?.productId?.productName}}</a>
                    </div>
                    <div class="cart__meta-text header-text">
                        <div class="color-block-circle-small mr-3 "
                            [style.background-color]="item?.productDetails[0]?.colors?.colorCode"></div>
                        Size : {{item?.productDetails[0]?.size}}

                        <!-- <br>Length : Standard -->
                    </div>
                    <!-- <p class="small--hide">
                        <a class="btn btn--small btn--tertiary cart__remove header-text">Remove</a>
                    </p> -->
                </td>
                <!-- <td class="cart__price-wrapper header-text">
                    ${{item?.subTotal}}
                </td> -->
                <td class="small--hide">
                    <div class="header-text">
                        {{item?.createdAt | date:'medium'}}
                    </div>
                </td>
                <td class="small--hide">
                    <div class="header-text">
                        {{item?.orderStatus | titlecase}}
                    </div>
                </td>
                <td class="text-right small--hide">
                    <div class="header-text">
                        {{item?.totalPrice | currency: ipDetails?.geoplugin_currencySymbol}}
                    </div>
                </td>
                <td class="cart__meta small--text-left">
                    <div class="font-custom d-flex">
                        <button *ngIf="item?.addressType == 'DELIVER_AT_SAME_ADDRESS'" class="btn btn-sm-custom"
                            (click)="viewSingleAddressModal(item._id)">View</button>

                        <button *ngIf="item?.addressType == 'DELIVER_AT_MULTIPLE_ADDRESS'" class="btn btn-sm-custom"
                            (click)="viewMultipleAddressModal(item._id)">View</button>
                        <!-- <button class="btn btn-sm-custom">Edit</button>
                            <button class="btn btn-sm-custom">Delete</button> -->
                    </div>
                </td>
            </tr>
            <tr *ngIf="!orderList?.length">
                <td colspan="4"><span class="d-flex justify-content-center">
                        Order section is empty. After placing order, You can track them from here!
                    </span>
                </td>

            </tr>
        </tbody>
    </table>
</div>

<!-- <mat-card *ngIf="!orderList.length" class="w-75 m-auto">
    <div class="m-auto w-75 text-center">
        <mat-card class="example-card m-auto">
            <img mat-card-image src="/assets/images/shoppingbag.jpg" alt="shopping cart">
        </mat-card>
        <mat-card-title>You haven't placed any order yet!</mat-card-title>
        <mat-card-subtitle>Order section is empty. After placing order, You can track them from here!
        </mat-card-subtitle>
        <div style="margin-top: 5%;">
            <button class="w-50" mat-raised-button color="accent">Start Shopping</button>
        </div>
    </div>
</mat-card> -->

<!-- view my order product modal -->
<div class="modal fade" id="viewMyOrderProductModalId" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">View
                    Order
                </h2>
                <!-- <div class="form-element-wrapper"> -->
                <!-- <div class="border-btn-wrapper">
                        <button class="submit-form-btn buttonGlobal btn-add-cust" id="submit-cart-modal">
                            + Add
                        </button>
                    </div> -->
                <!-- </div> -->
                <div class="">
                    <!-- <div class="hidden-desktop">
                      <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                  </div> -->



                </div>


                <!-- <div class="form-element-wrapper">
                    <div class="border-btn-wrapper">
                        <div class="submit-form-btn buttonGlobal" id="submit-cart-modal" data-dismiss="modal">
                            Submit
                        </div>
                    </div>
                </div> -->


            </div>
        </div>
    </div>
</div>

<!-- end view retailer order product modal -->


<!-- view my retailer order product modal -->
<div class="modal fade" id="viewRetailerOrderProductModalId" tabindex="-1" aria-labelledby="bagModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered retailer-dlg">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Order
                    Details
                </h2>
                <!-- <div class="form-element-wrapper"> -->
                <!-- <div class="border-btn-wrapper">
                      <button class="submit-form-btn buttonGlobal btn-add-cust" id="submit-cart-modal">
                          + Add
                      </button>
                  </div> -->
                <!-- </div> -->
                <div class="">
                    <!-- <div class="hidden-desktop">
                    <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                </div> -->

                    <div class="container bootstrap snippets bootdey">
                        <div class="panel panel-default">
                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 text-left">
                                        <h4 class="title-color-dark"><strong>Client Details </strong></h4>
                                        <ul class="list-unstyled">
                                            <li><strong>First Name:</strong> {{viewOrderDetails?.userId?.firstName}}
                                            </li>
                                            <li><strong>Last Name:</strong> {{viewOrderDetails?.userId?.lastName}}</li>
                                            <li><strong>Country:</strong> {{viewOrderDetails?.userId?.country}}</li>
                                            <!-- <li><strong>DOB:</strong> YYYY/MM/DD</li> -->
                                        </ul>
                                    </div>
                                    <div class="col-md-6 col-sm-6 text-right">
                                        <h4 class="title-color-dark"><strong>Address Details </strong></h4>
                                        <ul class="list-unstyled">
                                            <li><strong>Address:</strong> {{viewOrderDetails?.billingAddress?.address}}
                                            </li>
                                            <li><strong>City:</strong> {{viewOrderDetails?.billingAddress?.city}}</li>
                                            <li><strong>State:</strong> {{viewOrderDetails?.billingAddress?.state}}</li>
                                            <li><strong>Country:</strong>
                                                {{viewOrderDetails?.billingAddress?.country}}-{{viewOrderDetails?.billingAddress?.zipCode}}
                                            </li>
                                        </ul>

                                    </div>
                                    <!-- <div class="col-md-6 col-sm-6 text-right">
                                        <h4 class="title-color-dark"><strong>Payment Details </strong></h4>
                                        <ul class="list-unstyled">
                                            <li><strong>Bank Name:</strong> 012345678901</li>
                                            <li><strong>Account Number:</strong> 012345678901</li>
                                            <li><strong>SWIFT Code:</strong> SWITCH012345678CODE</li>
                                            <li><strong>V.A.T Reg #:</strong> VAT5678901CODE</li>
                                        </ul>

                                    </div> -->

                                </div>

                                <div class="table-responsive">
                                    <table class="table table-condensed nomargin">
                                        <thead>
                                            <tr class="title-color-dark">
                                                <th>Item Name</th>
                                                <th>Quantity</th>
                                                <th>Unit Price</th>
                                                <!-- <th>VAT</th> -->
                                                <th>Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of viewOrderDetails?.productDetails">
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img [src]="item?.productId?.productImage?.frontImage?.webImage || 'assets/images/other/bridal.jpg'"
                                                            alt="" class="first-img-list">
                                                        <strong class="teet">{{item?.productId?.productName}}</strong>
                                                        <!-- Black -->
                                                    </div>
                                                </td>
                                                <td>{{item?.enteredQuantity}}</td>
                                                <td>{{item?.productPrice | currency :
                                                    ipDetails?.geoplugin_currencySymbol}}</td>
                                                <!-- <td>$3.78</td> -->
                                                <td>{{item?.productPrice * item?.enteredQuantity | currency :
                                                    ipDetails?.geoplugin_currencySymbol}}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img src="assets/images/other/bridal.jpg" alt=""
                                                            class="first-img-list">
                                                        <strong class="teet">Unique side and front panel design</strong>
                                                        Black
                                                    </div>
                                                </td>
                                                <td>2</td>
                                                <td>$67.00</td>
                                                <td>$1.80</td>
                                                <td>$68.80</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img src="assets/images/other/bridal.jpg" alt=""
                                                            class="first-img-list">
                                                        <strong class="teet">Unique side and front panel design</strong>
                                                        Black
                                                    </div>
                                                </td>
                                                <td>3</td>
                                                <td>$335.00</td>
                                                <td>$100.20</td>
                                                <td>$435.20</td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>

                                <hr class="nomargin-top">
                                <div class="row">
                                    <div class="col-sm-6 text-left">
                                        <!-- <h4 class="title-color-dark"><strong>Contact Details </strong></h4>
                                        <p class="nomargin nopadding">
                                            <strong>Note:</strong> Lid est laborum dolo rumes fugats.
                                        </p><br>

                                        <address>
                                            PO Box 21132 <br>
                                            Vivas 2355 Australia<br>
                                            Phone: 1-800-565-2390 <br>
                                            Fax: 1-800-565-2390 <br>
                                            Email:moteemaids@abc.com
                                        </address> -->
                                    </div>

                                    <div class="col-sm-6 text-right">
                                        <ul class="list-unstyled">
                                            <li><strong>Sub-Total Amount:</strong> {{viewOrderDetails?.totalPrice |
                                                currency :
                                                ipDetails?.geoplugin_currencySymbol}}</li>
                                            <li><strong>Discount:</strong> {{0 | currency :
                                                ipDetails?.geoplugin_currencySymbol}}</li>
                                            <!-- <li><strong>VAT ($6):</strong> $12.0</li> -->
                                            <li><strong>Shipping Charge:</strong> {{viewOrderDetails?.shippingCharge |
                                                currency :
                                                ipDetails?.geoplugin_currencySymbol}}</li>
                                            <li><strong>Grand Total:</strong> {{viewOrderDetails?.subTotal | currency :
                                                ipDetails?.geoplugin_currencySymbol}}</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>


                <!-- <div class="form-element-wrapper">
                  <div class="border-btn-wrapper">
                      <div class="submit-form-btn buttonGlobal" id="submit-cart-modal" data-dismiss="modal">
                          Submit
                      </div>
                  </div>
              </div> -->


            </div>
        </div>
    </div>
</div>

<!-- end view retailer order product modal -->
<!-- view retailer multiple address order product modal -->
<div class="modal fade" id="viewRetailerMultipleAddressOrderProductModalId" tabindex="-1"
    aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered retailer-dlg">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Order
                    Details
                </h2>
                <!-- <div class="form-element-wrapper"> -->
                <!-- <div class="border-btn-wrapper">
                      <button class="submit-form-btn buttonGlobal btn-add-cust" id="submit-cart-modal">
                          + Add
                      </button>
                  </div> -->
                <!-- </div> -->
                <div class="">
                    <!-- <div class="hidden-desktop">
                    <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                </div> -->

                    <div class="container bootstrap snippets bootdey">
                        <div class="panel panel-default">
                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 text-left">
                                        <h4 class="title-color-dark"><strong>Client Details </strong></h4>
                                        <ul class="list-unstyled">
                                            <li><strong>First Name:</strong> {{viewOrderDetails?.userId?.firstName}}
                                            </li>
                                            <li><strong>Last Name:</strong> {{viewOrderDetails?.userId?.lastName}}</li>
                                            <!-- <li><strong>Country:</strong> {{viewOrderDetails?.userId?.country}}</li> -->
                                            <!-- <li><strong>DOB:</strong> YYYY/MM/DD</li> -->
                                        </ul>
                                    </div>

                                    <!-- <div class="col-md-6 col-sm-6 text-right">
                                        <h4 class="title-color-dark"><strong>Payment Details </strong></h4>
                                        <ul class="list-unstyled">
                                            <li><strong>Bank Name:</strong> 012345678901</li>
                                            <li><strong>Account Number:</strong> 012345678901</li>
                                            <li><strong>SWIFT Code:</strong> SWITCH012345678CODE</li>
                                            <li><strong>V.A.T Reg #:</strong> VAT5678901CODE</li>
                                        </ul>

                                    </div> -->

                                </div>

                                <div class="table-responsive">
                                    <table class="table table-condensed nomargin">
                                        <thead>
                                            <tr class="title-color-dark">
                                                <th>Item Name</th>
                                                <th>Address</th>
                                                <th>Quantity</th>
                                                <th>Unit Price</th>
                                                <!-- <th>VAT</th> -->
                                                <th>Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of viewOrderDetails?.productDetails">
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img [src]="item?.productId?.productImage?.frontImage?.webImage || 'assets/images/other/bridal.jpg'"
                                                            alt="" class="first-img-list">
                                                        <strong class="teet">{{item?.productId?.productName}}</strong>
                                                        <!-- Black -->
                                                    </div>
                                                </td>
                                                <td>
                                                    <span>{{item?.shippingAddress?.address}},</span><br>
                                                    <span>{{item?.shippingAddress?.city}},</span>
                                                    <span>{{item?.shippingAddress?.state}},</span> <br>
                                                    <span>{{item?.shippingAddress?.country + '-' +
                                                        item?.shippingAddress?.zipCode}}</span>
                                                </td>
                                                <td>{{item?.enteredQuantity}}</td>
                                                <td>{{(item?.productPrice / item?.enteredQuantity) | currency :
                                                    ipDetails?.geoplugin_currencySymbol}}</td>
                                                <!-- <td>$3.78</td> -->
                                                <td>{{item?.productPrice * item?.enteredQuantity | currency :
                                                    ipDetails?.geoplugin_currencySymbol}}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img src="assets/images/other/bridal.jpg" alt=""
                                                            class="first-img-list">
                                                        <strong class="teet">Unique side and front panel design</strong>
                                                        Black
                                                    </div>
                                                </td>
                                                <td>okhla phase 1 delhi</td>

                                                <td>2</td>
                                                <td>$67.00</td>
                                                <td>$1.80</td>
                                                <td>$68.80</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="title-color-dark">
                                                        <img src="assets/images/other/bridal.jpg" alt=""
                                                            class="first-img-list">
                                                        <strong class="teet">Unique side and front panel design</strong>
                                                        Black
                                                    </div>
                                                </td>
                                                <td>okhla phase 1 delhi</td>

                                                <td>3</td>
                                                <td>$335.00</td>
                                                <td>$100.20</td>
                                                <td>$435.20</td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>

                                <hr class="nomargin-top">
                                <div class="row">
                                    <div class="col-sm-6 text-left">
                                        <!-- <h4 class="title-color-dark"><strong>Contact Details </strong> </h4>
                                        <p class="nomargin nopadding">
                                            <strong>Note:</strong> Lid est laborum dolo rumes fugats.
                                        </p><br>

                                        <address>
                                            PO Box 21132 <br>
                                            Vivas 2355 Australia<br>
                                            Phone: 1-800-565-2390 <br>
                                            Fax: 1-800-565-2390 <br>
                                            Email:moteemaids@abc.com
                                        </address> -->
                                    </div>

                                    <div class="col-sm-6 text-right">
                                        <ul class="list-unstyled">
                                            <li><strong>Sub-Total Amount:</strong> {{viewOrderDetails?.totalPrice |
                                                currency :
                                                ipDetails?.geoplugin_currencySymbol}}</li>
                                            <li><strong>Discount:</strong> {{0 | currency :
                                                ipDetails?.geoplugin_currencySymbol}}</li>
                                            <!-- <li><strong>VAT ($6):</strong> $12.0</li> -->
                                            <li><strong>Shipping Charge:</strong> {{viewOrderDetails?.shippingCharge |
                                                currency :
                                                ipDetails?.geoplugin_currencySymbol}}</li>
                                            <li><strong>Grand Total:</strong> {{viewOrderDetails?.subTotal | currency :
                                                ipDetails?.geoplugin_currencySymbol}}</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>


                <!-- <div class="form-element-wrapper">
                  <div class="border-btn-wrapper">
                      <div class="submit-form-btn buttonGlobal" id="submit-cart-modal" data-dismiss="modal">
                          Submit
                      </div>
                  </div>
              </div> -->


            </div>
        </div>
    </div>
</div>

<!-- end view retailer multiple address order product modal -->