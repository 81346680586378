<div class="wrraper back-pic">
  <!-- signin Modal -->
  <div class="formDiv">
    <mat-card class="mat-elevation-z0 bdr">
      <div class="main">
        <div class="top-text text-left">
          <mat-card-title class="sign-text new-style">Get started with your account
          </mat-card-title>
          <p class="text-left textAfterloginHeading header-text">
            Already have an account? <a class="regis" (click)="navigateToLogin()"> Log in</a>
          </p>
        </div>
        <div class="input-field">
          <form [formGroup]="signinForm">
            <div class="row">
              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----full name----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">First Name <span class="c-red">*</span></label>
                <input type="text" class="user-name" placeholder="Enter First Name" formControlName="fullName"
                  maxlength="60" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('fullName').invalid && (signinForm.get('fullName').touched || signinForm.get('fullName').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('fullName').errors.required">
                    *First name is required.
                  </span>
                  <span *ngIf="signinForm.get('fullName').errors.pattern">
                    *Please enter valid first name.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('fullName').hasError('required') &&
                      (signinForm.get('fullName').touched ||
                        signinForm.get('fullName').dirty || isSubmittedForm)
                    ">
                    *First name is required.</span>
                  <span class="error" *ngIf="
                      signinForm.get('fullName').hasError('pattern') &&
                      (signinForm.get('fullName').touched ||
                        signinForm.get('fullName').dirty)
                    ">
                    *Please enter valid first name.</span>

                  <span class="error"
                    *ngIf="signinForm.get('fullName').hasError('minlength') && signinForm.get('fullName').dirty">
                    *Minimum length must be 2.
                  </span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <!------------=-=-=--=-=-=-=---------- email Field ----=-=-=-=-=-=-=-=-=----------->
                <label class="top_heading_lab header-text">Last Name <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Enter Last Name" formControlName="lastName"
                  maxlength="60" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('lastName').invalid && (signinForm.get('lastName').touched || signinForm.get('lastName').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('lastName').errors.required">
                    *Last name is required.
                  </span>
                  <span *ngIf="signinForm.get('lastName').errors.pattern">
                    *Please enter valid last name.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                            signinForm.get('lastName').hasError('required') &&
                            (signinForm.get('lastName').touched ||
                              signinForm.get('lastName').dirty || isSubmittedForm)
                          ">
                    *Last name is required.</span>
                  <span class="error" *ngIf="
                            signinForm.get('lastName').hasError('pattern') &&
                            (signinForm.get('lastName').touched ||
                              signinForm.get('lastName').dirty)
                          ">
                    *Please enter valid last name.</span>

                  <span class="error"
                    *ngIf="signinForm.get('lastName').hasError('minlength') && signinForm.get('lastName').dirty">
                    *Minimum length must be 2.
                  </span>
                </div> -->
              </div>
            </div>

            <div class="row">
              <div class="input1 col-md-6">
                <!------------=-=-=--=-=-=-=---------- email Field ----=-=-=-=-=-=-=-=-=----------->
                <label class="top_heading_lab header-text">Email ID <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Enter Email ID" formControlName="emailId"
                  maxlength="256" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('emailId').invalid && (signinForm.get('emailId').touched || signinForm.get('emailId').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('emailId').errors.required">
                    *Email ID name is required.
                  </span>
                  <span *ngIf="signinForm.get('emailId').errors.pattern">
                    *Please enter valid email id.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                           signinForm.get('emailId').hasError('required') &&
                           (signinForm.get('emailId').touched ||
                             signinForm.get('emailId').dirty || isSubmittedForm)
                         ">
                    *Email id is required.</span>
                  <span class="error" *ngIf="
                           signinForm.get('emailId').hasError('pattern') &&
                           (signinForm.get('emailId').touched ||
                             signinForm.get('emailId').dirty)
                         ">
                    *Please enter valid email id.</span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----full name----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">Country <span class="c-red">*</span></label>
                <select name="" id="" class="user-name" formControlName="country"
                  (change)="getStateList($event.target.value)">
                  <option value="Select Country name" selected>Select Country</option>
                  <option *ngFor="let itme of countryList" [value]="itme.countryCode">{{itme?.countryName}}</option>
                  <!-- <option value="India">US</option>
                          <option value="India">UK</option> -->
                </select>
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('country').invalid && (signinForm.get('country').touched || signinForm.get('country').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('country').errors.required">
                    *Country is required.
                  </span>
                  <!-- <span *ngIf="signinForm.get('country').errors.pattern">
                    *Please enter valid email id.
                  </span> -->
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                                  signinForm.get('country').hasError('required') &&
                                  (signinForm.get('country').touched ||
                                    signinForm.get('country').dirty || isSubmittedForm)
                                ">
                    *Country is required.</span>
                </div> -->
              </div>
            </div>

            <div class="row">
              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----full name----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">State <span class="c-red">*</span></label>
                <select name="" id="" class="user-name" formControlName="state" disabled
                  *ngIf="!signinForm.get('country').value">
                  <option value="" selected>Select State</option>
                  <option *ngFor="let item of stateList" [value]="item._id">{{item?.stateName}}</option>
                </select>

                <select name="" id="" class="user-name" formControlName="state" *ngIf="signinForm.get('country').value">
                  <option value="" selected>Select State</option>
                  <option *ngFor="let item of stateList" [value]="item._id">{{item?.stateName}}</option>
                </select>
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('state').invalid && (signinForm.get('state').touched || signinForm.get('state').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('state').errors.required">
                    *State is required.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                            signinForm.get('state').hasError('required') &&
                            (signinForm.get('state').touched ||
                              signinForm.get('state').dirty || isSubmittedForm)
                          ">
                    *State is required.</span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----full name----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">City</label>
                <!-- <select name="" id="" class="user-name" formControlName="city">
                    <option value="Select City name" selected>Select City name</option>
               
                  </select> -->

                <input type="text" class="user-name" placeholder="Enter City Name" formControlName="city" maxlength="60"
                  autocomplete="new-password" />

                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('city').invalid && (signinForm.get('city').touched || signinForm.get('city').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('city').errors.required">
                    *City is required.
                  </span>
                  <span *ngIf="signinForm.get('city').errors.pattern">
                    *Please enter valid city.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                            signinForm.get('city').hasError('required') &&
                            (signinForm.get('city').touched ||
                              signinForm.get('city').dirty || isSubmittedForm)
                          ">
                    *City is required.</span>
                </div> -->
              </div>
            </div>

            <div class="row">
              <div class="input1 col-md-6">
                <!------------=-=-=--=-=-=-=---------- email Field ----=-=-=-=-=-=-=-=-=----------->
                <label class="top_heading_lab header-text">Address Line 1 <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Enter Address 1" formControlName="address1"
                  maxlength="60" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('address1').invalid && (signinForm.get('address1').touched || signinForm.get('address1').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('address1').errors.required">
                    *address is required.
                  </span>
                  <span *ngIf="signinForm.get('address1').errors.pattern">
                    *Please enter valid address.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                            signinForm.get('address1').hasError('required') &&
                            (signinForm.get('address1').touched ||
                              signinForm.get('address1').dirty || isSubmittedForm)
                          ">
                    *Address line 1 is required.</span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----full name----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">Address Line 2</label>
                <input type="text" class="user-name" placeholder="Enter Address 2" maxlength="60"
                  autocomplete="new-password" formControlName="address2" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('address2').invalid && (signinForm.get('address2').touched || signinForm.get('address2').dirty || isFormSubmitted));">
                  <!-- <span *ngIf="signinForm.get('address2').errors.required">
                    *address is required.
                  </span> -->
                  <span *ngIf="signinForm.get('address2').errors.pattern">
                    *Please enter valid address.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                                              signinForm.get('address2').hasError('required') &&
                                              (signinForm.get('address2').touched ||
                                                signinForm.get('address2').dirty || isSubmittedForm)
                                            ">
                    *Address line 2 is required.</span>
                </div> -->
              </div>
            </div>
            <!-- zipcode and contact number -->
            <div class="row">
              <div class="input1 col-md-6 ">
                <!-- --------=-=-=--=-----Zip Code----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">Postal Code <span class="c-red">*</span></label>
                <input type="text" class="user-name" placeholder="Enter Postal Code" formControlName="zipcode"
                  maxlength="10" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('zipcode').invalid && (signinForm.get('zipcode').touched || signinForm.get('zipcode').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('zipcode').errors.required">
                    *Zip code is required.
                  </span>
                  <span *ngIf="signinForm.get('zipcode').errors.pattern">
                    *Please enter valid zip code.
                  </span>
                </div>
                <!-- <div>
                  <div>
                    <span class="error" *ngIf="
                    signinForm.get('zipcode').hasError('required') &&
                    (signinForm.get('zipcode').touched ||
                      signinForm.get('zipcode').dirty || isSubmittedForm)
                  ">
                      *Zip code is required.</span>
                  </div>
                </div> -->
              </div>
              <div class="form-group col-md-2 pr-0">
                <!-- -=-=-=-=-=-=-=-=-=-=- Mobile number Field-=-=--=-=-=-=-=-=-=-= -->
                <!-- <span style="display: flex;"> -->
                <label class="top_heading_lab header-text">Country Code <span class="c-red">*</span></label>

                <input type="text" aria-label="Country" class="user-name" [formControl]="myControl" maxlength="7"
                  (keypress)="commonService.numberOnly($event)" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete" class="contr">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.code || option.name">
                    <span style="display: flex; justify-content: space-between">
                      <!-- <p>{{ option.name }}</p> -->
                      <p>{{ option.code }}</p>
                    </span>
                  </mat-option>
                </mat-autocomplete>
              </div>
              <div class="input1 col-md-4 pl-1">
                <!------------=-=-=--=-=-=-=---------- Contact Number ----=-=-=-=-=-=-=-=-=----------->
                <label class="top_heading_lab header-text contact-new">Contact No. <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Enter Contact Number" formControlName="mobileNumber"
                  maxlength="15" autocomplete="new-password" (keypress)="commonService.numberOnly($event)" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('mobileNumber').invalid && (signinForm.get('mobileNumber').touched || signinForm.get('mobileNumber').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('mobileNumber').errors.required">
                    *Contact number is required.
                  </span>
                  <span *ngIf="signinForm.get('mobileNumber').errors.pattern">
                    *Please enter valid contact number.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                          signinForm.get('mobileNumber').hasError('required') &&
                          (signinForm.get('mobileNumber').touched ||
                            signinForm.get('mobileNumber').dirty || isSubmittedForm)
                        ">
                    *Contact number is required.</span>
                </div> -->

              </div>
            </div>
            <div class="row">
              <div class="input1 col-md-6 postion-relative">
                <!------------=-=-=--=-=-=-=---------- email Field ----=-=-=-=-=-=-=-=-=----------->
                <label class="top_heading_lab header-text">Password <span class="c-red">*</span></label>

                <input [type]="passType ?'password' : 'text'" class="user-name" placeholder="Enter Password"
                  formControlName="password" maxlength="32" autocomplete="new-password" />
                <span class="eye-icon" (click)="passType = !passType"> <i
                    [ngClass]="passType?'fa fa-eye':'fa fa-eye-slash'"></i> </span>
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('password').invalid && (signinForm.get('password').touched || signinForm.get('password').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('password').errors.required">
                    *Password is required.
                  </span>
                  <span *ngIf="signinForm.get('password').errors.pattern">
                    *Password must contain minimum 8 characters with uppercase letter, a number & a special
                    character.
                  </span>
                </div>
                <!-- <div>
                  <span class="error"
                    *ngIf="signinForm.get('password').hasError('required') && (signinForm.get('password').dirty || signinForm.get('password').touched || isSubmittedForm)">*Please
                    enter Password.</span>
                  <span class="error" *ngIf="signinForm.get('password').hasError('pattern')">
                    *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1
                    number and 1 special character.
                  </span>
                </div> -->
              </div>
              <div class="input1 col-md-6 postion-relative">
                <!-- --------=-=-=--=-----full name----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">Confirm Password <span class="c-red">*</span></label>
                <input [type]="passType1 ?'password' : 'text'" class="user-name" placeholder="Confirm Password"
                  maxlength="32" autocomplete="new-password" formControlName="confirmPassword" />
                <span class="eye-icon" (click)="passType1 = !passType1"> <i
                    [ngClass]="passType1?'fa fa-eye':'fa fa-eye-slash'"></i> </span>
                <div class="text-danger">
                  <span *ngIf="signinForm.get('confirmPassword').hasError('required') &&
                                    (signinForm.get('confirmPassword').touched ||
                                      signinForm.get('confirmPassword').dirty || isFormSubmitted)
                                  ">
                    *Confirm password is required.</span>
                  <span *ngIf=" signinForm.get('confirmPassword').value &&
                                    (signinForm.get('password').value !=
                                      signinForm.get('confirmPassword').value) &&
                                    signinForm.get('confirmPassword').dirty
                                  ">
                    *Confirm Password should match with Password.</span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                                  signinForm.get('confirmPassword').hasError('required') &&
                                  (signinForm.get('confirmPassword').touched ||
                                    signinForm.get('confirmPassword').dirty || isSubmittedForm)
                                ">
                    *Confirm password is required.</span>
                  <span class="error" *ngIf="
                                  signinForm.get('password').value !=
                                    signinForm.get('confirmPassword').value &&
                                  signinForm.get('confirmPassword').dirty
                                ">
                    *Confirm Password should match with New Password.</span>
                </div> -->
              </div>
            </div>
            <!---=-=-=-=-= Facebook Website  -=-=-=-=-=-=-=-=-=-=-===--->
            <!-- <div class="row">
                <div class="input1 col-md-12">
                  <label class="top_heading_lab header-text">Facebook*</label>
  
                  <input
                    type="text"
                    class="user-name"
                    placeholder="Facebook"
                    formControlName="facebook"
                    maxlength="30"
                    autocomplete="new-password"
                  />
                </div>
              </div> -->

            <!---=-=-=-=-=Instagram Website  -=-=-=-=-=-=-=-=-=-=-===--->
            <div class="row">
              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">Facebook <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Facebook" formControlName="facebook" maxlength="60"
                  autocomplete="new-password" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('facebook').invalid && (signinForm.get('facebook').touched || signinForm.get('facebook').dirty || isFormSubmitted));">
                  <!-- <span *ngIf="signinForm.get('facebook').errors.required">
                      *Facebook url is required.
                    </span> -->
                  <span *ngIf="signinForm.get('facebook').errors.pattern">
                    *Please enter valid facebook url.
                  </span>
                </div>
              </div>
              <div class="input1 col-md-6">
                <!-- ------------password-------------- -->
                <label class="top_heading_lab header-text">Instagram <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Instagram" formControlName="instagram" maxlength="60"
                  autocomplete="new-password" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('instagram').invalid && (signinForm.get('instagram').touched || signinForm.get('instagram').dirty || isFormSubmitted));">
                  <!-- <span *ngIf="signinForm.get('instagram').errors.required">
                      *Instagram url is required.
                    </span> -->
                  <span *ngIf="signinForm.get('instagram').errors.pattern">
                    *Please enter valid instagram url.
                  </span>
                </div>
              </div>


            </div>

            <!---=-=-=-=-=LinkedIn Website  -=-=-=-=-=-=-=-=-=-=-===--->
            <div class="row">
              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">Twitter <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Twitter" formControlName="twitter" maxlength="60"
                  autocomplete="new-password" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('twitter').invalid && (signinForm.get('twitter').touched || signinForm.get('twitter').dirty || isFormSubmitted));">
                  <!-- <span *ngIf="signinForm.get('twitter').errors.required">
                      *Twitter url is required.
                    </span> -->
                  <span *ngIf="signinForm.get('twitter').errors.pattern">
                    *Please enter valid twitter url.
                  </span>
                </div>
              </div>

              <div class="input1 col-md-6">
                <!-- ------------password-------------- -->
                <label class="top_heading_lab header-text">LinkedIn <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="LinkedIn" formControlName="linkedIn" maxlength="60"
                  autocomplete="new-password" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('linkedIn').invalid && (signinForm.get('linkedIn').touched || signinForm.get('linkedIn').dirty || isFormSubmitted));">
                  <!-- <span *ngIf="signinForm.get('linkedIn').errors.required">
                                          *LinkedIn url is required.
                                        </span> -->
                  <span *ngIf="signinForm.get('linkedIn').errors.pattern">
                    *Please enter valid linkedIn url.
                  </span>
                </div>
              </div>


            </div>

            <div class="row">
              <div class="input1 col-md-6">
                <!------------=-=-=--=-=-=-=---------- email Field ----=-=-=-=-=-=-=-=-=----------->
                <label class="top_heading_lab header-text">Govt. Document Front <span class="c-red">*</span></label>
                <input type="file" class="user-name" placeholder="Govt. Document" formControlName="govtDocsFront"
                  (change)="uploadImg($event, 'FRONT')" />
                <img *ngIf="frontImageUrl" [src]="frontImageUrl?.webImage" class="mt-1 image-custom" alt="">
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('govtDocsFront').invalid && (signinForm.get('govtDocsFront').touched || signinForm.get('govtDocsFront').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('govtDocsFront').errors.required">
                    *Govt. document is required.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                        signinForm.get('govtDocsFront').hasError('required') &&
                        (signinForm.get('govtDocsFront').touched ||
                          signinForm.get('govtDocsFront').dirty || isSubmittedForm)
                      ">
                    *Govt. document is required.</span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----full name----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">Govt. Document Back <span class="c-red">*</span></label>
                <input type="file" class="user-name" placeholder="Govt. Document" formControlName="govtDocsBack"
                  (change)="uploadImg($event,'BACK')" />
                <img *ngIf="backImageUrl" [src]="backImageUrl.webImage" class="mt-1 image-custom" alt="">

                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('govtDocsBack').invalid && (signinForm.get('govtDocsBack').touched || signinForm.get('govtDocsBack').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('govtDocsBack').errors.required">
                    *Govt. document is required.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                          signinForm.get('govtDocsBack').hasError('required') &&
                          (signinForm.get('govtDocsBack').touched ||
                            signinForm.get('govtDocsBack').dirty || isSubmittedForm)
                        ">
                    *Govt. document is required.</span>
                </div> -->
              </div>
            </div>

            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="checkTerms">
              <!-- <label class="form-check-label" for="exampleCheck1" data-toggle="tooltip" data-placement="top"
                title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reiciendis cumque aliquam quisquam quaerat esse provident blanditiis modi doloribus id corrupti numquam, expedita ratione distinctio quasi mollitia quae veniam eveniet ducimus.">I
                agree terms and conditions</label> -->
              <label class="form-check-label">I
                agree to the &nbsp;<a style="color: #007bff;" href="/terms-condition" target="_blank">terms and
                  conditions</a></label>
              <!-- validation -->
              <div>
                <span class="text-danger"
                  *ngIf="signinForm.get('checkTerms').hasError('required') && (signinForm.get('checkTerms').touched || signinForm.get('checkTerms').dirty || isFormSubmitted)">*Please
                  agree terms and conditions.</span>
              </div>
            </div>
            <!-- </div> -->
            <div class="down-button text-left">
              <button mat-button class="buttonGlobal" (click)="influencerSignUpApi()">Get Started</button>
            </div>
          </form>
        </div>
      </div>
    </mat-card>
  </div>
</div>