import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  eventDetailsForm: FormGroup
  isSubmitted: boolean = false
  appointmentData = JSON.parse(localStorage.getItem('appointmentData'))
  getCurrentDate: any = new Date().toISOString().split('T')[0];

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit(): void {
    this.formValidation()
  }
  formValidation() {
    this.eventDetailsForm = new FormGroup({
      'date': new FormControl('', Validators.required),
      'role': new FormControl('', Validators.required),
      'budget': new FormControl('', Validators.required),
      'link': new FormControl('', Validators.required),
      'gownDetails': new FormControl('', Validators.required),
      'additionalNotes': new FormControl(''),
    })
  }

  onSubmitEventDetail() {
    const data = {
      "eventDate": this.eventDetailsForm.value.date ? new Date(this.eventDetailsForm.value.date).toString() : '',
      "roleInEvent": this.eventDetailsForm.value.role,
      "budget": this.eventDetailsForm.value.budget,
      "socialLink": this.eventDetailsForm.value.link,
      "gownDetails_Description": this.eventDetailsForm.value.gownDetails,
      "additionalNotes": this.eventDetailsForm.value.additionalNotes
    }
    this.appointmentData['eventDetails'] = data
    let url = `user/addAppointment`
    this.commonService.showSpinner()
    this.commonService.removeEmptyKey(this.appointmentData)
    this.commonService.postApi(url, this.appointmentData, 0).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        localStorage.setItem('appointmentRes', JSON.stringify(res.result))
        this.router.navigateByUrl('/confirm-payment')
        this.commonService.hideSpinner()
      }
      else {
        this.commonService.hideSpinner()
      }
    })


  }
  navigateToConfirmPayment() {
    if (this.eventDetailsForm.invalid) {
      this.isSubmitted = true
      return
    }
    this.onSubmitEventDetail()

  }
}
