import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-dress-preview',
  templateUrl: './create-dress-preview.component.html',
  styleUrls: ['./create-dress-preview.component.scss']
})
export class CreateDressPreviewComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  openDialog() {
    const dialogRef = this.dialog.open(CreateDressPreviewComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
