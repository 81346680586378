<div class="wrapper">
    <!-- fifth section image section -->
    <div class="fifth-details">
        <!-- <div class="txt-clr">
      <h2>Explore the collections</h2>
    </div> -->
        <div class="">
            <div class="row">
                <figure class="fifth-sect-img">
                    <img class="fifth-first-img-show" src="/assets/images/Shoot_3/STILLS_1983.jpg" alt="">
                    <div class="hero__inner-sec">
                        <div class="hero_upper-sec">
                            <span class="mega-subtitle-sec header-text">
                  <em>Motee Maids</em>
                </span>
                            <h1 class="header-text">dress code</h1>
                            <div class="hero_inn-sec ">
                                <p class="header-text">"Simple, all-around chic designs make every bridal brunch, shower and party someting to celebrate. Selected styles now available in black and navy."</p>
                            </div>
                            <!-- <a href="" class="new-hero-sec header-text">
                  Shop Little White Dress
                </a> -->
                        </div>
                    </div>
                </figure>

            </div>
        </div>

    </div>
    <!-- end toolbar -->
    <mat-sidenav-container class="h-100 w-100">
        <!-- sidebar start -->
        <mat-sidenav mode="side" opened class="w-20">
            <div class="d-flex justify-content-between align-items-center p-2">
                <h3 class="m-0 font-weight-bold">Filter By</h3>
            </div>
            <mat-divider></mat-divider>
          
            <div class="col-md-12">
                <div class="color mt-5">
                    <h4 class="header-text text-left">Filters</h4>
                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    FABRIC
                                </mat-panel-title>
                                <!-- <mat-panel-description>
                      This is a summary of the content
                    </mat-panel-description> -->
                            </mat-expansion-panel-header>
                            <!-- <p>This is the primary content of the panel.</p> -->
                            <div class="header-text">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck1">
                                    <label class="custom-control-label" for="customCheck1">Crepe</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck2">
                                    <label class="custom-control-label" for="customCheck2"> Embroidery</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck3">
                                    <label class="custom-control-label" for="customCheck3">Embroidered Tulle</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck4">
                                    <label class="custom-control-label" for="customCheck4">Faille</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck5">
                                    <label class="custom-control-label" for="customCheck5"> Fil Coupe</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck6">
                                    <label class="custom-control-label" for="customCheck6">Lace</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck7">
                                    <label class="custom-control-label" for="customCheck7">Moire</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck8">
                                    <label class="custom-control-label" for="customCheck8"> Organza</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck9">
                                    <label class="custom-control-label" for="customCheck9">Radzimir</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck10">
                                    <label class="custom-control-label" for="customCheck10"> Silk-Faille</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck11">
                                    <label class="custom-control-label" for="customCheck11"> Silk-Wool</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck12">
                                    <label class="custom-control-label" for="customCheck12">Silk-Tulle</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck13">
                                    <label class="custom-control-label" for="customCheck13">Tulle</label>
                                </div>

                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>
                    
                </div>
                <div class="color mt-3">
                    <!-- <h4 class="header-text text-center">Fabric</h4> -->
                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    PRICE
                                </mat-panel-title>
                                <!-- <mat-panel-description>
                  This is a summary of the content
                </mat-panel-description> -->
                            </mat-expansion-panel-header>
                            <!-- <p>This is the primary content of the panel.</p> -->
                            <div class="header-text">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c1">
                                    <label class="custom-control-label" for="c1">$100 - $300</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c2">
                                    <label class="custom-control-label" for="c2"> $300 - $500</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c3">
                                    <label class="custom-control-label" for="c3">$500 - $800</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c4">
                                    <label class="custom-control-label" for="c4">$800 - $1500</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c5">
                                    <label class="custom-control-label" for="c5">$1500 - $3000</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c6">
                                    <label class="custom-control-label" for="c6">$3000 - $8000</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c7">
                                    <label class="custom-control-label" for="c7">Over $8000</label>
                                </div>


                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
                <div class="color mt-3">
                    <!-- <h4 class="header-text text-left">Filters</h4> -->

                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    COLOUR
                                </mat-panel-title>
                                <!-- <mat-panel-description>
                        This is a summary of the content
                      </mat-panel-description> -->
                            </mat-expansion-panel-header>
                            <!-- <p>This is the primary content of the panel.</p> -->
                            <div class="header-text">
                                <div class="p-2 d-flex justify-content-between">
                                    <!-- <span class="header-text">BLACK</span> -->
                                    <p class="black"></p>
                                    <p class="blue"></p>
                                    <p class="green"></p>
                                    <p class="gray"></p>
                                    <p class="brown"></p>


                                </div>
                                <div class="p-2 d-flex justify-content-between">
                                    <!-- <p class="pink"></p>
                                    <p class="purple"></p> -->
                                    <p class="red"></p>
                                    <p class="gold"></p>
                                    <p class="navy"></p>
                                    <p class="lightGreen"></p>
                                    <p class="lightCoral"></p>
                                </div>
                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
                <div class="color mt-3">
                    <!-- <h4 class="header-text text-left">Filters</h4> -->

                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    SIZE
                                </mat-panel-title>
                                <!-- <mat-panel-description>
                      This is a summary of the content
                    </mat-panel-description> -->
                            </mat-expansion-panel-header>
                            <!-- <p>This is the primary content of the panel.</p> -->
                            <div class="header-text">
                                <select class="form-control form-control-sm">
                                <label for="">Body Size</label>
                                <option disabled>Select Size</option>
                                <option>0</option>
                                <option>2</option>
                                <option>4</option>
                                <option>6</option>
                                <option>8</option>
                                <option>10</option>
                                <option>12</option>
                                <option>14</option>
                                <option>16</option>
                                <option>18</option>
                                <option>20</option>
                                <option>22</option>
                                <option>24</option>
                              </select>
                               
                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
            </div>
        </mat-sidenav>
        <!-- sidebar end -->
        <mat-sidenav-content class=" pt-5">
           
            <!-- images list container -->
            <div class="new-bridal-cls">
                <div class="d-flex  header-c">
                    <h1 class="header-text title_bridal">
                        Bridal
                    </h1>
                    <p class="header-text">
                        Simply iconic. The true namesake collection, Motee Maids Bridal is simply modern, highlighted by signature couture design techniques and hand-touched by the master seamstresses at our New York atelier.
                        <strong>See the collection on you with Motee Maids Virtual Try-On</strong>
                    </p>
                </div>
                <div class="row justify-content-around">
                    <div class="fifth-img" routerLink="/view-mothers-dress" *ngFor="let item of productListArray">

                        <!-- <img class="fifth-img-show" src="/assets/images/Fully_Beaded_Collection_Shoot_2/Amelia_Fully Beaded_Back.jpg" alt=""> -->
                        <img class="fifth-img-show" [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt="">

                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">{{item?.productName}}</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                {{item?.productPrice[0]?.MRP}}
                            </h4>
                            <div class="ic" *ngFor="let color of item?.colorDetails">
                                <img [src]="color?.image" alt="" class="image-custom-list">

                                <!-- <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i> -->
                            </div>
                        </div>

                        <!-- <h4 class="text-left header-text">Buy fabric swatches in velvet, satin, and more fabrications to help wedding plan.</h4> -->
                    </div>
                    <!-- <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Fully_Beaded_Collection_Shoot_2/Amelia_Fully_Beaded.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">PERSEPHONE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Fully_Beaded_Collection_Shoot_2/Emma_Back.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MICHAELA</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="row justify-content-around top-data-new">
                    <div class="fifth-img" routerLink="/view-mothers-dress">

                        <img class="fifth-img-show" src="/assets/images/Shoot_3/olga_in_green.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Shoot_3/Olga_Embroidery_Daisy_Champagn_0581.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Shoot_3/NAVY_1539 - ALT-2.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="row justify-content-around top-data-new">
                    <div class="fifth-img" routerLink="/view-mothers-dress">

                        <img class="fifth-img-show" src="/assets/images/Shoot_3/Motee_Tonal_Green.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Shoot_3/LAYRA_SKIRT_1577_ALT.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">SYDNEY</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="row justify-content-around top-data-sec">
                    <div class="fifth-img" routerLink="/view-mothers-dress">

                        <img class="fifth-img-show" src="" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Shoot_3/Pressimages/Cara_Encrusted.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">SYDNEY</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- </div> -->
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>