import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { RegEx } from 'src/app/config/reg-ex';
import { CommonService } from 'src/app/provider/common.service';
declare var $: any;
@Component({
  selector: 'app-individual-checkout',
  templateUrl: './individual-checkout.component.html',
  styleUrls: ['./individual-checkout.component.scss']
})
export class IndividualCheckoutComponent implements OnInit {
  chackoutForm: FormGroup
  isSubmitted: boolean = false
  email = localStorage.getItem('email')
  countryListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  stateListArray: any;
  shoppingBag: any;
  totalItems: any;
  address: any;
  state: any;
  zipCode: any;
  country: any;
  addressId: any;
  city: any;
  followinglistData: any;
  groupId: any;
  folowingListOption: any;
  ipDetails: any;
  subtotal: number = 0;
  shippingCharge: number = 0;
  total: number = 0
  informationForm: FormGroup;
  socialShopOrderDetails: any;

  constructor(public activatedRoute: ActivatedRoute, private commonService: CommonService, private router: Router) {
    this.ipDetails = this.commonService.getIPDetails();

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.socialShopOrderDetails) {
        this.socialShopOrderDetails = JSON.parse(res.socialShopOrderDetails)
        this.productListInGroup(this.socialShopOrderDetails.groupId)
      }
    })
    this.formValidation()
    this.countryListDetail()
    this.listAddress()
    // this.myShopingBagApi();
    this.informationFormValidation();
  }

  formValidation() {
    this.chackoutForm = new FormGroup({
      'address': new FormControl("", [Validators.required, Validators.pattern(RegEx.address)]),
      'state': new FormControl("", Validators.required),
      'country': new FormControl("", Validators.required),
      // 'city': new FormControl("", Validators.required),
      'city': new FormControl("", [Validators.pattern(RegEx.city)]),
      'zipCode': new FormControl("", [Validators.required, Validators.pattern(RegEx.internationalZipCode)])
    })
  }

  informationFormValidation() {
    this.informationForm = new FormGroup({
      // 'email': new FormControl('')
      email: new FormControl("", [Validators.required, Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/)])
    })
    if (localStorage.getItem('email')) {
      this.informationForm.patchValue({
        'email': localStorage.getItem('email')
      })
      this.informationForm.controls['email'].disable()
    }
  }

  // addNewAddress(){
  //   if(this.chackoutForm.invalid){
  //     this.isSubmitted = true
  //     return
  //   }
  // $('#addNewAddressModalId').modal('hide')
  // this.isSubmitted = false

  // }
  listAddressArray: any = [];

  /**
   * @param listAddress function is used to get address list
   */
  listAddress() {
    let apiReqUrl = ApiEndPoint.listAddress
    let apiReqData = {
      page: this.currentPage,
      limit: this.itemPerPage,
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log("change password response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        this.listAddressArray = res.result ? res.result.docs : []
        this.countryListDetail();
        localStorage.removeItem('addressId')
      } else {
        this.listAddressArray = []
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      this.listAddressArray = []
      this.commonService.hideSpinner()
    })
  }

  /**
   * @param stateList function is used to get contry list
   */
  stateList(countryCode) {
    let apiReqUrl = `product/stateList?countryCode=${countryCode.value || countryCode}`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.stateListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    })
  }

  /**
   * @param countryListDetail function is used to get country list 
   */
  countryListDetail() {
    let apiReqUrl = 'product/countryList'
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.countryListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    })
  }

  /**
   * @param addNewAddress function is used to add new addres
   */
  addNewAddress() {
    if (this.chackoutForm.invalid) {
      this.isSubmitted = true
      return
    }
    let apiReqUrl = ApiEndPoint.addAddress
    let apiReqData = {
      address: this.chackoutForm.value.address,
      country: this.chackoutForm.value.country,
      state: this.chackoutForm.value.state,
      city: this.chackoutForm.value.city,
      zipCode: this.chackoutForm.value.zipCode
    }
    this.commonService.removeEmptyKey(apiReqData)
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        // this.countryListArray = res.result ? res.result : []
        this.commonService.hideSpinner()
        $('#addNewAddressModalId').modal('hide')
        localStorage.removeItem('addressId')
        this.listAddress();
        this.chackoutForm.reset();
        this.isSubmitted = false
      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    }, err => {
      if (err['responseCode'] == 400) {
        this.commonService.alertError(err.responseMessage);
      }
    })
  }

  getCountry(countryCode) {
    if (!this.countryListArray.length) {
      return
    }
    let temp = this.countryListArray.filter((item) => {
      return item?.countryCode == countryCode
    })
    // console.log(temp);
    return temp[0]?.countryName
  }

  /**
   * @param myShopingBagApi function is used to show product list in bag
   */
  myShopingBagApi() {
    let apiReqUrl = `${ApiEndPoint.shoppingbagList + '?page=' + this.currentPage + '&limit=' + this.itemPerPage}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.shoppingBag = res.result.docs
        this.totalItems = res.result.total
        this.getTotalProductPrice()
        this.commonService.hideSpinner()
      } else {
        this.shoppingBag = []
        this.totalItems = 0
        this.commonService.hideSpinner()
      }
    }, (err) => {
      if (err.responseCode == 404) {
        this.shoppingBag = []
        this.totalItems = 0
        this.commonService.hideSpinner()
      }
    })
  }

  productIndex: any;
  productListInGroupDetails: any;
  // /group/productListInGroup
  productListInGroup(groupId) {
    // const indexes = [];
    let apiReqUrl = `${ApiEndPoint.productListInGroup + '?groupId=' + groupId}`;
    // let apiReqData = {
    //   'groupId': groupId
    // }
    this.commonService.getApi(apiReqUrl, 1).subscribe(
      (res: any) => {
        console.log(res);
        if (res['responseCode'] == 200) {
          let filteredData = res.result.products.filter(x => x.numberOfIndex == this.socialShopOrderDetails.numberOfIndex)
          res.result.products = filteredData
          this.shoppingBag = res.result;
          this.getTotalProductPrice()
        }
      },
      (err) => {
        console.log(err);
      })
  }

  sendRadioButton(address, state, city, zipCode, country, _id) {
    this.address = address,
      this.state = state,
      this.city = city
    this.zipCode = zipCode,
      this.country = country,
      this.addressId = _id
    console.log(this.address, this.state, this.zipCode, this.country);
  }

  // navigateToCheckout(){
  // let apiReqUrl = ApiEndPoint.addProductInGroup;
  // let apiReqData={
  //   'email':this.email,
  //   'address':this.addressId,
  //   'productDetails':this.shoppingBag,
  //   'subTotal':256,
  //   'shipping':30,
  //   'total': 500
  // }
  // this.commonService.showSpinner()
  // this.commonService.postApi(apiReqUrl,apiReqData,1).subscribe((res:any)=>{
  //   if (res['responseCode'] == 200) {
  //     this.commonService.hideSpinner();
  //     this.groupId = res.result.groupId
  //     this.followinglistData = res["result"]["docs"];
  //     for (let item of this.followinglistData) {
  //       this.folowingListOption.push(true)
  //     }
  //   } else {
  //     this.commonService.hideSpinner();
  //   }
  // })
  // console.log(apiReqData);

  // console.log(apiReqData.email,apiReqData.address,apiReqData.productDetails,apiReqData.shipping,apiReqData.subTotal,apiReqData.total);
  // let navigationExtra = {
  //   address:this.address,
  //   state:this.state,
  //   city:this.city,
  //   zipCode:this.zipCode,
  //   country:this.country,
  //   email:this.email
  // }

  // this.router.navigate(['/payment'],{queryParams:navigationExtra})
  // }

  // /group/addProductInGroup
  // addProductInGroup() {
  //   let apiReqUrl = ApiEndPoint.addProductInGroup;
  //   let apiReqData = {
  //     "productDetails": [
  //       {
  //         "productId": "string",
  //         "embellishmentId": "string",
  //         "quantity": 0,
  //         "colors": "string",
  //         "size": "string"
  //       }
  //     ],
  //     "shippingAddress": "string",
  //     "couponId": "string",
  //     "billingAddress": {
  //       "address": "string",
  //       "country": "string",
  //       "state": "string",
  //       "zipCode": "string"
  //     },
  //     "totalPrice": 0
  //   }
  //   console.log(apiReqData);

  //   this.commonService.showSpinner();
  //   this.commonService
  //     .postApi(apiReqUrl, apiReqData, 1)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       if (res['responseCode'] == 200) {
  //         this.commonService.hideSpinner();
  // this.groupId = res.result.groupId
  // this.followinglistData = res["result"]["docs"];
  // for (let item of this.followinglistData) {aa
  //   this.folowingListOption.push(true)
  // }
  //       } else {
  //         this.commonService.hideSpinner();
  //       }
  //     });
  // }

  getTotalProductPrice() {
    this.shoppingBag.products.forEach(element => {
      console.log(element);
      this.subtotal = this.subtotal + element.totalMrp * element.enteredQuantity
      this.total = this.subtotal + this.shippingCharge
    });
  }

  // change this as per request need discuss after that  \use paage individual pay for paymnet copy from payment
  checkout() {
    // console.log(this.addressId);
    if (this.addressId == undefined) {
      // console.log("address");
      return this.commonService.alertError('Please select address to continue.')
    }
    console.log(this.shoppingBag);

    // let productDetails = this.shoppingBag.map((element: any) => {
    //   return this.commonService.removeEmptyKey({
    //     "productId": element?.productId?._id,
    //     "colors": element?.colors ? element?.colors._id : '',
    //     "premiumColorId": element?.premiumColorId ? element?.premiumColorId?._id : '',
    //     "size": element?.size,
    //     // "quantity": element.enteredQuantity
    //     "enteredQuantity": element?.enteredQuantity,
    //     "productPrice": element?.totalMrp
    //   })

    // });

    let apiReqUrl = ApiEndPoint.createOrder;
    // let apiReqData = {
    //   // "productDetails": [
    //   //   {
    //   //     "productId": "626103e0ec008600dc18c478",
    //   //     "colors": "623b6659f09218d63ce3efb1",
    //   //     "size": "04",
    //   //     "quantity": 1
    //   //   },
    //   //   {
    //   //     "productId": item.productDetails.productId._id,
    //   //     "colors": item.productDetails.colors,
    //   //     "size": item.productDetails.size,
    //   //     "quantity": item.productDetails.quantity
    //   //   }
    //   // ],
    //   productDetails: productDetails,
    //   // totalPrice: this.total,
    //   // shippingCharge: this.shippingCharge,
    //   // subTotal: this.subtotal,
    //   shippingAddress: this.addressId,
    //   email: this.informationForm.value.email
    // }
    let apiReqData = {
      // "productDetails": [
      //   {
      //     "productId": "626103e0ec008600dc18c478",
      //     "colors": "623b6659f09218d63ce3efb1",
      //     "size": "04",
      //     "quantity": 1
      //   },
      //   {
      //     "productId": item.productDetails.productId._id,
      //     "colors": item.productDetails.colors,
      //     "size": item.productDetails.size,
      //     "quantity": item.productDetails.quantity
      //   }
      // ],
      // productDetails: productDetails,
      totalPrice: this.total,
      shippingCharge: this.shippingCharge,
      subTotal: this.subtotal,
      shippingAddress: this.addressId,
      email: this.email,

      payType: this.shoppingBag.payType,
      groupId: this.socialShopOrderDetails.groupId,
      // totalMrp: this.socialShopOrderDetails.payType,
      // currencyCode: this.socialShopOrderDetails.payType,
      currencyCode: this.ipDetails.geoplugin_currencyCode,
      // addressType: 'DELIVER_AT_SAME_ADDRESS'
      numberOfIndex: this.socialShopOrderDetails.numberOfIndex
    }
    console.log(apiReqData);
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner();
        // this.groupId = res.result.groupId
        // this.followinglistData = res["result"]["docs"];
        // for (let item of this.followinglistData) {
        //   this.folowingListOption.push(true)
        // }

        this.router.navigate(['/checkout-individual-pay'], { queryParams: { orderId: res.result._id, groupId: this.socialShopOrderDetails.groupId, numberOfIndex: this.socialShopOrderDetails.numberOfIndex } })
        // this.commonService.alertSuccess(res.responseMessage)
      } else {
        this.commonService.alertError(res.responseMessage)
        this.commonService.hideSpinner();
      }
    }, err => {
      console.log(err);
      this.commonService.alertError(err.responseMessage)
      this.commonService.hideSpinner();
      // this.router.navigate(['/payment'], { queryParams: { orderId: err.result._id } })
    })
    // console.log(apiReqData);
    // this.router.navigate(['/payment'],{queryParams:navigationExtra})
  }

  returnToCart() {
    this.router.navigate(['/my-shopping-bag'])
  }

}

