<div class="wrapper">
    <div class="w-90 p-3 d-flex justify-content-around">
        <div class="lft col-md-6 p-3">
            <div class="">
                <h2 class="header-text">Motee Maids</h2>
            </div>
            <div class="content">
                <h4 class="header-text m-0">Importance Notice</h4>
                <p class="header-text">Please note, signature is required for all dress deliveries. This is to ensure
                    the safe delivery of your Motee Maids dress, no exceptions are made to this policy.</p>
            </div>
            <div class="form">
                <div class="contect-form mb-2">
                    <div class="d-flex justify-content-between">
                        <h4 class="header-text m-0">Contact information</h4>
                        <!-- <p class="header-text m-0">Already have an account?<a class="hh" routerLink="/signin">Log in</a></p> -->
                    </div>
                    <div class="field__input-wrapper">
                        <label for="checkout_email" class="field__label field__label--visible"></label>
                        <input placeholder="Email" autocapitalize="off" spellcheck="false" autocomplete="shipping email"
                            data-shopify-pay-handle="true" data-autofocus="true" data-backup="customer_email"
                            aria-describedby="checkout-context-step-one" aria-required="true" class="field__input"
                            [value]="email" size="30" type="email" id="checkout_email">
                    </div>
                </div>


                <div class="shipping mt-4">
                    <h4 class="header-text m-0">Shipping Address</h4>

                    <div *ngFor="let item of shoppingBag?.products" class="card">
                        <div class="row mr0 mL0">
                            <div class="col-sm-5">
                                <div class="card-left-image">
                                    <!-- <img src="../../../../../assets/images/other/dummyimg.png" alt=""> -->
                                    <img [src]="item?.productId?.productImage?.frontImage?.webImage || 'assets/images/other/dummyimg.png'"
                                        class="card-img" alt="...">
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="card-block">
                                    <div class="data-details">
                                        <table>
                                            <tbody>
                                                <tr class="detail-line-name">
                                                    <td colspan="2" class="product-name title-color-dark">
                                                        <h3> {{item?.productId?.productName}}</h3>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light">
                                                    <td class="title">
                                                        Color
                                                    </td>
                                                    <td class="value">
                                                        <!-- <span class="cart-modal-product-color">Ivory</span> -->
                                                        <div class="ic margin-left-30">
                                                            <!-- <i class="fa fa-circle text-danger"></i> -->
                                                            <div *ngIf="item?.colors"
                                                                class="color-block-circle-small mr-3 "
                                                                [style.background-color]="item?.colors?.colorCode">
                                                            </div>
                                                            <div *ngIf="item?.premiumColorId"
                                                                class="color-block-circle-small mr-3 "
                                                                [style.background-color]="item?.premiumColorId?.colorCode">
                                                            </div>

                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light">
                                                    <td class="title">
                                                        Size
                                                    </td>
                                                    <td class="value ">
                                                        <span
                                                            class="cart-modal-product-size margin-left-30">{{item?.size}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="item?.addressDetails" class="text-color-light">
                                                    <td class="title ">
                                                        Address
                                                    </td>
                                                    <td class="value margin-left-30">
                                                        <!-- <span class="cart-modal-product-bodice-size rght-side">
                                                            <p class="product-name title-color-dark m-0">
                                                                {{item?.addressDetails?.address}}</p>
                                                            <p class="text-color-light m-0">{{item?.addressDetails?.city
                                                                + ', ' +
                                                                item?.addressDetails?.state}}</p>
                                                            <p class="text-color-light m-0">
                                                                {{item?.addressDetails?.country + ', ' +
                                                                item?.addressDetails?.zipCode}}</p>
                                                        </span> -->
                                                        <span class="cart-modal-product-bodice-size ">
                                                            <p class="product-name title-color-dark rght-side">
                                                                {{item?.addressDetails?.address}}
                                                                {{item?.addressDetails?.city}}
                                                                {{item?.addressDetails?.state}}
                                                                {{item?.addressDetails?.country}}
                                                                {{item?.addressDetails?.zipCode}}</p>
                                                            <!-- <p class="text-color-light m-0">{{item?.addressDetails?.city
                                                                + ', ' +
                                                                item?.addressDetails?.state}}</p>
                                                            <p class="text-color-light m-0">
                                                                {{item?.addressDetails?.country + ', ' +
                                                                item?.addressDetails?.zipCode}}</p> -->
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light" style="display: none;">
                                                    <td class="title">
                                                        Bodice Size
                                                    </td>
                                                    <td class="value">
                                                        <span class="cart-modal-product-bodice-size"></span>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light" style="display: none;">
                                                    <td class="title">
                                                        Skirt Size
                                                    </td>
                                                    <td class="value">
                                                        <span class="cart-modal-product-skirt-size"></span>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light" style="display: none;">
                                                    <td class="title">
                                                        Skirt length
                                                    </td>
                                                    <td class="value">
                                                        <span class="cart-modal-product-skirt-length"></span>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light" style="display: none;">
                                                    <td class="title">
                                                        Dress length
                                                    </td>
                                                    <td class="value">
                                                        <span class="cart-modal-product-dress-length"></span>
                                                    </td>
                                                </tr>
                                                <!-- <tr class="text-color-light">
                                                    <td class="title">
                                                        Price
                                                    </td>
                                                    <td class="value">
                                                        $<span class="cart-modal-product-price">1495.00</span>
                                                    </td>
                                                </tr> -->
                                                <tr class="text-color-light">
                                                    <td class="title">
                                                        <!-- Quantity -->
                                                        MRP
                                                    </td>
                                                    <td class="value">
                                                        <!-- <span class="cart-modal-product-qty">10</span> -->
                                                        <span class="margin-left-30">
                                                            {{(item?.enteredQuantity * item?.totalMrp) | currency:
                                                            item?.currencyCode}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div class="card-btn-container mt10">
                                    <a class="btn btn-sm card-btn mL10 buttonGlobal"
                                        (click)="selectAddressModalOpen(item)">Select Address</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="card">
                        <div class="row mr0 mL0">
                            <div class="col-sm-5">
                                <div class="card-left-image">
                                    <img src="assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg" class="card-img" alt="...">
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="card-block">
                                    <div class="data-details">
                                        <table>
                                            <tbody>
                                                <tr class="detail-line-name">
                                                    <td colspan="2" class="product-name title-color-dark">
                                                        <h3> LW203 - Tulle Trapeze Dress</h3>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light">
                                                    <td class="title">
                                                        Color
                                                    </td>
                                                    <td class="value">

                                                        <div class="ic">
                                                            <i class="fa fa-circle text-danger"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light">
                                                    <td class="title">
                                                        Size
                                                    </td>
                                                    <td class="value">
                                                        <span class="cart-modal-product-size">30</span>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light" style="display: none;">
                                                    <td class="title">
                                                        Bodice Size
                                                    </td>
                                                    <td class="value">
                                                        <span class="cart-modal-product-bodice-size"></span>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light" style="display: none;">
                                                    <td class="title">
                                                        Skirt Size
                                                    </td>
                                                    <td class="value">
                                                        <span class="cart-modal-product-skirt-size"></span>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light" style="display: none;">
                                                    <td class="title">
                                                        Skirt length
                                                    </td>
                                                    <td class="value">
                                                        <span class="cart-modal-product-skirt-length"></span>
                                                    </td>
                                                </tr>
                                                <tr class="text-color-light" style="display: none;">
                                                    <td class="title">
                                                        Dress length
                                                    </td>
                                                    <td class="value">
                                                        <span class="cart-modal-product-dress-length"></span>
                                                    </td>
                                                </tr>

                                                <tr class="text-color-light">
                                                    <td class="title">
                                                        Quantity
                                                    </td>
                                                    <td class="value">
                                                        <span class="cart-modal-product-qty">10</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div class="card-btn-container mt10">
                                    <a class="btn btn-sm card-btn mL10 buttonGlobal" data-toggle="modal" data-target="#selectAddressModal">Select Address</a>
                                </div>
                            </div>
                        </div>
                    </div> -->




                </div>




            </div>
            <!-- <div class="foot d-flex mt-4  justify-content-between align-items-center">
                <div class="lt d-flex justify-content-center align-items-center">
                    <i class="fa fa-angle-left mr-1"></i>
                    <p class="header-text m-0">Return to cart</p>
                </div>
                <div class="rt">
                    <button class="buttonGlobal" routerLink="/bridal-collection-list">Continue to Shopping</button>
                </div>
            </div> -->
        </div>
        <div class="vlt"></div>
        <div class="col-md-4 p-3">
            <mat-card>
                <!-- <mat-card-content>
                    <div class="lht">

                        <figure>
                            <img src="assets/images/other/bridal.jpg" alt="">
                        </figure>
                        <div>
                            <p class="header-text m-0">a</p>
                            <p class="header-text m-0">b</p>
                        </div>
                        <div>
                            <p class="header-text m-0">c</p>
                        </div>
                    </div>
                </mat-card-content> -->
                <!-- <mat-card-content class="mat-card-content-custom">
                    <mat-card-subtitle>Subtotal:</mat-card-subtitle>
                    <mat-card-subtitle>{{subtotal | currency: ipDetails?.geoplugin_currencySymbol}}</mat-card-subtitle>
                </mat-card-content>
                <mat-card-content class="mat-card-content-custom">
                    <mat-card-subtitle>Shipping</mat-card-subtitle>
                    <mat-card-subtitle>{{shippingCharge | currency: ipDetails?.geoplugin_currencySymbol}}
                    </mat-card-subtitle>
                </mat-card-content> -->
                <!-- <mat-card-content class="mat-card-content-custom">
                    <mat-card-subtitle>Total</mat-card-subtitle>
                    <mat-card-subtitle>{{total | currency: ipDetails?.geoplugin_currencySymbol}}</mat-card-subtitle>
                </mat-card-content> -->

                <hr>
                <mat-card-content class="mat-card-content-custom mt-4 mb-4">
                    <mat-card-title>Total</mat-card-title>
                    <mat-card-title>{{total | currency: ipDetails?.geoplugin_currencySymbol}}</mat-card-title>
                </mat-card-content>
                <!-- <button mat-raised-button class="buttonGlobal" color="accent"
                    routerLink="/multi-address-payment">CHECKOUT</button> -->
                <button mat-raised-button class="buttonGlobal" color="accent" (click)="checkout()">CHECKOUT</button>

            </mat-card>
        </div>
    </div>
</div>




<!--  centered modal -->
<div class="modal fade" id="selectAddressModal" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Please
                    select address!
                </h2>
                <!-- <div class="form-element-wrapper"> -->
                <div class="border-btn-wrapper">
                    <button class="submit-form-btn buttonGlobal btn-add-cust" id="submit-cart-modal"
                        data-dismiss="modal" data-toggle="modal" data-target="#addNewAddressModalId">
                        + Add
                    </button>
                </div>
                <!-- </div> -->
                <div class="">
                    <!-- <div class="hidden-desktop">
                      <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                  </div> -->

                    <div class="image-wrapper">


                        <div class="data-details">

                            <div class="address-card-box-shadow" *ngFor="let item of listAddressArray">
                                <div class="row mt-3">
                                    <div class="col-xl-1 col-lg-2  col-md-3 col-sm-3">
                                        <div class="align-checkbox-vertical-center">
                                            <input class="form-check-input" type="radio" name="exampleRadios"
                                                [checked]="item?._id ==selectedProduct?.addressDetails?._id"
                                                id="exampleRadios1" value="option1" (click)="sendRadioButton(item)">
                                        </div>
                                    </div>
                                    <div class="col-xl-11 col-lg-10 col-md-9 col-sm-9">
                                        <div class="row mt-2">
                                            <div class="col-12">
                                                <p class="product-name title-color-dark">{{item?.address}}</p>
                                                <p class="text-color-light m-0">{{item?.state + ',' + item?.city +
                                                    ',' + item?.zipCode}}</p>
                                                <p class="text-color-light">{{getCountry(item?.country)}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="address-card-box-shadow">

                                <div class="row mt-3">
                                    <div class="col-xl-1 col-lg-2  col-md-3 col-sm-3">
                                        <div class="align-checkbox-vertical-center">
                                            <input class="form-check-input" type="radio" name="exampleRadios"
                                                id="exampleRadios1" value="option1" checked>
                                        </div>
                                    </div>
                                    <div class="col-xl-11 col-lg-10 col-md-9 col-sm-9">
                                        <div class="row mt-2">
                                            <div class="col-12">
                                                <p class="product-name title-color-dark">10 Fubar Road</p>
                                                <p class="text-color-light m-0">Fubartown, London SW1A 1AA</p>
                                                <p class="text-color-light">United Kingdom</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->


                        </div>
                        <!-- <div class="total-wrapper">
                            <hr>
                            <table>
                                <tbody>
                                    <tr class="title-color-dark">
                                        <td class="total-title">
                                            Total:
                                        </td>
                                        <td class="total-price">
                                            $<span class="cart-modal-product-total">1495.00</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                    </div>

                </div>


                <div class="form-element-wrapper">
                    <div class="border-btn-wrapper">
                        <div class="submit-form-btn buttonGlobal" id="submit-cart-modal"
                            (click)="setAddressIntoProductArray()">
                            Submit
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>



<!-- add address -->

<!--  centered modal -->
<div class="modal fade" id="addNewAddressModalId" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>
            <form [formGroup]="chackoutForm" action="">
                <div class="modal-atc-body">
                    <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">New
                        Address!
                    </h2>
                    <div class="">
                        <div class="image-wrapper">

                            <div class="data-details">
                                <div class="row mt-2">
                                    <div class="col-md-12">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Address</mat-label>
                                            <input matInput placeholder="Address" formControlName="address"
                                                maxlength="60">
                                        </mat-form-field>
                                        <div class="text-danger"
                                            *ngIf="(chackoutForm.get('address').invalid && (chackoutForm.get('address').touched || chackoutForm.get('address').dirty || isSubmitted));">
                                            <span *ngIf="chackoutForm.get('address').errors.required">
                                                *Address is required.
                                            </span>
                                            <span *ngIf="chackoutForm.get('address').errors.pattern">
                                                *Please enter valid address.
                                            </span>
                                        </div>
                                        <!-- <span class="error"
                                            *ngIf="chackoutForm.get('address').hasError('required') && (chackoutForm.get('address').dirty || chackoutForm.get('address').touched || isSubmitted)">*Please
                                            enter address.</span> -->
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Country</mat-label>
                                            <mat-select (selectionChange)="stateList($event)" formControlName="country">
                                                <mat-option *ngFor="let item of countryListArray"
                                                    [value]="item?.countryCode">{{item?.countryName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div class="text-danger"
                                            *ngIf="(chackoutForm.get('country').invalid && (chackoutForm.get('country').touched || chackoutForm.get('country').dirty || isSubmitted));">
                                            <span *ngIf="chackoutForm.get('country').errors.required">
                                                *Country is required.
                                            </span>
                                        </div>
                                        <!-- <span class="error"
                                            *ngIf="chackoutForm.get('country').hasError('required') && (chackoutForm.get('country').dirty || chackoutForm.get('country').touched || isSubmitted)">*Please
                                            enter country.</span> -->
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>State</mat-label>
                                            <mat-select formControlName="state">
                                                <mat-option *ngFor="let item of stateListArray"
                                                    [value]="item?.stateName">{{item?.stateName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div class="text-danger"
                                            *ngIf="(chackoutForm.get('state').invalid && (chackoutForm.get('state').touched || chackoutForm.get('state').dirty || isSubmitted));">
                                            <span *ngIf="chackoutForm.get('state').errors.required">
                                                *State is required.
                                            </span>
                                        </div>
                                        <!-- <span class="error"
                                            *ngIf="chackoutForm.get('state').hasError('required') && (chackoutForm.get('state').dirty || chackoutForm.get('state').touched || isSubmitted)">*Please
                                            enter state.</span> -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>City</mat-label>
                                            <input matInput placeholder="City" formControlName="city">
                                        </mat-form-field>
                                        <div class="text-danger"
                                            *ngIf="(chackoutForm.get('city').invalid && (chackoutForm.get('city').touched || chackoutForm.get('city').dirty || isSubmitted));">
                                            <span *ngIf="chackoutForm.get('city').errors.required">
                                                *City is required.
                                            </span>
                                            <span *ngIf="chackoutForm.get('city').errors.pattern">
                                                *Please enter valid city.
                                            </span>
                                        </div>
                                        <!-- <span class="error"
                                            *ngIf="chackoutForm.get('city').hasError('required') && (chackoutForm.get('city').dirty || chackoutForm.get('city').touched || isSubmitted)">*Please
                                            enter city.</span> -->
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Zipcode</mat-label>
                                            <input matInput placeholder="Zipcode" formControlName="zipCode">
                                        </mat-form-field>
                                        <div class="text-danger"
                                            *ngIf="(chackoutForm.get('zipCode').invalid && (chackoutForm.get('zipCode').touched || chackoutForm.get('zipCode').dirty || isSubmitted));">
                                            <span *ngIf="chackoutForm.get('zipCode').errors.required">
                                                *Zip code is required.
                                            </span>
                                            <span *ngIf="chackoutForm.get('zipCode').errors.pattern">
                                                *Please enter valid zip code.
                                            </span>
                                        </div>
                                        <!-- <span class="error"
                                            *ngIf="chackoutForm.get('zipCode').hasError('required') && (chackoutForm.get('zipCode').dirty || chackoutForm.get('zipCode').touched || isSubmitted)">*Please
                                            enter zipCode.</span> -->

                                    </div>
                                </div>



                            </div>


                        </div>

                    </div>


                    <div class="form-element-wrapper">
                        <div class="border-btn-wrapper">
                            <div class="submit-form-btn buttonGlobal" id="submit-cart-modal" (click)="addNewAddress()">
                                Submit
                            </div>
                        </div>
                    </div>


                </div>
            </form>
        </div>
    </div>
</div>