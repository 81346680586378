<mat-card class="mat-card-custom">
    <mat-card-title>Welcome, Lets get started with your Custom Dress !</mat-card-title>
    <form [formGroup]="guestRegistratonForm" class="main-content">
        <!-- Name -->
        <div class="p-4 pb-0">
            <div>
                <mat-label class="mat-label-custom">Enter your Name</mat-label>
                <input type="text" placeholder="First name" formControlName="name">
                <span class="error"
                    *ngIf="guestRegistratonForm.get('name').hasError('required') && (guestRegistratonForm.get('name').touched ||guestRegistratonForm.get('name').dirty)">
                    *Name is required.</span>
            </div>
        </div>

        <!-- Wedding/Event Date -->
        <div class="p-4 pb-0">
            <div>
                <mat-label class="mat-label-custom">Wedding/Event Date</mat-label>
                <input type="date" placeholder="Wedding/Event date" formControlName="date">
                <span class="error"
                    *ngIf="guestRegistratonForm.get('date').hasError('required') && (guestRegistratonForm.get('date').touched ||guestRegistratonForm.get('date').dirty)">
                    *Wedding/Event date is required.</span>
            </div>
        </div>

        <!-- phone number -->
        <div class="p-4 pb-0">
            <div>
                <mat-label class="mat-label-custom" style="display: block;">Phone Number</mat-label>
                <div class="d-flex">
                    <div class="pr-1">
                        <select name="" id="country-code" formControlName="countryCode" class="w-100">
                            <option value="+1">+1</option>
                        </select>
                        <span class="error"
                            *ngIf="guestRegistratonForm.get('countryCode').hasError('required') && (guestRegistratonForm.get('countryCode').touched ||guestRegistratonForm.get('countryCode').dirty)">
                            *Country code is required.</span>
                    </div>
                    <div class="w-100">
                        <input type="text" placeholder="Phone no." formControlName="phoneNo" maxlength="14">
                        <span class="error"
                            *ngIf="guestRegistratonForm.get('phoneNo').hasError('required') && (guestRegistratonForm.get('phoneNo').touched || guestRegistratonForm.get('phoneNo').dirty)">*Phone
                            no. is required.</span>
                        <span class="error" *ngIf="guestRegistratonForm.get('phoneNo').hasError('pattern')">*Please
                            enter valid phone no.</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- email id -->
        <div class="d-flex p-4 pb-0" style="justify-content: space-between;">
            <div class="w-50 mr-2">
                <mat-label class="mat-label-custom">Email ID</mat-label>
                <input type="text" placeholder="Email id" formControlName="email" maxlength="256">
                <span class="error"
                    *ngIf="guestRegistratonForm.get('email').hasError('required') && (guestRegistratonForm.get('email').touched || guestRegistratonForm.get('email').dirty)">*email
                    id is required.</span>
                <span class="error" *ngIf="guestRegistratonForm.get('email').hasError('pattern')">*Please enter a valid
                    email address.</span>
            </div>
            <!-- instagram -->
            <div class="w-50">
                <mat-label class="mat-label-custom">Instagram(optional)</mat-label>
                <input type="text" placeholder="Address line 2">
            </div>
        </div>
    </form>
    <!-- button -->
    <div class="continue">
        <button class="w-25" mat-raised-button color="accent"
            [disabled]="guestRegistratonForm.invalid">Continue</button>
    </div>
</mat-card>
<mat-divider></mat-divider>
<div>
    <p class="p-3" style="font-size: 11px;">2021–2022 All Rights Reserved. XYZ® is a registered trademark of The ABCD
        Group.<a class="text-decoration-underline">Cookie Preferences</a>,<a class="text-decoration-underline">
            Privacy</a>, and <a class="text-decoration-underline">Terms</a>.</p>
</div>