import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { RegEx } from 'src/app/config/reg-ex';
import { CommonService } from 'src/app/provider/common.service';
declare var $;

@Component({
  selector: 'app-checkout-multiple-address',
  templateUrl: './checkout-multiple-address.component.html',
  styleUrls: ['./checkout-multiple-address.component.scss']
})
export class CheckoutMultipleAddressComponent implements OnInit {
  chackoutForm: FormGroup;
  isSubmitted: boolean = false


  email = localStorage.getItem('email')
  countryListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  stateListArray: any;
  shoppingBag: any;
  totalItems: any;
  address: any;
  state: any;
  zipCode: any;
  country: any;
  addressId: any;
  city: any;
  followinglistData: any;
  groupId: any;
  folowingListOption: any;
  ipDetails: any;
  subtotal: number = 0;
  shippingCharge: number = 0;
  total: number = 0;
  socialShopOrderDetails: any;
  constructor(public activatedRoute: ActivatedRoute, private commonService: CommonService, private router: Router) {
    this.ipDetails = this.commonService.getIPDetails();

  }


  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.socialShopOrderDetails) {
        this.socialShopOrderDetails = JSON.parse(res.socialShopOrderDetails)
        this.productListInGroup(this.socialShopOrderDetails.groupId)
      }
    })
    this.formValidation()
    this.countryListDetail()
    this.listAddress();
  }

  formValidation() {
    this.chackoutForm = new FormGroup({
      'address': new FormControl("", [Validators.required, Validators.pattern(RegEx.address)]),
      'state': new FormControl("", Validators.required),
      'country': new FormControl("", Validators.required),
      // 'city': new FormControl("", Validators.required),
      'city': new FormControl("", [Validators.pattern(RegEx.city)]),
      'zipCode': new FormControl("", [Validators.required, Validators.pattern(RegEx.internationalZipCode)])
    })
  }


  // addNewAddressModalOpen() {
  //   if (this.chackoutForm.invalid) {
  //     this.isSubmitted = true
  //     return
  //   }
  //   this.isSubmitted = false
  //   $('#addNewAddressModalId').modal('hide')
  // }

  listAddressArray: any = [];

  /**
   * @param listAddress function is used to get address list
   */
  listAddress() {
    let apiReqUrl = ApiEndPoint.listAddress
    let apiReqData = {
      page: this.currentPage,
      limit: this.itemPerPage,
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log("change password response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        this.listAddressArray = res.result ? res.result.docs : []
        this.countryListDetail();
        localStorage.removeItem('addressId')
      } else {
        this.listAddressArray = []
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      this.listAddressArray = []
      this.commonService.hideSpinner()
    })
  }

  /**
   * @param stateList function is used to get contry list
   */
  stateList(countryCode) {
    let apiReqUrl = `product/stateList?countryCode=${countryCode.value || countryCode}`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.stateListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    })
  }

  /**
   * @param countryListDetail function is used to get country list 
   */
  countryListDetail() {
    let apiReqUrl = 'product/countryList'
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.countryListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    })
  }

  /**
   * @param addNewAddress function is used to add new addres
   */
  addNewAddress() {
    if (this.chackoutForm.invalid) {
      this.isSubmitted = true
      return
    }
    let apiReqUrl = ApiEndPoint.addAddress
    let apiReqData = {
      address: this.chackoutForm.value.address,
      country: this.chackoutForm.value.country,
      state: this.chackoutForm.value.state,
      city: this.chackoutForm.value.city,
      zipCode: this.chackoutForm.value.zipCode
    }
    this.commonService.removeEmptyKey(apiReqData)

    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        // this.countryListArray = res.result ? res.result : []
        this.commonService.hideSpinner()
        $('#addNewAddressModalId').modal('hide')
        localStorage.removeItem('addressId')
        this.listAddress();
        this.chackoutForm.reset();
        this.isSubmitted = false
      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    }, err => {
      if (err['responseCode'] == 400) {
        this.commonService.alertError(err.responseMessage);
      }
    })
  }

  getCountry(countryCode) {
    if (!this.countryListArray.length) {
      return
    }
    let temp = this.countryListArray.filter((item) => {
      return item?.countryCode == countryCode
    })
    // console.log(temp);
    return temp[0]?.countryName
  }

  /**
   * @param myShopingBagApi function is used to show product list in bag
   */
  // myShopingBagApi() {
  //   let apiReqUrl = `${ApiEndPoint.shoppingbagList + '?page=' + this.currentPage + '&limit=' + this.itemPerPage}`
  //   this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
  //     if (res.responseCode == 200) {
  //       this.shoppingBag = res.result.docs
  //       this.totalItems = res.result.total
  //       this.getTotalProductPrice()
  //       this.commonService.hideSpinner()
  //     } else {
  //       this.shoppingBag = []
  //       this.totalItems = 0
  //       this.commonService.hideSpinner()
  //     }
  //   }, (err) => {
  //     if (err.responseCode == 404) {
  //       this.shoppingBag = []
  //       this.totalItems = 0
  //       this.commonService.hideSpinner()
  //     }
  //   })
  // }

  productIndex: any;
  productListInGroupDetails: any;
  // /group/productListInGroup
  productListInGroup(groupId) {
    // const indexes = [];
    let apiReqUrl = `${ApiEndPoint.productListInGroup + '?groupId=' + groupId}`;
    // let apiReqData = {
    //   'groupId': groupId
    // }
    this.commonService.getApi(apiReqUrl, 1).subscribe(
      (res: any) => {
        console.log(res);
        if (res['responseCode'] == 200) {
          this.shoppingBag = res.result;
          this.getTotalProductPrice()
        }
      },
      (err) => {
        console.log(err);
      })
  }

  addressChoosen
  sendRadioButton(item) {
    // this.address = address,
    //   this.state = state,
    //   this.city = city
    // this.zipCode = zipCode,
    //   this.country = country,
    //   this.addressId = _id
    console.log(item);
    this.addressChoosen = item
  }
  getTotalProductPrice() {
    this.shoppingBag.products.forEach(element => {
      console.log(element);
      this.subtotal = this.subtotal + element.totalMrp * element.enteredQuantity
      this.total = this.subtotal + this.shippingCharge
    });
  }

  checkout() {
    // console.log(this.addressId);
    // if (this.addressId == undefined) {
    //   // console.log("address");
    //   return this.commonService.alertError('Please select address to continue.')
    // }
    var countAddressDetailsKey = 0
    this.shoppingBag.products.filter((o) => {
      if (o.hasOwnProperty('addressDetails')) {
        countAddressDetailsKey += 1
      }
    })
    if (countAddressDetailsKey !== Number(this.shoppingBag.numberOfDresses)) {
      return this.commonService.alertError('Please select address to all corresponding dress then continue checkout.')
      // return this.commonService.alertError('कृपया सभी संबंधित ड्रेस के लिए पता चुनें और फिर चेकआउट जारी रखें।')
    }
    console.log(countAddressDetailsKey);
    let apiReqUrl = `${ApiEndPoint.createMultipleOrder + '?groupId=' + this.socialShopOrderDetails.groupId}`;
    let productWithAddress = this.shoppingBag.products.map((element: any) => {
      return this.commonService.removeEmptyKey({
        userId: element?.userId?._id,
        shippingAddress: element?.addressDetails?._id,
        productId: element.productId._id,

        // product details
        armId: element.armId == null ? '' : element.armId,
        colors: element?.colors ? element?.colors._id : '',
        premiumColorId: element?.premiumColorId ? element?.premiumColorId._id : '',
        convertedRate: element?.convertedRate,
        dressLength: element.dressLength,
        embellishmentId: element.embellishmentId == null ? '' : element.embellishmentId,
        enteredQuantity: element.enteredQuantity,
        productType: element.productType,
        size: element.size,
        skirtExtraLength: element.skirtExtraLength,
        // totalMrp: element.totalMrp
        productPrice: element.totalMrp
      })
    })

    let apiReqData = {
      // totalPrice: this.total,
      // shippingCharge: this.shippingCharge,
      // subTotal: this.subtotal,
      // shippingAddress: this.addressId,
      email: this.email,

      payType: this.shoppingBag.payType,
      // groupId: this.socialShopOrderDetails.groupId,
      // totalMrp: this.socialShopOrderDetails.payType,
      // currencyCode: this.socialShopOrderDetails.payType,
      currencyCode: this.ipDetails.geoplugin_currencyCode,
      addressType: 'DELIVER_AT_MULTIPLE_ADDRESS',
      productDetails: productWithAddress
    }

    console.log(apiReqData);
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log(res);
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner();
        // this.groupId = res.result.groupId
        // this.followinglistData = res["result"]["docs"];
        // for (let item of this.followinglistData) {
        //   this.folowingListOption.push(true)
        // }
        this.router.navigate(['/multi-address-payment'], { queryParams: { orderId: res.result._id } })
        this.commonService.alertSuccess(res.responseMessage)
      } else {
        this.commonService.alertError(res.responseMessage)
        this.commonService.hideSpinner();
      }
    }, err => {
      console.log(err);
      this.commonService.hideSpinner();
      this.commonService.alertError(err.responseMessage)
      // this.router.navigate(['/payment'], { queryParams: { orderId: err.result._id } })
    })
  }

  selectedProduct
  selectAddressModalOpen(item) {
    console.log(item);
    this.selectedProduct = item
    $('#selectAddressModal').modal('show')
  }

  setAddressIntoProductArray() {
    $('#selectAddressModal').modal('hide')
    this.shoppingBag.products = this.shoppingBag.products.map(x => {
      if (x.numberOfIndex == this.selectedProduct.numberOfIndex) {
        return { ...x, addressDetails: this.addressChoosen }
      } else {
        return { ...x }
      }
    })
    console.log(this.shoppingBag);
  }
}
