<div class="wrapper">

    <!-- second section (text and input field text) -->
    <!-- <div class="top-cls">
        <div class="txt-clr">
            <h2 class="header-text">HERE WE GO</h2>
        </div>
        <div class="top-head d-flex justify-content-center">
            <input type="text" class="new-first">
            <button type="submit" class="new-btnP header-text">Subscribe</button>
        </div>
        <br>
        <div class="text-center">
            <h4 class="header-text">Sign up to receive style advice on wedding wear that just fits - your style, your
                vision,
                you.</h4>
        </div>
    </div> -->

    <div *ngIf="!searchStatus" class="content-section">

        <div class="text-center">
            <div>

                <h2 class="header-text">
                    Sorry, we couldn't find any results matching
                </h2>
                <span class="header-text">"Video"</span>

                <div class="top-head d-flex justify-content-center">
                    <input type="text" class="new-first" [(ngModel)]="search" (keyup.enter)="searchList()">
                    <button type="submit" class="new-btnP header-textzz" (click)="searchList()">Submit</button>
                </div>
            </div>

            <!-- search tips section -->
            <div class="mt-3 text-color-light">
                <p>Here are a few helpful tips:
                    <br>
                    Check your spelling;
                    <br>
                    Search for simpler, shorter terms;
                    <br>
                    Search for something less specific.
                </p>
            </div>

        </div>
        <hr class="line-color-light">
        <div class="card-section-custom text-center">
            <h1 class="title-color-dark">IF YOU CAN'T FIND WHAT YOU ARE LOOKING FOR
                <br>
                LET US HELP YOU
            </h1>
            <div class="row">
                <div class="col-md-4">
                    <div class="card">

                        <div class="card-body title-color-dark">
                            <div>
                                <em class="fa fa-envelope"></em>
                            </div>
                            <div>
                                <p>Email us at</p>
                                <p>customerservice@moteemaid.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">

                        <div class="card-body title-color-dark">
                            <div>
                                <i class="fa fa-commenting" aria-hidden="true"></i>
                            </div>
                            <div>
                                <p>Chat with our personal Stylist</p>
                                <p>Open Live Chat</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card bounce">

                        <div class="card-body title-color-dark">
                            <div>
                                <em class="fa fa-mobile"></em>
                            </div>
                            <div>
                                <p>Call or text us at</p>
                                <p>212-121-2121</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- search result -->
    <div *ngIf="searchStatus" class="content-section">
        <div class="text-center">
            <div>
                <div class="top-head d-flex justify-content-center">
                    <input type="text" class="new-first" [(ngModel)]="search"
                        placeholder="Search By Category Name or Product Name" (keyup.enter)="searchList()">
                    <button type="submit" class="new-btnP header-text buttonGlobal"
                        (click)="searchList()">Submit</button>
                </div>
            </div>
        </div>
        <hr class="line-color-light">
        <!-- <div class="card-section-custom text-center">
            <div class="row" >
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6" style="padding-bottom: 20px;" *ngFor="let item of searchListArray">
                    <div class="card new-cls">

                        <div class="card-body title-color-dark">
                            <div class="hover-reveal-effect">
                                <img [src]="item?.productDetails?.image[0]?.imageUrl?.webImage" class="grid-view-item__image productFullImg lazyautosizes lazyloaded" alt="">
                                <div class="descrip">
                                    <span class="header-text">{{item?.productName}}</span>
                                    <p class="header-text">{{item?.productPrice[0]?.symbol}}{{item?.productPrice[0]?.MRP}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="new-bridal-cls">

            <div class="row">
                <!-- <div class="row"> -->

                <div class="fifth-img col-lg-4 col-md-6 col-sm-6 col-xs-6" style="padding-bottom: 20px;"
                    *ngFor="let item of searchListArray">
                    <!-- <div class="flip-box">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img class="fifth-img-show"
                                    [src]="item?.productDetails?.image[0]?.imageUrl?.webImage" alt=""
                                    (click)="navigateToViewMotherDress(item?._id)">
                            </div>
                            <div class="flip-box-back">
                                <img class="fifth-img-show"
                                    [src]="item?.productDetails?.image[1]?.imageUrl?.webImage" alt=""
                                    (click)="navigateToViewMotherDress(item?._id)">
                            </div>
                        </div>
                    </div> -->
                    <div class="imageBox">
                        <img class="fifth-img-show" [src]="item?.productImage?.frontImage?.webImage" alt=""
                            (click)="navigateToViewMotherDress(item?._id)">
                        <img class=" fifth-img-hide" [src]="item?.productImage?.backImage?.webImage" alt=""
                            (click)="navigateToViewMotherDress(item?._id)">
                        <!-- <img class="fifth-img-show"
                        [src]="item?.productDetails?.image[0]?.imageUrl?.webImage" alt=""
                        (click)="navigateToViewMotherDress(item?._id)">
                        <img class=" fifth-img-hide"
                        [src]="item?.productDetails?.image[1]?.imageUrl?.webImage" alt=""
                        (click)="navigateToViewMotherDress(item?._id)"> -->
                    </div>


                    <div class="top">
                        <div class="d-flex justify-content-between align-items-center new-like">
                            <h4 class="text-left pc-p header-text m-0">{{item?.productName}}</h4>
                            <i [ngClass]="item?.isLike?'fa fa-heart ct':'fa fa-heart-o'"
                                (click)="addProductWishlist(item?._id)"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                <!-- ${{item?.productPrice[0]?.MRP}}.00 -->
                                {{item?.countryPrice?.totalMrp | currency:
                                ipDetails?.geoplugin_currencySymbol}}
                            </h4>
                            <div class="ic d-flex">
                                <ng-container *ngFor="let color of item.colorDetails">
                                    <div class="image-custom-list" [style.background-color]="color?.colorCode">
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <!-- <h4 class="text-left header-text">Buy fabric swatches in velvet, satin, and more fabrications to help wedding plan.</h4> -->
                </div>

            </div>

            <!-- <div class="row" *ngIf="searchListArray.length=='' && searchEventStatus==true"> -->
            <div class="row" *ngIf="!searchListArray.length && searchEventStatus==true && !isLoading">
                <!-- <span >No results found</span> -->
                <img class="no-result-found" src="/assets/images/No-results-found.png" alt="">
            </div>

        </div>
    </div>
</div>