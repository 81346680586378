import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyShoppingBagComponent } from './pages/customer/account/my-shopping-bag/my-shopping-bag.component';
// import { MyProfileComponent } from './pages/customer/account/my-profile/my-profile.component';
import { SigninComponent } from './pages/customer/auth/signin/signin.component';
import { SignupComponent } from './pages/customer/auth/signup/signup.component';
import { ForgotPasswordComponent } from './pages/customer/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/customer/auth/reset-password/reset-password.component';
import { OtpVerificationComponent } from './pages/customer/auth/otp-verification/otp-verification.component';
import { PrivacyPolicyComponent } from './pages/customer/static-content/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './pages/customer/static-content/terms-condition/terms-condition.component';
import { CookiePreferenceComponent } from './pages/customer/static-content/cookie-preference/cookie-preference.component';
import { FooterComponent } from './pages/customer/sharing/footer/footer.component';
import { AddBlogDialogComponent } from './pages/customer/motee-collection-management/customize/add-blog-dialog/add-blog-dialog.component';
import { CollectionListComponent } from './pages/customer/mother-of-bride-collection-management/collection-list/collection-list.component';
import { SwatchesCollectionListComponent } from './pages/customer/swatches-collection-management/swatches-collection-list/swatches-collection-list.component';
import { ViewMothersDressComponent } from './pages/customer/mother-of-bride-collection-management/view-mothers-dress/view-mothers-dress.component';
// import { MyAddressComponent } from './pages/customer/account/my-address/my-address.component';
// import { MyOrdersComponent } from './pages/customer/account/my-orders/my-orders.component';
import { HeaderComponent } from './pages/customer/sharing/header/header.component';
import { AddNewAddressComponent } from './pages/customer/account/add-new-address/add-new-address.component';
// import { EditProfileComponent } from './pages/customer/account/edit-profile/edit-profile.component';
import { HomepageComponent } from './pages/customer/homepage/homepage.component';
import { ViewSwatchesComponent } from './pages/customer/swatches-collection-management/view-swatches/view-swatches.component';
import { ViewBridesmaidDressComponent } from './pages/customer/bridesmaid-collection-management/view-bridesmaid-dress/view-bridesmaid-dress.component';
import { BridesmaidCollectionListComponent } from './pages/customer/bridesmaid-collection-management/bridesmaid-collection-list/bridesmaid-collection-list.component';
import { ViewBridalDressComponent } from './pages/customer/bridal-collection-management/view-bridal-dress/view-bridal-dress.component';
import { BridalCollectionListComponent } from './pages/customer/bridal-collection-management/bridal-collection-list/bridal-collection-list.component';
import { CatelogueCollectionListComponent } from './pages/customer/catelogue-collection-management/catelogue-collection-list/catelogue-collection-list.component';
// import { OrderStatusComponent } from './pages/customer/account/order-status/order-status.component';
import { CreateChatRoomComponent } from './pages/customer/chat-room/create-chat-room/create-chat-room.component';
import { InviteChattingComponent } from './pages/customer/chat-room/invite-chatting/invite-chatting.component';
// import { MyWishlistComponent } from './pages/customer/account/my-wishlist/my-wishlist.component';
import { StoreLocatorComponent } from './pages/customer/store-locator/store-locator.component';
import { MoteeCollectionListComponent } from './pages/customer/motee-collection-management/motee-collection-list/motee-collection-list.component';
import { CreateDressPreviewComponent } from './pages/customer/motee-collection-management/customize/create-dress-preview/create-dress-preview.component';
import { CheckoutSelectAddressComponent } from './pages/customer/account/checkout-select-address/checkout-select-address.component';
import { CheckoutSavedAddressComponent } from './pages/customer/account/checkout-saved-address/checkout-saved-address.component';
import { LoginComponent } from './pages/retailer/auth/login/login.component';
import { ForgotPasswordRetailerComponent } from './pages/retailer/auth/forgot-password-retailer/forgot-password-retailer.component';
import { ResetPasswordRetailerComponent } from './pages/retailer/auth/reset-password-retailer/reset-password-retailer.component';
import { OtpVerificationRetailerComponent } from './pages/retailer/auth/otp-verification-retailer/otp-verification-retailer.component';
import { RetailerSignupComponent } from './pages/retailer/auth/retailer-signup/retailer-signup.component';
import { WelcomeComponent } from './pages/retailer/homepage/welcome/welcome.component';
import { GetStartedComponent } from './pages/retailer/homepage/get-started/get-started.component';
import { OrderSampleSetComponent } from './pages/retailer/homepage/order-sample-set/order-sample-set.component';
import { DressCollectionListComponent } from './pages/customer/motee-collection-management/dress-collection-list/dress-collection-list.component';
import { CreateYourDressComponent } from './pages/customer/motee-collection-management/customize/create-your-dress/create-your-dress.component';
import { AllOrdersComponent } from './pages/retailer/homepage/all-orders/all-orders.component';
import { CheckoutSelectStoreAddressComponent } from './pages/retailer/homepage/checkout-select-store-address/checkout-select-store-address.component';
import { MyAccountComponent } from './pages/retailer/account/my-account/my-account.component';
import { RetailerHeaderComponent } from './pages/retailer/sharing-retailer/retailer-header/retailer-header.component'
import { EditMyAddressComponent } from './pages/retailer/account/edit-my-address/edit-my-address.component';
import { ChangePasswordComponent } from './pages/retailer/account/change-password/change-password.component';
import { RegistrationComponent } from './pages/retailer/guest/registration/registration.component';
import { CatelogueComponent } from './pages/retailer/guest/catelogue/catelogue.component';
import { InfluencerLoginComponent } from './pages/influencer/auth/influencer-login/influencer-login.component';
import { InfluencerSignupComponent } from './pages/influencer/auth/influencer-signup/influencer-signup.component';
import { CouponListComponent } from './pages/influencer/coupon-management/coupon-list/coupon-list.component';
import { VisitorListComponent } from './pages/retailer/visitor-management/visitor-list/visitor-list.component';
import { ViewVisitorComponent } from './pages/retailer/visitor-management/view-visitor/view-visitor.component';
import { ProductListComponent } from './pages/retailer/product-management/product-list/product-list.component';
import { ViewProductComponent } from './pages/retailer/product-management/view-product/view-product.component';
import { PressComponent } from './pages/customer/stories/press/press.component';
import { BridalPartyComponent } from './pages/customer/bridal-collection-management/bridal-party/bridal-party.component';
import { AuthGuard } from './guard/auth.guard';
import { BlogComponent } from './pages/customer/stories/blog/blog.component';
import { DashboardGuard } from './guard/dashboard.guard';
import { RealBridesComponent } from './pages/customer/stories/real-brides/real-brides.component';
import { ForgotPasswordInfluencerComponent } from './pages/influencer/auth/forgot-password-influencer/forgot-password-influencer.component';
import { CollectionBridalPartyComponent } from './pages/customer/bridal-collection-management/collection-bridal-party/collection-bridal-party.component';
import { EventDressesComponent } from './pages/customer/bridal-collection-management/event-dresses/event-dresses.component';
import { FeaturesComponent } from './pages/customer/bridal-collection-management/features/features.component';
import { ReadyToShipBridalComponent } from './pages/customer/bridal-collection-management/ready-to-ship-bridal/ready-to-ship-bridal.component';
import { TrunkComponent } from './pages/customer/trunk-shows/trunk/trunk.component';
import { TradeComponent } from './pages/customer/trunk-shows/trade/trade.component';
import { VisitNycSalonComponent } from './pages/customer/book-an-appointment/visit-nyc-salon/visit-nyc-salon.component';
import { FaqComponent } from './pages/customer/book-an-appointment/faq/faq.component';
import { ChatRoomListComponent } from './pages/customer/chat-room/chat-room-list/chat-room-list.component';
import { SelectDateComponent } from './pages/customer/book-an-appointment/select-date/select-date.component';
import { MaskListComponent } from './pages/customer/swatches-collection-management/masks/mask-list/mask-list.component';
import { ViewMaskComponent } from './pages/customer/swatches-collection-management/masks/view-mask/view-mask.component';
import { NouvelleSwatchesListComponent } from './pages/customer/swatches-collection-management/nouvelle-swatches-management/nouvelle-swatches-list/nouvelle-swatches-list.component';
import { ViewNouvelleSwatchesComponent } from './pages/customer/swatches-collection-management/nouvelle-swatches-management/view-nouvelle-swatches/view-nouvelle-swatches.component';
import { ClientInformationComponent } from './pages/customer/book-an-appointment/client-information/client-information.component';
import { EventDetailComponent } from './pages/customer/book-an-appointment/event-detail/event-detail.component';
import { ConfirmPaymentComponent } from './pages/customer/book-an-appointment/confirm-payment/confirm-payment.component';
import { ContactUSComponent } from './pages/customer/static-content/contact-us/contact-us.component';
import { JoinOurTeamComponent } from './pages/customer/static-content/join-our-team/join-our-team.component';
import { PartnerProgramComponent } from './pages/customer/static-content/partner-program/partner-program.component';
import { ViewRealBrideComponent } from './pages/customer/stories/view-real-bride/view-real-bride.component';
import { ViewBlogComponent } from './pages/customer/stories/view-blog/view-blog.component';
import { ViewPressComponent } from './pages/customer/stories/view-press/view-press.component';
import { MoteeMaidsBridalComponent } from './pages/customer/bridal-collection-management/motee-maids-bridal/motee-maids-bridal.component';
import { LatestMoteeCollectionComponent } from './pages/customer/motee-collection-management/latest-motee-collection/latest-motee-collection.component';
import { EveningCollectionComponent } from './pages/customer/motee-collection-management/evening-collection/evening-collection.component';
import { SearchComponent } from './pages/customer/sharing/search/search.component';
import { ProfileManagementComponent } from './pages/customer/profile-management/profile-management.component';
import { ProductEventDetailComponent } from './pages/customer/book-an-appointment/product-event-detail/product-event-detail.component';
import { CheckoutMultipleAddressComponent } from './pages/customer/account/checkout-multiple-address/checkout-multiple-address.component';
import { PaymentComponent } from './pages/customer/account/payment/payment.component';
import { MultiAddressPaymentComponent } from './pages/customer/account/multi-address-payment/multi-address-payment.component';
import { OtpVarificationInfluencerComponent } from './pages/influencer/auth/otp-varification-influencer/otp-varification-influencer.component';
import { ResetPasswordInfluecerComponent } from './pages/influencer/reset-password-influecer/reset-password-influecer.component';
import { IndividualPaymentComponent } from './pages/customer/account/individual-payment/individual-payment.component';
import { IndividualCheckoutComponent } from './pages/customer/account/individual-checkout/individual-checkout.component';
import { IndividualPayComponent } from './pages/customer/account/individual-pay/individual-pay.component';
import { CurationListComponent } from './pages/customer/chat-room/curation-list/curation-list.component';

const routes: Routes = [
  { path: "", redirectTo: "homepage", pathMatch: "full" },
  { path: 'homepage', component: HomepageComponent, canActivate: [DashboardGuard] },
  { path: 'search', component: SearchComponent, canActivate: [DashboardGuard] },

  // customer auth
  { path: 'signin', component: SigninComponent, canActivate: [AuthGuard] },
  { path: 'signup', component: SignupComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AuthGuard] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthGuard] },
  { path: 'otp', component: OtpVerificationComponent, canActivate: [AuthGuard] },

  // customer account
  { path: 'add-new-address', component: AddNewAddressComponent, canActivate: [DashboardGuard] },
  { path: 'checkout-saved-address', component: CheckoutSavedAddressComponent, canActivate: [DashboardGuard] },
  { path: 'checkout-select-address', component: CheckoutSelectAddressComponent, canActivate: [DashboardGuard] }, // social shop checkout (same address)
  { path: 'checkout-multiple-address', component: CheckoutMultipleAddressComponent, canActivate: [DashboardGuard] }, // social shop checkout (multiple address)
  { path: 'payment', component: PaymentComponent, canActivate: [DashboardGuard] },
  { path: 'multi-address-payment', component: MultiAddressPaymentComponent, canActivate: [DashboardGuard] }, // social shop payment
  { path: 'single-address-payment', component: IndividualPaymentComponent, canActivate: [DashboardGuard] }, // social shop payment

  { path: 'checkout-individual', component: IndividualCheckoutComponent, canActivate: [DashboardGuard] }, // INDIVIDUAL flow
  { path: 'checkout-individual-pay', component: IndividualPayComponent, canActivate: [DashboardGuard] }, // INDIVIDUAL flow


  // { path: 'edit-profile', component: EditProfileComponent, canActivate: [DashboardGuard] },
  // { path: 'addresses', component: MyAddressComponent },
  // { path: 'my-order', component: MyOrdersComponent },
  // { path: 'my-profile', component: MyProfileComponent },
  // { path: 'order-status', component: OrderStatusComponent },
  { path: 'my-shopping-bag', component: MyShoppingBagComponent, canActivate: [DashboardGuard] },
  { path: 'motee-maids-bridal', component: MoteeMaidsBridalComponent, canActivate: [DashboardGuard] },
  { path: 'evening-collection', component: EveningCollectionComponent, canActivate: [DashboardGuard] },

  { path: 'profile-management', component: ProfileManagementComponent, canActivate: [DashboardGuard] },


  // bridal collection
  { path: 'add-bag', component: AddBlogDialogComponent, canActivate: [DashboardGuard] },
  { path: 'bridal-collection-list', component: BridalCollectionListComponent, canActivate: [DashboardGuard] },
  { path: 'view-bridal-dress', component: ViewBridalDressComponent, canActivate: [DashboardGuard] },
  { path: 'bridal-party', component: BridalPartyComponent, canActivate: [DashboardGuard] },
  { path: 'collection-bridal-party', component: CollectionBridalPartyComponent, canActivate: [DashboardGuard] },
  { path: 'event-dress', component: EventDressesComponent, canActivate: [DashboardGuard] },
  { path: 'feature', component: FeaturesComponent, canActivate: [DashboardGuard] },
  { path: 'readytoship-bridal', component: ReadyToShipBridalComponent, canActivate: [DashboardGuard] },




  // brides maid collection
  { path: 'bridesmaid-collection-list', component: BridesmaidCollectionListComponent, canActivate: [DashboardGuard] },
  { path: 'view-bridesmaid-dress', component: ViewBridesmaidDressComponent, canActivate: [DashboardGuard] },

  // catalogue collection
  { path: 'catelogue-collection-list', component: CatelogueCollectionListComponent, canActivate: [DashboardGuard] },

  // chat room
  { path: 'curation-list', component: CurationListComponent, canActivate: [DashboardGuard] },
  { path: 'create-chat-room', component: CreateChatRoomComponent, canActivate: [DashboardGuard] },
  { path: 'invite-chatting', component: InviteChattingComponent, canActivate: [DashboardGuard] },
  { path: 'chat-room', component: ChatRoomListComponent, canActivate: [DashboardGuard] },

  // motee collection
  // motte collection -> customize
  { path: 'dress-preview', component: CreateDressPreviewComponent, canActivate: [DashboardGuard] },
  { path: 'create-dress', component: CreateYourDressComponent, canActivate: [DashboardGuard] },
  { path: 'dress-collection', component: DressCollectionListComponent, canActivate: [DashboardGuard] },
  { path: 'motee-collection-list', component: MoteeCollectionListComponent, canActivate: [DashboardGuard] },
  { path: 'latest-motee-collection', component: LatestMoteeCollectionComponent, canActivate: [DashboardGuard] },


  // mother of bride collection
  { path: 'mothers-collection-list', component: CollectionListComponent, canActivate: [DashboardGuard] },
  { path: 'view-mothers-dress', component: ViewMothersDressComponent, canActivate: [DashboardGuard] },

  // sharing
  // { path: 'footer', component: FooterComponent },
  // { path: 'header', component: HeaderComponent },

  // static content
  { path: 'return-policy', component: CookiePreferenceComponent, canActivate: [DashboardGuard] },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [DashboardGuard] },
  { path: 'terms-condition', component: TermsConditionComponent, canActivate: [DashboardGuard] },

  // store locator
  { path: 'store-locator', component: StoreLocatorComponent, canActivate: [DashboardGuard] },

  // swatches collection
  { path: 'swatches-collection-list', component: SwatchesCollectionListComponent, canActivate: [DashboardGuard] },
  { path: 'view-swatches', component: ViewSwatchesComponent, canActivate: [DashboardGuard] },


  // ### influencer 
  // auth
  { path: 'influencer-login', component: InfluencerLoginComponent, canActivate: [AuthGuard] },
  { path: 'influencer-signup', component: InfluencerSignupComponent, canActivate: [AuthGuard] },
  { path: 'influencer-forgot', component: ForgotPasswordInfluencerComponent, canActivate: [AuthGuard] },

  // coupon list
  { path: 'coupon-list', component: CouponListComponent, canActivate: [DashboardGuard] },


  // ### retailer 
  // auth
  { path: 'retailer-login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'retailer-forgot', component: ForgotPasswordRetailerComponent, canActivate: [AuthGuard] },
  { path: 'retailer-reset', component: ResetPasswordRetailerComponent, canActivate: [AuthGuard] },
  { path: 'retailer-otp', component: OtpVerificationRetailerComponent, canActivate: [AuthGuard] },
  { path: "retailer-signup", component: RetailerSignupComponent, canActivate: [AuthGuard] },

  // account
  { path: "change-password", component: ChangePasswordComponent, canActivate: [DashboardGuard] },
  { path: 'edit-my-address', component: EditMyAddressComponent, canActivate: [DashboardGuard] },
  { path: 'my-account', component: MyAccountComponent, canActivate: [DashboardGuard] },

  // guest
  { path: 'guest-catelogue', component: CatelogueComponent },
  { path: 'guest-registration', component: RegistrationComponent },

  // homepage
  { path: 'all-order', component: AllOrdersComponent, canActivate: [DashboardGuard] },
  { path: 'checkout-retailers-selcet-address', component: CheckoutSelectStoreAddressComponent, canActivate: [DashboardGuard] },
  { path: 'retailer-get-started', component: GetStartedComponent, canActivate: [DashboardGuard] },
  { path: 'retaile-order-sample-set', component: OrderSampleSetComponent, canActivate: [DashboardGuard] },
  { path: 'retailer-welcome', component: WelcomeComponent, canActivate: [DashboardGuard] },

  // product management
  { path: 'product-list', component: ProductListComponent, canActivate: [DashboardGuard] },
  { path: 'view-product', component: ViewProductComponent, canActivate: [DashboardGuard] },

  // retailer header
  { path: 'retailer-header', component: RetailerHeaderComponent, canActivate: [DashboardGuard] },

  // visitor management
  { path: 'visitor-list', component: VisitorListComponent, canActivate: [DashboardGuard] },
  { path: 'view-visitor', component: ViewVisitorComponent, canActivate: [DashboardGuard] },



  // { path: 'my-wishlist', component: MyWishlistComponent },
  // {path:'store-locator',component:StoreLocatorComponent},

  //customer stories
  { path: 'press', component: PressComponent, canActivate: [DashboardGuard] },
  { path: 'blog', component: BlogComponent, canActivate: [DashboardGuard] },
  { path: 'real-brides', component: RealBridesComponent, canActivate: [DashboardGuard] },
  { path: 'real-brides-view', component: ViewRealBrideComponent, canActivate: [DashboardGuard] },
  { path: 'blog-view', component: ViewBlogComponent, canActivate: [DashboardGuard] },
  { path: 'press-view', component: ViewPressComponent, canActivate: [DashboardGuard] },




  //customer trunks
  { path: 'trunk', component: TrunkComponent, canActivate: [DashboardGuard] },
  { path: 'trade', component: TradeComponent, canActivate: [DashboardGuard] },

  //customer masks
  { path: 'mask-list', component: MaskListComponent, canActivate: [DashboardGuard] },
  { path: 'view-mask', component: ViewMaskComponent, canActivate: [DashboardGuard] },

  //customer nouvelle
  { path: 'nouvelle-swatches-list', component: NouvelleSwatchesListComponent, canActivate: [DashboardGuard] },
  { path: 'view-nouvelle-swatches', component: ViewNouvelleSwatchesComponent, canActivate: [DashboardGuard] },

  //Book an appointmet
  { path: 'visit-nyc-salon', component: VisitNycSalonComponent, canActivate: [DashboardGuard] },
  { path: 'faq', component: FaqComponent, canActivate: [DashboardGuard] },
  { path: 'select-date', component: SelectDateComponent, canActivate: [DashboardGuard] },
  { path: 'client-information', component: ClientInformationComponent, canActivate: [DashboardGuard] },
  { path: 'event-detail', component: EventDetailComponent, canActivate: [DashboardGuard] },
  { path: 'confirm-payment', component: ConfirmPaymentComponent, canActivate: [DashboardGuard] },
  { path: 'product-event-detail', component: ProductEventDetailComponent, canActivate: [DashboardGuard] },


  // static content
  { path: 'contactUs', component: ContactUSComponent, canActivate: [DashboardGuard] },
  { path: 'join-our-team', component: JoinOurTeamComponent, canActivate: [DashboardGuard] },
  { path: 'partner-program', component: PartnerProgramComponent, canActivate: [DashboardGuard] },
  // { path: 'return-policy', component:CookiePreferenceComponent},

  { path: 'otp-verification-influencer', component: OtpVarificationInfluencerComponent, canActivate: [AuthGuard] },
  { path: 'reset-password-influencer', component: ResetPasswordInfluecerComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
