<!-- my order copy -->
<!-- <div class="container"> -->
<div class="content">

    <!-- <div class="order-details">
              <span class="header-text">Order Details</span>
            </div> -->
    <div class="top-text text-center">
        <mat-card-title class="mat-card-title sign-text ml-0 title-color-dark">My Appointment</mat-card-title>
    </div>
    <table class="cart">
        <thead>
            <tr>
                <th class="cart__row-price header-text" scope="col">Appointment Status</th>
                <!-- <th class="cart__row-price header-text" scope="col">Store Address</th> -->
                <th class="cart__row-price header-text" scope="col">Appointment Date</th>
                <th class="cart__row-price header-text" scope="col">Appointment Time</th>
                <th class="cart__row-price header-text" scope="col">Budget</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of appointmentListArray">
                <td class="cart__meta small--text-left">
                    <p>{{item?.appointmentStatus | titlecase}}</p>
                </td>
                <!-- <td class="cart__meta small--text-left">
                        <p>
                            
                        </p>
                    </td> -->
                <!-- <td>
                    <a [href]="'https://www.google.com/maps/@'+item?.storeLocation?.coordinates[0]+','+item?.storeLocation?.coordinates[1]+',5z'"
                        target="_blank" rel="noopener noreferrer">
                        https://www.google.com/maps/@{{item?.storeLocation?.coordinates[0]}},{{item?.storeLocation?.coordinates[1]}},5z
                    </a>
                </td> -->
                <td class="cart__meta small--text-left">
                    <!-- <p>{{item?.appointmentDate | date:'shortDate'}} </p> -->
                    <p>{{item?.appointmentDate | date:'mediumDate'}} </p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>{{item?.slot}}</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>{{(item?.eventDetails?.budget) | currency: ipDetails?.geoplugin_currencySymbol}}</p>
                </td>
            </tr>
            <tr *ngIf="!appointmentListArray?.length">
                <td colspan="4"><span class="d-flex justify-content-center">
                        You don't have upcoming appointments
                    </span>
                </td>

            </tr>
        </tbody>
    </table>
</div>