import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-program',
  templateUrl: './partner-program.component.html',
  styleUrls: ['./partner-program.component.scss']
})
export class PartnerProgramComponent implements OnInit {

  constructor() {
    window.scrollTo(0,0)
   }

  ngOnInit(): void {
  }

}
