import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { AuthService } from 'src/app/provider/auth.service';
import { CommonService } from 'src/app/provider/common.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchStatus: boolean = true;
  searchListArray: any = [];
  search: any;
  searchEventStatus: boolean = false
  like: boolean = false;
  ipDetails: any;

  constructor(public commonService: CommonService, private router: Router, public authService: AuthService, private activatedRoute: ActivatedRoute) {
    this.ipDetails = this.commonService.getIPDetails()
  }



  ngOnInit(): void {
    window.scrollTo(0, 0)
  }

  isLoading: boolean = false
  searchList() {
    if (!this.search) {
      return this.commonService.errorToast('Please input the text to search.')
    } else {
      this.searchEventStatus = true
      let apiReqUrl = 'product/globalSearch'
      let reqData = {
        search: this.search,
        currencyCode: this.ipDetails.geoplugin_currencyCode
        // currencyCode: 'INR'
      }
      this.isLoading = true
      this.commonService.showSpinner()
      this.commonService.postApi(apiReqUrl, reqData, (this.authService.checkAuthToken() ? 1 : 0)).subscribe((res: any) => {
        if (res['responseCode'] == '200') {
          this.searchListArray = res.result.docs
          this.commonService.hideSpinner()
          this.isLoading = false
        } else {
          this.searchListArray = [];
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage)
          this.isLoading = false
        }
      }, (res: any) => {
        if (res.responseCode == 404) {
          this.searchListArray = [];
          this.commonService.hideSpinner();
          this.isLoading = false
        }
      })
    }
  }

  /**
   * @param ApiEndPoint contains end point url
   * @param 
   */
  addProductWishlist(productId) {
    if (!this.authService.checkAuthToken()) {
      return this.router.navigate(['/signin']);
    } else {
      let apiReqUrl = `${ApiEndPoint.addWishListProduct + '?newProductId=' + productId}`
      this.commonService.showSpinner()
      this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner()
          this.searchList()

          //  this.like= !this.like
          //  this.commonService.successToast(res.responseMessage)
        } else {
          this.commonService.hideSpinner()
          this.commonService.errorToast(res.responseMessage)
        }
      })
    }
  }

  navigateToViewMotherDress(productId) {
    this.router.navigate(["/view-mothers-dress"], { queryParams: { productId: productId } })
  }

}
