<!-- Footer -->
<!-- <div class="foot"> -->
<footer class="site-footer">
  <div class="top-foot">
    <div class="page-width">
        <div class="site-footer__content">
            <div class="site-footer__item site-footer__item--center site-footer__item--full-width">
                <div class="site-footer__item-inner site-footer__item-inner--link_list new-lst-dta">
                    <ul class="site-footer__linklist list--inline new-unor">
                        <li class="site-footer__linklist-item new-con" routerLink="/contactUs">
                            <a class="anc header-text">
                                Contact Us
                            </a>
                        </li>
                        <p class="foot-line"></p>
                        <li class="site-footer__linklist-item new-con" routerLink="/join-our-team">
                            <a class="anc header-text">
                                Join Our Team
                            </a>
                        </li>
                        <li class="site-footer__linklist-item new-con" routerLink="/privacy-policy">
                            <a class="anc header-text">
                                Privacy Policy
                            </a>
                        </li>
                        <li class="site-footer__linklist-item new-con" routerLink="/terms-condition">
                            <a class="anc header-text">
                                Terms & Conditions
                            </a>
                        </li>
                        <li class="site-footer__linklist-item new-con" routerLink="/partner-program">
                            <a class="anc header-text">
                                Partner Program
                            </a>
                        </li>
                        <li class="site-footer__linklist-item new-con" routerLink="/faq">
                            <a class="anc header-text">
                                FAQ
                            </a>
                        </li>
                        <li class="site-footer__linklist-item" routerLink="/return-policy">
                            <a class="anc header-text">
                                Return Policy
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- <hr class="site-footer__hr remove-top-margin"> -->
    <!-- <div class="email-form-return-policy-container">
    
        <div class="top-cls">
            <div class="txt-clr">
                <h2 class="header-text">HERE WE GO</h2>
            </div>
            <div class="top-head d-flex justify-content-center">
                <input type="text" class="new-first" placeholder="Email Address">
                <button type="submit" class="new-btnP header-text">Subscribe</button>
            </div>
            <br>
            <div class="text-center">
                <h4 class="header-text">Sign up to receive style advice on wedding wear that just fits - your style, your vision, you.</h4>
            </div>
        </div>

    </div> -->
    <hr class="site-footer__hr remove-top-margin">
    <div class="page-width">
        <ul class="orddr_list p-0">
            <!-- <div class="ulContent d-flex">
                <div class="dropdown">
                    <li class="list_data" routerLink="/user/upload-post"><i class="fa fa-facebook" aria-hidden="true" ></i></li>
        
                </div>
                <div class="dropdown">
                    <li class="list_data" routerLink="/user/upload-post"><i class="fa fa-twitter" aria-hidden="true" ></i></li>
        
                </div>
                <div class="dropdown">
                    <li class="list_data" routerLink="/user/upload-post"><i class="fa fa-pinterest" aria-hidden="true" ></i></li>
        
                </div>
                <div class="dropdown">
                    <li class="list_data" routerLink="/user/upload-post"><i class="fa fa-instagram" aria-hidden="true" ></i></li>
        
                </div>
            </div> -->
            <div class="text-center">
                <h4 class="header-text">Copyright @2022, Motee Maids</h4>
            </div>
        </ul>
    </div>

  </div>
</footer>
<!-- </div> -->
<!-- Footer -->