import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provider/common.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
// import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-store-locator',
  templateUrl: './store-locator.component.html',
  styleUrls: ['./store-locator.component.scss'],
})
export class StoreLocatorComponent implements OnInit {
  // @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  title = 'google-places-autocomplete';
  userAddress: string = ''
  userLatitude: string = ''
  userLongitude: string = ''
  // options: any[]
  long: any = "";
  lat: any = "";
  address: any
  // markers: any;
  // center: any;

  radius: any
  // address: any
  longitude: any;
  latitude: any;

  // addMarker() {
  //   this.markers.push({
  //     // position: {
  //     //   lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
  //     //   lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
  //     // },
  //     position: {
  //       lat: this.latitude,
  //       lng: this.longitude
  //     },
  //     label: {
  //       color: 'red',
  //       text: 'Marker label ' + (this.markers.length + 1),
  //     },
  //     title: 'Marker title ' + (this.markers.length + 1),
  //     options: { animation: google.maps.Animation.BOUNCE },
  //   });
  // }

  // addMarker() {
  //   this.markers.push({
  //     position: {
  //       lat: 35.5362475,
  //       lng: -117.9267386,
  //     },
  //     label: {
  //       color: 'red',
  //       text: 'Marker label ' + (this.markers.length + 1),
  //     },
  //     title: 'Marker title ' + (this.markers.length + 1),
  //     options: { animation: google.maps.Animation.BOUNCE },
  //   });
  // }

  searchForm: FormGroup;
  locationStoreArray: any[] = [];

  // public handleAddressChange(address: Address) {

  //   this.options = []
  //   // console.log(address.formatted_address);
  //   this.longitude = address.geometry.location.lng()
  //   this.latitude = address.geometry.location.lat()

  //   // console.log('lat ', this.latitude, 'long', this.longitude);
  //   this.options.push(address.formatted_address);
  // }


  markers = [];
  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    // mapTypeId: 'hybrid',
    zoomControl: true,
    // scrollwheel: false,
    disableDoubleClickZoom: true,
    // maxZoom: 15,
    // minZoom: 8,

  };

  addMarker() {
    this.markers.push({
      position: {
        // lat: 35.5362475,
        // lng: -117.9267386,
        lat: this.latitude,
        lng: this.longitude
      },
      label: {
        color: 'red',
        text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      options: { animation: google.maps.Animation.BOUNCE },
    });
  }


  click(event: google.maps.MouseEvent) {
    console.log(event);
    this.logCenter();
  }

  logCenter() {
    console.log(JSON.stringify(this.map.getCenter()));
  }
  constructor(public commonService: CommonService) {
    // var a = JSON.parse(localStorage.getItem("searchDetails"))
    // this.options = (a?.location == 'undefined') ? '' : a?.location
    // this.radius = (a?.radius == 'undefined') ? '' : a?.radius
    // this.latitude = a?.lat
    // this.longitude = a?.lang
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      // location: new FormControl('', Validators.required),
      location: new FormControl('', [Validators.required]),
      radiusMeasurement: new FormControl('', Validators.required),
      radius: new FormControl('', Validators.required),
    });

    // this.locationStore();
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        // lat: 35.5362475,
        // lng: -117.9267386,
        lat: this.lat,
        lng: this.long
      };
    });
    console.log(this.center,"ssssssss");
    setTimeout(() => {
      this.addMarker();
    }, 1000)
  }

  maxDistance: any = "1000";
  type: any;
  // lat: any;
  // long: any;

  // handleAddressChange(address: any) {
  //   this.userAddress = address.formatted_address
  //   this.userLatitude = address.geometry.location.lat()
  //   this.userLongitude = address.geometry.location.lng()
  // }


  public handleAddressChange(address: Address) {
    console.log(address);

    // this.options = []
    this.address = address.formatted_address
console.log(this.address,"adddddddd")
    this.longitude = address.geometry.location.lng()
    this.latitude = address.geometry.location.lat()
console.log(this.longitude,this.latitude);


    // // this.options[0] = Number(address.geometry.location.lat())
    // // this.options[1] = Number(address.geometry.location.lng())
    // this.options[0] = Number(address.geometry.location.lng())
    // this.options[1] = Number(address.geometry.location.lat())
    // console.log(this.longitude);
    // console.log(this.latitude);
    // setTimeout(() => {
    //   this.addMarker()
    // }, 2000);
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        // lat: 35.5362475,
        // lng: -117.9267386,
        lat: this.latitude,
        lng: this.longitude
      };
    });
    console.log(this.center);
    setTimeout(() => {
      this.addMarker();
    }, 1000)
  }

  // user/locationStore?maxDistance=23&type=Kilometer&lat=13&long=1323
  locationStore() {
    // let apiReqUrl = `${
    //   'user/locationStore?maxDistance=' +
    //   this.maxDistance +
    //   '&type=' +
    //   this.type +
    //   '&lat=' +
    //   this.lat +
    //   '&long=' +
    //   this.long
    // }`;
    let apiReqUrl = `${
      // 'user/locationStore?maxDistance=' +
      // ((this.searchForm.value.location) ? (this.searchForm.value.location) : 1000)
      'user/locationStore?maxDistance=' + 1000
      +
      // this.maxDistance
      '&type=' + ((this.searchForm.value.radiusMeasurement) ? (this.searchForm.value.radiusMeasurement) : 'MILES') +
      '&lat=' + this.latitude + '&long=' + this.longitude}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 0).subscribe(
      (res: any) => {
        if (res['responseCode'] == '200') {
          this.locationStoreArray = res.result.docs;


          this.commonService.hideSpinner();
          // this.commonService.successToast(res.responseMessage)
        }
      },
      (err: any) => {
        this.commonService.hideSpinner();
        if (err['responseCode'] == '401') {
          this.commonService.errorToast(err['responseMessage']);
        } else {
          this.commonService.errorToast(err.responseMessage);
        }
      }
    );
  }
}
