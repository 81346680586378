import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService, CountryCode } from 'src/app/provider/common.service';
import { AuthService } from 'src/app/provider/auth.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  signinForm: FormGroup;
  toggleEye: boolean = true;  // toggle eye
  countryList: any[];
  filteredOptions: Observable<CountryCode[]>;
  myControl = new FormControl('+91');
  isSigninFormSubmitted: boolean = false;
  calling_code: any;
  ipAddress: string;
  constructor(public authService: AuthService, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.formValidation()
  }


  formValidation() {
    // Form group and Form Control Name With Validations
    this.signinForm = new FormGroup({
      mobileNunber: new FormControl("", [
        Validators.required

      ]),
      password: new FormControl("", [
        Validators.required,

      ]),

    });
    this.countryList = this.commonService.countryListJson;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (value ? this._filter(value) : this.countryList.slice())),
    );

  }



  private _filter(value: string): CountryCode[] {
    const filterValue = value.toLowerCase();
    return this.countryList.filter(option => option.code.toLowerCase().includes(filterValue));
  }

  /**
   * userType are used in website
   * @param CONSUMER -> normal user comes to buy product
   * @param RETAILER -> wholesale retailer comes to buy product for consumer
   * @param INFLUENCER -> location ideal person of some place refer to buy product
   */
  login() {
    if (this.signinForm.invalid) {
      this.isSigninFormSubmitted = true
      return
    }
    let apiReqUrl = ApiEndPoint.login
    let apiReqData = {
      "mobileNumberPId": this.signinForm.value.mobileNunber,
      "password": this.signinForm.value.password,
      typeOfUser: 'RETAILER'
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.commonService.hideSpinner()
        localStorage.setItem('token', res.result.token);
        localStorage.setItem('userType', 'RETAILER')
        // Swal.fire({
        //   icon: 'success',
        //   text: res.responseMessage,
        // })
        //     // this.commonService.successToast(res['message'])
        // this.commonService.successToast('Login Successfully.')
        // this.authService.loggedIn.next('LOGGED_IN')
        this.authService.isLoggedIn.next(true)
        this.authService.loggedInUserType.next('RETAILER')

        this.router.navigate(['homepage']);
        // this.getBalance() 
        //  this.getAccountDetails()


        // remember me 
        // if (this.signinForm.value.rememberMe == true) {
        //   let remData = {
        //     "email": btoa(this.signinForm.value.email),
        //     "password": btoa(this.signinForm.value.password),
        //     "rememberMe": this.signinForm.value.rememberMe
        //   }
        //   localStorage.setItem('rememberMe', JSON.stringify(remData))
        // } else {
        //   localStorage.removeItem('rememberMe')
        // }
      } else {
        this.commonService.hideSpinner()
        this.commonService.alertError(res.responseMessage)
      }
    }, (err) => {
      console.log(err);
      this.commonService.hideSpinner()
      if (err.responseCode == 404) {
        this.commonService.alertError(err.responseMessage)
      } else if (err.responseCode == 402) {
        this.commonService.alertError(err.responseMessage)
      } else {
        this.commonService.alertError(err.responseMessage)
      }
    })
  }

  navigateToSignup() {
    this.router.navigate(['/signup'])
  }
  navigateToForgot() {
    this.router.navigate(['/forgot-password'])
  }
}
