import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { CommonService } from '../provider/common.service';
import { AuthService } from '../provider/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(public authService: AuthService, private commonService: CommonService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      this.commonService.hideSpinner();
      var errorThrow = {
        responseCode: error.error.code || error.error.statusCode || error.error.responseCode,
        responseMessage: error.error.message || error.error.statusMessage || error.error.responseMessage,
        result: error.error.result
      }
      if (error.error instanceof ErrorEvent) {
        console.log(error);
      } else {
        console.log('This is server side error')
        switch (error.error.responseCode) {
          case 400:
            console.log('Error 400.')
            // this.authService.logout()
            // this.commonService.errorToast(error.error.responseMessage);
            break;
          case 401:
            // this.commonService.errorToast('Unauthorized Access.');
            this.commonService.errorToast(error.error.responseMessage);
            this.authService.logout();
            break;
          case 402:
            // this.commonService.errorToast('You have been blocked by Admin.');
            // this.commonService.errorToast(error.error.responseMessage);
            // this.authService.logout();
            break;
          case 403:
            console.log("Error 403")
            // this.commonService.errorToast('You have been blocked by Admin.');
            this.commonService.errorToast(error.error.responseMessage);
            this.authService.logout();
            break;
          case 404:
            console.log("Error 404")
            // this.commonService.errorToast('Unauthorized Access.');
            // this.commonService.errorToast(error.error.responseMessage);
            // this.authService.logout()
            break;
          case 440:
            console.log("Error 440")
            // this.commonService.errorToast('Please provide token');
            this.commonService.errorToast(error.error.responseMessage);
            this.authService.logout();
            break;
          case 500:
            if (error.error.responseMessage.includes('jwt expired')) {
              this.authService.logout();
            }
            // this.commonService.errorToast('Internal Server Error.');
            break;
          default:
            // this.commonService.errorToast(error.error.responseMessage);
            break;
        }
      }
      return throwError(errorThrow)
    }))
  }
}
