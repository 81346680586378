<mat-dialog-content class="mat-typography text-center">
    <div class="header pb-2 pt-0">
        <h2 class="header-text m-0">Select Dress Type</h2>
    </div>
    <div class="content">
        <!-- <p class="header-text m-0 mb-2" mat-dialog-close (click)="addProductInGroup('/readytoship-bridal')">Ready to Ship</p> -->
        <!-- <div class="nav-item dropdown">
                                                                                                                    <a class="nav-link dropdown-toggle" data-toggle="dropdown">
                                                                                                                        <i class="fa fa-user first-profile-ico" aria-hidden="true"></i>
                                                                                                                    </a>
                                                                                                                    <div class="dropdown-menu">
                                                                                                                        <a class="dropdown-item" (click)="loginNavigate('CUSTOMER')">Login As Consumer</a>
                                                                                                                        <a class="dropdown-item" (click)="loginNavigate('RETAILER')">Login As Retailer</a>
                                                                                                                        <a class="dropdown-item" (click)="loginNavigate('INFLUENCER')">Login As Influencer</a>
                                                                                                                    </div>
                                                                                                                </div> -->
        
        <!-- {{headerList | json}} -->
        <!-- <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse"> -->
        <!-- <div class="navbar-nav">
                                                            <div *ngFor="let item of headerList" class="nav-item menu-items mr-2" [ngClass]="{'dropdown': item?.sub.length}">
                                                                <a class="nav-link" [ngClass]="{'dropdown-toggle': item?.sub.length}"
                                                                    data-toggle="dropdown">{{item?.parent?.categoryName}}</a>
                                                                <div *ngIf="item?.sub?.length" class="dropdown-menu">
                                                                    <a *ngFor="let sub of item?.sub" class="dropdown-item">{{sub?.subCategoryName}}</a>
                                                                </div>
                                                            </div>
                                                        </div> -->
        
        <div class="dropdown">
            <ng-container *ngFor="let item of headerList" class="nav-item menu-items mr-2"
                [ngClass]="{'dropdown': item?.sub.length}">
                <a id="dLabel" role="button" data-toggle="dropdown" data-target="#" href="#">
                    {{item?.parent?.categoryName}} <span class="caret"></span>
                </a>
                <!-- <br> -->
                <ul *ngIf="item?.sub?.length" class="dropdown-menu" class="dropdown-menu" role="menu" aria-labelledby="dLabel">
                    <li *ngFor="let sub of item?.sub">{{sub?.subCategoryName}}</li>
                </ul>
            </ng-container>
        </div>
        <!-- </div> -->
        <p class="header-text m-0" mat-dialog-close (click)="addProductInGroup('/create-dress')">Customize Your Dress
        </p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="mat_dialog_custom_action">
    <button mat-raised-button class="buttonGlobal" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
