<h2 mat-dialog-title class="top_text">Order your first sample set !</h2>
<mat-dialog-content class="mat-typography text_data_para">
  <h3>You need to order sample sets and swatch folders to get <br>
     started .</h3>
  
  

  
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <button mat-button mat-dialog-close class="cancel_btn">Cancel</button> -->
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="sbt_btn">Let's Go</button>
</mat-dialog-actions>

<button mat-button (click)="openDialog()">Open dialog</button>