<div class="wrapper">
    <div class="text-first text-center pt-5">
        <h1 class="new-style">Select a date</h1>
    </div>
    <div class="fld">
        <!-- <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="inp-fld">
                        <input type="date" placeholder="Jump date" [(ngModel)]="currentDate" (input)="getAvailableSlot()"
                        class="inp-dtl mr-2" [min]="getCurrentDate">
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="inp-fld">
                        <button mat-button class="buttonGlobal" (click)="getToday()">TODAY</button>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="inp-fld">
            <input type="date" placeholder="Jump date" [(ngModel)]="currentDate" (input)="getAvailableSlot()"
                class="inp-dtl mr-2" [min]="getCurrentDate" style="width: 32%;">
            <button mat-button class="buttonGlobal" style="width: 28%;" (click)="getToday()">TODAY</button>
        </div>
        <!-- <div class="new-day-data">
            <div class="day-text new-style">
                <h3>SUNDAY</h3>
                <h3>MONDAY</h3>
                <h3>TUESDAY</h3>
                <h3>WEDNESDAY</h3>
                <h3>THURSDAY</h3>
                <h3>FRIDAY</h3>
                
                <h3>SATURDAY</h3>
            </div>
            <div class="list-details d-flex">
                <ul class="details-unorder">
                    <ng-template ngFor let-item [ngForOf]="sundaySlots?.allSlotTimes" let-i="index">
                        <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 0}"
                            (click)="getSlotTime(item,0,i)">
                            {{item?.time}}</li>
                        <li *ngIf="item.slotStatus" class="disable-slot">
                            {{item?.time}}</li>
                    </ng-template>
                    <li class="b-none" *ngIf="!sundaySlots?.allSlotTimes"></li>
                </ul>
                <ul class="details-unorder">
                    <ng-template ngFor let-item [ngForOf]="mondaySlots?.allSlotTimes" let-i="index">
                        <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 1}"
                            (click)="getSlotTime(item,1,i)">
                            {{item?.time}}</li>
                        <li *ngIf="item.slotStatus" class="disable-slot">
                            {{item?.time}}</li>
                    </ng-template>
                    <li class="b-none" *ngIf="!mondaySlots?.allSlotTimes"></li>
                </ul>
                <ul class="details-unorder">
                    <ng-template ngFor let-item [ngForOf]="tuesdaySlots?.allSlotTimes" let-i="index">
                        <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 2}"
                            (click)="getSlotTime(item,2,i)">
                            {{item?.time}}</li>
                        <li *ngIf="item.slotStatus" class="disable-slot">
                            {{item?.time}}</li>
                    </ng-template>
                    <li class="b-none" *ngIf="!tuesdaySlots?.allSlotTimes"></li>
                </ul>
                <ul class="details-unorder">
                    <ng-template ngFor let-item [ngForOf]="wednessdaySlots?.allSlotTimes" let-i="index">
                        <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 3}"
                            (click)="getSlotTime(item,3,i)">
                            {{item?.time}}
                        </li>
                        <li *ngIf="item.slotStatus" class="disable-slot">
                            {{item?.time}}</li>
                    </ng-template>
                    <li class="b-none" *ngIf="!wednessdaySlots?.allSlotTimes"></li>
                </ul>
                <ul class="details-unorder">
                    <ng-template ngFor let-item [ngForOf]="thursdaySlots?.allSlotTimes" let-i="index">
                        <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 4}"
                            (click)="getSlotTime(item,4,i)">
                            {{item?.time}}</li>
                        <li *ngIf="item.slotStatus" class="disable-slot">
                            {{item?.time}}</li>
                    </ng-template>
                    <li class="b-none" *ngIf="!thursdaySlots?.allSlotTimes"></li>
                </ul>
                <ul class="details-unorder">
                    <ng-template ngFor let-item [ngForOf]="fridaySlots?.allSlotTimes" let-i="index">
                        <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 5}"
                            (click)="getSlotTime(item,5,i)">
                            {{item?.time}}</li>
                        <li *ngIf="item.slotStatus" class="disable-slot">
                            {{item?.time}}</li>
                    </ng-template>
                    <li class="b-none" *ngIf="!fridaySlots?.allSlotTimes"></li>
                </ul>
                <ul class="details-unorder">
                    <ng-template ngFor let-item [ngForOf]="saturdaySlots?.allSlotTimes" let-i="index">
                        <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 6}"
                            (click)="getSlotTime(item,6,i)">
                            {{item?.time}}</li>
                        <li *ngIf="item.slotStatus" class="disable-slot">
                            {{item?.time}}</li>
                    </ng-template>
                    <li class="b-none" *ngIf="!saturdaySlots?.allSlotTimes"></li>
                </ul>
            </div>
        </div> -->
        <div class=" container col-md-9" style="overflow-y: auto">
        <table class="table table-borderless mt-5"  style="width: 100%" >
            <thead>
                <tr>
                    <th *ngFor="let item of slotsArray" scope="col" class="pb-0">
                        <div class="d-flex justify-content-center">
                            <div class="d-block text-center">
                                <h3 class="mb-0">{{item?.day | uppercase}}</h3>
                                <p class="mb-0">{{item?.date | date:'mediumDate'}}</p>
                            </div>
                        </div>
                    </th>
                    <!-- <th scope="col">MONDAY</th>
                    <th scope="col">TUESDAY</th>
                    <th scope="col">WEDNESDAY</th>
                    <th>THURSDAY</th>
                    <th>FRIDAY</th>
                    <th>SATURDAY</th> -->
                </tr>
            </thead>
            <tbody>
                <tr class="details-unorder">

                    <td class="details-unorder">
                        <ng-template ngFor let-item [ngForOf]="sundaySlots?.allSlotTimes" let-i="index">
                            <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 0}"
                                (click)="getSlotTime(sundaySlots?.date, item, 0, i)">
                                {{item?.time}}</li>
                            <li *ngIf="item.slotStatus" class="disable-slot">
                                {{item?.time}}</li>
                        </ng-template>
                        <li class="b-none" *ngIf="!sundaySlots?.allSlotTimes"></li>
                    </td>
                    <td class="details-unorder">
                        <ng-template ngFor let-item [ngForOf]="mondaySlots?.allSlotTimes" let-i="index">
                            <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 1}"
                                (click)="getSlotTime(mondaySlots?.date, item, 1, i)">
                                {{item?.time}}</li>
                            <li *ngIf="item.slotStatus" class="disable-slot">
                                {{item?.time}}</li>
                        </ng-template>
                        <li class="b-none" *ngIf="!mondaySlots?.allSlotTimes"></li>
                    </td>
                    <td class="details-unorder">
                        <ng-template ngFor let-item [ngForOf]="tuesdaySlots?.allSlotTimes" let-i="index">
                            <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 2}"
                                (click)="getSlotTime(tuesdaySlots?.date, item, 2, i)">
                                {{item?.time}}</li>
                            <li *ngIf="item.slotStatus" class="disable-slot">
                                {{item?.time}}</li>
                        </ng-template>
                        <li class="b-none" *ngIf="!tuesdaySlots?.allSlotTimes"></li>
                    </td>
                    <td class="details-unorder">
                        <ng-template ngFor let-item [ngForOf]="wednessdaySlots?.allSlotTimes" let-i="index">
                            <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 3}"
                                (click)="getSlotTime(wednessdaySlots?.date, item, 3, i)">
                                {{item?.time}}
                            </li>
                            <li *ngIf="item.slotStatus" class="disable-slot">
                                {{item?.time}}</li>
                        </ng-template>
                        <li class="b-none" *ngIf="!wednessdaySlots?.allSlotTimes"></li>
                    </td>
                    <td class="details-unorder">
                        <ng-template ngFor let-item [ngForOf]="thursdaySlots?.allSlotTimes" let-i="index">
                            <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 4}"
                                (click)="getSlotTime(thursdaySlots?.date, item, 4, i)">
                                {{item?.time}}</li>
                            <li *ngIf="item.slotStatus" class="disable-slot">
                                {{item?.time}}</li>
                        </ng-template>
                        <li class="b-none" *ngIf="!thursdaySlots?.allSlotTimes"></li>
                    </td>
                    <td class="details-unorder">
                        <ng-template ngFor let-item [ngForOf]="fridaySlots?.allSlotTimes" let-i="index">
                            <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 5}"
                                (click)="getSlotTime(fridaySlots?.date, item, 5, i)">
                                {{item?.time}}</li>
                            <li *ngIf="item.slotStatus" class="disable-slot">
                                {{item?.time}}</li>
                        </ng-template>
                        <li class="b-none" *ngIf="!fridaySlots?.allSlotTimes"></li>
                    </td>
                    <td class="details-unorder">
                        <ng-template ngFor let-item [ngForOf]="saturdaySlots?.allSlotTimes" let-i="index">
                            <li *ngIf="!item.slotStatus" [ngClass]="{'activeSlot': i === index && rowIndex === 6}"
                                (click)="getSlotTime(saturdaySlots?.date, item, 6, i)">
                                {{item?.time}}</li>
                            <li *ngIf="item.slotStatus" class="disable-slot">
                                {{item?.time}}</li>
                        </ng-template>
                        <li class="b-none" *ngIf="!saturdaySlots?.allSlotTimes"></li>
                    </td>
                </tr>

            </tbody>
        </table>
        </div>
    </div>
    <div class="down-button text-center">
        <button mat-button class="buttonGlobal1 buttonGlobal"  (click)="navigate()">Continue</button>
    </div>
</div>