import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
declare var $
@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {
  orderDetails: boolean = true
  orderList: any;
  currentPage = 1;
  itemPerPage = 20
  ipDetails: any;

  constructor(private commonService: CommonService) {
    this.ipDetails = this.commonService.getIPDetails();
  }

  ngOnInit(): void {
    this.myOrderApi()
  }
  ngOnDestroy() {
    $("body>#viewMyOrderProductModalId").remove();
    // $("body>#viewAddress").remove();


  }
  // orderModal() {
  //   $('#viewMyOrderProductModalId').appendTo("body").modal('show');
  // }

  viewProductModalOpen() {
    $('#viewMyOrderProductModalId').modal('show')
  }

  /**@param  */
  myOrderApi() {
    let apiReqUrl = 'user/listMyOrder'
    let apiReqData = {
      page: this.currentPage,
      limit: this.itemPerPage
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.orderList = res.result.docs ? res.result.docs : []
        this.commonService.hideSpinner()
      } else {
        this.orderList = []
        this.commonService.hideSpinner()
      }
    }, (err: any) => {
      this.orderList = []
      this.commonService.hideSpinner()
      // this.commonService.alertError(err.responseMessage)
    })
  }

  checkout(item) {
    console.log(item);

  }

  orderId: any;
  viewSingleAddressModal(orderId) {
    this.orderId = orderId
    $('#viewRetailerOrderProductModalId').appendTo("body").modal('show');
    this.viewOrder()
  }

  viewOrderDetails: any;
  viewOrder() {
    let apiReqUrl = `${'user/viewOrder?orderId=' + this.orderId}`
    // let apiReqData = {
    //   page: this.currentPage,
    //   limit: this.itemPerPage
    // }
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        // this.orderList = res.result.docs
        this.viewOrderDetails = res.result
        this.commonService.hideSpinner()
      } else {
        this.commonService.hideSpinner()
      }
    }, (err: any) => {
      this.commonService.hideSpinner()
      this.commonService.alertError(err.responseMessage)
    })
  }
  viewMultipleAddressModal(orderId) {
    this.orderId = orderId
    $('#viewRetailerMultipleAddressOrderProductModalId').appendTo("body").modal('show');
    this.viewOrder()
  }

}