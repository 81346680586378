<div class="wrapper">
  <!-- <mat-toolbar class="toolbar_custom">
    Home/My Account
</mat-toolbar> -->


  <!-- after create room -->
  <div class="w-50 m-auto text-center" style="min-height: 800px">
    <div>
      <h1 class="congratulation header-text">Congratulations!</h1>
    </div>
    <div style="font-size: 18px;text-align: left;">
      <p class="header-text">
        You have successfully created a new virtual shopping curation on Motee maids. You can now invite users from any
        social media or platform to your curation using the invite link we provide.
      </p>
    </div>
    <mat-toolbar>
      <p class="header-text" style="font-size: 15px;">{{generatedLink}}</p>
      <!-- <img style="" class="myDiv" src="assets/images/other/capyicon.jpg" alt="Copy" (click)="copyText(generatedLink)"> -->
      <i class="fa fa-copy myDiv" title="Click to copy" (click)="copyText(generatedLink)"></i>
      <!-- <div class="hide">Click to copy</div> -->
    </mat-toolbar>
    <div class="copy-button">
      <div>
        <button mat-button mat-raised-button class="data-btn buttonGlobal myDivbutton" target="_blank"
          (click)="copyText(generatedLink)" value="click to copy">Copy Invite Link</button>
        <span class="hide">Click to copy</span>
      </div>
      <div>
        <button mat-button mat-raised-button class="data-btn buttonGlobal" target="_blank" (click)="enterRoom()">Enter
          Your Curation</button>
      </div>
    </div>
    <p class="text-left"></p>
    <div class="icon-custom">
      <!-- <i class="fa fa-whatsapp"></i>
      <i class="fa fa-instagram"></i>
      <i class="fa fa-snapchat"></i>
      <i class="fa fa-facebook"></i>
      <i class="fa fa-twitter"></i>

      <i class="fa fa-linkedin"></i>

      <i class="fa fa-skype"></i> -->
      <!-- <i class="fa fa-snapchat"></i> -->
      <!-- <i class="fa fa-facebook"></i> -->
      <!-- <i class="fa fa-twitter"></i> -->
      <!-- <i class="fa fa-whatsapp"></i> -->
      <!-- <i class="fa fa-instagram"></i> -->
      <!-- <i class="fa fa-snapchat"></i> -->
      <!-- <i class="fa fa-facebook"></i> -->

      <!-- <mat-icon *ngIf="[type1] =='whatsapp'" aria-hidden="false" aria-label="Example home icon" (click)="share(type1)">
        whatsapp</mat-icon> -->
      <!-- <mat-icon>whatsapp</mat-icon> -->
      <i *ngIf="[type1] =='whatsapp'" class="fa fa-whatsapp" (click)="share(type1)" style="    font-size: 48px;
    margin-right: 10px;"></i>
      <mat-icon *ngIf="[type] =='facebook'" aria-hidden="false" aria-label="Example home icon" (click)="share(type)">
        facebook</mat-icon>
      <mat-icon *ngIf="[type2] =='snapchat'" aria-hidden="false" aria-label="Example home icon" (click)="share(type2)">
        snapchat</mat-icon>
      <!-- <span class='material-icons-outlined'>whatsapp</span> -->
      <!-- <mat-icon *ngIf="type == 'whatsapp'" aria-hidden="false" aria-label="Example home icon">whatsapp</mat-icon> -->
      <!-- <mat-icon *ngIf="type == 'whatsapp'"  aria-hidden="false" aria-label="Example home icon" >whatsapp</mat-icon>
      <mat-icon *ngIf="type == 'facebook'" aria-hidden="false" aria-label="Example home icon" >facebook</mat-icon>
      <mat-icon *ngIf="type == 'snapchat'" aria-hidden="false" aria-label="Example home icon" >snapchat</mat-icon> -->



      <!-- <share-buttons theme="circles-dark"
      [include]="['copy', 'facebook', 'email', 'messenger', 'mix', 'line', 'linkedin', 'pinterest', 'print', 'reddit', 'sms', 'telegram', 'tumblr', 'twitter', 'viber', 'vk', 'xing', 'whatsapp']"
      [showIcon]="true"
      [showText]="false"
      url="https://rodrigo.kamada.com.br/"
      description="Angular Share Buttons"
      twitterAccount="rodrigokamada"
      class="pt-5">
    </share-buttons> -->
    </div>
  </div>
</div>