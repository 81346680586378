<div class="wrapper">
    <div class="staticHeading text-center">
        <h1 class="headingTitle new-style">{{privacyPoliciesData?.title}}</h1>
    </div>
    <div class="termscontent">
        <div class="privacyDivs">
            <!-- <h3 class="header-text">Privacy Policy</h3> -->
            <div class="header-text" [innerHtml]="privacyPoliciesData?.description">
                <!-- The Privacy Policy is a document informing the users about the use, process and disclosure of their personal information collected through a Website or Mobile Application. This document can be used for any type of industries including healthcare, finance, e-commerce, transportation, etc...

This Privacy Policy can be used for either Website or Mobile Application. It is mandatory for every Website/Application in India who collects and process personal information to have a Privacy Policy. Any Website/App that gathers any data about its users, even if it is simply through tracking their location, is required to have a Privacy Policy.

It is mandatory under the IT Act, 2000 to notify and get the consent of users before collecting and processing their information. Thus the Privacy Policy has to be visible and understandable to the users.

The Sensitive Personal Data in India constitutes the following categories of the data: passwords, financial data, health data, official identifier, sex life, sexual orientation, biometric data, genetic data, transgender status, intersex status, caste or tribe, religious belief, political affiliation or any other category of data specified by the concerned authority.

The Privacy Policy is a document informing the users about the use, process and disclosure of their personal information collected through a Website or Mobile Application's. -->


            </div>
        </div>

    </div>
</div>