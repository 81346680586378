import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-my-appoinment',
  templateUrl: './my-appoinment.component.html',
  styleUrls: ['./my-appoinment.component.scss']
})
export class MyAppoinmentComponent implements OnInit {
  appointmentListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  email = localStorage.getItem('email')
  ipDetails: any;

  constructor(public commonService: CommonService) {
    this.ipDetails = this.commonService.getIPDetails();
  }

  ngOnInit(): void {
    this.listAppointment();
  }


  listAppointment() {
    let apiReqUrl = `${ApiEndPoint.listAppointment + '?email=' + this.email}`
    let apiReqData = {
      page: this.currentPage,
      limit: this.itemPerPage,
    }
    this.commonService.showSpinner()
    apiReqData = this.commonService.removeEmptyKey(apiReqData);

    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.appointmentListArray = res.result.docs ? res.result.docs : []
        this.commonService.hideSpinner()
      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
}
