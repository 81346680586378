import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-visit-nyc-salon',
  templateUrl: './visit-nyc-salon.component.html',
  styleUrls: ['./visit-nyc-salon.component.scss']
})
export class VisitNycSalonComponent implements OnInit {
bookAppointmentForm:FormGroup
isSubmitted:boolean = false
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.formValidation()
  }

  formValidation(){
    this.bookAppointmentForm = new FormGroup({
      'store':new FormControl('',Validators.required),
      'event':new FormControl('',Validators.required),
      'appointment':new FormControl('',Validators.required),
    })
  }

  navigateToSelectDate(){
    if(this.bookAppointmentForm.invalid){
      this.isSubmitted = true
      return
    }
    this.router.navigateByUrl('/select-date')
  }
}
