<!-- start toolbar -->
<!-- <mat-toolbar class="toolbar_custom">
    <span>Home/</span><span>Mothers Collection</span>
  </mat-toolbar> -->
  <!-- end toolbar -->
<mat-sidenav-container class="h-100 w-100">
    <!-- sidebar start -->
    <mat-sidenav mode="side" opened class="w-25">
        <div class="d-flex justify-content-between align-items-center p-2">
            <h3 class="m-0 font-weight-bold">Filter By</h3>
            <div class="col text-right">
                <i class="fa fa-plus" *ngIf="filtered" (click)="addFilter()"></i>
                <i class="fa fa-minus" *ngIf="!filtered" (click)="removeFilter()"></i>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div class="accord_details">
            <mat-accordion>
                <div class="second_list_data">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                 Price
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="p-2">
                <div class="grid-group-price">
                  <div class="first_price">
                    <p class="price_custom">2000 <span>-</span> 4000</p>
                  </div>
                  <div class="first_price">
                    <p class="price_custom">4000 <span>-</span> 6000</p>
                  </div>
                </div>
               <div class="grid-group-price">
                  <div class="first_price">
                    <p class="price_custom">6000 <span>-</span> 8000</p>
                  </div>
                  <div class="first_price">
                    <p class="price_custom">8000 <span>-</span> 10000</p>
                  </div>
                </div>
                <div class="grid-group-price">
                  <div class="first_price">
                    <p class="price_custom">10000 <span>-</span> 12000</p>
                  </div>
                  <div class="first_price">
                    <p class="price_custom">12000 <span>-</span> 14000</p>
                  </div>
                </div>
                <!-- <p class="price_custom bg-gradient-primary"></p> -->
                <div class="grid-group-price">
                  <div class="first_price">
                    <p class="price_custom">14000 <span>-</span> 18000</p>
                  </div>
                  <div class="first_price">
                    <p class="price_custom">18000 <span>-</span> 20000</p>
                  </div>
                </div>
                <div class="grid-group-price">
                  <div class="first_price">
                    <p class="price_custom">20000 <span>-</span> 24000</p>
                  </div>
                  <div class="first_price">
                    <p class="price_custom">24000 <span>-</span> 26000</p>
                  </div>
                </div>
                <div class="grid-group-price">
                  <div class="first_price">
                    <p class="price_custom">26000 <span>-</span> 28000</p>
                  </div>
                  <div class="first_price">
                    <p class="price_custom">28000 <span>-</span> 30000</p>
                  </div>
                </div>
                <div class="grid-group-price">
                  <div class="first_price">
                    <p class="price_custom">30000 <span>-</span> 32000</p>
                  </div>
                  <div class="first_price">
                    <p class="price_custom">32000 <span>-</span> 34000</p>
                  </div>
                </div>
                <!-- <p class="price_custom bg-gradient-primary"></p> -->
                <div class="grid-group-price">
                  <div class="first_price">
                    <p class="price_custom">34000 <span>-</span> 38000</p>
                  </div>
                  <div class="first_price">
                    <p class="price_custom">38000 <span>-</span> 40000</p>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
                </div>
            <div class="second_list_data">
            <mat-expansion-panel >
              <mat-expansion-panel-header>
                <mat-panel-title>
                    Strap Sleeves
                </mat-panel-title>
                <!-- <mat-panel-description>
                    <div class="col text-right">
                        <i class="fa fa-plus" *ngIf="filtered" (click)="addFilter()"></i>
                        <i class="fa fa-minus" *ngIf="!filtered" (click)="removeFilter()"></i>
                    </div>
                </mat-panel-description> -->
              </mat-expansion-panel-header>
              <p>I'm visible because I am open</p>
            </mat-expansion-panel>
            </div>
              <!-- <mat-expansion-panel hideToggle> -->
                
              <div class="second_list_data">
              <mat-expansion-panel (opened)="panelOpenState = true"
                                   (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Size
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                    Currently I am {{panelOpenState ? 'open' : 'closed'}}
                  </mat-panel-description> -->
                </mat-expansion-panel-header>
                <!-- <p>I'm visible because I am open</p> -->
                <div class="size_select_value">
                  <select class="form-control form-control-sm">
                    <label for="">Body Size</label>
                    <option disabled>Select Size</option>
                    <option>0</option>
                    <option>2</option>
                    <option>4</option>
                    <option>6</option>
                    <option>8</option>
                    <option>10</option>
                    <option>12</option>
                    <option>14</option>
                    <option>16</option>
                    <option>18</option>
                    <option>20</option>
                    <option>22</option>
                    <option>24</option>
                  </select>
                  <select class="form-control form-control-sm second_select_val">Body Size
                    <label for="">Skirt Size</label>
                    <option disabled>Select Size</option>
                    <option>0</option>
                    <option>2</option>
                    <option>4</option>
                    <option>6</option>
                    <option>8</option>
                    <option>10</option>
                    <option>12</option>
                    <option>14</option>
                    <option>16</option>
                    <option>18</option>
                    <option>20</option>
                    <option>22</option>
                    <option>24</option>
                  </select>
                  <select class="form-control form-control-sm second_select_val">Body Size
                    <label for="">Skirt Length</label>
                    <option >Select Length</option>
                    <option>Standard</option>
                    <option>Extra Length</option>
                    
                  </select>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="second_list_data">
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Length
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                      Currently I am {{panelOpenState ? 'open' : 'closed'}}
                    </mat-panel-description> -->
                  </mat-expansion-panel-header>
                  <!-- <p>I'm visible because I am open</p> -->
                  <div class="size_select_value">
                    <p class="price_custom">Extra Small</p>
                    <p class="price_custom">Small</p>
                    <p class="price_custom">Medium</p>
                    <p class="price_custom">Large</p>
                    <p class="price_custom">Extra Large</p>
                  </div>
                </mat-expansion-panel>
              </div>
          <div class="fourth_clas">
            <!-- <mat-expansion-panel hideToggle> -->
              <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Colour
                        </mat-panel-title>
                <!-- <mat-panel-description>
                  This is a summary of the content
                </mat-panel-description> -->
              </mat-expansion-panel-header>
              <owl-carousel-o [options]="newOptions">
                  <div class="d-flex">
                <ng-template carouselSlide>
                  
                  <input type="tel" disabled class="colour_list_details" style="background-color: silver;" >
                  <p class="text_images_paras">Silver</p>
                </ng-template>  
                <ng-template carouselSlide>
                  
                  <input type="tel" disabled class="colour_list_details" style="background-color: midnightblue;">
          
                  <p class="text_images_paras">Midnightblue</p>
            
                </ng-template>  
                <ng-template carouselSlide>
                  <input type="tel" disabled class="colour_list_details" style="background-color: blueviolet;">
          
                  <p class="text_images_paras">Blueviolet</p>
            
                </ng-template>  
                <ng-template carouselSlide>
                          <input type="tel" disabled class="colour_list_details" style="background-color: red;">
          
                  <p class="text_images_paras">red</p>
            
                </ng-template>  
      <ng-template carouselSlide>
                          <input type="tel" disabled class="colour_list_details" style="background-color: seagreen;">
          
                  <p class="text_images_paras">Seagreen</p>
            
                </ng-template> 
                  </div>
              </owl-carousel-o>
              <owl-carousel-o [options]="newOptions">
                <ng-template carouselSlide>
                          <input type="tel" disabled class="colour_list_details" style="background-color: skyblue;">
          
                  <p class="text_images_paras">Skyblue</p>
                </ng-template>  
                <ng-template carouselSlide>
                          <input type="tel" disabled class="colour_list_details" style="background-color: steelblue;">
          
                  <p class="text_images_paras">Steelblue</p>
            
                </ng-template>  
                <ng-template carouselSlide>
                          <input type="tel" disabled class="colour_list_details" style="background-color: darkorange;">
          
                  <p class="text_images_paras">Darkorange</p>
            
                </ng-template>  
                <ng-template carouselSlide>
                          <input type="tel" disabled class="colour_list_details" style="background-color: rosybrown;">
          
                  <p class="text_images_paras">Rosybrown</p>
            
                </ng-template>  
                <ng-template carouselSlide>
                          <input type="tel" disabled class="colour_list_details" style="background-color: indianred;">
          
                  <p class="text_images_paras">Indianred</p>
            
                </ng-template> 
                
              </owl-carousel-o>
              <owl-carousel-o [options]="newOptions">
                <ng-template carouselSlide>
                          <input type="tel" disabled class="colour_list_details new_down_detail" style="background-color: gainsboro;">
          
                  <p class="text_images_paras">Gainsboro</p>
                </ng-template>  
               
                
              </owl-carousel-o>
              <!-- <p>This is the primary content of the panel.</p> -->
            </mat-expansion-panel>
          </div>
          
          <div class="second_list_data">
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Embelishment
                </mat-panel-title>
                <!-- <mat-panel-description>
                  Currently I am {{panelOpenState ? 'open' : 'closed'}}
                </mat-panel-description> -->
              </mat-expansion-panel-header>
              <!-- <p>I'm visible because I am open</p> -->
                <owl-carousel-o [options]="embelishmentOption">
                  <ng-template carouselSlide>
                    <img class="four_img_list" src="/assets/images/first-emb.png" alt="">
                    <p class="text_images_para">Encrusted</p>
                  </ng-template>  
                  <ng-template carouselSlide>
                    <img class="four_img_list" src="/assets/images/emb2.png" alt="">
                    <p class="text_images_para">Polka</p>
              
                  </ng-template>  
                  <ng-template carouselSlide>
                    <img class="four_img_list" src="/assets/images/emb3.png" alt="">
                    <p class="text_images_para">Petal</p>
              
                  </ng-template>  
                  <ng-template carouselSlide>
                    <img class="four_img_list" src="/assets/images/emb4.png" alt="">
                    <p class="text_images_para">Diamond</p>
              
                  </ng-template>  
                  <ng-template carouselSlide>
                    <img class="four_img_list" src="/assets/images/emb2.png" alt="">
                    <p class="text_images_para">Polka</p>
              
                  </ng-template> 
                  
                </owl-carousel-o>
                <owl-carousel-o [options]="embelishmentOption">
                  <ng-template carouselSlide>
                    <img class="four_img_list" src="/assets/images/first-emb.png" alt="">
                    <p class="text_images_para">Encrusted</p>
                  </ng-template>  
                  <ng-template carouselSlide>
                    <img class="four_img_list" src="/assets/images/emb2.png" alt="">
                    <p class="text_images_para">Polka</p>
              
                  </ng-template>  
                  <ng-template carouselSlide>
                    <img class="four_img_list" src="/assets/images/emb3.png" alt="">
                    <p class="text_images_para">Petal</p>
              
                  </ng-template>  
                  <ng-template carouselSlide>
                    <img class="four_img_list" src="/assets/images/emb4.png" alt="">
                    <p class="text_images_para">Diamond</p>
              
                  </ng-template>  
                  <ng-template carouselSlide>
                    <img class="four_img_list" src="/assets/images/emb3.png" alt="">
                    <p class="text_images_para">Petal</p>
              
                  </ng-template> 
                  
                </owl-carousel-o>
            </mat-expansion-panel>
          </div>
            </mat-accordion>
          </div>

        <div class="d-flex justify-content-between align-items-center p-2 pr-2">
            <!-- <label class="m-0">Explore</label> -->
             <select name="" id="" class="border-0 bg-transparent select_custom font-weight-bold">
                 <option value="">Explore</option>
             </select>
        </div>
    </mat-sidenav>
    <!-- sidebar end -->
    <mat-sidenav-content class="">
        <!-- title -->
        <div class="wrraper">
        <div class="d-flex pl-2 pt-2 pb-2">
            <h1 class="m-0 dress_title_custom">All Mother of Bride's Dresses</h1>
        </div>

        <!-- pagination and filter -->
        <div class="d-flex justify-content-between pl-2 pr-3">
            <div class="col-md-7 d-flex align-items-center">
                <p class="col-md-3 m-0">1 - 12 of 1436</p>
               <div class="col-md-6">
                   <mat-label class="mr-2">Sort by</mat-label>
                <mat-form-field appearance="outline" class="w-75 h-75 mat_select_custom">
                    <mat-select name="" id="" [(value)]="selected">
                        <mat-option value="Recomended">Recomended</mat-option>
                        <mat-option value="Price Low to High">Price Low to High</mat-option>
                        <mat-option value="Price High to Low">Price High to Low</mat-option>
                    </mat-select>
                </mat-form-field>
                    <!-- <label for="">sort by</label> -->
               </div>
               <a class="text-dark text-underline col-md-3">view larger</a>
            </div>
            <div class="pull-right col-md-3 text-center align-items-center">
                <mat-label class="mr-2">Select</mat-label>
                <mat-form-field appearance="outline" class="w-50 h-75 mat_select_custom">
                    <!-- <mat-label>Select</mat-label> -->
                <!-- <label for="">Select</label> -->
                    <mat-select name="" id=""  [(value)]="value">
                        <mat-option value="page 1" selected>page 1</mat-option>
                    </mat-select>
                </mat-form-field>
                of 25
            </div>
        </div>

        <!-- images list container -->
        <div class="container">
            <div class="row justify-content-around">
                <!-- <div class=" box_custom"> -->
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 m-2 h-100 box_custom pt-1 pb-1 wow pulse" routerLink="/view-mothers-dress">
                        <i class="fa fa-heart text-light border-1"></i>
                        <img src="./assets/images/grid6.png" alt="" class="shadow box_custom wow  bounceInDown " style="width: 100%; height:170px;">
                        <div class="row justify-content-center text-center p-2">
                            <h4 class="m-0">LOREM</h4>
                            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <div>
                                <p class="m-3">WG4016</p>
                                <p class="m-0">$1599.00 - $2549.00</p>
                                <p class="m-1">8 Color</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 m-2 h-100 box_custom pt-1 pb-1 wow pulse" routerLink="/view-mothers-dress">
                        <i class="fa fa-heart text-light border-1"></i>
                         <img src="./assets/images/grid5.png" alt="" class="shadow box_custom wow  bounceInDown " style="width: 100%; height:170px;">
                        <div class="row justify-content-center text-center p-2">
                            <h4 class="m-0">LOREM</h4>
                            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <div>
                                <p class="m-3">WG4016</p>
                                <p class="m-0">$1599.00 - $2549.00</p>
                                <p class="m-1">8 Color</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 m-2 h-100 box_custom pt-1 pb-1 wow pulse" routerLink="/view-mothers-dress">
                        <i class="fa fa-heart text-light border-1"></i>
                         <img src="./assets/images/grid3.png" alt="" class="shadow box_custom wow  bounceInDown " style="width: 100%; height:170px;">
                        <div class="row justify-content-center text-center p-2">
                            <h4 class="m-0">LOREM</h4>
                            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <div>
                                <p class="m-3">WG4016</p>
                                <p class="m-0">$1599.00 - $2549.00</p>
                                <p class="m-1">8 Color</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 m-2 h-100 box_custom pt-1 pb-1 wow pulse" routerLink="/view-mothers-dress">
                        <i class="fa fa-heart text-light border-1"></i>
                         <img src="./assets/images/grid4.png" alt="" class="shadow box_custom wow rollIn " style="width: 100%; height:170px;">
                        <div class="row justify-content-center text-center p-2">
                            <h4 class="m-0">LOREM</h4>
                            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <div>
                                <p class="m-3">WG4016</p>
                                <p class="m-0">$1599.00 - $2549.00</p>
                                <p class="m-1">8 Color</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 m-2 h-100 box_custom pt-1 pb-1 wow pulse" routerLink="/view-mothers-dress">
                        <i class="fa fa-heart text-light border-1"></i>
                        <img src="./assets/images/background2.jpg" alt="" class="shadow box_custom wow rollIn " style="width: 100%; height:170px;">
                        <div class="row justify-content-center text-center p-2">
                            <h4 class="m-0">LOREM</h4>
                            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <div>
                                <p class="m-3">WG4016</p>
                                <p class="m-0">$1599.00 - $2549.00</p>
                                <p class="m-1">8 Color</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 m-2 h-100 box_custom pt-1 pb-1 wow pulse" routerLink="/view-mothers-dress">
                        <i class="fa fa-heart text-light border-1"></i>
                         <img src="./assets/images/grid2.png" alt="" class="shadow box_custom wow rollIn " style="width: 100%; height:170px;">
                        <div class="row justify-content-center text-center p-2">
                            <h4 class="m-0">LOREM</h4>
                            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                           <div>
                                <p class="m-3">WG4016</p>
                                <p class="m-0">$1599.00 - $2549.00</p>
                                <p class="m-1">8 Color</p>
                           </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
    </mat-sidenav-content>
</mat-sidenav-container>