<div class="wrapper">
    <div class="join-data">
        <!-- <div class="staticHeading text-center">
            <h1 class="headingTitle header-text">Join Our Team</h1>
        </div> -->
        <div class="termscontent">
            <div class="privacyDivs header-text text-center">
                <h1 class="header-text">{{veiwRealBridesDetails?.title}}</h1>
                <p>Mackenzie & Jared</p>

                <p>Great Sand Dunes National Park, CO

                </p>
                <p>Bride’s Dress: “Audrey” by Nouvelle moteemaids

                </p>

            </div>
            <div class="privacyDivs">
                <figure>
                    <img class="new-data" src="assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg" alt="">
                </figure>

                <p class="header-text text-center">Photography: Sheena Shahangian Photography LLC
                </p>
            </div>

            <div class="privacyDivs">
                <figure class="d-flex justify-content-around">
                    <img class="new-data-sec" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Front.jpg" alt="">
                    <img class="new-data-sec" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Back.jpg" alt="">

                </figure>
                <p class="header-text">Jared and I met as toddlers. Growing up in a small town our families knew each other before either of us were even born. Our story begins with play dates and birthday parties when we were little and grew into a close friendship as we got
                    older. Our friendship grew and lasted through middle school, high school, college, and moved to opposite sides of the country. After seven years of Jared living in Dallas, and me living in New York, some big life changes brought the
                    two of us face to face again and we realized we wanted to be together.</p>
            </div>
            <div class="privacyDivs">
                <figure>
                    <img class="new-data" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Front.jpg" alt="">
                </figure>
                <p class="header-text">Jared proposed in our hometown, Ludington, MI, on December 28, 2019. He asked me to join him outside his parent’s house for a surprise, and when we walked through the woods there were photos of us together from when we were kids all the
                    way through our thirties scattered along the path and strung up throughout the pine trees. When we arrived at the end of the photos, he proposed. Little did I know, he had a photographer hiding in the bushes to capture the whole thing.</p>
            </div>
            <!-- <div class="privacyDivs">
                <figure class="d-flex justify-content-around">
                    <img class="new-data-sec" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Front.jpg" alt="">
                    <img class="new-data-sec" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Back.jpg" alt="">

                </figure>
                <p class="header-text">Jared and I met as toddlers. Growing up in a small town our families knew each other before either of us were even born. Our story begins with play dates and birthday parties when we were little and grew into a close friendship as we got
                    older. Our friendship grew and lasted through middle school, high school, college, and moved to opposite sides of the country. After seven years of Jared living in Dallas, and me living in New York, some big life changes brought the
                    two of us face to face again and we realized we wanted to be together.</p>
            </div>
            <div class="privacyDivs">
                <figure class="d-flex justify-content-around">
                    <img class="new-data-sec" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Front.jpg" alt="">
                    <img class="new-data-sec" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Back.jpg" alt="">

                </figure>
                <p class="header-text">Jared and I met as toddlers. Growing up in a small town our families knew each other before either of us were even born. Our story begins with play dates and birthday parties when we were little and grew into a close friendship as we got
                    older. Our friendship grew and lasted through middle school, high school, college, and moved to opposite sides of the country. After seven years of Jared living in Dallas, and me living in New York, some big life changes brought the
                    two of us face to face again and we realized we wanted to be together.</p>
            </div> -->
            <!-- <div class="privacyDivs">
                <figure class="d-flex justify-content-around">
                    <img class="new-data-sec" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Front.jpg" alt="">
                    <img class="new-data-sec" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Back.jpg" alt="">

                </figure>
                <p class="header-text">Jared and I met as toddlers. Growing up in a small town our families knew each other before either of us were even born. Our story begins with play dates and birthday parties when we were little and grew into a close friendship as we got older. Our friendship grew and lasted through middle school, high school, college, and moved to opposite sides of the country. After seven years of Jared living in Dallas, and me living in New York, some big life changes brought the two of us face to face again and we realized we wanted to be together.</p>
            </div>
            <div class="privacyDivs">
                <figure>
                    <img class="new-data" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Front.jpg" alt="">
                </figure>
  <p class="header-text">Jared proposed in our hometown, Ludington, MI, on December 28, 2019. He asked me to join him outside his parent’s house for a surprise, and when we walked through the woods there were photos of us together from when we were kids all the way through our thirties scattered along the path and strung up throughout the pine trees. When we arrived at the end of the photos, he proposed. Little did I know, he had a photographer hiding in the bushes to capture the whole thing.</p>
            </div>
            <div class="privacyDivs">
                <figure>
                    <img class="new-data" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Lake_Sequin_Sash_Navy_Front.jpg" alt="">
                </figure>
  <p class="header-text">Jared proposed in our hometown, Ludington, MI, on December 28, 2019. He asked me to join him outside his parent’s house for a surprise, and when we walked through the woods there were photos of us together from when we were kids all the way through our thirties scattered along the path and strung up throughout the pine trees. When we arrived at the end of the photos, he proposed. Little did I know, he had a photographer hiding in the bushes to capture the whole thing.</p>
            </div> -->
            <div class="privacyDivs header-text text-center">
                <h1 class="header-text">Vendor Credits
                </h1>
                <p>Bride’s Dress: “Audrey” by Nouvelle Motee Maids</p>

                <p>Purchased At: Nordstrom, Dallas, TX

                </p>
                <p>Photographer: Sheena Shahangian Photography LLC

                </p>
                <p>First Look Location: Surf Hotel </p>
                <p>Elopement Location: Great Sand Dunes National Park, CO</p>
                <p>Dress Alterations: Altered For The Altar </p>
                <p>Bride's Hair: H & Co. A Luxe Salon</p>
            </div>
            <div class="privacyDivs header-text text-center mt-5">
                <span class="ico-list">
                    <i class="fa fa-facebook"></i>
                    <i class="fa fa-instagram"></i>
                    <i class="fa fa-twitter"></i>
                    <i class="fa fa-skype"></i>
                    <i class="fa fa-linkedin"></i>

                </span>
            </div>
            <div class="down-button text-center mt-5">
                <button mat-button class="buttonGlobal" routerLink="/real-brides">Real Bride</button>
            </div>
        </div>
    </div>
</div>