import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  toggleEye: boolean = true;  // toggle eye
  toggleEye1: boolean = true; //toggle eye1
  vertical: any = "true";
  changePasswordCard:boolean=true
  resetPasswordForm: FormGroup; //form name
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.formValidation()
  }

  formValidation(){
    // Form group and Form Control Name With Validations
    this.resetPasswordForm = new FormGroup({
     newPassword: new FormControl("", [
       Validators.required,
       Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i),
       Validators.maxLength(30),
     ]),
     confirmPassword: new FormControl("", [
       Validators.required,
       Validators.maxLength(32),
     ]),
   });
     }

     navigateToSignin(){
      this.router.navigate(['/signin'])
    }
   
    toOpenResetPasswordSuccessCard()
    {
      this.changePasswordCard= !this.changePasswordCard
    }
}
