<div class="wrapper">
    <!-- <mat-toolbar class="toolbar_custom header-text">
        Home/My Account
    </mat-toolbar> -->
    <mat-sidenav-container class="clt">
        <mat-sidenav mode="side" opened>
            <!-- customer -->
            <button mat-button class="side_menu_button header-text"
                [ngClass]="{'active': managementStatus == 'PROFILE'}" (click)="managementShow('PROFILE')">
                <p>My Account</p>
            </button>
            <button mat-button class="side_menu_button header-text"
                [ngClass]="{'active': managementStatus == 'ADDRESS'}" (click)="managementShow('ADDRESS')">
                <p>My Address</p>
            </button>
            <button mat-button class="side_menu_button header-text" [ngClass]="{'active': managementStatus == 'ORDER'}"
                (click)="managementShow('ORDER')">
                <p>My Orders</p>
            </button>
            <!-- <button mat-button class="side_menu_button header-text" (click)="managementShow('PROFILE')">
                <p>Order Status</p>
            </button> -->
            <!-- <button mat-button class="side_menu_button header-text" (click)="managementShow('EVENT')">
                <p>My Events</p>
            </button> -->
            <button mat-button class="side_menu_button header-text"
                [ngClass]="{'active': managementStatus == 'APPOINTMENT'}" (click)="managementShow('APPOINTMENT')">
                <p>My Appointment</p>
            </button>
            <!-- <button mat-button class="side_menu_button header-text" (click)="managementShow('MEASUREMENT')">
                <p>My Measurement</p>
            </button> -->
            <button mat-button class="side_menu_button header-text"
                [ngClass]="{'active': managementStatus == 'WISHLIST'}" (click)="managementShow('WISHLIST')">
                <p>My Wishlist</p>
            </button>

            <!-- influencer -->
            <button *ngIf="loggedInUserType == 'INFLUENCER'" mat-button class="side_menu_button header-text"
                [ngClass]="{'active': managementStatus == 'COUPONS'}" (click)="managementShow('COUPONS')">
                <p>Coupons</p>
            </button>

            <!-- retailer -->
            <button *ngIf="loggedInUserType == 'RETAILER'" mat-button class="side_menu_button header-text"
                [ngClass]="{'active': managementStatus == 'VISITORS'}" (click)="managementShow('VISITORS')">
                <p>Visitors Management</p>
            </button>
            <!-- <button *ngIf="loggedInUserType == 'RETAILER'" mat-button class="side_menu_button header-text"
                [ngClass]="{'active': managementStatus == 'RETAILER_ORDERS'}"
                (click)="managementShow('RETAILER_ORDERS')">
                <p>Orders Management</p>
            </button> -->
            <button mat-button class="side_menu_button header-text" (click)="logOutModal()">
                <p>Logout</p>
            </button>

        </mat-sidenav>
        <mat-sidenav-content>
            <app-my-profile *ngIf="managementStatus == 'PROFILE'"></app-my-profile>
            <app-my-address *ngIf="managementStatus == 'ADDRESS'"></app-my-address>
            <app-my-orders *ngIf="managementStatus == 'ORDER'"></app-my-orders>
            <!-- <app-my-event *ngIf="managementStatus == 'EVENT'"></app-my-event> -->
            <app-my-appoinment *ngIf="managementStatus == 'APPOINTMENT'"></app-my-appoinment>
            <app-my-measurement *ngIf="managementStatus == 'MEASUREMENT'"></app-my-measurement>
            <app-my-wishlist *ngIf="managementStatus == 'WISHLIST'"></app-my-wishlist>
            <app-coupon-list *ngIf="managementStatus == 'COUPONS'"></app-coupon-list>
            <app-visitor-list *ngIf="managementStatus == 'VISITORS'"></app-visitor-list>
            <!-- <app-product-list *ngIf="managementStatus == 'RETAILER_ORDERS'"></app-product-list> -->
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>


<!-- logout modal -->
<!-- <div class="modal fade global-modal reset-modal" id="logoutModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Logout?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to logout?</p>
                                <div class="modal-btn-box">
                                    <button type="button" class="btn btn-theme" (click)="logout()">YES</button>
                                    <button type="button" class="btn btn-theme ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->
<div class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>
            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Logout
                </h2>
                <div class="">
                    <div class="image-wrapper">
                        <div class="data-details">
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <mat-catd>
                                        <h3 class="text-center">Are you sure you want to logout?</h3>
                                    </mat-catd>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-element-wrapper m-0">
                    <div class="new-style-logout">
                        <div class="submit-logout buttonGlobal new-cls-no" id="submit-cart-modal" data-dismiss="modal">
                            No
                        </div>
                        <div class="submit-logout buttonGlobal" id="submit-cart-modal" data-dismiss="modal"
                            (click)="logout()">
                            Yes
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>