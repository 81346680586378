import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
import { CreateDressPreviewComponent } from '../create-dress-preview/create-dress-preview.component';
import { AuthService } from 'src/app/provider/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-create-your-dress',
  templateUrl: './create-your-dress.component.html',
  styleUrls: ['./create-your-dress.component.scss'],
})
export class CreateYourDressComponent implements OnInit {
  panelOpenState = false;
  bodyListArray: any = [];
  skirtListArray: any = [];
  armListArray: any = [];
  // embellishmentListArray: any = [];
  // colorListDetail: any = [];
  colorListArray: any = [];
  embellishmentListArray: any = [];
  productListArray: any = [];
  xpandStatus = false;
  xpandStatus1 = false;
  xpandStatus2 = false;

  step = 0;
  extraLength: any = [];

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  collectionResult: any;

  customOptions: OwlOptions = {
    autoWidth: true,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    navText: [
      '<i class="fa-chevron-left"></i>',
      '<i class="fa-chevron-right></i>"',
    ],
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    // responsive: {
    //   0: {
    //     items: 3
    //   },
    //   400: {
    //     items: 3
    //   },
    //   740: {
    //     items: 3
    //   },
    //   940: {
    //     items: 3
    //   }
    // },
    // nav: true
    nav: false,
    // navigation:true,
  };
  newOptions: OwlOptions = {
    autoWidth: true,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    navText: [
      '<i class="fa-chevron-left"></i>',
      '<i class="fa-chevron-right></i>"',
    ],
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    // responsive: {
    //   0: {
    //     items: 3
    //   },
    //   400: {
    //     items: 5
    //   },
    //   740: {
    //     items: 5
    //   },
    //   940: {
    //     items: 5
    //   }
    // },
    // nav: true
    nav: false,
    // navigation:true,
  };
  embelishmentOption: OwlOptions = {
    autoWidth: true,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    navText: [
      '<i class="fa-chevron-left"></i>',
      '<i class="fa-chevron-right></i>"',
    ],
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    // responsive: {
    //   0: {
    //     items: 3
    //   },
    //   400: {
    //     items: 4
    //   },
    //   740: {
    //     items: 4
    //   },
    //   940: {
    //     items: 4
    //   }
    // },
    // nav: true
    nav: false,
    // navigation:true,
  };

  isLoading: boolean = false;
  cardForm: FormGroup;
  collectionId: any;
  enteredQuantity: number = 1;
  combinationDetails: any;
  ipDetails: any;

  premiumColorListArray: any[] = [];

  countNumber1to34: any = [
    {
      isSelceted: false,
      count: '00',
    },
    {
      isSelceted: false,
      count: '02',
    },
    {
      isSelceted: false,
      count: '04',
    },
    {
      isSelceted: false,
      count: '06',
    },
    {
      isSelceted: false,
      count: '08',
    },
    {
      isSelceted: false,
      count: '10',
    },
    {
      isSelceted: false,
      count: '12',
    },
    {
      isSelceted: false,
      count: '14',
    },
    {
      isSelceted: false,
      count: '16',
    },
    {
      isSelceted: false,
      count: '18',
    },
    {
      isSelceted: false,
      count: '20',
    },
    {
      isSelceted: false,
      count: '22',
    },
    {
      isSelceted: false,
      count: '24',
    },
    {
      isSelceted: false,
      count: '26',
    },
    {
      isSelceted: false,
      count: '28',
    },
    {
      isSelceted: false,
      count: '30',
    },
    {
      isSelceted: false,
      count: '32',
    },
    {
      isSelceted: false,
      count: '34',
    },
    {
      isSelceted: false,
      count: '36',
    },
  ];

  premiumColorId: any;
  selectedSize: any;
  selectedEmbellishmentPrice: any = 0;
  selectedColorPrice: any = 0;
  selectedArmPrice: any = 0;
  selectedSkirtExtraLength: any = 0;
  skirtExtraLength: any; // get from collection to show on UI and add when user selected
  sizeListArray: any[] = [];
  extraLengthArray: any[] = [];

  socialShopDetails: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    public commonService: CommonService
  ) {
    this.ipDetails = this.commonService.getIPDetails();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res) {
        this.collectionId = res.collectionId;
        this.getCollectionDetails();

        /**
         * route come from social shoping
         * check for details of social shoping
         * such as type and paymeny terms :: group or individual
         */
        if (res.socialShopDetails) {
          this.socialShopDetails = JSON.parse(res.socialShopDetails);
          console.log(this.socialShopDetails);
        }
      }
    });
    // this.bodyList();
    // this.skirtList();
    this.colourList();
    this.getPremiumColorList(); // get premium color list
    // this.embellishmentList();
    // this.getCollection()

    // debit and credit card validation
    this.cardForm = new FormGroup({
      expiryDate: new FormControl('', [Validators.required]),
      card: new FormControl(
        '',
        (Validators.required, Validators.pattern(/^[1-9][0-9]{7,15}$/))
      ),
      cvv: new FormControl(
        '',
        (Validators.required, Validators.pattern(/^[0-9]{3}$/))
      ),
      cardHolderName: new FormControl('', Validators.required),
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateDressPreviewComponent, {
      height: '610px',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  /**
   * @param bodyListArray
   * show all the body from collection list
   * @param skirtListArray
   * show all the skirt from collection list
   * @param armListArray
   * show all the arm from collection list
   * @param embellishmentListArray
   * show all the embellishment from collection list
   */
  getCollectionDetails() {
    // 'http://172.16.1.119:3031/api/v1/product/viewCollection?_id=627a0c0d03eba6aaa9b03e11' \
    let apiReqUrl = `${
      ApiEndPoint.viewCollection + '?_id=' + this.collectionId
    }`;

    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      console.log(res);
      if (res['responseCode'] == '200') {
        // this.collectionResult = res.result

        // body array creation
        let bodyListArray = res.result.bodyArray.map((x) => {
          return x.bodyId;
        }); // take out object from bodyId
        this.bodyListArray = bodyListArray.map((y) => ({
          ...y,
          isSelected: false,
        })); // add isSelected key to show check-right
        console.log(this.bodyListArray);

        // skirt array creation
        let skirtListArray = res.result.skirtArray.map((x) => {
          return x.skirtId;
        }); // take out object from bodyId
        this.skirtListArray = skirtListArray.map((y) => ({
          ...y,
          isSelected: false,
        })); // add isSelected key to show check-right
        console.log(this.skirtListArray);

        // arm array creation
        // let armListArray = res.result.armArray.map(x => { return x.armId }) // take out object from bodyId
        // this.armListArray = armListArray.map(y => ({ ...y, isSelected: false })) // add isSelected key to show check-right
        // console.log(this.armListArray);

        // embellishment array creation
        let embellishmentListArray = res.result.embellishmentArray.map((x) => {
          return x.embellishmentId;
        }); // take out object from bodyId
        this.embellishmentListArray = embellishmentListArray.map((y) => ({
          ...y,
          isSelected: false,
        })); // add isSelected key to show check-right
        console.log(this.embellishmentListArray);

        // this.bodyListArray = res.result
        // this.bodyListArray = res.result.map(ele => ({ ...ele, isSelected: false }))  // add isSelected key to show check-right
        // console.log(this.bodyListArray);
        this.commonService.hideSpinner();
      } else {
        this.collectionResult = [];
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }

  bodyList() {
    let apiReqUrl = ApiEndPoint.listBodice;

    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        // this.bodyListArray = res.result
        this.bodyListArray = res.result.map((ele) => ({
          ...ele,
          isSelected: false,
        })); // add isSelected key to show check-right
        // console.log(this.bodyListArray);
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = [];
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }
  // skirt list  api
  skirtList() {
    let apiReqUrl = ApiEndPoint.listSkirt;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.skirtListArray = res.result ? res.result : [];
        this.commonService.hideSpinner();
      } else {
        this.skirtListArray = [];
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }

  // colour list  api
  colourList() {
    let apiReqUrl = ApiEndPoint.listColor;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        // this.colorListArray = res.result ? res.result : [];
        this.colorListArray = res.result.map((ele) => ({
          ...ele,
          isSelected: false,
        })); // add isSelected key to show check-right
        this.commonService.hideSpinner();
      } else {
        this.colorListArray = [];
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }

  // -------------------- get premium color list --------------------- //
  getPremiumColorList() {
    let apiReqUrl: any = `${ApiEndPoint.listPremiumColor}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log('get category management list response ==>', res);
      if (res.responseCode == 200) {
        this.premiumColorListArray = res.result ? res.result : '';
        // this.totalItems = res.result.total
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.premiumColorListArray = [];
        // this.totalItems = 0
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    });
  }

  // embellishment list  api
  embellishmentList() {
    let apiReqUrl = ApiEndPoint.listEmbellishment;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        // this.embellishmentListArray = res.result ? res.result : '';
        this.embellishmentListArray = res.result.map((ele) => ({
          ...ele,
          isSelected: false,
        })); // add isSelected key to show check-right
        this.commonService.hideSpinner();
      } else {
        this.embellishmentListArray = [];
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }

  /**
   * @param ApiEndPoint
   * @param apiReqUrl
   * send category and sub category
   * get collection api integration
   *  */
  // getCollection() {
  //   let apiReqUrl = ApiEndPoint.productListWithFilter
  //   let apiReqData = {
  //     "page": 1,
  //     "limit": 10,
  //     "minAmount": 0,
  //     "maxAmount": 0,
  //     "fabric": "string",
  //     "category": "6218d2a2228aa58ec6e2e02e",
  //     "subCategory": "6218d2aa228aa58ec6e2e02f",
  //     "color": "string"
  //   }

  //   this.commonService.showSpinner()
  //   this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
  //     if (res.responseCode == 200) {
  //       this.commonService.hideSpinner()
  //       // this.commonService.successToast(res.responseMessage)
  //       this.productListArray = res.result.docs[0] ? res.result.docs[0] : ''
  //     }
  //   }, (err: any) => {
  //     this.commonService.hideSpinner();
  //     if (err.responseCode == '401') {
  //       this.commonService.errorToast(err['responseMessage']);
  //       localStorage.removeItem('token');
  //     } else {
  //       this.commonService.errorToast(err.responseMessage);
  //     }
  //   })
  // }

  bodyId: any;
  skirtId: any;
  armId: any = '';
  colorId: any;
  embellishmentId: any;

  createCombinationImageUrl: any;

  bodyName: string = '';
  skirtName: string = '';
  staticBodyImageUrl: string = '';
  staticSkirtImageUrl: string = '';
  staticFullImageUrl: string = '';
  showStaticImage: string = '';

  selectBody(item) {
    this.bodyId = item._id;
    // show check icon on lis
    this.bodyListArray.forEach((element) => {
      return (element.isSelected = false);
    });
    item.isSelected = true; // make that item isSelected true
    this.bodyName = item.bodyName;
    this.showStaticImage = item.rawImage.webImage;
    this.skirtId = undefined;
    this.embellishmentId = undefined;
    // end show check icon on list
    // this.createSketch();
    // this.createStaticCombination()
  }

  selectSkirt(item) {
    this.skirtId = item._id;
    this.embellishmentId = undefined;
    // show check icon on list
    this.skirtListArray.forEach((element) => {
      return (element.isSelected = false);
    });
    item.isSelected = true; // make that item isSelected true
    this.skirtName = item.skirtName;
    this.staticSkirtImageUrl = item.rawImage.webImage;
    // end show check icon on list
    this.createSketch();
  }

  createSketch() {
    if (!this.bodyId) {
      return;
    }
    let apiReqData = {
      bodiceId: this.bodyId,
      skirtId: this.skirtId || "",
      embellishmentId: this.embellishmentId || "",
    };
    apiReqData = this.commonService.removeEmptyKey(apiReqData)

    let apiReqUrl = ApiEndPoint.getExactSketchDress;

    this.isLoading = true;

    this.commonService.getApi2(apiReqUrl,0, apiReqData).subscribe(
      (res: any) => {
        // console.log(res)
        if (res.responseCode == 200) {
          this.showStaticImage = res.result.rawImage.webImage
          this.createCombination()
          this.isLoading = false;
        } else {
          // this.commonService.hideSpinner()

          this.showStaticImage = undefined;
          this.allStaticImage = undefined;
          this.embellishmentId = undefined;
          this.skirtId = undefined;
          this.isLoading = false;
          this.commonService.alertError(res.responseMessage);
        }
      },
      (err) => {
        // this.commonService.hideSpinner()
      
        this.embellishmentId ? this.embellishmentId = null : this.skirtId = null
        this.showStaticImage = undefined;
        this.allStaticImage = undefined;
        this.isLoading = false;
        this.commonService.alertError(err.responseMessage);
      }
    );
  }

  selectArm(item) {
    if (!this.combinationDetails) {
      return this.commonService.alertError(
        'Please select body and skirt combination, to continue.'
      );
    }
    this.armId = item._id;
    // show check icon on list
    this.armListArray.forEach((element) => {
      return (element.isSelected = false);
    });
    item.isSelected = true; // make that item isSelected true
    // end show check icon on list
    // this.createCombination()

    // get price of embellishment from - res.result.collectionDetails -> is use to get selected embellishment price from collection
    this.selectedArmPrice = this.combinationDetails?.collectionDetails?.arm
      ?.length
      ? (this.combinationDetails?.collectionDetails?.arm.find(
          (x) => x.armId == this.armId
        )).price
      : 0;
    console.log(this.selectedArmPrice);
    this.getTaotalPrice();
  }
  allStaticImage: any;
  selectEmbellishment(item) {
    if (!this.bodyId || !this.skirtId) {
      return this.commonService.alertError(
        'Please select body and skirt combination, to continue.'
      );
    }
    this.embellishmentId = item._id;
    // show check icon on list
    this.embellishmentListArray.forEach((element) => {
      if (element._id == item._id) {
        element.isSelected = true; // make that item isSelected true
      } else {
        element.isSelected = false;
      }
    });
   this.createSketch()
    // end show check icon on list
    // this.createCombination()
    // this.showStaticImage = item.image.webImage
    // get price of embellishment from - res.result.collectionDetails -> is use to get selected embellishment price from collection
    this.selectedEmbellishmentPrice = this.combinationDetails?.productDetails
      ?.length
      ? (this.combinationDetails?.productDetails?.find((x) => {
          return x.embellishmentId._id == this.embellishmentId;
        })).price
      : 0;
    console.log(this.selectedEmbellishmentPrice);

    this.getTaotalPrice();
  }
  selectColor(item) {
    if (!this.bodyName) {
      return;
      // this.commonService.alertError('Please select body and skirt combination, to continue.')
    }
    this.colorId = item.colorId._id;
    // show check icon on list
    this.colorListArray.forEach((element) => {
      return (element.isSelected = false);
    });
    item.colorId.isSelected = true; // make that item isSelected true
    // end show check icon on list
    // this.createCombination()
    // get price of color from - res.result.collectionDetails -> is use to get color price from collection
    // this.selectedColorPrice = this.combinationDetails.collectionDetails.standardColor
    console.log(this.selectedColorPrice);
    this.showStaticImage = item.frontImage.webImage;
    this.allStaticImage = item;
    console.log('this.allStaticImage', this.allStaticImage);

    // this.getTaotalPrice();

    // remove premium color from selection also remove premium color id from request
    this.premiumColorId = '';
    this.premiumColorListArray.forEach((element) => {
      return (element.isSelected = false);
    });
  }

  // select color
  selectPremiumColor(item) {
    if (!this.combinationDetails) {
      return this.commonService.alertError(
        'Please select body and skirt combination, to continue.'
      );
    }
    // console.log(item);
    // this.premiumColorId = item;
    this.premiumColorId = item.premiumColorId._id;
    // show check icon on list
    this.premiumColorListArray.forEach((element) => {
      return (element.isSelected = false);
    });
    item.isSelected = true; // make that item isSelected true
    // end show check icon on list
    // this.createCombination()
    // get price of color from - res.result.collectionDetails -> is use to get color price from collection
    // this.selectedColorPrice = this.combinationDetails.collectionDetails.premiumColor
    console.log(this.selectedColorPrice);
    this.showStaticImage = item.frontImage.webImage;
    this.allStaticImage = item;
    console.log('this.allStaticImage', this.allStaticImage);
    // this.getTaotalPrice();
    // remove standard color from selection also remove color id from request
    this.colorId = '';
    this.colorListArray.forEach((element) => {
      return (element.isSelected = false);
    });
  }

  bodySizeSelected: any;

  skirtLengthSelected: any;
  skirtLengthSelectedAmount: number;

  // selectBodySize(item) {
  //   // console.log(item);
  //   this.bodySizeSelected = item.target.value
  //   // console.log(this.bodySizeSelected);

  //   // this.embellishmentId = item._id
  //   // show check icon on list
  //   // this.embellishmentListArray.forEach(element => { return element.isSelected = false; });
  //   // item.isSelected = true; // make that item isSelected true
  //   // end show check icon on list
  //   // this.createCombination()
  // }

  stantdardColor: any = [];
  premiumColor: any = [];
  extralengthPrice = 0;
  selectSkirtLength(item) {
    if (!item) {
      return;
    }
    if (!this.combinationDetails) {
      return this.commonService.alertError(
        'Please select body and skirt combination, to continue.'
      );
    }

    console.log(item);

    let selected = this.extraLength.find((ele) => {
      return ele.type == item;
    });
    this.skirtLengthSelected = selected.type;
    this.extralengthPrice = selected.addOnPrice;
    console.log(this.skirtLengthSelected);
    console.log(this.skirtExtraLength.price);

    this.getTaotalPrice();
    // this.embellishmentId = item._id
    // show check icon on list
    // this.embellishmentListArray.forEach(element => { return element.isSelected = false; });
    // item.isSelected = true; // make that item isSelected true
    // end show check icon on list
    // this.createCombination()
  }
  size: any = [];
  newProductId: any;
  createCombination() {
    // console.log(this.skirtId);
    // console.log(this.bodyId);
    let apiReqData = {
      // 'bodiceId': '6225de16d9987ba01759e3f1',
      // 'skirtId': '6225de16d9987ba01759e3f3',
      // 'colorId': '61f919c0943e6ecafd2afef2'
      bodiceId: this.bodyId,
      skirtId: this.skirtId,
      // 'colorId': this.colorId,
      currencyCode: this.ipDetails.geoplugin_currencyCode,
      embellishmentId: this.embellishmentId,
    };

    let apiReqUrl = ApiEndPoint.getProductDetailsOfCustomProduct;
    // this.commonService.showSpinner();
    this.createCombinationImageUrl = '';
    this.isLoading = true;

    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe(
      (res: any) => {
        // console.log(res)
        if (res.responseCode == 200) {
          this.size = res.result.otherProductDetails.size;
          this.extraLength = res.result.otherProductDetails.extraLength;
          this.newProductId = res.result.otherProductDetails._id;
          this.stantdardColor = [];
          this.premiumColor = [];
          this.colorListArray = [];
          this.premiumColorListArray = [];
          let _data;
          res.result.productDetails.map((item) => {
            item.productImageDetails.map((item2) => {
              item2.colorId && this.stantdardColor.push(item2);
              item2.colorId && this.colorListArray.push(item2);
              item2.premiumColorId && this.premiumColor.push(item2);
              item2.premiumColorId && this.premiumColorListArray.push(item2);
              _data = item;
            });
          });

          if (this.bodyId && this.skirtId) {
            res.result.rawImage =
              res.result?.productDetails?.productImageDetails?.frontImage?.webImage; // on object create new key name. Assign old value to this
            delete res.result?.productDetails?.productImageDetails?.frontImage
              ?.webImage;
            console.log(res.result.rawImage);
       
            // console.log();
            this.combinationDetails = res.result; // get cobmination details after manupulation
            console.log(this.combinationDetails);
            // res.result.finalResult -> is only use for product price, product image and conversionRate only
            /**
             * @param combinationDetails
             * res.result.collectionDetails -> is use to get selected embellishment price from collection
             * is use to get selected color / premiumColor price from collection
             * is use to get selected size price from collection
             * is use to get selected skirt extra length price from collection
             */

            // get color list based on product select
            // this.colorListArray = res.result.colorId.map(ele => ({ ...ele, isSelected: false }))  // add isSelected key to show check-right

            // get skirt extra length get from combinationDetails
            this.skirtExtraLength = this.combinationDetails.productDetails.find(
              (x) => x.skirtId._id == this.skirtId
            );
            console.log(this.skirtExtraLength);

            // get price based on body and skirt selected
            this.getTaotalPrice();
            // this.colorListArray = res.result.colorId.map(ele => ({ ...ele, isSelected: false }))  // add isSelected key to show check-right
            // this.sizeListArray = res.result.size;
            // this.extraLengthArray = res.result.collectionId.extraLength
          } else {
            setTimeout(() => {
              this.createCombinationImageUrl = res.result;
              this.isLoading = false;
              // this.commonService.hideSpinner()
            }, 200);
          }
          console.log(this.createCombinationImageUrl);
        } else {
          // this.commonService.hideSpinner()

          this.showStaticImage = undefined;
          this.allStaticImage = undefined;
          this.embellishmentId = undefined;
          this.skirtId = undefined;
          this.isLoading = false;
          this.commonService.alertError(res.responseMessage);
        }
      },
      (err) => {
        // this.commonService.hideSpinner()
        if (err.responseCode == 409) {
          this.embellishmentId = undefined;
        } else {
          this.skirtId = undefined;
        }
        this.showStaticImage = undefined;
        this.allStaticImage = undefined;
        this.isLoading = false;
        this.commonService.alertError(err.responseMessage);
      }
    );
  }

  changeImg(img) {
    this.showStaticImage = img;
  }
  createStaticCombination() {
    if (this.bodyName) {
      this.stantdardColor.length
        ? this.selectColor(this.stantdardColor[0])
        : this.selectColor(this.premiumColor[0]);
    }

    // if(this.bodyName && this.bodyName.includes("ADRIANA")){
    //   if(this.skirtName && this.skirtName.includes("DAHLIA")){
    //     // this.showStaticImage = "assets/images/images/Adriana with Dhalia skirt.png"
    //     this.stantdardColor.length ? this.selectColor(this.stantdardColor[0]) : this.selectColor(this.premiumColor[0])
    //   }
    //   else if(this.skirtName && this.skirtName.includes("EVELYN")){
    //     // this.showStaticImage = "assets/images/images/Adriana with Evelyn skirt.png"
    //     this.stantdardColor.length ? this.selectColor(this.stantdardColor[0]) : this.selectColor(this.premiumColor[0])
    //   }
    //   else{
    //     this.showStaticImage = this.staticBodyImageUrl
    //   }

    // }
    // else if(this.bodyName && this.bodyName.includes("AMELIA")){
    //   if(this.skirtName && this.skirtName.includes("DAHLIA")){
    //     // this.showStaticImage = "assets/images/images/Amelia with Dhalia skirt.png"
    //     this.stantdardColor.length ? this.selectColor(this.stantdardColor[0]) : this.selectColor(this.premiumColor[0])
    //   }
    //   else if(this.skirtName && this.skirtName.includes("EVELYN")){
    //     // this.showStaticImage = "assets/images/images/Amelia with Evelyn skirt.png"

    //   }
    //   else{
    //     this.showStaticImage = this.staticBodyImageUrl
    //   }

    // }
  }

  // addToBagApi() {
  //   if (!this.authService.checkAuthToken()) {
  //     return this.router.navigate(['/signin']);
  //   } else {
  //     $('#bagModal').modal('show')
  //     // let apiReqUrl = ApiEndPoint.addToBag;
  //     // let apiReqData = {
  //     //   // "dressLength": "string",
  //     //   "productId": this.createCombinationImageUrl._id
  //     //   // "colors": this.productDetail.productDetails[0].color._id,
  //     //   // "size": this.productDetail.productDetails[0].size,
  //     //   // "quantity": this.productDetail.productDetails[0].quantity
  //     //   // "maskDetails": {
  //     //   //   "maskId": "string",
  //     //   //   "colors": "string",
  //     //   //   "quantity": 0
  //     //   // }
  //     // }
  //     // // this.commonService.showSpinner()
  //     // $('#bagModal').modal('show')
  //     // this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
  //     //   if (res.responseCode == 200) {
  //     //     // this.commonService.hideSpinner()
  //     //     this.commonService.successToast(res.responseMessage)
  //     //   } else {
  //     //     // this.commonService.hideSpinner()
  //     //     this.commonService.errorToast(res.responseMessage)
  //     //   }
  //     // })
  //   }
  // }

  /**
   * @param apiReqUrl contain end poin url to add product in bag
   * @param addToBagApi function used to add product in bag list
   */
  addToBagApi() {
    console.log(this.combinationDetails);

    if (!this.authService.checkAuthToken()) {
      return this.router.navigate(['/signin']);
    } else if (!this.combinationDetails) {
      this.commonService.alertError(
        'Please select body and skirt combination, to continue.'
      );
    } else if (!this.premiumColorId && !this.colorId) {
      this.commonService.alertError(
        'Please select color or premium color, to continue.'
      );
    } else {
      // this.getProductId()

      // let apiReqUrl = ApiEndPoint.addToBag;
      // let apiReqUrl = `${ApiEndPoint.addToBag + '?productId=' + this.combinationDetails.finalResult._id}`;
      let apiReqUrl = `${
        ApiEndPoint.addToBag + '?productId=' + this.newProductId
      }`;

      // let apiReqData = {
      //   // "dressLength": "string",
      //   "productDetails": {
      //     "productId": this.productId,
      //     // "colors": this.productDetail.productDetails[0].color,
      //     // "size": this.productDetail.productDetails[0].size,
      //     // "quantity": this.productDetail.productDetails[0].quantity
      //     "colors": this.colorId,
      //     "size": this.selectedSize,
      //     "quantity": this.enteredQuantity,
      //   },
      //   "MRP": this.productDetail.MRP,
      //   "currencyCode": this.ipDetails.geoplugin_currencyCode

      //   // "maskDetails": {
      //   //   "maskId": "string",
      //   //   "colors": "string",
      //   //   "quantity": 0
      //   // }
      // }
      let apiReqData = {
        //   {
        //   "dressLength": "string",
        //     "totalMrp": 0,
        //       "currencyCode": "string",
        //         "colors": "string",
        //           "size": "string",
        //             "enteredQuantity": 0,
        //               "convertedRate": 0,
        //                 "premiumColorId": "string"
        // }

        dressLength: this.selectedSizeType, // selected size type send like :: REGULAR, LARGE or EXTRA_LARGE depend on selectedSize
        size: this.selectedSize,
        totalMrp: this.totalPrice,
        currencyCode: this.ipDetails.geoplugin_currencyCode,
        colors: this.colorId,
        premiumColorId: this.premiumColorId,
        enteredQuantity: this.enteredQuantity,
        convertedRate: this.combinationDetails?.obj?.conversionRate,
        embellishmentId: this.embellishmentId, // optional
        skirtExtraLength:
          this.selectedSkirtExtraLength == 0
            ? ''
            : this.selectedSkirtExtraLength *
              this.combinationDetails?.obj?.conversionRate,
        productType: 'CUSTOMIZE', // ["CUSTOMIZE","READY_TO_SHIP","COLLECTION"]
      };
      console.log(apiReqData);
      this.commonService.removeEmptyKey(apiReqData);
      console.log(apiReqData);
      this.commonService.showSpinner();
      this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe(
        (res: any) => {
          if (res.responseCode == 200) {
            this.commonService.hideSpinner();
            // $('#confirmEventModal').modal('show')
            this.commonService.alertSuccess(res.responseMessage);
            this.router.navigate(['/my-shopping-bag']);
          } else {
            this.commonService.hideSpinner();
            this.commonService.errorToast(res.responseMessage);
          }
        },
        (err: any) => {
          if (err.responseCode == 400) {
            this.commonService.hideSpinner();
            this.commonService.alertError(err.responseMessage);
          }
        }
      );
    }
  }

  // proceedSocialShop() {
  //   console.log("proceed social shop");
  // }

  //   let apiReqUrl = ApiEndPoint.productListWithFilter
  //   let apiReqData = {
  //     "page": 1,
  //     "limit": 10,
  //     "minAmount": 0,
  //     "maxAmount": 0,
  //     "fabric": "string",
  //     "category": "6218d2a2228aa58ec6e2e02e",
  //     "subCategory": "6218d2aa228aa58ec6e2e02f",
  //     "color": "string"
  //   }

  //   this.commonService.showSpinner()
  //   this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
  //     if (res.responseCode == 200) {
  //       this.commonService.hideSpinner()
  //       // this.commonService.successToast(res.responseMessage)
  //       this.productListArray = res.result.docs[0] ? res.result.docs[0] : ''
  //     }
  //   }, (err: any) => {
  //     this.commonService.hideSpinner();
  //     if (err.responseCode == '401') {
  //       this.commonService.errorToast(err['responseMessage']);
  //       localStorage.removeItem('token');
  //     } else {
  //       this.commonService.errorToast(err.responseMessage);
  //     }
  //   })
  // }

  //payment
  /**
   * Stripe patment
   */
  // load stripe
  // loadStripe() {
  //   console.log("leadstripe");
  //   if (!window.document.getElementById('stripe-custom-form-script')) {
  //     var s = window.document.createElement("script");
  //     s.id = "stripe-custom-form-script";
  //     s.type = "text/javascript";
  //     s.src = "https://js.stripe.com/v2/";
  //     s.onload = () => {
  //       window['Stripe'].setPublishableKey(this.commonService.stripePublishKey);
  //     }
  //     window.document.body.appendChild(s);
  //   }
  // }

  // isSubmittedAddressForm
  // addressForm: FormGroup;
  // orderId
  // orderDetails
  // pay() {
  //   var self = this
  //   if (!window['Stripe']) {
  //     console.log("Stripe did not initialize properly");

  //     alert('Oops! Stripe did not initialize properly.');
  //     return;
  //   }
  //   else if (this.cardForm.invalid) {
  //     console.log("cardForm.invalid");

  //     // console.log(this.cardForm.value);
  //     return this.commonService.alertError('Please provide your debit/credit card details.')
  //   }
  //   else if (this.addressForm.invalid) {
  //     console.log("addressForm.invalid");

  //     this.isSubmittedAddressForm = true
  //     return this.commonService.alertError('Please provide your billing address details.')
  //   } else {
  //     console.log("token generated else");

  //     // if (!window['Stripe']) {
  //     //   alert('Oops! Stripe did not initialize properly.');
  //     //   return;
  //     // }
  //     (<any>window).Stripe.card.createToken(
  //       {
  //         name: 'hello',
  //         number: self.cardForm.value.card,
  //         exp_month: self.cardForm.value.expiryDate.split('-')[1],
  //         exp_year: self.cardForm.value.expiryDate.split('-')[0],
  //         cvc: self.cardForm.value.cvv,
  //         // address:'Okhla'
  //       }
  //       // {
  //       //   number: '4000056655665556',
  //       //   exp_month: 4,
  //       //   exp_year: 2023,
  //       //   cvc: '314',
  //       // }
  //       , (status: number, data: any) => {
  //         console.log("stripe response", data)
  //         if (status === 200) {
  //           console.log(`Success! Card token ${data.card.id}.`)
  //           // send stripe and other details to server
  //           this.placeOrder(data.id) // place order when we get stripe token
  //           self.commonService.hideSpinner()
  //         } else {
  //           console.log(data.error.message)
  //         }
  //       });
  //   }
  // }

  // placeOrder(id) {
  //   let apiReqUrl = `${ApiEndPoint.checkOut + '?orderId=' + this.orderId}`
  //   let apiReqData = {
  //     "id": id,
  //     "shippingAddress": this.orderDetails.shippingAddress._id,
  //     "billingAddress": {
  //       // "address": this.orderDetails.shippingAddress.address,
  //       // "zipCode": this.orderDetails.shippingAddress.zipCode,
  //       // "city": this.orderDetails.shippingAddress.city,
  //       // "state": this.orderDetails.shippingAddress.state,
  //       // "country": this.orderDetails.shippingAddress.country
  //       //
  //       address: this.addressForm.value.address,
  //       country: this.addressForm.value.country,
  //       state: this.addressForm.value.state,
  //       city: this.addressForm.value.city,
  //       zipCode: this.addressForm.value.zipCode
  //     }
  //   }
  //   this.commonService.showSpinner();
  //   this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
  //     console.log(res);
  //     this.commonService.hideSpinner();
  //   })
  // }

  // select color
  // selectColor(item) {
  //   console.log(item);
  //   this.colorId = item
  //   this.premiumColorId = ''
  // }

  // select color
  // selectPremiumColor(item) {
  //   console.log(item);
  //   this.premiumColorId = item;
  //   this.colorId = ''
  // }

  /**
   * get selected size price with range of size selected by user
   * such as 0 - 18 comes into @constant REGULAR
   * 20 - 24 comes into @constant LARGE
   * 26 amd above comes into @constant EXTRA_LARGE
   *
   * and send this value to add this size price to get @constant totalPrice
   */
  selectedSizePrice: number = 0;
  selectedSizeType: string = '';
  // select size
  selectBodySize(item) {
    if (!this.combinationDetails) {
      return this.commonService.alertError(
        'Please select body and skirt combination, to continue.'
      );
    }
    // selectSize(item) {
    console.log(item);
    this.selectedSize = item;

    let getSizePrice = this.combinationDetails?.collectionDetails;

    // end condition for size range
    this.getTaotalPrice();
  }

  selectedLength: number = 0;
  // select size
  selectLength(item) {
    // console.log(item);
    this.selectedLength = item;
    this.getTaotalPrice();
  }

  totalPrice: number;
  getTaotalPrice() {
    // console.log(this.selectedLength);
    // console.log(this.selectedSizePrice);
    const totalMrp = Number(
      this.combinationDetails?.obj?.countryPrice?.totalMrp
    );
    const embellishment =
      Number(this.selectedEmbellishmentPrice) *
        this.combinationDetails.obj.conversionRate || 0;
    const color =
      Number(this.selectedColorPrice) *
      this.combinationDetails.obj.conversionRate;
    const extraLength =
      Number(this.selectedLength) * this.combinationDetails.obj.conversionRate;

    const skirtExtraLength =
      Number(this.extralengthPrice) *
        this.combinationDetails.obj.conversionRate || 0;
    // console.log(totalMrp);
    // console.log(extraLength);
    // console.log(size);
    console.log(embellishment);
    console.log(color);
    console.log(skirtExtraLength);

    // this.totalPrice = Number(this.productDetail?.countryPrice?.totalMrp) + (Number(this.selectedLength) * this.productDetail.conversionRate) + (Number(this.selectedSizePrice) * this.productDetail.conversionRate)
    this.totalPrice =
      totalMrp + embellishment + color + extraLength + skirtExtraLength;
    console.log(this.totalPrice);
  }

  /* social shoping functionality
   *
   */

  proceedToChatRoom() {
    // this.router.navigate(['/chat-room'])
    if (!this.authService.checkAuthToken()) {
      return this.router.navigate(['/signin']);
    } else if (!this.combinationDetails) {
      this.commonService.alertError(
        'Please select body and skirt combination, to continue.'
      );
    } else if (!this.premiumColorId && !this.colorId) {
      this.commonService.alertError(
        'Please select color or premium color, to continue.'
      );
    } else if (!this.selectedSizeType) {
      this.commonService.alertError('Please select size, to continue.');
    } else {
      // let apiReqUrl = ApiEndPoint.addToBag;
      let apiReqUrl = ApiEndPoint.addProductInGroup;
      let apiReqData = {
        //   {
        //   "dressLength": "string",
        //     "totalMrp": 0,
        //       "currencyCode": "string",
        //         "colors": "string",
        //           "size": "string",
        //             "enteredQuantity": 0,
        //               "convertedRate": 0,
        //                 "premiumColorId": "string"
        // }
        // gourp details
        groupId: this.socialShopDetails.groupId,
        // productId: '6242e211c0e6445fce7472ea',
        numberOfIndex: this.socialShopDetails.numberOfIndex,

        // product details
        productId: this.combinationDetails.finalResult._id,
        dressLength: this.selectedSizeType, // selected size type send like :: REGULAR, LARGE or EXTRA_LARGE depend on selectedSize
        size: this.selectedSize,
        totalMrp: this.totalPrice,
        currencyCode: this.ipDetails.geoplugin_currencyCode,
        colors: this.colorId,
        premiumColorId: this.premiumColorId,
        // "enteredQuantity": this.enteredQuantity,
        convertedRate: this.combinationDetails?.obj?.conversionRate,
        embellishmentId: this.embellishmentId, // optional
        skirtExtraLength:
          this.selectedSkirtExtraLength == 0
            ? ''
            : this.selectedSkirtExtraLength *
              this.combinationDetails?.obj?.conversionRate,
        productType: 'CUSTOMIZE', // ["CUSTOMIZE","READY_TO_SHIP","COLLECTION"]
      };
      console.log(apiReqData);
      this.commonService.removeEmptyKey(apiReqData);
      console.log(apiReqData);
      this.commonService.showSpinner();
      this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe(
        (res: any) => {
          if (res.responseCode == 200) {
            this.commonService.hideSpinner();
            // $('#confirmEventModal').modal('show')
            this.commonService.alertSuccess(res.responseMessage);
            // this.router.navigate(['/my-shopping-bag'])

            // based on payment type GROUP or INDIVIDUAL the will change the navigation
            if (res.result.payType == 'GROUP') {
              this.router.navigate(['/chat-room'], {
                queryParams: { groupId: this.socialShopDetails.groupId },
              });
            } else if (res.result.payType == 'INDIVIDUAL') {
              let socialShopOrderDetails = {
                groupId: this.socialShopDetails.groupId,
                numberOfIndex: this.socialShopDetails.numberOfIndex,
              };
              this.router.navigate(['/checkout-individual'], {
                queryParams: {
                  socialShopOrderDetails: JSON.stringify(
                    socialShopOrderDetails
                  ),
                },
              });
            }
          } else {
            this.commonService.hideSpinner();
            this.commonService.errorToast(res.responseMessage);
          }
        },
        (err: any) => {
          if (err.responseCode == 400) {
            this.commonService.hideSpinner();
            this.commonService.alertError(err.responseMessage);
          }
        }
      );
    }
  }

  proceedToCheckout() {}
}
