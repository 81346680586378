import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPoliciesData: any;

  constructor(private commonService:CommonService) {
    window.scrollTo(0,0)
   }

  ngOnInit(): void {
    this.viewPrivacyPolicyApi()
  }

/**
 * @param viewPrivacyPolicyApi function is used to add view privacy policy 
 * @param apiReqUrl contains end point url to view privacy policy
 */
  viewPrivacyPolicyApi(){
    let apiReqUrl = ApiEndPoint.privacyPolicies
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl,1).subscribe((res:any)=>{
      if(res.responseCode == 200){
        this.privacyPoliciesData = res.result
        this.commonService.hideSpinner()
      } else{
        this.commonService.hideSpinner()
      }
    })
  }
}
