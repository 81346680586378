<div class="wrapper">
    <div class="w-90">
        <div class="row pt-3">
            <div class="col-md-6 d-flex m-2">
                <div class="first-img">
                    <figure class="first-img-fig" (click)="changeImg('/assets/images/masks/mask3.jpeg')">
                        <img class="img-fist" src="/assets/images/masks/mask3.jpeg" alt="" />
                    </figure>
                    <figure class="first-img-fig" (click)="changeImg('/assets/images/masks/mask4.jpeg')">
                        <img class="img-fist" src="/assets/images/masks/mask4.jpeg" alt="" />
                    </figure>
                    <figure class="first-img-fig" (click)="changeImg('/assets/images/masks/mask6.jpeg')">
                        <img class="img-fist" src="/assets/images/masks/mask6.jpeg" alt="" />
                    </figure>
                </div>
                <figure class="fgb">
                    <img [src]="imgSrc" alt="">
                </figure>
            </div>
            <div class="col-md-5 ">
                <div class="header text-center">
                    <h2 class="header-text">Fluid Satin Mask</h2>
                    <p class="header-text">The finishing touch for your bridal party. Fluid satin face masks that match our bridesmaids color palette. Made in NYC.</p>
                    <h3 class="header-text mt-2 mb-2">$25.00</h3>
                </div>
                <div class="details-infos-wrapper">
                    <div class="details-infos-item">
                        <h4 class="header-text">Color Information</h4>
                        <p class="header-text">Colors displayed on your monitor may vary from the actual fabric color. We encourage you to view a fabric swatch in person, either at an authorized moteemaids Retailer or by ordering online before placing your order.</p>
                    </div>
                    <div class="details-infos-item">
                        <h4 class="header-text">Production and Shipping</h4>
                        <p class="header-text">Please note - all masks are made to order in NYC. Please allow 2 weeks for production & shipping.</p>
                    </div>
                </div>
                <mat-accordion>
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header class="header-text mt-2">
                            <mat-panel-title> Details & Care </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="list-unorder">
                            <li>Fluid satin outer</li>
                            <li>Fully lined</li>
                            <li>Adjustable fabric ear loops</li>
                            <li>Interior pocket for filter insert (insert not included)</li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
                <div class="header-text">
                    <span class="contact-detail">
                        <i class="fa fa-commenting list-cmnt"></i>
                        <p class="m-0">Contact stylist</p>
                    </span>
                </div>

                <div class="d-flex justify-content-center ico-list header-text">
                    <i class="fa fa-facebook-square"></i>
                    <i class="fa fa-twitter-square" aria-hidden="true"></i>
                    <i class="fa fa-pinterest-square" aria-hidden="true"></i>
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                    <i class="fa fa-envelope-square" aria-hidden="true"></i>
                </div>
                <div class="modal-footer border-0 justify-content-around">
                    <button mat-button class="col-md-4 first-cls" data-toggle="modal" data-target="#bagModal">
                        <i
                        class="fa fa-shopping-bag beeg" aria-hidden="true"></i>Add to Bag</button>
                </div>
            </div>
        </div>
        <div class="text-center mb-4">
            <button mat-raised-button class="buttonGlobal" routerLink="/mask-list">Back To list</button>
        </div>
    </div>
</div>








<!--  centered modal -->
<div class="modal fade" id="bagModal" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="moteemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <div class="flex-wrapper">
                    <!-- <div class="hidden-desktop">
                        <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                    </div> -->

                    <div class="image-wrapper">
                        <div class="outer-circle">
                            <div class="image-circle">
                                <img src="assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg" alt="">
                            </div>
                        </div>

                        <div class="data-details">
                            <table>
                                <tbody>
                                    <tr class="detail-line-name">
                                        <td colspan="2" class="product-name title-color-dark">
                                            LW203 - Tulle Trapeze Dress
                                        </td>
                                    </tr>
                                    <tr class="text-color-light">
                                        <td class="title">
                                            Color
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-color">Ivory</span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light" style="display: none;">
                                        <td class="title">
                                            Size
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-size"></span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light" style="display: none;">
                                        <td class="title">
                                            Bodice Size
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-bodice-size"></span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light" style="display: none;">
                                        <td class="title">
                                            Skirt Size
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-skirt-size"></span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light" style="display: none;">
                                        <td class="title">
                                            Skirt length
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-skirt-length"></span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light" style="display: none;">
                                        <td class="title">
                                            Dress length
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-dress-length"></span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light">
                                        <td class="title">
                                            Price
                                        </td>
                                        <td class="value">
                                            $<span class="cart-modal-product-price">1495.00</span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light">
                                        <td class="title">
                                            Quantity
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-qty">1</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="total-wrapper">
                            <hr>
                            <table>
                                <tbody>
                                    <tr class="title-color-dark">
                                        <td class="total-title">
                                            Total:
                                        </td>
                                        <td class="total-price">
                                            $<span class="cart-modal-product-total">1495.00</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="text-wrapper">
                        <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">You've successfully added a dress to your bag!
                        </h2>

                        <h3 class="header-text" style="font-size: 1em;font-weight: 200;">Please ensure you’ve reviewed our size Guide for measurements &amp; sizing accuracy prior to placing your order. All of our dresses are made to order and are non-returnable.</h3>
                        <div class="onboarding-wrapper">
                            <h4 class="title-color-dark" style="font-size: .8em;font-weight: 600;">
                                For exceptional service and to ensure we deliver your dress on time, please tell us your event date &amp; bridal party name.
                            </h4>

                            <form id="cart-modal-form" action="" accept-charset="utf-8" siq_id="autopick_9203">
                                <div class="form-element-wrapper">
                                    <label for="event-date" class="text-color-light">Event date</label>

                                    <div class="element-with-icon">
                                        <input type="date" id="event-date" name="event-date" class="add-cart-form-datepicker hasDatepicker">
                                        <!-- <i class="fa fa-calendar calendar-popup" aria-hidden="true"></i> -->
                                    </div>
                                    <!-- <span class="error-msg error-event-date">Field is required</span> -->
                                </div>

                                <div class="form-element-wrapper">
                                    <label for="bridal-party-name" class="text-color-light">Bridal Party / Bride
                                        name</label>
                                    <input type="text" id="bridal-party-name" name="bridal-party-name">
                                    <!-- <span class="error-msg error-bridal-party-name">Field is required</span> -->
                                </div>

                                <div class="form-element-wrapper">
                                    <div class="border-btn-wrapper">
                                        <div class="submit-form-btn buttonGlobal" id="" data-dismiss="modal" routerLink="/my-shopping-bag">
                                            Submit
                                        </div>
                                    </div>
                                </div>


                                <input type="hidden" id="order-variant-id" name="order-variant-id" value="">
                                <input type="hidden" id="rush-fee-id" name="rush-fee-id" value="">

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>