import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  termsConditionsData: any;

  constructor(private commonService:CommonService) {
    window.scrollTo(0,0)
   }

  ngOnInit(): void {
    this.termsConditionsApi()
  }

  /**
   * @param termsConditionsApi function is used to view terms and condition
   * @param apiReqUrl contains end poin url to view terms and condition
   */
  termsConditionsApi(){
    let apiReqUrl = ApiEndPoint.termsAndConditions
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl,1).subscribe((res:any)=>{
    if(res.responseCode == 200){
      this.termsConditionsData = res.result
      this.commonService.hideSpinner()
    } else{
      this.commonService.hideSpinner()
    }
      
    })
  }
}
