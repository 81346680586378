import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-blog-dialog',
  templateUrl: './add-blog-dialog.component.html',
  styleUrls: ['./add-blog-dialog.component.scss']
})
export class AddBlogDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
