import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
editstore:boolean=true
emailId:boolean=true
editForm:FormGroup
  constructor() { }

  ngOnInit(): void {
    this.editForm=new FormGroup({
      'storeName': new FormControl("",Validators.required),
      'emailID': new FormControl("",[Validators.required,Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/)])

    })
  }
  editstoreName(){
     this.editstore=!this.editstore
  }

  editEmailId()
  {
   this.emailId = !this.emailId
  }
}
