<app-header></app-header>
<!-- <app-retailer-header ></app-retailer-header> -->
<!-- <app-store-locator-dialog></app-store-locator-dialog> -->
<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-beat" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner><router-outlet></router-outlet> -->
<!-- <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  color = "#fff"
  size = "medium"
  [fullScreen] = "true"
  template="<img src='assets/images/other/custom-loader.gif' />" 
>
</ngx-spinner> -->
<ngx-spinner
      type="timer"
      size="medium"
      [fullScreen] = "true"></ngx-spinner>
<!-- <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  color = "#fff"
  size = "small"
  [fullScreen] = "true"
  template="<img src='assets/images/other/Logo-Animation.gif' />" 
>
</ngx-spinner> -->
<!-- <button mat-button (click)="openDialog()">Open store locator dialog</button> -->
<!-- <app-invite-chatting  type='facebook'[shareUrl]='link'></app-invite-chatting>
<app-invite-chatting  type='whatsapp'[shareUrl]='link'></app-invite-chatting>
<app-invite-chatting  type='snapchat' [shareUrl]='link'></app-invite-chatting>
<app-invite-chatting  type='skype' [shareUrl]='link'></app-invite-chatting> -->



<router-outlet></router-outlet>
<app-footer></app-footer>
<!-- to open store locator modal -->
