<div class="wrapper">
    <div class="row m-0">
        <div class="col-md-6">
            <div class="book-appointment-container">
                <h1 class="new-style">To confirm your appointment</h1>
                <div class="book-appointment-text header-text">
                    <p>An amount of {{appointmentPrice | currency : ipDetails?.geoplugin_currencySymbol}} will be
                        pre-authorized on
                        your account. You will not be
                        charged if you change or
                        cancel with at least 24 hours notice.</p>
                    <p>Once completed, you will receive a confirmation email of your appointment.

                    </p>
                    <!-- <p>Please note there is a $50 fee to reserve an appointment at the Motee Maids salon. The appointment fee is a hold on your credit card that will be refunded after you attend your appointment, or change/cancel with at least 24 hours notice. Changes or cancelations inside of 24 hours will forfeit the fee.</p> -->
                    <h1 class="new-style text-center">PAYMENT METHOD</h1>
                </div>

                <div class="book-appointment-text">
                    <div class="down-button">
                        <!-- <button mat-button class="buttonGlobal dltset">Pay with <strong>PAYPAL</strong></button> -->
                        <button mat-button class="buttonGlobal dltset" (click)="paymentModalOpen()">Debit or Credit
                            Card</button>

                    </div>
                    <!-- <p class="text-center">Powered by PayPal</p> -->
                </div>

                <div class="text-detils-new">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-md-9 d-flex justify-content-between">
                            <div class="col-md-6 cus">
                                <div>
                                    <h3 class="header-text">Party Name</h3>
                                    <p class="header-text">{{appointmentRes?.clientName}}</p>
                                </div>
                                <div>
                                    <h3 class="header-text">Event Date</h3>
                                    <p class="header-text">{{appointmentRes?.appointmentDate | date:'medium'}}</p>
                                </div>
                                <!-- <div>
                                    <h3 class="header-text">You Are</h3>
                                    <p class="header-text">Bride</p>
                                </div> -->
                                <div>
                                    <h3 class="header-text">Budget</h3>
                                    <p class="header-text">{{appointmentRes?.eventDetails?.budget | currency :
                                        ipDetails?.geoplugin_currencySymbol}}</p>
                                </div>
                                <!-- <div>
                                    <h3 class="header-text">Email</h3>
                                    <p class="header-text">{{appointmentRes?.email}}</p>
                                </div>
                                <div>
                                    <h3 class="header-text">Phone Number</h3>
                                    <p class="header-text">{{appointmentRes?.mobileNumber}}</p>
                                </div> -->
                            </div>
                            <div class="col-md-6 cus">
                                <!-- <div>
                                    <h3 class="header-text">Appointment Type</h3>
                                    <p class="header-text">{{appointmentRes?.consultingType}}</p>
                                </div> -->
                                <!-- <div>
                                    <h3 class="header-text">Concierge</h3>
                                    <p class="header-text">NYC Salon</p>
                                </div> -->
                                <!-- <div>
                                    <h3 class="header-text">For dress</h3>
                                    <p class="header-text">Bridal</p>
                                </div> -->
                                <div>
                                    <h3 class="header-text">Email</h3>
                                    <p class="header-text">{{appointmentRes?.email}}</p>
                                </div>
                                <div>
                                    <h3 class="header-text">Phone Number</h3>
                                    <p class="header-text">{{appointmentRes?.mobileNumber}}</p>
                                </div>
                                <div>
                                    <h3 class="header-text">Date of appointment</h3>
                                    <p class="header-text">{{appointmentRes?.appointmentDate | date:'medium'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="down-button">
                    <button mat-button class="buttonGlobal" routerLink="/select-date">Continue</button>
                </div> -->
                <!--  -->
                <!-- <div class="content mt-5">
                    <h3 class="m-0">Payment</h3>
                    <h5 class="header-text">All transactions are secure and encrypted.</h5>
                </div>
                <div class="">
                    <div class="padding">
                        <div class="">
                            <div class="container-fluid d-flex justify-content-center">
                                <div class="col-sm-12 col-md-12">
                                    <form [formGroup]="cardForm">
                                        <div class="card" style="border: 2px solid rgba(0,0,0,.125);">
                                            <div class="card-header">
                                                <div class="row">
                                                    <div class="col-md-6"> <span>CREDIT/DEBIT CARD PAYMENT</span> </div>
                                                    <div class="col-md-6 text-right" style="margin-top: -5px;"> <img
                                                            src="https://img.icons8.com/color/36/000000/visa.png"> <img
                                                            src="https://img.icons8.com/color/36/000000/mastercard.png">
                                                        <img src="https://img.icons8.com/color/36/000000/amex.png">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body" style="height: 270px">
                                                <div class="form-group">
                                                    <label for="cc-number" class="control-label">CARD NUMBER</label>
                                                    <input id="cc-number" type="tel"
                                                        class="input-lg form-control cc-number" formControlName="card"
                                                        autocomplete="cc-number" placeholder="•••• •••• •••• ••••"
                                                        required maxlength="16">
                                                    <span class="error"
                                                        *ngIf="cardForm.get('card').hasError('required') && (cardForm.get('card').touched || cardForm.get('card').dirty)">
                                                        *Card number is required.
                                                    </span>
                                                    <span class="error"
                                                        *ngIf="cardForm.get('card').hasError('pattern') && (cardForm.get('card').touched || cardForm.get('card').dirty)">
                                                        Please Enter valid card number.
                                                    </span>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="cc-exp" class="control-label">CARD
                                                                EXPIRY</label>
                                                            <input id="cc-exp" type="tel"
                                                                class="input-lg form-control cc-exp"
                                                                formControlName="expiryDate" autocomplete="cc-exp"
                                                                placeholder="YYYY-MM" required>
                                                            <span class="error"
                                                                *ngIf="cardForm.get('expiryDate').hasError('required') && (cardForm.get('expiryDate').touched || cardForm.get('expiryDate').dirty)">
                                                                *Card expiry date is required.
                                                            </span>
                                                            <span class="error"
                                                                *ngIf="cardForm.get('expiryDate').hasError('pattern') && (cardForm.get('expiryDate').touched || cardForm.get('expiryDate').dirty)">
                                                                Please Enter valid date.
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="cc-cvc" class="control-label">CARD CVC</label>
                                                            <input id="cc-cvc" type="tel"
                                                                class="input-lg form-control cc-cvc"
                                                                formControlName="cvv" autocomplete="off"
                                                                placeholder="•••" required maxlength="3">
                                                            <span class="error"
                                                                *ngIf="cardForm.get('cvv').hasError('required') && (cardForm.get('cvv').touched || cardForm.get('cvv').dirty)">
                                                                *Card cvc is required.
                                                            </span>
                                                            <span class="error"
                                                                *ngIf="cardForm.get('cvv').hasError('pattern') && (cardForm.get('cvv').touched || cardForm.get('cvv').dirty)">
                                                                Please enter valid cvc.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="numeric" class="control-label">CARD HOLDER NAME</label>
                                                    <input type="text" class="input-lg form-control"
                                                        formControlName="cardHolderName">
                                                    <span class="error"
                                                        *ngIf="cardForm.get('cardHolderName').hasError('required') && (cardForm.get('cardHolderName').touched || cardForm.get('cardHolderName').dirty)">
                                                        *Card holder name required.
                                                    </span>
                                                </div>
                                                 <div class="form-group"> <input value="MAKE PAYMENT" type="button"
                                                        class="btn btn-success btn-lg form-control"
                                                        style="font-size: .8rem;"> </div> 
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!--  -->
            </div>
        </div>
        <div class="col-md-6">
            <div class="image-container">
                <!-- <img src="/assets/images/SEQUIN_SASH_COLLECTION_JPEG/Kings_Lynn.jpg" alt="" class="image-custom"> -->
                <img src="/assets/images/Fully_Beaded_Collection_Shoot_2/Navy_Polka.jpg" alt="" class="image-custom">

            </div>
        </div>
    </div>

</div>


<!-- add new address modal -->
<div class="modal fade" id="paymentModalId" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn" (click)="closeModal()">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <div class="content text-center">
                    <h1 class="m-0">Payment</h1>
                    <h5 class="header-text">All transactions are secure and encrypted.</h5>
                </div>
                <div class="">
                    <div class="padding">
                        <div class="">
                            <div class="d-flex justify-content-center">
                                <div class="w-100">
                                    <form [formGroup]="cardForm">
                                        <div class="card" style="border: 2px solid rgba(0,0,0,.125);">
                                            <div class="card-header">
                                                <div class="row">
                                                    <div class="col-md-6"> <span>CREDIT/DEBIT CARD PAYMENT</span> </div>
                                                    <div class="col-md-6 text-right" style="margin-top: -5px;"> <img
                                                            src="https://img.icons8.com/color/36/000000/visa.png"> <img
                                                            src="https://img.icons8.com/color/36/000000/mastercard.png">
                                                        <img src="https://img.icons8.com/color/36/000000/amex.png">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="card-body" style="height: 270px"> -->
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label for="cc-number" class="control-label">CARD NUMBER</label>
                                                    <input id="cc-number" type="tel"
                                                        class="input-lg form-control cc-number" formControlName="card"
                                                        autocomplete="cc-number" placeholder="•••• •••• •••• ••••"
                                                        required maxlength="16">
                                                    <div class="text-danger"
                                                        *ngIf="(cardForm.get('card').invalid && (cardForm.get('card').touched || cardForm.get('card').dirty || isSubmitted));">
                                                        <span *ngIf="cardForm.get('card').errors.required">
                                                            *Card number is required.
                                                        </span>
                                                        <span *ngIf="cardForm.get('card').errors.pattern">
                                                            *Please enter valid card number.
                                                        </span>
                                                    </div>
                                                    <!-- <span class="error"
                                                        *ngIf="cardForm.get('card').hasError('required') && (cardForm.get('card').touched || cardForm.get('card').dirty)">
                                                        *Card number is required.
                                                    </span>
                                                    <span class="error"
                                                        *ngIf="cardForm.get('card').hasError('pattern') && (cardForm.get('card').touched || cardForm.get('card').dirty)">
                                                        Please enter valid card number.
                                                    </span> -->
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="cc-exp" class="control-label">CARD
                                                                EXPIRY</label>
                                                            <!-- <input id="cc-exp" type="tel"
                                                                class="input-lg form-control cc-exp"
                                                                formControlName="expiryDate" autocomplete="cc-exp"
                                                                placeholder="YYYY-MM" required maxlength="7"> -->
                                                            <input id="cc-exp" type="month"
                                                                class="input-lg form-control cc-exp"
                                                                [min]="commonService.getCurrentYearAndMonth()"
                                                                formControlName="expiryDate" autocomplete="cc-exp"
                                                                placeholder="YYYY-MM" required>
                                                            <div class="text-danger"
                                                                *ngIf="(cardForm.get('expiryDate').invalid && (cardForm.get('expiryDate').touched || cardForm.get('expiryDate').dirty || isSubmitted));">
                                                                <span
                                                                    *ngIf="cardForm.get('expiryDate').errors.required">
                                                                    *Card expiry date is required.
                                                                </span>
                                                            </div>
                                                            <!-- <span class="error"
                                                                *ngIf="cardForm.get('expiryDate').hasError('required') && (cardForm.get('expiryDate').touched || cardForm.get('expiryDate').dirty)">
                                                                *Card expiry date is required.
                                                            </span>
                                                            <span class="error"
                                                                *ngIf="cardForm.get('expiryDate').hasError('pattern') && (cardForm.get('expiryDate').touched || cardForm.get('expiryDate').dirty)">
                                                                Please enter valid date.
                                                            </span> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="cc-cvc" class="control-label">CARD CVC</label>
                                                            <input id="cc-cvc" type="password"
                                                                class="input-lg form-control cc-cvc"
                                                                formControlName="cvv" autocomplete="off"
                                                                placeholder="•••" maxlength="3">
                                                            <div class="text-danger"
                                                                *ngIf="(cardForm.get('cvv').invalid && (cardForm.get('cvv').touched || cardForm.get('cvv').dirty || isSubmitted));">
                                                                <span *ngIf="cardForm.get('cvv').errors.required">
                                                                    *Card cvc is required.
                                                                </span>
                                                                <span *ngIf="cardForm.get('cvv').errors.pattern">
                                                                    *Please enter valid card cvc.
                                                                </span>
                                                            </div>
                                                            <!-- <span class="error"
                                                                *ngIf="cardForm.get('cvv').hasError('required') && (cardForm.get('cvv').touched || cardForm.get('cvv').dirty)">
                                                                *Card cvc is required.
                                                            </span>
                                                            <span class="error"
                                                                *ngIf="cardForm.get('cvv').hasError('pattern') && (cardForm.get('cvv').touched || cardForm.get('cvv').dirty)">
                                                                Please enter valid cvc.
                                                            </span> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="numeric" class="control-label">CARD HOLDER NAME</label>
                                                    <input type="text" class="input-lg form-control"
                                                        formControlName="cardHolderName" maxlength="60">
                                                    <div class="text-danger"
                                                        *ngIf="(cardForm.get('cardHolderName').invalid && (cardForm.get('cardHolderName').touched || cardForm.get('cardHolderName').dirty || isSubmitted));">
                                                        <!-- <span *ngIf="cardForm.get('cardHolderName').errors.required">
                                                                                                                *Card holder name is required.
                                                                                                            </span> -->
                                                        <span *ngIf="cardForm.get('cardHolderName').errors.pattern">
                                                            *Please enter valid card holder name.
                                                        </span>
                                                    </div>
                                                    <!-- <span class="error"
                                                        *ngIf="cardForm.get('cardHolderName').hasError('required') && (cardForm.get('cardHolderName').touched || cardForm.get('cardHolderName').dirty)">
                                                        *Card holder name required.
                                                    </span> -->
                                                </div>
                                                <div class="mt-2">
                                                    <button class="btn btn-success btn-lg form-control"
                                                        (click)="pay()">MAKE
                                                        PAYMENT</button>
                                                </div>
                                                <!-- <div class="form-group"> <input value="MAKE PAYMENT" type="button"
                                                        class="btn btn-success btn-lg form-control"
                                                        style="font-size: .8rem;"> </div> -->
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <!-- <div class="form-element-wrapper">
                <div class="border-btn-wrapper">
                    <button type="button" class="submit-form-btn buttonGlobal" id="submit-cart-modal">
                        Cancel
                    </button>
                </div>
            </div> -->


        </div>
    </div>
</div>
<!-- </div> -->