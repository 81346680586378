<div class="wrapper">
  <div class="w-t pt-3">
    <div class="header">
      <h2 class="header-text til">Find Our Stores</h2>
    </div>
    <div class="bh-sl-form-container">
      <form [formGroup]="searchForm" action="" class="scasl-distance">
        <div class="form-input">
          <label for="bh-sl-address">Current Location</label>
          <!-- <input type="text" class="pac-target-input" name="bh-sl-address" placeholder="Enter Location"
            id="bh-sl-address" formControlName="location" maxlength="60" /> -->
          <input matInput placeholder="Location" formControlName="location" ngx-google-places-autocomplete
            [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)">
          <!-- <input  ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" type="text" class="pac-target-input" name="bh-sl-address" placeholder="Enter Location" id="bh-sl-address"  formControlName="location"  /> -->
          <!-- <p>Address: {{ userAddress }}</p>
                    <p>Latitude: {{ userLatitude }}</p>
                    <p>Longitude: {{ userLongitude }}</p> -->
          <span class="error"
            *ngIf="searchForm.get('location').hasError('required') && (searchForm.get('location').touched || searchForm.get('location').dirty)">*Please
            enter current location.</span>
          <button class="buttonGlobal locate" id="scapl-geocode-btn" type="button" (click)="locationStore()">Find My
            Location
          </button>
        </div>
        <div class="form-input">
          <label for="scasl-radius-container">Search Radius</label>
          <select id="scasl-radius-container" name="bh-sl-maxdistance" formControlName="radius">
            <option class="header-text" value="" selected>Select Radius</option>
            <option class="header-text" value="10">10</option>
            <option class="header-text" value="20">20</option>
            <option class="header-text" value="30">30</option>
            <option class="header-text" value="40">40</option>
            <option class="header-text" value="0">No Limit</option>
          </select>
          <span class="error"
            *ngIf="searchForm.get('radius').hasError('required') && (searchForm.get('radius').touched || searchForm.get('radius').dirty)">*Please
            select search radius.</span>
        </div>

        <div class="form-input">
          <label for="scasl-lengthUnit">Search Radius Measurement</label>
          <select id="scasl-lengthUnit" name="scasl-lengthUnit" formControlName="radiusMeasurement">
            <option class="header-text" value="">Select Radius Measurement</option>
            <option value="KILOMETER">Kilometer</option>
            <option value="MILES">Mile</option>
          </select>
          <span class="error"
            *ngIf="searchForm.get('radiusMeasurement').hasError('required') && (searchForm.get('radiusMeasurement').touched || searchForm.get('radiusMeasurement').dirty)">*Please
            select search radius measurement.</span>
        </div>
        <!-- <div class="filter-top">
                    <label for="filter-lab">Filter Tags</label>
                    <ul id="scasl-tag-list-container" class="filter-list">
                        <li>
                            <label class="data-lab">
                <input type="checkbox" name="tagsvalue" value="Motee Maids Bridal" />
                Motee Maids Bridal
              </label>
                        </li>
                        <li>
                            <label class="data-lab">
                <input
                  type="checkbox"
                  name="tagsvalue"
                  value="Mott Bridesmaids"
                />
                Motee Maids Bridesmaids
              </label>
                        </li>
                        <li>
                            <label class="data-lab">
                <input
                  type="checkbox"
                  name="tagsvalue"
                  value="Little White Dress"
                />
                Little White Dress
              </label>
                        </li>
                        <li>
                            <label class="data-lab">
                <input
                  type="checkbox"
                  name="tagsvalue"
                  value="Nouvelle Motee Maids Bridal"
                />
                Nouvelle Motee Maids Bridal
              </label>
                        </li>
                        <li>
                            <label class="data-lab">
                <input type="checkbox" name="tagsvalue" value="Evening" />
                Evening
              </label>
                        </li>
                        <li>
                            <label class="data-lab">
                <input
                  type="checkbox"
                  name="tagsvalue"
                  value="Nouvelle Motee Maids Bridesmaids"
                />
                Nouvelle Motee Maids Bridesmaids
              </label>
                        </li>
                    </ul>
                </div> -->
        <div class="scasl-search-btn">
          <button class="locate-search buttonGlobal" id="scapl-geocode-btn" type="button" (click)="locationStore()"
            [disabled]="!searchForm.valid">
            Search
          </button>
        </div>
      </form>
    </div>
    <div class="d-flex pt-4 mb-3 new-map-blo">
      <div class="col-md-4" style="padding-left: 0%; padding-right: 0%;">
        <div class="bh-sl-loc-list scasl-location-list">
          <ul>
            <li *ngFor="let item of locationStoreArray">
              <h3 class="header-text" style="color: #000;">{{item?.storeDetails?.storeName}}</h3>
              <p>{{item?.addressLine1}} {{item?.addressLine2}}</p>
              <p>{{item?.city}} {{item?.country}}</p>
              <p>Ph: {{item?.storeDetails?.storeContactNumber}}</p>
              <p>Email: {{item?.storeDetails?.storeWebsite}}</p>
              <p>{{item?.distance }} {{item?.unit}}</p>
              <!-- <button class="btn bht" id="scapl-geocode-btn" type="button">
                Direction
              </button> -->
            </li>
            <!-- <li>
                            <h3 class="header-text">The Motee Maids Salon</h3>
                            <p>625 Madison Ave, 2nd Floor</p>
                            <p>New York, NY 10022, USA</p>
                            <p>Ph: 212-583-1700</p>
                            <p>Email: flagship@moteemaids.com</p>
                            <p>766.62 kilometers</p>
                            <button class="btn bht" id="scapl-geocode-btn" type="button">
                Direction
              </button>
                        </li>
                        <li>
                            <h3 class="header-text">The Motee Maids Salon</h3>
                            <p>625 Madison Ave, 2nd Floor</p>
                            <p>New York, NY 10022, USA</p>
                            <p>Ph: 212-583-1700</p>
                            <p>Email: flagship@moteemaids.com</p>
                            <p>766.62 kilometers</p>+d="scapl-geocode-btn" type="button">
                Direction
              </button>
                        </li>
                        <li>
                            <h3 class="header-text">The Motee Maids Salon</h3>
                            <p>625 Madison Ave, 2nd Floor</p>
                            <p>New York, NY 10022, USA</p>
                            <p>Ph: 212-583-1700</p>
                            <p>Email: flagship@moteemaids.com</p>
                            <p>766.62 kilometers</p>
                            <button class="btn bht" id="scapl-geocode-btn" type="button">
                Direction
              </button>
                        </li>
                        <li>
                            <h3 class="header-text">The Motee Maids Salon</h3>
                            <p>625 Madison Ave, 2nd Floor</p>
                            <p>New York, NY 10022, USA</p>
                            <p>Ph: 212-583-1700</p>
                            <p>Email: flagship@moteemaids.com</p>
                            <p>766.62 kilometers</p>
                            <button class="btn bht" id="scapl-geocode-btn" type="button">
                Direction
              </button>
                        </li>
                        <li>
                            <h3 class="header-text">The Motee Maids Salon</h3>
                            <p>625 Madison Ave, 2nd Floor</p>
                            <p>New York, NY 10022, USA</p>
                            <p>Ph: 212-583-1700</p>
                            <p>Email: flagship@moteemaids.com</p>
                            <p>766.62 kilometers</p>
                            <button class="btn bht" id="scapl-geocode-btn" type="button">
                Direction
              </button>
                        </li> -->
          </ul>
        </div>
      </div>
      <!-- <img mat-card-image src="/assets/images/map.jpg" alt="map">    -->
      <div class="col-md-8 new-down-map">
        <!-- <google-map class="google_custom">
          <map-marker *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label"
            [title]="marker.title" [options]="marker.options">
          </map-marker>
        </google-map> -->
        <google-map height="500px" width="100%" [zoom]="zoom" [center]="center" [options]="options"
          (mapClick)="click($event)">
          <map-marker *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label"
            [title]="marker.title" [options]="marker.options">
          </map-marker>
        </google-map>

      </div>
    </div>
  </div>
</div>