<div class="wrapper">
  <!-- <mat-toolbar class="toolbar_custom">
    Home/My Account
</mat-toolbar> -->
  <!-- create room button-->
  <!-- <div *ngIf="!createRoom" class="w-50 m-auto h-auto text-center">
    <div> <button mat-button mat-raised-button color="primary" target="_blank" (click)=createChatRoom()
        class="header-text buttonGlobal">+ Create your curation</button>
    </div>
    <div style="font-size: 25px;line-height: 33px;">
      <p class="header-text">You can create your own curation & share invite link on social media.

      </p>
      <p class="header-text">Invite your friends & family or social media buddies. </p>
      <br>
      <p class="header-text">To make You decide Your Special dress for special occassion!</p>
    </div>
  </div> -->

  <!-- create chat room -->
  <!-- <mat-card class="example-card" *ngIf="createRoom"> -->
  <mat-card class="example-card">

    <mat-card-header>
      <div class="d-flex" style="justify-content: space-between; width: 100%;">
        <mat-card-title class="header-text">Create your curation</mat-card-title>
        <mat-card-subtitle>
          <!-- <i class="fa fa-times"></i> -->
        </mat-card-subtitle>
      </div>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="chatRoomForm" class="example-form">

        <!-- enter a name for your room -->
        <mat-form-field class="example-full-width filed_custom" appearance="outline">
          <mat-label class="header-text">Event Name</mat-label>
          <input matInput placeholder="Enter a Name for your Room" formControlName="eventName" class="curation-inp"
            maxlength="60">
        </mat-form-field>
        <span class="error"
          *ngIf="chatRoomForm.get('eventName').hasError('required') && (chatRoomForm.get('eventName').touched ||chatRoomForm.get('eventName').dirty || isSubmittedForm)">
          *Event name is required.</span>

        <!-- event date -->
        <mat-form-field class="example-full-width filed_custom fld" appearance="outline">
          <mat-label class="header-text">Event Date</mat-label>
          <input matInput type="date" placeholder="Event Date" formControlName="eventDate" class="curation-inp"
            [min]="commonService.getCurrentDate">
        </mat-form-field>
        <span class="error"
          *ngIf="chatRoomForm.get('eventDate').hasError('required') && (chatRoomForm.get('eventDate').touched || chatRoomForm.get('eventDate').dirty || isSubmittedForm)">
          *Event date is required.</span>

        <!-- selector who will pay for the Dresses -->
        <mat-form-field class="example-full-width filed_custom fld" appearance="outline">
          <mat-label class="header-text">Enter number of dresses</mat-label>
          <!-- <select matNativeControl required formControlName="payment">
                            <option value="bride" disabled>--Select--</option>
                            <option value="bride">Bride</option>
                            <option value="individual">Individual</option>
                        </select>                -->
          <input matInput type="text" formControlName="numberOfDresses" class="curation-inp"
            (keypress)="commonService.numberOnly($event)" maxlength="2"
            oninput="if(!value.match('^[1-9][0-9]*$'))value='';">

        </mat-form-field>
        <!-- <span class="error" *ngIf="chatRoomForm.get('payment').hasError('required') && (chatRoomForm.get('payment').touched ||chatRoomForm.get('payment').dirty)">
                        *Please select who will pay.</span> -->

        <div class="form-check">
          <input class="form-check-input" type="radio" id="exampleRadios1" value="GROUP" checked
            formControlName="payType">
          <label class="form-check-label header-text" for="exampleRadios1">
            You will pay for all dresses
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" id="exampleRadios2" value="INDIVIDUAL" formControlName="payType">
          <label class="form-check-label header-text" for="exampleRadios2">
            Individually pay for his/her dresses
          </label>
        </div>
      </form>
    </mat-card-content>
    <!-- button -->
    <mat-card-actions class="text-right">
      <button mat-raised-button (click)="navigateTo()">Cancel</button>
      <!-- <button mat-raised-button class="subm" routerLink="/invite-chatting">Ok</button> -->
      <button type="submit" mat-raised-button class="subm" [disabled]="!chatRoomForm.valid"
        (click)="addGroup()">Ok</button>
    </mat-card-actions>
  </mat-card>

</div>