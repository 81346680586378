import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogList: any;

  constructor(private router:Router,private commonService:CommonService) { }

  ngOnInit(): void {
    this.blogApiList()
  }
  viewBlog(){
    this.router.navigate(['/blog-view'])
  }

  /**
   * @param blogApiList is used to get add to bag product in bag list
   * @param apiReqUrl contains end point url to get baglist
   */
  blogApiList(){
    let apiReqUrl=`${"story/blogList?type=BLOG"}`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl,1).subscribe((res:any)=>{
       if(res.responseCode == 200){
        this.blogList = res.result
        console.log(this.blogList);
        console.log(this.blogList[0]);
        this.commonService.hideSpinner()
      }else{
        this.commonService.hideSpinner()
      }
    },(err)=>{
      this.commonService.hideSpinner()
    })}
    
}
