<!-- start toolbar -->
<!-- <mat-toolbar class="toolbar_custom">
   <i class="fa fa-angle-double-left mr-2"></i> <a routerLink="/mothers-collection-list" class="text-blue">Back to results</a>
</mat-toolbar> -->
<!-- end toolbar -->
<div class="wrapper" style="max-width: 100%;" #target>
    <div class="d-flex mb-5 new-d-cls">
      
        <div class="mt-4" id="sideSec">
            <div class="first-img">
               
                <figure *ngIf="productImage?.productImageDetails[0]?.frontImage?.webImage" class="first-img-fig"
                    (click)="changeImg(productImage?.productImageDetails[0]?.frontImage?.webImage)">
                    <img class="img-fist" [src]="productImage?.productImageDetails[0]?.frontImage?.webImage" alt="" />
                </figure>

                <figure *ngIf="productImage?.productImageDetails[0]?.backImage?.webImage" class="first-img-fig"
                    (click)="changeImg(productImage?.productImageDetails[0]?.backImage?.webImage)">
                    <img class="img-fist" [src]="productImage?.productImageDetails[0]?.backImage?.webImage" alt="" />
                </figure>

                <figure *ngIf="productImage?.productImageDetails[0]?.leftImage?.webImage" class="first-img-fig"
                    (click)="changeImg(productImage?.productImageDetails[0]?.leftImage?.webImage)">
                    <img class="img-fist" [src]="productImage?.productImageDetails[0]?.leftImage?.webImage" alt="" />
                </figure>

                <figure *ngIf="productImage?.productImageDetails[0]?.rightImage?.webImage" class="first-img-fig"
                    (click)="changeImg(productImage?.productImageDetails[0]?.rightImage?.webImage)">
                    <img class="img-fist" [src]="productImage?.productImageDetails[0]?.rightImage?.webImage" alt="" />
                </figure>

                <figure *ngIf="productImage?.productImageDetails[0]?.video" class="first-img-fig" (click)="changeImg(productImage?.productImageDetails[0]?.video)">
                    <img class="img-fist" [src]="productImage?.productImageDetails[0]?.video" alt="" />
                </figure>

               
            </div>
        </div>
        <mat-card id="rightSide" class="example-card mat-elevation-z0 outline new-mat-data">
          
            <div class="example-card_body">
                <div class="list-detail-img" id="firstSec">
                   
                    <lib-ngx-image-zoom [thumbImage]="imgSrc" [fullImage]="imgSrc" [magnification]="1"
                        [enableScrollZoom]="true"></lib-ngx-image-zoom>
                    
                 

                </div>
                <!-- <mat-card-content class="mrgin pl-2 pt-5 w-45 text-center header-text"> -->
                <mat-card-content class="mrgin w-100 text-center header-text" id="lastSection">
                    <h1 class="top-one">{{productDetail?.productName || '---'}}</h1>
                    <h4 class="text_custom" style="word-break: break-all;">
                        {{productDetail?.description || '---'}} </h4>
                 

                    <!-- <h2 class="m-0">${{productDetail?.productPrice[0]?.MRP || 0}}.00 </h2> -->
                    <h2 class="m-0">{{productDetail?.countryPrice?.totalMrp | currency:
                        ipDetails?.geoplugin_currencySymbol}}</h2>
                    <!-- <p class="">A807</p> -->
                    <!-- <div>
             <i class="fa fa-star m-2"></i>
             <i class="fa fa-star m-2"></i>
             <i class="fa fa-star m-2"></i>
             <i class="fa fa-star m-2"></i>
             <i class="fa fa-star m-2"></i>
          </div> -->
                    <!-- <p><span>- Be the first to</span><a class="text-dark ml-2 text-decoration-underline">write a review</a></p> -->

                    <!-- <div (click)="addFavorites();addProductWishlist()" class="favlist"> -->
                    <div (click)="addProductWishlist()" class="favlist">
                        <!-- <mat-card class="mb-3">
                <div>
                   <mat-card-title class="title-custom">Estimated delivery date: 10/07/2021 - 10/08/2021: </mat-card-title>
                   <mat-card-subtitle>You have selected an item that needs to be special ordered which means the delivery
                      date is longer than usual and your method of payment will be charged upon order placement. 
                      Please see our payment policy for more details.</mat-card-subtitle>
                </div>
             </mat-card> -->
                        <h2>
                            Add to Favorites
                            <i [ngClass]="productLike?.isLike?'fa fa-heart ct':'fa fa-heart-o'"></i>
                        </h2>
                    </div>

                    <div>
                        <!-- <mat-form-field appearance="outline" class="mat-form-filed-custom"> -->
                        <!-- <mat-label>Size</mat-label> -->
                        <div *ngIf="productDetail?.colorDetails.length">
                            <mat-accordion>
                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                    [expanded]="step === 0" (opened)="setStep(0)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            STANDARD COLOR
                                        </mat-panel-title>
                                        <!-- <mat-panel-description>
                                        This is a summary of the content
                                    </mat-panel-description> -->
                                    </mat-expansion-panel-header>
                                    <!-- <p>This is the primary content of the panel.</p> -->
                                    <div class="header-text">
                                        <!-- {{colorListArray | json}} -->
                                        <!-- <div class="p-2 d-flex justify-content-between"> -->
                                        <div class="p-2 d-flex">
                                            <ng-container *ngFor="let item of productDetail?.colorDetails">
                                                <!-- <div class="color-block-circle" >{{item?.colorCode}}</div>  -->
                                                <div class="color-block-circle-small mr-3"
                                                    [ngClass]="{'color-block-circle-active': item?._id == colorId}"
                                                    [style.background-color]="item?.colorCode"
                                                    (click)="selectColor(item?._id)"></div>
                                            </ng-container>

                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>

                        <div *ngIf="productDetail?.premiumColorDetails.length" class="clr-list">
                            <mat-accordion>
                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                    [expanded]="step === 0" (opened)="setStep(0)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            PREMIUM COLOR
                                        </mat-panel-title>
                                        <!-- <mat-panel-description>
                                                                This is a summary of the content
                                                            </mat-panel-description> -->
                                    </mat-expansion-panel-header>
                                    <!-- <p>This is the primary content of the panel.</p> -->
                                    <div class="header-text">
                                        <!-- {{colorListArray | json}} -->
                                        <!-- <div class="p-2 d-flex justify-content-between"> -->
                                        <div class="p-2 d-flex">
                                            <ng-container *ngFor="let item of productDetail?.premiumColorDetails">
                                                <!-- <div class="color-block-circle" >{{item?.colorCode}}</div>  -->
                                                <div class="color-block-circle-small mr-3"
                                                    [ngClass]="{'color-block-circle-active': item?._id == premiumColorId}"
                                                    [style.background-color]="item?.colorCode"
                                                    (click)="selectPremiumColor(item?._id)"></div>
                                            </ng-container>

                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>

                        <!-- </mat-form-field> -->
                        <div *ngIf="productDetail?.size.length" class="clr-list">
                            <mat-accordion>

                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                    [expanded]="step === 0" (opened)="setStep(0)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            SIZE
                                        </mat-panel-title>
                                        <!-- <mat-panel-description>
                              This is a summary of the content
                            </mat-panel-description> -->
                                    </mat-expansion-panel-header>
                                    <!-- <p>This is the primary content of the panel.</p> -->
                                    <div class="header-text">
                                        <select class="form-control form-control-sm"
                                            (change)="productDetail?.isCustomize ? selectSize($event.target.value) : selectSizeReadyProduct($event.target.value) ">
                                            <option selected>Select Size</option>
                                            <option *ngFor="let item of productDetail?.size" [value]="item">{{item}}
                                            </option>
                                        </select>
                                    </div>
                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                        <!-- length -->
                        <div *ngIf="productDetail?.extraLength.length" class="clr-list">
                            <mat-accordion>

                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                    [expanded]="step === 0" (opened)="setStep(0)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            LENGTH
                                        </mat-panel-title>
                                        <!-- <mat-panel-description>
                              This is a summary of the content
                            </mat-panel-description> -->
                                    </mat-expansion-panel-header>
                                    <!-- <p>This is the primary content of the panel.</p> -->
                                    <div class="header-text">
                                        <select class="form-control form-control-sm"
                                            (change)="selectLength($event.target.value)">
                                            <option value="" selected>Select Length</option>
                                            <option *ngFor="let item of productDetail?.extraLength"
                                                [value]="item?.addOnPrice">{{item?.type}} + {{(item?.addOnPrice *
                                                productDetail?.conversionRate) | currency
                                                :ipDetails?.geoplugin_currencySymbol}} extra
                                            </option>
                                        </select>
                                    </div>
                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                        <!-- end length -->
                        <div *ngIf="!socialShopDetails" class="clr-list">
                            <mat-form-field appearance="outline" class="mat-form-filed-custom">
                                <mat-label>Quantity</mat-label>
                                <input matInput type="text" min="1" [(ngModel)]="enteredQuantity" maxlength="2"
                                    (keypress)="commonService.numberOnly($event)"
                                    oninput="if(!value.match('^[1-9][0-9]*$'))value='';">
                            </mat-form-field>
                        </div>
                        <div class="clr-list">
                            <h1>Total Price: {{totalPrice * enteredQuantity | currency :
                                ipDetails?.geoplugin_currencySymbol}}</h1>
                        </div>
                        <div class="size-guide">
                            <span (click)="toOpenSizeChart()">Size Guide</span>
                        </div>
                    </div>



                    <mat-card-actions *ngIf="!socialShopDetails" class="action_custom" routerLink="/store-locator">
                        <button mat-button class="mb-2 w-100 detail-bag">
                            Try in store
                        </button>
                        <!-- <button  mat-button  class="m-1 w-100"  routerLink="/store-locator">Visit to Store Nearby</button> -->
                    </mat-card-actions>
                    <br />
                    <mat-accordion>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header class="header-text">
                                <mat-panel-title> Details & Care </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="list-unorder">
                                <li>{{productDetail?.description}}</li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <hr />
                    <br />
                    <div class="modal-footer border-0 justify-content-around">
                        <!-- <button mat-button class="col-md-4 first-cls" (click)="addBag()"> -->
                        <!-- <button mat-button class="col-md-6 first-cls" data-toggle="modal" data-target="#bagModal" (click)="addToBagApi()"> -->
                        <!-- <button mat-button class="col-md-6 buttonGlobal first-cls" (click)="addToBagApi()">
                            <i class="fa fa-shopping-bag beeg" aria-hidden="true"></i>
                            Add to Bag
                        </button> -->
                        <!-- normal shoping ::: we will add product to bag and then checkout -->
                        <button *ngIf="!socialShopDetails" mat-button class="col-md-6 buttonGlobal first-cls"
                            (click)="addToBagApi()">
                            <!-- <i class="fa fa-shopping-bag beeg" aria-hidden="true"></i> -->
                            <span>
                                Add to Bag
                            </span>
                        </button>
                        <!-- social shoping ::: we will add product to chat room product slots and then group room creater will checkout -->
                        <button *ngIf="socialShopDetails?.payType == 'GROUP'" mat-button
                            class="col-md-6 buttonGlobal first-cls" (click)="proceedToChatRoom()">
                            <!-- <i class="fa fa-shopping-bag beeg" aria-hidden="true"></i> -->
                            Proceed to chat room
                        </button>
                        <!-- social shoping ::: we will directly proceed to checkout and after payment we will back to chat room -->
                        <button *ngIf="socialShopDetails?.payType == 'INDIVIDUAL'" mat-button
                            class="col-md-6 buttonGlobal first-cls" (click)="proceedToChatRoom()">
                            <!-- <i class="fa fa-shopping-bag beeg" aria-hidden="true"></i> -->
                            Proceed to checkout
                        </button>
                        <!-- <button mat-button class="col-md-4 first-cls" routerLink="/checkout-saved-address">
              Checkout
            </button> -->
                    </div>
                    <!-- <div>
                        <span class="contact-detail">
                            <i class="fa fa-commenting list-cmnt"></i>
                            <p class="m-0">Contact stylist</p>
                        </span>
                    </div> -->

                    <!-- <div class="d-flex justify-content-center ico-list">
                        <i class="fa fa-facebook-square"></i>
                        <i class="fa fa-twitter-square" aria-hidden="true"></i>
                        <i class="fa fa-pinterest-square" aria-hidden="true"></i>
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                        <i class="fa fa-envelope-square" aria-hidden="true"></i>
                    </div> -->

                </mat-card-content>
            </div>
        </mat-card>
    </div>
</div>

<!--  confirm event add modal -->
<div class="modal fade" id="confirmEventModal" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="moteemaids">
                <div class="close-btn" data-dismiss="modal">
                    <i class="fa fa-times new-ics" aria-hidden="true"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <div class="flex-wrapper">
                    <div class="text-wrapper">
                        <h2 class="hidden-mobile title-color-dark text-center"
                            style="font-size: 28px;font-weight: 400;">You've successfully added a dress to your bag!
                        </h2>

                        <h3 class="header-text" style="font-size: 1em;font-weight: 200;">Please ensure you’ve reviewed
                            our size Guide for measurements &amp; sizing accuracy prior to placing your order. All of
                            our dresses are made to order and are non-returnable.</h3>
                        <div class="onboarding-wrapper">
                            <h4 class="title-color-dark" style="text-align: center;">
                                Do you want to add event date & bridal party name ?
                            </h4>
                            <div class="d-flex justify-content-center">
                                <button class="submit-form-btn buttonGlobal cnfrm-modal-button"
                                    routerLink="/my-shopping-bag">No</button>
                                <button class="submit-form-btn buttonGlobal cnfrm-modal-button" data-toggle="modal"
                                    data-target="#bagModal" data-dismiss="modal">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--  centered modal -->
<div class="modal fade" id="bagModal" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="moteemaids">
                <div class="close-btn" data-dismiss="modal" routerLink="/my-shopping-bag">
                    <i class="fa fa-times new-ics" aria-hidden="true"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <div class="flex-wrapper">
                    <!-- <div class="hidden-desktop">
                        <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                    </div> -->

                    <div class="image-wrapper">
                        <div class="outer-circle">
                            <div class="image-circle">
                                <img src="assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg"
                                    alt="">
                            </div>
                        </div>

                        <div class="data-details">
                            <table>
                                <tbody>
                                    <tr class="detail-line-name">
                                        <td colspan="2" class="product-name title-color-dark">
                                            <!-- LW203 - Tulle Trapeze Dress -->
                                            {{productDetail?.productName || '--'}}
                                        </td>
                                    </tr>
                                    <tr class="text-color-light">
                                        <td class="title">
                                            Color
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-color">
                                                Ivory
                                                <!-- {{productDetail?.colorDetails[0]?.colorName || '--'}} -->
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light" style="display: none;">
                                        <td class="title">
                                            Size
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-size"></span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light" style="display: none;">
                                        <td class="title">
                                            Bodice Size
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-bodice-size"></span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light" style="display: none;">
                                        <td class="title">
                                            Skirt Size
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-skirt-size"></span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light" style="display: none;">
                                        <td class="title">
                                            Skirt length
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-skirt-length"></span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light" style="display: none;">
                                        <td class="title">
                                            Dress length
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-dress-length"></span>
                                        </td>
                                    </tr>
                                    <tr class="text-color-light">
                                        <td class="title">
                                            Price
                                        </td>
                                        <td class="value">
                                            <!-- $<span
                                                class="cart-modal-product-price">{{productDetail?.productPrice[0]?.MRP
                                                || '--'}}.00</span> -->
                                            <span
                                                class="cart-modal-product-price">{{ipDetails?.geoplugin_currencySymbol}}{{productDetail?.countryPrice?.totalMrp}}.00</span>
                                        </td>
                                    </tr>
                                    <!-- <tr class="text-color-light">
                                        <td class="title">
                                            Quantity
                                        </td>
                                        <td class="value">
                                            <span
                                                class="cart-modal-product-qty">{{productDetail?.productDetails[0]?.quantity}}</span>
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>

                        <div class="total-wrapper">
                            <hr>
                            <table>
                                <tbody>
                                    <tr class="title-color-dark">
                                        <td class="total-title">
                                            Total:
                                        </td>
                                        <td class="total-price">
                                            <!-- $<span
                                                class="cart-modal-product-total">{{productDetail?.productPrice[0]?.MRP
                                                || '--'}}</span>.00 -->
                                            <span
                                                class="cart-modal-product-price">{{ipDetails?.geoplugin_currencySymbol}}{{productDetail?.countryPrice?.totalMrp}}.00</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="text-wrapper">
                        <h2 class="hidden-mobile title-color-dark text-center"
                            style="font-size: 28px;font-weight: 400;">You've successfully added a dress to your bag!
                        </h2>

                        <h3 class="header-text" style="font-size: 1em;font-weight: 200;">Please ensure you’ve reviewed
                            our size Guide for measurements &amp; sizing accuracy prior to placing your order. All of
                            our dresses are made to order and are non-returnable.</h3>
                        <div class="onboarding-wrapper">
                            <h4 class="title-color-dark" style="font-size: .8em;font-weight: 600;">
                                For exceptional service and to ensure we deliver your dress on time, please tell us your
                                event date &amp; bridal party name.
                            </h4>

                            <form [formGroup]="addEventForm" id="cart-modal-form" action="" accept-charset="utf-8"
                                siq_id="autopick_9203">
                                <div class="form-element-wrapper">
                                    <label for="event-date" class="text-color-light">Event date</label>

                                    <div class="element-with-icon">
                                        <input type="date" id="event-date" name="event-date"
                                            class="add-cart-form-datepicker hasDatepicker" formControlName="eventDate">
                                        <!-- <i class="fa fa-calendar calendar-popup" aria-hidden="true"></i> -->
                                    </div>
                                    <span class="error"
                                        *ngIf="addEventForm.get('eventDate').hasError('required') && (addEventForm.get('eventDate').dirty || addEventForm.get('eventDate').touched || isSubmittedForm)">*Please
                                        enter event name.</span>
                                </div>

                                <div class="form-element-wrapper">
                                    <label for="bridal-party-name" class="text-color-light">Bridal Party / Bride
                                        name</label>
                                    <input type="text" id="bridal-party-name" name="bridal-party-name"
                                        formControlName="clientName">
                                    <span class="error"
                                        *ngIf="addEventForm.get('clientName').hasError('required') && (addEventForm.get('clientName').dirty || addEventForm.get('clientName').touched || isSubmittedForm)">*Please
                                        enter birdal party/bride name.</span>

                                </div>

                                <div class="form-element-wrapper">
                                    <div class="border-btn-wrapper">
                                        <div class="submit-form-btn buttonGlobal" id="" (click)="addEventApi()">
                                            Submit
                                        </div>
                                    </div>
                                </div>


                                <input type="hidden" id="order-variant-id" name="order-variant-id" value="">
                                <input type="hidden" id="rush-fee-id" name="rush-fee-id" value="">

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- open size chart modal -->
<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
    Launch demo modal
  </button> -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document" style="max-width: 50%;">
        <div class="modal-content">
            <div class="modal-body p-0">
                <i class="fa fa-times-circle close-icon-align" aria-hidden="true" data-dismiss="modal"></i>
                <img class="modal-image" src="/assets/images/size-data.png" alt="">
            </div>
        </div>
    </div>
</div>