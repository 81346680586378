<!-- signin Modal -->
<div class="wrraper back-pic">
    <div class="login_top_details">
  
      <mat-card class="mat-elevation-z0 bdr">
          <div class="main">
              <div class="top-text text-center">
                  <mat-card-title class="sign-text new-style">Forgot Password
                  </mat-card-title>
              </div>
              <div class="input-field new_login_details">
                  <form [formGroup]="forgotForm">
                      <div class="input1 col-md-12 col-lg-12">
  
                          <label for="" class="top_heading_lab header-text">Enter Your Partner ID :
  
                          </label>
                          <div class="row">
                          
                            <div class="col-md-12 col-lg-12">
                          <input type="text"  class="user-name" placeholder="Enter Your Partner ID" formControlName='email' maxlength="256">
                          <!-- validation -->
                      </div>
                          <span class="error" *ngIf="forgotForm.get('email').hasError('required') && (forgotForm.get('email').touched ||forgotForm.get('email').dirty || isSubmitTrue)">
                              *Partner id is required.</span>
                          <!-- <span class="error" *ngIf="forgotForm.get('email').hasError('pattern') && (forgotForm.get('email').touched||forgotForm.get('email').dirty)">
                              *Please enter valid Partner id.</span> -->
                      </div>
                    </div>
                      <!-- button -->
                      <div class="down-button">
                          <button mat-button class="buttonGlobal col-md-7" (click)="sendOtp()">Submit</button>
                      </div>
                  </form>
              </div>
          </div>
      </mat-card>
      </div>
  </div>