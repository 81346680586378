import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CommonService, CountryCode } from 'src/app/provider/common.service';

@Component({
  selector: 'app-edit-my-address',
  templateUrl: './edit-my-address.component.html',
  styleUrls: ['./edit-my-address.component.scss']
})
export class EditMyAddressComponent implements OnInit {
  editAddressForm:FormGroup;
  countryList: any[];
  myControl = new FormControl('+91');
  toggleEye: boolean = true;  // toggle eye for password field
  toggleEyeC: boolean = true;  // toggle eye for confirm password
  filteredOptions: Observable<CountryCode[]>;
  
  constructor(private route: Router, public commonService: CommonService) { }

  ngOnInit(): void {
    this.formValidation()
  }
  formValidation(){
    this.editAddressForm= new FormGroup({
    name:new FormControl("",Validators.required),
    email:new FormControl("",[Validators.required,
      Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)]),
    storeName: new FormControl("",Validators.required),
    location: new FormControl("",Validators.required),
    address: new FormControl("",Validators.required),
    country: new FormControl("",Validators.required),
    zipCode: new FormControl("",Validators.required),
    countryCode: new FormControl("",Validators.required),
    contactNo:  new FormControl("", [
      Validators.required,
      Validators.pattern(/^[^0][0-9]*$/),]),
  })
  this.countryList = this.commonService.countryListJson;
  this.filteredOptions = this.myControl.valueChanges.pipe(
    startWith(''),
    map(value => (value ? this._filter(value) : this.countryList.slice())),
  );
}

private _filter(value: string): CountryCode[] {
  const filterValue = value.toLowerCase();
  return this.countryList.filter(option => option.code.toLowerCase().includes(filterValue));
}

}
