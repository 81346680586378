import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { AuthService } from 'src/app/provider/auth.service';
import { CommonService } from 'src/app/provider/common.service';
import { MyAddressComponent } from './my-address/my-address.component';
declare var $
@Component({
  selector: 'app-profile-management',
  templateUrl: './profile-management.component.html',
  styleUrls: ['./profile-management.component.scss']
})
export class ProfileManagementComponent implements OnInit {
  
  managementStatus: string = 'PROFILE'
  loggedInUserType: any;
  countryListArray: any = [];
  stateListArray: any = []
  // addAddressForm: FormGroup;
  editAddressFrom:FormGroup;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  addressId:any
  constructor(private activatedRoute: ActivatedRoute, public authService: AuthService, public commonService: CommonService) {

  }

  ngOnInit(): void {
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn();
    console.log(this.loggedInUserType);
    this.activatedRoute.queryParams.subscribe((res: any) => {
      window.scroll(0, 0)
      this.managementShow(res.showTab)
    })
    // this.formValidation();
    // this.editAdrressFormValidation()
    this.countryListDetail();
    // this.viewAddress()
  }




  managementShow(management) {
    switch (management) {
      case 'PROFILE':
        this.managementStatus = 'PROFILE';
        break;
      case 'ADDRESS':
        this.managementStatus = 'ADDRESS';
        break;
      case 'ORDER':
        this.managementStatus = 'ORDER';
        break;
      case 'WISHLIST':
        this.managementStatus = 'WISHLIST';
        break;
      case 'EVENT':
        this.managementStatus = 'EVENT';
        break;
      case 'APPOINTMENT':
        this.managementStatus = 'APPOINTMENT';
        break;
      case 'MEASUREMENT':
        this.managementStatus = 'MEASUREMENT';
        break;

      // retailer
      case 'VISITORS':
        this.managementStatus = 'VISITORS';
        break;
      case 'RETAILER_ORDERS':
        this.managementStatus = 'RETAILER_ORDERS';
        break;

      // influencer
      case 'COUPONS':
        this.managementStatus = 'COUPONS';
        break;
    }
  }

  // logout() {
  //   this.authService.logout()
  // }

  logOutModal() {
    $('#logoutModal').modal('show')
  }

  logout() {
    $('#logoutModal').modal('hide')
    this.authService.logout()

  }

  /**
   * userType are used in website
   * @param country list -> normal user comes to buy product
   */
  countryListDetail() {
    let apiReqUrl = 'product/countryList'

    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.countryListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);

      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }

  stateList(countryCode) {


    let apiReqUrl = `product/stateList?countryCode=${countryCode.value ||countryCode }`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.stateListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);

      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }

 


  listAddressArray: any = [];
  listAddress() {
    let apiReqUrl = ApiEndPoint.listAddress
    let apiReqData = {
      page: this.currentPage,
      limit: this.itemPerPage,

    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log("change password response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        this.listAddressArray = res.result.docs
        
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

 

 
  // deleteAddress() {
   
  //   let addressId = localStorage.getItem('addressId')
   
  //   let apiReqUrl: any = `${ApiEndPoint.deleteAddress + '?addressId=' + addressId }`
  //   this.commonService.showSpinner();
  //   this.commonService.deleteApi(apiReqUrl,1).subscribe((res: any) => {
  //     console.log("delete category user response ==>", res)
  //     if (res.responseCode == 200) {
  //       $('#deleteAddress').modal('hide');
  //      localStorage.removeItem('addressId')
  //      this.listAddress()
  //       this.commonService.successToast(res.responseMessage);
  //     } else {
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(res.responseMessage)
  //     }
  //   })
  // }
}
