<div class="wrapper">
    <!-- fifth section image section -->
    <div class="fifth-details">
        <!-- <div class="txt-clr">
    <h2>Explore the collections</h2>
  </div> -->
        <div class="">
            <div class="row">
                <figure class="fifth-sect-img">
                    <img class="fifth-first-img-show"
                        src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Seraphina_Close_Up.jpg" alt="">
                    <div class="hero__inner-sec">
                        <div class="hero_upper-sec responsive-condition">
                            <span class="mega-subtitle-sec header-text">
                                <em>Motee Maids</em>
                            </span>
                            <h1 class="header-text">dress code</h1>
                            <div class="hero_inn-sec ">
                                <p class="header-text">"Simple, all-around chic designs make every bridal brunch, shower
                                    and party someting to celebrate. Selected styles now available in black and navy."
                                </p>
                            </div>
                            <!-- <a href="" class="new-hero-sec header-text">
                Shop Little White Dress
              </a> -->
                        </div>
                    </div>
                </figure>

            </div>
        </div>

    </div>
    <!-- start toolbar -->
    <!-- <mat-toolbar class="toolbar_custom">
<span>Home/</span><span>Bridal Collection</span>
</mat-toolbar> -->
    <!-- end toolbar -->
    <div *ngIf="sidenavMode == 'over'" class="filter-align-section fixedElement">
        <!-- <div class="filter-align-section fixedElement"> -->
        <i class="fa fa-filter" aria-hidden="true" (click)="sidenav.toggle()"></i>
    </div>
    <mat-sidenav-container class="h-100 w-100 filter-align">
        <!-- sidebar start -->
        <mat-sidenav #sidenav [mode]="sidenavMode" [opened]="sidenavModeOpened" class="sidenav-section">
            <div class="d-flex justify-content-between align-items-center p-2">
                <h3 class="m-0 font-weight-bold">Filter By</h3>
                <!-- <h3 class="m-0 font-weight-bold" style="font-size: 12px;" (click)="clearFilter()">Clear Filter</h3> -->
                <h3 *ngIf="swatchesId.length || colorId.length || sizeSelected.length || priceId"
                    class="m-0 font-weight-bold clear-filter" (click)="clearFilter()">Clear Filter</h3>
            </div>
            <mat-divider></mat-divider>

            <div class="col-md-12">
                <!-- <div class="color mt-2">
                    <mat-accordion>
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    FABRIC
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <div class="header-text">
                                <div *ngFor="let item of swatchesListArray" class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [id]="item._id"
                                        [(ngModel)]="item.isSelected" (click)="selectSwatches(item)">
                                    <label class="custom-control-label" for="customCheck1">{{item?.fabricName}}</label>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div> -->
                <div class="color mt-3">
                    <!-- <h4 class="header-text text-center">Fabric</h4> -->
                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState =true" (closed)="panelOpenState =false"
                            [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    PRICE
                                </mat-panel-title>
                                <!-- <mat-panel-description>
                This is a summary of the content
              </mat-panel-description> -->
                            </mat-expansion-panel-header>
                            <!-- <p>This is the primary content of the panel.</p> -->
                            <div class="header-text">
                                <div *ngFor="let item of priceListArray; let last = last;"
                                    class=" custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [id]="item._id" class="checkbox1"
                                        [(ngModel)]="item.isSelected" (change)="selectPrice(item)" [checked]="item.isSelected">
                                    <!-- <label *ngIf="!last" class="custom-control-label" for="c1">${{item?.minAmount}} -
                                        ${{item?.maxAmount}}</label>
                                    <label *ngIf="last" class="custom-control-label" for="c1">Over
                                        ${{item?.minAmount}}</label> -->
                                    <!-- <label *ngIf="!last" class="custom-control-label" for="c1">{{item?.minAmount |
                                        currency:
                                        ipDetails?.geoplugin_currencySymbol}} -
                                        {{item?.maxAmount | currency:
                                        ipDetails?.geoplugin_currencySymbol}}</label>
                                    <label *ngIf="last" class="custom-control-label" for="c1">Over
                                        {{item?.minAmount | currency:
                                        ipDetails?.geoplugin_currencySymbol}}</label> -->
                                    <label *ngIf="!last" class="custom-control-label"
                                        for="c1">{{ipDetails?.geoplugin_currencySymbol}}{{item?.minAmount |
                                        shortNumber}} - {{ipDetails?.geoplugin_currencySymbol}}{{item?.maxAmount |
                                        shortNumber}}</label>
                                    <label *ngIf="last" class="custom-control-label" for="c1">Over
                                        {{ipDetails?.geoplugin_currencySymbol}}{{item?.minAmount |
                                        shortNumber}}</label>
                                </div>


                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
                <div class="color mt-3">
                    <!-- <h4 class="header-text text-left">Filters</h4> -->

                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    COLOUR
                                </mat-panel-title>
                                <!-- <mat-panel-description>
                      This is a summary of the content
                    </mat-panel-description> -->
                            </mat-expansion-panel-header>
                            <!-- <p>This is the primary content of the panel.</p> -->
                            <div class="header-text">
                                <div class=" row p-2">
                                    <ng-container *ngFor="let item of colorListArray">
                                        <div class="col-md-2">
                                            <div class="color-block-circle "
                                                [ngClass]="{'color-block-circle-active': item.isSelected}"
                                                [style.background-color]="item?.colorCode" (click)="selectColor(item)">


                                            </div>
                                        </div>
                                    </ng-container>

                                </div>
                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
                <div class="color mt-3">
                    <!-- <h4 class="header-text text-left">Filters</h4> -->

                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    SIZE
                                </mat-panel-title>
                                <!-- <mat-panel-description>
                    This is a summary of the content
                  </mat-panel-description> -->
                            </mat-expansion-panel-header>
                            <!-- <p>This is the primary content of the panel.</p> -->
                            <div class="header-text">
                                <div *ngFor="let item of countNumber1to34" class=" custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [id]="item.count" class="checkbox1"
                                        [(ngModel)]="item.isSelected" (click)="selectSizes(item)"  [checked]="item.isSelected">
                                    <label class="custom-control-label" for="customCheck1">{{item?.count}}</label>
                                </div>
                                <!-- <select class="form-control form-control-sm">
                              <label for="">Body Size</label>
                              <option selected>Select Size</option>
                              <option>0</option>
                              <option>2</option>
                              <option>4</option>
                              <option>6</option>
                              <option>8</option>
                              <option>10</option>
                              <option>12</option>
                              <option>14</option>
                              <option>16</option>
                              <option>18</option>
                              <option>20</option>
                              <option>22</option>
                              <option>24</option>
                            </select> -->

                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
            </div>
        </mat-sidenav>
        <!-- sidebar end -->
        <mat-sidenav-content class=" pt-5">


            <!-- images list container -->
            <div class="new-bridal-cls">
                <div class="d-flex  header-c">
                    <!-- <h1 class="header-text title_bridal">
                        Bridal
                    </h1> -->
                    <p class="header-text">
                        Simply iconic. The true namesake collection, Motee Maids Bridal is simply modern, highlighted by
                        signature couture design techniques and hand-touched by the master seamstresses at our New York
                        atelier.
                        <!-- <strong>See the collection on you with Motee Maids Virtual Try-On</strong> -->
                    </p>
                </div>
                <div class="row top-data-sec reveal">
                    <!-- <div class="row top-data-sec"> -->
                    <ng-container *ngIf="productListArray.length && !isSkeleton">

                        <div class="fifth-img col-md-4"
                            [ngClass]="item?.productDetails[0].productImageDetails[0]?.backImage?.webImage ? 'fifth-img-ex':''"
                            *ngFor="let item of productListArray">
                            <!-- <div class="flip-box">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img class="fifth-img-show"
                                    [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt=""
                                    (click)="navigateToViewMotherDress(item?._id)">
                            </div>
                            <div class="flip-box-back">
                                <img class="fifth-img-show"
                                    [src]="item?.productDetails[0]?.image[1]?.imageUrl?.webImage" alt=""
                                    (click)="navigateToViewMotherDress(item?._id)">
                            </div>
                        </div>
                    </div> -->
                            <div class="imageBox">
                                <img class="fifth-img-show" [src]="item?.productDetails[0].productImageDetails[0]?.frontImage?.webImage" alt=""
                                    (click)="navigateToViewMotherDress(item?._id)">
                                <img class=" fifth-img-hide" [src]="item?.productDetails[0].productImageDetails[0]?.backImage?.webImage" alt=""
                                    (click)="navigateToViewMotherDress(item?._id)">
                                <!-- <img class="fifth-img-show" [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt=""
                                (click)="navigateToViewMotherDress(item?._id)">
                            <img class=" fifth-img-hide" [src]="item?.productDetails[0]?.image[1]?.imageUrl?.webImage" alt=""
                                (click)="navigateToViewMotherDress(item?._id)"> -->
                            </div>

                            <!-- <img class="fifth-img-show" [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt=""  (click)="navigateToViewMotherDress(item?._id)"> -->
                            <div class="top">
                                <div class="d-flex justify-content-between align-items-center new-like">
                                    <h4 class="text-left pc-p header-text m-0">{{item?.productName}}</h4>
                                    <i [ngClass]="item?.isLike? 'fa fa-heart ct':'fa fa-heart-o '"
                                        (click)="addProductWishlist(item?._id)"></i>
                                    <!-- <i *ngIf="!like" class="fa fa-heart-o" (click)="addProductWishlist(item?._id)"></i>
                          <i *ngIf="like" class="fa fa-heart ct" (click)="addProductWishlist(item?._id)"></i> -->
                                </div>
                                <div class="text-left pric">
                                    <h4 class="m-0 header-text">
                                        <!-- ${{item?.productPrice[0]?.MRP}}.00 -->
                                        <!-- {{ipDetails?.geoplugin_currencySymbol}}{{item?.MRP}}.00 -->
                                        <!-- {{ipDetails?.geoplugin_currencySymbol}}{{item?.countryPrice?.totalMrp}} -->
                                        {{item?.countryPrice?.totalMrp | currency:
                                        ipDetails?.geoplugin_currencySymbol}}

                                    </h4>
                                    <div class="ic d-flex">
                                        <ng-container *ngFor="let color of item.colorDetails">
                                            <!-- <img [src]="color?.image" [alt]="color?.colorName" class="image-custom-list"> -->
                                            <div class="image-custom-list" [style.background-color]="color?.colorCode">
                                            </div>

                                            <!-- <i class="fa fa-circle mr-2"></i> -->
                                        </ng-container>
                                        <!-- <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- product skeleton -->
                    <ng-container *ngIf="!productListArray.length && isSkeleton">
                        <div class="skeleton skeleton-section col-xl-4 col-lg-4 col-md-4 col-sm-2"
                            *ngFor="let item of generateFake(6)">
                            <div class="item-wrap">
                                <div class="item-img">
                                    <ngx-skeleton-loader animation="progress" [theme]="skeletonImageTheme">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Shoot_3/Pressimages/Cara_Encrusted.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">SYDNEY</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div> -->
                    <!-- =-=-=-=-=-=-=-=-= 404 error=-=-=-=-=-=-=-=-=-=-  -->
                    <div class="noPostVailableDiv reveal" *ngIf="!productListArray.length && !isSkeleton"
                        style="text-align: center;margin: auto;">
                        <div class="">
                            <img src="/assets/images/no-data-found.png" alt="img" class="animate" style="width: 50%;">
                        </div>
                    </div>
                </div>
                <!-- <div class="row justify-content-around">
                    <div class="fifth-img" routerLink="/view-mothers-dress">

                        <img class="fifth-img-show" src="/assets/images/Fully_Beaded_Collection_Shoot_2/Amelia_Fully Beaded_Back.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">SYDNEY</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>

                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Fully_Beaded_Collection_Shoot_2/Amelia_Fully_Beaded.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">PERSEPHONE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Fully_Beaded_Collection_Shoot_2/Emma_Back.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MICHAELA</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="row justify-content-around top-data-new">
                    <div class="fifth-img" routerLink="/view-mothers-dress">

                        <img class="fifth-img-show" src="/assets/images/Shoot_3/olga_in_green.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Shoot_3/Olga_Embroidery_Daisy_Champagn_0581.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Shoot_3/NAVY_1539 - ALT-2.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-around top-data-new">
                    <div class="fifth-img" routerLink="/view-mothers-dress">

                        <img class="fifth-img-show" src="/assets/images/Shoot_3/Motee_Tonal_Green.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">MONROE WITH APPLIQUE</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="fifth-img" routerLink="/view-mothers-dress">


                        <img class="fifth-img-show" src="/assets/images/Shoot_3/LAYRA_SKIRT_1577_ALT.jpg" alt="">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-left pc-p header-text m-0">SYDNEY</h4> <i class="fa fa-heart ct" aria-hidden="true"></i>
                        </div>
                        <div class="text-left pric">
                            <h4 class="m-0 header-text">
                                $ 6000.00
                            </h4>
                            <div class="ic">
                                <i class="fa fa-circle mr-2"></i><i class="fa fa-circle text-danger"></i>
                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
            <!-- </div> -->
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>