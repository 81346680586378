<div class="wrapper">
    <div class="join-data">
        <!-- <div class="staticHeading text-center">
            <h1 class="headingTitle header-text">Join Our Team</h1>
        </div> -->
        <div class="termscontent">
            <div class="privacyDivs header-text text-center">
                <h1 class="header-text">MoteeMaids Virtual Try-On
                </h1>
                <p>Browse your favorite Motee Maids Bridal, Nouvelle Motee Maids, Motee Maids Bridesmaids, and Little White Dress collection dresses and virtually try them on with our new</p>

                <p>"Virtual Try-On" technology

                </p>
                <p>Upload a photo of yourself, your friends, or anyone you choose to see which dress suits you best!

                </p>

            </div>
            <div class="privacyDivs">
                <figure>
                    <img class="new-data" src="assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg" alt="">
                </figure>

                <p class="header-text text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit atque a asperiores tenetur culpa veritatis quae, suscipit reprehenderit odio! Aut ab, atque quos eius accusantium minus fuga eligendi nemo et voluptates consequuntur nulla
                    eveniet beatae reprehenderit optio dolores quidem modi quaerat. Dolorem consequuntur asperiores earum ut id fuga quos cumque nulla recusandae sequi, ipsa assumenda tempora, sunt nemo soluta, rerum ratione pariatur iste. Amet nesciunt
                    repellat suscipit explicabo! Officia quisquam provident, tempora dicta harum eveniet necessitatibus magnam consequatur voluptate minima ullam accusamus odit deleniti debitis quod culpa deserunt eum distinctio ea. In sint, cum natus
                    facilis repudiandae dolorum dolore amet.
                </p>
            </div>
            <div class="down-button text-center mt-5">
                <button mat-button class="buttonGlobal" routerLink="/blog">Blog</button>
            </div>
        </div>
    </div>
</div>