<div class="wrapper">
    <div class="md-modal md-show">
        <div id="cart_modal" class="modal-atc">
            <div class="modal-atc-header">
                <img class="amsale-logo" src="//cdn.shopify.com/s/files/1/0032/0562/7968/t/19/assets/Amsale_logo_no_bg.png?v=6128786056097400243" alt="Motee Maids">
                <div class="close-btn">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <div class="flex-wrapper">
                    <div class="hidden-desktop">
                        <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                    </div>

                    <div class="image-wrapper">
                        <div class="outer-circle">
                            <div class="image-circle" style="background: url(//cdn.shopify.com/s/files/1/0032/0562/7968/products/Amsale_LittleWhiteDress_LW203_Tulle_Pleated_TrapezeDress_Mood4_300x300.jpg?v=1637170197);background-size: cover;"></div>
                        </div>

                        <div class="data-details">
                            <table>
                                <tbody>
                                    <tr class="detail-line-name">
                                        <td colspan="2" class="product-name">
                                            LW203 - Tulle Trapeze Dress
                                        </td>
                                    </tr>
                                    <tr class="detail-line-color">
                                        <td class="title">
                                            Color
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-color">Ivory</span>
                                        </td>
                                    </tr>
                                    <tr class="detail-line-size" style="display: none;">
                                        <td class="title">
                                            Size
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-size"></span>
                                        </td>
                                    </tr>
                                    <tr class="detail-line-bodice-size" style="display: none;">
                                        <td class="title">
                                            Bodice Size
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-bodice-size"></span>
                                        </td>
                                    </tr>
                                    <tr class="detail-line-skirt-size" style="display: none;">
                                        <td class="title">
                                            Skirt Size
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-skirt-size"></span>
                                        </td>
                                    </tr>
                                    <tr class="detail-line-skirt-length" style="display: none;">
                                        <td class="title">
                                            Skirt length
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-skirt-length"></span>
                                        </td>
                                    </tr>
                                    <tr class="detail-line-length" style="display: none;">
                                        <td class="title">
                                            Dress length
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-dress-length"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            Price
                                        </td>
                                        <td class="value">
                                            $<span class="cart-modal-product-price">1495.00</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            Quantity
                                        </td>
                                        <td class="value">
                                            <span class="cart-modal-product-qty">1</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="total-wrapper">
                            <hr>
                            <table>
                                <tbody>
                                    <tr>
                                        <td class="total-title">
                                            Total:
                                        </td>
                                        <td class="total-price">
                                            $<span class="cart-modal-product-total">1495.00</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="text-wrapper">
                        <h2 class="hidden-mobile">You've successfully added a dress to your bag!</h2>

                        <h3>Please ensure you’ve reviewed our size Guide for measurements &amp; sizing accuracy prior to placing your order. All of our dresses are made to order and are non-returnable.</h3>

                        <hr>

                        <div class="onboarding-wrapper">
                            <h4>
                                For exceptional service and to ensure we deliver your dress on time, please tell us your event date &amp; bridal party name.
                            </h4>

                            <form id="cart-modal-form" action="" accept-charset="utf-8" siq_id="autopick_9203">
                                <div class="form-element-wrapper">
                                    <label for="event-date">Event date</label>

                                    <div class="element-with-icon">
                                        <input type="text" id="event-date" name="event-date" class="add-cart-form-datepicker hasDatepicker">
                                        <i class="fa fa-calendar calendar-popup" aria-hidden="true"></i>
                                    </div>
                                    <span class="error-msg error-event-date">Field is required</span>
                                </div>

                                <div class="form-element-wrapper">
                                    <label for="bridal-party-name">Bridal Party / Bride name</label>
                                    <input type="text" id="bridal-party-name" name="bridal-party-name">
                                    <span class="error-msg error-bridal-party-name">Field is required</span>
                                </div>

                                <div class="form-element-wrapper">
                                    <div class="border-btn-wrapper">
                                        <div class="submit-form-btn" id="submit-cart-modal">
                                            Submit
                                        </div>
                                    </div>
                                </div>


                                <input type="hidden" id="order-variant-id" name="order-variant-id" value="">
                                <input type="hidden" id="rush-fee-id" name="rush-fee-id" value="">

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>