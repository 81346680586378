<!-- my order copy -->
<div class="wrapper">
    <!-- <div class="container"> -->
    <div class="content">

        <div class="order-details">
            <div class="row">
                <div class="col-md-8">
                    <div class="m-auto h-auto text-center">

                        <p class="header-text">You can create your own curation & share invite link on social
                            media.</p>
                        <p class="header-text">Invite your friends & family or social media buddies.</p>
                        <p class="header-text">To make you decide your special dress for special occassion!</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <!-- <div class="w-50 m-auto h-auto text-center"> -->
                    <div class="text-right">
                        <div>
                            <button mat-button mat-raised-button color="primary" class="header-text buttonGlobal"
                                (click)=createChatRoom()>+ Create your
                                curation</button>
                        </div>

                    </div>
                </div>
                <!-- <span class="header-text">Order Details</span> -->
            </div>
            <div class="top-text text-center mt-20">
                <mat-card-title class="mat-card-title sign-text ml-0 title-color-dark">My Curation
                </mat-card-title>
            </div>
            <!-- filter -->
            <div class="filter-section mb-3 mt-3">
                <!-- <div class=" w-100 d-flex"> -->
                <div class="">
                    <form [formGroup]="searchForm">
                        <!-- <div class="filter col-md-12 d-flex justify-content-between"> -->
                        <div class="row">
                            <div class="col-md-2">
                                <input type="text" placeholder="Search" class=" form-control srch-inp"
                                    formControlName="search" maxlength="200">
                            </div>
                            <div class="col-md-2">
                                <input type="date" placeholder="From" class=" form-control srch-inp"
                                    formControlName="fromDate" [max]="today">
                            </div>

                            <div class="col-md-2">
                                <input type="date" placeholder="To" class=" form-control srch-inp"
                                    [readonly]="!this.searchForm.controls['fromDate'].value"
                                    [min]="searchForm.controls['fromDate'].value" formControlName="toDate">
                            </div>
                            <!-- <div class="put col-md-3 d-flex justify-content-between pr-0"> -->

                            <!-- </div> -->
                            <!-- </div> -->
                            <!-- <div> -->
                            <div class="col-md-2">
                                <button class="btn buttonGlobal srch-inp" (click)="searchFormSubmit()">Search</button>
                            </div>
                            <div class="col-md-2">
                                <button class="btn buttonGlobal srch-inp" (click)="searchFormReset()">Reset</button>
                            </div>
                            <!-- <button class="btn buttonGlobal srch-inp" (click)="addVisitor()">Add New Visitor</button> -->
                        </div>
                        <!-- </div> -->
                    </form>
                </div>
            </div>
            <!-- end filter -->
            <table class="cart">
                <thead>
                    <tr>
                        <th class="cart__row-price header-text" scope="col">S.No.</th>
                        <th class="cart__row-price header-text" scope="col">Event Name</th>
                        <th class="cart__row-price header-text" scope="col">Event Date</th>
                        <th class="cart__row-price header-text" scope="col">Created by</th>
                        <th class="cart__row-price header-text" scope="col">Number of Dresses</th>
                        <th class="cart__row-price header-text" scope="col">Pay Type</th>
                        <th class="cart__row-price header-text" scope="col">Payment Status</th>
                        <th class="cart__row-price header-text" scope="col">Created At</th>
                        <th class="cart__row-price header-text" scope="col">Action</th>

                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of curationListArray | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index">
                        <td>
                            {{itemPerPage * (currentPage-1)+ i+1}}</td>
                        <td class="cart__meta small--text-left">
                            <p>{{item?.eventName || '--'}}</p>
                        </td>
                        <td class="cart__meta small--text-left">
                            <p>{{(item?.eventDate | date:'medium') || '--'}}</p>
                        </td>
                        <td class="cart__meta small--text-left">
                            <p>{{item?.createdBy?.firstName || '--'}} {{item?.createdBy?.lastName}}</p>
                        </td>
                        <td class="cart__meta small--text-left">
                            <p>{{item?.numberOfDresses || '--'}}</p>
                        </td>
                        <!-- <td class="cart__meta small--text-left">
                            <p>{{item?.createdBy}}</p>
                        </td> -->
                        <td class="cart__meta small--text-left">
                            <p>{{(item?.payType | titlecase) || '--'}}</p>
                        </td>
                        <td class="cart__meta small--text-left">
                            <p>{{(item?.paymentDone == true ? 'Completed' : 'Pending') || '--'}}</p>
                        </td>
                        <td class="cart__meta small--text-left">
                            <p>{{(item?.createdAt | date:'medium') || '--'}}</p>
                        </td>
                        <td class="cart__meta small--text-left">
                            <div class="font-custom d-flex">
                                <button class="btn btn-sm-custom" (click)="viewCuration(item?._id)">View</button>
                                <!-- <button class="btn btn-sm-custom" (click)="editVisitor()">Edit</button> -->
                                <!-- <button class="btn btn-sm-custom"
                                    (click)="deleteVisitorModal(item?._id)">Delete</button> -->
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="!curationListArray.length">
                        <td class="table-no-record" colspan="8">No Record Found</td>
                    </tr>
                </tbody>
            </table>
            <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
            </div>
        </div>
    </div>
    <!-- add new visitor details modal -->
    <div class="modal fade" id="addNewVisitordetailsModalId" tabindex="-1" aria-labelledby="bagModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered chat-dgl">
            <div class="modal-content">
                <div class="modal-atc-header">
                    <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                    <div class="close-btn">
                        <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                    </div>
                </div>

                <div class="modal-atc-body">
                    <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Add
                        Visitor
                    </h2>
                    <!-- <div class="form-element-wrapper"> -->
                    <!-- <div class="border-btn-wrapper">
                      <button class="submit-form-btn buttonGlobal btn-add-cust" id="submit-cart-modal">
                          + Add
                      </button>
                  </div> -->
                    <!-- </div> -->
                    <form [formGroup]="addVisitorForm">

                        <div class="">
                            <!-- <div class="hidden-desktop">
                    <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                </div> -->

                            <div class="image-wrapper">

                                <div class="data-details">
                                    <div class="row mt-2">
                                        <div class="col-md-12">

                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>Name</mat-label>
                                                <input matInput placeholder="Name" formControlName="clientName">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-12">

                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>Email</mat-label>
                                                <input matInput placeholder="Email" formControlName="email"
                                                    maxlength="256">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-12 d-flex">
                                            <div class="example-full-width col-md-3">
                                                <!-- <mat-label>Mobile Number</mat-label> -->

                                                <input type="text" placeholder="Country Code" matInput
                                                    aria-label="Country" class="user-name" [formControl]="myControl"
                                                    maxlength="7" (keypress)="commonService.numberOnly($event)"
                                                    [matAutocomplete]="auto" />
                                                <mat-autocomplete #auto="matAutocomplete" class="contr">
                                                    <mat-option *ngFor="let option of filteredOptions | async"
                                                        [value]="option.code || option.name">
                                                        <span style="display: flex; justify-content: space-between">

                                                            <p>{{ option.code }}</p>
                                                        </span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <mat-form-field class="example-full-width col-md-9" appearance="outline">

                                                <mat-label>Mobile Number</mat-label>
                                                <input matInput placeholder="Mobile Number"
                                                    formControlName="mobileNumber">
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>


                            </div>

                        </div>


                        <div class="form-element-wrapper">
                            <div class="border-btn-wrapper">
                                <div class="submit-form-btn buttonGlobal" id="submit-cart-modal"
                                    (click)="addVisitorUser()">
                                    Submit
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>



    <!-- start edit  visitor details modal -->
    <!-- <div class="modal fade" id="editVisitordetailsModalId" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered chat-dgl">
      <div class="modal-content">
          <div class="modal-atc-header">
              <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
              <div class="close-btn">
                  <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
              </div>
          </div>
          <div class="modal-atc-body">
              <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Edit Visitor
              </h2>
              <div class="">
                  <div class="image-wrapper">
                      <div class="data-details">
                          <div class="row mt-2">
                              <div class="col-md-12">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Name</mat-label>
                                      <input matInput placeholder="Name">
                                  </mat-form-field>
                              </div>
                              <div class="col-md-12">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Email</mat-label>
                                      <input matInput placeholder="Email">
                                  </mat-form-field>
                              </div>
                              <div class="col-md-12">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Mobile Number</mat-label>
                                      <input matInput placeholder="Mobile Number">
                                  </mat-form-field>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="form-element-wrapper">
                  <div class="border-btn-wrapper">
                      <div class="submit-form-btn buttonGlobal" id="submit-cart-modal" data-dismiss="modal">
                          Submit
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> -->

    <!-- end edit  visitor details modal -->
    <!--start delete visitor details modal -->
    <div class="modal fade" id="deleteVisitordetailsModalId" tabindex="-1" aria-labelledby="bagModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered chat-dgl">
            <div class="modal-content">
                <div class="modal-atc-header">
                    <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                    <div class="close-btn">
                        <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                    </div>
                </div>

                <div class="modal-atc-body">
                    <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">
                        Delete
                        Visitor
                    </h2>
                    <!-- <div class="form-element-wrapper"> -->
                    <!-- <div class="border-btn-wrapper">
                    <button class="submit-form-btn buttonGlobal btn-add-cust" id="submit-cart-modal">
                        + Add
                    </button>
                </div> -->
                    <!-- </div> -->
                    <div class="">
                        <!-- <div class="hidden-desktop">
                  <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
              </div> -->

                        <div class="image-wrapper">

                            <mat-card class="data-details">
                                <div class="row mt-2">
                                    <div class="col-md-12 text-center">

                                        <h3>Are you sure you want to delete this visitor ?</h3>

                                    </div>


                                </div>
                                <!-- <div class="row">
                              <div class="col-md-5">
                                  <button class="buttonGlobal">
                                  NO
                                 </button>
                              </div>
                              <div class="col-md-5">
                                  <button class="buttonGlobal">
                                      YES
                                  </button>
                              </div>
                          </div> -->
                            </mat-card>
                        </div>

                    </div>


                    <div class="form-element-wrapper">
                        <div class="border-btn-wrapper first-dlt-btn">
                            <div class="submit-form-btn buttonGlobal dlt-visitor-no" id="submit-cart-modal"
                                data-dismiss="modal">
                                NO
                            </div>
                            <div class="submit-form-btn buttonGlobal dlt-visitor-yes" id="submit-cart-modal"
                                (click)="deleteVisitor()">
                                YES
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>

    <!-- end delete visitor details modal -->




    <!-- delivery information Modal -->
    <div class="modal fade" id="deliveryInfoModalId" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered cent-data">
            <div class="modal-content content-data">
                <div class="modal-header">
                    <h5 class="modal-title header-text mod-text" id="exampleModalLabel">DELIVERY INFORMATION</h5>
                    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
                </div>
                <div class="modal-body" style="overflow: auto;">
                    <ul class="deta-list">
                        <li class="header-text">
                            Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the
                            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                            of
                            type and scrambled it to make a type specimen book. It has survived
                            not only five centuries, but also the leap into electronic typesetting, remaining
                            essentially
                            unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                            Lorem
                            Ipsum passages, and more recently with
                            desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </li>
                        <li class="header-text">
                            Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the
                            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                            of
                            type and scrambled it to make a type specimen book. It has survived
                            not only five centuries, but also the leap into electronic typesetting, remaining
                            essentially
                            unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                            Lorem
                            Ipsum passages, and more recently with
                            desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </li>
                    </ul>
                </div>
                <div class="btn-list">
                    <button type="button" class="btn btn-secondary cncl" data-dismiss="modal">CANCEL</button>
                    <button type="button" class="btn btn-primary sbt buttonGlobal" data-dismiss="modal"
                        (click)="confirmCuration()">CONFIRM</button>
                </div>
            </div>
        </div>
    </div>