<!-- signin Modal -->
<div class="wrraper back-pic">
    <div class="login_top_details">
  
      <mat-card class="mat-elevation-z0 bdr">
          <div class="main">
              <div class="top-text text-center">
                  <mat-card-title class="sign-text new-style">Forgot Password
                  </mat-card-title>
                  <!-- <p class="text-center textAfterloginHeading">Please enter the registered emailId id to reset your</p>
                  <p class="text-center textAfterloginHeading">password.</p> -->
              </div>
              <div class="input-field new_login_details">
                  <form [formGroup]="forgotForm">
                      <div class="input1 col-md-12 col-lg-12">
  
                          <label for="" class="top_heading_lab header-text">Enter Your Email Id :
  
                          </label>
                          <div class="row">
                            <div class="col-md-12 col-lg-12">
                          <input type="text" class="user-name pass_data" placeholder="Enter Email Id" formControlName='emailId' maxlength="256">
                          <!-- validation -->
                      </div>
                          <span class="error" *ngIf="forgotForm.get('emailId').hasError('required') && (forgotForm.get('emailId').touched ||forgotForm.get('emailId').dirty || isSubmittedForm)">
                              *Email id is required.</span>
                          <span class="error" *ngIf="forgotForm.get('emailId').hasError('pattern') && (forgotForm.get('emailId').touched||forgotForm.get('emailId').dirty)">
                              *Please enter valid email id.</span>
                      </div>
                    </div>
                      <!-- button -->
                      <div class="down-button">
                          <!-- <button mat-button class="buttonGlobal col-md-7" (click)="navigateToOtp()" routerLink="/influencer-login">Submit</button> -->
                          <button mat-button class="buttonGlobal col-md-7" (click)="forgotPasswordApi()">Submit</button>

                      </div>
                  </form>
              </div>
          </div>
      </mat-card>
      </div>
  </div>