<!-- start toolbar -->
<!-- <mat-toolbar class="toolbar_custom">
    <span>Home/</span><span>Look Books</span>
  </mat-toolbar> -->
  <!-- end toolbar -->

<div class="wrraper">
  <div class="">
    <mat-card class="card_custom_bg">
    <mat-card class="shadow-none mb-5">
      <mat-card-header class="mat_header_custom">
        <!-- <div mat-card-avatar class="example-header-image"></div> -->
        <mat-card-title>Original Collection</mat-card-title>
        <mat-card-subtitle>
          <a class="text-decoration-underline">More</a><i class="fa fa-arrow-circle-right m-1"></i>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="card_custom">
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/signin.jpg" alt="" class="w-100 mh-500 box_custom wow bounceInLeft" >
        </div>
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/background2.jpg" alt="" class="w-100 mh-50 box_custom wow bounceInLeft">
          <img mat-card-image  src="./assets/images/grid2.png" alt="" class="w-100 mh-50 box_custom wow rollIn ">
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="shadow-none mb-5">
      <mat-card-header class="mat_header_custom">
        <!-- <div mat-card-avatar class="example-header-image"></div> -->
        <mat-card-title>Collection 2021</mat-card-title>
        <mat-card-subtitle>
          <a class="text-decoration-underline">More</a><i class="fa fa-arrow-circle-right m-1"></i>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="card_custom">
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/grid3.png" alt="" class="w-100 mh-50 box_custom wow bounceInLeft ">
          <img mat-card-image  src="./assets/images/grid4.png" alt="" class="w-100 mh-50 box_custom animate__animated animate__backInRight wow flash">
        </div>
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/grid5.png" alt="" class="w-100 mh-50 box_custom wow rollIn ">
          <img mat-card-image  src="./assets/images/grid6.png" alt="" class="w-100 mh-50 box_custom wow bounceInLeft">
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="shadow-none mb-5">
      <mat-card-header class="mat_header_custom">
        <!-- <div mat-card-avatar class="example-header-image"></div> -->
        <mat-card-title>Style Library</mat-card-title>
        <mat-card-subtitle>
          <a class="text-decoration-underline">More</a><i class="fa fa-arrow-circle-right m-1"></i>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="styles_card_custom">
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/grid2.png" alt="" class="w-25 mh-50 box_custom animate__animated animate__backInLeft wow flash">
          <img mat-card-image  src="./assets/images/grid6.png" alt="" class="w-25 mh-50 box_custom animate__animated animate__backInLeft wow bounceInLeft">
          <img mat-card-image  src="./assets/images/grid7.png" alt="" class="w-25 mh-50 box_custom animate__animated animate__backInRight wow rollIn">
          <img mat-card-image  src="./assets/images/grid3.png" alt="" class="w-25 mh-50 box_custom animate__animated animate__backInRight wow swing">
        </div>
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/grid4.png" alt="" class="w-25 mh-50 box_custom animate__animated animate__backInLeft  wow pulse">
          <img mat-card-image  src="./assets/images/grid7.png" alt="" class="w-25 mh-50 box_custom animate__animated animate__backInLeft wow bounceInLeft">
          <img mat-card-image  src="./assets/images/grid8.png" alt="" class="w-25 mh-50 box_custom animate__animated animate__backInRight wow rollIn">
          <img mat-card-image  src="./assets/images/grid9.png" alt="" class="w-25 mh-50 box_custom animate__animated animate__backInRight wow swing">
        </div>
      </mat-card-content>
    </mat-card>

  </mat-card>
  </div>
</div>