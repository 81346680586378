<div class="wrapper">
    <div class="row m-0">
        <div class="col-md-6">
            <form action="" [formGroup]="bookAppointmentForm">
            <div class="book-appointment-container">
                <h1 class="new-style">Book Appointments!</h1>
                <div class="book-appointment-text header-text">
                    <p>Welcome to our New York City Flagship Salon.</p>
                    <p>We invite you to call or text our concierge now at 212-583-1700 or 212-971-3167 to reserve an appointment to view the moteemaids, Nouvelle moteemaids - bridal and bridesmaids, Little White Dress or Christos Collection. Or complete
                        the request form at your convenience and a consultant will follow up shortly.</p>
                    <p>Please note there is a $50 fee to reserve an appointment at the moteemaids salon. The appointment fee is a hold on your credit card that will be refunded after you attend your appointment, or change/cancel with at least 24 hours notice.
                        Changes or cancelations inside of 24 hours will forfeit the fee.</p>
                </div>

                <div class="book-appointment-text">
                    <mat-form-field appearance="outline" class="w-100 mat-form-field-custom-padding">
                        <mat-label>Select Near By Store</mat-label>
                        <select matNativeControl required formControlName = 'store'>
                                <option value="volvo">Select Near By Store</option>
                                <option value="saab">Store A</option>
                                <option value="mercedes">Store B</option>
                        </select>
                    </mat-form-field>
                    <span class="error" *ngIf = "bookAppointmentForm.get('store').hasError('required') && (bookAppointmentForm.get('store').dirty || bookAppointmentForm.get('store').touched || isSubmitted)">*Please select event type</span>

                    <mat-form-field appearance="outline" class="w-100 mat-form-field-custom-padding">
                        <mat-label>Select Event</mat-label>
                        <select matNativeControl required formControlName = 'event'>
                                <option value="volvo">Select Event</option>
                                <option value="saab">Bridal</option>
                                <option value="mercedes">Bridesmaid</option>
                        </select>
                    </mat-form-field>
                    <span class="error" *ngIf = "bookAppointmentForm.get('event').hasError('required') && (bookAppointmentForm.get('event').dirty || bookAppointmentForm.get('event').touched || isSubmitted)">*Please select event type</span>

                    <mat-form-field appearance="outline" class="w-100 mat-form-field-custom-padding">
                        <mat-label>Appointment Type</mat-label>
                        <select matNativeControl required formControlName = 'appointment'>
                            <option value="volvo">Select Appointment Type</option>
                            <option value="saab">Bridal First Appointment</option>
                            <option value="mercedes">Bridal Follow Up</option>
                       </select>
                    </mat-form-field>
                    <span class="error" *ngIf = "bookAppointmentForm.get('appointment').hasError('required') && (bookAppointmentForm.get('appointment').dirty || bookAppointmentForm.get('appointment').touched || isSubmitted)">*Please select appointment type</span>
                </div>
                <div class="down-button">
                    <button mat-button class="buttonGlobal new-btn" (click)= navigateToSelectDate() >Continue</button>
                </div>
            </div>
        </form>    
        </div>
        <div class="col-md-6">
            <div class="image-container">
                <img src="/assets/images/Fully_Beaded_Collection_Shoot_2/Navy_Polka.jpg" alt="" class="image-custom">

            </div>
        </div>
    </div>

</div>