<!-- <mat-toolbar class="toolbar_custom">
    Home/My Account
</mat-toolbar> -->
<mat-sidenav-container>
    <!-- sidenav -->
    <mat-sidenav mode="side" opened>
        <button mat-button class="side_menu_button" target="_blank" routerLink="/my-profile"><p>My Profile</p></button>
        <mat-divider></mat-divider>

        <button mat-button class="side_menu_button" target="_blank" routerLink="/addresses"><p>Addresses</p></button>
        <mat-divider></mat-divider>

        <button mat-button class="side_menu_button" target="_blank" routerLink="/my-order"><p>My Orders</p></button>
        <mat-divider></mat-divider>

        <button mat-button class="side_menu_button" target="_blank" routerLink="/order-status"><p>Order Status</p></button>
        <mat-divider></mat-divider>

        <button mat-button class="side_menu_button" target="_blank" routerLink="/my-wishlist"><p>My Wishlists</p></button>
        <mat-divider></mat-divider>
    </mat-sidenav>

    <!-- sidenav content -->
    <mat-sidenav-content class="sidenav-content-custom">
        <div class="content">
                <div class="main-cotent">
                     <span>Basic Information</span>
                </div>
                <div class="address-details">
                            <div class="unique-id" style="width: 68%;">
                                <span>Store Unique ID</span>
                                <span>:</span>
                                <span>#SDG3435</span>
                            </div>

                            <div *ngIf="editstore" class="unique-id">
                                <span>Store Name</span>
                                <span id="column">:</span>
                                <span>lorem ispum</span>
                                <span><a  class="anchor-b-e" (click)="editstoreName()">Edit</a></span>
                            </div>
                            <!-- edit store name -->
                            <div *ngIf="!editstore" class="unique-id">
                                <span>Store Name</span>
                                <span id="column">:</span>
                                <form [formGroup]="editForm">
                                    <input type="text" placeholder="store name" formControlName="storeName">
                                    <span class="error" *ngIf="editForm.get('storeName').hasError('required') && (editForm.get('storeName').touched || editForm.get('storeName').dirty)">*Store name is required.</span>
                                </form>
                                <span><a  class="anchor-b-e" (click)="editstoreName()">Save</a></span>
                            </div>

                            <div *ngIf="emailId" class="unique-id">
                                <span>Email ID</span>
                                <span class="colomn-email-id">:</span>
                                <span class="colomn-email-id">Xyz.123@gmail.com</span>
                                <span><a  class="anchor-b-e"  (click)="editEmailId()">Changes</a></span>
                            </div>
                            <!-- edit email id -->
                            <div *ngIf="!emailId" class="unique-id">
                                <span>Email ID</span>
                                <span class="colomn-email-id">:</span>
                                <form [formGroup]="editForm"><input type="email" placeholder="email id" formControlName="emailID" maxlength="256">
                                    <span class="error" *ngIf="editForm.get('emailID').hasError('required') && (editForm.get('emailID').touched || editForm.get('emailID').dirty)">*Email id is required.</span>
                                </form>
                                <span><a class="anchor-b-e" (click)="editEmailId()">Changes</a></span>
                            </div>
                </div>

                <div class="main-cotent">
                    <span>Set Preference</span>
               </div>

               <div class="d-flex display-price m-2">
                    <span>Display Price</span>
                    <span>:</span>
                    <span><mat-tab-group mat-align-tabs="start">
                        <mat-tab label="Whole Sale">Content 1</mat-tab>
                        <mat-tab label="RRP">Content 2</mat-tab>
                    </mat-tab-group></span> 
               </div>

               <div class="main-cotent">
                 <span>Password</span>
               </div>
               <div class="w-75 m-3 password-suggesstion-info">
                   <p>A strong password contains a mix of numbers, letters, and symbols.
                    It is hard to guess, does not resemble a real word,
                     and is only used for this account.</p>
               </div>

               <div class="w-75 m-3 password-suggesstion-info">
                  <button mat-raised-button color="accent" routerLink="/change-password">Update Password</button>
               </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
