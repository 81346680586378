import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CommonService, CountryCode } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import Swal from 'sweetalert2';
import { RegEx } from 'src/app/config/reg-ex';

@Component({
  selector: 'app-influencer-signup',
  templateUrl: './influencer-signup.component.html',
  styleUrls: ['./influencer-signup.component.scss']
})
export class InfluencerSignupComponent implements OnInit {
  countryList: any[];
  myControl = new FormControl('+91');
  toggleEye: boolean = true;  // toggle eye for password field
  toggleEyeC: boolean = true;  // toggle eye for confirm password
  filteredOptions: Observable<CountryCode[]>;
  signinForm: FormGroup;
  frontImageUrl: any;
  backImageUrl: any;
  stateList: any;
  isFormSubmitted: boolean = false;
  passType: boolean = true;
  passType1: boolean = true;
  constructor(private route: Router, public commonService: CommonService) { }

  ngOnInit(): void {
    this.formValidation()
    // this.getStateList()
    this.getCoutryList()
  }


  formValidation() {
    this.signinForm = new FormGroup({
      // fullName: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z]*"), Validators.minLength(2)]),
      // lastName: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]*"), Validators.minLength(2)]),
      fullName: new FormControl('', [Validators.required, Validators.pattern(RegEx.firstName)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(RegEx.lastName)]),
      // panCard: new FormControl('', [Validators.required]),
      // location: new FormControl('', [Validators.required]),
      address1: new FormControl('', [Validators.required, Validators.pattern(RegEx.address)]),
      address2: new FormControl('', [Validators.pattern(RegEx.address)]),
      govtDocsFront: new FormControl('', [Validators.required]),
      govtDocsBack: new FormControl('', [Validators.required]),
      zipcode: new FormControl('', [Validators.required, Validators.pattern(RegEx.internationalZipCode)]),
      facebook: new FormControl('', [Validators.required, Validators.pattern(RegEx.url)]),
      twitter: new FormControl('', [Validators.required, Validators.pattern(RegEx.url)]),
      instagram: new FormControl('', [Validators.required, Validators.pattern(RegEx.url)]),
      linkedIn: new FormControl('', [Validators.required, Validators.pattern(RegEx.url)]),
      emailId: new FormControl("", [Validators.required, , Validators.pattern(RegEx.email)]),
      // emailId: new FormControl("", [Validators.required]),

      mobileNumber: new FormControl('', [Validators.required, Validators.pattern(RegEx.phoneNo)]),
      // mobileNumber: new FormControl('', [Validators.required]),

      country: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      city: new FormControl('', [Validators.pattern(RegEx.city)]),
      checkTerms: new FormControl('', [Validators.requiredTrue]),
      password: new FormControl('', [Validators.required, Validators.pattern(RegEx.pswd)]),
      // password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    });
    this.countryList = this.commonService.countryListJson;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (value ? this._filter(value) : this.countryList.slice())),
    );
  }

  private _filter(value: string): CountryCode[] {
    const filterValue = value.toLowerCase();
    return this.countryList.filter(option => option.code.toLowerCase().includes(filterValue));
  }

  navigateToLogin() {
    this.route.navigate(['/influencer-login'])
  }

  // influencer signup 
  influencerSignUpApi() {
    console.log(this.signinForm.value);

    if (this.signinForm.invalid) {
      return this.isFormSubmitted = true
    }
    let apiReqUrl = ApiEndPoint.signup
    let apiReqData = {
      'userType': 'INFLUENCER',
      'firstName': this.signinForm.value.fullName,
      'lastName': this.signinForm.value.lastName,
      'email': (this.signinForm.value.emailId).toLowerCase(),
      'country': this.signinForm.value.country,
      'state': this.signinForm.value.state,
      'city': this.signinForm.value.city,
      'countryCode': this.myControl.value,
      'addressLine1': this.signinForm.value.address1,
      'postalCode': this.signinForm.value.zipcode,
      'addressLine2': this.signinForm.value.address2 ? this.signinForm.value.address2 : '',
      'mobileNumber': this.signinForm.value.mobileNumber,
      "socialLinks": {
        "facebook": this.signinForm.value.facebook,
        "instagram": this.signinForm.value.instagram,
        "twitter": this.signinForm.value.twitter,
        "linkedIn": this.signinForm.value.linkedIn,
      },
      'password': this.signinForm.value.password,
      "docImage": {
        "frontImage": this.frontImageUrl,
        "backImage": this.backImageUrl
      },

      // 'govDoc':this.signinForm.value.govtDocs
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        // this.commonService.successToast(res.responseMessage)
        this.commonService.alertSuccess(res.responseMessage)
        this.route.navigate(['/influencer-login'])
        // this.route.navigate(['/otp-verification-influencer'],{queryParams:{mobileNumber:this.signinForm.value.mobileNumber}})
      } else {
        this.commonService.hideSpinner()
        this.commonService.alertError(res.responseMessage)
      }
    }, (err) => {
      this.commonService.hideSpinner()
      this.commonService.alertError(err.responseMessage)
    })
  }


  uploadImg(event, imageOf): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img, imageOf);
  }
  uploadImageFunc(img, imageOf) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('product/uploadFile', fb, 0).subscribe((res: any) => {
      console.log(res);
      console.log(imageOf);

      if (res.responseCode == 200) {
        // this.imageUrl = res['result']
        // upload image both front and back and assign respective property based on condition 'FRONT' or 'BACK'
        if (imageOf == 'FRONT') {
          console.log(imageOf);

          this.frontImageUrl = res['result']
        } else {
          this.backImageUrl = res['result']
        }
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }

  /**get coutry list api*/
  getCoutryList() {
    let apiReqUrl = "product/countryList"
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.countryList = res.result
        this.commonService.hideSpinner()
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);

      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }


  /** get state list api */
  getStateList(countryCode) {
    let apiReqUrl = `product/stateList?countryCode=${countryCode}`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.stateList = res.result ? res.result : []
        this.commonService.hideSpinner()

      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);

      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }
}

