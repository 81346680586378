import { BrowserModule } from '@angular/platform-browser';
// import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { NgOtpInputModule } from 'ng-otp-input';
import { StoreLocatorDialogComponent } from './modals/store-locator-dialog/store-locator-dialog.component';
// import { ErrorInterceptor } from './interceptor/error.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { GoogleMapsModule } from '@angular/google-maps';
// import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxImageZoomModule } from 'ngx-image-zoom'; //image-zoom
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

// components
import { MyShoppingBagComponent } from './pages/customer/account/my-shopping-bag/my-shopping-bag.component';
import { MyProfileComponent } from './pages/customer/profile-management/my-profile/my-profile.component';
import { SigninComponent } from './pages/customer/auth/signin/signin.component';
import { SignupComponent } from './pages/customer/auth/signup/signup.component';
import { ForgotPasswordComponent } from './pages/customer/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/customer/auth/reset-password/reset-password.component';
import { OtpVerificationComponent } from './pages/customer/auth/otp-verification/otp-verification.component';
import { PrivacyPolicyComponent } from './pages/customer/static-content/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './pages/customer/static-content/terms-condition/terms-condition.component';
import { CookiePreferenceComponent } from './pages/customer/static-content/cookie-preference/cookie-preference.component';
import { FooterComponent } from './pages/customer/sharing/footer/footer.component';
import { AddBlogDialogComponent } from './pages/customer/motee-collection-management/customize/add-blog-dialog/add-blog-dialog.component';
import { CollectionListComponent } from './pages/customer/mother-of-bride-collection-management/collection-list/collection-list.component';
import { SwatchesCollectionListComponent } from './pages/customer/swatches-collection-management/swatches-collection-list/swatches-collection-list.component';
import { ViewMothersDressComponent } from './pages/customer/mother-of-bride-collection-management/view-mothers-dress/view-mothers-dress.component';
import { MyAddressComponent } from './pages/customer/profile-management/my-address/my-address.component';
import { MyOrdersComponent } from './pages/customer/profile-management/my-orders/my-orders.component';
import { HeaderComponent } from './pages/customer/sharing/header/header.component';
import { AddNewAddressComponent } from './pages/customer/account/add-new-address/add-new-address.component';
// import { EditProfileComponent } from './pages/customer/profile-management/edit-profile/edit-profile.component';
import { HomepageComponent } from './pages/customer/homepage/homepage.component';
import { ViewSwatchesComponent } from './pages/customer/swatches-collection-management/view-swatches/view-swatches.component';
import { ViewBridesmaidDressComponent } from './pages/customer/bridesmaid-collection-management/view-bridesmaid-dress/view-bridesmaid-dress.component';
import { BridesmaidCollectionListComponent } from './pages/customer/bridesmaid-collection-management/bridesmaid-collection-list/bridesmaid-collection-list.component';
import { BridalCollectionListComponent } from './pages/customer/bridal-collection-management/bridal-collection-list/bridal-collection-list.component';
import { ViewBridalDressComponent } from './pages/customer/bridal-collection-management/view-bridal-dress/view-bridal-dress.component';
import { CatelogueCollectionListComponent } from './pages/customer/catelogue-collection-management/catelogue-collection-list/catelogue-collection-list.component';
import { OrderStatusComponent } from './pages/customer/profile-management/order-status/order-status.component';
import { CreateChatRoomComponent } from './pages/customer/chat-room/create-chat-room/create-chat-room.component';
import { InviteChattingComponent } from './pages/customer/chat-room/invite-chatting/invite-chatting.component';
import { CreateYourDressComponent } from './pages/customer/motee-collection-management/customize/create-your-dress/create-your-dress.component';
import { DressCollectionListComponent } from './pages/customer/motee-collection-management/dress-collection-list/dress-collection-list.component';
import { MyWishlistComponent } from './pages/customer/profile-management/my-wishlist/my-wishlist.component';
import { StoreLocatorComponent } from './pages/customer/store-locator/store-locator.component';
import { MoteeCollectionListComponent } from './pages/customer/motee-collection-management/motee-collection-list/motee-collection-list.component';
import { CreateDressPreviewComponent } from './pages/customer/motee-collection-management/customize/create-dress-preview/create-dress-preview.component';
import { CheckoutSelectAddressComponent } from './pages/customer/account/checkout-select-address/checkout-select-address.component';
import { CheckoutSavedAddressComponent } from './pages/customer/account/checkout-saved-address/checkout-saved-address.component';
import { WelcomeComponent } from './pages/retailer/homepage/welcome/welcome.component';
import { GetStartedComponent } from './pages/retailer/homepage/get-started/get-started.component';
import { OrderSampleSetComponent } from './pages/retailer/homepage/order-sample-set/order-sample-set.component';
import { LandingPageComponent } from './pages/retailer/homepage/landing-page/landing-page.component';
import { AllOrdersComponent } from './pages/retailer/homepage/all-orders/all-orders.component';
import { CheckoutSelectStoreAddressComponent } from './pages/retailer/homepage/checkout-select-store-address/checkout-select-store-address.component';
import { RegistrationComponent } from './pages/retailer/guest/registration/registration.component';
import { LoginComponent } from './pages/retailer/auth/login/login.component';
import { ForgotPasswordRetailerComponent } from './pages/retailer/auth/forgot-password-retailer/forgot-password-retailer.component';
import { ResetPasswordRetailerComponent } from './pages/retailer/auth/reset-password-retailer/reset-password-retailer.component';
import { OtpVerificationRetailerComponent } from './pages/retailer/auth/otp-verification-retailer/otp-verification-retailer.component';
import { RetailerSignupComponent } from './pages/retailer/auth/retailer-signup/retailer-signup.component';
import { MyAccountComponent } from './pages/retailer/account/my-account/my-account.component';
import { RetailerHeaderComponent } from './pages/retailer/sharing-retailer/retailer-header/retailer-header.component';
import { EditMyAddressComponent } from './pages/retailer/account/edit-my-address/edit-my-address.component';
import { ChangePasswordComponent } from './pages/retailer/account/change-password/change-password.component';
import { CatelogueComponent } from './pages/retailer/guest/catelogue/catelogue.component';
import { InfluencerSignupComponent } from './pages/influencer/auth/influencer-signup/influencer-signup.component';
import { InfluencerLoginComponent } from './pages/influencer/auth/influencer-login/influencer-login.component';
import { CouponListComponent } from './pages/influencer/coupon-management/coupon-list/coupon-list.component';
import { VisitorListComponent } from './pages/retailer/visitor-management/visitor-list/visitor-list.component';
import { ViewVisitorComponent } from './pages/retailer/visitor-management/view-visitor/view-visitor.component';
import { ProductListComponent } from './pages/retailer/product-management/product-list/product-list.component';
import { ViewProductComponent } from './pages/retailer/product-management/view-product/view-product.component';
import { PressComponent } from './pages/customer/stories/press/press.component';
import { BridalPartyComponent } from './pages/customer/bridal-collection-management/bridal-party/bridal-party.component';
import { RealBridesComponent } from './pages/customer/stories/real-brides/real-brides.component';
import { ForgotPasswordInfluencerComponent } from './pages/influencer/auth/forgot-password-influencer/forgot-password-influencer.component';
import { CollectionBridalPartyComponent } from './pages/customer/bridal-collection-management/collection-bridal-party/collection-bridal-party.component';
import { EventDressesComponent } from './pages/customer/bridal-collection-management/event-dresses/event-dresses.component';
import { FeaturesComponent } from './pages/customer/bridal-collection-management/features/features.component';
import { ReadyToShipBridalComponent } from './pages/customer/bridal-collection-management/ready-to-ship-bridal/ready-to-ship-bridal.component';
import { TrunkComponent } from './pages/customer/trunk-shows/trunk/trunk.component';
import { TradeComponent } from './pages/customer/trunk-shows/trade/trade.component';
import { VisitNycSalonComponent } from './pages/customer/book-an-appointment/visit-nyc-salon/visit-nyc-salon.component';
import { FaqComponent } from './pages/customer/book-an-appointment/faq/faq.component';
import { ChatRoomListComponent } from './pages/customer/chat-room/chat-room-list/chat-room-list.component';
import { SelectDateComponent } from './pages/customer/book-an-appointment/select-date/select-date.component';
import { MaskListComponent } from './pages/customer/swatches-collection-management/masks/mask-list/mask-list.component';
import { ViewMaskComponent } from './pages/customer/swatches-collection-management/masks/view-mask/view-mask.component';
import { NouvelleSwatchesListComponent } from './pages/customer/swatches-collection-management/nouvelle-swatches-management/nouvelle-swatches-list/nouvelle-swatches-list.component';
import { ViewNouvelleSwatchesComponent } from './pages/customer/swatches-collection-management/nouvelle-swatches-management/view-nouvelle-swatches/view-nouvelle-swatches.component';
import { ClientInformationComponent } from './pages/customer/book-an-appointment/client-information/client-information.component';
import { EventDetailComponent } from './pages/customer/book-an-appointment/event-detail/event-detail.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ConfirmPaymentComponent } from './pages/customer/book-an-appointment/confirm-payment/confirm-payment.component';
import { SelectDressModalComponent } from './pages/customer/chat-room/select-dress-modal/select-dress-modal.component';
import { ContactUSComponent } from './pages/customer/static-content/contact-us/contact-us.component';
import { JoinOurTeamComponent } from './pages/customer/static-content/join-our-team/join-our-team.component';
import { PartnerProgramComponent } from './pages/customer/static-content/partner-program/partner-program.component';
import { ViewRealBrideComponent } from './pages/customer/stories/view-real-bride/view-real-bride.component';
import { ViewBlogComponent } from './pages/customer/stories/view-blog/view-blog.component';
import { ViewPressComponent } from './pages/customer/stories/view-press/view-press.component';
import { MoteeMaidsBridalComponent } from './pages/customer/bridal-collection-management/motee-maids-bridal/motee-maids-bridal.component';
import { LatestMoteeCollectionComponent } from './pages/customer/motee-collection-management/latest-motee-collection/latest-motee-collection.component';
import { EveningCollectionComponent } from './pages/customer/motee-collection-management/evening-collection/evening-collection.component';
import { ViewBridalPartyDressComponent } from './pages/customer/bridal-collection-management/view-bridal-party-dress/view-bridal-party-dress.component';
import { SearchComponent } from './pages/customer/sharing/search/search.component';
import { ProfileManagementComponent } from './pages/customer/profile-management/profile-management.component';
import { MyEventComponent } from './pages/customer/profile-management/my-event/my-event.component';
import { MyAppoinmentComponent } from './pages/customer/profile-management/my-appoinment/my-appoinment.component';
import { MyMeasurementComponent } from './pages/customer/profile-management/my-measurement/my-measurement.component';
import { CheckoutMultipleAddressComponent } from './pages/customer/account/checkout-multiple-address/checkout-multiple-address.component';
import { ProductEventDetailComponent } from './pages/customer/book-an-appointment/product-event-detail/product-event-detail.component';
import { PaymentComponent } from './pages/customer/account/payment/payment.component';
import { MultiAddressPaymentComponent } from './pages/customer/account/multi-address-payment/multi-address-payment.component';
import { OtpVarificationInfluencerComponent } from './pages/influencer/auth/otp-varification-influencer/otp-varification-influencer.component';
import { ResetPasswordInfluecerComponent } from './pages/influencer/reset-password-influecer/reset-password-influecer.component';
import { HttpErrorInterceptor } from './interceptor/http-error.interceptor';
import { HttpResInterceptor } from './interceptor/http-res.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlogComponent } from './pages/customer/stories/blog/blog.component';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { IndividualPaymentComponent } from './pages/customer/account/individual-payment/individual-payment.component';
import { environment } from 'src/environments/environment';
import { IndividualCheckoutComponent } from './pages/customer/account/individual-checkout/individual-checkout.component';
import { IndividualPayComponent } from './pages/customer/account/individual-pay/individual-pay.component';
import { CurationListComponent } from './pages/customer/chat-room/curation-list/curation-list.component';
// import { CurationListComponent } from './pages/customer/curation-list/curation-list.component';

const config: SocketIoConfig = {
  // url: 'https://node-moteemaids.mobiloitte.com',
  url: environment.chatSocketUrl,
  //  url: 'http://172.16.1.250:3031'
  options: {},
};

// import { ShareButtonsConfig } from 'ngx-sharebuttons';
// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
// import { ShareButtonsModule } from 'ngx-sharebuttons';

// const customConfig: ShareButtonsConfig = {
//   include: ['facebook', 'twitter', 'google'],
//   exclude: ['tumblr', 'stumble', 'vk'],
//   theme: 'modern-light',
//   gaTracking: true,
//   twitterAccount: 'twitterUsername'
// }
// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
// import { ShareIconsModule } from 'ngx-sharebuttons/icons';

@NgModule({
  declarations: [
    AppComponent,
    StoreLocatorDialogComponent,
    MyShoppingBagComponent,
    MyProfileComponent,
    SigninComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    OtpVerificationComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    CookiePreferenceComponent,
    FooterComponent,
    AddBlogDialogComponent,
    CollectionListComponent,
    SwatchesCollectionListComponent,
    ViewMothersDressComponent,
    MyAddressComponent,
    MyAddressComponent,
    MyOrdersComponent,
    HeaderComponent,
    AddNewAddressComponent,
    // EditProfileComponent,
    HomepageComponent,
    ViewSwatchesComponent,
    ViewBridesmaidDressComponent,
    BridesmaidCollectionListComponent,
    BridalCollectionListComponent,
    ViewBridalDressComponent,
    CatelogueCollectionListComponent,
    OrderStatusComponent,
    CreateChatRoomComponent,
    InviteChattingComponent,
    CreateYourDressComponent,
    DressCollectionListComponent,
    MyWishlistComponent,
    StoreLocatorComponent,
    MoteeCollectionListComponent,
    CreateDressPreviewComponent,
    CheckoutSelectAddressComponent,
    CheckoutSavedAddressComponent,
    WelcomeComponent,
    GetStartedComponent,
    OrderSampleSetComponent,
    LandingPageComponent,
    AllOrdersComponent,
    CheckoutSelectStoreAddressComponent,
    RegistrationComponent,
    LoginComponent,
    ForgotPasswordRetailerComponent,
    ResetPasswordRetailerComponent,
    OtpVerificationRetailerComponent,
    RetailerSignupComponent,
    MyAccountComponent,
    RetailerHeaderComponent,
    EditMyAddressComponent,
    ChangePasswordComponent,
    CatelogueComponent,
    InfluencerSignupComponent,
    InfluencerLoginComponent,
    CouponListComponent,
    VisitorListComponent,
    ViewVisitorComponent,
    ProductListComponent,
    ViewProductComponent,
    PressComponent,
    BridalPartyComponent,
    RealBridesComponent,
    ForgotPasswordInfluencerComponent,
    CollectionBridalPartyComponent,
    EventDressesComponent,
    FeaturesComponent,
    ReadyToShipBridalComponent,
    RealBridesComponent,
    TrunkComponent,
    TradeComponent,
    VisitNycSalonComponent,
    FaqComponent,
    ChatRoomListComponent,
    SelectDateComponent,
    MaskListComponent,
    ViewMaskComponent,
    NouvelleSwatchesListComponent,
    ViewNouvelleSwatchesComponent,
    ClientInformationComponent,
    EventDetailComponent,
    ConfirmPaymentComponent,
    SelectDressModalComponent,
    ContactUSComponent,
    JoinOurTeamComponent,
    PartnerProgramComponent,
    ViewRealBrideComponent,
    ViewBlogComponent,
    ViewPressComponent,
    MoteeMaidsBridalComponent,
    LatestMoteeCollectionComponent,
    EveningCollectionComponent,
    ViewBridalPartyDressComponent,
    SearchComponent,
    ProfileManagementComponent,
    MyEventComponent,
    MyAppoinmentComponent,
    MyMeasurementComponent,
    CheckoutMultipleAddressComponent,
    ProductEventDetailComponent,
    PaymentComponent,
    MultiAddressPaymentComponent,
    OtpVarificationInfluencerComponent,
    ResetPasswordInfluecerComponent,
    BlogComponent,
    ShortNumberPipe,
    IndividualPaymentComponent,
    IndividualCheckoutComponent,
    IndividualPayComponent,
    CurationListComponent,
    // CurationListComponent,
  ],
  imports: [
    BrowserModule,
    // CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgOtpInputModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    GoogleMapsModule,
    GooglePlaceModule,
    NgxPaginationModule,
    NgxImageZoomModule, //image zoom
    SocketIoModule.forRoot(config),
    SimpleSmoothScrollModule, //smooth scroll on view mother dress on click
    // the module you choice 'ShareModule', 'ShareButtonModule' or 'ShareButtonsModule'
    // ShareButtonsModule.withConfig(customConfig),
    // ShareButtonsModule
    // ShareIconsModule // Optional if you want the default share
    // ShareButtonsModule.withConfig({
    //   debug: true
    // }),
    // ShareIconsModule,
    NgxSkeletonLoaderModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResInterceptor, multi: true },
    MatDatepickerModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
