<div class="wrapper">
    <div class="staticHeading text-center">
        <h1 class="headingTitle new-style">{{termsConditionsData?.title}}</h1>
    </div>
    <div class="termscontent">
        <div class="privacyDivs">
            <!-- <h3 class="header-text">Terms & Conditions</h3> -->
            <p class="header-text">
                <!-- These Terms & Conditions reflect the way Google’s business works, the laws that apply to our company, and certain things we’ve always believed to be true. As a result, these Terms of Service help define Google’s relationship with you as you interact with our services. For example, these terms include the following topic headings:

                What you can expect from us, which describes how we provide and develop our services
                What we expect from you, which establishes certain rules for using our services
                Content in Google services, which describes the intellectual property rights to the content you find in our services — whether that content belongs to you, Google, or others
                In case of problems or disagreements, which describes other legal rights you have, and what to expect in case someone violates these terms -->
                {{termsConditionsData?.description}}
            </p>
        </div>

    </div>
</div>