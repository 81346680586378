<!-- start toolbar -->
<!-- <mat-toolbar class="toolbar_custom">
    <i class="fa fa-angle-double-left mr-2"></i> <a routerLink="/mothers-collection-list" class="text-blue">Back to results</a>
 </mat-toolbar> -->
 <!-- end toolbar -->
 <div class="wrraper">
  <div class="container d-flex justify-content-between mb-5">
    <mat-grid-list cols="1" rowHeight="1:1" class="w-15 h-50 p-0">
       <mat-grid-tile class="box_custom  wow bounceInLeft mb-2">
          <img src="./assets/images/other/grid2.png" alt="" class="w-100 h-100">
       </mat-grid-tile>
       <mat-grid-tile class="box_custom  wow bounceInLeft">
          <img src="./assets/images/other/grid4.png" alt="" class="w-100 h-100">
       </mat-grid-tile>
       <mat-grid-tile class="box_custom  wow bounceInLeft">
          <img src="./assets/images/other/grid6.png" alt="" class="w-100 h-100">
       </mat-grid-tile>
       <mat-grid-tile class="box_custom  wow bounceInLeft">
          <img src="./assets/images/other/grid8.png" alt="" class="w-100 h-100">
       </mat-grid-tile>
     </mat-grid-list>
     <mat-card class="example-card">
       <!-- <mat-card-header>
         <div mat-card-avatar class="example-header-image"></div>
         <mat-card-title>Shiba Inu</mat-card-title>
         <mat-card-subtitle>Dog Breed</mat-card-subtitle>
       </mat-card-header> -->
       <div class="example-card_body p-2">
          <div class="w-35">
            <div class=" fa_heart">
             <i class="fa fa-heart text-light border-1 mb-1"></i>
             <i class="fa fa-search-plus text-light border-1"></i>
            </div>
             <img mat-card-image src="./assets/images/grid6.png" alt="img" class="m-0 w-100 wow bounceInDown">
             
             <mat-card-actions class="action_custom">
                <button mat-raised-button color="accent" class="w-100 m-3" data-toggle="modal" data-target="#staticBackdrop"><i class="fa fa-heart text-light mr-2"></i>Save To Favourites</button>
              </mat-card-actions>
          </div>
       <mat-card-content class="ml-1 pl-2 pt-5 w-60">
          <h1 class="m-0">LOREM IPSUM</h1>
         <h4 class="text_custom m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          WG4016</h4>
          <h2 class="m-0">$2549.00 **</h2>
          <div>
             <i class="fa fa-star m-2"></i>
             <i class="fa fa-star m-2"></i>
             <i class="fa fa-star m-2"></i>
             <i class="fa fa-star m-2"></i>
             <i class="fa fa-star m-2"></i>
          </div>
          <p><span>- Be the first to</span><a class="text-dark ml-2 text-decoration-underline">write a review</a></p>
          <div class="second_list_data">
           <mat-expansion-panel (opened)="panelOpenState = true"
                                (closed)="panelOpenState = false">
             <mat-expansion-panel-header>
               <mat-panel-title>
                 Size
               </mat-panel-title>
               <!-- <mat-panel-description>
                 Currently I am {{panelOpenState ? 'open' : 'closed'}}
               </mat-panel-description> -->
             </mat-expansion-panel-header>
             <!-- <p>I'm visible because I am open</p> -->
             <div class="size_select_value">
               <select class="form-control form-control-sm">
                 <label for="">Body Size</label>
                 <option disabled>Select Size</option>
                 <option>0</option>
                 <option>2</option>
                 <option>4</option>
                 <option>6</option>
                 <option>8</option>
                 <option>10</option>
                 <option>12</option>
                 <option>14</option>
                 <option>16</option>
                 <option>18</option>
                 <option>20</option>
                 <option>22</option>
                 <option>24</option>
               </select>
               <select class="form-control form-control-sm second_select_val">Body Size
                 <label for="">Skirt Size</label>
                 <option disabled>Select Size</option>
                 <option>0</option>
                 <option>2</option>
                 <option>4</option>
                 <option>6</option>
                 <option>8</option>
                 <option>10</option>
                 <option>12</option>
                 <option>14</option>
                 <option>16</option>
                 <option>18</option>
                 <option>20</option>
                 <option>22</option>
                 <option>24</option>
               </select>
               <select class="form-control form-control-sm second_select_val">Body Size
                 <label for="">Skirt Length</label>
                 <option >Select Length</option>
                 <option>Standard</option>
                 <option>Extra Length</option>
                 
               </select>
             </div>
           </mat-expansion-panel>
         </div>
       <div class="fourth_clas">
         <!-- <mat-expansion-panel hideToggle> -->
           <mat-expansion-panel (opened)="panelOpenState = true"
                              (closed)="panelOpenState = false">
           <mat-expansion-panel-header>
             <mat-panel-title>
               Colour
                     </mat-panel-title>
             <!-- <mat-panel-description>
               This is a summary of the content
             </mat-panel-description> -->
           </mat-expansion-panel-header>
           <owl-carousel-o [options]="newOptions">
             <ng-template carouselSlide>
               
               <input type="tel" disabled class="colour_list_details" style="background-color: silver;" >
               <p class="text_images_paras">Silver</p>
             </ng-template>  
             <ng-template carouselSlide>
               
               <input type="tel" disabled class="colour_list_details" style="background-color: midnightblue;">
       
               <p class="text_images_paras">Midnightblue</p>
         
             </ng-template>  
             <ng-template carouselSlide>
               <input type="tel" disabled class="colour_list_details" style="background-color: blueviolet;">
       
               <p class="text_images_paras">Blueviolet</p>
         
             </ng-template>  
             <ng-template carouselSlide>
                       <input type="tel" disabled class="colour_list_details" style="background-color: red;">
       
               <p class="text_images_paras">red</p>
         
             </ng-template>  
             <ng-template carouselSlide>
                       <input type="tel" disabled class="colour_list_details" style="background-color: seagreen;">
       
               <p class="text_images_paras">Seagreen</p>
         
             </ng-template> 
             
           </owl-carousel-o>
           <owl-carousel-o [options]="newOptions">
             <ng-template carouselSlide>
                       <input type="tel" disabled class="colour_list_details" style="background-color: skyblue;">
       
               <p class="text_images_paras">Skyblue</p>
             </ng-template>  
             <ng-template carouselSlide>
                       <input type="tel" disabled class="colour_list_details" style="background-color: steelblue;">
       
               <p class="text_images_paras">Steelblue</p>
         
             </ng-template>  
             <ng-template carouselSlide>
                       <input type="tel" disabled class="colour_list_details" style="background-color: darkorange;">
       
               <p class="text_images_paras">Darkorange</p>
         
             </ng-template>  
             <ng-template carouselSlide>
                       <input type="tel" disabled class="colour_list_details" style="background-color: rosybrown;">
       
               <p class="text_images_paras">Rosybrown</p>
         
             </ng-template>  
             <ng-template carouselSlide>
                       <input type="tel" disabled class="colour_list_details" style="background-color: indianred;">
       
               <p class="text_images_paras">Indianred</p>
         
             </ng-template> 
             
           </owl-carousel-o>
           <owl-carousel-o [options]="newOptions">
             <ng-template carouselSlide>
                       <input type="tel" disabled class="colour_list_details new_down_detail" style="background-color: gainsboro;">
       
               <p class="text_images_paras">Gainsboro</p>
             </ng-template>  
            
             
           </owl-carousel-o>
           <!-- <p>This is the primary content of the panel.</p> -->
         </mat-expansion-panel>
       </div>
       
          <div>
             <mat-label class="mr-2">Quantity</mat-label>
             <mat-form-field appearance="outline" class="w-50 h-75 mat_select_custom">
                 <mat-select name="" id=""  [(value)]="value">
                     <mat-option value="Select color" selected>1</mat-option>
                 </mat-select>
             </mat-form-field>
             <mat-card class="mb-3">
                <div>
                   <mat-card-title class="title-custom">Estimated delivery date: 10/07/2021 - 10/08/2021: </mat-card-title>
                   <mat-card-subtitle>You have selected an item that needs to be special ordered which means the delivery
                      date is longer than usual and your method of payment will be charged upon order placement. 
                      Please see our payment policy for more details.</mat-card-subtitle>
                </div>
             </mat-card>
             <h2>Your selections cost: $159.95</h2>
             <mat-divider class="position-relative"></mat-divider>
          </div>
          <mat-card-actions class="action_custom">
            <button  mat-raised-button color="primary" class="mb-2 w-75" data-toggle="modal" data-target="#staticBackdrop">Add to Bag</button>
            <button  mat-raised-button color="warn" class="m-1 w-75"  routerLink="/store-locator">Visit to Store Nearby</button>
          </mat-card-actions>
       </mat-card-content>
    </div>
     </mat-card>       
 </div>

    <mat-card class="mt-2 mb-2">
      <mat-card-title>Related Products</mat-card-title>
    <!-- <mat-title>Related Products</mat-title> -->
      <owl-carousel-o [options]="embelishmentOption">
        <ng-template carouselSlide>
          <img class="four_img_list wow bounceInDown" src="/assets/images/other/grid6.png" alt="">
          <div class="row justify-content-center text-center card-content_custom">
            <h4 class="m-0">LOREM</h4>
            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div>
                <p class="m-3">WG4016</p>
                <p class="m-0">$1599.00 - $2549.00</p>
                <p class="m-1">8 Color</p>
            </div>
        </div>
        </ng-template>  
        <ng-template carouselSlide>
          <img class="four_img_list wow bounceInDown" src="/assets/images/other/grid5.png" alt="">
          <div class="row justify-content-center text-center card-content_custom">
            <h4 class="m-0">LOREM</h4>
            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div>
                <p class="m-3">WG4016</p>
                <p class="m-0">$1599.00 - $2549.00</p>
                <p class="m-1">8 Color</p>
            </div>
        </div>
    
        </ng-template>  
        <ng-template carouselSlide>
          <img class="four_img_list wow bounceInDown" src="/assets/images/other/grid4.png" alt="">
          <div class="row justify-content-center text-center card-content_custom">
            <h4 class="m-0">LOREM</h4>
            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div>
                <p class="m-3">WG4016</p>
                <p class="m-0">$1599.00 - $2549.00</p>
                <p class="m-1">8 Color</p>
            </div>
        </div>
    
        </ng-template>  
        <ng-template carouselSlide>
          <img class="four_img_list wow bounceInDown" src="/assets/images/other/grid2.png" alt="">
          <div class="row justify-content-center text-center card-content_custom">
            <h4 class="m-0">LOREM</h4>
            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div>
                <p class="m-3">WG4016</p>
                <p class="m-0">$1599.00 - $2549.00</p>
                <p class="m-1">8 Color</p>
            </div>
        </div>
    
        </ng-template>  
        <ng-template carouselSlide>
          <img class="four_img_list wow bounceInDown" src="/assets/images/other/signin.jpg" alt="">
          <div class="row justify-content-center text-center card-content_custom">
            <h4 class="m-0">LOREM</h4>
            <p class="m-0">Brief Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div>
                <p class="m-3">WG4016</p>
                <p class="m-0">$1599.00 - $2549.00</p>
                <p class="m-1">8 Color</p>
            </div>
        </div>
    
        </ng-template> 
        
      </owl-carousel-o>
    </mat-card>



 </div>
 
 <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h2 class="modal-title" id="staticBackdropLabel">The item has been successfully added:</h2>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
          <div>
              <i class="fa fa-times fa_times_custom bg-dark text-light"data-dismiss="modal" aria-label="Close"></i>
          </div>
        </div>
        <div class="modal-body p-1">
            <div class="d-flex justify-content-between">
                <div class="w-50">
                    <!-- <div class=""> -->
                        <img src="/assets/images/other/grid4.png" alt="" class="w-100 h-100">
                    <!-- </div> -->
                </div>
                <div class="w-50 p-2">
                    <div>
                        <h4 class="mb-1">Lorem ipsum dolor sit amet consectetur adipiscing elit Custom Dress</h4>
                        <h4 class="m-0">$5559.95</h4>
                        <div class="">
                            <div class="d-flex">
                                <label for="" class="mb-1">color</label><span>:</span><p class="mb-1">Mink</p>
                            </div>
                            <div class="d-flex">
                                <label for="" class="mb-1">size</label><span>:</span><p class="mb-1">12</p>
                            </div>
                            <div class="d-flex">
                                <label for="" class="mb-1">QTY</label><span>:</span><p class="mb-1">1</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 justify-content-around">
          <button mat-raised-button color="primary" class="col-md-6" data-dismiss="modal">Continue Shopping</button>
          <button mat-raised-button color="warn" class="col-md-4" data-dismiss="modal">Checkout</button>
        </div>
      </div>
    </div>
  </div>