<div class="wrapper">
  <!-- <mat-toolbar class="toolbar_custom header-text">
    Home/My Account
  </mat-toolbar> -->
  <mat-sidenav-container>
    <mat-sidenav mode="side" opened>
      <button mat-button class="side_menu_button header-text" target="_blank" routerLink="/my-profile"><p>My Account</p></button>
      <button mat-button class="side_menu_button header-text" target="_blank" routerLink="/addresses"><p>Addresses</p></button>
      <button mat-button class="side_menu_button header-text" target="_blank" routerLink="/my-order"><p>My Orders</p></button>
      <button mat-button class="side_menu_button header-text" target="_blank" routerLink="/order-status"><p>Order Status</p></button>
      <button mat-button class="side_menu_button header-text" target="_blank" routerLink="/my-wishlist"><p>My Wishlists</p></button>
    </mat-sidenav>
    <mat-sidenav-content>
      
      <!-- progress bar started to track order -->

      <div class="order-details">
        <span class="header-text">Order Status</span>
    </div>  
      <div class="">
        <mat-horizontal-stepper linear #stepper labelPosition="bottom" class="horizontal_custom pl-3 pr-3">
          <!-- <label for="">Placed </label> -->
          <mat-step stepControl class="mat_step_custom" label="Placed">
          </mat-step>
        
          <mat-step stepControl class="mat_step_custom" label="Confirmed">
          </mat-step>
  
          <mat-step stepControl class="mat_step_custom"  label="Shipped">
          </mat-step>
  
        </mat-horizontal-stepper>
  
        <mat-vertical-stepper style="width: 200px;" vertical #stepper labelPosition="top" class="horizontal_custom pl-2">
          <!-- <label for="">Placed </label> -->
          <mat-step stepControl class="mat_step_custom" label="Order Accepted">
          </mat-step>
        
          <mat-step stepControl class="mat_step_custom" label="Dispatched" >
          </mat-step>
  
          <mat-step stepControl class="mat_step_custom"  label="Lorem">
          </mat-step>
  
        </mat-vertical-stepper>

      </div>
      
      <!-- progress bar end  -->

      <div class="w-90 m-auto">
      <table class="cart">
        <thead>
            <tr>
                <th class="header-text" colspan="2">Product</th>
                <th class="cart__row-price header-text" scope="col">price</th>
                <th class="text-center small--hide header-text" scope="col">Quantity</th>
                <th class="cart__row-price header-text text-right" scope="col">Total</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="cart__image-wrapper">
                    <div>
                        <img src="/assets/images/other/crsl.jpg" class="cart__image" alt="">
                    </div>
                </td>
                <td class="cart__meta small--text-left">
                    <div class="list-view-item__title">
                        <a class="header-text">Top V-neck</a>
                    </div>
                    <div class="cart__meta-text header-text">
                        Body Size : 12
                        <br>
                        Skirt Size : 8
                        <br>
                        Skirt Length : Standard
                    </div>
                    <p class="small--hide">
                        <a class="btn btn--small btn--tertiary cart__remove header-text">Cancel</a>
                    </p>
                </td>
                <td class="cart__price-wrapper header-text">
                    $ 5400.00
                </td>
                <td class="text-right small--hide">
                    <div class="cart__qty">
                      <p class="header-text">1</p>
                    </div>
                </td>
                <td class="text-right small--hide">
                    <div class="header-text">
                        $ 6000.00
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
      </div>
      </mat-sidenav-content>
  </mat-sidenav-container>
</div>
  