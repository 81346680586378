<div class="wrapper">
    <div class="row m-0">
        <div class="col-md-6">
            <form action="" [formGroup]="eventDetailsForm">
                <div class="book-appointment-container">
                    <h1 class="new-style">Event details</h1>

                    <div class="book-appointment-text">
                        <div class="row">
                            <div class="w-50 pdgns">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Jump date</mat-label>
                                    <input type="date" matInput formControlName='date' [min]="getCurrentDate">
                                </mat-form-field>
                                <span class="error"
                                    *ngIf="eventDetailsForm.get('date').hasError('required') && (eventDetailsForm.get('date').dirty || eventDetailsForm.get('date').touched || isSubmitted)">*Please
                                    enter jump date.</span>
                            </div>
                            <div class="w-50 pdgns">

                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Role in the Event </mat-label>
                                    <input matInput formControlName='role' maxlength="60">
                                </mat-form-field>
                                <span class="error"
                                    *ngIf="eventDetailsForm.get('role').hasError('required') && (eventDetailsForm.get('role').dirty || eventDetailsForm.get('role').touched || isSubmitted)">*Please
                                    enter role in the event.</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="w-50 pdgns">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Budget</mat-label>
                                    <input matInput formControlName='budget' maxlength="10"
                                        (keypress)="commonService.numberOnly($event)">
                                </mat-form-field>
                                <span class="error"
                                    *ngIf="eventDetailsForm.get('budget').hasError('required') && (eventDetailsForm.get('budget').dirty || eventDetailsForm.get('budget').touched || isSubmitted)">*Please
                                    enter budget.</span>
                            </div>

                            <div class="w-50 pdgns">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Link to Pinterest</mat-label>
                                    <input matInput formControlName='link' maxlength="60">
                                </mat-form-field>
                                <span class="error"
                                    *ngIf="eventDetailsForm.get('link').hasError('required') && (eventDetailsForm.get('link').dirty || eventDetailsForm.get('link').touched || isSubmitted)">*Please
                                    enter link.</span>
                            </div>
                        </div>

                        <mat-form-field appearance="outline" class="w-100 pdgns">
                            <mat-label>Tell us what you are looking for. (Gown Details)</mat-label>
                            <textarea matInput formControlName='gownDetails' maxlength="256"></textarea>
                        </mat-form-field>
                        <span class="error"
                            *ngIf="eventDetailsForm.get('gownDetails').hasError('required') && (eventDetailsForm.get('gownDetails').dirty || eventDetailsForm.get('gownDetails').touched || isSubmitted)">*Please
                            enter gown details.</span>


                        <mat-form-field appearance="outline" class="w-100 pdgns">
                            <mat-label>Additionnal Notes about the event (Optional)</mat-label>
                            <textarea matInput formControlName='additionalNotes' maxlength="256"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="down-button mt-5">
                        <button mat-button class="buttonGlobal" (click)="navigateToConfirmPayment()">Continue</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-6">
            <div class="image-container">
                <img src="/assets/images/Fully_Beaded_Collection_Shoot_2/Navy_Polka.jpg" alt="" class="image-custom">
            </div>
        </div>
    </div>

</div>