import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { Socket } from "ngx-socket-io";
import { CommonService } from 'src/app/provider/common.service';
import { AuthService } from 'src/app/provider/auth.service';
import { ChatService } from 'src/app/provider/chat.service';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-create-chat-room',
  templateUrl: './create-chat-room.component.html',
  styleUrls: ['./create-chat-room.component.scss']
})
export class CreateChatRoomComponent implements OnInit {
  createRoom: boolean = false;
  chatRoomForm: FormGroup;
  isSubmittedForm: boolean = false;

  constructor(public authService: AuthService, public commonService: CommonService, private router: Router) {
    window.scrollTo(0, 0)
  }

  ngOnInit(): void {
    this.formValidation()
  }

  formValidation() {
    this.chatRoomForm = new FormGroup({
      'eventName': new FormControl("", Validators.required),
      'eventDate': new FormControl("", Validators.required),
      'numberOfDresses': new FormControl("", Validators.required),
      'payType': new FormControl("", Validators.required)
    })
  }

  createChatRoom() {
    // if (this.createRoom) {
    //   this.createRoom = false
    // } else {
    //   this.createRoom = true
    // }
    if (!this.authService.checkAuthToken()) {
      return this.router.navigate(['/signin']);
    } else {
      this.createRoom = true;
    }
  }


  /**
   * @param GROUP -> will pay for all the dress purchased in a group
   * @param INDIVIDUAL -> every one will pay for his/her dress
   */
  // https://motee-maids-customer.mobiloitte.org/chat-room?groupId=${validatedBody.groupId}
  addGroup() {
    if (this.chatRoomForm.invalid) {
      this.isSubmittedForm = true
      return
    }
    let apiReqUrl = ApiEndPoint.addGroup
    let apiReqData = {
      'eventName': this.chatRoomForm.value.eventName,
      'eventDate': this.chatRoomForm.value.eventDate,
      'numberOfDresses': this.chatRoomForm.value.numberOfDresses,
      'payType': this.chatRoomForm.value.payType
    }
    console.log(apiReqData);
    // this.chatRoomForm.controls['eventName'].markAsTouched()

    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log(res);
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        console.log(res.result._id);
        this.router.navigate(['/invite-chatting'], { queryParams: { groupId: res.result._id } })

        // this.generateLink(res.result._id);
        // this.groupId = res.result.groupId
        // this.followinglistData = res["result"]["docs"]; 
        // for (let item of this.followinglistData) {aa
        //   this.folowingListOption.push(true)
        // }
      } else {
        this.commonService.hideSpinner();
      }
    });
  }

  // generate link to share with friends and family members
  // /group/generateLink
  // generateLink(groupId) {
  //   console.log(groupId);

  //   let apiReqUrl = ApiEndPoint.generateLink
  //   let apiReqData = {
  //     'groupId': groupId
  //   }
  //   this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
  //     console.log(res);
  //     if (res["responseCode"] == 200) {
  //       localStorage.setItem('webLink', res.result.webLink)
  //       // this.router.navigate(['/chat-room'], { queryParams: { groupId: groupId } })
  //       this.router.navigate(['/invite-chatting'], { queryParams: { groupId: groupId } })
  //       // this.followinglistData = res["result"]["docs"];
  //       // for (let item of this.followinglistData) {
  //       //   this.folowingListOption.push(true)
  //       // }
  //     }
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  // /group/addMember
  // addMember(groupId) {
  //   let apiReqUrl = ApiEndPoint.addMember
  //   let apiReqData = {
  //     'groupId': groupId
  //   }
  //   this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res) => {
  //     console.log(res);
  //     if (res["responseCode"] == 200) {
  //       // this.followinglistData = res["result"]["docs"];
  //       // for (let item of this.followinglistData) {
  //       //   this.folowingListOption.push(true)
  //       // }
  //     }
  //   }, (err) => {
  //     console.log(err);
  //   });
  // }

  navigateTo() {
    this.router.navigate(['/curation-list'])
  }

}
