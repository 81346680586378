<div class="wrapper">

    <div class="row">
        <div class="col-md-8">
            <div class="new-top-text">
                <!-- <h1>Abdul's Bridal Party</h1> -->
                <h1>{{productListInGroupDetails?.eventName || '--'}}</h1>


            </div>
            <!-- <div class="row m-0 justify-content-around"> -->
            <div class="row m-1 ml-5 mr-1  justify-content-left">
                <!-- <div class="action text-center mb-2">
                    <button class="buttonGlobal buttonGlobal1" data-toggle="modal" data-target="#checkout">Refresh
                        Slots</button>
                </div> -->
                <!-- <div *ngFor="let item of generateFake(productListInGroupDetails.numberOfDresses)" class="img-fgg">
                    <figure>
                        <img class="first-img-show" src="assets/images/other/grid1.png" alt="">
                    </figure>
                    <div class="content">
                        <img src="/assets/images/other/dummy_profile2.png" alt=""> <span class="header-text">Eleena</span>
                    </div>
                </div> -->

                <!-- <div *ngFor="let item of generateFake(productListInGroupDetails?.numberOfDresses); let index = index">
                    <ng-container *ngFor="let product of productListInGroupDetails.products">
                        <div *ngIf="product?.numberOfIndex != index" class="img-fg">
                            <figure >
                                <img class="first-img-show" src="assets/images/other/dummyimg.png" alt=""> <i class="fa fa-plus" (click)="openDialog()"></i>
                            </figure>
                        </div>
                        <div *ngIf="product?.numberOfIndex == index" class="img-fgg">
                            <figure>
                                <img class="first-img-show" src="assets/images/other/grid1.png" alt="">
                            </figure>
                            <div class="content">
                                <img src="/assets/images/other/dummy_profile2.png" alt=""> <span class="header-text">Eleena</span>
                            </div>
                        </div>
                    </ng-container>
                </div> -->

                <!-- <div class="col-md-8"> -->
                <!-- <div *ngFor="let item of productIndex; let index = index"> -->
                <!-- <ng-container *ngFor="let product of productListInGroupDetails.products"> -->
                <!-- <div *ngIf="(item | json) == ({} | json)" class="img-fg">
                                                                                                                        
                                                                                                                                <figure>
                                <img class="first-img-show" src="assets/images/other/dummyimg.png" alt=""> -->
                <!-- <i class="fa fa-plus" (click)="openDialog(index)"></i> -->
                <!-- <i class="fa fa-plus" (click)="addAddressModal()"></i>
                                                                                                                                                                                            
                                                                                                                                                                                            </figure>
                        </div> -->
                <!-- <div *ngIf="(item | json) != ({} | json)" class="img-fgg">
                        <figure>
                            <img class="first-img-show"
                                [src]="item?.product?.productDetails[0]?.image[0]?.imageUrl?.webImage || 'assets/images/other/grid1.png'"
                                alt="">
                        </figure>
                        <div class="content p-0">
                            <img src="/assets/images/other/dummy_profile2.png" alt=""> <span
                                class="header-text">{{item?.userId?.firstName}}</span>
                        </div>
                        </div> -->
                <!-- </ng-container> -->
                <!-- </div> -->

                <!-- homepage -->
                <div class="dataa-first-card" *ngFor="let item of productIndex; let index = index">
                    <div class="first-img" *ngIf="(item | json) == ({} | json)" class="img-fg">
                        <img class="first-img-show" src="assets/images/other/dummyimg.png" alt="">
                        <i class="fa fa-plus add-us" (click)="selectIndexInGroup(index)"></i>

                        <!-- <div class="first-bord-text"> -->
                        <!-- <span class="second-header-text new-style">Motee Maids</span> -->
                        <!-- </div> -->
                        <div class="first-bord-text">
                            <img src="/assets/images/profile-img.jpg" alt=""
                                style="width: 37px;height: 37px;margin-left: 10px;border-radius: 50px;"> <span
                                class="header-text" style="margin-left: 10px;">Not Selected</span>
                        </div>
                    </div>
                    <div class="first-img" *ngIf="(item | json) != ({} | json)" class="img-fg">
                        <!-- <figure>
                            <img class="first-img-show" src="assets/images/other/grid2.png" alt=""> <i class="fa fa-times"></i>
                        </figure> -->
                        <img class="first-img-show"
                            [src]="item?.productId?.productImage?.frontImage?.webImage || 'assets/images/other/dummyimg.png'"
                            alt="">
                        <i *ngIf="userId == item?.userId?._id && !item?.paymentStatus && !productListInGroupDetails?.paymentDone"
                            class="fa fa-times align-remove-icon" (click)="removeIndexInGroup(index)"></i>
                        <!-- <i *ngIf="!item?.paymentStatus && !productListInGroupDetails?.paymentDone"
                            class="fa fa-plus add-us" (click)="selectIndexInGroup(index)"></i> -->
                        <i *ngIf="userId == item?.userId?._id && !item?.paymentStatus && !productListInGroupDetails?.paymentDone && !item?.productId"
                            class="fa fa-edit add-us" (click)="selectIndexInGroup(index)"></i>
                        <div class="d-flex justify-content-space-between w-100">
                            <div class="first-bord-text">
                                <img [src]="item?.userId?.usersProfilePic?.webImage || '/assets/images/profile-img.jpg'"
                                    alt="" style="width: 37px;height: 37px;margin-left: 10px;border-radius: 50px;">
                                <span class="header-text" style="margin-left: 10px;">{{item?.userId?.firstName}}</span>
                            </div>
                            <div *ngIf="productListInGroupDetails?.payType == 'INDIVIDUAL'"
                                class="payment-check-uncheck-shection">
                                <i *ngIf="item?.paymentStatus" class="fa fa-check-circle" aria-hidden="true"></i>
                                <i *ngIf="!item?.paymentStatus && item?.orderId" class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                    (click)="navigateToPayment(item.orderId, item.numberOfIndex)"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end homepage card -->
                <!-- </div> -->

                <!-- <div class="img-fgg">
                    <figure>
                        <img class="first-img-show" src="assets/images/other/grid1.png" alt="">
                    </figure>
                    <div class="content">
                        <img src="/assets/images/other/dummy_profile2.png" alt=""> <span class="header-text">Eleena</span>
                    </div>
                </div>
                <div class="img-fgg ">
                    <figure>
                        <img class="first-img-show" src="assets/images/other/grid2.png" alt=""> <i class="fa fa-times"></i>
                    </figure>
                    <div class="content">
                        <img src="/assets/images/other/dummy_profile2.png" alt=""> <span class="header-text">Abdul</span>
                    </div>
                </div>
                <div class="img-fgg ">
                    <figure>
                        <img class="first-img-show" src="/assets/images/Fully_Beaded_Collection_Shoot_2/Amelia_Fully Beaded_Back.jpg" alt="">
                    </figure>
                    <div class="content">
                        <img src="/assets/images/other/dummy_profile2.png" alt=""> <span class="header-text">Ahmad</span>
                    </div>
                </div>
                <div class="img-fgg ">
                    <figure>
                        <img class="first-img-show" src="/assets/images/Fully_Beaded_Collection_Shoot_2/Amelia_Fully_Beaded.jpg" alt="">
                    </figure>
                    <div class="content">
                        <img src="/assets/images/other/dummy_profile2.png" alt=""> <span class="header-text">Aquib</span>
                    </div>
                </div> -->
            </div>
            <!-- <div class="row justify-content-around new-sec">
              <div class="img-fg">
                    <figure>
                        <img class="first-img-show" src="assets/images/other/dummyimg.png" alt=""> <i class="fa fa-plus" (click)="openDialog()"></i>
                    </figure>
                </div>
                <div class="img-fg">
                    <figure>
                        <img class="first-img-show" src="assets/images/other/dummyimg.png" alt=""> <i class="fa fa-plus" (click)="openDialog()"></i>
                    </figure>
                  
                </div>
                <div class="img-fg">
                    <figure>
                        <img class="first-img-show" src="assets/images/other/dummyimg.png" alt=""> <i class="fa fa-plus" (click)="openDialog()"></i>
                    </figure>
                </div>
                <div class="img-fg">
                    <figure>
                        <img class="first-img-show" src="assets/images/other/dummyimg.png" alt=""> <i class="fa fa-plus" (click)="openDialog()"></i>
                    </figure>
                </div>
            </div> -->
            <div *ngIf="(userId == productListInGroupDetails?.createdBy) && (productListInGroupDetails?.payType == 'GROUP') && (!productListInGroupDetails?.paymentDone)"
                class="action text-center mb-2">
                <button class="buttonGlobal buttonGlobal1" data-toggle="modal" data-target="#checkout"
                    [disabled]="productListInGroupDetails?.products?.length != productListInGroupDetails?.numberOfDresses">Checkout</button>
            </div>
        </div>
        <div class="col-md-4">
            <div style="margin-top: 5em;">

                <mat-toolbar style="white-space: break-spaces;">
                    <p class="header-text" style="font-size: 12px; line-height: 15px;">{{generatedLink}}</p>
                    <!-- <img style="cursor: pointer;" class="myDiv" src="assets/images/other/capyicon.jpg" alt="Copy"
                        (click)="copyText(generatedLink)"> -->
                    <i class="fa fa-copy myDiv" title="Click to copy" (click)="copyText(generatedLink)"></i>
                    <!-- <div class="hide">Click to copy</div> -->
                </mat-toolbar>
                <div class="icon-custom" style="cursor: pointer; text-align: center;">
                    <i *ngIf="[type1] =='whatsapp'" class="fa fa-whatsapp" (click)="share(type1)"
                        style="font-size: 22px; margin-right: 3px;"></i>
                    <mat-icon *ngIf="[type] =='facebook'" aria-hidden="false" aria-label="Example home icon"
                        (click)="share(type)">
                        facebook</mat-icon>
                    <mat-icon *ngIf="[type2] =='snapchat'" aria-hidden="false" aria-label="Example home icon"
                        (click)="share(type2)">
                        snapchat</mat-icon>
                </div>
            </div>
            <!-- chat modal -->
            <div class=" global-modal reset-modal feed-mod-one">
                <div class="one-feed mb-3" style="width: 37em;border:2px solid #021944;margin-top: 0em; ">
                    <form class="change_password">
                        <div class=" top-data">
                            <div class="modal-header text-center modal-header-custm feedback-header-data">
                                <p>CHAT</p>
                                <!-- <span class="d-grid">
                                    <img class="user-profiles" src="http://emilcarlsson.se/assets/harveyspecter.png"
                                        alt="" />

                                </span>
                                <span class="d-grid">
                                    <img class="user-profiles" src="http://emilcarlsson.se/assets/harveyspecter.png"
                                        alt="" />

                                </span>
                                <span class="d-grid">
                                    <img class="user-profiles" src="http://emilcarlsson.se/assets/harveyspecter.png"
                                        alt="" />

                                </span>
                                <span class="d-grid">
                                    <img class="user-profiles" src="http://emilcarlsson.se/assets/harveyspecter.png"
                                        alt="" />

                                </span> -->


                            </div>

                            <div class="modal-inner-content">
                                <div id="frame">
                                    <div class="content p-0">
                                        <!-- <div class="contact-profile">
                                            <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
                                            <p>Harvey Specter</p>
</div> -->
                                        <div #contentArea class="messages">
                                            <ul>
                                                <ng-container *ngFor="let item of particularChat?.messages">
                                                    <li *ngIf="item?.senderId?._id != userId" class="sent">
                                                        <!-- <div class="right-chat-text">
                                                                                                                                                                                    <div class="text-msg"> -->
                                                        <img [src]="item?.senderId?.usersProfilePic?.webImage || '/assets/images/profile-img.jpg'"
                                                            alt="" />
                                                        <!-- <h6>hello 23 Apr, 2022</h6> -->
                                                        <p>{{item?.message}}</p>
                                                        <!-- </div>
                                                                                                                                                                                </div> -->
                                                    </li>
                                                    <li *ngIf="item?.senderId?._id == userId" class="replies">
                                                        <!-- <div class="right-chat-text">
                                                                                                                                                                                    <div class="text-msg"> -->
                                                        <img [src]="item?.senderId?.usersProfilePic?.webImage || '/assets/images/profile-img.jpg'"
                                                            alt="" />
                                                        <!-- <h6>hello 23 Apr, 2022</h6> -->
                                                        <p>{{item?.message}}</p>
                                                        <!-- </div>
                                                                                                                                                                                </div> -->
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>


                                        <!-- <div class="messages">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <ul>
                                                <li class="sent">
                                                    <img src="http://emilcarlsson.se/assets/mikeross.png" alt="" />
                                                    <p>How the hell am I supposed to get a jury to believe you when I am
                                                        not even sure that I do?!</p>
                                                </li>
                                                <li class="replies">
                                                    <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
                                                    <p>When you're backed against the wall, break the god damn thing
                                                        down.
                                                    </p>
                                                </li>
                                                <li class="replies">
                                                    <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
                                                    <p>Excuses don't win championships.</p>
                                                </li>
                                                <li class="sent">
                                                    <img src="http://emilcarlsson.se/assets/mikeross.png" alt="" />
                                                    <p>Oh yeah, did Michael Jordan tell you that?</p>
                                                </li>
                                                <li class="replies">
                                                    <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
                                                    <p>No, I told him that.</p>
                                                </li>
                                                <li class="replies">
                                                    <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
                                                    <p>What are your choices when someone puts a gun to your head?</p>
                                                </li>
                                                <li class="sent">
                                                    <img src="http://emilcarlsson.se/assets/mikeross.png" alt="" />
                                                    <p>What are you talking about? You do what they say or they shoot
                                                        you.
                                                    </p>
                                                </li>
                                                <li class="replies">
                                                    <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
                                                    <p>Wrong. You take the gun, or you pull out a bigger one. Or, you
                                                        call their bluff. Or, you do any one of a hundred and forty six
                                                        other things.</p>
                                                </li>
                                            </ul>
                                        </div> -->

                                        <div class="message-input">
                                            <div class="wrap">
                                                <input type="text" placeholder="Write your message..."
                                                    [(ngModel)]="newMessage" [ngModelOptions]="{standalone:true}"
                                                    (keyup.enter)="sendMessage()" />
                                                <!-- <i class="fa fa-paperclip attachment" aria-hidden="true"></i> -->
                                                <button class="submit" (click)="sendMessage()"><i
                                                        class="fa fa-paper-plane" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- modal checkout -->
<!-- Modal -->
<div class="modal fade" id="checkout" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered cent-data">
        <div class="modal-content">
            <!-- <div class="modal-header">
          <h5 class="modal-title header-text mod-text" id="exampleModalLabel">DELIVERY INFORMATION</h5> -->
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
            <!-- </div> -->
            <div class="modal-body">
                <!-- <ul class="deta-list">
            <li class="header-text">
               Would you like to deliver all the dresses to same
               <br>
               address?
            </li>
            
        </ul> -->
                <h3 class="text-center">Would you like to deliver all the dresses </h3>
                <h3 class="text-center" style="margin-top: -1em;">to same address?</h3>
            </div>
            <div class="btn-list">
                <!-- <button type="button" class="btn btn-secondary cncl" data-dismiss="modal"
                    routerLink="/checkout-multiple-address">NO</button>
                <button type="button" class="btn btn-primary buttonGlobal sbt" data-dismiss="modal"
                    routerLink="/checkout-saved-address">YES</button> -->
                <button type="button" class="btn btn-secondary cncl" data-dismiss="modal"
                    (click)="proceedToCheckout('DELIVER_AT_MULTIPLE_ADDRESS')">NO</button>
                <button type="button" class="btn btn-primary buttonGlobal sbt" data-dismiss="modal"
                    (click)="proceedToCheckout('DELIVER_AT_SAME_ADDRESS')">YES</button>
            </div>
        </div>
    </div>
</div>
<!-- rey to ship or customize modal -->
<!-- add new address modal -->
<div class="modal fade" id="dressTypeModal" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <!-- <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div> -->
            </div>

            <div class="modal-atc-body">
                <!-- <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Select
                    Dress Type
                </h2> -->
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 24px;font-weight: 400;">Select
                    Dress Type
                </h2>
                <div class="">

                    <div class="image-wrapper">

                        <div class="data-details">
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <div class="dropdown new-drop select-dress-border">
                                        <ng-container *ngFor="let item of headerList" class="nav-item menu-items mr-2"
                                            [ngClass]="{'dropdown': item?.sub.length}">
                                            <a id="dLabel" role="button" data-toggle="dropdown" data-target="#"
                                                style="color: #6c6c6b;" href="#">
                                                {{item?.parent?.categoryName}} <span class="caret"></span>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </a>
                                            <!-- <br> -->
                                            <ul *ngIf="item?.sub?.length" class="dropdown-menu p-3" role="menu"
                                                aria-labelledby="dLabel">
                                                <li *ngFor="let sub of item?.sub" (click)="selectDressTypeInGroup(sub)">
                                                    {{sub?.subCategoryName}}</li>
                                            </ul>
                                        </ng-container>
                                    </div>
                                    <!-- </div> -->
                                    <!-- <p class="header-text m-0" mat-dialog-close
                                        (click)="addProductInGroup('/create-dress')">Customize Your
                                        Dress
                                    </p> -->
                                    <p class="header-text custo-your-dress select-dress-border" mat-dialog-close
                                        (click)="selectDressTypeInGroup('CUSTOMIZE')">Customize
                                        Your
                                        Dress
                                    </p>
                                </div>

                            </div>



                        </div>




                    </div>


                </div>

            </div>


            <div class="form-element-wrapper">
                <div class="border-btn-wrapper">
                    <button type="button" class="submit-form-btn buttonGlobal" id="submit-cart-modal"
                        (click)="closeSelectDressModal()">
                        Cancel
                    </button>
                </div>
            </div>


        </div>
    </div>
</div>
<!-- </div> -->

<!-- end add address modal -->