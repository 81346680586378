<div class="wrapper">
    <div class="row m-0">
        <div class="col-md-6">
            <form action="" [formGroup]="clientInfoForm">
                <div class="book-appointment-container">
                    <h1 class="new-style">Client Information</h1>
                    <div class="book-appointment-text">
                        <div class="row">
                            <div class="w-50 pdgn">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>First Name</mat-label>
                                    <input matInput formControlName='firstName' maxlength="60">
                                </mat-form-field>
                                <span class="error"
                                    *ngIf="clientInfoForm.get('firstName').hasError('required') && (clientInfoForm.get('firstName').dirty || clientInfoForm.get('firstName').touched || isSubmitted)">*Please
                                    enter first name.</span>
                                <span class="error" *ngIf="clientInfoForm.get('firstName').hasError('pattern')">*Please
                                    enter valid first name.</span>
                                <span class="error"
                                    *ngIf="clientInfoForm.get('firstName').hasError('minlength')">*Minimum length must
                                    be 2.</span>

                            </div>

                            <div class="w-50 pdgn">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Last Name</mat-label>
                                    <input matInput formControlName='lastName' maxlength="60">
                                </mat-form-field>
                                <span class="error"
                                    *ngIf="clientInfoForm.get('lastName').hasError('required') && (clientInfoForm.get('lastName').dirty || clientInfoForm.get('lastName').touched || isSubmitted)">*Please
                                    enter last name.</span>
                                <span class="error" *ngIf="clientInfoForm.get('lastName').hasError('pattern')">*Please
                                    enter valid last name.</span>
                                <span class="error"
                                    *ngIf="clientInfoForm.get('lastName').hasError('minlength')">*Minimum length must be
                                    2.</span>

                            </div>
                        </div>

                        <!-- <div class="row"> -->
                        <div class="" style="padding-bottom: 15px;">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Email</mat-label>
                                <input *ngIf="!isReadonly" matInput formControlName='email' maxlength="256">
                                <input *ngIf="isReadonly" matInput formControlName='email' maxlength="256"
                                    readonly="readonly">
                            </mat-form-field>
                            <span class="error"
                                *ngIf="clientInfoForm.get('email').hasError('required') && (clientInfoForm.get('email').dirty || clientInfoForm.get('email').touched || isSubmitted)">*Please
                                enter email id.</span>
                            <span class="error" *ngIf="clientInfoForm.get('email').hasError('pattern')">*Please
                                enter
                                valid email id.</span>
                        </div>
                        <!-- </div> -->

                        <div class="row">
                            <div class="w-50 pdgn">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Phone</mat-label>
                                    <input matInput formControlName='phone' type="text" maxlength="15"
                                        (keypress)="commonService.numberOnly($event)">
                                </mat-form-field>
                                <div class="text-danger" style="float: left;"
                                    *ngIf="(clientInfoForm.get('phone').invalid && (clientInfoForm.get('phone').touched || clientInfoForm.get('phone').dirty || isSubmitted));">
                                    <span *ngIf="clientInfoForm.get('phone').errors.required" style="float: left;">
                                        *Phone number is required.
                                    </span>
                                    <span *ngIf="clientInfoForm.get('phone').errors.pattern" style="float: left;">
                                        *Please enter valid phone number.
                                    </span>
                                </div>
                                <!-- <span class="error"
                                    *ngIf="clientInfoForm.get('phone').hasError('required') && (clientInfoForm.get('phone').dirty || clientInfoForm.get('phone').touched || isSubmitted)">*Please
                                    enter phone no.</span> -->
                            </div>

                            <div class="w-50 pdgn">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput formControlName='zipCode' maxlength="10" placeholder="Zip Code">
                                </mat-form-field>
                                <span class="error"
                                    *ngIf="clientInfoForm.get('zipCode').hasError('required') && (clientInfoForm.get('zipCode').dirty || clientInfoForm.get('zipCode').touched || isSubmitted)">*Please
                                    enter zip code.</span>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <p class="text-left">May we schedule a Pre-Appointment Consultation with you?</p>
                        <div class="down-button d-flex">
                            <button mat-button class="buttonGlobal m-2 w-auto dtta" (click)="preConsulting = true"
                                [ngClass]="{'active': preConsulting == true}">YES</button>
                            <button mat-button class="buttonGlobal m-2 w-auto dtta"
                                (click)="preConsulting = false;consultingType=''"
                                [ngClass]="{'active': preConsulting == false}">NO</button>

                        </div>
                    </div>
                    <div *ngIf="preConsulting">
                        <!-- <p class="text-left">Via (Select one or more options):</p> -->
                        <p class="text-left">Via (Select one options):</p>
                        <div class="down-button d-flex">
                            <!-- <button mat-button class="buttonGlobal m-2 w-auto dtta" > <i class="fas fa-phone"></i> &nbsp; CALL</button>
                <button mat-button class="buttonGlobal m-2 w-auto dtta" > <i class='fas fa-comment-alt'></i> &nbsp; TEXT</button> -->
                            <button mat-button class="buttonGlobal m-2 w-auto dtta" [disabled]="!preConsulting"
                                (click)="consultingType = 'CALL'" [ngClass]="{'active': consultingType == 'CALL'}"> <i
                                    class="fa fa-phone-square"></i> &nbsp; CALL</button>
                            <button mat-button class="buttonGlobal m-2 w-auto dtta" [disabled]="!preConsulting"
                                (click)="consultingType = 'TEXT'" [ngClass]="{'active': consultingType == 'TEXT'}"> <i
                                    class='fa fa-commenting'></i> &nbsp; TEXT</button>
                        </div>
                    </div>
                    <div class="down-button mt-5">
                        <button mat-button class="buttonGlobal" (click)="navigateToEventDetails()">Continue</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-6">
            <div class="image-container">
                <!-- <img src="/assets/images/SEQUIN_SASH_COLLECTION_JPEG/Kings_Lynn.jpg" alt="" class="image-custom"> -->
                <img src="/assets/images/Fully_Beaded_Collection_Shoot_2/Navy_Polka.jpg" alt="" class="image-custom">
            </div>
        </div>
    </div>

</div>