import { Component } from '@angular/core';
import { StoreLocatorDialogComponent } from './modals/store-locator-dialog/store-locator-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from './provider/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // public link: string = 'https://medium.com/@garfunkel61/angular-simplest-solution-for-social-sharing-feature-6f00d5d99c5e'
  constructor(public dialog: MatDialog, public commonService: CommonService) {
    this.setCurrencyCode()
  }
  title = 'motte-maid-website';

  setCurrencyCode() {
    // write here to get current currency code
    this.commonService.getIPAddress().subscribe((res) => {
      console.log(res);
      this.getLocationCurrent(res['IPv4'])
    })
    // console.log()
  }

  // 'http://localhost:3031/api/v1/admin/getSystemDetails?ip=182.71.75.106'
  getLocationCurrent(ip) {
    // this.commonService.getLocation("http://www.geoplugin.net/json.gp?ip=" + ip).subscribe((res: any) => {
    this.commonService.getApi(`${"admin/getSystemDetails?ip=" + ip}`, 0).subscribe((res: any) => {
      console.log(res.result);
      localStorage.setItem('ipDetails', JSON.stringify(res.result))
      localStorage.setItem('countryName', JSON.stringify(res.result.geoplugin_countryName))

      // this.lat = res["geoplugin_latitude"]
      // this.lng = res["geoplugin_longitude"]
      // this.tradingActivity()
      // this.getCenterPostion(this.latitude, this.longitude)
    })
  }

}
