import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-invite-chatting',
  templateUrl: './invite-chatting.component.html',
  styleUrls: ['./invite-chatting.component.scss'],
})
export class InviteChattingComponent implements OnInit {
  roomEnterance = false;
  generatedLink: any;
  groupId: any;
  type = 'facebook';
  type1 = 'whatsapp';
  type2 = 'snapchat';
  shareUrl: string;
  navUrl: string;
  public link: string =
    'https://medium.com/@garfunkel61/angular-simplest-solution-for-social-sharing-feature-6f00d5d99c5e';
  typeMatch: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.groupId = res.groupId;
      this.generateLink(this.groupId);
    });
  }

  // enterRoom(){
  //   this.roomEnterance = true
  // }

  enterRoom() {
    localStorage.setItem('curationLink', this.generatedLink)
    this.router.navigate(['/chat-room'], {
      queryParams: { groupId: this.groupId },
    });
  }

  // generate link to share with friends and family members
  // /group/generateLink
  generateLink(groupId) {
    // console.log(groupId);
    let apiReqUrl = ApiEndPoint.generateLink;
    let apiReqData = {
      groupId: groupId,
      webLink: `${this.commonService.webUrl + 'chat-room?groupId=' + this.groupId}`
    };
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe(
      (res: any) => {
        console.log(res);
        if (res['responseCode'] == 200) {
          this.generatedLink = res.result.webLink;
          // localStorage.setItem('webLink', res.result.webLink)
          // this.router.navigate(['/chat-room'], { queryParams: { groupId: groupId } })
          // this.router.navigate(['/invite-chatting'], { queryParams: { groupId: groupId } })
          // this.followinglistData = res["result"]["docs"];
          // for (let item of this.followinglistData) {
          //   this.folowingListOption.push(true)
          // }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // private createNavigationUrl(typeMatch) {
  //   switch ((this.typeMatch = typeMatch)) {
  //     case 'facebook':
  //       this.navUrl = 'https://www.facebook.com/sharer/sharer.php?' + this.link;
  //       break;
  //     case 'whatsapp':
  //       this.navUrl = 'https://whatsapp.com/share?' + this.link;
  //       break;
  //     case 'snapchat':
  //       this.navUrl = 'https://snapchat.com/share?' + this.link;
  //       break;
  //   }
  // }

  private createNavigationUrl(typeMatch) {
    switch ((this.typeMatch = typeMatch)) {
      case 'facebook':
        this.navUrl = 'https://www.facebook.com/sharer/sharer.php?' + this.generatedLink;
        break;
      case 'whatsapp':
        this.navUrl = 'https://whatsapp.com/share?' + this.generatedLink;
        break;
      case 'snapchat':
        this.navUrl = 'https://snapchat.com/share?' + this.generatedLink;
        break;
    }
  }

  public share(type) {
    this.createNavigationUrl(type);
    return window.open(this.navUrl, '_blank');
  }

  // enterRoom(){
  //   this.router.navigate(['/chat-room'])
  // }

  /**
   * @param copyText copy any Text
   * */
  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.commonService.alertSuccess('Invitation link copied successfully');
  }
}
