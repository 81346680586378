<div class="wrapper">
    <div class="top-text">
        <h1 class="text-center header-text">Dresses for Every Event</h1>
    </div>
    <!-- first section  -->
    <div class="container mt-4">
        <div class="row data-top">
            <div class="first-img" routerLink="/readytoship-bridal">
                <img class="first-img-show" src="/assets/images/Shoot_3/Pressimages/Cara_Cowl_in_Mixed_Encrusted_Close_up.jpg" alt="">
                <div class="middle">
                    <div class="text header-text">SHOP NOW</div>
                  </div>
                <h1 class="text-left header-text">Bridesmaids</h1>
                <h4 class="text-left header-text">Ready to Ship Dresses in Velvet, Satin, and more.</h4>
            </div>
            <div class="second-img" routerLink="/readytoship-bridal">
                <img class="second-img-show" src="/assets/images/Shoot_3/Pressimages/Cara_Cowl_in_Mixed_Encrusted_Close_up.jpg" alt="">
                <div class="middle">
                    <div class="text header-text">SHOP NOW</div>
                  </div>
                <h1 class="text-left header-text">Robes & Sets</h1>
                <h4 class="text-left header-text">Getting ready outfits and satin robes for the bride and bridesmaids. Available and ready to ship!
                    </h4>
            </div>
        </div>
    </div>
<!-- second section -->
<div class="container new-top-data">
    <div class="row data-top">
        <div class="third-img" routerLink="/readytoship-bridal">
            <img class="third-img-show" src="/assets/images/Shoot_3/Pressimages/Cara_Cowl_in_Mixed_Encrusted_Close_up.jpg" alt="">
            <div class="middle">
                <div class="text header-text">SHOP NOW</div>
              </div>
            <h1 class="text-left header-text">Masks</h1>
            <h4 class="text-left header-text">Thoughtfully designed to complement our bridal and bridesmaid gowns.</h4>
        </div>
        <div class="forth-img" routerLink="/readytoship-bridal">
            <img class="forth-img-show" src="/assets/images/Shoot_3/Pressimages/Cara_Cowl_in_Mixed_Encrusted_Close_up.jpg" alt="">
            <div class="middle">
                <div class="text header-text">SHOP NOW</div>
              </div>
            <h1 class="text-left header-text">Flower Girl</h1>
            <h4 class="text-left header-text">Our sweetest styles for your bridal party's smallest member, all available now and ready to ship
                </h4>
        </div>
    </div>
</div>
<!-- third section -->
<div class="container new-top-tt">
    <div class="row data-top">
        <div class="fifth-img" routerLink="/readytoship-bridal">
            <img class="fifth-img-show" src="/assets/images/Shoot_3/Pressimages/Cara_Cowl_in_Mixed_Encrusted_Close_up.jpg" alt="">
            <div class="middle">
                <div class="text header-text">SHOP NOW</div>
              </div>
            <h1 class="text-left header-text">Color Swatches</h1>
            <h4 class="text-left header-text">Buy fabric swatches in velvet, satin, and more fabrications to help wedding plan.</h4>
        </div>
        <div class="six-img" routerLink="/readytoship-bridal">
            <img class="six-img-show" src="/assets/images/Shoot_3/Pressimages/Cara_Cowl_in_Mixed_Encrusted_Close_up.jpg" alt="">
            <div class="middle">
                <div class="text header-text">SHOP NOW</div>
              </div>
            <h1 class="text-left header-text">Sale</h1>
            <h4 class="text-left header-text">Select bridesmaids dresses on sale and ready to ship.
                </h4>
        </div>
    </div>
</div>
</div>