import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  guestRegistratonForm:FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.formValidation()
  }

  formValidation(){
    this.guestRegistratonForm= new FormGroup({
    name:new FormControl("",Validators.required),
    date:new FormControl("",Validators.required),
    email:new FormControl(" ",[Validators.required,
      Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/)]),
    storeName: new FormControl("",Validators.required),
    location: new FormControl("",Validators.required),
    address: new FormControl("",Validators.required),
    country: new FormControl("",Validators.required),
    zipCode: new FormControl("",Validators.required),
    countryCode: new FormControl("",Validators.required),
    phoneNo:  new FormControl("", [
      Validators.required,
      Validators.pattern(/^[^0][0-9]*$/),]),
  })
    }
}
