import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.scss']
})
export class TradeComponent implements OnInit {
  subCategoryList: any;
  tradeList: any;
  subCategoryId: any;

  constructor(private commonService: CommonService) { }
  categoryId: any
  ngOnInit(): void {
    this.listSubCategory()
  }

  /**
   * @param listSubCategory function used to get sub categroy list
   * @param apiReqUrl contains end point url
   */

  listSubCategory() {
    let apiReqUrl = "product/listSubCategoryWithoutPagination"
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.subCategoryList = res.result
        this.getSubCategory('')
        this.commonService.hideSpinner()
      } else {
        this.commonService.hideSpinner()
      }
    })
  }
  /**
   * @param getSubCategory function is called when fetched list subcategory and on change of sub category 
   */
  getSubCategory(subCategoryId) {
    console.log(subCategoryId);
    this.subCategoryId = subCategoryId
    this.listShowtrade()
  }

  /**
 * @param listShowtrade function used to fetched or get show trade details
 * @param apiReqUrl contains end point url with subCategoryId and without subCategoryId according to condition
 */
  listShowtrade() {
    let apiReqUrl = `${"admin/listShows?type=TRADE" + (this.subCategoryId ? ('&subCategoryId=' + this.subCategoryId) : '')}`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.tradeList = res.result
        this.commonService.hideSpinner()
      } else {
        this.tradeList = []
        this.commonService.hideSpinner()
      }
    }, (err: any) => {
      this.tradeList = []
      this.commonService.hideSpinner()
      // Swal.fire({
      //   icon: 'error',
      //   text: err.responseMessage,
      // })
    })
  }

}
