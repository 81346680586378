<!-- <div class="container"> -->
<div class="content">

    <!-- <div class="order-details">
        <span class="header-text">My Favorites</span>
    </div> -->
    <div class="top-text text-center pb-2">
        <mat-card-title class="mat-card-title sign-text ml-0 ">My Wishlist</mat-card-title>
    </div>
    <!-- <div class="row">
        <div class="col-md-4" *ngFor="let item of listWishlistArray">
            <figure class="first-img">
                <img [src]="item?.productId?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt="STILLS_1983"
                    class="imgf">
            </figure>
            <div class="footer_content">
                <div class="list-view-item__title">
                    <a class="title-color-dark" style="color: #6C6C6B;">{{item?.productId?.productName}}</a>
                </div>
                <div class="cart__meta-text header-text">
                    <span class="">Size : <label>{{item?.productId?.productDetails[0]?.size}}</label></span>
                    <br>
                    <span class=""> price :
                        <label>{{item?.productId?.productPrice[0]?.symbol}}{{item?.productId?.productPrice[0]?.MRP}}</label></span>
                </div>
                <div class="down-button">
                    <button mat-button class="buttonGlobal new-remove"
                        (click)="deleteWishlist(item?._id)">Remove</button>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row">
        <!-- <div class="row"> -->
        <ng-container *ngIf="productListArray.length && !isSkeleton">

            <div class="fifth-img col-md-4" *ngFor="let item of productListArray">
                <!-- <div class="flip-box">
                <div class="flip-box-inner">
                    <div class="flip-box-front">
                        <img class="fifth-img-show"
                            [src]="item?.productId?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt=""
                            (click)="navigateToViewMotherDress(item?.productId?._id)">
                    </div>
                    <div class="flip-box-back">
                        <img class="fifth-img-show"
                            [src]="item?.productId?.productDetails[0]?.image[1]?.imageUrl?.webImage" alt=""
                            (click)="navigateToViewMotherDress(item?.productId?._id)">
                    </div>
                </div>
            </div> -->
                <div class="imageBox">
                    <img class="fifth-img-show" [src]="item?.productDetails?.productImage?.frontImage?.webImage" alt=""
                        (click)="navigateToViewMotherDress(item?.productDetails?._id)">
                    <img class=" fifth-img-hide" [src]="item?.productDetails?.productImage?.frontImage?.webImage" alt=""
                        (click)="navigateToViewMotherDress(item?.productDetails?._id)">
                    <!-- <img class="fifth-img-show"
                [src]="item?.productId?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt=""
                            (click)="navigateToViewMotherDress(item?.productId?._id)">
                <img class=" fifth-img-hide"
                [src]="item?.productId?.productDetails[0]?.image[1]?.imageUrl?.webImage" alt=""
                (click)="navigateToViewMotherDress(item?.productId?._id)"> -->
                </div>


                <!-- <div class="imageBox">
                <img class="fifth-img-show"
                [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt=""
                (click)="navigateToViewMotherDress(item?._id)">
                <img class=" fifth-img-hide"
                [src]="item?.productDetails[0]?.image[1]?.imageUrl?.webImage" alt=""
                (click)="navigateToViewMotherDress(item?._id)">
            </div> -->

                <!-- <div class="portfolio-single" ontouchstart="this.classList.toggle('hover');">
                <figure>
                    <img class="fifth-img-show"
                    [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt=""
                    (click)="navigateToViewMotherDress(item?._id)">
                  <figcaption>
                    <img class="fifth-img-show"
                    [src]="item?.productDetails[0]?.image[1]?.imageUrl?.webImage" alt=""
                    (click)="navigateToViewMotherDress(item?._id)">
                  </figcaption>
                </figure>
              </div> -->

                <div class="top">
                    <div class="d-flex justify-content-between align-items-center new-like">
                        <h4 class="text-left pc-p header-text m-0">{{item?.productDetails?.productName}}</h4>
                        <i [ngClass]="item?.isLike?'fa fa-heart ct':'fa fa-heart-o'"
                            (click)="addProductWishlist(item?.productDetails?._id)"></i>
                    </div>
                    <div class="text-left pric">
                        <h4 class="m-0 header-text">
                            {{item?.countryPrice?.totalMrp | currency :
                            ipDetails?.geoplugin_currencySymbol}}
                        </h4>
                        <!-- <div class="ic d-flex">
                        <ng-container *ngFor="let color of item.colorDetails">
                            <div class="image-custom-list" [style.background-color]="color?.colorCode">
                            </div>
                        </ng-container>
                    </div> -->
                    </div>
                </div>

                <!-- <h4 class="text-left header-text">Buy fabric swatches in velvet, satin, and more fabrications to help wedding plan.</h4> -->
            </div>
        </ng-container>

        <!-- product skeleton -->
        <ng-container *ngIf="!productListArray.length && isSkeleton">
            <div class="skeleton skeleton-section col-xl-4 col-lg-4 col-md-4 col-sm-2"
                *ngFor="let item of generateFake(6)">
                <div class="item-wrap">
                    <div class="item-img">
                        <ngx-skeleton-loader animation="progress" [theme]="skeletonImageTheme">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- =-=-=-=-=-=-=-=-= 404 error=-=-=-=-=-=-=-=-=-=-  -->
        <div class="noPostVailableDiv w-100" *ngIf="!productListArray.length && !isSkeleton"
            style="text-align: center;     border-top: 1px solid #bfcedf; padding: 1em 10px; border-bottom: 1px solid #bfcedf;">
            <div>
                <!-- <img src="../../../assets/images/error404.png" alt="img" class="animate" style="width: 50%;"> -->
                <span>No data found.</span>
            </div>
        </div>
    </div>
</div>