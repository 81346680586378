import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
@Component({
  selector: 'app-bridal-party',
  templateUrl: './bridal-party.component.html',
  styleUrls: ['./bridal-party.component.scss']
})
export class BridalPartyComponent implements OnInit {
  productListArray: any;

  constructor(private commonService:CommonService) { }

  ngOnInit(): void {
    this.getCollection()
  }

  getCollection() {
    let apiReqUrl = ApiEndPoint.productListWithFilter
    let apiReqData = {
      "page": 1,
      "limit": 10,
      "minAmount": 0,
      "maxAmount": 0,
      "fabric": "string",
      "category": "6231915fcb6cf95b3ef46453", // ready to ship
      "subCategory": "62319238cb6cf95b3ef46454", //Bridal Party
      "color": "string"
    }
    
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        console.log("fdsgdfgfdhdfhfghfghh");this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
        this.productListArray = res.result.docs ? res.result.docs : ''
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err.statusCode == '401') {
        this.commonService.errorToast(err['error']['message']);
        localStorage.removeItem('token');
      } else {
        this.commonService.errorToast(err.message);
      }
    })
  }
}
