import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CountryCode, CommonService } from 'src/app/provider/common.service';
import { AuthService } from 'src/app/provider/auth.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-influencer-login',
  templateUrl: './influencer-login.component.html',
  styleUrls: ['./influencer-login.component.scss']
})
export class InfluencerLoginComponent implements OnInit {
  signinForm: FormGroup;
  toggleEye: boolean = true;  // toggle eye
  countryList: any[];
  filteredOptions: Observable<CountryCode[]>;
  myControl = new FormControl('+91');
  isSigninFormSubmitted: boolean = false
  constructor(public authService: AuthService, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.formValidation()
  }


  formValidation() {
    // Form group and Form Control Name With Validations
    this.signinForm = new FormGroup({
      emailId: new FormControl("", [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/),

      ]),
      password: new FormControl("", Validators.required),
      // rememberMe: new FormControl(""),
    });
    this.countryList = this.commonService.countryListJson;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (value ? this._filter(value) : this.countryList.slice())),
    );

  }
  private _filter(value: string): CountryCode[] {
    const filterValue = value.toLowerCase();
    return this.countryList.filter(option => option.code.toLowerCase().includes(filterValue));
  }

  /**
   * userType are used in website
   * @param CONSUMER -> normal user comes to buy product
   * @param RETAILER -> wholesale retailer comes to buy product for consumer
   * @param INFLUENCER -> location ideal person of some place refer to buy product
   */
  login() {
    if (this.signinForm.invalid) {
      this.isSigninFormSubmitted = true
      return
    }
    // if(this.signinForm.value.emailId == '' && this.signinForm.value.password ==''){
    //   this.commonService.errorToast("email and password  can't empty")
    //   return
    // }else if(this.signinForm.value.emailId == '' || this.signinForm.value.password ==''){
    //   this.signinForm.value.emailId==''?this.commonService.errorToast("email  can't empty"):this.commonService.errorToast("password can't empty")
    //   return
    // }
    // else{
    else {
      let apiReqUrl = ApiEndPoint.login
      let apiReqData = {
        "mobileNumberPId": this.signinForm.value.emailId,
        "password": this.signinForm.value.password,
        typeOfUser: 'INFLUENCER'
      }
      this.commonService.showSpinner()
      this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner()
          // localStorage.setItem('token',res.result)
          localStorage.setItem('userType', res.result.userType)
          localStorage.setItem('token', res.result.token)
          // this.commonService.successToast(res.responseMessage)
          // Swal.fire({
          //   icon: 'success',
          //   text: res.responseMessage,
          // })
          this.authService.isLoggedIn.next(true)
          this.authService.loggedInUserType.next('INFLUENCER')
          this.router.navigate(['homepage']);
        } else {
          this.commonService.hideSpinner();
        }
      }, (err) => {
        console.log(err);
        this.commonService.hideSpinner()
        if (err.responseCode == 404) {
          this.commonService.alertError(err.responseMessage)
        } else if (err.responseCode == 402) {
          this.commonService.alertError(err.responseMessage)
        } else {
          this.commonService.alertError(err.responseMessage)
        }
      })
    }
  }

  navigateToSignup() {
    this.router.navigate(['/influencer-signup'])
  }
  navigateToForgot() {
    this.router.navigate(['/influencer-forgot'])
  }
}
