import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../provider/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
    // window.scroll(0, 0)
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //   // console.log(state.url)
    //   // console.log(this.authService.checkAuthToken())
    //   if (this.authService.checkAuthToken()) {
    //     // console.log("ifff")
    //     this.authService.loggedIn.next('LOGGED_IN')
    //     // this.router.navigate(['/home'])
    //     // return false;
    //   } else {
    //     // console.log("else")
    //     this.authService.loggedIn.next('OTHER')
    //   }
    //   return true;
    // }

    if (this.authService.checkAuthToken()) {
      console.log(this.authService.checkUserTypeLoggedIn())
      // this.authService.loggedIn.next('LOGGED_IN')
      this.authService.isLoggedIn.next(true)
      this.authService.loggedInUserType.next(this.authService.checkUserTypeLoggedIn())
      // this.router.navigate(['/homepage'])
      window.scroll(0, 0)
      // return true;
    } else {
      this.authService.isLoggedIn.next(false)
      // this.authService.loggedInUserType.next(this.authService.checkUserTypeLoggedIn())
      // this.authService.loggedIn.next('LOGGED_OUT')
      window.scroll(0, 0)
      // return true;
    }
    return true;
  }
} 
