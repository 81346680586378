import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegEx } from 'src/app/config/reg-ex';
import { AuthService } from 'src/app/provider/auth.service';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss']
})
export class ClientInformationComponent implements OnInit {
  clientInfoForm: FormGroup
  isSubmitted: boolean = false
  appoinmentDate: any
  slotTime: any;
  // emailDisable: boolean = false;
  isReadonly: any = false;
  constructor(private router: Router, private activateRoute: ActivatedRoute, public authService: AuthService, public commonService: CommonService) {
    this.activateRoute.queryParams.subscribe((res) => {
      this.appoinmentDate = res.appoinmentDate
      this.slotTime = res.slotTime
    })
  }

  async ngOnInit() {
    this.formValidation();
    if (this.authService.checkAuthToken()) {
      this.isReadonly = true;
      console.log(this.commonService.tokenInfo)
      let email = await this.commonService.tokenInfo.email
      console.log(email);

      this.clientInfoForm.get('email').setValue(email)
      // this.clientInfoForm.get('email').disable()
      // this.emailDisable = true
      // this.clientInfoForm.controls['email'].disable()
    }
    // if (localStorage.getItem('email')) {
    //   this.informationForm.patchValue({
    //     'email': localStorage.getItem('email')
    //   })
    //   this.informationForm.controls['email'].disable()
    // }
  }
  formValidation() {
    this.clientInfoForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z]*$/), Validators.minLength(2)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2)]),
      email: new FormControl("", [
        Validators.required,

        Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/)
      ]),
      phone: new FormControl('', Validators.pattern(RegEx.phoneNo)),
      zipCode: new FormControl('', [Validators.required, Validators.pattern(RegEx.internationalZipCode)]),
    })
  }
  preConsulting: any = false
  consultingType: any

  navigateToEventDetails() {

    if (this.clientInfoForm.invalid) {
      this.isSubmitted = true
      return
    }
    console.log(this.appoinmentDate)
    const dateStr = '2022-07-21';

    const date = new Date(dateStr);

    const iso = date.toISOString();
    console.log(iso);
    const data = {
      // "appointmentDate": new Date(this.appoinmentDate).toString(),
      "appointmentDate": new Date(this.appoinmentDate).toISOString(),
      "slot": this.slotTime,
      "clientName": this.clientInfoForm.value.firstName + " " + this.clientInfoForm.value.lastName,
      "email": this.clientInfoForm.value.email,
      "countryCode": "+91",
      "mobileNumber": String(this.clientInfoForm.value.phone),
      "zipCode": this.clientInfoForm.value.zipCode,
      "preConsulting": this.preConsulting,
      "consultingType": this.consultingType,
    }
    localStorage.setItem('appointmentData', JSON.stringify(data))
    this.router.navigateByUrl('/event-detail')
  }

}
