<!-- signin Modal -->
<div class="wrraper back-pic">
    <div class="login_top_details">
  
      <mat-card class="mat-elevation-z0 bdr">
          <div class="main">
              <div class="top-text text-center">
  
                  <mat-card-title class="sign-text new-style">OTP VERIFICATION
                  </mat-card-title>
                  <p class="text-center textAfterloginHeading header-text">Verify Your Email</p>
                  <!-- <p class="text-center textAfterloginHeading"> address.</p> -->
              </div>
              <div class="input-field">
                  <form [formGroup]="otpForm">
                      <!-- email Field -->
                      <!-- <ngx-otp-input class="otp" [config]="otpInputConfig" (onInputChange)="onOtpChange($event)"></ngx-otp-input> -->
                      <ng-otp-input class="otp" (onInputChange)="onOtpChange($event)" [config]="{length:6,allowNumbersOnly:true}" style="background:transparent ; color: #fff;" >
                      </ng-otp-input>
                        <span class="timestamp-data">   <p (click)="resendOtp()" class="resendOtp">Resend OTP</p ><p class="mt-10">{{time == '1.59' || time == '0.59' ? '' : time }}</p></span>
                        <!-- button -->
                      <div class="down-button">
                          <button mat-button class="buttonGlobal col-md-7"  (click)="navigateToResetPassword()">CONFIRM
                              OTP</button>
                      </div>
                  </form>
              </div>
          </div>
      </mat-card>
      </div>
      
  </div>