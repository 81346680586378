import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService, CountryCode } from 'src/app/provider/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password-retailer',
  templateUrl: './forgot-password-retailer.component.html',
  styleUrls: ['./forgot-password-retailer.component.scss']
})
export class ForgotPasswordRetailerComponent implements OnInit {
  forgotForm: FormGroup;
  countryList: any[];
  filteredOptions: Observable<CountryCode[]>;
  myControl = new FormControl('');
  currentUrl: string;
  isSubmitTrue:boolean = false
  constructor(public commonService: CommonService, private router:Router) { }

  ngOnInit(): void {
    this.formValidation()
  }

  formValidation(){
  this.forgotForm = new FormGroup({
    email: new FormControl("", [
      Validators.required
      
    ]),

  });
  this.countryList = this.commonService.countryListJson;
  this.filteredOptions = this.myControl.valueChanges.pipe(
    startWith(''),
    map(value => (value ? this._filter(value) : this.countryList.slice())),
  );
 
}
private _filter(value: string): CountryCode[] {
  const filterValue = value.toLowerCase();    
  return this.countryList.filter(option => option.code.toLowerCase().includes(filterValue));
}


sendOtp() {
  if(this.forgotForm.invalid){
    this.isSubmitTrue = true
    return
  }
  let apiReqUrl= ApiEndPoint.forgotPassword
  var data = {
    email: this.forgotForm.value.email
  }
  localStorage.setItem('email', data['email'])
  this.commonService.showSpinner()
  this.commonService.postApi(apiReqUrl, data, 0).subscribe((res:any) => {
    console.log(res);
    this.commonService.hideSpinner()
    if (res['responseCode'] == 200) {
      // set current url to localstorage 
      this.currentUrl = this.router.url;
      localStorage.setItem('currentUrl', this.currentUrl)
      // after seting current path to localstorage
      Swal.fire({
        icon: 'success',
        text: res.responseMessage,
      })      
      this.router.navigate(['/retailer-otp'])

    }else{
      this.commonService.hideSpinner();
      this.commonService.successToast(res['responseMessage']);

    }

  },(err)=>{
    console.log(err);
    this.commonService.hideSpinner()
    if(err.responseCode == 404){
      this.commonService.alertError(err.responseMessage)
    }
  })
}
}
