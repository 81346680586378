<div class="wrapper">
    <!-- fifth section image section -->
    <div class="fifth-details">

        <div class="">
            <div class="row">
                <figure class="fifth-sect-img">
                    <img class="fifth-first-img-show" src="assets/images/Shoot_1/Motee_Maids_Pink.jpg" alt="">
                    <div class="hero__inner-sec responsive-condition">
                        <div class="hero_upper-sec">
                            <span class="mega-subtitle-sec header-text">
                                <em>Motee Maids</em>
                            </span>
                            <h1 class="header-text">dress code</h1>
                            <div class="hero_inn-sec ">
                                <p class="header-text">"Simple, all-around chic designs make every bridal brunch, shower
                                    and party someting to celebrate. Selected styles now available in black and navy."
                                </p>
                            </div>

                        </div>
                    </div>
                </figure>

            </div>
        </div>

    </div>
    <!-- start toolbar -->

    <!-- end toolbar -->

    <div *ngIf="sidenavMode == 'over'" class="filter-align-section fixedElement">
        <!-- <div class="filter-align-section fixedElement"> -->
        <i class="fa fa-filter" aria-hidden="true" (click)="sidenav.toggle()"></i>
    </div>
    <mat-sidenav-container class="h-100 w-100 filter-align">
        <!-- <div class="title">
            <h2 class="titleText">Local</h2>
            <i class="fa fa-filter" aria-hidden="true" (click)="sidenav.toggle()"></i>
        </div> -->
        <!-- sidebar start -->
        <!-- <mat-sidenav mode="side" opened class="w-20"> -->
        <mat-sidenav #sidenav [mode]="sidenavMode" [opened]="sidenavModeOpened" class="sidenav-section">
            <div class="d-flex justify-content-between align-items-center p-2">
                <h3 class="m-0 font-weight-bold">Filter By</h3>
                <!-- swateches and color only price and size not implemented -->
                <h3 *ngIf="swatchesId.length || colorId.length || sizeSelected.length || priceId"
                    class="m-0 font-weight-bold clear-filter" (click)="clearFilter()">Clear
                    Filter</h3>
            </div>
            <mat-divider></mat-divider>

            <div class="col-md-12">
                <!-- <div class="color mt-2">
                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    FABRIC
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="header-text">
                                <div *ngFor="let item of swatchesListArray" class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [id]="item._id"
                                        [(ngModel)]="item.isSelected" (click)="selectSwatches(item)">
                                    <label class="custom-control-label" for="customCheck1">{{item?.fabricName}}</label>
                                </div>
                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>

                </div> -->
                <div class="color mt-3">
                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    PRICE
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="header-text">
                                <div *ngFor="let item of priceListArray; let last = last;"
                                    class=" custom-checkbox" >
                                    <input type="checkbox"  [id]="item._id" class="checkbox1"
                                        [(ngModel)]="item.isSelected" (change)="selectPrice(item)"  [checked]="item.isSelected">
                                    <!-- <label *ngIf="!last" class="custom-control-label" for="c1">${{item?.minAmount}} -
                                        ${{item?.maxAmount}}</label>
                                    <label *ngIf="last" class="custom-control-label" for="c1">Over
                                        ${{item?.minAmount}}</label> -->
                                    <label *ngIf="!last" class="custom-control-label"
                                        for="c1">{{ipDetails?.geoplugin_currencySymbol}}{{item?.minAmount |
                                        shortNumber}} - {{ipDetails?.geoplugin_currencySymbol}}{{item?.maxAmount |
                                        shortNumber}}</label>
                                    <label *ngIf="last" class="custom-control-label" for="c1">Over
                                        {{ipDetails?.geoplugin_currencySymbol}}{{item?.minAmount |
                                        shortNumber}}</label>
                                </div>
                                <!-- <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c1">
                                    <label class="custom-control-label" for="c1">$100 - $300</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c2">
                                    <label class="custom-control-label" for="c2"> $300 - $500</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c3">
                                    <label class="custom-control-label" for="c3">$500 - $800</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c4">
                                    <label class="custom-control-label" for="c4">$800 - $1500</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c5">
                                    <label class="custom-control-label" for="c5">$1500 - $3000</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c6">
                                    <label class="custom-control-label" for="c6">$3000 - $8000</label>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="c7">
                                    <label class="custom-control-label" for="c7">Over $8000</label>
                                </div> -->
                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
                <div class="color mt-3">
                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    COLOUR
                                </mat-panel-title>

                            </mat-expansion-panel-header>

                            <div class="header-text">

                                <!-- <div class="p-2 d-flex justify-content-between">
                                    <ng-container *ngFor="let item of colorListArray">
                                  
                                        <div class="color-block-circle" [ngClass]="{'color-block-circle-active': item.isSelected}" [style.background-color]="item?.colorCode"
                                            (click)="selectColor(item)"></div>
                                    </ng-container>

                                </div> -->
                                <div class=" row p-2">
                                    <ng-container *ngFor="let item of colorListArray">
                                        <div class="col-md-2">
                                            <div class="color-block-circle "
                                                [ngClass]="{'color-block-circle-active': item.isSelected}"
                                                [style.background-color]="item?.colorCode" (click)="selectColor(item)">


                                            </div>
                                        </div>
                                    </ng-container>

                                </div>
                            </div>

                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
                <div class="color mt-3 ">

                    <mat-accordion>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            [expanded]="step === 0" (opened)="setStep(0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    SIZE
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="header-text">
                                <!-- <select class="form-control form-control-sm">
                                    <label for="">Body Size</label>
                                    <option selected>Select Size</option>
                                    <option *ngFor="let item of countNumber1to34" [value]="item">{{item}}</option>
                                </select> -->
                                <div *ngFor="let item of countNumber1to34" class="  custom-checkbox">
                                    <input type="checkbox"  [id]="item.count" class="checkbox1"
                                        [(ngModel)]="item.isSelected" (change)="selectSizes(item)"  [checked]="item.isSelected">
                                    <label class="custom-control-label " for="customCheck1">{{item?.count}}</label>
                                </div>
                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
            </div>
        </mat-sidenav>
        <!-- sidebar end -->
        <mat-sidenav-content class=" pt-5">

            <!-- images list container -->
            <div class="new-bridal-cls">
                <div class="d-flex  header-c">
                    <!-- <h1 class="header-text title_bridal">
                        Bridal
                    </h1> -->
                    <p class="header-text">
                        Simply iconic. The true namesake collection, Motee Maids Bridal is simply modern, highlighted by
                        signature couture design techniques and hand-touched by the master seamstresses at our New York
                        atelier.
                        <!-- <strong>See the collection on you with Motee Maids Virtual Try-On</strong> -->
                    </p>
                </div>
                <div class="row reveal">
                    <ng-container *ngIf="productListArray.length && !isSkeleton">
                        <div *ngFor="let item of productListArray" class="fifth-img col-md-4"
                            [ngClass]="item?.productImage?.backImage?.webImage ? 'fifth-img-ex':''">
                            <div class="imageBox">
                                <img class="fifth-img-show" [src]="item?.productImage?.frontImage?.webImage" alt=""
                                    (click)="navigateToViewMotherDress(item?._id)">
                                <img class="fifth-img-hide" [src]="item?.productImage?.backImage?.webImage" alt=""
                                    (click)="navigateToViewMotherDress(item?._id)">
                                <!-- <img class="fifth-img-show" [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage"
                                alt="" (click)="navigateToViewMotherDress(item?._id)">
                            <img class=" fifth-img-hide" [src]="item?.productDetails[0]?.image[1]?.imageUrl?.webImage"
                                alt="" (click)="navigateToViewMotherDress(item?._id)"> -->
                            </div>

                            <div class="top">
                                <div class="d-flex justify-content-between align-items-center new-like">
                                    <h4 class="text-left pc-p header-text m-0">{{item?.productName}}</h4>
                                    <i [ngClass]="item?.isLike?'fa fa-heart ct':'fa fa-heart-o'"
                                        (click)="addProductWishlist(item?._id)"></i>
                                </div>
                                <div class="text-left pric">
                                    <h4 class="m-0 header-text">
                                        <!-- ${{item?.productPrice[0]?.MRP}}.00 -->
                                        <!-- {{ipDetails?.geoplugin_currencySymbol}}{{item?.countryPrice?.totalMrp}}.00 -->
                                        {{item?.countryPrice?.totalMrp | currency:
                                        ipDetails?.geoplugin_currencySymbol}}
                                    </h4>
                                    <div class="ic d-flex">
                                        <ng-container *ngFor="let color of item.colorDetails">
                                            <div class="image-custom-list" [style.background-color]="color?.colorCode">
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- product skeleton -->
                    <ng-container *ngIf="!productListArray.length && isSkeleton">
                        <div class="skeleton skeleton-section col-xl-4 col-lg-4 col-md-4 col-sm-2"
                            *ngFor="let item of generateFake(6)">
                            <div class="item-wrap">
                                <div class="item-img">
                                    <ngx-skeleton-loader animation="progress" [theme]="skeletonImageTheme">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!--  if no product found  -->
                    <div class="noPostVailableDiv reveal" *ngIf="!productListArray.length && !isSkeleton"
                        style="text-align: center;margin: auto;">
                        <div class="">
                            <img src="/assets/images/no-data-found.png" alt="img" class="animate" style="width: 50%;">
                        </div>
                    </div>
                </div>

            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>