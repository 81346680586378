import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  type: any
  homepageResultArray: any = [];
  subscribeForm: FormGroup
  subscribeUserForm: FormGroup
  isSubscribeUserFormtrue: boolean = false
  isSubscribeTrue: boolean = false
  constructor(public commonService: CommonService, private activatedroute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.activatedroute.queryParams.subscribe((res) => {
      this.type = "HOMEPAGE";
    });
    this.formValidation();
    this.userFormValidation();
    this.logo()
  }

  formValidation() {
    // Form group and Form Control Name With Validations
    this.subscribeForm = new FormGroup({
      email: new FormControl("", [Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/)]),
    });
  };

  userFormValidation() {
    this.subscribeUserForm = new FormGroup({
      email: new FormControl("", [Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/)]),
    });
  }

  /**
   * userType are used in website
   * @param logo -> normal user comes to buy product
   */
  logo() {
    let apiReqUrl = 'static/viewHomePage'

    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.homepageResultArray = res.result
        this.commonService.hideSpinner()

      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);

      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }

  isSubscribedFormSubmit: boolean = false
  /**
   * @param subscribeUser function is used to subscribe by user
   * @param apiReqUrl contains end point url subscribe
   * @param apiReqData contains request data i.e sent at the time of subscribe 
   */
  subscribeUser() {
    if (!this.subscribeForm.value.email && !this.subscribeUserForm.value.email) {
      return this.commonService.infoToast('Please provide email address you wish to subscribe.')
    } else if (this.subscribeUserForm.invalid) {
      return this.isSubscribedFormSubmit = true;
    } else {
      let apiReqUrl = `${'admin/addBroadcast'}`
      let apiReqData = {
        'email': this.subscribeForm.value.email || this.subscribeUserForm.value.email
      }
      this.commonService.showSpinner()
      this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
        if (res['responseCode'] == '200') {
          // this.homepageResultArray = res.result[0]
          // this.logo()
          this.subscribeForm.reset();
          this.subscribeUserForm.reset();
          this.commonService.hideSpinner()
          this.commonService.successToast(res.responseMessage)
        }
      }, (err: any) => {
        this.commonService.hideSpinner();
        if (err['responseCode'] == '401') {
          this.commonService.errorToast(err['responseMessage']);

        } else {
          this.commonService.errorToast(err.responseMessage);
        }
      })
    }
  }

  navigateToLatestMoteeCollection() {
    this.router.navigate(['/latest-motee-collection'])
  }

}
