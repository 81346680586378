<div class="wrapper">
    <div class="d-wr">
        <div class="first_top_text">

            <!-- <div class="w-50">
                <figure class="imgs-fig">
                    <img src="/assets/images/Fully_Beaded_Collection_Shoot_2/motee-maid-x-You-ok_528x.gif" alt="">
                </figure>
            </div> -->
            <div class="grid-item grid__item small--one-whole three-fifths">
                <div class="main-content-container">
                    <div class="top-title">
                        <h4 class="top-title">Uniquely You</h4>
                        <div class="logo">
                            <img class="logoImage" src="../../../../assets/images/motee-maids-log.png"
                                alt="motee maid"><span class="header-text logo-text"><strong>X You</strong></span>
                        </div>
                    </div>
                    <div class="text-content">
                        <p class="header-text">
                            We want to collaborate with you to create not just the perfect dress, but the perfect dress
                            for you. With Motee maids's library of silhouettes, styles and fabrics to choose from, your
                            dream dress is about to become your reality.
                        </p>
                        <!-- <p class="header-text">Starting price $ 4,850.</p> -->
                        <p class="header-text">Starting price {{startingPrice | currency:
                            ipDetails?.geoplugin_currencySymbol}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="data second_top_text"> -->
        <div class="row">
            <div class="col-md-3" *ngFor="let item of collectionCategoryArray">
                <img class="four_img_list" [src]="item?.image?.webImage || 'assets/images/placeholder-image.png'" alt=""
                    (click)="selectCategory(item._id)">
                <p class="text_images_para">{{item?.collectionName}}</p>
            </div>
        </div>
        <!-- </div> -->
        <!-- <hr class="site-footer__hr remove-top-margin"> -->
        <!-- <div class="second_top_text">
            <h1 class="text-center">Start Creating Your collection</h1>
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let item of collectionCategoryArray">
                    <img class="four_img_list" [src]="item?.image?.webImage" alt="" (click)="selectCategory(item._id)">
                    <p class="text_images_para">{{item?.collectionName}}</p>
                </ng-template>
              </owl-carousel-o>
        </div> -->
        <!-- <hr> -->
        <!-- <div class="second_top_text mt-2">
         
        </div> -->

    </div>
</div>