<!-- to open add to blog modal -->
<button data-toggle="modal" data-target="#staticBackdrop">Add to Bag</button>

<!-- Add to Blog Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h2 class="modal-title" id="staticBackdropLabel">The item has been successfully added:</h2>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
          <div>
              <i class="fa fa-times bg-dark text-light" data-dismiss="modal" aria-label="Close"></i>
          </div>
        </div>
        <div class="modal-body p-1">
            <div class="d-flex justify-content-between">
                <div class="w-50">
                    <!-- <div class=""> -->
                        <img src="" alt="" class="w-100 h-100">
                    <!-- </div> -->
                </div>
                <div class="w-50 p-2">
                    <div>
                        <h4 class="mb-1">Lorem ipsum dolor sit amet consectetur adipiscing elit Custom Dress</h4>
                        <h4 class="m-0">$5559.95</h4>
                        <div class="">
                            <div class="d-flex">
                                <label for="" class="mb-1">color</label><span>:</span><p class="mb-1">Mink</p>
                            </div>
                            <div class="d-flex">
                                <label for="" class="mb-1">size</label><span>:</span><p class="mb-1">12</p>
                            </div>
                            <div class="d-flex">
                                <label for="" class="mb-1">QTY</label><span>:</span><p class="mb-1">1</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button type="button" class="btn btn-primary col-md-6" data-dismiss="modal">Continue Shopping</button>
          <button type="button" class="btn btn-danger col-md-4" data-dismiss="modal">Checkout</button>
        </div>
      </div>
    </div>
  </div>