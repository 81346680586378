<div class="wrapper">
    <div class="top-text">
        <h1 class="text-center header-text">Bridal Party</h1>
    </div>
    <!-- first section  -->
    <div class="container mt-4">
        <div class="row data-top">
            <div class="first-img" routerLink="/readytoship-bridal" *ngFor="let item of productListArray">
                <img class="first-img-show" [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt="">

                <div class="middle">
                    <div class="text header-text">SHOP NOW</div>
                  </div>
                <h1 class="text-left header-text">{{item?.productName}}</h1>
                <h4 class="text-left header-text">{{item?.description}}</h4>
            </div>
        </div>
    </div>