<div class="content">
    <div class="row">
        <div class="col-md-6">
            <div *ngIf="!editProfileStatus" class="">

                <mat-card class="mat-elevation-z0">

                    <!-- <p class=" header-text"> Profile Details</p> -->
                    <div class="top-text text-center">
                        <mat-card-title class="mat-card-title sign-text ml-0 title-color-dark">Profile Details
                        </mat-card-title>
                    </div>

                    <div>
                        <div class="profile-details-content">
                            <div class="row  header-text" style="text-align: center; margin-right: 20px;">
                                <img src="{{userProfileArray?.usersProfilePic?.webImage || 'assets/images/profile-img.jpg' }}"
                                    style="height: 100px;width: 100px; border-radius: 50%; border: 1px solid black!important;"
                                    alt="profile image">
                            </div>
                        </div>
                        <div class="profile-details-content">
                            <div class="details row  header-text">
                                <label class="font-weight-custom col-md-3">Full Name</label><span
                                    class="mol-md-1">:</span><span class="col-md-4"> {{userProfileArray?.firstName}}
                                    {{userProfileArray?.lastName}}</span>
                            </div>
                        </div>
                        <div class="profile-details-content  header-text">
                            <div class="details">
                                <span class="font-weight-custom col-md-3">Mobile Number</span><span
                                    class="mol-md-1">:</span><span class="col-md-4">{{userProfileArray?.countryCode}}
                                    {{userProfileArray?.mobileNumber}}</span>
                            </div>
                        </div>
                        <div class="profile-details-content header-text">
                            <div class="details">
                                <span class="font-weight-custom col-md-3">Email ID </span><span
                                    class="mol-md-1">:</span><span class="col-md-4"> {{userProfileArray?.email}}</span>
                            </div>
                        </div>

                        <!-- <div class="profile-details-content header-text">
                            <div class="details">
                                <span class="font-weight-custom col-md-4">Date of Birth </span><span class="mol-md-1">:</span><span class="col-md-4"> 11/11/1999</span>
                            </div>
                        </div> -->
                        <div class="profile-details-content header-text">
                            <div class="details" *ngIf="userProfileArray?.userType != 'CUSTOMER'">
                                <span class="font-weight-custom col-md-4">Location </span><span
                                    class="mol-md-1">:</span><span class="col-md-4"> {{countryNameList}}</span>
                            </div>
                        </div>
                        <div *ngIf="loggedInUserType == 'RETAILER'" class="profile-details-content header-text">
                            <div class="details">
                                <span class="font-weight-custom col-md-4">Show Price </span><span
                                    class="mol-md-1">:</span><span class="col-md-4">{{userProfileArray?.showPrice ==
                                    true ? 'YES' : 'NO'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center ml-4 mt-3">
                        <button mat-raised-button color="accent" (click)="editProfileSubmit()">Edit Profile</button>
                        <button mat-raised-button color="accent" class="ml-3" style="margin-right: 20px;"
                            (click)="changePassword()">Change
                            Password</button>

                    </div>
                </mat-card>




            </div>

            <!-- edit profile -->
            <div *ngIf="editProfileStatus" class="container">
                <form [formGroup]="editAddressForm">
                    <div class="contact-edit">
                        <!-- <mat-toolbar class="mat-toolbar-custom1 header-text">
                            <span>Edit Profile Details</span>
                        </mat-toolbar> -->
                        <div class="top-text text-center">
                            <mat-card-title class="mat-card-title sign-text ml-0 title-color-dark">Edit Profile Details
                            </mat-card-title>
                        </div>
                        <div class="user-profile">
                            <div class="image-box">
                                <img *ngIf="imageUrl" src="{{imageUrl.webImage || 'assets/images/profile-img.jpg' }}"
                                    id="sub-admin-image" alt="">
                                <label class="upload-label">
                                    <input type="file" formControlName="image" name="image"
                                        accept="image/jpg,image/jpeg,image/png" id="image" (change)="uploadImg($event)"
                                        maxlength="56">
                                    <em class="fa fa-pencil" style="color: #86559f!important;font-size: 12px"></em>
                                </label>
                            </div>
                        </div>
                        <div class="pl-4 pt-4">
                            <div class="row">
                                <div class="col-md-3 d-flex align-items-end">
                                    <p class="header-text">First Name :</p>
                                </div>
                                <div class="col-md-9">
                                    <input type="text" placeholder="First Name" class="form-control"
                                        formControlName="firstName" maxlength="60">
                                    <div class="text-danger"
                                        *ngIf="(editAddressForm.get('firstName').invalid && (editAddressForm.get('firstName').touched || editAddressForm.get('firstName').dirty || isFormSubmitted));">
                                        <span *ngIf="editAddressForm.get('firstName').errors.required">
                                            *First name is required.
                                        </span>
                                        <span *ngIf="editAddressForm.get('firstName').errors.pattern">
                                            *Please enter valid first name.
                                        </span>
                                    </div>
                                    <!-- <span class="error"
                                        *ngIf="editAddressForm.get('firstName').hasError('required') && (editAddressForm.get('firstName').touched ||editAddressForm.get('firstName').dirty)">
                                        *First name is required.</span>
                                    <span class="error"
                                        *ngIf="editAddressForm.get('firstName').hasError('pattern') && (editAddressForm.get('firstName').touched || editAddressForm.get('firstName').dirty)">
                                        *Please enter valid first name.</span>
                                    <span class="error"
                                        *ngIf="editAddressForm.get('firstName').hasError('minlength') && editAddressForm.get('firstName').dirty">
                                        *Minimum length must be 2.
                                    </span> -->

                                </div>
                            </div>

                        </div>

                        <div class="pl-4 pt-4">
                            <div class="row">
                                <div class="col-md-3 d-flex align-items-end">
                                    <p class="header-text">Last Name :</p>
                                </div>
                                <div class="col-md-9">
                                    <input type="text" class="form-control" placeholder="Last Name"
                                        formControlName="lastName" maxlength="60">
                                    <div class="text-danger"
                                        *ngIf="(editAddressForm.get('lastName').invalid && (editAddressForm.get('lastName').touched || editAddressForm.get('lastName').dirty || isFormSubmitted));">
                                        <span *ngIf="editAddressForm.get('lastName').errors.required">
                                            *Last name is required.
                                        </span>
                                        <span *ngIf="editAddressForm.get('lastName').errors.pattern">
                                            *Please enter valid last name.
                                        </span>
                                    </div>
                                    <!-- <span class="error"
                                        *ngIf="editAddressForm.get('lastName').hasError('required') && (editAddressForm.get('lastName').touched ||editAddressForm.get('lastName').dirty)">
                                        *Last name is required.</span>
                                    <span class="error"
                                        *ngIf="editAddressForm.get('lastName').hasError('pattern') && (editAddressForm.get('lastName').touched || editAddressForm.get('lastName').dirty)">
                                        *Please enter valid last name.</span>
                                    <span class="error"
                                        *ngIf="editAddressForm.get('lastName').hasError('minlength') && editAddressForm.get('lastName').dirty">
                                        *Minimum length must be 2.
                                    </span> -->
                                </div>
                            </div>
                        </div>

                        <div class="pl-4 pt-4">
                            <div class="row">
                                <div class="col-md-3 d-flex align-items-end">
                                    <p class="header-text">Email :</p>
                                </div>
                                <div class="col-md-9">
                                    <input type="text" class="form-control" placeholder="Email" formControlName="email"
                                        maxlength="256" disabled>
                                    <div class="text-danger"
                                        *ngIf="(editAddressForm.get('email').invalid && (editAddressForm.get('email').touched || editAddressForm.get('email').dirty || isFormSubmitted));">
                                        <span *ngIf="editAddressForm.get('email').errors.required">
                                            *Email address is required.
                                        </span>
                                        <span *ngIf="editAddressForm.get('email').errors.pattern">
                                            *Please enter a valid email address.
                                        </span>
                                    </div>
                                    <!-- <span class="error"
                                        *ngIf="editAddressForm.get('email').hasError('required') && (editAddressForm.get('email').touched ||editAddressForm.get('email').dirty)">
                                        *Email is required.</span>
                                    <span class="error" *ngIf="editAddressForm.get('email').hasError('pattern')">*Please
                                        enter valid email.</span> -->
                                </div>
                            </div>

                        </div>
                        <div class="pl-4 pt-4">
                            <div class="row">
                                <div class="col-md-3 d-flex align-items-end">
                                    <p class="header-text">Mobile Number :</p>
                                </div>
                                <div class="col-md-9 d-flex">

                                    <input type="text" aria-label="Country" class="form-control col-md-2 num"
                                        [formControl]="myControl" maxlength="7"
                                        (keypress)="commonService.numberOnly($event)" [matAutocomplete]="auto" />
                                    <mat-autocomplete #auto="matAutocomplete" class="contr">
                                        <mat-option *ngFor="let option of filteredOptions | async"
                                            [value]="option.code || option.name">
                                            <span style="display: flex; justify-content: space-between">
                                                <!-- <p>{{ option.name }}</p> -->
                                                <p>{{ option.code }}</p>
                                            </span>
                                        </mat-option>
                                    </mat-autocomplete>
                                    <!-- <label class="top_heading_lab mt-0 header-text">Mobile Number (Optional)</label> -->

                                    <input type="text" class="form-control col-md-10 num" autocomplete="new-password"
                                        placeholder="Enter Mobile Number" formControlName="mobileNumber" maxlength="15"
                                        (keypress)="commonService.numberOnly($event)" />
                                    <div class="text-danger"
                                        *ngIf="(editAddressForm.get('mobileNumber').invalid && (editAddressForm.get('mobileNumber').touched || editAddressForm.get('mobileNumber').dirty));">
                                        <!-- <span *ngIf="editAddressForm.get('mobileNumber').errors.required || isFormSubmitted">
                                                            *Mobile Number address is required
                                                          </span> -->
                                        <span *ngIf="editAddressForm.get('mobileNumber').errors.pattern">
                                            *Please enter valid mobile number.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="loggedInUserType == 'RETAILER'" class="pl-4 pt-4">
                            <div class="row">
                                <div class="col-md-3 d-flex align-items-end">
                                    <p class="header-text">Show Price :</p>
                                </div>
                                <div class="col-md-9">
                                    <div class="custom-control custom-switch" style="padding-left: 2.25rem;">
                                        <input type="checkbox" formControlName="showPrice"
                                            class="custom-control-input form-control" id="customSwitch1">
                                        <label class="custom-control-label" for="customSwitch1"></label>
                                    </div>

                                </div>
                            </div>

                        </div>


                        <div class="d-flex justify-content-center align-items-center p-3 btns">
                            <!-- <span class="mr-2">
                                <button mat-raised-button color="accent" >Change Password</button>
                            </span> -->
                            <span class="mr-2">
                                <button mat-raised-button color="accent" (click)="editProfile()"
                                    [disabled]="!editAddressForm.valid">Update</button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            <!-- end -->
        </div>
        <div class="col-md-6">

            <div *ngIf="changePasswordStatus">

                <!-- signin Modal -->
                <!-- <div class="wrraper back-pic">
                    <div class="login_top_details"> -->

                <mat-card *ngIf="changePasswordCard" class="mat-elevation-z0">
                    <div class="main">
                        <!-- <div class="top-text text-center">
                            <mat-card-title class="sign-text">Create New Pasword</mat-card-title>
                        </div> -->
                        <div class="top-text text-center">
                            <mat-card-title class="mat-card-title sign-text ml-0 title-color-dark">Create New Password
                            </mat-card-title>
                        </div>
                        <div class="input-field">
                            <form [formGroup]="resetPasswordForm">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="" class="top_heading_lab">Enter Your Old Password :</label>
                                        <input type="text" class="user-name" placeholder="Enter old password"
                                            formControlName='oldPassword' maxlength="60"
                                            [type]="toggleEye2 ? 'password' : 'text'">
                                        <!-- eye Password Protector -->
                                        <mat-icon matSuffix (click)="toggleEye2 = !toggleEye2">
                                            {{toggleEye2 ? 'visibility_off' : 'visibility'}}
                                        </mat-icon>
                                        <!-- validation -->
                                        <div class="error pl"
                                            *ngIf="resetPasswordForm.get('oldPassword').hasError('required') && (resetPasswordForm.get('oldPassword').touched ||resetPasswordForm.get('oldPassword').dirty || isSubmittedForm)">
                                            *Old password is required.</div>
                                        <!-- <span class="error pl" *ngIf="resetPasswordForm.get('newPassword').hasError('pattern') && (resetPasswordForm.get('newPassword').touched||resetPasswordForm.get('newPassword').dirty)">
                                            *Password should have minimum 8 characters, at least 1 uppercase letter, 1
                                            lowercase letter, 1
                                            number and 1 special character.</span> -->
                                    </div>
                                    <!-- email Field -->
                                    <div class="col-md-12">
                                        <label for="" class="top_heading_lab">Enter Your New Password :</label>
                                        <input type="text" class="user-name" placeholder="Enter new password"
                                            formControlName='newPassword' maxlength="60"
                                            [type]="toggleEye1 ? 'password' : 'text'">
                                        <!-- eye Password Protector -->
                                        <mat-icon matSuffix (click)="toggleEye1 = !toggleEye1">
                                            {{toggleEye1 ? 'visibility_off' : 'visibility'}}
                                        </mat-icon>
                                        <!-- validation -->
                                        <div class="error pl"
                                            *ngIf="resetPasswordForm.get('newPassword').hasError('required') && (resetPasswordForm.get('newPassword').touched ||resetPasswordForm.get('newPassword').dirty || isSubmittedForm)">
                                            *Password is required.</div>
                                        <div class="error pl"
                                            *ngIf="resetPasswordForm.get('newPassword').hasError('pattern') && (resetPasswordForm.get('newPassword').touched||resetPasswordForm.get('newPassword').dirty)">
                                            *Password should have minimum 8 characters, at least 1 uppercase letter, 1
                                            lowercase letter, 1
                                            number and 1 special character.</div>
                                    </div>
                                    <div class="col-md-12">
                                        <!-- Password Field -->
                                        <label for="" class="top_heading_lab">Re-Enter New Password :
                                        </label>
                                        <input type="password" class="user-name"
                                            [type]="toggleEye ? 'password' : 'text'" placeholder="Re-enter password"
                                            formControlName='confirmPassword' maxlength="32">
                                        <!-- eye Password Protector -->
                                        <mat-icon matSuffix (click)="toggleEye = !toggleEye">
                                            {{toggleEye ? 'visibility_off' : 'visibility'}}
                                        </mat-icon>
                                        <!-- validation -->
                                        <div class="error pl"
                                            *ngIf="(resetPasswordForm.controls['newPassword'].value!==(resetPasswordForm.controls['confirmPassword'].value) && (resetPasswordForm.get('confirmPassword').touched || resetPasswordForm.get('confirmPassword').dirty))">
                                            *Password
                                            and confirm password should be match.</div>
                                    </div>
                                    <!-- button -->
                                </div>
                                <div class="down-button">
                                    <!-- <button mat-button class="buttonGlobal col-md-7" (click)="toOpenResetPasswordSuccessCard()" [disabled]="!resetPasswordForm.valid">Change Password</button> -->
                                    <button mat-button class="buttonGlobal col-md-7"
                                        (click)="changePasswordUser()">Change Password</button>

                                </div>

                            </form>
                            <!-- <div class="m-5 w-75">
                                        <p class="pl-5"> ©2021–2022 All Rights Reserved. XYZ® is a registered trademark
                                            of The ABCD
                                            Group. Cookie Preferences, Privacy, and Terms.</p>
                                    </div> -->
                        </div>
                    </div>
                </mat-card>
                <!-- Reset password success card -->
                <!-- <mat-card *ngIf="!changePasswordCard" class="w-50 m-auto text-center">
                    <mat-card-title class="color-green">Password reset successfully</mat-card-title>
                    <mat-card-content>Password has been reset successfully!</mat-card-content>
                    <mat-action>
                    </mat-action>
                                   </mat-card> -->

                <!-- </div>
                </div> -->
                <!-- <p class=" header-text"> Event Details</p>
                <div>
                    <div class="profile-details-content  header-text">
                        <div class="details row">
                            <span class="font-weight-custom col-md-4">Full Name</span><span
                                class="mol-md-1">:</span><span class="col-md-4"> lorem ipsum</span>
                        </div>
                    </div>
                    <div class="profile-details-content header-text">
                        <div class="details">
                            <span class="font-weight-custom col-md-4">Mobile Number</span><span
                                class="mol-md-1">:</span><span class="col-md-4"> 1234567890</span>
                        </div>
                    </div>
                    <div class="profile-details-content header-text">
                        <div class="details">
                            <span class="font-weight-custom col-md-4">Email ID </span><span
                                class="mol-md-1">:</span><span class="col-md-4"> Xyz.123@gmail.com</span>
                        </div>
                    </div>

                    <div class="profile-details-content header-text">
                        <div class="details">
                            <span class="font-weight-custom col-md-4">Date of Birth </span><span
                                class="mol-md-1">:</span><span class="col-md-4"> 11/11/1999</span>
                        </div>
                    </div>
                    <div class="profile-details-content header-text">
                        <div class="details">
                            <span class="font-weight-custom col-md-4">Location </span><span
                                class="mol-md-1">:</span><span class="col-md-4"> India</span>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center align-items-center p-3 btns">
                        <span class="mr-2">
                            <button mat-raised-button color="accent" (click)="changePassword()">Update Password</button>
                        </span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>


</div>

<!-- edit profile -->
<!-- <div *ngIf="editProfileStatus" class="container">

    <div class="vl"></div>

    <div class="w-50 contact-edit">
        <mat-toolbar class="mat-toolbar-custom1 header-text">
            <span>Event Details</span>
        </mat-toolbar>
        <div class="pl-4 pt-4">
            <div>
                <p class="header-text">*Type of Event:</p>
                <select name="cars" id="cars">
                    <option value="volvo" selected>Wedding</option>
                    <option value="saab">Prom</option>
                    <option value="opel">Other Special Ocassion</option>
                </select>
                <span class="error"
                    *ngIf="editAddressForm.get('typeOfEvent').hasError('required') && (editAddressForm.get('typeOfEvent').touched ||editAddressForm.get('typeOfEvent').dirty)">
                    *Type of event is required.</span>
            </div>
        </div>

        <div class="pl-4 pt-4">
            <div>
                <p class="header-text">* Role of Event:</p>
                <select name="cars" id="cars">
                    <option value="volvo" selected>Bride/Event Owner</option>
                    <option value="saab">BridesMaids</option>
                    <option value="opel">Mothers of Bride</option>
                </select>
                <span class="error"
                    *ngIf="editAddressForm.get('roleOfEvent').hasError('required') && (editAddressForm.get('roleOfEvent').touched ||editAddressForm.get('roleOfEvent').dirty)">
                    *Role of event is required.</span>
            </div>
        </div>

        <div class="pl-4 pt-4">
            <div>
                <p class="header-text">*Event Date:</p>
                <input type="date" formControlName="eventDate">
                <span class="error"
                    *ngIf="editAddressForm.get('eventDate').hasError('required') && (editAddressForm.get('eventDate').touched ||editAddressForm.get('eventDate').dirty)">
                    *Event date is required.</span>
            </div>
        </div>
    </div>
    </form>
    <div class="d-flex justify-content-center align-items-center p-3 btns">
        <span class="mr-2">
            <button mat-raised-button color="accent">Change Password</button>
        </span>
        <span class="mr-2">
            <button mat-raised-button color="accent" (click)="editProfileSubmit()">SAVE</button>
        </span>
    </div>
</div> -->