<div class="content">
    <div class="top-text text-center">
        <mat-card-title class="mat-card-title sign-text ml-0 new-style">My Coupons</mat-card-title>
    </div>
    <div class="container pt-4">
        <div class="coupons d-flex" >
            <mat-card class="shadow-none clar_ mb-2" *ngFor="let item of couponListArray">
                <div class="row" >
               
                    <div class="left">
                        <h5>UP TO</h5>
                        <h2 class="text-light perc">{{item?.amount || '--'}} {{((item?.discountType == 'PERCENTAGE') ? '%' : '' ) }}</h2>
                        <h4>Discount</h4>
                    </div>
                    <div class="bg-left">
                        <h5 class="text-light">Coupon Code</h5>
                        <h2 class="text-dark code">{{item?.couponCode}}</h2>
                        <img class="coupn" (click)="copyToClipboard(item?.couponCode)" src="assets/images/other/capyicon.jpg" alt="Copy"> 

                        <h4 class="text-light">Get minimum 10% and upto 50% discount on order Rs. 1000.</h4>
                        <h4>T&C apply</h4>
                    </div>
                
                </div>
            </mat-card>
        </div>
    </div>
</div>
<!-- </div> -->