<div class="wrapper">
    <!-- start toolbar -->
    <!-- <mat-toolbar class="toolbar_custom header-text">
    <span>Home/</span><span>Shoping Bag</span>
  </mat-toolbar> -->
    <!-- end toolbar -->
    <!-- shopping bag address and product -->
    <div class="section-header">
        <h3 class="header-text">My Cart ({{totalItems || 0}})</h3>
    </div>
    <div class="row">
        <div class="col-md-9">
            <div class="w-85 w-85-custom mb-3">
                <div class="height-div">
                    <table class="cart">
                        <thead>
                            <tr>
                                <th class="header-text" colspan="2">Product</th>
                                <th class="cart__row-price header-text" scope="col">Price</th>
                                <th class="text-center small--hide header-text" scope="col">Quantity</th>
                                <th class="cart__row-price header-text text-right" scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let item of shoppingBag | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index">
                                <!-- <tr *ngFor="let item of shoppingBag"> -->
                                <td class="cart__image-wrapper">
                                    <div>
                                        <!-- <img [src]="item?.productDetails?.productId?.productDetails[0]?.image[0]?.imageUrl?.webImage" class="cart__image" alt="" (click)="navigateToProductDetails(item?._id)"> -->
                                        <img [src]="item?.productId?.productImage?.frontImage.webImage"
                                            class="cart__image" alt="" (click)="navigateToProductDetails(item?._id)">
                                    </div>
                                </td>
                                <td class="cart__meta small--text-left">
                                    <div class="list-view-item__title">
                                        <a class="header-text">{{item?.productId?.productName}}</a>
                                    </div>
                                    <!-- <div class="cart__meta-text header-text">
                                Body Size : {{item?.productDetails?.size}}
                                <br> Skirt Size : {{item?.productDetails?.size}}
                                <br> Skirt Length : Standard
                            </div> -->
                                    <div class="cart__meta-text header-text">
                                        <div *ngIf="item?.colors" class="color-block-circle-small mr-3 "
                                            [style.background-color]="item?.colors?.colorCode"></div>
                                        <div *ngIf="item?.premiumColorId" class="color-block-circle-small mr-3 "
                                            [style.background-color]="item?.premiumColorId?.colorCode"></div>
                                        <!-- <br> -->
                                        Size : {{item?.size}}
                                        <!-- <br> Skirt Size : {{item?.productDetails?.size}} -->
                                        <br> Length : {{item?.dressLength | titlecase}}
                                    </div>
                                    <p class="small--hide">
                                        <a class="btn btn--small btn--tertiary cart__remove header-text"
                                            (click)="removeProductFromBagApi(item?._id)">Remove</a>
                                    </p>
                                </td>
                                <td class="cart__price-wrapper header-text">
                                    <!-- {{ipDetails?.geoplugin_currencySymbol}}{{item?.productDetails?.productId?.productPrice[0]?.MRP}}.00 -->
                                    {{item?.totalMrp | currency: item?.currencyCode}}

                                </td>
                                <!-- <td class="text-right small--hide"> -->
                                <td class="small--hide">
                                    <div class="cart__qty">
                                        <label for="quantity" class="cart__qty-label"></label>
                                        <!-- <input type="number" class="cart__qty-input header-text" [value]="item?.productDetails?.quantity" min="1" pattern="[0-9]*" data-quantity-item="1" id="quantity" (change)="getAllProductId(item?._id,item?.productDetails?.productId?._id,item?.productDetails?.colors,item?.productDetails?.size,item?.productDetails?.quantity)"> -->

                                        <!-- <input type="number" class="cart__qty-input header-text"
                                            [value]="item?.enteredQuantity" min="1" pattern="[0-9]*"
                                            data-quantity-item="1" id="quantity"
                                            (keydown)="productQuantityChange(index, item?.enteredQuantity)"> -->
                                        <i class="fa fa-minus-square" aria-hidden="true"
                                            (click)="editBagProductQuantity(item?._id,item?.enteredQuantity, 'SUB')"></i>
                                        <!-- <input type="number" class="cart__qty-input header-text"
                                            [value]="item?.enteredQuantity" min="1" pattern="[0-9]*"
                                            data-quantity-item="1" id="quantity"> -->
                                        <span class="cart__qty-input header-text">{{item?.enteredQuantity}}</span>
                                        <i class="fa fa-plus-square" aria-hidden="true"
                                            (click)="editBagProductQuantity(item?._id,item?.enteredQuantity, 'ADD')"></i>

                                    </div>
                                    <!-- {{item?.productDetails?.quantity}}
                                                                                    {{shoppingBagBuffer?.productDetails?.quantity}}
                                                                                    <p *ngIf="item?.productDetails?.quantity == shoppingBagBuffer?.productDetails?.quantity"
                                                                                        class="text-center small--hide">
                                                                                        <a class="btn btn--small btn--tertiary cart__remove header-text">Update</a>
                                                                                    </p> -->
                                </td>
                                <td class="text-right small--hide">
                                    <div class="header-text">
                                        {{(item?.enteredQuantity * item?.totalMrp) | currency: item?.currencyCode}}
                                    </div>
                                    <!-- <div class="header-text" [subtotal]= "subtotal + item?.productDetails?.quantity * item?.productDetails?.productId?.productPrice[0]?.MRP"
                        >
                            ${{item?.productDetails?.quantity * item?.productDetails?.productId?.productPrice[0]?.MRP}}.00
                        </div> -->
                                </td>
                            </tr>
                            <!-- <tr *ngIf="!shoppingBag.length">
                                <td class="small--hide">
                                    No record found
                                </td>
                            </tr> -->

                            <tr *ngIf="!shoppingBag?.length">
                                <td colspan="5"><span class="d-flex justify-content-center">
                                        You don't have item in your cart
                                    </span>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="custom-pagination mt-2 text-align-end">
                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
            </div> -->
                </div>
                <!-- <div class="cart__footer">
                    <div class="grid">
                        <div class="grid__item text-right small--text-center">
                            <div class="">
                                <span class="cart__subtotal-title header-text">
                                    Subtotal
                                </span>
                                <span class="cart__subtotal header-text">
                                    {{subtotal | currency: ipDetails?.geoplugin_currencySymbol}}
                                </span>
                            </div>
                            <div class="cart__shipping header-text rte">
                                Taxes and <a>shipping</a> calculated at checkout
                            </div>
                            <div class="cart__shipping  grid__item float-right small--one-half medium-up--one-third">
                                <span class="header-text">If you have a rush order please contact</span> <a
                                    class="header-text">
                                    customerservice@moteemaids.com </a> <span class="header-text">for assistance or call
                                    or
                                    text
                                    212-971- 3167.</span>
                                <p style="margin-top: 25px;">
                                    <span class="header-text">The Fit Check tool is a guide only. Fit may vary depending
                                        on
                                        the
                                        construction and materials of the selected style, and additional tailoring may
                                        be
                                        required. Please check the size chart before ordering. If you are not sure about
                                        your
                                        size, or have any questions, please contact us at</span> <a class="header-text">
                                        customerservice@moteemaids.com</a>.
                                </p>
                            </div>
                            <div class="cart__submit-controls">
                                <a class="btn btn--secondary header-text cart__continue small--hide cart__submit-control"
                                    routerLink="/bridal-collection-list">Continue shopping</a>
                                <button type="submit"
                                    class="btn btn--secondary header-text small--hide cart__submit-control"
                                    (click)="updateShoppingBag()">Update</button>
                                <button type="submit"
                                    class="btn btn--small-wide cart__submit cart__submit-control buttonGlobal"
                                    (click)="checkout()">Checkout</button>
                            </div>
                        </div>
                    </div>
                </div> -->


            </div>
        </div>
        <div class="col-md-3">
            <!-- <div class="col-md-5 col-12 ps-md-5 p-0 "> -->
            <div class="box-left">
                <!-- <p class="textmuted h8">Invoice</p> -->
                <p class="fw-bold h7 box-left-label" style="border-bottom: 1px solid #bfcedf">Price Details</p>
                <!-- <p class="textmuted h8">3897 Hickroy St, salt Lake city</p>
                <p class="textmuted h8 mb-2">Utah, United States 84104</p> -->
                <div class="h8">
                    <!-- <div class="row m-0 mb-3 border-bottom">
                        <div class="col-8 h8 pe-0 ps-2 p-0">
                            <span class="d-block py-2">Price ({{totalItems || 0}} items)</span>
                            <span class="d-block py-2">Delivery Charges</span>
                        </div>

                        <div class="col-4 p-0 text-end">
                              <span class="d-block py-2 "><span class="fas fa-dollar-sign"></span>{{subtotal | currency:
                                ipDetails?.geoplugin_currencySymbol}}</span>
                            <span class="d-block py-2"><span class="fas fa-dollar-sign"></span>{{shippingCharge |
                                currency: ipDetails?.geoplugin_currencySymbol}}</span>
                        </div>
                    </div> -->
                    <div class="d-flex h7 mb-2 border-bottom justify-content-between">
                        <p>Total Amount</p>
                        <p>{{total | currency: ipDetails?.geoplugin_currencySymbol}}</p>
                    </div>
                    <!-- <div class="h8 mb-5">
                        <p class="textmuted">Lorem ipsum dolor sit amet elit. Adipisci ea harum sed quaerat tenetur
                        </p>
                    </div> -->
                </div>
                <div class="p-2">
                    <!-- <p class="h7 fw-bold mb-1">Pay this Invoice</p> -->
                    <!-- <p class="textmuted h8 mb-2">Make payment for this invoice by filling in the details</p> -->
                    <!-- <div class="form">
                        <div class="btn btn-primary d-block h8">PAY
                            <span class="fas fa-dollar-sign ms-2"></span>1400<span
                                class="ms-3 fas fa-arrow-right"></span>
                        </div>
                    </div> -->
                    <div class="cart__submit-controls">
                        <!-- <a class="btn btn--secondary header-text cart__continue small--hide cart__submit-control"
                            routerLink="/bridal-collection-list">Continue shopping</a> -->
                        <!-- <button type="submit" class="btn btn--secondary header-text small--hide cart__submit-control"
                            (click)="updateShoppingBag()">Update</button> -->
                        <button type="submit" class="btn btn--small-wide cart__submit cart__submit-control buttonGlobal"
                            (click)="checkout()">PLACE ORDER</button>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>

    </div>
</div>