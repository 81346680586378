import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { RegEx } from 'src/app/config/reg-ex';
import { AuthService } from 'src/app/provider/auth.service';
import { CommonService } from 'src/app/provider/common.service';
declare var $: any

@Component({
  selector: 'app-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.scss']
})
export class ConfirmPaymentComponent implements OnInit {
  cardForm: FormGroup
  loginRes: any = {};
  showAddress: boolean = false;
  shoppingBag: any[];
  totalItems: any;
  addonArr: any = [];
  currentPage = 1;
  itemPerPage = 10;
  shippingAddress: any;
  shippingAddressState: any;
  shippingAddressCity: any;
  shippingAddressZipCode: any;
  shippingAddressCountry: any;
  email: any;
  totalPrice: number;
  appointmentRes: any;
  ipDetails: any;
  appointmentPrice: any;
  isSubmitted: any;

  constructor(public commonService: CommonService, private router: Router, private ngZone: NgZone, public authService: AuthService) {
    this.ipDetails = this.commonService.getIPDetails();

  }

  ngOnInit(): void {
    this.cardForm = new FormGroup({
      expiryDate: new FormControl('', Validators.required),
      card: new FormControl('', [Validators.required, Validators.pattern(RegEx.cardNumber)]),
      cvv: new FormControl('', [Validators.required, Validators.pattern(RegEx.cardCvv)]),
      cardHolderName: new FormControl('', Validators.pattern(RegEx.cardName))
    })
    this.appointmentRes = localStorage.getItem('appointmentRes')
    this.appointmentRes = JSON.parse(this.appointmentRes);
    console.log((this.appointmentRes));
    this.loadStripe()

    // conversion rate
    this.commonService.getConversionRate(this.ipDetails.geoplugin_currencyCode).subscribe((res: any) => {
      console.log(res);
      if (res['responseCode'] == 200) {
        // this.currentConversionRate = res.result
        this.appointmentPrice = 50 * res.result
      }
    })
    console.log(this.appointmentPrice);

    // end conversion rate 
  }
  //payment 
  /**
  * Stripe patment
  */
  // load stripe 
  loadStripe() {
    console.log("leadstripe");
    if (!window.document.getElementById('stripe-custom-form-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey('pk_test_bPLMoVgKgkEh5Q42Q6g3suQP00OR22mpEL');
      }
      window.document.body.appendChild(s);
    }
  }

  pay() {
    // this.commonService.showSpinner()
    var self = this
    if (!window['Stripe']) {
      console.log("Stripe did not initialize properly");

      alert('Oops! Stripe did not initialize properly.');
      return;
    }
    else if (this.cardForm.invalid) {
      console.log("cardForm.invalid");
      this.isSubmitted = true
      // console.log(this.cardForm.value);
      return this.commonService.alertError('Please provide your debit/credit card details.')
    }
    // else if (this.addressForm.invalid) {
    //   console.log("addressForm.invalid");

    //   this.isSubmittedAddressForm = true
    //   return this.commonService.alertError('Please provide your billing address details.')
    // }
    else {
      console.log("token generated else");
      // self.commonService.showSpinner();
      // this.commonService.showSpinner()
      // if (!window['Stripe']) {
      //   alert('Oops! Stripe did not initialize properly.');
      //   return;
      // }
      (<any>window).Stripe.card.createToken(
        {
          name: self.cardForm.value.cardHolderName,
          number: self.cardForm.value.card,
          exp_month: self.cardForm.value.expiryDate.split('-')[1],
          exp_year: self.cardForm.value.expiryDate.split('-')[0],
          cvc: self.cardForm.value.cvv,
          // address:'Okhla'
        }
        // {
        //   number: '4000056655665556',
        //   exp_month: 4,
        //   exp_year: 2023,
        //   cvc: '314',
        // }  
        , (status: number, data: any) => {
          console.log("stripe response", data)
          if (data.error) {
            this.commonService.alertError(data.error.message)
          }
          if (status === 200) {
            console.log(`Success! Card token ${data.card.id}.`)
            // send stripe and other details to server
            this.placeOrder(data.id) // place order when we get stripe token
            self.commonService.hideSpinner()
          } else {
            this.commonService.alertError(data.error.message)
            console.log(data.error.message)
          }
        });
    }
  }

  placeOrder(id) {
    this.ngZone.run(() => {
      console.log(id);
      let apiReqUrl = `${ApiEndPoint.confirmAppointment}`
      let apiReqData = {
        "id": id,
        "email": this.appointmentRes.email,
        "currencyCode": this.ipDetails.geoplugin_currencyCode
      }
      console.log(apiReqUrl);
      console.log(apiReqData);
      this.commonService.showSpinner();
      this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
        console.log(res);
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          this.closeModal()
          this.commonService.alertSuccess(res.responseMessage)
          if (this.authService.checkAuthToken()) {
            this.router.navigate(['/profile-management'], { queryParams: { showTab: 'APPOINTMENT' } });
          } else {
            this.router.navigate(['/homepage']);
          }
        } else {
          this.closeModal()
          this.commonService.hideSpinner();
          this.commonService.alertError(res.responseMessage)
        }
      }, (err: any) => {
        this.closeModal()
        this.commonService.hideSpinner();
        this.commonService.alertError(err.responseMessage)
      })
    })
  }


  paymentModalOpen() {
    $('#paymentModalId').modal('show')
  }


  closeModal() {
    $('#paymentModalId').modal('hide')
  }

}
