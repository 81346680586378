import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AddBlogDialogComponent } from '../add-blog-dialog/add-blog-dialog.component';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
import { AuthService } from 'src/app/provider/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
// import { SimpleSmoothScrollService,SimpleSmoothScrollOption } from 'ng2-simple-smooth-scroll';
declare var $: any;
@Component({
  selector: 'app-view-mothers-dress',
  templateUrl: './view-mothers-dress.component.html',
  styleUrls: ['./view-mothers-dress.component.scss'],
})
export class ViewMothersDressComponent implements OnInit {
  isSubmittedForm: boolean = false;
  addEventForm: FormGroup;
  value = 'Select color';
  panelOpenState = false;
  changeImage: boolean = false;
  productId: any;
  productDetails: any;
  like: boolean = false;
  productDetail: any;
  productImage: any;
  productLike: any;
  size: number[];
  // myThumbnail="https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg";
  // myFullresImage="https://wittlock.github.io/ngx-image-zoom/assets/fullres.jpg";
  step = 0;
  colorId: any;
  enteredQuantity: any = 1;
  selectedSize: any;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ipDetails: any;
  premiumColorId: any;
  socialShopDetails: any;

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService
  ) // private smooth: SimpleSmoothScrollService
  {
    this.ipDetails = this.commonService.getIPDetails();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.activatedRoute.queryParams.subscribe((res) => {
      this.productId = res.productId;

      /**
       * route come from social shoping
       * check for details of social shoping
       * such as type and paymeny terms :: group or individual
       */
      if (res.socialShopDetails) {
        this.socialShopDetails = JSON.parse(res.socialShopDetails);
        console.log(this.socialShopDetails);
      }
    });

    this.size = this.commonService.size;
    this.viewProductApi();
    this.addEventForm = new FormGroup({
      eventDate: new FormControl('', Validators.required),
      clientName: new FormControl('', Validators.required),
    });
    // this.smooth.smoothScrollToAnchor();
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddBlogDialogComponent, {
      height: '350px',
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  imgSrc: any;
  changeImg(src) {
    this.imgSrc = src;
    // this.smooth.smoothScrollToTop({ duration: 1000, easing: 'linear' });
  }
  // addFavorites() {
  //   this.router.navigate(['/profile-management'], {
  //     queryParams: { showTab: 'WISHLIST' },
  //   });
  // }

  /**
   * @param apiReqUrl contain end poin url to add product in bag
   * @param addToBagApi function used to add product in bag list
   */
  addToBagApi() {
    if (!this.authService.checkAuthToken()) {
      return this.router.navigate(['/signin']);
    } else {
      // validation alerts
      if (!this.colorId && !this.premiumColorId) {
        return this.commonService.alertError(
          'Please select color or premium color to continue.'
        );
      }
      // end validation alerts
      // let apiReqUrl = ApiEndPoint.addToBag;
      let apiReqUrl = `${
        ApiEndPoint.addToBag + '?productId=' + this.productId
      }`;

      // let apiReqData = {
      //   // "dressLength": "string",
      //   "productDetails": {
      //     "productId": this.productId,
      //     // "colors": this.productDetail.productDetails[0].color,
      //     // "size": this.productDetail.productDetails[0].size,
      //     // "quantity": this.productDetail.productDetails[0].quantity
      //     "colors": this.colorId,
      //     "size": this.selectedSize,
      //     "quantity": this.enteredQuantity,
      //   },
      //   "MRP": this.productDetail.MRP,
      //   "currencyCode": this.ipDetails.geoplugin_currencyCode

      //   // "maskDetails": {
      //   //   "maskId": "string",
      //   //   "colors": "string",
      //   //   "quantity": 0
      //   // }
      // }
      let apiReqData = {
        //   {
        //   "dressLength": "string",
        //     "totalMrp": 0,
        //       "currencyCode": "string",
        //         "colors": "string",
        //           "size": "string",
        //             "enteredQuantity": 0,
        //               "convertedRate": 0,
        //                 "premiumColorId": "string"
        // }

        dressLength: this.selectedSizeType,
        totalMrp: this.totalPrice,
        currencyCode: this.ipDetails.geoplugin_currencyCode,
        colors: this.colorId,
        size: this.selectedSize,
        enteredQuantity: this.enteredQuantity,
        convertedRate: this.productDetail?.conversionRate,
        premiumColorId: this.premiumColorId,
      };
      console.log(apiReqData);
      this.commonService.removeEmptyKey(apiReqData);
      this.commonService.showSpinner();
      this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe(
        (res: any) => {
          if (res.responseCode == 200) {
            this.commonService.hideSpinner();
            // $('#confirmEventModal').modal('show')
            this.commonService.alertSuccess(res.responseMessage);
            this.router.navigate(['/my-shopping-bag']);
          } else {
            this.commonService.hideSpinner();
            this.commonService.errorToast(res.responseMessage);
          }
        },
        (err: any) => {
          if (err.responseCode == 400) {
            this.commonService.hideSpinner();
            this.commonService.alertError(err.responseMessage);
          }
        }
      );
    }
  }

  /**
   * @param apiReqUrl contain end poin url to view product details
   * @param viewProductApi function is used to view product details
   */
  viewProductApi() {
    // let apiReqUrl = `${ApiEndPoint.userViewProduct + '?_id=' + this.productId + "&currencyCode=" + this.ipDetails.geoplugin_currencyCode}`;
    let apiReqUrl = `${
      ApiEndPoint.userViewNewProduct +
      '?_id=' +
      this.productId +
      '&currencyCode=' +
      this.ipDetails.geoplugin_currencyCode
    }`;

    this.commonService.showSpinner();
    this.commonService
      .getApi(apiReqUrl, this.authService.checkAuthToken() ? 1 : 0)
      .subscribe((res: any) => {
        if (res.responseCode == 200) {
          // this.productDetail = res.result[0] ? res.result[0] : [];
          // this.productLike = res.result[0] ? res.result[0] : [];
          // this.imgSrc = res.result[0].productDetails[0].image[0].imageUrl.webImage;
          // this.productImage = res.result[0].productDetails[0].image;
          this.productDetail = res.result[0] ? res.result[0] : [];
          this.productLike = res.result[0] ? res.result[0] : [];
          this.imgSrc =
            res.result[0].productDetails[0].productImageDetails[0].frontImage.webImage;
          this.productImage = res.result[0].productDetails[0];
          this.colorId = res.result[0].productDetails[0].productImageDetails[0].colorId
          this.getTaotalPrice(); // calculate total price

          console.log(this.productDetail);
          this.commonService.hideSpinner();
          //  this.commonService.successToast(res.responseMessage)
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      });
  }

  /**
   * @param ApiEndPoint contains end point url
   * @param addProductWishlist function used to add product in whislist
   */
  addProductWishlist() {
    if (!this.authService.checkAuthToken()) {
      return this.router.navigate(['/signin']);
    } else {
      let apiReqUrl = `${
        ApiEndPoint.addWishListProduct + '?newProductId=' + this.productId
      }`;
      // this.commonService.showSpinner();
      this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
        if (res.responseCode == 200) {
          // this.commonService.hideSpinner();
          this.like = !this.like;
          console.log(this.like);
          // if (this.like) {
          //   this.router.navigate(['/profile-management']);
          // }
          this.productLike.isLike = res.result.isLike;
          // this.commonService.alertSuccess(res.responseMessage)
        } else {
          // this.commonService.hideSpinner();
          // this.commonService.errorToast(res.responseMessage);
        }
      });
    }
  }

  /**
   *
   */
  toOpenSizeChart() {
    $('#exampleModal').modal('show');
  }

  /**
   * @param apiReqUrl contains end point url to add event
   * @param addEventApi function used to add event
   */
  addEventApi() {
    if (this.addEventForm.invalid) {
      this.isSubmittedForm = true;
      return;
    }
    let apiReqUrl = ApiEndPoint.addEvent;
    let apiReqData = {
      clientName: this.addEventForm.value.clientName,
      eventDate: this.addEventForm.value.eventDate,
    };
    this.commonService.showSpinner();
    this.commonService
      .postApi(apiReqUrl, apiReqData, 1)
      .subscribe((res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          this.commonService.alertSuccess(res.responseMessage);
          this.router.navigate(['/my-shopping-bag']);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      });
  }

  // select color
  selectColor(item) {
    console.log(item);
    this.colorId = item;
    this.premiumColorId = '';
    // ---------------new code image handle

    this.productDetail.productDetails.map((ele) => {
      ele.productImageDetails.map((ele2)=>{
        if(ele2.colorId == item || ele2.premiumColorId == item){

          this.imgSrc = ele2.frontImage.webImage;
        this.productImage = {
          productImageDetails : [ele2]
        };
        }
      })
    });
    // this.imgSrc =
    // res.result[0].productDetails[0].productImageDetails[0].frontImage.webImage;

  
  }

  // select color
  selectPremiumColor(item) {
    console.log(item);
    this.premiumColorId = item;
    this.colorId = '';
      // ---------------new code image handle

      this.productDetail.productDetails.map((ele) => {
        ele.productImageDetails.map((ele2)=>{
          if(ele2.colorId == item || ele2.premiumColorId == item){
  
            this.imgSrc = ele2.frontImage.webImage;
          this.productImage = {
            productImageDetails : [ele2]
          };
          }
        })
      });
      // this.imgSrc =
      // res.result[0].productDetails[0].productImageDetails[0].frontImage.webImage;
  }

  selectedSizePrice: number = 0;
  selectedSizeType: string = '';
  // select size
  selectSize(item) {
    console.log(item);
    this.selectedSize = item;

    let getSizePrice =
      this.productDetail?.collectionDetails?.price.mrp?.bodyList.filter(
        (x) => x.bodyId == this.productDetail.bodiceId
      )[0];
    // console.log(getSizePrice);

    // condition for size range
    // if (Number(this.selectedSize) >= 0 && Number(this.selectedSize) <= 16) {
    if (Number(this.selectedSize) >= 0 && Number(this.selectedSize) <= 18) {
      console.log('0-18');
      this.selectedSizeType = 'REGULAR';
      // this.selectedSizePrice = this.productDetail?.collectionDetails?.size.filter(x => x.type == "REGULAR").map(x => x.addOnPrice)[0]
      this.selectedSizePrice = getSizePrice?.size
        .filter((x) => x.type == 'REGULAR')
        .map((x) => x.price)[0];
      // } else if (Number(this.selectedSize) >= 18 && Number(this.selectedSize) <= 22) {
    } else if (
      Number(this.selectedSize) >= 20 &&
      Number(this.selectedSize) <= 24
    ) {
      console.log('20-24');
      this.selectedSizeType = 'LARGE';
      // this.selectedSizePrice = this.productDetail?.collectionDetails?.size.filter(x => x.type == "LARGE").map(x => x.addOnPrice)[0]
      this.selectedSizePrice = getSizePrice?.size
        .filter((x) => x.type == 'LARGE')
        .map((x) => x.price)[0];
    } else {
      console.log('26 and above');
      this.selectedSizeType = 'EXTRA_LARGE';
      // this.selectedSizePrice = this.productDetail?.collectionDetails?.size.filter(x => x.type == "EXTRA_LARGE").map(x => x.addOnPrice)[0]
      this.selectedSizePrice = getSizePrice?.size
        .filter((x) => x.type == 'EXTRA_LARGE')
        .map((x) => x.price)[0];
    }

    console.log(this.selectedSize);
    console.log(this.selectedSizePrice);

    // end condition for size range
    this.getTaotalPrice();
  }

  selectSizeReadyProduct(item) {
    console.log(item);
    this.selectedSize = item;
    // condition for size range
    if (Number(this.selectedSize) >= 0 && Number(this.selectedSize) <= 18) {
      console.log('0-18');
      this.selectedSizeType = 'REGULAR';
      this.selectedSizePrice = 0;
    } else if (
      Number(this.selectedSize) >= 20 &&
      Number(this.selectedSize) <= 24
    ) {
      console.log('20-24');
      this.selectedSizeType = 'LARGE';
      this.selectedSizePrice = 0;
    } else {
      console.log('26 and above');
      this.selectedSizeType = 'EXTRA_LARGE';
      this.selectedSizePrice = 0;
    }

    console.log(this.selectedSize);
    console.log(this.selectedSizePrice);

    // end condition for size range
    this.getTaotalPrice();
  }

  selectedLength: number = 0;
  // select size
  selectLength(item) {
    console.log(item);
    this.selectedLength = item;
    this.getTaotalPrice();
  }

  totalPrice: number;
  getTaotalPrice() {
    // console.log(this.selectedLength);
    console.log(this.selectedSizePrice);

    const totalMrp = Number(this.productDetail?.countryPrice?.totalMrp);
    const extraLength =
      Number(this.selectedLength) * this.productDetail.conversionRate;
    const size =
      Number(this.selectedSizePrice) * this.productDetail.conversionRate;

    // console.log(totalMrp);
    // console.log(extraLength);
    // console.log(size);
    // this.totalPrice = Number(this.productDetail?.countryPrice?.totalMrp) + (Number(this.selectedLength) * this.productDetail.conversionRate) + (Number(this.selectedSizePrice) * this.productDetail.conversionRate)
    this.totalPrice = totalMrp + extraLength + size;
    // console.log(this.totalPrice);
  }

  /* social shoping functionality
   *
   */

  proceedToChatRoom() {
    // this.router.navigate(['/chat-room'])
    if (!this.authService.checkAuthToken()) {
      return this.router.navigate(['/signin']);
    }
    // else if (!this.combinationDetails) {
    //   this.commonService.alertError('Please select body and skirt combination, to continue.')
    // }
    else if (!this.premiumColorId && !this.colorId) {
      this.commonService.alertError(
        'Please select color or premium color, to continue.'
      );
    } else if (!this.selectedSizeType) {
      this.commonService.alertError('Please select size, to continue.');
    } else {
      // let apiReqUrl = ApiEndPoint.addToBag;
      let apiReqUrl = ApiEndPoint.addProductInGroup;
      let apiReqData = {
        // gourp details
        groupId: this.socialShopDetails.groupId,
        // productId: '6242e211c0e6445fce7472ea',
        numberOfIndex: this.socialShopDetails.numberOfIndex,

        // product details
        productId: this.productId,
        dressLength: this.selectedSizeType, // selected size type send like :: REGULAR, LARGE or EXTRA_LARGE depend on selectedSize
        size: this.selectedSize,
        totalMrp: this.totalPrice,
        currencyCode: this.ipDetails.geoplugin_currencyCode,
        colors: this.colorId,
        premiumColorId: this.premiumColorId,
        // "enteredQuantity": 1,
        convertedRate: this.productDetail?.conversionRate,
        productType: 'READY_TO_SHIP', // ["CUSTOMIZE","READY_TO_SHIP","COLLECTION"]
      };
      console.log(apiReqData);
      this.commonService.removeEmptyKey(apiReqData);
      console.log(apiReqData);
      this.commonService.showSpinner();
      this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe(
        (res: any) => {
          if (res.responseCode == 200) {
            this.commonService.hideSpinner();
            // $('#confirmEventModal').modal('show')
            this.commonService.alertSuccess(res.responseMessage);
            // this.router.navigate(['/my-shopping-bag'])
            // this.router.navigate(['/chat-room'], {
            //   queryParams: { groupId: this.socialShopDetails.groupId },
            // });
            // based on payment type GROUP or INDIVIDUAL the will change the navigation
            if (res.result.payType == 'GROUP') {
              this.router.navigate(['/chat-room'], {
                queryParams: { groupId: this.socialShopDetails.groupId },
              });
            } else if (res.result.payType == 'INDIVIDUAL') {
              let socialShopOrderDetails = {
                groupId: this.socialShopDetails.groupId,
                numberOfIndex: this.socialShopDetails.numberOfIndex,
              };
              this.router.navigate(['/checkout-individual'], {
                queryParams: {
                  socialShopOrderDetails: JSON.stringify(
                    socialShopOrderDetails
                  ),
                },
              });
            }
          } else {
            this.commonService.hideSpinner();
            this.commonService.errorToast(res.responseMessage);
          }
        },
        (err: any) => {
          if (err.responseCode == 400) {
            this.commonService.hideSpinner();
            this.commonService.alertError(err.responseMessage);
          }
        }
      );
    }
  }

  proceedToCheckout() {}
}
