import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
// import { NgxOtpInputConfig } from 'ngx-otp-input';
import { CommonService } from 'src/app/provider/common.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-otp-verification-retailer',
  templateUrl: './otp-verification-retailer.component.html',
  styleUrls: ['./otp-verification-retailer.component.scss']
})
export class OtpVerificationRetailerComponent implements OnInit {
  email: string;
  UserOtp: any;
  currentUrl: string;
  previousUrl: string;
  userData: {};
  isActivate: any;
  // countDowon : any = 0.3
  countDowon: any = 2.59;
  otpForm: FormGroup
  constructor(private router: Router, public commonService: CommonService, private activate: ActivatedRoute) {
    this.activate.queryParams.subscribe(res => {
      this.isActivate = res.isActive
    })
    window.scroll(0, 0)

  }
  contDownTimer() {
    var a = window.setInterval(() => {
      if (this.countDowon - (parseInt(this.countDowon)) > 0.60) {

        this.countDowon = this.countDowon - 0.40
      }
      if (this.countDowon > 0) {
        this.countDowon = this.countDowon - 0.01
        this.getTime((this.countDowon).toFixed(2))
      }
      else {
        this.resend_otp_active = true
        clearInterval(a)
      }
    }, 1000)
  }
  resend_otp_active: boolean = false
  time
  getTime(e) {
    this.time = String(e).replace('.', ':')
    this.time = String(e).replace('-', '')
  }
  ngOnInit(): void {
    this.otpForm = new FormGroup({
      otp: new FormControl("", [
        Validators.required,
      ]),
    });
    this.contDownTimer()
    this.email = localStorage.getItem('email')
    this.currentUrl = localStorage.getItem('currentUrl')
    console.log(this.currentUrl);

  }
  onOtpChange(otp) {
    this.UserOtp = otp;
    console.log(otp);

  }
  // ========= ngx otp input method===============
  // otpInputConfig: NgxOtpInputConfig = {
  //   otpLength: 4,

  // }
  //  otp verification 
  navigateToResetPassword() {
    if (this.UserOtp?.length != 6) {
      this.commonService.warningToast('Please enter OTP')
      return
    }
    console.log(this.UserOtp);
    let apiReqUrl = ApiEndPoint.verifyOtp
    this.userData = {
      mobileNumberPId: this.email,
      otp: this.UserOtp
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, this.userData, 0).subscribe((res: any) => {
      console.log(res);
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner()
        Swal.fire({
          icon: 'success',
          text: res.responseMessage,
        })
        this.router.navigate(['/retailer-reset'])
      }

    }, err => {
      this.commonService.hideSpinner()
      if (err.responseCode == 400) {
        this.commonService.alertError(err.responseMessage)
      }
      console.log(err);
    })

  }
  // resend otp 
  resendOtp() {
    if (!this.resend_otp_active) {
      return
    }
    else {
      // this.countDowon = 0.3
      this.countDowon = 2.59
      this.contDownTimer()
      this.resend_otp_active = false
    }
    this.email = localStorage.getItem('email');
    let apiReqUrl = ApiEndPoint.resendOtp
    var email = {
      'mobileNumberPId': this.email
    }
    this.commonService.postApi(apiReqUrl, email, 0).subscribe(res => {
      console.log(res);
    }, err => {
      console.log(err);
    })
  }
}
