<!-- start toolbar -->
<!-- <mat-toolbar class="toolbar_custom">
    <span>Home/</span><span>Motee Collection</span>
  </mat-toolbar> -->
  <!-- end toolbar -->

<div class="wrraper">
  <div class="">
    <mat-card class="card_custom_bg">
    <mat-card class="shadow-none mb-5">
      <mat-card-content class="styles_card_custom">
        <div class="content_custom">
          <div class="w-25 position-relative" routerLink="/dress-collection">
            <img mat-card-image  src="./assets/images/grid2.png" alt="" class="box_custom wow rollIn">
            <p class="para">The Original Collection</p>
          </div>
          <div class="w-25 position-relative"  routerLink="/dress-collection">
            <img mat-card-image  src="./assets/images/grid4.png" alt="" class="box_custom wow bounceInDown">
            <p class="para">2021 Collection</p>
          </div>
          <div class="w-25 position-relative"  routerLink="/dress-collection">
            <img mat-card-image  src="./assets/images/grid5.png" alt="" class="box_custom wow bounceInRight">
            <p class="para">Embroidery Collection</p>
          </div>
        </div>
        <div class="content_custom">
            <div class="w-25 position-relative"  routerLink="/dress-collection">
              <img mat-card-image  src="./assets/images/signin.jpg" alt="" class="box_custom wow rollIn">
              <p class="para">Fully Embelished Collection</p>
            </div>
            <div class="w-25 position-relative"  routerLink="/dress-collection">
              <img mat-card-image  src="./assets/images/background2.jpg" alt="" class="box_custom  wow bounceInDown">
              <p class="para">The Sequin Sash Collection</p>
            </div>
            <div class="w-25 position-relative"  routerLink="/dress-collection">
              <img mat-card-image  src="./assets/images/signin.jpg" alt="" class="box_custom  wow bounceInRight">
              <p class="para">Embroidery Collection</p>
            </div>
          </div>
          <div class="content_custom">
            <div class="w-25 position-relative"  routerLink="/dress-collection">
              <img mat-card-image  src="./assets/images/grid4.png" alt="" class="box_custom wow rollIn">
              <p class="para">The Sequin Strap Collection</p>
            </div>
            <div class="w-25 position-relative"  routerLink="/dress-collection">
              <img mat-card-image  src="./assets/images/grid3.png" alt="" class="box_custom  wow bounceInDown">
              <p class="para">Sleeve Collection</p>
            </div>
            <div class="w-25 position-relative"  routerLink="/dress-collection">
              <img mat-card-image  src="./assets/images/grid5.png" alt="" class="box_custom  wow bounceInRight">
              <p class="para">Plain Collection</p>
            </div>
          </div>
          <div class="content_custom">
            <div class="w-25 position-relative"  routerLink="/dress-collection">
              <img mat-card-image  src="./assets/images/grid6.png" alt="" class="box_custom wow rollIn">
              <p class="para">Embellished Collection</p>
            </div>
          </div>
      </mat-card-content>
    </mat-card>

  </mat-card>
  </div>
</div>