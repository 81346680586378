<!-- my order copy -->
<!-- <div class="container"> -->
<div class="content">

    <!-- <div class="order-details">
              <span class="header-text">Order Details</span>
            </div> -->
    <div class="top-text text-center">
        <mat-card-title class="mat-card-title sign-text ml-0 title-color-dark">My Measurement</mat-card-title>
    </div>

    <div class="row m-0">
        <div class="col-md-12">
            <div class="book-appointment-container">
                <!-- <h1 class="header-text">Event details</h1> -->
                <!-- <div class="book-appointment-text header-text">
                    <p>Welcome to our New York City Flagship Salon.</p>
                    <p>We invite you to call or text our concierge now at 212-583-1700 or 212-971-3167 to reserve an appointment to view the Motee Maids, Nouvelle Motee Maids - bridal and bridesmaids, Little White Dress or Christos Collection. Or complete the request form at your convenience and a consultant will follow up shortly.</p>
                    <p>Please note there is a $50 fee to reserve an appointment at the Motee Maids salon. The appointment fee is a hold on your credit card that will be refunded after you attend your appointment, or change/cancel with at least 24 hours notice. Changes or cancelations inside of 24 hours will forfeit the fee.</p>
                </div> -->

                <div class="book-appointment-text">
                    <div class="row">
                        <mat-form-field appearance="outline" class="w-50 pdgn">
                            <mat-label>Age</mat-label>
                            <input matInput>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-50 pdgns">
                            <mat-label>Bra Size</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline" class="w-50 pdgn">
                            <mat-label>Height</mat-label>
                            <input matInput>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-50 pdgns">
                            <mat-label>Weight</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline" class="w-100 pdgn">
                            <mat-label>Notes</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                    <!-- </div> -->
                </div>

                <div class="down-button mt-3 text-center">
                    <button mat-button class="buttonGlobal" routerLink="/confirm-payment">Save</button>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-6">
            <div class="image-container">
                <img src="/assets/images/Fully_Beaded_Collection_Shoot_2/Navy_Polka.jpg" alt="" class="image-custom">
            </div>
        </div> -->
    </div>
</div>