<!-- signin Modal -->
<div class="wrraper back-pic">
    <div class="login_top_details">

        <mat-card *ngIf="changePasswordCard" class="mat-elevation-z0 bdr">
            <div class="main">
                <div class="top-text text-center">
                    <mat-card-title class="sign-text new-style">Create New Pasword</mat-card-title>
                </div>
                <div class="input-field">
                    <form [formGroup]="resetPasswordForm">
                        <div class="row">

                            <!-- email Field -->
                            <div class="col-md-12">
                                <label for="" class="top_heading_lab">Enter Your New Password :</label>
                                <input type="text" class="user-name" placeholder="Enter new password"
                                    formControlName='newPassword' maxlength="60"
                                    [type]="toggleEye1 ? 'password' : 'text'">
                                <!-- eye Password Protector -->
                                <mat-icon matSuffix (click)="toggleEye1 = !toggleEye1">
                                    {{toggleEye1 ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                                <!-- validation -->
                                <span class="error"
                                    *ngIf="resetPasswordForm.get('newPassword').hasError('required') && (resetPasswordForm.get('newPassword').touched ||resetPasswordForm.get('newPassword').dirty)">
                                    *Password is required.</span>
                                <span class="error"
                                    *ngIf="resetPasswordForm.get('newPassword').hasError('pattern') && (resetPasswordForm.get('newPassword').touched||resetPasswordForm.get('newPassword').dirty)">
                                    *Please enter valid password.</span>
                            </div>
                            <div class="col-md-12">
                                <!-- Password Field -->
                                <label for="" class="top_heading_lab">Re-Enter New Password :
                                </label>
                                <input type="password" class="user-name" [type]="toggleEye ? 'password' : 'text'"
                                    placeholder="Re-enter password" formControlName='confirmPassword' maxlength="32">
                                <!-- eye Password Protector -->
                                <mat-icon matSuffix (click)="toggleEye = !toggleEye">
                                    {{toggleEye ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                                <!-- validation -->
                                <span class="error"
                                    *ngIf="(resetPasswordForm.controls['newPassword'].value!==(resetPasswordForm.controls['confirmPassword'].value) && (resetPasswordForm.get('confirmPassword').touched || resetPasswordForm.get('confirmPassword').dirty))">
                                    *Password
                                    and confirm password should be match.</span>
                            </div>
                            <!-- button -->
                        </div>
                        <div class="down-button">
                            <button mat-button class="buttonGlobal col-md-7" (click)="toOpenResetPasswordSuccessCard()"
                                [disabled]="!resetPasswordForm.valid">Change Password</button>
                        </div>

                    </form>
                    <div class="m-5 w-75">
                        <p class="pl-5"> ©2021–2022 All Rights Reserved. XYZ® is a registered trademark of The ABCD
                            Group. Cookie Preferences, Privacy, and Terms.</p>
                    </div>
                </div>
            </div>
        </mat-card>
        <!-- Reset password success card -->
        <mat-card *ngIf="!changePasswordCard" class="w-50 m-auto text-center">
            <mat-card class="mat-card-custom-1">
                <img mat-card-image src="/assets/images/logoNewTransParent.png" alt="Moti maid logo">
            </mat-card>
            <mat-card-title class="color-green">Password reset successfully</mat-card-title>
            <mat-card-content>Password has been reset successfully!</mat-card-content>
            <mat-action> <button mat-raised-button class="go-to-login" color="primary" routerLink="/signin">Go to Login
                    Page</button></mat-action>
            <div>
                <p class="p-3" style="font-size: 11px;">2021–2022 All Rights Reserved. XYZ® is a registered trademark of
                    The ABCD Group.<a class="text-decoration-underline">Cookie Preferences</a>,<a
                        class="text-decoration-underline"> Privacy</a>, and <a
                        class="text-decoration-underline">Terms</a>.</p>
            </div>
        </mat-card>
    </div>
</div>