import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-collection-list',
  templateUrl: './collection-list.component.html',
  styleUrls: ['./collection-list.component.scss']
})
export class CollectionListComponent implements OnInit {
  filtered:any = true
  selected = "Recomended"
  value = "page 1"
  panelOpenState = false;
  
    // panelOpenState = false;
    customOptions: OwlOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      // navText: ['', ''],
      navText: [ '<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"' ],
      // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
      responsive: {
        0: {
          items: 5
        },
        400: {
          items: 5
        },
        740: {
          items: 5
        },
        940: {
          items: 5
        }
      },
      // nav: true
      nav:false
      // navigation:true,
  
    }
    newOptions: OwlOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      // navText: ['', ''],
      navText: [ '<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"' ],
      // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
      responsive: {
        0: {
          items: 4
        },
        400: {
          items: 4
        },
        740: {
          items: 4
        },
        940: {
          items: 4
        }
      },
      // nav: true
      nav:false
      // navigation:true,
  
    }
    embelishmentOption: OwlOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      // navText: ['', ''],
      navText: [ '<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"' ],
      // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
      responsive: {
        0: {
          items: 4
        },
        400: {
          items: 4
        },
        740: {
          items: 4
        },
        940: {
          items: 4
        }
      },
      // nav: true
      nav:false
      // navigation:true,
  
    }
  

  constructor() { }

  ngOnInit(): void {
  }
  addFilter(){
    this.filtered = false
  }
  removeFilter(){
    this.filtered = true
  }
}
