<div class="main-class wrapper">
    <div class="toolbar-custom">
        <span class="header-text">Real Brides</span>
    </div>
    <div class="love-stories-header">
        <p class="text-center header-text">
            Share your love story with us, please email <a href="mailto:hello@moteemaids.com" aria-describedby="a11y-external-message">hello@moteemaids.com</a> with a link to high resolution images from your wedding day and the name of your photographer
            for a chance to be featured on our blog.
        </p>
    </div>

    <div class="page-width">
        <div class="row justify-content-around" style="margin-bottom: 50px;" >
            <!-- Motee Maids Evening  -->
            <div class="blog-post" *ngFor="let item of realBridesList">
                <div class="post-content" (click)="navigateToViewRealBrides(item?._id)" >
                    <div class="post-image" *ngFor="let itemImg of item.story">
                        <img [src]="itemImg?.image?.webImage" alt="Avatar" class="images-custom">
                        <div class="middle">
                            <div class="text">{{item?.title}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Motee Maids Little White Dress -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <img src="/assets/images/Shoot_3/LAYRA_SKIRT_1577_ALT.jpg" alt="Avatar" class="images-custom">
                        <div class="middle">
                            <div class="text">High School Sweethearts</div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!--Motee Maids - Fall 2022 -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <img src="" alt="Avatar" class="images-custom">
                        <div class="middle">
                            <div class="text">Napa Micro Wedding</div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- Motee Maids Archive - Spring 2022 -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <img mat-card-image src="/assets/images/Shoot_4additionalImages/2020-08-28_Motee Maids_JOB-34456_BM1965.jpg" class="images-custom">
                        <div class="middle">
                            <div class="text">BROOKLYN BRIDE</div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- Our New York City Atelier -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <img mat-card-image class="images-custom" src="/assets/images/Shoot_4additionalImages/2020-08-28_Motee Maids_JOB-34456_BM1966.jpg">
                        <div class="middle">
                            <div class="text">DRIVE-BY WEDDING</div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- New York Bridal Fashion Week - Preview Fall 2021 -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <img mat-card-image class="images-custom" src="/assets/images/Shoot_3/Shades_of_Hunter_Green_and_Ice_Blue.jpg">
                        <div class="middle">
                            <div class="text">CHANCE ENCOUNTER </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- How to Measure Your Dress Size -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <div class="middle">
                            <div class="text">BLENDING TRADITIONS </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- Book A Virtual Appointment -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <img mat-card-image class="images-custom" src="">
                        <div class="middle">
                            <div class="text">NEWPORT OCEANFRONT WEDDING </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- Behind-the-Scenes: Motee Maids Spring 2021 -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <img mat-card-image class="images-custom" src="/assets/images/other/behind_the_scenes_Amsale_Spring.gif">
                        <div class="middle">
                            <div class="text">Nantucket Love Story</div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- Motee Maids Virtual Try-On -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <img mat-card-image class="images-custom" src="/assets/images/Shoot_3/Olga_Embroidery_Daisy_Champagn_0581.jpg">
                        <div class="middle">
                            <div class="text">CASTLE LESLIE CELEBRATION </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- Motee Maids x Morgan Lane: A Match Made in Bridal -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <div class="middle">
                            <div class="text">SAVANNAH SUMMER LOVE</div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!--Fluid Satin Dresses that Shine -->
            <!-- <div class="blog-post">
                <div class="post-content" routerLink="/real-brides-view">
                    <div class="post-image">
                        <img mat-card-image class="images-custom" src="/assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg">
                        <div class="middle">
                            <div class="text">LEXINGTON LOVE STORY</div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

    </div>
