import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService, CountryCode } from 'src/app/provider/common.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import Swal from 'sweetalert2';
import { RegEx } from 'src/app/config/reg-ex';
@Component({
  selector: 'app-retailer-signup',
  templateUrl: './retailer-signup.component.html',
  styleUrls: ['./retailer-signup.component.scss']
})
export class RetailerSignupComponent implements OnInit {
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  options: any[]
  longitude: any;
  latitude: any;
  address: any
  countryList: any[];
  myControl = new FormControl('+91');
  toggleEye: boolean = true;  // toggle eye for password field
  toggleEyeC: boolean = true;  // toggle eye for confirm password
  filteredOptions: Observable<CountryCode[]>;
  signinForm: FormGroup;
  imageUrl: any
  imageUrlDocs: any
  currentUrl: string;
  previousUrl: string;
  brandCarry = [];
  countryListArray: any = [];
  stateListArray: any = []
  frontImageUrl: any
  backImageUrl: any
  passType: boolean = true;
  passType1: boolean = true;
  isFormSubmitted: boolean = false
  constructor(public dialog: MatDialog, private route: Router, public commonService: CommonService) { }

  ngOnInit(): void {
    this.formValidation();
    this.countryListDetail()
  }
  public handleAddressChange(address: Address) {

    this.options = []
    this.address = address.formatted_address

    this.longitude = address.geometry.location.lng()
    this.latitude = address.geometry.location.lat()


    this.options[0] = Number(address.geometry.location.lng())
    this.options[1] = Number(address.geometry.location.lat())

  }

  formValidation() {
    this.signinForm = new FormGroup({
      //  business address
      companyName: new FormControl('', [Validators.required, Validators.pattern(RegEx.companyName)]),
      addressLineOne: new FormControl('', [Validators.required, Validators.pattern(RegEx.address)]),
      addressLineTwo: new FormControl('', [Validators.pattern(RegEx.address)]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      townCity: new FormControl('', [Validators.pattern(RegEx.city)]),
      zipCode: new FormControl('', [Validators.required, Validators.pattern(RegEx.internationalZipCode)]),
      // company details
      mobileNumber: new FormControl('', Validators.pattern(RegEx.phoneNo)),

      vatNumber: new FormControl('', Validators.pattern(RegEx.vatNumber)),
      eoriNumber: new FormControl('', Validators.pattern(RegEx.eoriNumber)),

      // store details
      storeName: new FormControl('', [Validators.required, Validators.pattern(RegEx.storeName)]),
      location: new FormControl('', Validators.pattern(RegEx.address)),
      storeContactNumber: new FormControl('', [Validators.required, Validators.pattern(RegEx.phoneNo)]),
      storeWeb: new FormControl('', [Validators.required, Validators.pattern(RegEx.url)]),
      storeAbout: new FormControl(''),
      brandCarry: new FormControl(''),
      // user details
      firstName: new FormControl('', [Validators.required, Validators.pattern(RegEx.firstName)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(RegEx.lastName)]),
      email: new FormControl("", [Validators.required, , Validators.pattern(RegEx.email)]),
      password: new FormControl('', [Validators.required, Validators.pattern(RegEx.pswd)]),
      confirmPassword: new FormControl('', [Validators.required]),
      govtDocsFront: new FormControl('', [Validators.required]),
      govtDocsBack: new FormControl('', [Validators.required]),
      checkTerms: new FormControl('', [Validators.requiredTrue])
    });
    this.countryList = this.commonService.countryListJson;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (value ? this._filter(value) : this.countryList.slice())),
    );
  }

  private _filter(value: string): CountryCode[] {
    const filterValue = value.toLowerCase();
    return this.countryList.filter(option => option.code.toLowerCase().includes(filterValue));
  }


  // on click signup button 
  signUp() {
    console.log(this.signinForm.value);

    //  alert(this.signinForm.invalid)
    if (this.signinForm.invalid) {
      return this.isFormSubmitted = true
    }
    else {
      let apiReqUrl = ApiEndPoint.signup
      var signUpData = {
        userType: "RETAILER",
        firstName: this.signinForm.value.firstName,
        lastName: this.signinForm.value.lastName,
        email: this.signinForm.value.email,
        countryCode: this.myControl.value,
        mobileNumber: this.signinForm.value.mobileNumber,
        password: this.signinForm.value.password,
        // confirmPassword:this.signinForm.value.confirmPassword,
        companyName: this.signinForm.value.companyName,
        addressLine1: this.signinForm.value.addressLineOne,
        addressLine2: this.signinForm.value.addressLineTwo,
        country: this.signinForm.value.country,
        state: this.signinForm.value.state,
        city: this.signinForm.value.townCity,
        postalCode: this.signinForm.value.zipCode,
        eoriNumber: this.signinForm.value.eoriNumber,
        tax: {
          taxNumber: this.signinForm.value.vatNumber,
          taxType: "VAT",
          // instagram: this.signinForm.value.instagramLink,
          // youtube: this.signinForm.value.youtubeLink
        },
        docImage: {
          frontImage: this.frontImageUrl,
          backImage: this.backImageUrl
        },
        storeDetails: {
          storeName: this.signinForm.value.storeName,
          storeContactNumber: this.signinForm.value.storeContactNumber,
          location: {
            type: "Point",
            // coordinates: this.options
            coordinates: [28.5674072, 77.2922102]

          },
          storeWebsite: this.signinForm.value.storeWeb,
          brands: this.brandCarry,
          whereYouHearAboutUs: this.signinForm.value.storeAbout
        }

      }
      console.log(signUpData);
      this.commonService.showSpinner()
      this.commonService.postApi(apiReqUrl, signUpData, 1).subscribe((res: any) => {
        console.log(res);
        if (res['responseCode'] == 200) {
          // this.commonService.successToast(res['responseMessage'])
          localStorage.setItem('email', this.signinForm.value.email)
          this.commonService.hideSpinner()
          // set current url to localstorage 
          this.currentUrl = this.route.url;
          localStorage.setItem('currentUrl', this.currentUrl)
          this.commonService.alertSuccess(res.responseMessage)
          // after seting current path to localstorage
          this.route.navigate(['/retailer-login'])
        } else {
          this.commonService.hideSpinner();
          this.commonService.alertError(res.responseMessage)
        }

      }, (err) => {
        this.commonService.hideSpinner()
        this.commonService.alertError(err.responseMessage)
      })

    }
  }

  addBrandCarry(value) {
    if (this.signinForm.value.brandCarry) {
      this.brandCarry.push(value.value)
    }
    this.signinForm.controls['brandCarry'].reset()
  }
  removeBrandCarry(item, i) {
    this.brandCarry.splice(i, 1)
  }
  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-
  uploadImg(event, imageOf): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img, imageOf);
  }
  uploadImageFunc(img, imageOf) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('product/uploadFile', fb, 0).subscribe((res: any) => {
      console.log(res);
      console.log(imageOf);

      if (res.responseCode == 200) {
        // this.imageUrl = res['result']
        // upload image both front and back and assign respective property based on condition 'FRONT' or 'BACK'
        if (imageOf == 'FRONT') {
          console.log(imageOf);

          this.frontImageUrl = res['result']
        } else {
          this.backImageUrl = res['result']
        }
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }

  navigateToSignin() {
    this.route.navigate(['/retailer-login'])
  }

  /**
     * userType are used in website
     * @param country list -> normal user comes to buy product
     */
  countryListDetail() {
    let apiReqUrl = ApiEndPoint.countryList

    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.countryListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);

      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }

  stateList(countryCode) {
    this.stateListArray = []
    let apiReqUrl: any = `${ApiEndPoint.stateList + '?countryCode=' + countryCode}`
    // let apiReqUrl = `ApiEndPoint.stateList?countryCode=${countryCode}`
    // let apiReqUrl = `?countryCode=${countryCode}`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.stateListArray = res.result ? res.result : []
        this.commonService.hideSpinner()
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.errorToast(err['responseMessage']);
      } else {
        this.commonService.errorToast(err.responseMessage);
      }
    })
  }



}
