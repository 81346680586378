import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-view-real-bride',
  templateUrl: './view-real-bride.component.html',
  styleUrls: ['./view-real-bride.component.scss']
})
export class ViewRealBrideComponent implements OnInit {
  veiwRealBridesDetails: any;
  birdesViewId: any;

  constructor(private commonService: CommonService,private activatedRoute:ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.birdesViewId = res.birdesViewId
    })
   }

  ngOnInit(): void {
    this.viewRealBridesApi()
  }

  viewRealBridesApi(){
    let apiReqUrl = `${ApiEndPoint.viewBrides + '?_id=' + this.birdesViewId}`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl,1).subscribe((res:any)=>{
        if(res.responseCode == 200){
          this.veiwRealBridesDetails = res.result
          this.commonService.hideSpinner()
        }else{
          this.commonService.hideSpinner()
          this.commonService.errorToast(res.responseMessage)
        }
    },(err:any)=>{
      this.commonService.errorToast(err.errorToast)
    })
  }
}
