<div class="wrapper">
    <!-- upper images -->
    <div class="image-container container">
        <img src="assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg" alt="" class="images-custom">

        <div class="post-title header-text">
            <h1 href="">Trade Shows</h1>
        </div>
    </div>

    <div class="row-custom">
        <div>
            <select class="form-control"  (change)="getSubCategory($event.target.value)">
                    <option value="" selected disabled>Select a Collection</option>
                    <option *ngFor="let item of subCategoryList" [value]="item?._id">{{(item?.parentCategory?.categoryName) + ' - ' + (item?.subCategoryName)}}</option>
              </select>
        </div>
    </div>

    <div class="row m-0">
        <div class="col-md-4 text-center" *ngFor="let item of tradeList">
            <div class="event-box">
                <h3 class="header-text">{{item?.title}}</h3>
                <h4>{{item?.description}}</h4>
                <p class="event-date header-text">{{(item?.fromDate | date:medium) + ' - ' + (item?.toDate | date:medium)}}</p>
                <p class="event-location header-text">{{item?.location}}</p>
            </div>
        </div>
    </div>

</div>