<!-- signin Modal -->
<div class="wrraper back-pic">
    <div class="login_top_details">
      <mat-card class="mat-elevation-z0 bdr">
          <div class="main">
              <div class="top-text text-center">
  
                  <!-- <mat-card-title class="sign-text header-text">B2B Platform Login
                  </mat-card-title> -->
                  <mat-card-title class="sign-text new-style">Retailer Login
                  </mat-card-title>
                  <!-- <p class="text-center textAfterloginHeading">New Customer? <a class="regis" (click)="navigateToSignup()">Register</a> </p> -->
              </div>
              <div class="input-field new_login_details">
                  <form [formGroup]="signinForm">
                      <!-- email Field -->
                      <div class="row">
                <div class="input1 col-md-12 col-lg-12">
  
                      <label for="" class="top_heading_lab lab_data header-text" >Partner ID</label>
                      <div class="row">
                        <div class="col-md-12 col-lg-12">
                      <input type="text"  class="user-name pass_data" placeholder="Enter Your Partner ID" formControlName='mobileNunber' maxlength="60">
                      <!-- validation -->
                  </div>
                      <span class="error" style="margin-left: 9em;" *ngIf="signinForm.get('mobileNunber').hasError('required') && (signinForm.get('mobileNunber').touched ||signinForm.get('mobileNunber').dirty || isSigninFormSubmitted)">
                          *Partner id is required.</span>
                      <span class="error" style="margin-left: 9em;" *ngIf="signinForm.get('mobileNunber').hasError('pattern') && (signinForm.get('mobileNunber').touched||signinForm.get('mobileNunber').dirty)">
                          *Please enter valid Partner id.</span>
                  </div>
                </div>
                      <!-- Password Field -->
                <div class="input1 col-md-12 col-lg-12">
  
                      <label for="" class="top_heading_lab header-text">Password</label>
                    
                      <input type="password" class="user-password" [type]="toggleEye ? 'password' : 'text'" placeholder="Enter Password" formControlName='password' maxlength="32">
                      <!-- eye Password Protector -->
                      <mat-icon matSuffix (click)="toggleEye = !toggleEye">{{toggleEye ? 'visibility_off' : 'visibility'}}
                      </mat-icon>
                      <!-- validation -->
                      <span class="error" *ngIf="signinForm.get('password').hasError('required') && (signinForm.get('password').touched ||signinForm.get('password').dirty || isSigninFormSubmitted)">
                        *Password is required.</span>
                      
                </div>
                  </div>
                  <!-- <span class="checkLink">
                    <mat-checkbox class="rememberCheck header-text" formControlName="rememberMe">Remember my partner ID and Password</mat-checkbox>
                </span> -->
                      <div class="down-button">
                          <button mat-button class="buttonGlobal col-md-7" (click)="login()" >LOGIN</button>
                      </div>
                  </form>
                  <span class="checkLinks">
                    <span >Be a part of our Family! <a class="forgotLink" routerLink="/retailer-signup"> Register</a>
                    </span>
                    <a class="regis" routerLink="/auth/forgot-password" routerLink="/retailer-forgot">Forgot Password ?</a>
                </span>
              </div>
          </div>
      </mat-card>
    </div>
  </div>