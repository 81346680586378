import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-individual-payment',
  templateUrl: './individual-payment.component.html',
  styleUrls: ['./individual-payment.component.scss']
})
export class IndividualPaymentComponent implements OnInit {

  loginRes: any = {};
  showAddress: boolean = false;
  shoppingBag: any = [];
  totalItems: any;
  addonArr: any = [];
  currentPage = 1;
  itemPerPage = 10;
  // shippingAddress: any;
  // shippingAddressState: any;
  // shippingAddressCity: any;
  // shippingAddressZipCode: any;
  // shippingAddressCountry: any;
  email: any;
  cardForm: FormGroup
  totalPrice: number;
  // loginTypeArr: any=[];
  orderId: any;
  orderDetails: any;
  ipDetails: any;
  addressForm: FormGroup;
  countryListArray: any = [];
  stateListArray: any = [];
  isSubmittedAddressForm: any;
  groupId: any;

  constructor(public commonService: CommonService, private activatedRoute: ActivatedRoute, private router: Router, private ngZone: NgZone) {
    this.ipDetails = this.commonService.getIPDetails();

    // this.activatedRoute.queryParams.subscribe((res: any) => {
    //   this.shippingAddress = res.address
    //   this.shippingAddressCity = res.city
    //   this.shippingAddressZipCode = res.zipCode
    //   this.shippingAddressCountry = res.country
    //   this.shippingAddressState = res.state
    //   this.email = res.email
    //   console.log(this.shippingAddress);
    // })
  }

  ngOnInit(): void {
    this.addressFormValidation()
    // this.myShopingBagApi()

    // debit and credit card validation
    this.cardForm = new FormGroup({
      expiryDate: new FormControl('', [Validators.required]),
      card: new FormControl('', (Validators.required, Validators.pattern(/^[1-9][0-9]{7,15}$/))),
      cvv: new FormControl('', (Validators.required, Validators.pattern(/^[0-9]{3}$/))),
      cardHolderName: new FormControl('', Validators.required)
    })

    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      this.orderId = res.orderId
      this.groupId = res.groupId
      this.productListInGroup(this.groupId)
    })
    this.loadStripe();
    this.getOrder();
    this.countryListDetail();
  }

  addressFormValidation() {
    this.addressForm = new FormGroup({
      'address': new FormControl("", Validators.required),
      'state': new FormControl(""),
      'country': new FormControl("", Validators.required),
      'city': new FormControl("", Validators.required),
      'zipCode': new FormControl("", [Validators.required, Validators.maxLength(10)])
    })
  }

  //payment 
  /**
  * Stripe patment
  */
  // load stripe 
  loadStripe() {
    console.log("leadstripe");
    if (!window.document.getElementById('stripe-custom-form-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey(this.commonService.stripePublishKey);
      }
      window.document.body.appendChild(s);
    }
  }

  // get order details
  getOrder() {
    // let apiReqUrl = `${ApiEndPoint.viewOrder + '?orderId=626411866cc182f6506ba30c'}`;
    let apiReqUrl = `${ApiEndPoint.viewOrder + '?orderId=' + this.orderId}`;
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log(res);
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner();
        this.orderDetails = res.result;
        this.chooseBillingAddress('SAME_AS_SHIPPING_ADDRESS') // default set to same as shipping address
        // this.groupId = res.result.groupId
        // this.followinglistData = res["result"]["docs"];
        // for (let item of this.followinglistData) {
        //   this.folowingListOption.push(true)
        // }
      } else {
        this.commonService.hideSpinner();
      }
    })
    // console.log(apiReqData);
    // this.router.navigate(['/payment'],{queryParams:navigationExtra})
  }

  /**
  * @param myShopingBagApi function is used to show product list in bag
  */
  // myShopingBagApi() {
  //   let apiReqUrl = `${ApiEndPoint.shoppingbagList + '?page=' + this.currentPage + '&limit=' + this.itemPerPage}`
  //   this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
  //     console.log(res);
  //     if (res.responseCode == 200) {
  //       this.shoppingBag = res.result.docs
  //       this.totalItems = res.result.total
  //       this.commonService.hideSpinner()
  //     } else {
  //       this.shoppingBag = []
  //       this.totalItems = 0
  //       this.commonService.hideSpinner()
  //     }
  //   }, (err) => {
  //     if (err.responseCode == 404) {
  //       this.shoppingBag = []
  //       this.totalItems = 0
  //       this.commonService.hideSpinner()
  //     }
  //   })
  // }

  productListInGroup(groupId) {
    // const indexes = [];
    let apiReqUrl = `${ApiEndPoint.productListInGroup + '?groupId=' + groupId}`;
    // let apiReqData = {
    //   'groupId': groupId
    // }
    this.commonService.getApi(apiReqUrl, 1).subscribe(
      (res: any) => {
        console.log(res);
        if (res['responseCode'] == 200) {
          this.shoppingBag = res.result;
          this.getTotalProductPrice()
        }
      },
      (err) => {
        console.log(err);
      })
  }
  subtotal: number = 0;
  shippingCharge: number = 0;
  total: number = 0;
  getTotalProductPrice() {
    this.shoppingBag.products.forEach(element => {
      console.log(element);
      this.subtotal = this.subtotal + element.totalMrp * element.enteredQuantity
      this.total = this.subtotal + this.shippingCharge
    });
  }

  /**
     * @param countryListDetail function is used to get country list 
     */
  countryListDetail() {
    let apiReqUrl = 'product/countryList'
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.countryListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    })
  }

  /**
   * @param stateList function is used to get contry list
   */
  stateList(countryCode) {
    let apiReqUrl = `product/stateList?countryCode=${countryCode.value || countryCode}`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.stateListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      } else {
        this.stateListArray = res.result ? res.result : []
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {
      this.stateListArray = []
    })
  }



  pay() {
    var self = this
    if (!window['Stripe']) {
      console.log("Stripe did not initialize properly");

      alert('Oops! Stripe did not initialize properly.');
      return;
    }
    else if (this.cardForm.invalid) {
      console.log("cardForm.invalid");

      // console.log(this.cardForm.value);
      return this.commonService.alertError('Please provide your debit/credit card details.')
    }
    else if (this.addressForm.invalid) {
      console.log("addressForm.invalid");

      this.isSubmittedAddressForm = true
      return this.commonService.alertError('Please provide your billing address details.')
    } else {
      console.log("token generated else");

      // if (!window['Stripe']) {
      //   alert('Oops! Stripe did not initialize properly.');
      //   return;
      // }
      (<any>window).Stripe.card.createToken(
        {
          name: 'hello',
          number: self.cardForm.value.card,
          exp_month: self.cardForm.value.expiryDate.split('-')[1],
          exp_year: self.cardForm.value.expiryDate.split('-')[0],
          cvc: self.cardForm.value.cvv,
          // address:'Okhla'
        }
        // {
        //   number: '4000056655665556',
        //   exp_month: 4,
        //   exp_year: 2023,
        //   cvc: '314',
        // }  
        , (status: number, data: any) => {
          console.log("stripe response", data)
          if (status === 200) {
            console.log(`Success! Card token ${data.card.id}.`)
            // send stripe and other details to server
            this.placeOrder(data.id) // place order when we get stripe token
            self.commonService.hideSpinner()
          } else {
            this.commonService.alertSuccess(data.error.message)
            console.log(data.error.message)
          }
        });
    }
  }

  placeOrder(id) {
    this.ngZone.run(() => {
      let apiReqUrl = `${ApiEndPoint.checkOut + '?orderId=' + this.orderId}`
      let apiReqData = {
        "id": id,
        "shippingAddress": this.orderDetails.shippingAddress._id,
        "billingAddress": {
          // "address": this.orderDetails.shippingAddress.address,
          // "zipCode": this.orderDetails.shippingAddress.zipCode,
          // "city": this.orderDetails.shippingAddress.city,
          // "state": this.orderDetails.shippingAddress.state,
          // "country": this.orderDetails.shippingAddress.country
          // 
          address: this.addressForm.value.address,
          country: this.addressForm.value.country,
          state: this.addressForm.value.state,
          city: this.addressForm.value.city,
          zipCode: this.addressForm.value.zipCode
        },
        "currencyCode": this.ipDetails.geoplugin_currencyCode,
      }
      console.log(apiReqUrl);
      console.log(apiReqData);

      this.commonService.showSpinner();
      this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
        console.log(res);
        if (res['responseCode'] == '200') {
          this.commonService.hideSpinner();
          this.commonService.alertSuccess(res.responseMessage)
          this.router.navigate(['/profile-management'], {
            queryParams: { showTab: 'ORDER' },
          });
          this.commonService.totalItemsInShoppingBag.next(0)
        } else {
          this.commonService.hideSpinner();
          this.commonService.alertError(res.message)
        }
      })
    })
  }



  // bilingAddress() {
  //   console.log("hittt");

  //   this.showAddress = true
  // }
  // sameAddress() {
  //   console.log("hittt");
  //   this.showAddressStatus = false
  // }
  showAddressStatus
  /**
   * showAddressStatus will show the block 
   * when @param showAddressStatus is true then show address area field
   * @param addressChoosen 
   * SAME_AS_SHIPPING_ADDRESS
   * DIFFERENT_BILLING_ADDRESS
   */
  chooseBillingAddress(addressChoosen) {
    // console.log(addressChoosen);
    if (addressChoosen == 'SAME_AS_SHIPPING_ADDRESS') {
      this.showAddressStatus = false;
      // console.log("SAME_AS_SHIPPING_ADDRESS");
      this.addressForm.patchValue({
        "address": this.orderDetails.shippingAddress.address,
        "zipCode": this.orderDetails.shippingAddress.zipCode,
        "city": this.orderDetails.shippingAddress.city,
        "state": this.orderDetails.shippingAddress.state,
        "country": this.orderDetails.shippingAddress.country
      })
    } else {
      this.showAddressStatus = true;
      // console.log("DIFFERENT_BILLING_ADDRESS");
      this.addressForm.reset();
    }
  }

  changeAddress() {
    let socialShopOrderDetails = {
      groupId: this.groupId,
      // addressType: addressType
    }
    this.router.navigate(['/checkout-select-address'], {
      queryParams: { socialShopOrderDetails: JSON.stringify(socialShopOrderDetails) }
    })
  }

}
