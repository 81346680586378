import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // loggedIn = new BehaviorSubject<string>('LOGGED_OUT'); // default parameter will be LOGGED_OUT

  // loggedInData = new Subject();

  public isLoggedIn = new BehaviorSubject<boolean>(false); // login status
  public loggedInUserType = new Subject() // CONSUMER, RETAILER and INFLUENCER

  constructor(private router: Router) { }

  /**
   * @returns which header to be shown
   * @param LOGGED_OUT shows when user logged out
   * @param LOGGED_IN shows when user loggin
   */
  // get isLoggedIn() {
  //   return this.loggedIn.next();
  // }

  /**
   * check auth token exist or not in local storage 
   * @returns true if token exist
   * else false
   */
  public checkAuthToken() {
    return localStorage.getItem('token') !== null;
  }

  public checkUserTypeLoggedIn() {
    return localStorage.getItem('userType')
  }
  public isLogin(): boolean {
    return localStorage.getItem('token') ? true : false
  }
  logout() {
    // this.loggedIn.next('LOGGED_OUT')
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('appointmentData');
    // localStorage.removeItem('ipDetails')
    localStorage.removeItem('userId')
    localStorage.removeItem('email')
    // localStorage.removeItem('myId')
    // this.loggedInData.next('');
    this.isLoggedIn.next(false);
    this.loggedInUserType.next('')
    this.router.navigate(['/homepage']);
  }

}

