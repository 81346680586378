<!-- store locator modal -->
<h2 mat-dialog-title align="center" class="dialog_title_custom">Checkout Our Store Nearby</h2>
<mat-dialog-content class="mat-typography">
    <p>Hurray! We have a store near your location, Don’t miss out to check our collection of dresses and try them on your own.</p>
    <div class="dialog_content_custom">
        <h2 mat-dialog-title>MEMORIES BRIDAL BOUTIQUE - Dublin</h2>
        <div class="mat_dialog_body">
            <div>
                <i class="fa fa-check check_custom"></i><h3>Original Collection</h3>
            </div>
            <div>
                <i class="fa fa-check check_custom"></i><h3>Sequin Sash Collection</h3>
            </div>
            <div>
                <i class="fa fa-check check_custom"></i><h3>Fully Beaded Collection</h3>
            </div>
        </div>
        <div class="modal_address_custom">
            <a class="text-decoration-underline" style="margin: 2em 0;">Website</a>
            <div  class="dialog_body_address">
                <div>
                    <h3>Address :</h3>
                    <p>5a lower Main Street Esker North Lucan, Dublin IRELAND</p>
                </div>
                <div>
                    <div>
                        <h3>Phone:</h3>
                        <p>1234567890</p>
                    </div>
                    <a routerLink="/store-locator" mat-dialog-close>Show on map</a>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="mat_dialog_custom_action">
  <button mat-flat-button mat_button_custom mat-dialog-close>continue to Website</button>
  <button mat-flat-button mat_button_custom [mat-dialog-close]="true">Book an Appointment</button>
</mat-dialog-actions>
<button (click)="openDialog()">data</button>