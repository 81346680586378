import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
import { AuthService } from 'src/app/provider/auth.service';
declare var $: any;

@Component({
  selector: 'app-my-wishlist',
  templateUrl: './my-wishlist.component.html',
  styleUrls: ['./my-wishlist.component.scss']
})
export class MyWishlistComponent implements OnInit {
  shareFavouriteForm: FormGroup
  wishlistId: any;
  ipDetails: any;
  isSkeleton: boolean = true;

  constructor(public authService: AuthService, private router: Router, public commonService: CommonService, public activatedroute: ActivatedRoute) {
    this.ipDetails = this.commonService.getIPDetails();
  }

  ngOnInit(): void {
    this.activatedroute.queryParams.subscribe((res) => {
      console.log(res)
      this.wishlistId = res.wishlistId;
    })
    this.formValidation();
    this.listWishlist();
  }

  formValidation() {
    this.shareFavouriteForm = new FormGroup({

      'name': new FormControl("", Validators.required),
      'email': new FormControl("", [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/)]),
      'additionalMessege': new FormControl("", Validators.required),

    })
  }
  // open(){
  //   $('#newOne').appendTo("body").modal('show');
  // }
  // ngOnDestroy(){
  //   $("body>#newOne").remove();
  // }

  listWishlistArray: any = [];
  productListArray: any = [];
  listWishlist() {
    let apiReqUrl = `${ApiEndPoint.listWishlist + '?currencyCode=' + this.ipDetails.geoplugin_currencyCode
      }`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("change password response ==>", res)
      if (res.responseCode == 200) {
        this.isSkeleton = false
        this.commonService.hideSpinner()
        // this.listWishlistArray = res.result
        this.productListArray = res.result
      } else {
        this.isSkeleton = false
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {
      if (err.responseCode == 404) {
        this.isSkeleton = false
        this.productListArray = []
      }
    })
  }

  // deleteWishlist(wishlistId) {
  //   // let addressId = localStorage.set('addressId')
  //   localStorage.setItem('wishlistId', wishlistId)
  //   let apiReqUrl: any = `${ApiEndPoint.removeWishlist + '?id=' + wishlistId}`
  //   this.commonService.showSpinner()
  //   this.commonService.deleteApi(apiReqUrl, 1).subscribe((res: any) => {
  //     console.log("change password response ==>", res)
  //     if (res.responseCode == 200) {
  //       this.commonService.hideSpinner()
  //       // this.listAddressArray = res.result.docs
  //       this.listWishlist();
  //       localStorage.removeItem('addressId');
  //     } else {
  //       this.commonService.hideSpinner()
  //       this.commonService.errorToast(res.responseMessage)
  //     }
  //   })
  // }

  /**
   * @param ApiEndPoint contains end point url
   * @param 
   */
  addProductWishlist(productId) {
    if (!this.authService.checkAuthToken()) {
      return this.router.navigate(['/signin']);
    } else {
      let apiReqUrl = `${ApiEndPoint.addWishListProduct + '?newProductId=' + productId}`
      this.commonService.showSpinner()
      this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner()
          this.listWishlist()
          //  this.like= !this.like
          //  this.commonService.successToast(res.responseMessage)
        } else {
          this.commonService.hideSpinner()
          this.commonService.errorToast(res.responseMessage)
        }
      })
    }
  }

  navigateToViewMotherDress(productId) {
    this.router.navigate(["/view-mothers-dress"], { queryParams: { productId: productId } })
  }


  /**
    * Skelton for tranding
    * @param count 
    */
  // skeleton ui configuration
  skeletonImageTheme = { height: '600px' }
  skeletonTextTheme = { height: '30px' }

  // Generate Fake Object Array
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}
