import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-real-brides',
  templateUrl: './real-brides.component.html',
  styleUrls: ['./real-brides.component.scss']
})
export class RealBridesComponent implements OnInit {
  realBridesList: void;

  constructor(private commonService:CommonService,private router:Router ) { }

  ngOnInit(): void {
    this.realBridesApi()
  }
  /**
   * @param 
   */
 realBridesApi(){
let apiReqUrl='story/bridesList'
this.commonService.showSpinner()
this.commonService.getApi(apiReqUrl,1).subscribe((res:any)=>{
  if(res.responseCode == 200){
    this.realBridesList = res.result
    this.commonService.hideSpinner()
  }else{
    this.commonService.hideSpinner()
  }
})
 }

 navigateToViewRealBrides(birdesViewId){
   this.router.navigate(['/real-brides-view'],{queryParams:{birdesViewId:birdesViewId}})
 } 
}
