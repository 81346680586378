import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
@Component({
  selector: 'app-cookie-preference',
  templateUrl: './cookie-preference.component.html',
  styleUrls: ['./cookie-preference.component.scss']
})
export class CookiePreferenceComponent implements OnInit {
  result: any;

  constructor(private commoneService: CommonService) {
    window.scrollTo(0, 0)
  }

  ngOnInit(): void {
    this.returnPolicyApi()
  }

  /**
   * @param returnPolicyApi is used to get return policy
   */
  returnPolicyApi() {
    let apiReqUrl = ApiEndPoint.returnPolicy
    this.commoneService.showSpinner()
    this.commoneService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.result = res.result
        this.commoneService.hideSpinner()
      } else {
        this.result = {}
        this.commoneService.hideSpinner()
      }
    }, (err: any) => {
      this.result = {}
      this.commoneService.hideSpinner()
      // this.commoneService.alertError(err.responseMessage)
    })
  }
}
