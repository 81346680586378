import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService, CountryCode } from 'src/app/provider/common.service';
import { AuthService } from 'src/app/provider/auth.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { RegEx } from 'src/app/config/reg-ex';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  countryList: any[];
  myControl = new FormControl('');
  filteredOptions: Observable<CountryCode[]>;
  toggleEye: boolean = true;  // toggle eye for password field
  toggleEyeC: boolean = true;  // toggle eye for confirm password
  signinForm: FormGroup;
  currentUrl: string;
  previousUrl: string;
  isFormSubmitted: boolean = false


  constructor(public dialog: MatDialog, private route: Router, public commonService: CommonService, public authService: AuthService) { }

  ngOnInit(): void {
    this.formValidation()
  }
  formValidation() {
    this.signinForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.pattern(RegEx.firstName)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(RegEx.lastName)]),
      email: new FormControl("", [Validators.required, Validators.pattern(RegEx.email)]),
      password: new FormControl('', [Validators.required, Validators.pattern(RegEx.pswd)]),
      confirmPassword: new FormControl('', [Validators.required]),
      // mobileNumber: new FormControl('', [Validators.pattern(/^[^0][0-9]*$/), Validators.minLength(7)]),
      mobileNumber: new FormControl('', [Validators.pattern(RegEx.phoneNo)]),
      checkTerms: new FormControl('', [Validators.requiredTrue])
    });
    this.countryList = this.commonService.countryListJson;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (value ? this._filter(value) : this.countryList.slice())),
    );
    this.myControl.setValue('+91')
  }
  // formValidation() {
  //   this.signinForm = new FormGroup({
  //     firstName: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z]*$/), Validators.minLength(2)]),
  //     lastName: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2)]),
  //     email: new FormControl("", [
  //       Validators.required,
  //       Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/)
  //     ]),
  //     password: new FormControl('', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]),
  //     confirmPassword: new FormControl('', [Validators.required]),
  //     // mobileNumber: new FormControl('', [Validators.pattern(/^[^0][0-9]*$/), Validators.minLength(7)]),
  //     mobileNumber: new FormControl('', [Validators.minLength(7)]),
  //     checkTerms: new FormControl('', [Validators.requiredTrue])
  //   });
  //   this.countryList = this.commonService.countryListJson;
  //   this.filteredOptions = this.myControl.valueChanges.pipe(
  //     startWith(''),
  //     map(value => (value ? this._filter(value) : this.countryList.slice())),
  //   );
  //   this.myControl.setValue('+91')
  // }

  private _filter(value: string): CountryCode[] {
    const filterValue = value.toLowerCase();
    return this.countryList.filter(option => option.code.toLowerCase().includes(filterValue));
  }

  // on click signup button 
  signUp() {
    if (this.signinForm.invalid) {
      return this.isFormSubmitted = true
    }
    let apiReqUrl = ApiEndPoint.signup
    var signUpData = {
      userType: "CUSTOMER",
      firstName: this.signinForm.value.firstName,
      lastName: this.signinForm.value.lastName,
      email: this.signinForm.value.email,
      countryCode: this.signinForm.value.mobileNumber ? this.myControl.value : '',
      mobileNumber: String(this.signinForm.value.mobileNumber),
      password: this.signinForm.value.password
    }
    this.commonService.removeEmptyKey(signUpData)
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, signUpData, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        localStorage.setItem('email', this.signinForm.value.email)
        this.commonService.hideSpinner()
        this.commonService.alertSuccess(res.responseMessage)
        this.route.navigate(['/signin'])
      } else {
        this.commonService.hideSpinner()
        this.commonService.alertError(res.responseMessage)
      }
    }, (err) => {
      this.commonService.hideSpinner()
      this.commonService.alertError(err.responseMessage)
    })
  }

  navigateToSignin() {
    this.route.navigate(['/signin'])
  }
}


