<div class="main p-2">

    <!--main content form -->
    <div class="d-flex">
        <div class="w-75 p-2">
            <form [formGroup]="chackoutForm" class="p-1 form-custom">
                <div class="pt-2 event_custom">
                    <span class="counting-span">1 of 3</span> <span class="event">SHIPPING ADDRESS</span>
                </div>
                <p class="pl-3"><span style="color:red">*</span>Required Field</p>

                <div class="pl-3">
                    <p class="mb-0"><span style="color:red">*</span>First Name:</p>
                    <input type="text" class="w-75" placeholder="*first name" formControlName="firstName">
                    <span
                        *ngIf="chackoutForm.get('firstName').hasError('required') && (chackoutForm.get('firstName').touched || chackoutForm.get('firstName').dirty)"
                        class="error ml-4">*First name is required.</span>
                </div>

                <div class="pl-3 pt-3">
                    <p class="mb-0"><span style="color:red">*</span>Last Name:</p>
                    <input type="text" class="w-75" placeholder="*last name" formControlName="lastName">
                    <span
                        *ngIf="chackoutForm.get('lastName').hasError('required') && (chackoutForm.get('lastName').touched || chackoutForm.get('lastName').dirty)"
                        class="error ml-4">*Last name is required.</span>
                </div>


                <div class="pl-3 pt-3">
                    <p class="mb-0"><span style="color:red">*</span>Address:</p>
                    <input type="text" class="w-75" placeholder="*address" formControlName="address1" maxlength="264">
                    <span
                        *ngIf="chackoutForm.get('address1').hasError('required') && (chackoutForm.get('address1').touched || chackoutForm.get('address1').dirty)"
                        class="error ml-4">*Address1 is required.</span>
                </div>

                <div class="pl-3 pt-3">
                    <p class="mb-0"><span style="color:red">*</span>Address Line 2:</p>
                    <input type="text" class="w-75" placeholder="*address 2" formControlName="address2">
                    <span
                        *ngIf="chackoutForm.get('address2').hasError('required') && (chackoutForm.get('address2').touched || chackoutForm.get('address2').dirty)"
                        class="error ml-4">*Address2 is required.</span>
                </div>

                <!-- country -->
                <div class="pl-3 pt-3">
                    <p class="mb-0"><span style="color:red">*</span>Country:</p>
                    <select name="" id="" class="select-custom w-75 " formControlName="country">
                        <option value="country">Country</option>
                        <option value="IN">India</option>
                        <option value="US">US</option>
                    </select>
                    <span
                        *ngIf="chackoutForm.get('country').hasError('required') && (chackoutForm.get('country').touched || chackoutForm.get('country').dirty)"
                        class="error ml-4">*Please select your country.</span>
                </div>

                <!-- state -->
                <div class="pl-3 pt-3">
                    <p class="mb-0"><span style="color:red">*</span>State:</p>
                    <select name="" id="" class="select-custom w-75 " formControlName="stateOrProvinceOrRegion">
                        <option value="state">Uttar Pradesh</option>
                        <option value="IN">Madhya Pradesh</option>
                        <option value="US">Arunachal</option>
                    </select>
                    <span
                        *ngIf="chackoutForm.get('stateOrProvinceOrRegion').hasError('required') && (chackoutForm.get('stateOrProvinceOrRegion').touched || chackoutForm.get('stateOrProvinceOrRegion').dirty)"
                        class="error ml-4">*State is required.</span>
                </div>

                <!-- Zip code -->
                <div class="pl-3 pt-3">
                    <p class="mb-0"><span style="color:red">*</span>Zip Code:</p>
                    <input type="text" class="w-75" placeholder="*Zip/Post code" formControlName="zipCode">
                    <span
                        *ngIf="chackoutForm.get('zipCode').hasError('required') && (chackoutForm.get('zipCode').touched || chackoutForm.get('zipCode').dirty)"
                        class="error ml-4">*Zip code is required.</span>
                    <span *ngIf="chackoutForm.get('zipCode').hasError('maxlength')" class="error ml-4">*Zip code max
                        length should be 10.</span>
                </div>

                <!-- Phone -->
                <div class="pl-3 pt-3">
                    <p class="mb-0"><span style="color:red">*</span>Phone:</p>
                    <input type="text" class="w-75" placeholder="*Phone number" formControlName="phoneNumber"
                        maxlength="14">
                    <span
                        *ngIf="chackoutForm.get('phoneNumber').hasError('required') && (chackoutForm.get('phoneNumber').touched || chackoutForm.get('phoneNumber').dirty)"
                        class="error ml-4">*Zip code is required.</span>
                    <span *ngIf="chackoutForm.get('phoneNumber').hasError('pattern')" class="error ml-4">*Please enter
                        valid phone number.</span>
                </div>

                <!-- Email-->
                <div class="pl-3 pt-3">
                    <p class="mb-0"><span style="color:red">*</span>Email:</p>
                    <input type="text" class="w-75" placeholder="*Email" formControlName="email" maxlength="256">
                    <span
                        *ngIf="chackoutForm.get('email').hasError('required') && (chackoutForm.get('email').touched || chackoutForm.get('email').dirty)"
                        class="error ml-4">*Email is required.</span>
                    <span *ngIf="chackoutForm.get('email').hasError('pattern')" class="error ml-4">*Please enter a valid
                        email address.</span>
                </div>

                <div class="w-75">
                    <button mat-raised-button color="primary" class="save-button"
                        routerLink="/checkout-saved-address">Continue to Shipping Method</button>
                </div>
            </form>

            <!-- <div class="pt-2">
                <span class="counting-span">3 of 4</span> <span class="event">SHIPPING METHOD</span>
            </div> -->

            <div class="d-flex shipping-method-content">
                <div>
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
                        <mat-radio-button class="example-radio-button-1" value="Credit or Debit Card"> Standard Shipping
                        </mat-radio-button>
                        <p class="ml-2 destinations">20 - 30 Days to major Destinations</p>
                    </mat-radio-group>
                </div>

                <div>
                    <h3 class="m-0">$80</h3>
                </div>
            </div>

            <div class="d-flex shipping-method-content">
                <div>
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
                        <mat-radio-button class="example-radio-button-1" value="Credit or Debit Card"> Express Shipping
                        </mat-radio-button>
                        <p class="ml-2 destinations">7 - 15 Days to major Destinations</p>
                    </mat-radio-group>
                </div>
                <div>
                    <h3 class="m-0">$150</h3>
                </div>
            </div>

            <div class="p-2">
                <span class="counting-span">2 of 3</span> <span class="event">PAYMENT METHOD</span>
            </div>
            <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
                        <mat-radio-button class="example-radio-button" value="Credit or Debit Card">
                            Credit or Debit Card
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button" value="Paypal">
                            Paypal  </mat-radio-button>
                        <mat-radio-button class="example-radio-button" value="Lorem">
                            Lorem  </mat-radio-button>
                        <mat-radio-button class="example-radio-button" value="Lorem">
                            Lorem  </mat-radio-button>
                </mat-radio-group> -->
        </div>
        <!---right content card-->
        <div class="right-content">
            <mat-card>
                <mat-card-title class="text-center-custom">ORDER SUMMARY</mat-card-title>
                <mat-card-content class="mat-card-content-custom">
                    <mat-card-subtitle>Order Subtotal: </mat-card-subtitle>
                    <mat-card-subtitle>$ 2559.95</mat-card-subtitle>
                </mat-card-content>

                <mat-card-content class="mat-card-content-custom">
                    <mat-card-subtitle>Tax: </mat-card-subtitle>
                    <mat-card-subtitle>TBD</mat-card-subtitle>
                </mat-card-content>

                <mat-card-content class="mat-card-content-custom">
                    <mat-card-subtitle>Shipping & Handling: </mat-card-subtitle>
                    <mat-card-subtitle>$ 40.05</mat-card-subtitle>
                </mat-card-content>

                <mat-divider></mat-divider>

                <mat-card-content class="mat-card-content-custom">
                    <mat-card-subtitle>Shipping & Handling: </mat-card-subtitle>
                    <mat-card-subtitle>$ 40.05</mat-card-subtitle>
                </mat-card-content>
                <button mat-raised-button class="mat-raised-button-custom" color="accent"
                    routerLink="/confirm-payment">CHECKOUT</button>

            </mat-card>
        </div>
    </div>
    <!-- table -->
    <div class="w-75 p-3">
        <mat-action class="d-flex mat-action-custom">
            <p>Your Shopping cart <i class="fas fa-angle-down m-2"></i></p>
            <button mat-raised-button color="primary">Edit</button>
        </mat-action>
    </div>
    <table class="table table-sm">
        <thead>
            <tr>
                <th scope="col">Product </th>
                <th scope="col">Description</th>
                <th scope="col"> Estimated Delivery</th>
                <th scope="col">Quantity</th>
                <th scope="col">Total</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row"><img class="w-75" src="/assets/images/background2.jpg">
                    <p class="text-center">$ 2559.95</p>
                </th>
                <td class="font-custom">Lorem ipsum dolor sit amet consectetur adipiscing elit Brides Dress $159.95
                    <p>color: Petal</p>
                    <p>size: 8</p>
                    <div class="d-flex w-75" style="justify-content:space-between">
                        <p>QTY: 1 </p>
                        <p> <a class="anchor-b-r">Edit</a> </p>
                    </div>
                </td>
                <td class="font-custom">Standard Shipping Delivery (4-10 Business days) 10/06/2021 - 10/11/2021*</td>
                <td class="font-custom"><input type="number" min="1">
                    <p><a class="update">Update</a></p>
                </td>
                <td class="font-custom">$2559.95</td>
            </tr>
        </tbody>
    </table>
</div>