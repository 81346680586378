<div class="wrraper">
  <div class="">
    <mat-card class="card_custom_bg">
    <mat-card class="shadow-none mb-5">
      <mat-card-header class="mat_header_custom">
        <mat-card-title>Original Collection</mat-card-title>
      </mat-card-header>
      <mat-card-content class="card_custom">
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/others/grid6.png" alt="" class="w-100 h-100 box_custom wow bounceInLeft wow rollIn">
        </div>
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/other/bridal.jpeg" alt="" class="w-100 mh-50 box_custom wow bounceInLeft">
          <img mat-card-image  src="./assets/images/signin.jpg" alt="" class="w-100 mh-50 box_custom wow rollIn">
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="shadow-none mb-5">
      <mat-card-header class="mat_header_custom">
        <mat-card-title>Collection 2021</mat-card-title>
      </mat-card-header>
      <mat-card-content class="card_custom">
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/bridal.jpg" alt="" class="w-100 mh-50 box_custom wow bounceInLeft">
          <img mat-card-image  src="./assets/images/bridal.jpeg" alt="" class="w-100 mh-50 box_custom wow rollIn">
        </div>
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/bridal.jpg" alt="" class="w-100 mh-50 box_custom wow rollIn">
          <img mat-card-image  src="./assets/images/signin.jpg" alt="" class="w-100 mh-50 box_custom wow bounceInLeft">
        </div>
        <div class="content_custom">
            <img mat-card-image  src="./assets/images/bridal.jpg" alt="" class="w-100 mh-50 box_custom wow bounceInLeft">
            <img mat-card-image  src="./assets/images/bridal.jpg" alt="" class="w-100 mh-50 box_custom wow bounceInRight">
          </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="shadow-none mb-5">
      <mat-card-header class="mat_header_custom">
        <mat-card-title>Style Library</mat-card-title>
      </mat-card-header>
      <mat-card-content class="styles_card_custom">
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/other/grid6.png" alt="" class="w-25 mh-50 box_custom wow flash ">
          <img mat-card-image  src="./assets/images/bridal.jpeg" alt="" class="w-25 mh-50 box_custom wow rollIn">
          <img mat-card-image  src="./assets/images/signin.jpg" alt="" class="w-25 mh-50 box_custom wow bounceInLeft">
          <img mat-card-image  src="./assets/images/grid9.png" alt="" class="w-25 mh-50 box_custom wow swing">
        </div>
        <div class="content_custom">
          <img mat-card-image  src="./assets/images/bridal.jpeg" alt="" class="w-25 mh-50 box_custom wow pulse">
          <img mat-card-image  src="./assets/images/other/grid6.png" alt="" class="w-25 mh-50 box_custom wow rollIn">
          <img mat-card-image  src="./assets/images/grid9.png" alt="" class="w-25 mh-50 box_custom wow bounceInLeft">
          <img mat-card-image  src="./assets/images/signin.jpg" alt="" class="w-25 mh-50 box_custom wow swing">
        </div>
      </mat-card-content>
    </mat-card>

  </mat-card>
  </div>
</div>