import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-dress-collection-list',
  templateUrl: './dress-collection-list.component.html',
  styleUrls: ['./dress-collection-list.component.scss']
})
export class DressCollectionListComponent implements OnInit {
  bodyListArray: any = [];
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    navText: ['<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"'],
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    // nav: true
    nav: false
    // navigation:true,

  }
  skirtListArray: any;
  socialShopDetails: any;
  ipDetails: any;
  currentConversionRate: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public commonService: CommonService) {
    this.ipDetails = this.commonService.getIPDetails();
  }

  ngOnInit(): void {
    this.bodyList()
    this.skirtList();
    this.getCollectionCategory();

    // route comes from social shoping
    this.activatedRoute.queryParams.subscribe((res) => {
      // console.log(res);
      if (res.socialShopDetails) { this.socialShopDetails = res.socialShopDetails }
    })
  }


  bodyList() {
    let apiReqUrl = ApiEndPoint.listBodice

    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.bodyListArray = res.result
        this.commonService.hideSpinner()
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  // skirt list api
  skirtList() {
    let apiReqUrl = ApiEndPoint.listSkirt
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.skirtListArray = res.result ? res.result : []
        this.commonService.hideSpinner()
      } else {
        this.skirtListArray = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)

      }
    }
    )
  }

  collectionCategoryArray: any = []
  getCollectionCategory() {
    // this.collectionId = collectionId

    // https://node-moteemaids.mobiloitte.com/api/v1/product/listCollection'

    this.commonService.showSpinner();
    this.commonService.getApi(ApiEndPoint.listCollection, 0).subscribe((res: any) => {
      // console.log("add list collection response ==>", res)
      if (res.responseCode == 200) {
        this.collectionCategoryArray = res.result
        // this.commonService.successToast(res.responseMessage);
        // this.router.navigate(['/category/list-category'])
        this.getLowestColectionPrice()
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  startingPrice: number = 0
  getLowestColectionPrice() {
    console.log(this.collectionCategoryArray);
    this.collectionCategoryArray[0].price.mrp.basePrice
    // There's no real number bigger than plus Infinity
    var lowest = Number.POSITIVE_INFINITY;
    var highest = Number.NEGATIVE_INFINITY;
    var tmp;
    for (var i = this.collectionCategoryArray.length - 1; i >= 0; i--) {
      tmp = this.collectionCategoryArray[i].price.mrp.basePrice;
      if (tmp < lowest) lowest = tmp;
      if (tmp > highest) highest = tmp;
    }
    console.log(highest, lowest);
    this.startingPrice = lowest

    // conversion rate
    this.currentConversionRate = this.commonService.getConversionRate(this.ipDetails.geoplugin_currencyCode).subscribe((res: any) => {
      console.log(res);
      if (res['responseCode'] == 200) {
        this.currentConversionRate = res.result
        if (this.startingPrice != 0) {
          this.startingPrice = this.startingPrice * this.currentConversionRate
        }
        // this.priceListArray.map((x: any) => {
        //   return x.minAmount = x.minAmount * res.result, x.maxAmount = x.maxAmount * res.result
        // })
      }
    })
  }

  selectCategory(collectionId) {
    // console.log(collectionId);
    if (this.socialShopDetails) {
      // when page route come from social shoping
      this.router.navigate(['/create-dress'], { queryParams: { collectionId: collectionId, socialShopDetails: this.socialShopDetails } })
    } else {
      // when page route come from header
      this.router.navigate(['/create-dress'], { queryParams: { collectionId: collectionId } })
    }
  }

}

