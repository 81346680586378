<nav class="top_heading_dataa">
    <!-- <ul class="orddr_list">
        <div class="ulContent">
            <div class="dropdown">
                <li class="list_data" routerLink="/user/upload-post"><a class="buttonBackground" (click)="toogleNav()" routerLink="/user/upload-post" routerlinkActive><i class="fa fa-facebook" aria-hidden="true" ></i>
                </a></li>
    
            </div>
            <div class="dropdown">
                <li class="list_data" routerLink="/user/upload-post"><a class="buttonBackground" (click)="toogleNav()" routerLink="/user/upload-post" routerlinkActive><i class="fa fa-twitter" aria-hidden="true" ></i>
                </a></li>
    
            </div>
            <div class="dropdown">
                <li class="list_data" routerLink="/user/upload-post"><a class="buttonBackground" (click)="toogleNav()" routerLink="/user/upload-post" routerlinkActive><i class="fa fa-pinterest" aria-hidden="true" ></i>
                </a></li>
    
            </div>
            <div class="dropdown">
                <li class="list_data" routerLink="/user/upload-post"><a class="buttonBackground" (click)="toogleNav()" routerLink="/user/upload-post" routerlinkActive><i class="fa fa-instagram" aria-hidden="true" ></i>
                </a></li>
    
            </div>
        </div>
    </ul> -->
    <label class="logo">
        <div class="logoImageNew">
          <img class="logoImage" src="../../../../assets/images/logoNewTransParent.png" alt="logo" routerLink="/homepage"
            routerlinkActive>
        </div>
      </label>
    <ul class="ordr_list">
        <div class="ulContent">
            <div class="dropdown">
                <li class="list_data" routerLink="/create-chat-room" routerlinkActive><a class="buttonBackground" (click)="toogleNav()" >Guest</a></li>
    
            </div>
            <div class="dropdown">
                <li class="list_data" routerLink="/user/upload-post">
                    <!-- <a class="buttonBackground"  routerLink="/user/upload-post" routerlinkActive> -->
                    <div class="input-group search_top_detail">
                        <div class="form-outline">
                          <input type="search" id="form1" class="form-control inp_detail_on" placeholder="Search for products"/>
                          <!-- <label class="form-label" for="form1">Search</label> -->
                        </div>
                        <button type="button" class="btn btn-primary search_btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                <!-- </a> -->
            </li>
    
            </div>
            <!-- <div class="dropdown">
                <li class="list_data" routerLink="/create-chat-room" routerlinkActive><a class="buttonBackground" >Chat-room</a></li>
    
            </div> -->
            <div class="dropdown">
                <li class="list_data" routerLink="/user/upload-post"><a class="buttonBackground" (click)="toogleNav()" routerLink="/user/upload-post" routerlinkActive><i class="fa fa-bell ico_ico" aria-hidden="true" ></i>
                </a></li>
    
            </div>
            <div class="dropdown">
                <li class="list_data" routerLink="/my-wishlist" routerlinkActive><a class="buttonBackground" (click)="toogleNav()" ><i class="fa fa-heart ico_ico" aria-hidden="true" ></i>
                </a></li>
    
            </div>
            <div class="dropdown">
                <li class="list_data" routerLink="/my-shopping-bag" routerlinkActive><a class="buttonBackground" (click)="toogleNav()" ><i class="fa fa-shopping-bag ico_ico" aria-hidden="true" ></i>
                </a></li>
    
            </div>
            <div class="dropdown">
                <li class="list_data" routerLink="/my-profile" routerlinkActive><a class="buttonBackground" (click)="toogleNav()" ><i class="fa fa-home ico_ico" aria-hidden="true" ></i>
                </a></li>
    
            </div>
        </div>  
      </ul>
</nav>

<nav class="head_top_data">
    <!-- <input type="checkbox" id="check" [(ngModel)]="check">
    <label for="check" class="checkbtn">
    <i class="fa fa-align-justify" ></i>
  </label> -->
    <!-- <label class="logo">
    <div class="logoImageNew">
      <img class="logoImage" src="../../../../assets/images/logoNewTransParent.png" alt="logo" routerLink="/homepage"
        routerlinkActive>
    </div>
  </label> -->
  
    <ul class="unor_list">
        <div class="ulContent">
            
            <div class="dropdown">
                <li routerLink="/motee-collection-list">
                    <a class="buttonBackground" (click)="toogleNav()" routerLink="/motee-collection-list" routerlinkActive>
                   Customizable Collection</a></li>

            </div>
            <div class="dropdown ">
                <li routerLink="/bridal-collection-list">
                    <a class="buttonBackground" (click)="toogleNav()" routerLink="/bridal-collection-list" routerlinkActive>
                        Bridal Collection</a></li>
                <!-- <div class="dropdown-content">

                    <a class="dropdownItem" (click)="toogleNav()" routerLink="/activity/tranding" routerlinkActive>Trending</a>
                    <a class="dropdownItem" (click)="toogleNav()" routerLink="/activity/local">Local</a>
                    <a class="dropdownItem" (click)="toogleNav()" routerLink="/activity/following">Following</a>
                </div> -->
            </div>
            <div class="dropdown">
                <li routerLink="/bridesmaid-collection-list">
                    <a class="buttonBackground" (click)="toogleNav()" routerLink="/bridesmaid-collection-list" routerlinkActive>
                        Bridesmaid Collection</a></li>
                <!-- <li class="dropbtn">
                    <span class="iconify" data-icon="ic:twotone-category"></span>

                    <a class="dropdown-toggle"> Category</a></li>
                <div class="dropdown-content">

                    <a class="dropdownItem"  (click)="toogleNav()" > ctegory</a>

                </div> -->
            </div>
            <div  class="dropdown" routerLink="/message">

                <!-- <li class="dropbtn">

                    <li> <span class="iconify mes" data-icon="bi:chat-square-dots"></span><a class="buttonBackground" (click)="toogleNav()" routerlinkActive>Messaging</a></li> -->
                    <li routerLink="/mothers-collection-list">
                        <a class="buttonBackground" (click)="toogleNav()" routerLink="/mothers-collection-list" routerlinkActive>
                            Mother of Bride</a></li>

            </div>
            <div  (click)="toogleNav()" class="dropdown">

                <!-- <li><span class="iconify mes" data-icon="clarity:notification-solid"></span> <a class="buttonBackground" (click)="toogleNav()" routerlinkActive>Notifications</a></li> -->

                <li routerLink="/catelogue-collection-list">
                    <a class="buttonBackground" (click)="toogleNav()" routerLink="/catelogue-collection-list" routerlinkActive>
                        Catalogue</a></li>
            </div>

            <div  class="dropdown">
                <li><a class="buttonBackground" (click)="toogleNav()" routerLink="/swatches-collection-list" routerlinkActive>Swatches</a></li>
            </div>


            <!-- <div  class="dropdown">
                <li  routerLink="/user/upload-post"><a class="buttonBackground" (click)="toogleNav()" routerLink="/user/upload-post" routerlinkActive><i class="fa fa-search ico_ico" aria-hidden="true" ></i>
                </a></li>
            </div> -->

        </div>
    </ul>
</nav>