<h2 mat-dialog-title class="top_text">
    Let's Get Started !</h2>
<mat-dialog-content class="mat-typography text_data_para">
  <h3>Select  your preference to display prices for Guest users :</h3>
  
  <div class="form-check mt-2">
    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
    <label class="form-check-label" for="exampleRadios1">
        RRP (Recommended Retail Price)
    </label>
  </div>
  <div class="form-check mt-2">
    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
    <label class="form-check-label" for="exampleRadios2">
        Whole Sale
    </label>
  </div>

  
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <button mat-button mat-dialog-close class="cancel_btn">Cancel</button> -->
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="sbt_btn">Save</button>
</mat-dialog-actions>

<button mat-button (click)="openDialog()">Open dialog</button>