<div class="wrapper">
    <div class="w-t pt-5">
        <!-- <div class="header">
            <h2 class="header-text">Motee Maids Bridesmaid Fabric Swatches</h2>
        </div> -->
        <div class="swatches">
            <div class="d-flex">
                <div class="col-md-3" style="max-width: 21%;">
                    <div class="color mt-5">
                        <h4 class="header-text text-left">Filters</h4>

                        <mat-accordion>

                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="step === 0" (opened)="setStep(0)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        COLOUR
                                    </mat-panel-title>
                                    <!-- <mat-panel-description>
                              This is a summary of the content
                            </mat-panel-description> -->
                                </mat-expansion-panel-header>
                                <!-- <p>This is the primary content of the panel.</p> -->
                                <div class="header-text">
                                    <div class="p-2 d-flex justify-content-between">
                                        <!-- <span class="header-text">BLACK</span> -->
                                        <p class="black"></p>
                                        <p class="blue"></p>
                                        <p class="green"></p>
                                        <p class="gray"></p>
                                        <p class="brown"></p>
                                        <p class="red"></p>
                                        <p class="gold"></p>
                                        <p class="pink"></p>
                                        <p class="purple"></p>
                                        <p class="navy"></p>
                                    </div>

                                </div>
                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>
                    <div class="color mt-3">

                        <mat-accordion>

                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="step === 0" (opened)="setStep(0)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        FABRIC
                                    </mat-panel-title>
                                    <!-- <mat-panel-description>
                              This is a summary of the content
                            </mat-panel-description> -->
                                </mat-expansion-panel-header>
                                <!-- <p>This is the primary content of the panel.</p> -->
                                <div class="header-text">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Crepe</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1"> Embroidery</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Embroidered Tulle</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Faille</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1"> Fil Coupe</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Lace</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Moire</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1"> Organza</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Radzimir</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1"> Silk-Faille</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1"> Silk-Wool</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Silk-Tulle</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Tulle</label>
                                    </div>

                                </div>
                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>
                </div>
                <div class="col-md-9 pt-3">
                    <div class="top-data d-flex">
                        <h1>Bridal</h1>
                        <h4 style="padding-left: 10px;">Simply iconic. The true namesake collection, Motee Maids Bridal is simply modern, highlighted by signature couture design techniques and hand-touched by the master seamstresses at our New York atelier.
                            <br>
                            <strong>See the collection on you with Motee Maids Virtual Try-On</strong>
                        </h4>
                    </div>
                    <div class="row">
                        <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Marketing_Shots_Shoot_2/Diamond_Beading.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">Black</p>
                            </div>
                            <div class="row m-0">
                                <p class="redColor"></p>
                                <p class="greenColor"></p>
                                <p class="blueColor"></p>
                                <p class="goldColor"></p>
                            </div>
                        </div>
                        <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Shoot_4additionalImages/swt3.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">Cayenne</p>
                            </div>
                            <div class="row m-0">
                                <p class="redColor"></p>
                                <p class="greenColor"></p>
                                <p class="blueColor"></p>
                                <p class="goldColor"></p>
                            </div>
                        </div>
                        <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Shoot_4additionalImages/swt4.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">Champagne</p>
                            </div>
                            <div class="row m-0">
                                <p class="redColor"></p>
                                <p class="greenColor"></p>
                                <p class="blueColor"></p>
                                <p class="goldColor"></p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Shoot_4additionalImages/swt1.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">Copper</p>
                            </div>
                            <div class="row m-0">
                                <p class="redColor"></p>
                                <p class="greenColor"></p>
                                <p class="blueColor"></p>
                                <p class="goldColor"></p>
                            </div>
                        </div>
                        <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Shoot_4additionalImages/swt2.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">French-Blue</p>
                            </div>
                            <div class="row m-0">
                                <p class="redColor"></p>
                                <p class="greenColor"></p>
                                <p class="blueColor"></p>
                                <p class="goldColor"></p>
                            </div>
                        </div>
                        <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Shoot_3/Tulle_embroidered.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">Fuchsia</p>
                            </div>
                            <div class="row m-0">
                                <p class="redColor"></p>
                                <p class="greenColor"></p>
                                <p class="blueColor"></p>
                                <p class="goldColor"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>