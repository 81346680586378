<div class="wrapper">
    <!-- first section ("video part")-->
    <div id="shopify-section-slideshow"
        class="shopify-section index-section index-section--flush index-section--slideshow">
        <div data-section-id="slideshow" data-section-type="slideshow-section">
            <div id="SlideshowWrapper-slideshow" class="slideshow-wrapper" role="region" aria-label="slideshow"
                aria-describedby="slideshow-info" tabindex="-1">
                <div class="slideshow slideshow--medium slick-initialized slick-slider" id="Slideshow-slideshow"
                    data-autorotate="true" data-speed="5000" data-adapt-height="false"
                    data-slide-nav-a11y="Load slide [slide_number]">
                    <div class="slick-list draggable">
                        <div class="slick-track" style="opacity: 1; width: 100%;">
                            <div class="slideshow__slide slideshow__slide--b1da9218-16d8-44d2-b306-f94830e76471 slick-slide slick-current slick-active"
                                data-slick-index="0" aria-hidden="false" id="slickSlide00" tabindex="-1"
                                style="width: 100%; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">
                                <div class="slideshow__video js lazyloaded">
                                    <!-- <video class="feature-video" [src]="homepageResultArray?.video" autoplay loop muted
                                        preload playsinline>
                                    </video> -->
                                    <!-- <video loop muted autoplay oncanplay="this.play()"
                                        onloadedmetadata="this.muted = true">
                                        <source [src]="homepageResultArray?.video" type="video/mp4">
                                    </video> -->
                                    <video *ngIf="homepageResultArray?.video" class="feature-video"
                                        [src]="homepageResultArray?.video" playsinline loop autoplay
                                        [muted]="true"></video>
                                </div>

                                <div
                                    class="slideshow__text-wrap slideshow__text-wrap--desktop  slideshow__hide-caption">
                                    <div
                                        class="slideshow__text-content slideshow__text-content--vertical-center text-center caption-align--right">
                                        <div class="page-width"></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="slideshow__controls"></div>
                </div>
            </div>
            <div class="slideshow__text-wrap slideshow__text-wrap--mobile" style="display: none;"></div>
        </div>
    </div>

    <!-- second section (text and input field text) -->
    <!-- <div class="top-cls">
        <div class="new-sec-part">
            <div class="txt-clr">
                <h2 class="second-header-text new-style new-style-cls">HERE WE GO</h2>
            </div>
            <div class="text-center">
                <h4 class="thitd-header-text new-fnt-styl">Sign up to receive style advice on wedding wear that just
                    fits - your style, your vision, you.
                </h4>
            </div>

            <div class="top-head d-flex justify-content-center">
                <form [formGroup]="subscribeForm">
                    <div>
                        <div>
                            <i class="fa fa-envelope new-ico-env" aria-hidden="true"></i>
                            <input type="text" class="new-first" placeholder="Email Address" formControlName="email"
                                maxlength="60">
                        </div>
                        <div style="text-align: left;margin-left: 9%;">

                            <span class="error" *ngIf="subscribeForm.get('email').hasError('pattern')">*Please enter
                                valid address.</span>
                        </div>
                    </div>
                </form>
                <div>
                    <button type="submit" class="new-btnP header-text buttonGlobal"
                        (click)="subscribeUser()">Subscribe</button>
                </div>
            </div>
        </div>
        <br>

    </div> -->

    <!-- third section image section -->
    <div class="third-details">
        <div class="txt-clr txt-crl">
            <h2 class="second-header-text new-style toop">Explore the collections</h2>
        </div>
        <div class="">
            <div class="d-flex first-top-list">
                <div class="dataa-first-card">
                    <div class="first-img" (click)="navigateToLatestMoteeCollection()">
                        <!-- <div class="first-img" routerLink="/latest-motee-collection"> -->
                        <img class="first-img-show" [src]="homepageResultArray?.moteeMaidImage?.webImage" alt="">
                        <div class="first-bord-text">
                            <span class="second-header-text new-style">Motee Maids</span>
                        </div>
                    </div>
                </div>
                <div class="dataa-first-card">
                    <div class="second-img" (click)="navigateToLatestMoteeCollection()">
                        <!-- <div class="second-img" routerLink="/latest-motee-collection"> -->

                        <img class="first-img-show" [src]="homepageResultArray?.collectionImage?.webImage" alt="">
                        <div class="collection-grid-item__title-wrapper">
                            <div class="first-bord-text">
                                <span class="second-header-text new-style">
                                    Latest Collections

                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- fourth section image section -->


    <div class="fourth-details">
        <!-- <div class="txt-clr">
    <h2>Explore the collections</h2>
  </div> -->
        <div class="">
            <div class="row">
                <!-- <div class="fourth-sect-img"> -->
                <figure class="fourth-sect-img">
                    <img class="fourth-first-img-show" [src]="homepageResultArray?.dreeCode1?.webImage" alt="">

                    <!-- <img class="fourth-first-img-show" src="assets/images/Fully_Beaded_Collection_Shoot_2/Emma_Close_Up.jpg" alt=""> -->

                    <div class="hero__inner daata-show-cads">
                        <div class="hero_upper">
                            <span class="mega-subtitle new-stylish-font">
                                <em>Motee Maids</em>
                            </span>
                            <h1 class="new-stylish-font-new"
                                style="font-size: 31px;line-height: 41px;padding-left: 24px;font-family: 'Cormorant Garamond', serif;font-weight: 500;">
                                Dress Code</h1>
                            <div class="hero_inn light-low-font">
                                <p>"{{homepageResultArray?.dreeCode1?.description}}"</p>
                            </div>
                            <a class="new-hero header-text buttonGlobal"
                                (click)="navigateToLatestMoteeCollection()">Shop Little White Dress</a>
                            <!-- <a class="new-hero header-text" routerLink="/motee-maids-bridal">Shop Little White Dress</a> -->

                        </div>
                    </div>
                </figure>
                <!-- </div> -->
                <!-- <div class="second-img">
        <img class="first-img-show" src="assets/images/SEQUIN_SASH_COLLECTION_JPEG/Emma_Sequin_Sash_Back.jpg" alt="">
      </div> -->
            </div>
        </div>

    </div>

    <!-- fifth section image section -->
    <div class="fifth-details">
        <!-- <div class="txt-clr">
    <h2>Explore the collections</h2>
  </div> -->
        <div class="">
            <div class="row">
                <figure class="fifth-sect-img">
                    <img class="fifth-first-img-show bounce" [src]="homepageResultArray?.dreeCode2?.webImage" alt="">
                    <!-- <div class="
                     -->
                    <!-- <div class="hero__inner" style="left: -11px;"> -->
                    <div class="hero__inner new-data-list">

                        <!-- <div class="hero_upper-sec"> -->
                        <div class="hero_upper">
                            <span class="mega-subtitle-sec new-stylish-font" style="padding-left: 27px;">
                                <em>Motee Maids</em>
                            </span>
                            <h1 class="new-stylish-font" style="font-size: 31px;line-height: 41px;padding-left: 24px;font-family: 'Cormorant Garamond', serif;
                            font-weight: 500;">Dress Code</h1>
                            <div class="hero_inn light-low-font ">
                                <p class="">"{{homepageResultArray?.dreeCode2?.description}}"</p>
                            </div>
                            <!-- <a class="new-hero header-tex" routerLink="/motee-maids-bridal"> -->
                            <a class="new-hero header-text buttonGlobal" (click)="navigateToLatestMoteeCollection()">

                                Shop Little White Dress
                            </a>
                        </div>
                    </div>
                </figure>

            </div>
        </div>

    </div>

    <!-- sixth section image section -->
    <div class="six-details">
        <!-- <div class="txt-clr">
    <h2>Explore the collections</h2>
  </div> -->
        <div class="">
            <div class="row">
                <figure class="six-sect-img">
                    <img class="six-first-img-show" [src]="homepageResultArray?.evening?.webImage" alt="">
                    <div class="hero__inner-sec_new">
                        <div class="hero_upper-sec">
                            <span class="mega-subtitle-sec motee-new-font-fam" style="padding-left: 27px;">
                                <em>Motee Maids</em>
                            </span>
                            <h1 class="new-data-evng"
                                style="font-size: 31px;padding-left: 27px;font-family: 'Cormorant Garamond', serif;font-weight: 500;">
                                Evening</h1>
                            <div class="hero_inn-sec">
                                <p class="light-low-font" style="font-size: 19px;">
                                    "{{homepageResultArray?.evening?.description}}"</p>
                            </div>
                            <!-- <a class=" data-shop-btn header-text"  routerLink="/evening-collection"> SHOP NOW </a> -->
                            <a class=" data-shop-btn header-text buttonGlobal"
                                (click)="navigateToLatestMoteeCollection()"> SHOP NOW </a>

                        </div>
                    </div>
                </figure>

            </div>
        </div>

    </div>

    <!-- seven section text section -->
    <div class="seven-text">
        <div class="cont">
            <div class="row  crads-new-one">
                <div class="new-carded-text">
                    <h5 class=" card-new-text-show">Design your own dress</h5>
                    <p class="new-data-text-cls-top">We got the tools to help you create your dream wedding dress with
                        the click of a button. Introducing</p>
                    <!-- <a class="new-data-text-cls-top motte-new" routerLink="/dress-collection">MoteeMaids X You.</a> -->
                    <a class="new-data-text-cls-top motte-new" (click)="navigateToLatestMoteeCollection()">MoteeMaids X
                        You.</a>

                </div>
                <div class="new-carded-text">
                    <h5 class="card-new-text-show">Have a question?</h5>
                    <!-- <p class="new-data-text-cls-top">Chat with our Live Stylist at MoteeMaids.com, see our <a class="new-data-text-cls-top motte-new" routerLink="/faq">FAQ's</a> or reach out to</p> -->
                    <p class="new-data-text-cls-top">Chat with our Live Stylist at MoteeMaids.com, see our <a
                            class="new-data-text-cls-top motte-new"
                            (click)="navigateToLatestMoteeCollection()">FAQ's</a> or reach out to</p>

                    <a class="new-data-text-cls-top"
                        href="mailto:customerservice@MoteeMaids.com">customerservice@MoteeMaids.com.</a>
                </div>
                <div class="new-carded-text">
                    <h5 class="card-new-text-show">Where to shop?</h5>
                    <p class="new-data-text-cls-top">Shop at MoteeMaids.com or design it and try on at a store near you.
                    </p>
                    <a class="new-data-text-cls-top motte-new" (click)="navigateToLatestMoteeCollection()">MoteeMaids
                        Salon | Store Locator.</a>
                    <!-- <a class="new-data-text-cls-top motte-new" routerLink="/store-locator">MoteeMaids Salon | Store Locator.</a> -->
                </div>
            </div>

        </div>
    </div>

    <!-- eight section text section -->
    <div class="eight-text">
        <div class="cont">
            <div class="row justify-content-between mt-10">
                <div class="pt">
                    <!-- <h5>Design your own dress</h5> -->
                    <p class="new-down-text">“She was the queen of the understated wedding dress.”</p>
                    <h1 class="font-normal new-down-sec">brides</h1>
                </div>
                <div class="pt">

                    <p class="new-down-text">“...timeless, minimalist aesthetic...”</p>
                    <h1 class="font-normal new-down-sec">vogue</h1>
                </div>
                <div class="pt">

                    <p class="new-down-text">“Known for classic silhouettes with a minimal flair</p>
                    <h1 class="font-normal new-down-sec">bazaar</h1>
                </div>
            </div>
        </div>
    </div>

    <!-- nine section -->
    <div class="top-cls-new">
        <div class="new-sec-part">
            <div class="txt-clr">
                <h2 class="second-header-text new-style new-style-cls">HERE WE GO</h2>
            </div>
            <div class="text-center">
                <h4 class="thitd-header-text new-fnt-styl">Sign up to receive style advice on wedding wear that just
                    fits - your style, your vision, you.
                </h4>
            </div>
            <!-- <div class="top-head d-flex justify-content-center">
            <form [formGroup]="subscribeUserForm">

            <i class="fa fa-envelope new-ico-env" aria-hidden="true"></i>

            <input type="text" class="new-first" placeholder="Email Address" formControlName="subsrcibe" maxlength="60">
               <button type="submit" class="new-btnP header-text buttonGlobal" (click)="subscribeUser()" [disabled]="!subscribeUserForm.valid">Subscribe</button>
            </form>
           </div> -->
            <div class="top-head d-flex justify-content-center">
                <form [formGroup]="subscribeUserForm">
                    <div>
                        <div>
                            <i class="fa fa-envelope new-ico-env" aria-hidden="true"></i>
                            <input type="text" class="new-first" placeholder="Email Address" formControlName="email"
                                maxlength="256">
                        </div>
                        <div style="text-align: left;margin-left: 9%;">
                            <!-- <span class="error"
                                *ngIf="(subscribeUserForm.get('email').dirty)">*Please
                                enter email address.</span> -->
                            <!-- <span class="error"
                                *ngIf="subscribeUserForm.get('email').hasError('pattern') || isSubscribedFormSubmit">*Please
                                enter
                                valid email address.</span> -->
                            <span class="error" *ngIf="
                                                    subscribeUserForm.get('email').hasError('pattern') &&
                                                    (subscribeUserForm.get('email').touched ||
                                                      subscribeUserForm.get('email').dirty || isSubscribedFormSubmit)
                                                  ">*Please enter a valid email address.
                            </span>
                        </div>
                    </div>
                </form>
                <div>
                    <button type="submit" class="new-btnP header-text buttonGlobal"
                        (click)="subscribeUser()">Subscribe</button>
                </div>
            </div>
        </div>
        <br>

    </div>
</div>