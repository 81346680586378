import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService, CountryCode } from 'src/app/provider/common.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-forgot-password-influencer',
  templateUrl: './forgot-password-influencer.component.html',
  styleUrls: ['./forgot-password-influencer.component.scss']
})
export class ForgotPasswordInfluencerComponent implements OnInit {
  forgotForm: FormGroup;
  countryList: any[];
  filteredOptions: Observable<CountryCode[]>;
  myControl = new FormControl('+91');
  isSubmittedForm:boolean = false
  constructor(public commonService: CommonService, private router:Router) { }

  ngOnInit(): void {
    this.formValidation()
  }

  formValidation(){
  this.forgotForm = new FormGroup({
    emailId: new FormControl("", [
      Validators.required,
      Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/),

    ]),

  });
  this.countryList = this.commonService.countryListJson;
  this.filteredOptions = this.myControl.valueChanges.pipe(
    startWith(''),
    map(value => (value ? this._filter(value) : this.countryList.slice())),
  );
 
}
private _filter(value: string): CountryCode[] {
  const filterValue = value.toLowerCase();    
  return this.countryList.filter(option => option.code.toLowerCase().includes(filterValue));
}

navigateToOtp(){
  this.router.navigate(['/otp'])
}

/**
 * @param forgotPAsswordApi function is used to forgot influencer password 
 * */
forgotPasswordApi(){
  if(this.forgotForm.invalid){
    this.isSubmittedForm = true
    return
  }
  let apiReqUrl= ApiEndPoint.forgotPassword
 let apiReqData={
  email:this.forgotForm.value.emailId
 }
 this.commonService.showSpinner()
 this.commonService.postApi(apiReqUrl,apiReqData,0).subscribe((res:any)=>{
if(res['responseCode'] == 200){
  this.commonService.hideSpinner()
  this.commonService.alertSuccess(res.responseMessage)
  console.log(this.forgotForm.value.emailId); 
  this.router.navigate(['/otp-verification-influencer'],{queryParams:{email:this.forgotForm.value.emailId}})
}else{
  this.commonService.hideSpinner()
  this.commonService.errorToast(res.responseMessage)
}
 },(err)=>{
  console.log(err);
  this.commonService.hideSpinner()
  if(err.responseCode == 404){
    this.commonService.alertError(err.responseMessage)
  }
})
}
}
