<div class="wrapper">
  <div>
    <div class="logo">
      <div class="logoimg">
        <img
          class="logoImage"
          src="../../../../assets/images/motee-maids-log.png"
          alt="motee maid"
        /><span class="header-text logo-text"
          ><strong>X</strong><strong>You</strong></span
        >
      </div>
      <div>
        <h4 class="header-text">CUSTOMIZE YOUR BRIDAL GOWN</h4>
        <p class="header-text">
          Create not just the perfect dress, but the perfect dress for you, with
          Motee Maids's library of silhouettes, styles and fabrics.
        </p>
      </div>
    </div>

    <div class="first_left_img">
      <mat-card class="example-card p-0">
        <div class="row">
          <div class="col-2">
            <figure
              *ngIf="allStaticImage?.frontImage?.webImage"
              class="first-img-fig"
              (click)="changeImg(allStaticImage?.frontImage?.webImage)"
            >
              <img
                class="img-fist"
                [src]="allStaticImage?.frontImage?.webImage"
                alt=""
              />
            </figure>
  
            <figure
              *ngIf="allStaticImage?.backImage?.webImage"
              class="first-img-fig"
              (click)="changeImg(allStaticImage?.backImage?.webImage)"
            >
              <img
                class="img-fist"
                [src]="allStaticImage?.backImage?.webImage"
                alt=""
              />
            </figure>
  
            <figure
              *ngIf="allStaticImage?.leftImage?.webImage"
              class="first-img-fig"
              (click)="changeImg(allStaticImage?.leftImage?.webImage)"
            >
              <img
                class="img-fist"
                [src]="allStaticImage?.leftImage?.webImage"
                alt=""
              />
            </figure>
  
            <figure
              *ngIf="allStaticImage?.rightImage?.webImage"
              class="first-img-fig"
              (click)="changeImg(allStaticImage?.rightImage?.webImage)"
            >
              <img
                class="img-fist"
                [src]="allStaticImage?.rightImage?.webImage"
                alt=""
              />
            </figure>
          </div>
          <div class="col-10">
            <figure class="image-container">
              <img
                *ngIf="isLoading"
                class="create-dress-loader"
                src="assets/images/other/custom-loader.gif"
                alt=""
              />
              <img
                *ngIf="showStaticImage && !isLoading"
                class="dress-image"
                [src]="showStaticImage"
                alt=""
                srcset=""
              />
            </figure>
          </div>
        </div>
   

   

        <!-- <lib-ngx-image-zoom *ngIf="bodyId && !skirtId"
                    [thumbImage]="createCombinationImageUrl?.rawImage?.webImage"
                        [fullImage]="createCombinationImageUrl?.rawImage?.webImage"
                        [magnification]="1"
                        [enableScrollZoom]="true"
                        [lensWidth]="200"
                        [ngClass]="image-view-top-half"
                        ></lib-ngx-image-zoom>

                        <lib-ngx-image-zoom *ngIf="skirtId && !bodyId"
                        [thumbImage]="createCombinationImageUrl?.rawImage?.webImage"
                        [fullImage]="createCombinationImageUrl?.rawImage?.webImage"
                        [magnification]="0.4"
                        [enableScrollZoom]="true"
                        [circularLens]="true"
                        [lensWidth]="100"
                        [lensHeight]="100"
                        [ngClass]="image-view-bottom-half"
                        ></lib-ngx-image-zoom>

                        <lib-ngx-image-zoom *ngIf="skirtId && !bodyId"
                        [thumbImage]="createCombinationImageUrl?.rawImage?.webImage"
                        [fullImage]="createCombinationImageUrl?.rawImage?.webImage"
                        [magnification]="0.4"
                        [enableScrollZoom]="true"
                        [circularLens]="true"
                        [lensWidth]="100"
                        [lensHeight]="100"
                        [ngClass]="image-view-bottom-half"
                        ></lib-ngx-image-zoom> -->
        <!-- <div mat-card-avatar class="example-header-image" (click)="openDialog()"></div> -->
        <!-- <div mat-card-avatar class="booto_saved_post"></div> -->
        <!--     
    <div class="complete_detail">
      <div class="body_size_detail">
        
      </div>
    </div> -->
        <!-- <mat-card-content>

      <p>
        The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
        A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
        bred for hunting.
      </p>
    </mat-card-content> -->
        <!-- <mat-card-actions>
      <div mat-card-avatar class="booto_saved_post"></div>

    </mat-card-actions> -->
        <!-- <mat-card class="list_details">
                    <div class="tite_list">
                        <mat-card-title>Body</mat-card-title>
                        <mat-card-title>: &nbsp; &nbsp; 0.00 $</mat-card-title>

                    </div>
                    <hr>
                    <div class="tite_list">
                        <mat-card-title>Skirt</mat-card-title>
                        <mat-card-title>: &nbsp; &nbsp; 0.00 $</mat-card-title>

                    </div>
                    <hr>
                    <div class="tite_list">
                        <mat-card-title>Emblishment </mat-card-title>
                        <mat-card-title> : &nbsp; &nbsp; 0.00 $</mat-card-title>

                    </div>
                    <hr>
                    <div class="tite_list">
                        <mat-card-title>Colour</mat-card-title>
                        <mat-card-title>: &nbsp; &nbsp; 0.00 $</mat-card-title>

                    </div>
                    <hr>
                    <div class="tite_list">
                        <mat-card-title>Total</mat-card-title>
                        <mat-card-title> : &nbsp; &nbsp; 0.00 $</mat-card-title>

                    </div>
                </mat-card> -->
      </mat-card>

      <div class="accord_details">
        <mat-accordion>
          <!-- <mat-expansion-panel hideToggle> -->
          <mat-expansion-panel
            *ngIf="bodyListArray?.length || skirtListArray?.length"
            [expanded]="step === 0"
            (opened)="setStep(0)"
            hideToggle
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="justify-content-center">
                Body And Skirt
              </mat-panel-title>
              <!-- <mat-panel-description>
          This is a summary of the content
        </mat-panel-description> -->
            </mat-expansion-panel-header>
            <h1 class="header-text">Bodice</h1>
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let item of bodyListArray">
                <img
                  class="four_img_list"
                  [src]="item?.rawImage?.webImage"
                  alt=""
                  (click)="selectBody(item)"
                />
                <i
                  [ngClass]="{ 'slide-image': !item?.isSelected }"
                  class="fa fa-check right-tick"
                  aria-hidden="true"
                ></i>
                <!-- {{item?.isSelected}} -->
                <!-- <i [ngClass]="{'slide-image' : !item?.isSelected}" class="fa fa-pinterest" aria-hidden="true"></i> -->
                <!-- <input class="right-tick" type="radio" name="bodice" [checked]="item?.isSelected"> -->
                <p class="text_images_para">{{ item?.bodyName }}</p>
              </ng-template>
            </owl-carousel-o>
            <!-- <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner" *ngFor="let item of bodyListArray">
                              <div class="carousel-item active">
                                <img class="four_img_list" [src]="item?.rawImage?.webImage" alt=""
                                (click)="selectBody(item)">
                                <input class="right-tick" type="radio" name="bodice" [checked]="item?.isSelected">
                                <p class="text_images_para">{{item?.bodyName}}</p>
                              </div>
                             
                            </div>
                           <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </button>
                          </div> -->
            <h1 class="header-text mt-2">Skirt</h1>
            <div class="mt-2">
              <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let item of skirtListArray">
                  <img
                    class="four_img_list"
                    [src]="item?.rawImage?.webImage"
                    alt=""
                    (click)="selectSkirt(item)"
                  />
                  <i
                    [ngClass]="{ 'slide-image': item?._id != skirtId }"
                    class="fa fa-check right-tick"
                    aria-hidden="true"
                  ></i>
                  <p class="text_images_para">{{ item?.skirtName }}</p>
                </ng-template>
              </owl-carousel-o>
              <!-- <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner" *ngFor="let item of skirtListArray">
                                  <div class="carousel-item active" >
                                    <img class="four_img_list" [src]="item?.rawImage?.webImage" alt=""
                                        (click)="selectSkirt(item?._id)">
                                    <p class="text_images_para">{{item?.skirtName}}</p>
                                  </div>
                                 
                                </div>
                               <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </button>
                              </div> -->

              <div class="scasl-search-btn">
                <button
                  class="locate-search buttonGlobal"
                  id="scapl-geocode-btn"
                  type="button"
                  [disabled]="!bodyId || !skirtId"
                  (click)="nextStep()"
                >
                  NEXT STEP
                </button>
              </div>
            </div>
            <!-- <p>This is the primary content of the panel.</p> -->
          </mat-expansion-panel>

          <!-- <div *ngIf="armListArray?.length" class="second_list_data">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title class="justify-content-center">
                                    Arm
                                </mat-panel-title>
                          
                            </mat-expansion-panel-header>
                          
                            <owl-carousel-o [options]="embelishmentOption">
                                <ng-template carouselSlide *ngFor="let item of armListArray">
                                  
                                    <img class="four_img_list" [src]="item?.rawImage?.webImage" alt=""
                                        (click)="selectArm(item)">
                                    <i [ngClass]="{'slide-image' : !item?.isSelected}" class="fa fa-check right-tick"
                                        aria-hidden="true"></i>
                                   
                                    <p class="text_images_para">{{item?.armName}}</p>
                                </ng-template>


                            </owl-carousel-o>

                            <div class="scasl-search-btn">
                                <button class="locate-search buttonGlobal" id="scapl-geocode-btn" type="button"
                                    (click)="nextStep()">
                                    NEXT STEP
                                </button>
                            </div>
                        </mat-expansion-panel>
                    </div>-->

          <div *ngIf="embellishmentListArray?.length" class="second_list_data">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title class="justify-content-center">
                  Embellishment
                </mat-panel-title>
              </mat-expansion-panel-header>

              <owl-carousel-o [options]="embelishmentOption">
                <ng-template
                  carouselSlide
                  *ngFor="let item of embellishmentListArray"
                >
                  <img
                    class="four_img_lists"
                    [src]="item?.image?.webImage"
                    alt=""
                    (click)="selectEmbellishment(item, true)"
                  />
                  <i
                    [ngClass]="{ 'slide-image': item?._id != embellishmentId }"
                    class="fa fa-check right-tick-embellishment"
                    aria-hidden="true"
                  ></i>

                  <p class="text_images_para">{{ item?.embellishment_Type }}</p>
                </ng-template>
              </owl-carousel-o>

              <div class="scasl-search-btn">
                <button
                  class="locate-search buttonGlobal"
                  id="scapl-geocode-btn"
                  type="button"
                  (click)="nextStep()"
                >
                  NEXT STEP
                </button>
              </div>
            </mat-expansion-panel>
          </div>

          <div class="fourth_clas">
            <!-- <mat-expansion-panel hideToggle> -->
            <mat-expansion-panel
              [expanded]="step === 1"
              (opened)="setStep(1)"
              hideToggle
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="justify-content-center">
                  Standard Color
                </mat-panel-title>
                <!-- <mat-panel-description>
        This is a summary of the content
      </mat-panel-description> -->
              </mat-expansion-panel-header>
              <owl-carousel-o [options]="newOptions">
                <!-- <ng-template carouselSlide *ngFor="let item of colorListArray"> -->
                <ng-template carouselSlide *ngFor="let item of stantdardColor">
                  <!-- <img class="colour_list_details" [src]="item?.image" alt=""> -->
                  <!-- <input type="color" class="data-cls" [value]="item?.colorCode"
                                        (click)="selectColor(item?._id)"> -->
                  <div class="slide-color-outer-block">
                    <div
                      class="color-block"
                      [style.background-color]="item?.colorId?.colorCode"
                      (click)="selectColor(item)"
                    >
                      <i
                        [ngClass]="{
                          'slide-image': item?.colorId?._id != colorId
                        }"
                        class="fa fa-check right-tick-color"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <p class="text_images_paras">
                    {{ item?.colorId?.colorName }}
                  </p>
                </ng-template>
              </owl-carousel-o>

              <div class="scasl-search-btn">
                <!-- <button class="locate-skip" id="scapl-geocode-btn" type="button" (click)="nextStep()">
                                    SKIP
                                </button> -->
                <button
                  class="locate-search buttonGlobal"
                  id="scapl-geocode-btn"
                  type="button"
                  (click)="nextStep()"
                >
                  NEXT STEP
                </button>
              </div>
              <!-- <p>This is the primary content of the panel.</p> -->
            </mat-expansion-panel>
          </div>

          <div class="fourth_clas">
            <!-- <mat-expansion-panel hideToggle> -->
            <mat-expansion-panel
              [expanded]="step === 2"
              (opened)="setStep(2)"
              hideToggle
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="justify-content-center">
                  Premium Color
                </mat-panel-title>
                <!-- <mat-panel-description>
                            This is a summary of the content
                          </mat-panel-description> -->
              </mat-expansion-panel-header>
              <owl-carousel-o [options]="newOptions">
                <!-- <ng-template carouselSlide *ngFor="let item of premiumColorListArray"> -->
                <ng-template carouselSlide *ngFor="let item of premiumColor">
                  <!-- <img class="colour_list_details" [src]="item?.image" alt=""> -->
                  <!-- <input type="color" class="data-cls" [value]="item?.colorCode"
                                                            (click)="selectColor(item?._id)"> -->
                  <div class="slide-color-outer-block">
                    <div
                      class="color-block"
                      [style.background-color]="item?.premiumColorId?.colorCode"
                      (click)="selectPremiumColor(item)"
                    >
                      <i
                        [ngClass]="{
                          'slide-image':
                            item?.premiumColorId?._id != premiumColorId
                        }"
                        class="fa fa-check right-tick-color"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <p class="text_images_paras">
                    {{ item?.premiumColorId?.colorName }}
                  </p>
                </ng-template>
              </owl-carousel-o>

              <div class="scasl-search-btn">
                <button
                  class="locate-skip"
                  id="scapl-geocode-btn"
                  type="button"
                  (click)="nextStep()"
                >
                  SKIP
                </button>
                <button
                  class="locate-search buttonGlobal"
                  id="scapl-geocode-btn"
                  type="button"
                  (click)="nextStep()"
                >
                  NEXT STEP
                </button>
              </div>
              <!-- <p>This is the primary content of the panel.</p> -->
            </mat-expansion-panel>
          </div>

          <div class="second_list_data">
            <mat-expansion-panel
              [expanded]="step === 3"
              (opened)="setStep(3)"
              hideToggle
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="justify-content-center">
                  Size
                </mat-panel-title>
                <!-- <mat-panel-description>
          Currently I am {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description> -->
              </mat-expansion-panel-header>
              <!-- <p>I'm visible because I am open</p> -->
              <div class="header-text size_select_value">
                <label for="">Body Size</label>
                <select
                  class="form-control form-control-sm"
                  (change)="selectBodySize($event.target.value)"
                >
                  <option selected>Select Size</option>
                  <option
                    *ngFor="let item of size"
                    [value]="item"
                  >
                    {{ item }}
                  </option>
           
                </select>
            
              </div>

              <div class="header-text size_select_value">
                <label for="">Length</label>
                <select
                  class="form-control form-control-sm"
                  (change)="selectSkirtLength($event.target.value)"
                >
                  <option selected value="">Select length</option>
      
                  <option [value]="item?.type" *ngFor="let item of extraLength">
                    {{item.type}}
                    {{
                      item?.addOnPrice *
                        combinationDetails?.obj?.conversionRate
                        | currency : ipDetails?.geoplugin_currencySymbol
                    }}
                    extra
                  </option>
                </select>
              </div>
              <!-- <div class="scasl-search-btn">
                                <button class="locate-search buttonGlobal" id="scapl-geocode-btn" type="button" (click)='xpandStatus=xpandStatus?false:true'>
                                    NEXT STEP
                                </button>
                            </div> -->
            </mat-expansion-panel>
          </div>

          <div *ngIf="!socialShopDetails" class="clr-list">
            <mat-form-field
              appearance="outline"
              class="mat-form-filed-custom col-md-12 p-0"
            >
              <mat-label>Quantity</mat-label>
              <input
                matInput
                type="text"
                min="1"
                [(ngModel)]="enteredQuantity"
                maxlength="2"
                (keypress)="commonService.numberOnly($event)"
                oninput="if(!value.match('^[1-9][0-9]*$'))value='';"
              />
            </mat-form-field>
          </div>

          <mat-card class="list_details">
            <!-- <div class="tite_list"> -->
            <div class="total_price">
              <span>Total</span>
              <span>
                : &nbsp; &nbsp;
                {{
                  totalPrice * enteredQuantity
                    | currency : ipDetails?.geoplugin_currencySymbol
                }}</span
              >
            </div>
          </mat-card>

          <div class="down_button">
            <!-- <button class="down_detail_button"></button> -->
            <!-- <a class="appointment-btn btn header-text" data-toggle="modal" data-target="#bagModal"><i
                                class="fa fa-shopping-bag beeg" aria-hidden="true"></i>Add to
                            Bag</a> -->
            <!-- normal shoping ::: we will add product to bag and then checkout -->
            <a
              *ngIf="!socialShopDetails"
              class="appointment-btn btn header-text"
              (click)="addToBagApi()"
            >
              <!-- <i class="fa fa-shopping-bag beeg" aria-hidden="true"></i> -->
              Add to Bag
            </a>
            <!-- social shoping ::: we will add product to chat room product slots and then group room creater will checkout -->
            <a
              *ngIf="socialShopDetails?.payType == 'GROUP'"
              class="appointment-btn btn header-text"
              (click)="proceedToChatRoom()"
            >
              <!-- <i class="fa fa-shopping-bag beeg" aria-hidden="true"></i> -->
              Proceed to chat room
            </a>
            <!-- social shoping ::: we will directly proceed to checkout and after payment we will back to chat room -->
            <a
              *ngIf="socialShopDetails?.payType == 'INDIVIDUAL'"
              class="appointment-btn btn buttonGlobal header-text"
              (click)="proceedToChatRoom()"
            >
              <!-- <i class="fa fa-shopping-bag beeg " aria-hidden="true"></i> -->
              Proceed to checkout
            </a>
          </div>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>

<!--  centered modal -->
<div
  class="modal fade"
  id="bagModal"
  tabindex="-1"
  aria-labelledby="bagModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered chat-dgl">
    <div class="modal-content">
      <div class="modal-atc-header">
        <img
          class="mottemaids-logo"
          src="../../../../assets/images/motee-maids-log.png"
          alt="moteemaids"
        />
        <div class="close-btn" data-dismiss="modal">
          <i class="fa fa-times new-ics" aria-hidden="true"></i>
        </div>
      </div>

      <div class="modal-atc-body">
        <div class="flex-wrapper">
          <!-- <div class="hidden-desktop">
                      <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                  </div> -->

          <div class="image-wrapper">
            <div class="outer-circle">
              <div class="image-circle">
                <img
                  src="assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg"
                  alt=""
                />
              </div>
            </div>

            <div class="data-details">
              <table>
                <tbody>
                  <tr class="detail-line-name">
                    <td colspan="2" class="product-name title-color-dark">
                      LW203 - Tulle Trapeze Dress
                    </td>
                  </tr>
                  <tr class="text-color-light">
                    <td class="title">Color</td>
                    <td class="value">
                      <span class="cart-modal-product-color">Ivory</span>
                    </td>
                  </tr>
                  <tr class="text-color-light" style="display: none">
                    <td class="title">Size</td>
                    <td class="value">
                      <span class="cart-modal-product-size"></span>
                    </td>
                  </tr>
                  <tr class="text-color-light" style="display: none">
                    <td class="title">Bodice Size</td>
                    <td class="value">
                      <span class="cart-modal-product-bodice-size"></span>
                    </td>
                  </tr>
                  <tr class="text-color-light" style="display: none">
                    <td class="title">Skirt Size</td>
                    <td class="value">
                      <span class="cart-modal-product-skirt-size"></span>
                    </td>
                  </tr>
                  <tr class="text-color-light" style="display: none">
                    <td class="title">Skirt length</td>
                    <td class="value">
                      <span class="cart-modal-product-skirt-length"></span>
                    </td>
                  </tr>
                  <tr class="text-color-light" style="display: none">
                    <td class="title">Dress length</td>
                    <td class="value">
                      <span class="cart-modal-product-dress-length"></span>
                    </td>
                  </tr>
                  <tr class="text-color-light">
                    <td class="title">Price</td>
                    <td class="value">
                      $<span class="cart-modal-product-price">1495.00</span>
                    </td>
                  </tr>
                  <tr class="text-color-light">
                    <td class="title">Quantity</td>
                    <td class="value">
                      <span class="cart-modal-product-qty">1</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="total-wrapper">
              <hr />
              <table>
                <tbody>
                  <tr class="title-color-dark">
                    <td class="total-title">Total:</td>
                    <td class="total-price">
                      $<span class="cart-modal-product-total">1495.00</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="text-wrapper">
            <h2
              class="hidden-mobile title-color-dark text-center"
              style="font-size: 28px; font-weight: 400"
            >
              You've successfully added a dress to your bag!
            </h2>

            <h3 class="header-text" style="font-size: 1em; font-weight: 200">
              Please ensure you’ve reviewed our size Guide for measurements
              &amp; sizing accuracy prior to placing your order. All of our
              dresses are made to order and are non-returnable.
            </h3>
            <div class="onboarding-wrapper">
              <h4
                class="title-color-dark"
                style="font-size: 0.8em; font-weight: 600"
              >
                For exceptional service and to ensure we deliver your dress on
                time, please tell us your event date &amp; bridal party name.
              </h4>

              <form
                id="cart-modal-form"
                action=""
                accept-charset="utf-8"
                siq_id="autopick_9203"
              >
                <div class="form-element-wrapper">
                  <label for="event-date" class="text-color-light"
                    >Event date</label
                  >

                  <div class="element-with-icon">
                    <input
                      type="date"
                      id="event-date"
                      name="event-date"
                      class="add-cart-form-datepicker hasDatepicker"
                    />
                    <!-- <i class="fa fa-calendar calendar-popup" aria-hidden="true"></i> -->
                  </div>
                  <!-- <span class="error-msg error-event-date">Field is required</span> -->
                </div>

                <div class="form-element-wrapper">
                  <label for="bridal-party-name" class="text-color-light"
                    >Bridal Party / Bride name</label
                  >
                  <input
                    type="text"
                    id="bridal-party-name"
                    name="bridal-party-name"
                  />
                  <!-- <span class="error-msg error-bridal-party-name">Field is required</span> -->
                </div>

                <div class="form-element-wrapper">
                  <div class="border-btn-wrapper">
                    <div
                      class="submit-form-btn buttonGlobal"
                      id="submit-cart-modal"
                      data-dismiss="modal"
                      routerLink="/my-shopping-bag"
                    >
                      Submit
                    </div>
                  </div>
                </div>

                <input
                  type="hidden"
                  id="order-variant-id"
                  name="order-variant-id"
                  value=""
                />
                <input
                  type="hidden"
                  id="rush-fee-id"
                  name="rush-fee-id"
                  value=""
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
