import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provider/auth.service';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  logoResultArray: any = [];
  type: any;
  constructor(public authService: AuthService, public commonService: CommonService, private router: Router,private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.queryParams.subscribe((res) => {
      this.type = "LOGO";
    })
    // this.logo()
  }

/**
   * userType are used in website
   * @param logo -> normal user comes to buy product
   */
 logo() {
  let apiReqUrl = `${'static/homeLogoPageList?type='+this.type}`

  this.commonService.showSpinner()
  this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
    if (res['responseCode'] == '200') {
      this.logoResultArray = res.result.logoImage.webImage
      this.commonService.hideSpinner() 
      
    }
  }, (err: any) => {
    this.commonService.hideSpinner();
    if (err['responseCode'] == '401') {
      this.commonService.errorToast(err['responseMessage']);
      
    } else {
      this.commonService.errorToast(err.responseMessage);
    }
  })
}

}
