<div class="wrapper">
    <!-- <mat-toolbar class="toolbar_custom">
        Home/My Account
    </mat-toolbar> -->
    <mat-sidenav-container>
        <mat-sidenav mode="side" opened>
            <button mat-button class="side_menu_button header-text" routerLink="/my-profile">
                <p>My Account</p>
            </button>
            <button mat-button class="side_menu_button header-text" routerLink="/addresses">
                <p>Addresses</p>
            </button>
            <button mat-button class="side_menu_button header-text" routerLink="/my-order">
                <p>My Orders</p>
            </button>
            <button mat-button class="side_menu_button header-text" routerLink="/order-status">
                <p>Order Status</p>
            </button>
            <button mat-button class="side_menu_button header-text" routerLink="/my-wishlist">
                <p>My Wishlists</p>
            </button>
        </mat-sidenav>
        <mat-sidenav-content>
            <mat-card class="mat-card-custom">
                <div class="order-details">
                    <span class="header-text">Store Address</span>
                </div>
                <!-- form -->
                <form [formGroup]="editAddressForm" class="main-content">
                    <div class="d-flex pl-4 pt-4" style="justify-content: space-between;">
                        <!-- name -->
                        <div class="wd-50">
                            <p class="header-text">Name*:</p>
                            <input type="text" placeholder="First name" formControlName="name">
                            <span class="error"
                                *ngIf="editAddressForm.get('name').hasError('required') && (editAddressForm.get('name').touched ||editAddressForm.get('name').dirty)">
                                *Name is required.</span>
                        </div>
                        <!-- email id -->
                        <div class="wd-50 ">
                            <p class="header-text">email ID*</p>
                            <input type="text" placeholder="email id" formControlName="email" maxlength="256">
                            <!-- <span class="error" *ngIf="editAddressForm.get('email').hasError('required') && (editAddressForm.get('email').touched ||editAddressForm.get('email').dirty)">
                                *email is required.</span>
                            <span class="error" *ngIf="editAddressForm.get('email').hasError('pattern')">*Please enter valid email.</span>    -->
                            <span class="error"
                                *ngIf=" editAddressForm.get('email').hasError('required') && (editAddressForm.get('email').touched || editAddressForm.get('email').dirty)">
                                *Email is required.</span>
                            <span class="error"
                                *ngIf="editAddressForm.get('email').hasError('pattern') && (editAddressForm.get('email').dirty)">*Please
                                enter valid email.</span>
                        </div>
                    </div>


                    <div class="d-flex pl-4 pt-4" style="justify-content: space-between;">
                        <!-- store name -->
                        <div class="wd-50">
                            <p class="header-text">Store Name*:</p>
                            <input type="text" placeholder="Store name" formControlName="storeName">
                            <span class="error"
                                *ngIf="editAddressForm.get('storeName').hasError('required') && (editAddressForm.get('storeName').touched || editAddressForm.get('storeName').dirty)">*Store
                                name is required.</span>
                        </div>

                        <!-- location -->
                        <div class="wd-50">
                            <p class="header-text">Location*:</p>
                            <select name="cars" id="cars" formControlName="location">
                                <option value="--Selecet Your Country--">--Selecet Your Country--</option>
                                <option value="Afganistan">Afganistan +93</option>
                                <option value="Albania">Albania +355</option>
                                <option value="Andorra">Andorra +376</option>
                                <option value="India">India +91</option>
                                <option value="Albania">Albania +355</option>
                                <option value="India">India +91</option>
                            </select>
                            <span class="error"
                                *ngIf="editAddressForm.get('location').hasError('required') && (editAddressForm.get('location').touched ||editAddressForm.get('location').dirty)">
                                *Location is required.</span>
                        </div>
                    </div>

                    <div class="d-flex pl-4 pt-4" style="justify-content: space-between;">
                        <!-- address -->
                        <div class="wd-50">
                            <p class="header-text">Address*:</p>
                            <input type="text" placeholder=" Address" formControlName="address">
                            <span class="error"
                                *ngIf="editAddressForm.get('address').hasError('required') && (editAddressForm.get('address').touched || editAddressForm.get('address').dirty)">*Address
                                is required.</span>
                        </div>

                        <!-- address line 2 -->
                        <div class="wd-50">
                            <p class="header-text">Address Line 2:</p>
                            <input type="text" placeholder="Address line 2">
                        </div>
                    </div>

                    <div class="d-flex pl-4 pt-4 ml-1" style="justify-content: space-between;">
                        <!-- zip code -->
                        <div class="wd-50">
                            <p class="header-text">Zip Code*:</p>
                            <input type="text" placeholder=" Zip Code" formControlName="zipCode">
                            <span class="error"
                                *ngIf="editAddressForm.get('zipCode').hasError('required') && (editAddressForm.get('zipCode').touched || editAddressForm.get('zipCode').dirty)">*Zip
                                code is required.</span>
                        </div>

                        <!-- contact number -->
                        <div class="wd-50">
                            <p class="header-text">Contact Number*:</p>
                            <div class=" d-flex">
                                <!-- country code -->
                                <div class="form-group col-md-2 pr-0">
                                    <!-- -=-=-=-=-=-=-=-=-=-=- Mobile number Field-=-=--=-=-=-=-=-=-=-= -->
                                    <!-- <span style="display: flex;"> -->
                                    <!-- <label class="top_heading_lab header-text">Country*</label> -->

                                    <input type="text" aria-label="Country" class="user-name" [formControl]="myControl"
                                        maxlength="7" (keypress)="commonService.numberOnly($event)"
                                        [matAutocomplete]="auto" />
                                    <mat-autocomplete #auto="matAutocomplete" class="contr">
                                        <mat-option *ngFor="let option of filteredOptions | async"
                                            [value]="option.code || option.name">
                                            <span style="display: flex; justify-content: space-between">
                                                <!-- <p>{{ option.name }}</p> -->
                                                <p>{{ option.code }}</p>
                                            </span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>


                                <!-- contact number field -->
                                <div class="w-100">
                                    <input type="text" class="contact-number-input" placeholder="Contact number"
                                        formControlName="contactNo" maxlength="14">
                                    <span class="error"
                                        *ngIf="editAddressForm.get('contactNo').hasError('required') && (editAddressForm.get('contactNo').touched || editAddressForm.get('contactNo').dirty)">*contact
                                        no. is required.</span>
                                    <span class="error"
                                        *ngIf="editAddressForm.get('contactNo').hasError('pattern')">*Please enter valid
                                        contact no.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="m-4">
                    <input type="checkbox" checked>
                    <label class="header-text">Make this My Preferred Shipping Adress</label>
                </div>
                <!-- button -->
                <div class="m-4">
                    <button class="w-25" mat-raised-button color="accent">SAVE</button>
                </div>

            </mat-card>

        </mat-sidenav-content>
    </mat-sidenav-container>
</div>