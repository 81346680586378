<div class="wrapper">
    <div class="w-t pt-5">
        <!-- <div class="header">
            <h2 class="header-text">Motee Maids Bridesmaid Fabric Swatches</h2>
        </div> -->
        <div class="swatches">
            <div class="d-flex">
                <div class="col-md-3" style="max-width: 21%;">
                    <div class=" mt-5">
                        <!-- <h4 class="header-text text-left">Filters</h4> -->
                        <div class="d-flex justify-content-between align-items-center p-2 color">
                            <h3 class="m-0 font-weight-bold">Filter By</h3>
                            <!-- swateches and color only price and size not implemented -->
                            <h3 *ngIf="swatchesId.length || colorId.length || sizeSelected.length || priceId"
                                class="m-0 font-weight-bold clear-filter" (click)="clearFilter()">Clear
                                Filter</h3>
                        </div>
                        <!-- <mat-divider></mat-divider> -->

                        <!-- <mat-accordion>

                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                [expanded]="step === 0" (opened)="setStep(0)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        FABRIC
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <div class="header-text">

                                    <div *ngFor="let item of swatchesListArray" class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" [id]="item._id"
                                            [(ngModel)]="item.isSelected" (click)="selectSwatches(item)">
                                        <label class="custom-control-label" for="">{{item?.fabricName}}</label>
                                    </div>


                                </div>
                            </mat-expansion-panel>

                        </mat-accordion> -->

                    </div>
                    <div class="mt-3">
                        <mat-accordion>

                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                [expanded]="step === 0" (opened)="setStep(0)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        PRICE
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="header-text">
                                    <div *ngFor="let item of priceListArray; let last = last;"
                                        class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" [id]="item._id"
                                            [(ngModel)]="item.isSelected" (click)="selectPrice(item)">
                                        <!-- <label *ngIf="!last" class="custom-control-label" for="c1">${{item?.minAmount}}
                                            -
                                            ${{item?.maxAmount}}</label>
                                        <label *ngIf="last" class="custom-control-label" for="c1">Over
                                            ${{item?.minAmount}}</label> -->
                                        <label *ngIf="!last" class="custom-control-label"
                                            for="c1">{{ipDetails?.geoplugin_currencySymbol}}{{item?.minAmount |
                                            shortNumber}} - {{ipDetails?.geoplugin_currencySymbol}}{{item?.maxAmount |
                                            shortNumber}}</label>
                                        <label *ngIf="last" class="custom-control-label" for="c1">Over
                                            {{ipDetails?.geoplugin_currencySymbol}}{{item?.minAmount |
                                            shortNumber}}</label>
                                    </div>
                                    <!-- <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">$100 - $300</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1"> $300 - $500</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">$500 - $800</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">$800 - $1500</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">$1500 - $3000</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">$3000 - $8000</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Over $8000</label>
                                    </div> -->

                                </div>
                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>
                </div>
                <div class="col-md-9 pt-3">
                    <div class="top-data d-flex">
                        <!-- <h1>Bridal</h1> -->
                        <h4>Simply iconic. The true namesake collection, Motee Maids Bridal
                            is simply modern, highlighted by signature couture design techniques and hand-touched by the
                            master seamstresses at our New York atelier.
                            <br>
                            <!-- <strong>See the collection on you with Motee Maids Virtual Try-On</strong> -->
                        </h4>
                        <!-- <h4 style="padding-left: 10px;">Simply iconic. The true namesake collection, Motee Maids Bridal
                                                                            is simply modern, highlighted by signature couture design techniques and hand-touched by the
                                                                            master seamstresses at our New York atelier.
                                                                            <br>
                                                                            <strong>See the collection on you with Motee Maids Virtual Try-On</strong>
                                                                        </h4> -->
                    </div>
                    <div class="row reveal">
                        <ng-container *ngIf="productListArray.length && !isSkeleton">

                            <div class="col-md-4 fifth-img swt "
                                [ngClass]="item?.productImage?.backImage?.webImage ? 'fifth-img-ex':''"
                                *ngFor="let item of productListArray">
                                <!-- <div class="flip-box">
                                <div class="flip-box-inner">
                                    <div class="flip-box-front">
                                        <img class="fifth-img-show"
                                            [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt=""
                                            (click)="navigateToViewMotherDress(item?._id)">
                                    </div>
                                    <div class="flip-box-back">
                                        <img class="fifth-img-show"
                                            [src]="item?.productDetails[0]?.image[1]?.imageUrl?.webImage" alt=""
                                            (click)="navigateToViewMotherDress(item?._id)">
                                    </div>
                                </div>
                            </div> -->
                                <div class="imageBox">
                                    <img class="fifth-img-show" [src]="item?.productImage?.frontImage?.webImage" alt=""
                                        (click)="navigateToViewMotherDress(item?._id)">
                                    <img class=" fifth-img-hide" [src]="item?.productImage?.backImage?.webImage" alt=""
                                        (click)="navigateToViewMotherDress(item?._id)">
                                    <!-- <img class="fifth-img-show"
                                    [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt=""
                                    (click)="navigateToViewMotherDress(item?._id)">
                                <img class=" fifth-img-hide"
                                    [src]="item?.productDetails[0]?.image[1]?.imageUrl?.webImage" alt=""
                                    (click)="navigateToViewMotherDress(item?._id)"> -->
                                </div>
                                <!-- <img src="/assets/images/Marketing_Shots_Shoot_2/Diamond_Beading.jpg" alt=""> -->
                                <!-- <img [src]="item?.productDetails[0]?.image[0]?.imageUrl?.webImage" alt="" (click)="navigateToViewMotherDress(item?._id)"> -->
                                <div class="descrip">
                                    <span class="header-text">{{item?.productName}} -
                                        <!-- ${{item?.productPrice[0]?.MRP}}.00 -->
                                        <!-- {{ipDetails?.geoplugin_currencySymbol}}{{item?.MRP}}.00 -->
                                        <!-- {{ipDetails?.geoplugin_currencySymbol}}{{item?.countryPrice?.totalMrp}}.00 -->
                                        {{item?.countryPrice?.totalMrp | currency:
                                        ipDetails?.geoplugin_currencySymbol}}
                                    </span>
                                    <!-- <p class="header-text">Black</p> -->
                                    <div class="ic d-flex">
                                        <ng-container *ngFor="let color of item.colorDetails">
                                            <!-- <i class="fa fa-circle mr-2"></i> -->
                                            <!-- <img [src]="color?.image" alt="" class="image-custom-list"> -->
                                            <div class="image-custom-list" [style.background-color]="color?.colorCode">
                                            </div>
                                        </ng-container>
                                        <!-- <span>{{color?.colorCode}}</span> -->

                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- product skeleton -->
                        <ng-container *ngIf="!productListArray.length && isSkeleton">
                            <div class="skeleton skeleton-section col-xl-4 col-lg-4 col-md-4 col-sm-2"
                                *ngFor="let item of generateFake(6)">
                                <div class="item-wrap">
                                    <div class="item-img">
                                        <ngx-skeleton-loader animation="progress" [theme]="skeletonImageTheme">
                                        </ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Shoot_4additionalImages/swt3.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">Cayenne</p>
                            </div>
                        </div>
                        <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Shoot_4additionalImages/swt4.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">Champagne</p>
                            </div>
                        </div> -->
                        <!-- =-=-=-=-=-=-=-=-= 404 error=-=-=-=-=-=-=-=-=-=-  -->
                        <div class="noPostVailableDiv reveal" *ngIf="!productListArray.length && !isSkeleton"
                            style="text-align: center;margin: auto;">
                            <div class="">
                                <img src="/assets/images/no-data-found.png" alt="img" class="animate"
                                    style="width: 50%;">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Shoot_4additionalImages/swt1.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">Copper</p>
                            </div>
                        </div>
                        <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Shoot_4additionalImages/swt2.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">French-Blue</p>
                            </div>
                        </div>
                        <div class="col-md-4 swt" routerLink="/view-mothers-dress">
                            <img src="/assets/images/Shoot_3/Tulle_embroidered.jpg" alt="">
                            <div class="descrip">
                                <span class="header-text">Fluid Satin - $20.00</span>
                                <p class="header-text">Fuchsia</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>