<!-- my order copy -->
<!-- <div class="container"> -->
<div class="content">

    <!-- <div class="order-details">
          <span class="header-text">Order Details</span>
        </div> -->
    <div class="top-text text-center">
        <mat-card-title class="mat-card-title sign-text ml-0 title-color-dark">My Events</mat-card-title>
    </div>
    <table class="cart">
        <thead>
            <tr>
                <th class="cart__row-price header-text" scope="col">Event Name</th>
                <th class="cart__row-price header-text" scope="col">No. of Guest</th>
                <th class="cart__row-price header-text" scope="col">Event Address</th>
                <th class="cart__row-price header-text" scope="col">Event Date</th>
                <th class="cart__row-price header-text" scope="col">Event Time</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="cart__meta small--text-left">
                    <p>Birthday Party</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>25</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>Okhla Delhi, phase II</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>25/2/2022</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>11:30 AM</p>
                </td>
            </tr>
            <tr>
                <td class="cart__meta small--text-left">
                    <p>Birthday Party</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>25</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>Okhla Delhi, phase II</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>25/2/2022</p>
                </td>
                <td class="cart__meta small--text-left">
                    <p>11:30 AM</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>

