<div class="wrraper back-pic">
  <!-- signin Modal -->
  <div class="formDiv">
    <mat-card class="mat-elevation-z0 bdr">
      <div class="main">
        <div class="top-text text-left">
          <mat-card-title class="sign-text header-text">Get started with your account
          </mat-card-title>
          <p class="text-left textAfterloginHeading new-style">
            Already have an account?
            <a class="regis" (click)="navigateToSignin()"> Log in</a>
          </p>
        </div>
        <div class="input-field">
          <form [formGroup]="signinForm">
            <div class="row">
              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----full name----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">First Name <span class="c-red">*</span></label>
                <input type="text" class="user-name" placeholder="Enter First Name" formControlName="firstName"
                  maxlength="60" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('firstName').invalid && (signinForm.get('firstName').touched || signinForm.get('firstName').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('firstName').errors.required">
                    *First name is required.
                  </span>
                  <span *ngIf="signinForm.get('firstName').errors.pattern">
                    *Please enter valid first name.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('firstName').hasError('required') &&
                      (signinForm.get('firstName').touched ||
                        signinForm.get('firstName').dirty || isFormSubmitted)
                    ">
                    *First name is required.</span>
                  <span class="error" *ngIf="
                      signinForm.get('firstName').hasError('pattern') &&
                      (signinForm.get('firstName').touched ||
                        signinForm.get('firstName').dirty)
                    ">
                    *Please enter valid first name.</span>
                  <span class="error" *ngIf="signinForm.get('firstName').hasError('minlength')">
                    *Minimum length must be 2.
                  </span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <!-- -------=-=-=-=-=-=------user name---=-=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">Last Name <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Enter Last Name" formControlName="lastName"
                  maxlength="60" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('lastName').invalid && (signinForm.get('lastName').touched || signinForm.get('lastName').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('lastName').errors.required">
                    *Last name is required.
                  </span>
                  <span *ngIf="signinForm.get('lastName').errors.pattern">
                    *Please enter valid last name.
                  </span>
                </div>
                <!-- <span class="error" *ngIf="
                signinForm.get('lastName').hasError('required') &&
                (signinForm.get('lastName').touched ||
                  signinForm.get('lastName').dirty || isFormSubmitted)
              ">
                  *Last name is required.</span>
                <span class="error"
                  *ngIf="signinForm.get('lastName').hasError('pattern') && (signinForm.get('lastName').touched||signinForm.get('lastName').dirty)">
                  *Please enter valid last name.</span> -->
              </div>
              <!-- </div> -->
              <!-- sss -->
              <!-- <div class="row"> -->
              <div class="input1 col-md-12">
                <!------------=-=-=--=-=-=-=---------- email Field ----=-=-=-=-=-=-=-=-=----------->
                <label class="top_heading_lab header-text">Email Address <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Enter Email Address" formControlName="email"
                  maxlength="256" autocomplete="new-password" />

                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('email').invalid && (signinForm.get('email').touched || signinForm.get('email').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('email').errors.required">
                    *Email address is required
                  </span>
                  <span *ngIf="signinForm.get('email').errors.pattern">
                    *Please enter a valid email address.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                    signinForm.get('email').hasError('required') &&
                    (signinForm.get('email').touched ||
                      signinForm.get('email').dirty || isFormSubmitted)
                  ">
                    *Email address is required.</span>
                  <span class="error" *ngIf="
                    signinForm.get('email').hasError('pattern') &&
                    (signinForm.get('email').touched ||
                      signinForm.get('email').dirty)
                  ">*Please enter a valid email address.
                  </span>
                </div> -->
              </div>
              <!-- </div> -->

              <!---=-=-=-=-= password  -=-=-=-=-=-=-=-=-=-=-===--->
              <!-- <div class="row"> -->
              <div class="input1 col-md-12">
                <!------------=-=-=--=-=-=-=---------- email Field ----=-=-=-=-=-=-=-=-=----------->
                <label class="top_heading_lab header-text">Password <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Enter Password" formControlName="password"
                  [type]="toggleEye ? 'password' : 'text'" maxlength="32" autocomplete="new-password" />
                <mat-icon matSuffix (click)="toggleEye = !toggleEye">{{ toggleEye ? "visibility_off" : "visibility" }}
                </mat-icon>
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('password').invalid && (signinForm.get('password').touched || signinForm.get('password').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('password').errors.required">
                    *Password is required.
                  </span>
                  <span *ngIf="signinForm.get('password').errors.pattern">
                    *Password must contain minimum 8 characters with uppercase letter, a number & a special
                    character.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                    signinForm.get('password').hasError('required') &&
                    (signinForm.get('password').touched ||
                      signinForm.get('password').dirty || isFormSubmitted)
                  ">
                    *Password is required.</span>
                  <span class="error" *ngIf="
                    signinForm.get('password').hasError('pattern') &&
                    (signinForm.get('password').touched ||
                      signinForm.get('password').dirty)
                  ">
                    *Password must contain minimum 8 characters with uppercase letter, a number & a special
                    character.</span>
                </div> -->
              </div>
              <!-- </div> -->

              <!-- <div class="row"> -->
              <div class="input1 col-md-12">
                <!-- -------------confirm password------------- -->
                <label class="top_heading_lab mt-0 header-text">Confirm Password <span class="c-red">*</span></label>

                <input type="password" class="user-name" placeholder="Confirm Password"
                  formControlName="confirmPassword" [type]="toggleEyeC ? 'password' : 'text'" maxlength="32"
                  autocomplete="new-password" />
                <mat-icon matSuffix (click)="toggleEyeC = !toggleEyeC">{{ toggleEyeC ? "visibility_off" : "visibility"
                  }}
                </mat-icon>
                <!-- validation -->
                <!-- <div class="text-danger"
                  *ngIf="(signinForm.get('confirmPassword').invalid && (signinForm.get('confirmPassword').touched || signinForm.get('confirmPassword').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('confirmPassword').errors.required">
                    *Confirm password is required.
                  </span>
                  <span
                    *ngIf="(signinForm.get('password').value != signinForm.get('confirmPassword').value) && signinForm.get('confirmPassword').dirty">
                    *Confirm Password should match with New Password.
                  </span>
                </div> -->
                <div class="text-danger">
                  <span *ngIf="
                    signinForm.get('confirmPassword').hasError('required') &&
                    (signinForm.get('confirmPassword').touched ||
                      signinForm.get('confirmPassword').dirty || isFormSubmitted)
                  ">
                    *Confirm password is required.</span>
                  <span *ngIf=" signinForm.get('confirmPassword').value &&
                    (signinForm.get('password').value !=
                      signinForm.get('confirmPassword').value) &&
                    signinForm.get('confirmPassword').dirty
                  ">
                    *Confirm Password should match with Password.</span>
                </div>
              </div>
              <!-- </div> -->
              <!-- <div class="row"> -->
              <div class="input1 col-md-2 ">
                <!-- -=-=-=-=-=-=-=-=-=-=- Mobile number Field-=-=--=-=-=-=-=-=-=-= -->
                <!-- <span style="display: flex;"> -->
                <label class="top_heading_lab mt-0 header-text">Country Code</label>

                <input type="text" aria-label="Country" class="user-name" [formControl]="myControl" maxlength="7"
                  (keypress)="commonService.numberOnly($event)" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete" class="contr">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.code || option.name">
                    <span style="display: flex; justify-content: space-between">
                      <p>{{option.code}}</p>
                    </span>
                  </mat-option>
                </mat-autocomplete>
              </div>
              <div class="input1 col-md-10">
                <label class="top_heading_lab mt-0 header-text">Mobile Number (Optional)</label>

                <input type="text" class="user-name number" autocomplete="new-password"
                  placeholder="Enter Mobile Number" formControlName="mobileNumber" maxlength="15"
                  (keypress)="commonService.numberOnly($event)" />

                <!-- </span> -->
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('mobileNumber').invalid && (signinForm.get('mobileNumber').touched || signinForm.get('mobileNumber').dirty));">
                  <!-- <span *ngIf="signinForm.get('mobileNumber').errors.required || isFormSubmitted">
                    *Mobile Number address is required
                  </span> -->
                  <span *ngIf="signinForm.get('mobileNumber').errors.pattern">
                    *Please enter valid mobile number.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                    signinForm.get('mobileNumber').hasError('required') &&
                    (signinForm.get('mobileNumber').touched ||
                      signinForm.get('mobileNumber').dirty || isFormSubmitted)
                  ">
                    *Mobile Number is required.</span>
                  <span class="error"
                    *ngIf="signinForm.get('mobileNumber').hasError('pattern') && signinForm.get('mobileNumber').dirty">*Invalid
                    mobile number.</span>
                  <span class="error" *ngIf="signinForm.get('mobileNumber').hasError('minlength')">
                    *Please enter valid mobile number between 7-15 digit.
                  </span>
                </div> -->
                <!-- <span class="error" *ngIf="signinForm.get('mobileNumber').hasError('minlength') || signinForm.get('mobileNumber').hasError('maxlength')">
                                *Please enter valid number between 8-15 digit.</span> -->
              </div>
              <!-- </div> -->
              <!-- </div> -->
            </div>
            <!-- eeee -->
            <div class="form-check" style="margin-top: 30px;">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="checkTerms">
              <!-- <label class="form-check-label" for="exampleCheck1" data-toggle="tooltip" data-placement="top"
                title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reiciendis cumque aliquam quisquam quaerat esse provident blanditiis modi doloribus id corrupti numquam, expedita ratione distinctio quasi mollitia quae veniam eveniet ducimus.">I
                agree <a style="color: #007bff;">terms and conditions</a></label> -->

              <label class="form-check-label">I
                agree to the &nbsp;<a style="color: #007bff;" href="/terms-condition" target="_blank">terms and
                  conditions</a></label>
              <!-- validation -->
              <div>
                <span class="text-danger"
                  *ngIf="signinForm.get('checkTerms').hasError('required') && (signinForm.get('checkTerms').touched || signinForm.get('checkTerms').dirty || isFormSubmitted)">*Please
                  agree terms and conditions.</span>
              </div>
            </div>
            <div class="down-button text-left">
              <button mat-button class="buttonGlobal" (click)="signUp()">
                Get Started
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- <div class="text-right">
        <div>
          <img class="right_side_img" src="/assets/images/signin.jpg" alt="" />
        </div>
      </div> -->
    </mat-card>
  </div>
</div>