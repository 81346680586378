<div class="wrapper">
    <div class="container">
        <div class="staticHeading text-center">
            <h1 class="headingTitle new-style">Partner Program</h1>
        </div>
        <div class="termscontent">
            <div class="privacyDivs">
                <h1 class="new-style">About Our Retailer Partner Program
                </h1>
                <h4 class="header-text">It takes two to find the one.  </h4>
                <h5 class="header-text">In-store + online = The most personal dress-buying experience.
                </h5>
                <br>
                <p class="header-text">Together, we’re the ultimate power couple in helping a bride find her dream dress. To attract and keep the millennial bride’s attention, we need to show her some love. That means designing a dress-buying experience that fits her to perfection and empowers her to purchase on her terms, her way—so we don’t have a runaway bride. It’s the foundation of our moteemaids partner app, and we want you to join in. No matter where our bride discovers her dream dress—in your store, or online at moteemaids.com—she may need us both to make it happen. You bring the fit and feel (and tears and tissues). We’ve got convenience covered with the ease of a luxury online purchase. The journey is up to her, but ensuring a seamless experience is up to us.</p>
               <br>
                <p class="header-text">Invite the moteemaids bride in to touch, twirl and try on. Register her through the moteemaids partner app and let her think it over in her own space and time. She’ll commit when and where she’s ready, either in-store or online. In that moment, her dress dreams become a stunning reality, and you’re rewarded for your role in making it happen.</p>
            </div>
      <br>
     <hr>
     <div class="privacyDivs header-text">
        <!-- <h1 class="new-style"><strong class="new-style">How it works</strong> - The start of something special. -->
        <h1 class="new-style" style="color: #000000;">How it works - The start of something special.

        </h1>
       <ol>
           <li> <strong>We’re all yours.</strong> Go to the Apple iTunes store, search for moteemaids and download the app to your iPhone or iPad. An Android app isn’t available quite yet—but soon, promise.</li>
           <li> <strong> Make it official. </strong>Open the app from your mobile device and click to “Sign up.” You’ll only need to do this the first time you launch the app, then you’re set.</li>
           <li> <strong>Tell us everything. </strong> Register for an account on the moteemaids app by entering your store information, including your Store ID (check your email). To track your referrals, accuracy is everything, so double and triple check it.</li>
           <li> <strong>Bride time. </strong> As moteemaids clients visit your store, enter their profile information through your account on the app. We’re talking the basics: name, email and event date.
           </li>
           <li> <strong>Make a connection. </strong> The app will track any clients interested in moteemaids Bridesmaids, Little White Dress, moteemaids x You and Nouvelle Bridal dresses. Once you’ve added their profile information, that client will be logged into our system and will be assigned to you for that event.</li>
           <li> <strong>Finally, it clicks. </strong> She came, she saw, she’s thinking it over. If your client later chooses to purchase a dress online from moteemaids.com, rather than returning to your store, we’ll take it from there and ship directly to her. But worry not—we’ll track the purchase in the app, so you get your share for that sale. Amazing, right?</li>
      
        </ol>
        <br><br>
        <p class="header-text text-center">If you are a partner of ours please contact sales@moteemaids.com for more information</p>
    </div>
        </div>
    </div>
</div>