import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService, CountryCode } from 'src/app/provider/common.service';
declare var $;
@Component({
  selector: 'app-visitor-list',
  templateUrl: './visitor-list.component.html',
  styleUrls: ['./visitor-list.component.scss'],
})
export class VisitorListComponent implements OnInit {
  countryList: any[];
  myControl = new FormControl('');
  filteredOptions: Observable<CountryCode[]>;
  addVisitorForm: FormGroup;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  searchForm: FormGroup;
  visitorListArray: any = [];
  today = new Date().toISOString().split('T')[0];
  visitorId: any;
  name: any;
  email: any;
  mobileNumber: any;
  visitorListData: any = [];
  constructor(public commonService: CommonService, private router: Router) {}

  ngOnInit(): void {
    this.searchFormValidation();
    this.addVisitorFormValidation();
    this.getVisitorList();
  }
  ngOnDestroy() {
    $('body>#addNewVisitordetailsModalId').remove();
    $('body>#viewVisitordetailsModalId').remove();
    // $('body>#editVisitordetailsModalId').remove();
    $('body>#deleteVisitordetailsModalId').remove();
  }
  addVisitor() {
    $('#addNewVisitordetailsModalId').appendTo('body').modal('show');
  }
  // viewVisitor(){
  // $('#viewVisitordetailsModalId').appendTo("body").modal('show');
  // }
  // editVisitor() {
  //   $('#editVisitordetailsModalId').appendTo('body').modal('show');
  // }
  // deleteVisitor() {
  //   $('#deleteVisitordetailsModalId').appendTo('body').modal('show');
  // }

  addVisitorFormValidation() {
    (this.addVisitorForm = new FormGroup({
      // mobileNumber: new FormControl('', [Validators.required, Validators.pattern(/^[^0][0-9]*$/)]),

      //  business address
      clientName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl('', [Validators.required]),
      // country: new FormControl('',[Validators.required]),
    })),
      (this.countryList = this.commonService.countryListJson);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => (value ? this._filter(value) : this.countryList.slice()))
    );
  }
  private _filter(value: string): CountryCode[] {
    const filterValue = value.toLowerCase();
    return this.countryList.filter((option) =>
      option.code.toLowerCase().includes(filterValue)
    );
  }

  // search filter and reset filter
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      // status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
    });
  }

  searchFormSubmit() {
    if (
      this.searchForm.value.search ||
      this.searchForm.value.fromDate ||
      this.searchForm.value.toDate
    ) {
      //   if(this.searchForm.value.fromDate > this.searchForm.value.toDate){
      //     this.commonService.errorToast('To date should be greater than From date')
      //     // return
      //     // this.searchForm.value.fromDate = '';
      //     // this.messege = "please enter right date"
      //   }
      //   else if(this.searchForm.value.fromDate <= this.searchForm.value.toDate){

      //  this.oldDate = new Date(this.searchForm.value.fromDate).toISOString()
      //  this.newDate = new Date(this.searchForm.value.toDate+'T23:59:59.999Z')
      //  console.log('this.oldDate',this.oldDate );
      //  console.log('this.newDate',this.newDate );
      //       }
      // this.fromDate =this.searchForm.value.fromDate
      this.getVisitorList();
    }
  }
  searchFormReset() {
    if (
      this.searchForm.value.search ||
      this.searchForm.value.fromDate ||
      this.searchForm.value.toDate
    ) {
      this.searchForm.reset({
        search: '',
        // status: '',
        fromDate: '',
        toDate: '',
      });
      this.getVisitorList();
    }
  }

  // visitor list
  // -------------------- get category list --------------------- //
  getVisitorList() {
    let apiReqUrl: any = `${ApiEndPoint.listVisitor}`;

    this.commonService.showSpinner();
    let apiReqData: any = {
      page: this.currentPage,
      limit: this.itemPerPage,
      search: this.searchForm.value.search,
      // status: this.searchForm.value.status,
      fromDate: this.searchForm.value.fromDate
        ? new Date(this.searchForm.value.fromDate).toISOString()
        : '',
      toDate: this.searchForm.value.toDate
        ? new Date(this.searchForm.value.toDate).toISOString()
        : '',
    };
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData);
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe(
      (res: any) => {
        console.log('get category management list response ==>', res);
        if (res.responseCode == 200) {
          this.visitorListArray = res.result.docs ? res.result.docs : '';
          this.totalItems = res.result.total;
          this.commonService.hideSpinner();
          // this.commonService.successToast(res.responseMessage);
        } else {
          this.visitorListArray = [];
          this.totalItems = 0;
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },
      (err: any) => {
        this.visitorListArray = [];
      }
    );
  }

  // on click add visitor
  addVisitorUser() {
    let apiReqUrl = ApiEndPoint.addVisitor;
    var addVisitorData = {
      clientName: this.addVisitorForm.value.clientName,
      email: this.addVisitorForm.value.email,
      mobileNumber: this.addVisitorForm.value.mobileNumber,
      countryCode: this.myControl.value,
      visitorDescription: [
        {
          visitStatus: 'true',
          visitPlace: 'SHOP',
        },
      ],
    };
    console.log(addVisitorData);
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, addVisitorData, 1).subscribe(
      (res) => {
        console.log(res);
        if (res['responseCode'] == 200) {
          this.commonService.hideSpinner();
          $('#addNewVisitordetailsModalId').appendTo('body').modal('hide');
          this.getVisitorList();
        }
      },
      (err) => {
        console.log(err);
        this.commonService.hideSpinner();
        this.commonService.errorToast(err['responseMessage']);
      }
    );
  }

  // view visitor
  viewVisitorModal(visitorId) {
    $('#viewVisitordetailsModalId').appendTo('body').modal('show');
    this.viewVisitor(visitorId);
    this.visitorId = visitorId;
  }
  viewVisitor(visitorId) {
    localStorage.setItem('visitorId', visitorId);

    let apiReqUrl: any = `${ApiEndPoint.viewVisitor + '?_id=' + visitorId}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log('change password response ==>', res);
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.visitorListData = res.result;
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }

  deleteVisitorModal(visitorId) {
    $('#deleteVisitordetailsModalId').appendTo("body").modal('show');
    this.visitorId = visitorId
  }
 
  deleteVisitor() {
    // alert('jhjh')
    let apiReqUrl: any = `${ApiEndPoint.deleteVisitor + '?_id=' + this.visitorId}`
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl,1).subscribe((res: any) => {
      console.log("delete category user response ==>", res)
      if (res.responseCode == 200) {
        $('#deleteVisitordetailsModalId').modal('hide');
        this.getVisitorList();
        localStorage.removeItem('addressId')

        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  pagination(event) {
    console.log(event);
    this.currentPage = event;
    this.getVisitorList();
  }
}
