import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-mask-list',
  templateUrl: './mask-list.component.html',
  styleUrls: ['./mask-list.component.scss']
})
export class MaskListComponent implements OnInit {
  maskList: any;

  constructor(private commonService:CommonService) { }

  ngOnInit(): void {
    this.getMaskListApi()
  }

  /**
   * @param getMaskListApi used to get mask list
   */
  getMaskListApi(){
    let apiReqUrl = 'product/masklistForAppWeb'
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl,{},1).subscribe((res:any)=>{
      if(res.responseCode == 200){
      console.log(res);   
      this.maskList = res.result.docs 
      this.commonService.hideSpinner()
    }else{
      this.commonService.hideSpinner()
      this.commonService.errorToast(res.responseMessage)
    }
    },(err)=>{
      this.commonService.hideSpinner()
      this.commonService.errorToast(err.responseMessage)
    })
  }
}
