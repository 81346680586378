import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit {
  pressList: any;

  constructor(private commonService:CommonService) { }

  ngOnInit(): void {
    this.pressApiList()
  }

/**
 * @param pressApiList function is used to get press list
 * @param apiReqUrl contains end point url to get press list
 */  
 pressApiList(){
  let apiReqUrl=`${"story/blogList?type=PRESS"}`
  this.commonService.showSpinner()
  this.commonService.getApi(apiReqUrl,1).subscribe((res:any)=>{
    if(res.responseCode == 200){
      this.pressList = res.result
      this.commonService.hideSpinner()
    }else{
      this.commonService.hideSpinner()
    }
  },(err)=>{
    this.commonService.hideSpinner()
  })}
}
