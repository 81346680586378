import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CommonService, CountryCode } from 'src/app/provider/common.service';

@Component({
  selector: 'app-add-new-address',
  templateUrl: './add-new-address.component.html',
  styleUrls: ['./add-new-address.component.scss']
})
export class AddNewAddressComponent implements OnInit {
addAddressForm:FormGroup;
countryList: any[];
myControl = new FormControl('+91');
toggleEye: boolean = true;  // toggle eye for password field
toggleEyeC: boolean = true;  // toggle eye for confirm password
filteredOptions: Observable<CountryCode[]>;

constructor(private route: Router, public commonService: CommonService) { }

  ngOnInit(): void {
    this.formValidation()
  }

  formValidation(){
  this.addAddressForm= new FormGroup({
  firstName:new FormControl("",Validators.required),
  lastName: new FormControl("",Validators.required),
  address: new FormControl("",Validators.required),
  city: new FormControl("",Validators.required),
  state: new FormControl("",Validators.required),
  country: new FormControl("",Validators.required),
  zipCode: new FormControl("",Validators.required),
  phone:  new FormControl("", [
    Validators.required,
    Validators.pattern(/^[^0][0-9]*$/)]),
})
this.countryList = this.commonService.countryListJson;
this.filteredOptions = this.myControl.valueChanges.pipe(
  startWith(''),
  map(value => (value ? this._filter(value) : this.countryList.slice())),
);
}

private _filter(value: string): CountryCode[] {
const filterValue = value.toLowerCase();
return this.countryList.filter(option => option.code.toLowerCase().includes(filterValue));
}
}
