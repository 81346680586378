<div class="wrapper">
    <div class="w-90 p-3 d-flex justify-content-around">
        <div class="lft col-md-6 p-3">
            <div class="">
                <h2 class="header-text">Motee Maids</h2>
            </div>
            <div class="content">
                <h4 class="header-text m-0">Importance Notice</h4>
                <p class="header-text">Please note, signature is required for all dress deliveries. This is to ensure
                    the safe delivery of your Motee Maids dress, no exceptions are made to this policy.</p>
            </div>
            <div class="form">
                <form [formGroup]="informationForm">
                    <div class="contect-form mb-2">
                        <div class="d-flex justify-content-between">
                            <h4 class="header-text m-0">Contact information</h4>
                            <!-- <p class="header-text m-0">Already have an account?<a class="hh">Log in</a></p> -->
                        </div>
                        <div class="field__input-wrapper">
                            <label for="checkout_email" class="field__label field__label--visible"></label>
                            <!-- <input placeholder="Email" autocapitalize="off" spellcheck="false" autocomplete="shipping email"
                            data-shopify-pay-handle="true" data-autofocus="true" data-backup="customer_email"
                            aria-describedby="checkout-context-step-one" aria-required="true" class="field__input"
                            [value]="email" size="30" type="email" id="checkout_email"> -->
                            <input placeholder="Email" autocapitalize="off" spellcheck="false"
                                autocomplete="shipping email" data-shopify-pay-handle="true" data-autofocus="true"
                                data-backup="customer_email" aria-describedby="checkout-context-step-one"
                                aria-required="true" class="field__input" formControlName="email" size="30" type="text"
                                id="checkout_email" maxlength="256">
                        </div>
                        <div>
                            <span class="error p-0"
                                *ngIf="informationForm.get('email').hasError('required') && (informationForm.get('email').touched ||informationForm.get('email').dirty)">
                                *Email is required.</span>
                            <span class="error p-0" *ngIf="informationForm.get('email').hasError('pattern')">*Please
                                enter valid email.</span>
                        </div>
                    </div>
                </form>
                <div class="shipping mt-4">
                    <h4 class="header-text m-0">Shipping Address</h4>





                    <!-- <div class="field__input-wrapper d-flex justify-content-between">
                        <div class="col-md-6 pl-0">
                            <label for="checkout_fName" class="field__label field__label--visible"></label>
                            <input placeholder="First Name" autocapitalize="off" spellcheck="false"
                                autocomplete="shipping first name" data-shopify-pay-handle="true" data-autofocus="true"
                                data-backup="checkout_fName" aria-describedby="checkout-context-step-one"
                                aria-required="true" class="field__input" size="30" type="text" id="checkout_fName">
                        </div>
                        <div class="col-md-6 pr-0">
                            <label for="checkout_lName" class="field__label field__label--visible"></label>
                            <input placeholder="Last Name" autocapitalize="off" spellcheck="false"
                                autocomplete="shipping last name" data-shopify-pay-handle="true" data-autofocus="true"
                                data-backup="checkout_lName" aria-describedby="checkout-context-step-one"
                                aria-required="true" class="field__input" size="30" type="text" id="checkout_lName">
                        </div>
                    </div>
                    <div>
                        <div class="field__input-wrapper">
                            <label for="checkout_address" class="field__label field__label--visible"></label>
                            <input placeholder="Address" autocapitalize="off" spellcheck="false"
                                autocomplete="shipping address" data-shopify-pay-handle="true" data-autofocus="true"
                                data-backup="checkout_address" aria-describedby="checkout-context-step-one"
                                aria-required="true" class="field__input" size="30" type="text" id="checkout_address">
                        </div>
                        <div class="field__input-wrapper">
                            <label for="checkout_apart" class="field__label field__label--visible"></label>
                            <input placeholder="Apartment" autocapitalize="off" spellcheck="false"
                                autocomplete="shipping apart" data-shopify-pay-handle="true" data-autofocus="true"
                                data-backup="customer_apart" aria-describedby="checkout-context-step-one"
                                aria-required="true" class="field__input" size="30" type="text" id="checkout_apart">
                        </div>
                        <div class="field__input-wrapper">
                            <label for="checkout_city" class="field__label field__label--visible"></label>
                            <input placeholder="City" autocapitalize="off" spellcheck="false"
                                autocomplete="shipping city" data-shopify-pay-handle="true" data-autofocus="true"
                                data-backup="customer_city" aria-describedby="checkout-context-step-one"
                                aria-required="true" class="field__input" size="30" type="text" id="checkout_city">
                        </div>
                    </div>
                    <div class="field__input-wrapper d-flex justify-content-between">
                        <div class="col-md-4 pl-0">
                            <label for="country" class="field__label field__label--visible"></label>
                            <select size="1" autocomplete="shipping country" data-backup="country"
                                class="field__input field__input--select" aria-required="true" id="country"
                                placeholder="Country/region">
                                <option data-code="US" selected="selected" value="United States">United States</option>
                                <option disabled="disabled" value="---">---</option>
                                <option data-code="CA" value="Canada">India</option>
                                <option data-code="US" value="United States">United States</option>
                            </select>
                            <div class="field__caret">
                            </div>
                        </div>
                        <div class="col-md-4 pl-0">
                            <label for="state" class="field__label field__label--visible"></label>
                            <select placeholder="State" autocomplete="shipping address-level1" autocorrect="off"
                                data-backup="province" class="field__input field__input--select" aria-required="true"
                                name="checkout[shipping_address][province]" id="state">
                                <option disabled="disabled" value="---">---</option>
                                <option data-code="CA" value="Canada">Delhi</option>
                            </select>
                            <div class="field__caret">
                            </div>
                        </div>
                        <div class="col-md-4 pr-0 pl-0">
                            <label for="zipcode" class="field__label field__label--visible"></label>
                            <input placeholder="Zipcode" autocapitalize="off" spellcheck="false"
                                autocomplete="shipping last name" data-shopify-pay-handle="true" data-autofocus="true"
                                data-backup="zipcode" aria-describedby="checkout-context-step-one" aria-required="true"
                                class="field__input" size="30" type="number" id="zipcode" min="1">
                        </div>
                    </div>
                    <div>
                        <div class="field__input-wrapper">
                            <label for="phone" class="field__label field__label--visible"></label>
                            <input placeholder="Phone" autocapitalize="off" spellcheck="false"
                                autocomplete="shipping email" data-shopify-pay-handle="true" data-autofocus="true"
                                data-backup="customer_email" aria-describedby="checkout-context-step-one"
                                aria-required="true" class="field__input" size="30" type="number" min="1" id="phone">
                        </div>
                    </div> -->
                    <div class="border-btn-wrapper">
                        <button class="submit-form-btn buttonGlobal btn-add-cust" id="submit-cart-modal"
                            data-dismiss="modal" data-toggle="modal" data-target="#addNewAddressModalId">
                            + Add New Address
                        </button>
                    </div>

                    <!-- <div class="modal-atc-body"> -->
                    <!-- <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Please select address!
                        </h2> -->
                    <!-- <div class="form-element-wrapper"> -->
                    <!-- </div> -->
                    <div class="">
                        <!-- <div class="hidden-desktop">
                              <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                          </div> -->

                        <div class="image-wrapper">

                            <div class="data-details">
                                <div class="address-card-box-shadow" *ngFor="let item of listAddressArray">
                                    <div class="row mt-3 breakAll">
                                        <div class="col-xl-1 col-lg-2  col-md-3 col-sm-3">
                                            <div class="align-checkbox-vertical-center">
                                                <input class="form-check-input" type="radio" name="exampleRadios"
                                                    id="exampleRadios1" value="option1"
                                                    (click)="sendRadioButton(item?.address,item?.state,item?.city,item?.zipCode,item?.country,item?._id)">
                                            </div>
                                        </div>
                                        <div class="col-xl-11 col-lg-10 col-md-9 col-sm-9">
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <p class="product-name title-color-dark">{{item?.address}}</p>
                                                    <p class="text-color-light m-0">{{item?.state + ',' + item?.city +
                                                        ',' + item?.zipCode}}</p>
                                                    <p class="text-color-light">{{getCountry(item?.country)}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="address-card-box-shadow">
                                    <div class="row mt-3">
                                        <div class="col-xl-1 col-lg-2  col-md-3 col-sm-3">
                                            <div class="align-checkbox-vertical-center">
                                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                                            </div>
                                        </div>
                                        <div class="col-xl-11 col-lg-10 col-md-9 col-sm-9">
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <p class="product-name title-color-dark">10 Fubar Road</p>
                                                    <p class="text-color-light m-0">Fubartown, London SW1A 1AA</p>
                                                    <p class="text-color-light">United Kingdom</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div class="address-card-box-shadow">
                                    <div class="row mt-3">
                                        <div class="col-xl-1 col-lg-2  col-md-3 col-sm-3">
                                            <div class="align-checkbox-vertical-center">
                                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                                            </div>
                                        </div>
                                        <div class="col-xl-11 col-lg-10 col-md-9 col-sm-9">
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <p class="product-name title-color-dark">10 Fubar Road</p>
                                                    <p class="text-color-light m-0">Fubartown, London SW1A 1AA</p>
                                                    <p class="text-color-light">United Kingdom</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                            </div>
                        </div>

                    </div>


                    <!-- <div class="form-element-wrapper">
                            <div class="border-btn-wrapper">
                                <div class="submit-form-btn buttonGlobal" id="submit-cart-modal" data-dismiss="modal">
                                    Submit
                                </div>
                            </div>
                        </div> -->


                    <!-- </div> -->




                </div>
            </div>
            <div class="foot d-flex mt-4  justify-content-between align-items-center">
                <div class="lt d-flex justify-content-center align-items-center">
                    <i class="fa fa-angle-left mr-1"></i>
                    <p class="header-text m-0 cursor" (click)="returnToCart()">Return to cart</p>
                </div>
                <div class="rt">
                    <button class="buttonGlobal" routerLink="/latest-motee-collection">Continue to Shopping</button>
                </div>
            </div>
        </div>
        <div class="vlt"></div>
        <div class="col-md-4 p-3">
            <mat-card class="mat-list-card" *ngFor='let item of shoppingBag'>
                <mat-card-content>
                    <div class="lht">

                        <figure [matBadge]="item?.enteredQuantity" matBadgeOverlap="false">
                            <img [src]="item?.productId?.productImage?.frontImage?.webImage" alt="">
                        </figure>
                        <div>
                            <p class="header-text m-0">{{item?.productId?.productName}}</p>
                            <!-- <p class="header-text m-0">Black</p> -->
                            <div *ngIf="item?.colors" class="color-block-circle-small mr-3 "
                                [style.background-color]="item?.colors?.colorCode"></div>
                            <div *ngIf="item?.premiumColorId" class="color-block-circle-small mr-3 "
                                [style.background-color]="item?.premiumColorId?.colorCode"></div>
                        </div>

                        <div>
                            <p class="header-text m-0">
                                {{(item?.enteredQuantity * item?.totalMrp) | currency: item?.currencyCode}}
                            </p>
                        </div>
                    </div>
                    <div>
                        <p class="header-text m-0">Size : {{item?.size}}</p>
                        <p class="header-text m-0">Length : {{item?.dressLength}}</p>
                    </div>
                </mat-card-content>
                <br>
            </mat-card>
            <hr>
            <mat-card class="mt-3 mb-2">
                <!-- <mat-card-content class="mat-card-content-custom">
                    <mat-card-subtitle>Subtotal:</mat-card-subtitle>
                    <mat-card-subtitle>{{subtotal | currency: ipDetails?.geoplugin_currencySymbol}}</mat-card-subtitle>
                </mat-card-content>
                <mat-card-content class="mat-card-content-custom" style="margin-bottom: 25px;">
                    <mat-card-subtitle>Shipping</mat-card-subtitle>
                    <mat-card-subtitle>{{shippingCharge | currency: ipDetails?.geoplugin_currencySymbol}}
                    </mat-card-subtitle>
                </mat-card-content>
                <hr> -->
                <mat-card-content class="mat-card-content-custom mt-4">
                    <mat-card-title>Total</mat-card-title>
                    <mat-card-title>{{total | currency: ipDetails?.geoplugin_currencySymbol}}</mat-card-title>
                </mat-card-content>
                <br>
                <!-- <button mat-raised-button class="buttonGlobal" color="accent"  (click)="navigateToCheckout()">CHECKOUT</button> -->
                <div class="text-center">
                    <button mat-raised-button class="buttonGlobal" color="accent" (click)="checkout()">CHECKOUT</button>
                </div>
            </mat-card>
        </div>
    </div>
</div>








<!--  centered modal -->
<div class="modal fade" id="selectAddressModal" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>

            <div class="modal-atc-body">
                <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">Please
                    select address!
                </h2>
                <!-- <div class="form-element-wrapper"> -->
                <div class="border-btn-wrapper">
                    <button class="submit-form-btn buttonGlobal btn-add-cust" id="submit-cart-modal"
                        data-dismiss="modal" data-toggle="modal" data-target="#addNewAddressModalId">
                        + Add
                    </button>
                </div>
                <!-- </div> -->
                <div class="">
                    <!-- <div class="hidden-desktop">
                      <h2 class="hidden-desktop">You've successfully added a dress to your bag!</h2>
                  </div> -->

                    <div class="image-wrapper">

                        <div class="data-details">
                            <div class="row mt-3">
                                <div class="col-md-1">
                                    <div class="align-checkbox-vertical-center">
                                        <input class="form-check-input" type="radio" name="exampleRadios"
                                            id="exampleRadios1" value="option1" checked>
                                    </div>
                                </div>
                                <div class="col-md-11">
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="product-name title-color-dark">
                                                Mobiloitte
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="title text-color-light">
                                                City
                                            </span>
                                        </div>
                                        <div class="col-9">
                                            <span class="text-color-light">
                                                New Delhi
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="title text-color-light">
                                                State
                                            </span>
                                        </div>
                                        <div class="col-9">
                                            <span class="text-color-light">
                                                Delhi
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="title text-color-light">
                                                Country
                                            </span>
                                        </div>
                                        <div class="col-9">
                                            <span class="text-color-light">
                                                India
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-1">
                                    <div class="align-checkbox-vertical-center">
                                        <input class="form-check-input" type="radio" name="exampleRadios"
                                            id="exampleRadios1" value="option1" checked>
                                    </div>
                                </div>
                                <div class="col-md-11">
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="product-name title-color-dark">
                                                Mobiloitte
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="title text-color-light">
                                                City
                                            </span>
                                        </div>
                                        <div class="col-9">
                                            <span class="text-color-light">
                                                New Delhi
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="title text-color-light">
                                                State
                                            </span>
                                        </div>
                                        <div class="col-9">
                                            <span class="text-color-light">
                                                Delhi
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="title text-color-light">
                                                Country
                                            </span>
                                        </div>
                                        <div class="col-9">
                                            <span class="text-color-light">
                                                India
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-1">
                                    <div class="align-checkbox-vertical-center">
                                        <input class="form-check-input" type="radio" name="exampleRadios"
                                            id="exampleRadios1" value="option1" checked>
                                    </div>
                                </div>
                                <div class="col-md-11">
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="product-name title-color-dark">
                                                Mobiloitte
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="title text-color-light">
                                                City
                                            </span>
                                        </div>
                                        <div class="col-9">
                                            <span class="text-color-light">
                                                New Delhi
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="title text-color-light">
                                                State
                                            </span>
                                        </div>
                                        <div class="col-9">
                                            <span class="text-color-light">
                                                Delhi
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="title text-color-light">
                                                Country
                                            </span>
                                        </div>
                                        <div class="col-9">
                                            <span class="text-color-light">
                                                India
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="form-element-wrapper">
                    <div class="border-btn-wrapper">
                        <div class="submit-form-btn buttonGlobal" id="submit-cart-modal" data-dismiss="modal">
                            Submit
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>



<!-- add address -->

<!--  centered modal -->
<div class="modal fade" id="addNewAddressModalId" tabindex="-1" aria-labelledby="bagModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered chat-dgl">
        <div class="modal-content">
            <div class="modal-atc-header">
                <img class="mottemaids-logo" src="../../../../assets/images/motee-maids-log.png" alt="mottemaids">
                <div class="close-btn">
                    <i class="fa fa-times new-ics" aria-hidden="true" data-dismiss="modal"></i>
                </div>
            </div>
            <form [formGroup]="chackoutForm" action="">
                <div class="modal-atc-body">
                    <h2 class="hidden-mobile title-color-dark text-center" style="font-size: 28px;font-weight: 400;">New
                        Address!
                    </h2>
                    <div class="">
                        <div class="image-wrapper">

                            <div class="data-details">
                                <div class="row mt-2">
                                    <div class="col-md-12">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Address</mat-label>
                                            <input matInput placeholder="Address" formControlName="address"
                                                maxlength="60">
                                        </mat-form-field>
                                        <!-- <span class="error"
                                            *ngIf="chackoutForm.get('address').hasError('required') && (chackoutForm.get('address').dirty || chackoutForm.get('address').touched || isSubmitted)">*Please
                                            enter address.</span> -->
                                        <div class="text-danger"
                                            *ngIf="(chackoutForm.get('address').invalid && (chackoutForm.get('address').touched || chackoutForm.get('address').dirty || isSubmitted));">
                                            <span *ngIf="chackoutForm.get('address').errors.required">
                                                *Address is required.
                                            </span>
                                            <span *ngIf="chackoutForm.get('address').errors.pattern">
                                                *Please enter valid address.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Country</mat-label>
                                            <mat-select (selectionChange)="stateList($event)" formControlName="country">
                                                <mat-option *ngFor="let item of countryListArray"
                                                    [value]="item?.countryCode">{{item?.countryName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <!-- <span class="error"
                                            *ngIf="chackoutForm.get('country').hasError('required') && (chackoutForm.get('country').dirty || chackoutForm.get('country').touched || isSubmitted)">*Please
                                            select country.</span> -->
                                        <div class="text-danger"
                                            *ngIf="(chackoutForm.get('country').invalid && (chackoutForm.get('country').touched || chackoutForm.get('country').dirty || isSubmitted));">
                                            <span *ngIf="chackoutForm.get('country').errors.required">
                                                *Country is required.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>State</mat-label>
                                            <mat-select formControlName="state">
                                                <mat-option *ngFor="let item of stateListArray"
                                                    [value]="item?.stateName">{{item?.stateName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <!-- <span class="error"
                                            *ngIf="chackoutForm.get('state').hasError('required') && (chackoutForm.get('state').dirty || chackoutForm.get('state').touched || isSubmitted)">*Please
                                            select state.</span> -->
                                        <div class="text-danger"
                                            *ngIf="(chackoutForm.get('state').invalid && (chackoutForm.get('state').touched || chackoutForm.get('state').dirty || isSubmitted));">
                                            <span *ngIf="chackoutForm.get('state').errors.required">
                                                *State is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>City</mat-label>
                                            <input matInput placeholder="City" formControlName="city" maxlength="60">
                                        </mat-form-field>
                                        <!-- <span class="error"
                                            *ngIf="chackoutForm.get('city').hasError('required') && (chackoutForm.get('city').dirty || chackoutForm.get('city').touched || isSubmitted)">*Please
                                            enter city.</span> -->
                                        <div class="text-danger"
                                            *ngIf="(chackoutForm.get('city').invalid && (chackoutForm.get('city').touched || chackoutForm.get('city').dirty || isSubmitted));">
                                            <span *ngIf="chackoutForm.get('city').errors.required">
                                                *City is required.
                                            </span>
                                            <span *ngIf="chackoutForm.get('city').errors.pattern">
                                                *Please enter valid city.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Zip Code</mat-label>
                                            <input matInput placeholder="Zip Code" formControlName="zipCode"
                                                maxlength="10">
                                        </mat-form-field>
                                        <!-- <span class="error"
                                            *ngIf="chackoutForm.get('zipCode').hasError('required') && (chackoutForm.get('zipCode').dirty || chackoutForm.get('zipCode').touched || isSubmitted)">*Please
                                            enter zip code.</span> -->
                                        <div class="text-danger"
                                            *ngIf="(chackoutForm.get('zipCode').invalid && (chackoutForm.get('zipCode').touched || chackoutForm.get('zipCode').dirty || isSubmitted));">
                                            <span *ngIf="chackoutForm.get('zipCode').errors.required">
                                                *Zip code is required.
                                            </span>
                                            <span *ngIf="chackoutForm.get('zipCode').errors.pattern">
                                                *Please enter valid zip code.
                                            </span>
                                        </div>
                                    </div>
                                </div>



                            </div>


                        </div>

                    </div>


                    <div class="form-element-wrapper">
                        <div class="border-btn-wrapper">
                            <div class="submit-form-btn buttonGlobal" id="submit-cart-modal" (click)="addNewAddress()">
                                Submit
                            </div>
                        </div>
                    </div>


                </div>
            </form>
        </div>
    </div>
</div>