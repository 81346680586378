<div class="wrapper">
    <div class="join-data">
        <div class="staticHeading text-center">
            <h1 class="headingTitle new-style">Join Our Team</h1>
        </div>
        <div class="termscontent">
            <div class="privacyDivs">
                <h4 class="header-text">Blending innovation and tradition, moteemaids is a New York City atelier and design house that crafts striking and refined wedding wear for the modern bride and revolutionizes the 
                    shopping experience with a tech-forward approach.  </h4>
                <h4 class="header-text">As a Black-owned business, moteemaids cultivates an environment of inclusivity for both our brides and our employees. We value connection, creativity and collaboration, and lead the industry through innovation. Our family is made up of visual, solution-centric and forward-thinking individuals that work together to create an extraordinary product and experience.</h4>
            </div>
      <br>
      <div class="privacyDivs">
          <figure>
              <img class="new-data" src="assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg" alt="">
          </figure>
      </div>
      <br>
      <div class="privacyDivs">
        <h4 class="header-text">“More than an aesthetic—style is a way of living.” These words from our late founder moteemaids Aberra guide our every move at moteemaids, and working with our team means infusing style and 
            creativity into life so that everyday is as special as the big day.  </h4>
        <h4 class="header-text">moteemaids offers its employees an inviting and creative space to grow both personally and professionally, develop their skills with help from expert resources and contribute to a brand that is
             not only crafting striking wedding wear for every bride, but changing the way she shops for it.</h4>
    </div>
        </div>
    </div>
</div>