<div class="wrapper p-4 mb-5">
    <div class="header d-flex justify-content-between align-items-end">
       <div class="title d-flex col-md-7 justify-content-between align-items-center">
            <h2 class="header-text">Masks</h2>
            <p class="header-text">The finishing touch for your bridal party. A selection of masks that match our bridesmaids color palette. More to come soon.</p>
       </div>
       <div class="des">
           <p><em>3 Products</em></p>
       </div>
    </div>
    <div class="contents">
        <ul class="d-flex grid--uniform grid--view-items">
            <li class="grid__item grid__item--collection-template medium-up--one-third" *ngFor = "let item of maskList">
                <div class="custom__product-card" routerLink="/view-mothers-dress">
                    <a routerLink="/view-mask">
                        <div class="hover-reveal-effect">
                            <img [src]="item?.colors[0]?.image?.webImage" class="grid-view-item__image productFullImg lazyautosizes lazyloaded" alt="">
                            <img src="/assets/images/masks/mask2.jpeg" class="grid-view-item__image productFullImg hidden-reveal-image lazyautosizes lazyloaded" alt="">
                            <div class="descrip">
                                <span class="header-text">{{item?.maskName}}</span>
                                <p class="header-text">$20.00</p>
                            </div>
                        </div>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</div>