<!-- start toolbar -->
<!-- <mat-toolbar class="toolbar_custom">
    <span>Home/</span><span>View Product</span>
  </mat-toolbar> -->
  <!-- end toolbar -->


  <div class="wrraper">
    <div class="container pt-5">
        <div class="content wow  bounceInDown">
            <div class="title">
                <h3 class="m-0">View Product</h3>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Product Name</label><span class="col-md-1">:</span><p class="col-md-8 value">Top</p>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Product Image</label><span class="col-md-1">:</span>
                <div class="col-md-8 value">
                    <img src="/assets/images/grid1.png" alt="" style="width:300px;height:200px">
                </div>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Product Price(M.R.P.)</label><span class="col-md-1">:</span><p class="col-md-8 value">$ 1000</p>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Category</label><span class="col-md-1">:</span><p class="col-md-8 value">Bridal Dress</p>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Sub Category</label><span class="col-md-1">:</span><p class="col-md-8 value">Bridal gowns</p>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Product Description</label><span class="col-md-1">:</span><p class="col-md-8 value">This is bridal gowns and ready to ship</p>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Product Size</label><span class="col-md-1">:</span>
                <div class="col-md-8 value d-flex p-0">
                    <div class="d-flex col-md-3 p-0 m-0">
                        <label class="col-md-6 key_custom">Size</label><span class="col-md-1">:</span><p class="col-md-5 value">8</p>
                    </div>
                    <div class="d-flex col-md-4">
                        <label class="col-md-6 key_custom">Quantity</label><span class="col-md-1">:</span><p class="col-md-5 value">1</p>
                    </div>
                    <div class="d-flex col-md-5">
                        <label class="col-md-6 key_custom">Additional Price</label><span class="col-md-1">:</span><p class="col-md-5 value">$ 10</p>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Color</label><span class="col-md-1">:</span>
                <div class="col-md-8 value d-flex p-0">
                    <div class="d-flex col-md-5 p-0 m-0">
                        <label class="col-md-6 key_custom">Name</label><span class="col-md-1">:</span><p class="col-md-5 value">Black</p>
                    </div>
                    <div class="d-flex col-md-7">
                        <label class="col-md-6 key_custom">Additional Price</label><span class="col-md-1">:</span><p class="col-md-5 value">$ 10</p>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Fabric</label><span class="col-md-1">:</span>
                <div class="col-md-8 value d-flex p-0">
                    <div class="d-flex col-md-5 p-0 m-0">
                        <label class="col-md-6 key_custom">Name</label><span class="col-md-1">:</span><p class="col-md-5 value">Lace</p>
                    </div>
                    <div class="d-flex col-md-7">
                        <label class="col-md-6 key_custom">Additional Price</label><span class="col-md-1">:</span><p class="col-md-5 value">$ 0</p>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Neckline</label><span class="col-md-1">:</span>
                <div class="col-md-8 value d-flex p-0">
                    <div class="d-flex col-md-5 p-0 m-0">
                        <label class="col-md-6 key_custom">Name</label><span class="col-md-1">:</span><p class="col-md-5 value">V- neck</p>
                    </div>
                    <div class="d-flex col-md-7">
                        <label class="col-md-6 key_custom">Additional Price</label><span class="col-md-1">:</span><p class="col-md-5 value">$ 0</p>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <label class="col-md-3 key_custom">Silhouette</label><span class="col-md-1">:</span>
                <div class="col-md-8 value d-flex p-0">
                    <div class="d-flex col-md-5 p-0 m-0">
                        <label class="col-md-6 key_custom">Name</label><span class="col-md-1">:</span><p class="col-md-5 value">Straight</p>
                    </div>
                    <div class="d-flex col-md-7">
                        <label class="col-md-6 key_custom">Additional Price</label><span class="col-md-1">:</span><p class="col-md-5 value">$ 0</p>
                    </div>
                </div>
            </div>
            <div class="footer_ text-center">
                <button class="buttonGlobal">Back</button>
            </div>
        </div>
    </div>
</div>