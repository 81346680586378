import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
import Swal from 'sweetalert2';

// material table interface
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  total: any;
}
const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', total: '100' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He', total: '200' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li', total: '3000' },
];
@Component({
  selector: 'app-my-shopping-bag',
  templateUrl: './my-shopping-bag.component.html',
  styleUrls: ['./my-shopping-bag.component.scss']
})
export class MyShoppingBagComponent implements OnInit {

  // columns which are being used in table 
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'total'];
  dataSource = ELEMENT_DATA;
  clickedRows = new Set<PeriodicElement>();
  shoppingBag: any = [];
  itemPerPage = 50;
  currentPage = 1;
  totalItems: any;
  quntityForm: FormGroup
  subtotal = 0
  id: any;
  productId: any;
  quantity: any;
  size: any;
  colors: any;
  shoppingBagBuffer: any;
  ipDetails: any;
  // shippingCharge: number = 40;
  shippingCharge: number = 0;
  total: number = 0
  constructor(private commonService: CommonService, private router: Router) {
    this.ipDetails = this.commonService.getIPDetails();

  }


  ngOnInit(): void {
    this.myShopingBagApi()
    this.quntityForm = new FormGroup({
      'quantity': new FormControl('', Validators.required)
    })
  }

  myShopingBagApi() {
    // let apiReqUrl= ApiEndPoint.shoppingbagList 
    console.log("check");

    let apiReqUrl = `${ApiEndPoint.shoppingbagList + '?page=' + this.currentPage + '&limit=' + this.itemPerPage}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.shoppingBag = res.result.docs
        // this.shoppingBagBuffer = res.result.docs
        this.totalItems = res.result.total
        this.getTotalProductPrice()
        this.commonService.hideSpinner()
      } else {
        this.shoppingBag = []
        this.totalItems = 0
        this.getTotalProductPrice()
        this.commonService.hideSpinner()
      }
    }, (err) => {
      if (err.responseCode == 404) {
        this.shoppingBag = []
        this.totalItems = 0
        this.getTotalProductPrice()
        this.commonService.hideSpinner()
      }
    })
  }

  removeProductFromBagApi(_id) {
    let apiReqUrl = `${ApiEndPoint.bagDelete + '?bagId=' + _id}`
    this.commonService.showSpinner()
    this.commonService.deleteApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        // this.commonService.successToast(res.responseMessage)
        Swal.fire({
          icon: 'success',
          text: res.responseMessage,
        })
        this.myShopingBagApi()
      } else {
        this.commonService.hideSpinner()
        // this.commonService.errorToast('res.responseMessage')
      }
    }, err => {
      this.myShopingBagApi()
    })
  }

  pagination(event) {
    console.log(event);
    this.currentPage = event;
    this.myShopingBagApi();
  }

  navigateToProductDetails(_id) {
    // this.router.navigate(["/view-mothers-dress"], { queryParams: { productId: _id } })

  }

  getAllProductId(id, productId, colors, size, quantity) {
    this.id = id
    this.productId = productId
    this.colors = colors
    this.size = size
    this.quantity = quantity
    console.log(this.id, this.productId, this.colors, this.size, this.quantity);
  }

  /**
   * @param updateShoppingBag function is used to update shopping bag 
   */
  updateShoppingBag() {
    let apiReqUrl = ApiEndPoint.updateBag
    let apiReqData = {
      "_id": this.id,
      "dressLength": "string",
      "productDetails": {
        "productId": this.productId,
        "colors": this.colors,
        "size": this.size,
        "quantity": this.quantity
      },
    }
    this.commonService.showSpinner()
    this.commonService.putApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        this.commonService.alertSuccess(res.responseMessage)
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.alertError(res.responseMessage)
      }
    })
  }
  /**
   * userType are used in website
   * @param CONSUMER -> normal user comes to buy product
   * @param RETAILER -> wholesale retailer comes to buy product for consumer
   * @param INFLUENCER -> location ideal person of some place refer to buy product
   */
  customerLogin() {
    let apiReqUrl = ApiEndPoint.login;
    let apiReqData = {

      "productDetails": [
        {
          "productId": "624eaa46896a574f26535dbb",
          "quantity": 1,
          "colors": "6242cf95509b710c210012d4"
        }
      ],
      "address": "625eac62873fe921d5510e5c",
      "lat": "2.02",
      "long": "2.02"
    };
    this.commonService.showSpinner();
    this.commonService.postApi('user/createOrder?type=PRODUCT&buyType=WITHBAG', apiReqData, 0).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          localStorage.setItem('userId', res.result._id);
          // this.commonService.successToast(res.responseMessage)
          // Swal.fire({
          //   icon: 'success',
          //   text: res.responseMessage,
          // })
          this.router.navigate(['homepage']);
        } else {
          this.commonService.errorToast(res.responseMessage);
        }
      },
      (err) => {
        console.log(err);
        this.commonService.hideSpinner();
        if (err.responseCode == 404) {
          this.commonService.alertError(err.responseMessage);
        } else if (err.responseCode == 402) {
          this.commonService.alertError(err.responseMessage);
          localStorage.removeItem('token');
        } else {
          this.commonService.alertError(err.responseMessage);
        }
      }
    );
  }

  // productQuantityChange(index, currentQuantity) {
  //   console.log(index);
  //   console.log(currentQuantity);
  //   if (currentQuantity == undefined || currentQuantity == null) {
  //     return
  //   }
  //   this.shoppingBag[index].enteredQuantity == currentQuantity
  // }

  getTotalProductPrice() {
    if (this.shoppingBag.length) {
      this.subtotal = 0
      this.shoppingBag.forEach(element => {
        console.log(element);
        this.subtotal = this.subtotal + element.totalMrp * element.enteredQuantity
        this.total = this.subtotal + this.shippingCharge
      });
    } else {
      this.total = 0
    }
    this.commonService.totalItemsInShoppingBag.next(this.totalItems)
  }


  checkout() {
    // routerLink = "/checkout-saved-address"
    if (!this.shoppingBag.length) {
      return this.commonService.alertError('You have no items in your shopping cart.')
    } else {
      this.router.navigate(['/checkout-saved-address'])
    }
  }


  editBagProductQuantity(bagId, enteredQuantity, actionType) {
    console.log(bagId);
    console.log(enteredQuantity);
    console.log(actionType);
    actionType == 'ADD' ? enteredQuantity = enteredQuantity + 1 : enteredQuantity = enteredQuantity - 1;

    if (enteredQuantity == 0) {
      return this.commonService.infoToast('Product quantity can not be 0.')
    }
    let apiReqUrl = 'user/editBag?_id=' + bagId
    let apiReqData = {
      enteredQuantity: enteredQuantity
    }
    console.log(apiReqData);

    this.commonService.showSpinner();
    this.commonService.putApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.myShopingBagApi()
      } else {
        this.commonService.errorToast(res.responseMessage);
      }
    }, (err) => {
      console.log(err);
      this.commonService.hideSpinner();
      if (err.responseCode == 404) {
        this.commonService.alertError(err.responseMessage);
      } else if (err.responseCode == 402) {
        this.commonService.alertError(err.responseMessage);
        localStorage.removeItem('token');
      } else {
        this.commonService.alertError(err.responseMessage);
      }
    });
  }
}
