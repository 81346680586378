import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-store-locator-dialog',
  templateUrl: './store-locator-dialog.component.html',
  styleUrls: ['./store-locator-dialog.component.scss']
})
export class StoreLocatorDialogComponent implements OnInit {

  constructor( public dialog: MatDialog) { }

  ngOnInit(): void {
  }
 // open store locator modal path-pages/modals/store-locator-dialog
  openDialog() {
    const dialogRef = this.dialog.open(StoreLocatorDialogComponent, {
      height: '500px',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
