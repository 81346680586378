<div class="wrraper back-pic">
  <!-- signin Modal -->
  <div class="formDiv">
    <mat-card class="mat-elevation-z0 bdr">
      <div class="main">
        <div class="top-text text-left">
          <mat-card-title class="sign-text header-text">Get started with your account
          </mat-card-title>
          <p class="text-left textAfterloginHeading new-style">
            Already have an account? <a class="regis" (click)="navigateToSignin()"> Log in</a>
          </p>
        </div>
        <div class="input-field">
          <form [formGroup]="signinForm">

            <!-- business address -->
            <div class="row">
              <div class="input1 col-md-12">
                <!-- <h3 class="p-0 m-0">Business Address
                </h3> -->
                <!-- --------=-=-=--=-----Zip Code----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">Company Name <span class="c-red">*</span></label>
                <input type="text" class="user-name" placeholder="Enter Company Name" formControlName="companyName"
                  maxlength="60" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('companyName').invalid && (signinForm.get('companyName').touched || signinForm.get('companyName').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('companyName').errors.required">
                    *Company name is required.
                  </span>
                  <span *ngIf="signinForm.get('companyName').errors.pattern">
                    *Please enter valid company name.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('companyName').hasError('required') && 
                      (signinForm.get('companyName').touched || 
                      signinForm.get('companyName').dirty || isSubmittedForm)">
                    *Company name is required.</span>
                </div> -->
              </div>


              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----Zip Code----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">Address Line 1 <span class="c-red">*</span></label>
                <input type="text" class="user-name" placeholder="Enter Address Line 1" formControlName="addressLineOne"
                  maxlength="60" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('addressLineOne').invalid && (signinForm.get('addressLineOne').touched || signinForm.get('addressLineOne').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('addressLineOne').errors.required">
                    *Address is required.
                  </span>
                  <span *ngIf="signinForm.get('addressLineOne').errors.pattern">
                    *Please enter valid address.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('addressLineOne').hasError('required') &&
                      (signinForm.get('addressLineOne').touched ||
                        signinForm.get('addressLineOne').dirty || isSubmittedForm)
                    ">
                    *Address is required.</span>
                </div> -->
              </div>

              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----Zip Code----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">Address Line 2</label>
                <input type="text" class="user-name" placeholder="Enter Address Line 2" formControlName="addressLineTwo"
                  maxlength="60" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('addressLineTwo').invalid && (signinForm.get('addressLineTwo').touched || signinForm.get('addressLineTwo').dirty || isFormSubmitted));">
                  <!-- <span *ngIf="signinForm.get('addressLineTwo').errors.required">
                    *Address is required.
                  </span> -->
                  <span *ngIf="signinForm.get('addressLineTwo').errors.pattern">
                    *Please enter valid address.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('addressLineTwo').hasError('required') &&
                      (signinForm.get('addressLineTwo').touched ||
                        signinForm.get('addressLineTwo').dirty || isSubmittedForm)
                    ">
                    *Address is required.</span>
                </div> -->
              </div>

              <!-- -=-=-=-=-=-=-=-=-=-=- Mobile number Field-=-=--=-=-=-=-=-=-=-= -->

              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----Zip Code----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">Country <span class="c-red">*</span></label>
                <select name="" id="" class="user-name" formControlName="country"
                  (change)="stateList($event.target.value)">
                  <option value="Select Country" selected>Select Country</option>
                  <option *ngFor="let item of countryListArray" [value]="item?.countryCode">{{item?.countryName}}
                  </option>
                </select>
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('country').invalid && (signinForm.get('country').touched || signinForm.get('country').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('country').errors.required">
                    *Country is required.
                  </span>
                  <span *ngIf="signinForm.get('country').errors.pattern">
                    *Please enter valid country.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('country').hasError('required') &&
                      (signinForm.get('country').touched ||
                        signinForm.get('country').dirty || isSubmittedForm)
                    ">
                    *Country is required.</span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <!-- --------=-=-=--=-----Zip Code----=-=-=-=-=-=----------- -->
                <label class="top_heading_lab header-text">State <span class="c-red">*</span></label>
                <select name="" id="" class="user-name" formControlName="state">
                  <option value="Select State">Select State</option>
                  <option *ngFor="let item of stateListArray" [value]="item?.stateName">{{item?.stateName}}</option>


                </select>
                <!-- <input type="text" class="user-name" placeholder="Enter Zip Code" formControlName="zipcode"
                  maxlength="30" autocomplete="new-password" /> -->
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('state').invalid && (signinForm.get('state').touched || signinForm.get('state').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('state').errors.required">
                    *State is required.
                  </span>
                  <span *ngIf="signinForm.get('state').errors.pattern">
                    *Please enter valid State.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('state').hasError('required') &&
                      (signinForm.get('state').touched ||
                        signinForm.get('state').dirty || isSubmittedForm)
                    ">
                    *State is required.</span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">Town / City</label>
                <input type="text" class="user-name" placeholder="Enter Town / City" formControlName="townCity"
                  maxlength="60" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('townCity').invalid && (signinForm.get('townCity').touched || signinForm.get('townCity').dirty || isFormSubmitted));">
                  <!-- <span *ngIf="signinForm.get('townCity').errors.required">
                    *Town / City is required.
                  </span> -->
                  <span *ngIf="signinForm.get('townCity').errors.pattern">
                    *Please enter valid town / city.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('townCity').hasError('required') &&
                      (signinForm.get('townCity').touched ||
                        signinForm.get('townCity').dirty || isSubmittedForm)
                    ">
                    *Town / City is required.</span>
                </div> -->
              </div>

              <!-- --------=-=-=--=-----Zip Code----=-=-=-=-=-=----------- -->
              <div class="input1 col-md-6 ">
                <label class="top_heading_lab header-text">Zip Code <span class="c-red">*</span></label>
                <input type="text" class="user-name" placeholder="Enter Zip Code" formControlName="zipCode"
                  maxlength="10" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('zipCode').invalid && (signinForm.get('zipCode').touched || signinForm.get('zipCode').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('zipCode').errors.required">
                    *Zip code is required.
                  </span>
                  <span *ngIf="signinForm.get('zipCode').errors.pattern">
                    *Please enter valid zip code.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('zipCode').hasError('required') &&
                      (signinForm.get('zipCode').touched ||
                        signinForm.get('zipCode').dirty || isSubmittedForm)
                    ">
                    *Zip code is required.</span>
                </div> -->
              </div>
              <div class="input1 col-md-2 pr-0">
                <!-- <h3 class="p-0 m-0">Company Details
  
                </h3> -->
                <label class="top_heading_lab header-text">Country Code</label>

                <input type="text" aria-label="Country" class="user-name" [formControl]="myControl" maxlength="7"
                  (keypress)="commonService.numberOnly($event)" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete" class="contr">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.code || option.name">
                    <span style="display: flex; justify-content: space-between">

                      <p>{{ option.code }}</p>
                    </span>
                  </mat-option>
                </mat-autocomplete>
              </div>
              <!-- --------=-=-=--=-----full name----=-=-=-=-=-=----------- -->
              <div class="input1 col-md-10">
                <label class="top_heading_lab header-text">Mobile Number (Optional)</label>
                <input type="text" class="user-name" placeholder="Enter Mobile Number" formControlName="mobileNumber"
                  maxlength="15" autocomplete="new-password" (keypress)="commonService.numberOnly($event)" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('mobileNumber').invalid && (signinForm.get('mobileNumber').touched || signinForm.get('mobileNumber').dirty || isFormSubmitted));">
                  <!-- <span *ngIf="signinForm.get('mobileNumber').errors.required">
                                      *Town / City is required.
                                    </span> -->
                  <span *ngIf="signinForm.get('mobileNumber').errors.pattern">
                    *Please enter valid mobile number.
                  </span>
                </div>
              </div>
              <!-- vat number -->
              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">VAT Number (if applicable)
                </label>

                <input type="text" class="user-name" placeholder="Enter VAT Number" maxlength="32"
                  autocomplete="new-password" formControlName="vatNumber" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('vatNumber').invalid && (signinForm.get('vatNumber').touched || signinForm.get('vatNumber').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('vatNumber').errors.pattern">
                    *Please enter valid VAT number.
                  </span>
                </div>
              </div>

              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">EORI Number (if applicable)
                </label>

                <input type="text" class="user-name" placeholder="Enter EORI Number
              " maxlength="32" autocomplete="new-password" formControlName="eoriNumber" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('eoriNumber').invalid && (signinForm.get('eoriNumber').touched || signinForm.get('eoriNumber').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('eoriNumber').errors.pattern">
                    *Please enter valid EORI number.
                  </span>
                </div>
              </div>
              <!-- store details -->
              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">Store Name <span class="c-red">*</span>
                </label>

                <input type="text" class="user-name" placeholder="Enter Store Name" maxlength="60"
                  autocomplete="new-password" formControlName="storeName" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('storeName').invalid && (signinForm.get('storeName').touched || signinForm.get('storeName').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('storeName').errors.required">
                    *Store name is required.
                  </span>
                  <span *ngIf="signinForm.get('storeName').errors.pattern">
                    *Please enter valid store name.
                  </span>
                </div>
                <!-- <div class="text-danger"
                    *ngIf="(signinForm.get('storeName').invalid && (signinForm.get('storeName').touched || signinForm.get('storeName').dirty || isFormSubmitted));">
                    <span *ngIf="signinForm.get('storeName').errors.required">
                      *Store name code is required.
                    </span>
                    <span *ngIf="signinForm.get('storeName').errors.pattern">
                      *Please enter valid store name.
                    </span>
                  </div> -->
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('storeName').hasError('required') &&
                      (signinForm.get('storeName').touched ||
                        signinForm.get('storeName').dirty || isSubmittedForm)
                    ">
                    *Store name is required.</span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">Store Location
                </label>

                <input type="text" class="user-name" placeholder="Enter Store Location" autocomplete="new-password"
                  formControlName="location" ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)" />

                <div class="text-danger"
                  *ngIf="(signinForm.get('location').invalid && (signinForm.get('location').touched || signinForm.get('location').dirty || isFormSubmitted));">
                  <!-- <span *ngIf="signinForm.get('location').errors.required">
                      *Store location is required.
                    </span> -->
                  <span *ngIf="signinForm.get('location').errors.pattern">
                    *Please enter valid store location.
                  </span>
                </div>
              </div>

              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">Store Contact Number <span class="c-red">*</span>
                </label>

                <input type="text" class="user-name" placeholder="Enter Contact Number" maxlength="15"
                  autocomplete="new-password" formControlName="storeContactNumber"
                  (keypress)="commonService.numberOnly($event)" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('storeContactNumber').invalid && (signinForm.get('storeContactNumber').touched || signinForm.get('storeContactNumber').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('storeContactNumber').errors.required">
                    *Store contact number is required.
                  </span>
                  <span *ngIf="signinForm.get('storeContactNumber').errors.pattern">
                    *Please enter valid store contact number.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('storeContactNumber').hasError('required') &&
                      (signinForm.get('storeContactNumber').touched ||
                        signinForm.get('storeContactNumber').dirty || isSubmittedForm)">
                    *Store contact number is required.</span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">Store Website <span class="c-red">*</span>
                </label>

                <input type="text" class="user-name" placeholder="Enter Store Website Url" maxlength="60"
                  autocomplete="new-password" formControlName="storeWeb" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('storeWeb').invalid && (signinForm.get('storeWeb').touched || signinForm.get('storeWeb').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('storeWeb').errors.required">
                    *Store website is required.
                  </span>
                  <span *ngIf="signinForm.get('storeWeb').errors.pattern">
                    *Please enter valid store website.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('storeWeb').hasError('required') &&
                      (signinForm.get('storeWeb').touched ||
                        signinForm.get('storeWeb').dirty || isSubmittedForm)
                    ">
                    *Store web url is required.</span>
                </div> -->
              </div>
              <div class="input1 col-md-12">
                <label class="top_heading_lab header-text">Brand You Carry
                </label>

                <input type="text" class="user-name-text" placeholder="Enter Brand You Carry" maxlength="32"
                  autocomplete="new-password" formControlName="brandCarry">
                <div class="d-flex justify-content-space-between" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;"
                  *ngFor="let item of brandCarry let i = index">
                  <a class="col-md-8 pl-0">{{item}}</a><i class="fa fa-times text-danger ml-3 col-md-4 text-center"
                    (click)="removeBrandCarry(item,i)"></i>
                </div>
                <span class="col-md-2 ">
                  <button class="buttonGlobal new-add-btn"
                    (click)="addBrandCarry(signinForm.get('brandCarry'))">Add</button>
                </span>
              </div>
              <div class="input1 col-md-12">
                <label class="top_heading_lab header-text">Where Did You Hear AboutUs
                </label>

                <textarea type="text" rows="7" cols="5" class="user-name-text" placeholder="Enter about" maxlength="256"
                  autocomplete="new-password" formControlName="storeAbout"></textarea>
              </div>

            </div>

            <!-- end business address -->

            <!-- company details -->
            <div class="row">



              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">First Name <span class="c-red">*</span></label>
                <input type="text" class="user-name" placeholder="Enter Your First Name" formControlName="firstName"
                  maxlength="60" autocomplete="new-password" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('firstName').invalid && (signinForm.get('firstName').touched || signinForm.get('firstName').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('firstName').errors.required">
                    *First name is required.
                  </span>
                  <span *ngIf="signinForm.get('firstName').errors.pattern">
                    *Please enter valid first name.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                    signinForm.get('firstName').hasError('required') &&
                    (signinForm.get('firstName').touched ||
                      signinForm.get('firstName').dirty || isSubmittedForm)
                  ">
                    *First name is required.</span>
                  <span class="error" *ngIf="
                    signinForm.get('firstName').hasError('pattern') &&
                    (signinForm.get('firstName').touched ||
                      signinForm.get('firstName').dirty)
                  ">
                    *Please enter valid first name.</span>
                  <span class="error"
                    *ngIf="signinForm.get('firstName').hasError('minlength') && signinForm.get('firstName').dirty">
                    *Minimum length must be 2.
                  </span>
                </div> -->
              </div>

              <div class="input1 col-md-6">
                <label class="top_heading_lab header-text">Last Name <span class="c-red">*</span></label>
                <input type="text" class="user-name" placeholder="Enter Your Last Name" formControlName="lastName"
                  maxlength="60" autocomplete="new-password" />
                <div class="text-danger"
                  *ngIf="(signinForm.get('lastName').invalid && (signinForm.get('lastName').touched || signinForm.get('lastName').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('lastName').errors.required">
                    *Last name is required.
                  </span>
                  <span *ngIf="signinForm.get('lastName').errors.pattern">
                    *Please enter valid last name.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                    signinForm.get('lastName').hasError('required') &&
                    (signinForm.get('lastName').touched ||
                      signinForm.get('lastName').dirty || isSubmittedForm)
                  ">
                    *Last name is required.</span>
                  <span class="error" *ngIf="
                    signinForm.get('lastName').hasError('pattern') &&
                    (signinForm.get('lastName').touched ||
                      signinForm.get('lastName').dirty || isSubmittedForm )
                  ">
                    *Please enter valid last name.</span>
                </div> -->
              </div>
              <!------------=-=-=--=-=-=-=---------- email Field ----=-=-=-=-=-=-=-=-=----------->


            </div>
            <!-- end company details -->


            <!-- user details -->
            <div class="row">
              <!-- --------=-=-=--=-----full name----=-=-=-=-=-=----------- -->



              <!------------=-=-=--=-=-=-=---------- email Field ----=-=-=-=-=-=-=-=-=----------->
              <div class="input1 col-md-12">
                <label class="top_heading_lab header-text">Email Address <span class="c-red">*</span></label>

                <input type="text" class="user-name" placeholder="Enter Email Address" formControlName="email"
                  maxlength="256" autocomplete="new-password" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('email').invalid && (signinForm.get('email').touched || signinForm.get('email').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('email').errors.required">
                    *Email address is required.
                  </span>
                  <span *ngIf="signinForm.get('email').errors.pattern">
                    *Please enter a valid email address.
                  </span>
                </div>
                <!-- <span class="error" *ngIf="
                       signinForm.get('email').hasError('required') &&
                       (signinForm.get('email').touched ||
                         signinForm.get('email').dirty || isSubmittedForm)
                     ">
                  *Email address is required.</span>
                <span class="error" *ngIf="
                       signinForm.get('email').hasError('pattern') &&
                       (signinForm.get('email').touched ||
                         signinForm.get('email').dirty)
                     ">
                  *Please enter a valid email address.</span> -->
              </div>

              <div class="input1 col-md-6 postion-relative">
                <label class="top_heading_lab header-text">Password <span class="c-red">*</span></label>

                <input [type]="passType ?'password' : 'text'" class="user-name" placeholder="Enter Password"
                  formControlName="password" maxlength="32" autocomplete="new-password" />
                <span class="eye-icon" (click)="passType = !passType"> <i
                    [ngClass]="passType?'fa fa-eye':'fa fa-eye-slash'"></i> </span>
                <div class="text-danger"
                  *ngIf="(signinForm.get('password').invalid && (signinForm.get('password').touched || signinForm.get('password').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('password').errors.required">
                    *Password is required.
                  </span>
                  <span *ngIf="signinForm.get('password').errors.pattern">
                    *Password must contain minimum 8 characters with uppercase letter, a number & a special
                    character.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                        signinForm.get('password').hasError('required') &&
                        (signinForm.get('password').touched ||
                          signinForm.get('password').dirty || isSubmittedForm)
                      ">
                    *Password is required.</span>
                  <span class="error" *ngIf="
                    signinForm.get('password').hasError('pattern') &&
                    (signinForm.get('password').touched ||
                      signinForm.get('password').dirty)
                  ">
                    *Password must contain minimum 8 characters with uppercase letter, a number & a special
                    character.</span>
                </div> -->
              </div>
              <div class="input1 col-md-6 postion-relative">
                <label class="top_heading_lab header-text">Confirm Password <span class="c-red">*</span></label>

                <input [type]="passType1 ?'password' : 'text'" class="user-name" placeholder="Enter Confirm Password"
                  formControlName="confirmPassword" maxlength="32" autocomplete="new-password" />
                <span class="eye-icon" (click)="passType1 = !passType1"> <i
                    [ngClass]="passType1?'fa fa-eye':'fa fa-eye-slash'"></i> </span>
                <div class="text-danger">
                  <span *ngIf="
                                    signinForm.get('confirmPassword').hasError('required') &&
                                    (signinForm.get('confirmPassword').touched ||
                                      signinForm.get('confirmPassword').dirty || isFormSubmitted)
                                  ">
                    *Confirm password is required.</span>
                  <span *ngIf=" signinForm.get('confirmPassword').value &&
                                    (signinForm.get('password').value !=
                                      signinForm.get('confirmPassword').value) &&
                                    signinForm.get('confirmPassword').dirty
                                  ">
                    *Confirm Password should match with Password.</span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                      signinForm.get('confirmPassword').hasError('required') &&
                      (signinForm.get('confirmPassword').touched ||
                        signinForm.get('confirmPassword').dirty || isSubmittedForm)
                    ">
                    *Confirm password is required.</span>
                  <span class="error" *ngIf="
                      signinForm.get('password').value !=
                        signinForm.get('confirmPassword').value &&
                      signinForm.get('confirmPassword').dirty
                    ">
                    *Confirm Password should match with New Password.</span>
                </div> -->
              </div>

              <div class="input1 col-md-6">
                <!-- -------------Brands you carry------------- -->
                <label class="top_heading_lab mt-0 header-text">Govt. Document Front <span
                    class="c-red">*</span></label>

                <input type="file" class="user-name" placeholder="Govt. Document" formControlName="govtDocsFront"
                  autocomplete="new-password" accept=".png, .jpg, .jpeg" (change)="uploadImg($event, 'FRONT')" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('govtDocsFront').invalid && (signinForm.get('govtDocsFront').touched || signinForm.get('govtDocsFront').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('govtDocsFront').errors.required">
                    *Govt. document is required.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                    signinForm.get('govtDocsFront').hasError('required') &&
                    (signinForm.get('govtDocsFront').touched ||
                      signinForm.get('govtDocsFront').dirty || isSubmittedForm)
                  ">
                    *Govt. document is required.</span>
                </div> -->
              </div>
              <div class="input1 col-md-6">
                <!-- -------------Brands you carry------------- -->
                <label class="top_heading_lab mt-0 header-text">Govt. Document Back <span class="c-red">*</span></label>

                <input type="file" class="user-name" placeholder="Govt. Document" formControlName="govtDocsBack"
                  autocomplete="new-password" accept=".png, .jpg, .jpeg" (change)="uploadImg($event, 'BACK')" />
                <!-- validation -->
                <div class="text-danger"
                  *ngIf="(signinForm.get('govtDocsBack').invalid && (signinForm.get('govtDocsBack').touched || signinForm.get('govtDocsBack').dirty || isFormSubmitted));">
                  <span *ngIf="signinForm.get('govtDocsBack').errors.required">
                    *Govt. document is required.
                  </span>
                </div>
                <!-- <div>
                  <span class="error" *ngIf="
                    signinForm.get('govtDocsBack').hasError('required') &&
                    (signinForm.get('govtDocsBack').touched ||
                      signinForm.get('govtDocsBack').dirty || isSubmittedForm)
                  ">
                    *Govt. document is required.</span>
                </div> -->
              </div>
            </div>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="checkTerms">
              <!-- <label class="form-check-label" for="exampleCheck1" data-toggle="tooltip" data-placement="top"
                title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reiciendis cumque aliquam quisquam quaerat esse provident blanditiis modi doloribus id corrupti numquam, expedita ratione distinctio quasi mollitia quae veniam eveniet ducimus.">I
                agree terms and conditions</label> -->
              <label class="form-check-label">I
                agree to the &nbsp;<a style="color: #007bff;" href="/terms-condition" target="_blank">terms and
                  conditions</a></label>
              <!-- validation -->
              <div>
                <span class="text-danger"
                  *ngIf="signinForm.get('checkTerms').hasError('required') && (signinForm.get('checkTerms').touched || signinForm.get('checkTerms').dirty || isFormSubmitted)">*Please
                  agree terms and conditions.</span>
              </div>
            </div>
            <!-- end user details -->
            <!-- </div> -->
            <div class="down-button text-left">
              <!-- <button mat-button class="buttonGlobal" (click)="signUp()" [disabled]="!signinForm.valid" >Get -->
              <button mat-button class="buttonGlobal" (click)="signUp()">Get Started</button>
            </div>
          </form>
        </div>
      </div>
    </mat-card>
  </div>
</div>