export const RegEx = {
    'email': /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i,
    // 'name': /^[a-zA-Z]*$/i,
    // 'phoneNo': /^[0-9]*$/i,
    // 'pswd': /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i,
    // 'pswd': /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    'pswd': '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&()-/$=<>?])[a-zA-Z0-9!@#&()-/$=<>?]+$',

    // 'city': "^(?:[A-Za-z]{2,}(?:(\.\s|'s\s|\s?-\s?|\s)?(?=[A-Za-z]+))){1,2}(?:[A-Za-z]+)?$",
    'firstName': /^[a-zA-Z]{2,60}$/, // ok
    'lastName': /^[a-zA-Z ]{2,60}$/, // ok
    'state': /^[A-Za-z][A-Za-z ]*$/,
    'city': /^[A-Za-z][A-Za-z ]{2,}$/,
    'phoneNo': /^[1-9][0-9]{7,15}$/, // ok
    'address': /^[a-zA-Z0-9\s.,'-]{2,}$/, // ok
    'internationalZipCode': '[0-9-A-Z]+', // ok
    'cardNumber': /^[1-9][0-9]{15}$/, // ok
    'cardCvv': /^[0-9]{3}$/, // ok
    'cardName': /^[a-zA-Z ]{2,60}$/, // ok

    'companyName': /^[a-zA-Z0-9\s,'-]{2,}$/, // ok
    'vatNumber': /^[A-Z0-9]/,
    'eoriNumber': /^[A-Z0-9]/,
    'storeName': /^[a-zA-Z0-9\s,'-]{2,}$/, // ok
    'url': /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
}
