import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
@Component({
  selector: 'app-collection-bridal-party',
  templateUrl: './collection-bridal-party.component.html',
  styleUrls: ['./collection-bridal-party.component.scss']
})
export class CollectionBridalPartyComponent implements OnInit {
  filtered:any = true
  selected = "Recomended"
  value = "page 1"
  panelOpenState = false;
  
  
  step = 0;
  productListArray: any;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  // panelOpenState = false;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    navText: [ '<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"' ],
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive: {
      0: {
        items: 5
      },
      400: {
        items: 5
      },
      740: {
        items: 5
      },
      940: {
        items: 5
      }
    },
    // nav: true
    nav:false
    // navigation:true,

  }
  newOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    navText: [ '<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"' ],
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    // nav: true
    nav:false
    // navigation:true,

  }
  embelishmentOption: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    navText: [ '<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"' ],
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    // nav: true
    nav:false
    // navigation:true,

  }


  constructor(private commonService:CommonService) { }

  ngOnInit(): void {
    this.getBridalPartyCollectionList()
  }
  addFilter(){
    this.filtered = false
  }
  removeFilter(){
    this.filtered = true
  }

  getBridalPartyCollectionList(){
    let apiReqUrl = ApiEndPoint.productListWithFilter
    let apiReqData = {
      "page": 1,
      "limit": 10,
      "minAmount": 0,
      "maxAmount": 0,
      "fabric": "string",
      "category": "623857a5c440c3a610cb1833", // Collection
      "subCategory": "62385cb9c440c3a610cb183c", //Bridal Party
      "color": "string"
    }
    
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        console.log("fdsgdfgfdhdfhfghfghh");this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
        this.productListArray = res.result.docs ? res.result.docs : ''
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      if (err.statusCode == '401') {
        this.commonService.errorToast(err['error']['message']);
        localStorage.removeItem('token');
      } else {
        this.commonService.errorToast(err.message);
      }
    })
  }
}
