import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catelogue-collection-list',
  templateUrl: './catelogue-collection-list.component.html',
  styleUrls: ['./catelogue-collection-list.component.scss']
})
export class CatelogueCollectionListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
