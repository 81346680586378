import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqList: any;

  constructor(private commonServcie:CommonService ) { 
    window.scrollTo(0,0)
  }

  ngOnInit(): void {
    this.getFaqListApi()
  }

  getFaqListApi(){
    this.commonServcie.showSpinner()
    this.commonServcie.getApi(ApiEndPoint.faqList,1).subscribe((res:any)=>{
      if(res.responseCode == 200){
      this.faqList = res.result
      this.commonServcie.hideSpinner()
      }else{
        this.commonServcie.hideSpinner()
        this.commonServcie.errorToast(res.responseMessage)
      }
    },(err:any)=>{
      this.commonServcie.errorToast(err.responseMessage)

    })
  }
}
