<div class="main-class wrapper">
    <!-- <mat-toolbar class="toolbar-custom">
    <span class="header-text">Press</span>
</mat-toolbar> -->
    <div class="toolbar-custom text-center">
        <span class="header-text">Motee Maids Press</span>
    </div>
    <div class="page-width">
        <div class="row justify-content-start" style="margin-bottom: 25px;">
            <div class="blog-post" routerLink="/press-view" *ngFor="let item of pressList">
                <div class="post-content">
                    <figure class="post-image">
                        <img mat-card-image class="images-custom" [src]="item?.image?.webImage">
                    </figure>
                    <div class="post-title" style="max-width: 440px;">
                        <a class="anchor-custom header-text">{{item?.title}}</a>
                    </div>
                </div>
                <!-- <div class="popsugar-text">
                    <span class="brides-text header-text-custom">{{item?.title}}</span>
                </div> -->
            </div>

            <!-- <div class="blog-post" routerLink="/press-view">
            <div class="post-content">
                <figure class="post-image">
                </figure>
                <div class="post-title">
                    <a class="anchor-custom header-text">Motee Maids Evening - Spring 2022</a>
                </div>
            </div>
            <div class="popsugar-text">
                <span class="brides-text header-text-custom">BRIDES</span>
            </div>
        </div>
         -->
            <!-- <div class="blog-post" routerLink="/press-view">
            <div class="post-content">
                <figure class="post-image">
                    <img mat-card-image class="images-custom" src="/assets/images/Shoot_3/Motee_Cara_Cowl_in_Embroidery_Burgundy_Close_Up.jpg">
                </figure>
                <div class="post-title">
                  <a class="anchor-custom">Micro Wedding Trends</a>
                </div>
                <div class="popsugar-text">
                    <span class="brides-text header-text-custom">BRIDES</span>
                </div>
            </div>
        </div> -->

            <!-- <div class="blog-post" routerLink="/press-view">
            <div class="post-content">
                <figure class="post-image">
                    <img mat-card-image class="images-custom" src="/assets/images/SEQUIN_SASH_COLLECTION_JPEG/Seraphina_Close_Up.jpg">
                </figure>
                <div class="post-title">
                    <a class="anchor-custom header-text">Motee Maids Evening - Spring 2022</a>
                </div>
                <div class="popsugar-text-custom">
                    <span class="brides-text header-text-custom">BRIDES</span>
                </div>
            </div>
        </div> -->

            <!-- <div class="blog-post" routerLink="/press-view">
            <div class="post-content">
                <figure class="post-image">
                    <img mat-card-image class="images-custom" src="/assets/images/SEQUIN_SASH_COLLECTION_JPEG/Emma_Silver_Sash_Front.jpg">
                </figure>
                <div class="post-title">
                    <a class="anchor-custom header-text">Motee Maids Evening - Spring 2022</a>
                </div>
                <div class="popsugar-text-custom">
                    <span class="brides-text header-text-custom">BRIDES</span>
                </div>
            </div>
        </div> -->

            <!-- <div class="blog-post" routerLink="/press-view">
            <div class="post-content">
                <figure class="post-image">
                </figure>
                <div class="post-title">
                  <a class="anchor-custom">Vogue Runway</a>
                </div>
            </div>
            <div class="popsugar-text-custom">
                <span class="brides-text header-text-custom
                ">VOGUE</span>
            </div>
        </div> -->
        </div>

    </div>
</div>