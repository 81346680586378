import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { RegEx } from 'src/app/config/reg-ex';
import { CommonService } from 'src/app/provider/common.service';
import Swal from 'sweetalert2';
declare var $
@Component({
  selector: 'app-my-address',
  templateUrl: './my-address.component.html',
  styleUrls: ['./my-address.component.scss']
})
export class MyAddressComponent implements OnInit {
  isSubmitted: boolean = false
  countryListArray: any = [];
  stateListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  addressId: any
  addAddressForm: FormGroup;
  editAddressFrom: FormGroup;

  constructor(public commonService: CommonService, public activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.queryParams.subscribe((res) => {
      console.log(res)
      this.addressId = res.addressId;

    })
    this.countryListDetail()
    this.formValidation();
    this.editAdrressFormValidation()
    this.listAddress();
    // this.viewAddress();

  }
  formValidation() {
    this.addAddressForm = new FormGroup({
      'address': new FormControl("", [Validators.required, Validators.pattern(RegEx.address)]),
      'state': new FormControl("", Validators.required),
      'country': new FormControl("", Validators.required),
      // 'city': new FormControl("", Validators.required),
      'city': new FormControl("", [Validators.pattern(RegEx.city)]),
      'zipCode': new FormControl("", [Validators.required, Validators.pattern(RegEx.internationalZipCode)])
    });
  }
  editAdrressFormValidation() {
    this.editAddressFrom = new FormGroup({
      editAddress: new FormControl("", [Validators.required, Validators.pattern(RegEx.address)]),
      editCountry: new FormControl("", Validators.required),
      editState: new FormControl(""),
      editCity: new FormControl("", [Validators.pattern(RegEx.city)]),
      editZipCode: new FormControl("", [Validators.required, Validators.pattern(RegEx.internationalZipCode)])
    })
  }

  ngOnDestroy() {
    $("body>#addNewAddressModalId").remove();
    $("body>#deleteAddress").remove();
    $("body>#viewAddress").remove();

  }
  // countryListArray : any = [];
  countryName: any
  countryNameList: any
  /**
     * userType are used in website
     * @param country list -> normal user comes to buy product
     */
  countryListDetail() {
    let apiReqUrl = 'product/countryList'
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.countryListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    })
  }

  stateList(countryCode) {


    let apiReqUrl = `product/stateList?countryCode=${countryCode.value || countryCode}`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        this.stateListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    })
  }


  listAddressArray: any = [];
  listAddress() {
    let apiReqUrl = ApiEndPoint.listAddress
    let apiReqData = {
      page: this.currentPage,
      limit: this.itemPerPage,
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log("change password response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        this.listAddressArray = res.result ? res.result.docs : []
        this.countryListDetail();
        localStorage.removeItem('addressId')
      } else {
        this.listAddressArray = []
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      this.listAddressArray = []
      this.commonService.hideSpinner()
      // this.commonService.errorToast(err.responseMessage)
    })
  }

  getCountry(countryCode) {
    if (!this.countryListArray.length) {
      return
    }
    let temp = this.countryListArray.filter((item) => {
      return item?.countryCode == countryCode
    })
    // console.log(temp);
    return temp[0]?.countryName
  }

  deleteAddressModal(addressId) {
    $('#deleteAddress').appendTo("body").modal('show');
    this.addressId = addressId
  }

  deleteAddress() {
    let apiReqUrl: any = `${ApiEndPoint.deleteAddress + '?addressId=' + this.addressId}`
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        localStorage.removeItem('addressId')
        this.commonService.hideSpinner()
        $('#deleteAddress').modal('hide');
        this.commonService.alertSuccess(res.responseMessage)
        this.listAddress();
        // this.commonService.successToast(res.responseMessage);
        // Swal.fire({
        //   title: 'Oops...',
        //   text:res.responseMessage,
        // })

      } else {
        this.commonService.hideSpinner();
      }
    })
  }


  addAddressModal() {
    $('#addNewAddressModalId').appendTo("body").modal('show');
  }

  addAddress() {
    if (this.addAddressForm.invalid) {
      this.isSubmitted = true
      return
    }
    let apiReqUrl = ApiEndPoint.addAddress
    let apiReqData = {
      address: this.addAddressForm.value.address,
      country: this.addAddressForm.value.country,
      state: this.addAddressForm.value.state,
      city: this.addAddressForm.value.city,
      zipCode: this.addAddressForm.value.zipCode
    }
    this.commonService.removeEmptyKey(apiReqData)
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        // this.countryListArray = res.result ? res.result : []
        this.commonService.hideSpinner()

        $('#addNewAddressModalId').modal('hide')
        // this.commonService.successToast(res.responseMessage)
        localStorage.removeItem('addressId')
        this.listAddress();
        this.addAddressForm.reset();
        this.isSubmitted = false

      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    }, err => {
      if (err['responseCode'] == 400) {
        this.commonService.alertError(err.responseMessage);
      }
    })
  }

  viewAddressModal(addressId) {
    // alert(addressId)
    $('#viewAddress').appendTo("body").modal('show');
    this.viewAddress(addressId);
    // localStorage.setItem('addressId',addressId)
    this.addressId = addressId
  }
  viewAddress(addressId) {
    // let addressId = localStorage.set('addressId')
    localStorage.setItem('addressId', addressId)

    let apiReqUrl: any = `${ApiEndPoint.viewAddress + '?addressId=' + addressId}`
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("change password response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        this.editAddressFrom.patchValue({
          editAddress: res.result.address,
          editCountry: res.result.country,
          editState: res.result.state,
          editCity: res.result.city,
          editZipCode: res.result.zipCode
        })
        this.stateList(res.result.country)
        // this.listAddressArray = res.result.docs
        // localStorage.removeItem('addressId')
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  editAddress() {
    // let addressId = localStorage.getItem('addressId')
    let apiReqUrl: any = `${ApiEndPoint.editAddress + '?addressId=' + this.addressId}`
    let apiReqData = {
      address: this.editAddressFrom.value.editAddress,
      country: this.editAddressFrom.value.editCountry,
      state: this.editAddressFrom.value.editState,
      city: this.editAddressFrom.value.editCity,
      zipCode: this.editAddressFrom.value.editZipCode
    }
    this.commonService.removeEmptyKey(apiReqData)
    this.commonService.showSpinner()
    this.commonService.putApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res['responseCode'] == '200') {
        // this.countryListArray = res.result ? res.result : []
        this.commonService.hideSpinner()
        $('#viewAddress').modal('hide')
        // this.commonService.successToast(res.responseMessage)
        Swal.fire({
          text: res.responseMessage,
        })
        this.listAddress();
        localStorage.removeItem('addressId')
      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
    }, err => {
      if (err['responseCode'] == 400) {
        this.commonService.alertError(err.responseMessage);
      }
    })
  }
}
