<div class="top-header">
    <!-- <h1 class="list-top-head w-50"> -->
    <h1 class="list-top-head w-100">
        <marquee behavior="scroll" direction="left" scrollamount="6" onmouseover="this.stop();"
            onmouseout="this.start();">
            <!-- NEW LONDON STOCKIST NABBD -->
            <a [href]="topHeaderTitleLink" target="_blank"> {{topHeaderTitle || '--'}}</a>
        </marquee>
    </h1>
    <div>
        <div class="top-header-social-icon">
            <a href="https://www.facebook.com" class="top-header-item top-header-link" target="_blank">
                <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href="https://www.twitter.com" class="top-header-item top-header-link" target="_blank">
                <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a href="https://www.pinterest.com" class="top-header-item top-header-link" target="_blank">
                <i class="fa fa-pinterest" aria-hidden="true"></i>
            </a>
            <a href="https://www.instagram.com" class="top-header-item top-header-link" target="_blank">
                <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
        </div>
    </div>
</div>
<!-- <nav class="navbar navbar-expand-md navbar-light fixed-top middle-header pl-3 pr-3"> -->
<nav class="navbar navbar-expand-xl navbar-light fixed-top middle-header pl-3 pr-3">
    <a class="navbar-brand">
        <img class="logoImage" src="{{logoResultArray?.webImage}}" alt="logo" routerLink="/homepage" routerlinkActive>
    </a>
    <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
        <div class="navbar-nav">
            <div *ngFor="let item of headerList" class="nav-item menu-items mr-2"
                [ngClass]="{'dropdown': item?.sub.length}">
                <a class="nav-link" [ngClass]="{'dropdown-toggle': item?.sub.length}" data-toggle="dropdown"
                    (click)="item?.sub?.length ? '': menuNavigateTo(item?.parent?.categoryName)"
                    *ngIf="item?.parent?.categoryName !='Customize' &&  item?.parent?.categoryName != 'Social Shopping'">{{item?.parent?.categoryName}}</a>
                <!-- <a class="nav-link" [ngClass]="{'dropdown-toggle': item?.sub.length}" data-toggle="dropdown" 
                    (click)="item?.sub?.length ? '': menuNavigateTo(item?.parent?.categoryName)" *ngIf="item?.parent?.categoryName != 'Social Shopping' ">{{item?.parent?.categoryName}}</a> -->
                <a class="nav-link" [ngClass]="{'dropdown-toggle': item?.sub.length}" data-toggle="dropdown"
                    (click)="item?.sub?.length ? '': menuNavigateTo(item?.parent?.categoryName)"
                    *ngIf="item?.parent?.categoryName =='Customize' || item?.parent?.categoryName == 'Social Shopping' "
                    data-toggle="collapse" data-target=".navbar-collapse">{{item?.parent?.categoryName}}</a>
                <!-- <a class="nav-link" [ngClass]="{'dropdown-toggle': item?.sub.length}" data-toggle="dropdown" 
                    (click)="item?.sub?.length ? '': menuNavigateTo(item?.parent?.categoryName)" *ngIf="item?.parent?.categoryName == 'Social Shopping'" class="visible-xs" data-toggle="collapse" data-target=".navbar-collapse">{{item?.parent?.categoryName}}</a> -->
                <div *ngIf="item?.sub?.length" class="dropdown-menu">
                    <a *ngFor="let sub of item?.sub" class="dropdown-item" data-toggle="collapse"
                        data-target="#navbarCollapse"
                        (click)="menuSubNavigateTo(item?.parent?._id, sub?._id,item?.parent?.categoryName, sub?.subCategoryName); ">{{sub?.subCategoryName}}</a>
                    <!-- <a class="dropdown-item">Bridal Party</a> -->
                </div>
            </div>
            <!-- <div class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown">Ready to Ship</a>
                <div class="dropdown-menu">
                    <a class="dropdown-item">Bridal</a>
                    <a class="dropdown-item">Bridal Party</a>
                    <a class="dropdown-item">Event Dresses</a>
                    <a class="dropdown-item">Features</a>
                </div>
            </div>
            <a class="nav-item nav-link active">Customize</a>
            <a class="nav-item nav-link">Social Shopping</a>
            <div class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown">Messages</a>
                <div class="dropdown-menu">
                    <a class="dropdown-item">Inbox</a>
                    <a class="dropdown-item">Sent</a>
                    <a class="dropdown-item">Drafts</a>
                </div>
            </div> -->
        </div>
        <!-- <div class="navbar-nav">
            <div class="navbar-social-icon">

                <a href="https://www.facebook.com" class="nav-item nav-link" target="_blank">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a href="https://www.twitter.com" class="nav-item nav-link" target="_blank">
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="https://www.pinterest.com" class="nav-item nav-link" target="_blank">
                    <i class="fa fa-pinterest" aria-hidden="true"></i>
                </a>
                <a href="https://www.instagram.com" class="nav-item nav-link" target="_blank">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
            </div>
        </div> -->
        <!-- <form class="form-inline">
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Search">
                <div class="input-group-append">
                    <button type="button" class="btn btn-secondary"><i class="fa fa-search"></i></button>
                </div>
            </div>
        </form> -->
        <!-- <div class="navbar-nav">
            <a class="nav-item nav-link">Login</a>
            <a class="nav-item nav-link">Login</a>
        </div> -->
        <div class="navbar-nav">

            <div class="navbar-social-icon">

                <!-- before login -->
                <div *ngIf="!isLoggedIn" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown">
                        <i class="fa fa-user first-profile-ico" aria-hidden="true"></i>
                    </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" (click)="loginNavigate('CUSTOMER')">Login As Consumer</a>
                        <a class="dropdown-item" (click)="loginNavigate('RETAILER')">Login As Retailer</a>
                        <a class="dropdown-item" (click)="loginNavigate('INFLUENCER')">Login As Influencer</a>
                    </div>
                </div>

                <!-- after login -->
                <div *ngIf="isLoggedIn" class="nav-item">
                    <a class="nav-link">
                        <i class="fa fa-user" aria-hidden="true" (click)="menuNavigateTo('my-profile')"></i>
                    </a>
                </div>

                <div class="nav-item">
                    <a class="nav-link">
                        <i class="fa fa-search" aria-hidden="true" (click)="menuNavigateTo('search')"></i>
                    </a>
                </div>
                <div *ngIf="isLoggedIn" class="nav-item">
                    <a class="nav-link iconClass">
                        <i class="fa fa-shopping-bag" aria-hidden="true"
                            (click)="menuNavigateTo('my-shopping-bag')"></i>
                        <span *ngIf="totalItemsInShoppingBag !=0 "
                            class="badge badge-light badge-light-custom">{{totalItemsInShoppingBag}}</span>

                    </a>
                </div>


            </div>

        </div>

    </div>
</nav>